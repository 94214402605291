import { Radio, Text, Textarea } from '@alkem/react-ui-inputs';
import InputWithLabel from 'components/input-with-label';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectFunctionValueOptions,
  selectReferentialCodeOptions,
} from '../../../../selectors';
import {
  ExportMapping,
  ExportMappingPayload,
  ExportMappingPayloadValueData,
  Option,
  Options,
  RegularOption,
} from '../../../../types';
import { getStringWithoutLineBreaks } from '../../../../utils';
import { ExportMappingsSimpleSelector } from '../../simple-selector';
import { getData, valueCastOptions } from '../utils';

const getInitialValueCast = (payload: ExportMappingPayload) => {
  const { value } = payload;
  if (!value) return undefined;

  return valueCastOptions.find(
    (valueTypeOption) => valueTypeOption.id === value.cast
  );
};

const getInitialFunctionValue = (
  payload: ExportMappingPayload,
  functionValueOptions: Options
) => {
  const { value } = payload;
  if (!value || value.cast !== 'function') return undefined;

  return functionValueOptions.find(
    (functionValueOption) => functionValueOption.label === value.data
  );
};
interface Props {
  elementName: string;
  exportMapping: ExportMapping;
  dataTypeOption: RegularOption;
  isDeclinable?: boolean;
  referential?: string;
  dispatchSetExportMapping: ({
    cast,
    field,
    use_su,
    referential,
    is_declinable,
    value,
    custom_parse,
  }: ExportMappingPayload) => void;
}
export function ExportMappingsSimpleElementItemValueTypeValueSetter({
  elementName,
  exportMapping,
  dataTypeOption,
  isDeclinable,
  referential,
  dispatchSetExportMapping,
}: Props) {
  const referentialCodeOptions = useSelector(selectReferentialCodeOptions);
  const functionValueOptions = useSelector(selectFunctionValueOptions);
  const notInitialRender = useRef(false);
  const [valueCastOption, setValueCastOption] = useState(
    getInitialValueCast(exportMapping.payload)
  );
  const [valueData, setValueData] = useState<
    ExportMappingPayloadValueData | undefined
  >(exportMapping.payload.value?.data);
  const [functionValueOption, setFunctionValueOption] = useState<
    Option | undefined
  >(getInitialFunctionValue(exportMapping.payload, functionValueOptions));
  const [referentialCode, setReferentialCode] = useState<
    RegularOption | undefined
  >(undefined);

  useEffect(() => {
    if (notInitialRender.current) {
      setValueCastOption(undefined);
      setValueData(undefined);
      setFunctionValueOption(undefined);
      setReferentialCode(undefined);
    } else {
      notInitialRender.current = true;
    }
  }, [dataTypeOption, isDeclinable, referential]);

  const updateValueCastOption = (value: RegularOption) => {
    const { cast, field, use_su, is_declinable, referential } =
      exportMapping.payload;
    let data: ExportMappingPayloadValueData | undefined = undefined;
    if (cast === 'boolean' && value.id === 'value') {
      data = true;
    }
    setValueCastOption(value);
    setValueData(data);
    setReferentialCode(undefined);
    setFunctionValueOption(undefined);
    dispatchSetExportMapping({
      cast,
      field,
      use_su,
      is_declinable,
      referential,
      value: {
        cast: value.id,
        data,
      },
    });
  };

  const resetValueTypeOption = () => {
    const { cast, field, use_su, is_declinable, referential } =
      exportMapping.payload;
    setValueCastOption(undefined);
    setValueData(undefined);
    setReferentialCode(undefined);
    setFunctionValueOption(undefined);
    dispatchSetExportMapping({
      cast,
      field,
      use_su,
      is_declinable,
      referential,
    });
  };

  const updateBooleanValueData = (event: { target: { value: string } }) => {
    const { value } = event.target;
    const { cast, field, use_su, referential, is_declinable } =
      exportMapping.payload;
    setValueData(value);
    dispatchSetExportMapping({
      cast,
      field,
      use_su,
      referential,
      is_declinable,
      value: {
        ...(exportMapping.payload.value || {}),
        data: value === 'true',
      },
    });
  };

  const updateReferentialCode = (valueOption: RegularOption) => {
    const { cast, referential, value, is_declinable } = exportMapping.payload;
    setReferentialCode(valueOption);
    let data:
      | { code: string }
      | { data: string; expressedIn: { code: string } }[]
      | undefined = undefined;
    if (isDeclinable) {
      data = [
        {
          ...(value?.data?.[0] || {}),
          expressedIn: { code: valueOption.label },
        },
      ];
    } else {
      data = { code: valueOption.label };
    }
    setValueData(data);
    dispatchSetExportMapping({
      cast,
      referential,
      is_declinable,
      value: {
        ...(value || {}),
        data,
      },
    });
  };

  const resetReferentialCode = () => {
    const { cast, referential, value, is_declinable } = exportMapping.payload;
    setReferentialCode(undefined);
    const data = isDeclinable
      ? [
          {
            ...value?.data?.[0],
            expressedIn: undefined,
          },
        ]
      : undefined;
    setValueData(data);
    dispatchSetExportMapping({
      cast,
      referential,
      is_declinable,
      value: {
        ...(value || {}),
        data,
      },
    });
  };

  const updateValueDataDeclinable = (event: { target: { value: string } }) => {
    const { cast, referential, value, is_declinable } = exportMapping.payload;
    const dataType = dataTypeOption as RegularOption;
    const { data, isValueDataSet } = getData(
      event.target.value,
      dataType,
      (updatedData: string | number | undefined) =>
        setValueData([{ ...(value?.data?.[0] || {}), data: updatedData }])
    );
    dispatchSetExportMapping({
      cast,
      referential,
      is_declinable,
      value: {
        ...(value || {}),
        data: [
          {
            ...(valueData?.[0] || {}),
            data,
          },
        ],
      },
    });
    if (!isValueDataSet) {
      setValueData([{ ...(value?.data?.[0] || {}), data }]);
    }
  };

  const updateValueData = (event: { target: { value: string } }) => {
    const { cast, referential, value } = exportMapping.payload;
    const dataType = dataTypeOption as RegularOption;
    const { data, isValueDataSet } = getData(
      event.target.value,
      dataType,
      setValueData
    );
    dispatchSetExportMapping({
      cast,
      referential,
      value: {
        ...(value || {}),
        data,
      },
    });
    if (!isValueDataSet) {
      setValueData(data);
    }
  };

  const updateLambda = (event: { target: { value: string } }) => {
    const { cast, referential, value } = exportMapping.payload;
    const data = getStringWithoutLineBreaks(event.target.value) || undefined;
    setValueData(data);
    dispatchSetExportMapping({
      cast,
      referential,
      value: {
        ...(value || {}),
        data,
      },
    });
  };

  const updateFunctionValue = (functionValue: {
    id: number;
    label: string;
  }) => {
    const { cast, referential, value } = exportMapping.payload;
    const data = functionValue.label;
    setFunctionValueOption(functionValue);
    dispatchSetExportMapping({
      cast,
      referential,
      value: {
        ...(value || {}),
        data,
      },
    });
  };

  const resetFunctionValue = () => {
    const { cast, referential, value } = exportMapping.payload;
    setFunctionValueOption(undefined);
    dispatchSetExportMapping({
      cast,
      referential,
      value: {
        ...(value || {}),
        data: undefined,
      },
    });
  };

  const { id } = exportMapping;

  return (
    <div className="GDSNExportMappingsSimpleElementItemValueTypeValueSetter">
      <ExportMappingsSimpleSelector
        label="The value is"
        testId={`gdsn-export-mappings-simple-element-${elementName}-value-type-property-${id}`}
        options={valueCastOptions}
        onSelect={updateValueCastOption}
        option={valueCastOption}
        onReset={resetValueTypeOption}
      />
      {valueCastOption?.id === 'value' && (
        <>
          {dataTypeOption?.id === 'boolean' && (
            <div
              className="GDSNExportMappingsSimpleElementItemType__booleanValueData"
              data-testid={`gdsn-export-mappings-simple-element-${elementName}-boolean-value-data-${id}`}
            >
              <InputWithLabel
                inputId={`gdsn-export-mappings-simple-element-${elementName}-boolean-value-data-${id}`}
                label="Value"
              >
                <Radio
                  id={`gdsn-export-mappings-simple-element-${elementName}-boolean-value-data-${id}`}
                  value={valueData}
                  options={[
                    { label: 'TRUE', value: true },
                    { label: 'FALSE', value: false },
                  ]}
                  onChange={updateBooleanValueData}
                />
              </InputWithLabel>
            </div>
          )}
          {!['boolean', 'entity'].includes(dataTypeOption.id) &&
            isDeclinable &&
            referential && (
              <InputWithLabel
                inputId={`gdsn-export-mappings-simple-element-${elementName}-value-data-declinable-${id}`}
                label="Value"
              >
                <Text
                  className="GDSNExportMappingsSimpleElementItemType__value-data-declinable"
                  id={`gdsn-export-mappings-simple-element-${elementName}-value-data-declinable-${id}`}
                  value={
                    (
                      valueData as {
                        data: string;
                        expressedIn: { code: string };
                      }[]
                    )?.[0]?.data ?? ''
                  }
                  onChange={updateValueDataDeclinable}
                />
              </InputWithLabel>
            )}
          {(dataTypeOption?.id === 'entity' || isDeclinable) && referential && (
            <ExportMappingsSimpleSelector
              label={`Select ${
                isDeclinable ? 'an expressed in' : 'a referential'
              } code`}
              testId={`gdsn-export-mappings-simple-element-${elementName}-referential-code-${id}`}
              options={referentialCodeOptions[referential]}
              onSelect={updateReferentialCode}
              option={referentialCode}
              onReset={resetReferentialCode}
              placeholder={
                (referentialCodeOptions[referential] || []).length === 0
                  ? `No ${
                      isDeclinable ? 'expressed in' : 'referential'
                    } code found`
                  : undefined
              }
              disabled={
                (referentialCodeOptions[referential] || []).length === 0
              }
              displayedValueProps={{
                label: isDeclinable ? 'Expressed in' : 'Value',
                value: isDeclinable
                  ? (
                      valueData as {
                        data: string;
                        expressedIn: { code: string };
                      }[]
                    )?.[0]?.expressedIn?.code
                  : (valueData as { code: string })?.code,
              }}
            />
          )}
          {!['boolean', 'entity'].includes(dataTypeOption.id) &&
            !isDeclinable && (
              <div
                className="GDSNExportMappingsSimpleElementItemType__valueData"
                data-testid={`gdsn-export-mappings-simple-element-${elementName}-value-data-${id}`}
              >
                <InputWithLabel
                  inputId={`gdsn-export-mappings-simple-element-${elementName}-value-data-${id}`}
                  label="Value"
                >
                  <Text
                    id={`gdsn-export-mappings-simple-element-${elementName}-value-data-${id}`}
                    value={(valueData as string | number) ?? ''}
                    onChange={updateValueData}
                    type={
                      ['string', 'text'].includes(
                        (dataTypeOption as RegularOption).id
                      )
                        ? 'text'
                        : 'number'
                    }
                  />
                </InputWithLabel>
              </div>
            )}
        </>
      )}
      {valueCastOption?.id === 'lambda' && (
        <InputWithLabel
          inputId={`gdsn-export-mappings-simple-element-${elementName}-lambda-${id}`}
          label="Lambda self, hierarchy, constants:"
        >
          <Textarea
            id={`gdsn-export-mappings-simple-element-${elementName}-lamdba-${id}`}
            value={valueData as string}
            autoresize
            onChange={updateLambda}
            onKeyDown={(e) => {
              if (e.key === 'Enter') e.preventDefault();
            }}
          />
        </InputWithLabel>
      )}
      {valueCastOption?.id === 'function' && (
        <ExportMappingsSimpleSelector
          label="Function name"
          testId={`gdsn-export-mappings-simple-element-${elementName}-function-value-${id}`}
          options={functionValueOptions}
          onSelect={updateFunctionValue}
          option={functionValueOption}
          onReset={resetFunctionValue}
          placeholder={
            functionValueOptions.length === 0
              ? 'No function value found'
              : undefined
          }
          disabled={functionValueOptions.length === 0}
        />
      )}
    </div>
  );
}
