import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { separateActions } from 'utils/redux';

import { HeaderLayout } from '@alkem/react-layout';
import Search from 'components/ui/input/search';

import * as routes from 'constants/routes';

import qs from 'querystringify';
import history from 'utils/history';

import ExportList from '../list';
import ExportFilters from '../filters';

import { searchExportList } from '../actions';

const mapDispatchToProps = {
  searchExportList,
};

export class ExportDashboardView extends PureComponent {
  static propTypes = {
    search: PropTypes.string,
    actions: PropTypes.shape({
      searchExportList: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    search: qs.parse(history.location.search).q || '',
  };

  updateSearchQuery = (e) => {
    this.props.actions.searchExportList(e);
  };

  render() {
    const headerProps = {
      title: 'Export dashboard',
      backHref: routes.home,
      backMessage: 'Back home',
      isTitleSmall: true,
    };

    return (
      <div className="ExportDashboardView__body">
        <HeaderLayout {...headerProps}>
          <Search
            query={this.props.search}
            updateSearchQuery={this.updateSearchQuery}
            wide={true}
            placeholder="GTIN / #Product identifier / :Product key id (comma separated)"
            tooltip={`
              GTIN: just enter the number
              <br />
              Product Key: prefixed with a colon ":" (e.g. :123456)
              <br />
              Product Identifier: prefixed with a hash "#" (e.g. ::123456)
            `}
          />
        </HeaderLayout>
        <div className="container-fluid row">
          <div className="col-md-3">
            <ExportFilters />
          </div>
          <div className="col-md-9">
            <ExportList />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  mapDispatchToProps,
  separateActions
)(ExportDashboardView);
