import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import { SimpleSelect } from '@alkem/react-ui-select';
import { SOURCE_PRODUCTDNA } from 'constants/organization';
import { separateActions } from 'utils/redux';
import { hasFullPower, isAtLeastSupport } from 'utils/user';
import { Link } from 'react-router-dom';

import {
  ORGANIZATION_ROLE_USER,
  ORGANIZATION_ROLE_ADMIN,
  TYPE_ALK_SUPER_ADMIN,
  TYPE_SUPPORT,
  TYPE_PLATFORM_ADMIN,
  TYPE_USER,
} from 'constants/user';

import { accessPolicyEdit } from 'constants/routes';
import { fill } from 'utils/routes';

import './index.scss';
import {
  selectFormFirstname,
  selectFormLastname,
  selectFormTitle,
  selectFormUsername,
  selectFormPhonenumber,
  selectFormOrganization,
  selectFormOrganizationRole,
  selectFormType,
  selectModalUser,
} from '../../selectors/modal';
import { resetUserUpdate } from '../../actions';
import { updateFormInput } from '../../actions/modal';
import TextInput from './text-input';
import { OrganizationsAutocomplete } from './organizations';

const mapStateToProps = (state) => ({
  id: selectModalUser(state).get('id'),
  firstname: selectFormFirstname(state),
  lastname: selectFormLastname(state),
  title: selectFormTitle(state),
  username: selectFormUsername(state),
  phonenumber: selectFormPhonenumber(state),
  type: selectFormType(state),
  organization: selectFormOrganization(state),
  organizationRole: selectFormOrganizationRole(state),
  adminUser: state.user,
  modalUser: selectModalUser(state),
});

const mapDispatchToProps = {
  updateFormInput,
  resetUserUpdate,
};

const rolesOptions = [ORGANIZATION_ROLE_USER, ORGANIZATION_ROLE_ADMIN].map(
  (role) => ({
    id: `role-option-${role}`,
    label: role.toUpperCase(),
    value: role,
  })
);

const typeOptions = [
  { id: 'type-option-user', label: 'User', value: TYPE_USER },
  { id: 'type-option-support', label: 'Support', value: TYPE_SUPPORT },
  { id: 'type-option-pa', label: 'Platform Admin', value: TYPE_PLATFORM_ADMIN },
  {
    id: 'type-option-superadmin',
    label: 'Super Admin',
    value: TYPE_ALK_SUPER_ADMIN,
  },
];

class UserUpdateForm extends PureComponent {
  static propTypes = {
    id: PropTypes.number,
    isAccepted: PropTypes.bool.isRequired,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    username: PropTypes.string,
    title: PropTypes.string,
    phonenumber: PropTypes.string,
    organization: PropTypes.object,
    organizationRole: PropTypes.object,
    type: PropTypes.number,
    actions: PropTypes.shape({
      updateFormInput: PropTypes.func.isRequired,
      resetUserUpdate: PropTypes.func.isRequired,
    }),
    adminUser: PropTypes.object,
    modalUser: PropTypes.object,
  };

  componentWillUnmount() {
    this.props.actions.resetUserUpdate();
  }

  typeToField = (type) => {
    return typeOptions.find((typeObj) => typeObj.value === type);
  };

  onFirstnameChange = (value) => {
    this.props.actions.updateFormInput({
      key: 'firstname',
      value,
    });
  };

  onLastnameChange = (value) => {
    this.props.actions.updateFormInput({
      key: 'lastname',
      value,
    });
  };

  onTitleChange = (value) => {
    this.props.actions.updateFormInput({
      key: 'title',
      value,
    });
  };

  onUsernameChange = (value) => {
    this.props.actions.updateFormInput({
      key: 'username',
      value,
    });
  };

  onPhonenumberChange = (value) => {
    this.props.actions.updateFormInput({
      key: 'phonenumber',
      value,
    });
  };

  onOrganizationSelect = (organization) => {
    this.props.actions.updateFormInput({
      key: 'organization',
      value: fromJS(organization),
    });
  };

  onOrganizationUnselect = () => {
    this.props.actions.updateFormInput({
      key: 'organization',
      value: fromJS({}),
    });
  };

  onOrganizationRoleChange = (role) => {
    this.props.actions.updateFormInput({
      key: 'organizationRole',
      value: fromJS(role),
    });
  };

  onTypeChange = (type) => {
    this.props.actions.updateFormInput({
      key: 'type',
      value: type.value,
    });
  };

  hasAccessPolicy = (type) =>
    type === TYPE_SUPPORT || type === TYPE_PLATFORM_ADMIN;

  formatValueForAutocomplete(value) {
    return value && value.toJS ? [value.toJS()] : [];
  }

  render() {
    const { adminUser, id } = this.props;
    const mandatory =
      this.props.organization.get('source') === SOURCE_PRODUCTDNA;
    return (
      <div className="UserUpdateForm">
        <div className="UserUpdateForm__title">User</div>

        <div className="UserUpdateForm__line">
          <div className="UserUpdateForm__label">ID</div>
          <div className="UserUpdateForm__input">
            <TextInput
              className="UserUpdateForm__textInput form-control"
              value={`${this.props.id}`}
              disabled
            />
          </div>
        </div>

        <div className="UserUpdateForm__line">
          <div className="UserUpdateForm__label">
            Title {mandatory && `(*)`}
          </div>
          <div className="UserUpdateForm__input">
            <TextInput
              className="UserUpdateForm__textInput form-control"
              placeholder="Title"
              value={this.props.title}
              onChange={this.onTitleChange}
            />
          </div>
        </div>

        <div className="UserUpdateForm__line">
          <div className="UserUpdateForm__label">
            Firstname {mandatory && `(*)`}
          </div>
          <div className="UserUpdateForm__input">
            <TextInput
              className="UserUpdateForm__textInput form-control"
              placeholder="Firstname"
              value={this.props.firstname}
              onChange={this.onFirstnameChange}
            />
          </div>
        </div>

        <div className="UserUpdateForm__line">
          <div className="UserUpdateForm__label">
            Lastname {mandatory && `(*)`}
          </div>
          <div className="UserUpdateForm__input">
            <TextInput
              className="UserUpdateForm__textInput form-control"
              placeholder="Lastname"
              value={this.props.lastname}
              onChange={this.onLastnameChange}
            />
          </div>
        </div>

        <div className="UserUpdateForm__line">
          <div className="UserUpdateForm__label">
            Username {mandatory && `(*)`}
          </div>
          <div className="UserUpdateForm__input">
            <TextInput
              className="UserUpdateForm__textInput form-control"
              placeholder="Username"
              value={this.props.username}
              onChange={this.onUsernameChange}
              disabled={isAtLeastSupport(this.props.modalUser)}
            />
          </div>
        </div>

        <div className="UserUpdateForm__line">
          <div className="UserUpdateForm__label">
            Phone number {mandatory && `(*)`}
          </div>
          <div className="UserUpdateForm__input">
            <TextInput
              className="UserUpdateForm__textInput form-control"
              placeholder="Phone"
              value={this.props.phonenumber}
              onChange={this.onPhonenumberChange}
            />
          </div>
        </div>

        <div className="UserUpdateForm__title">Organization</div>
        <div className="UserUpdateForm__line">
          <div className="UserUpdateForm__label">Belongs to</div>
          <div className="UserUpdateForm__input">
            <OrganizationsAutocomplete
              id="user-update-organization-input"
              value={this.formatValueForAutocomplete(this.props.organization)}
              onSelect={this.onOrganizationSelect}
              onUnselect={this.onOrganizationUnselect}
              searchOnClick
            />
          </div>
        </div>

        <div className="UserUpdateForm__line">
          <div className="UserUpdateForm__label">Role</div>
          <div className="UserUpdateForm__input">
            <SimpleSelect
              id="roles"
              options={rolesOptions}
              value={this.props.organizationRole.toJS()}
              onSelect={this.onOrganizationRoleChange}
              disabled={!this.props.isAccepted}
              autoSize
            />
          </div>
        </div>

        {hasFullPower(adminUser) && (
          <div>
            <div className="UserUpdateForm__title">Platform</div>
            <div className="UserUpdateForm__line">
              <div className="UserUpdateForm__label">Type</div>
              <div className="UserUpdateForm__input">
                <SimpleSelect
                  id="type"
                  options={typeOptions}
                  value={this.typeToField(this.props.type)}
                  onSelect={this.onTypeChange}
                  autoSize
                />
              </div>
            </div>
            {this.hasAccessPolicy(this.props.type) && (
              <div className="UserUpdateForm__line">
                <div className="UserUpdateForm__label">Policies</div>
                <div className="UserUpdateForm__input">
                  <Link to={fill(accessPolicyEdit, id)}>
                    Configure access policy
                  </Link>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(UserUpdateForm);
