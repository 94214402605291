import { Application } from '@alkem/sdk-dashboard';
import {
  APPLICATION_TYPE_UNKNOWN,
  APPLICATION_TYPES,
} from 'constants/application';

export default function OrganizationApplicationItemsHeader({
  application,
}: {
  application: Application;
}) {
  return (
    <li className="OrganizationApplications__header">
      {application.type === APPLICATION_TYPE_UNKNOWN.key
        ? 'Others'
        : APPLICATION_TYPES[application.type].label}
    </li>
  );
}
