import { AceEditorProps, CodeEditor } from 'components/code-editor';
import { useEffect, useState } from 'react';
import './index.scss';

type Props = {
  id: string;
  defaultValue: any;
  onChange?: (json: any) => void;
};

export const JsonText = ({
  id,
  defaultValue,
  onChange,
  width = '100%',
  height = '200px',
  ...props
}: Props & Omit<AceEditorProps, 'defaultValue'>) => {
  const [value, setValue] = useState('');
  useEffect(() => {
    setValue(JSON.stringify(defaultValue, null, 2));
  }, [defaultValue]);
  const _onChange = (v) => {
    setValue(v);
    try {
      onChange?.(JSON.parse(v));
    } catch (e) {
      // do nothing
    }
  };
  return (
    <div className="JsonText">
      <CodeEditor
        name={id}
        value={value}
        className="JsonText__editor"
        showGutter={false}
        showPrintMargin={false}
        onChange={_onChange}
        editorProps={{ $blockScrolling: Infinity }}
        mode="json"
        width={width}
        height={height}
        {...props}
      />
    </div>
  );
};
