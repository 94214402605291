import { useState, useCallback, useEffect } from 'react';
import InputWithLabel from 'components/input-with-label';
import { SimpleSelect } from '@alkem/react-ui-select';
import { Text, Radio } from '@alkem/react-ui-inputs';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectCustomParseOptions,
  selectListFieldOptions,
} from '../../../selectors';
import { GET_FIELD_LIST, SET_EXPORT_MAPPING } from '../../../actions/constants';

import './list-element-item-type.scss';
import {
  ExportMapping,
  ExportMappingPayload,
  FieldOption,
  Option,
} from '../../../types';

type ItemTypeOption = {
  id: string;
  label: string;
};

const itemTypeOptions: ItemTypeOption[] = [
  { id: 'gdsn_node', label: 'GDSN node' },
  { id: 'list', label: 'SXM list field' },
  { id: 'custom_parse', label: 'Custom parse' },
];

const getInitialItemType = (payload: ExportMappingPayload) => {
  if (!payload) return undefined;

  const { cast, custom_parse } = payload;
  if (!cast && !!custom_parse) {
    return itemTypeOptions[2];
  }
  if (cast) {
    return itemTypeOptions.find(({ id }) => id === cast);
  }
  return undefined;
};

interface Props {
  elementName: string;
  exportMapping: ExportMapping;
}
export function ExportMappingsListElementItemType({
  elementName,
  exportMapping,
}: Props) {
  const dispatch = useDispatch();
  const listFieldOptions = useSelector(selectListFieldOptions);
  const customParseOptions = useSelector(selectCustomParseOptions);
  const [itemTypeOption, setItemTypeOption] = useState<
    ItemTypeOption | undefined
  >(getInitialItemType(exportMapping.payload));
  const [category, setCategory] = useState(
    exportMapping.payload.use_su ? 'sharingUnit' : 'consumerUnit'
  );
  const [fieldSearchValue, setFieldSearchValue] = useState('');
  const [listFieldOption, setListFieldOption] = useState<
    FieldOption | undefined
  >(undefined);
  const [field, setField] = useState(exportMapping.payload.field || '');
  const [customParseOption, setCustomParseOption] = useState<
    Option | undefined
  >(
    customParseOptions.find(
      (customParseOption) =>
        customParseOption.label === exportMapping.payload.custom_parse
    )
  );

  const dispatchGetFieldList = useCallback(
    ({
      entityType,
      search,
    }: {
      entityType?: string;
      search?: string;
    }): void => {
      dispatch({
        type: GET_FIELD_LIST,
        entityType: entityType || category,
        search: search ?? fieldSearchValue,
        mustGetListFields: true,
      });
    },
    [dispatch, category, fieldSearchValue]
  );

  useEffect(() => {
    dispatchGetFieldList({});
  }, []);

  const dispatchSetExportMapping = useCallback(
    ({ cast, field, custom_parse, use_su }: ExportMappingPayload) => {
      dispatch({
        type: SET_EXPORT_MAPPING,
        payload: {
          exportMapping: {
            ...exportMapping,
            payload: {
              ...exportMapping.payload,
              cast,
              field,
              custom_parse,
              use_su,
            },
          },
        },
      });
    },
    [dispatch, exportMapping]
  );

  const clearFieldSelectorFields = () => {
    setFieldSearchValue('');
    setListFieldOption(undefined);
    setField('');
  };

  const updateItemType = (value: ItemTypeOption) => {
    setItemTypeOption(value);
    clearFieldSelectorFields();
    setCustomParseOption(undefined);
    let cast: string | undefined = value.id;
    if (cast === 'custom_parse') {
      cast = undefined;
    }
    dispatchSetExportMapping({ cast });
  };

  const resetItemType = () => {
    setItemTypeOption(undefined);
    clearFieldSelectorFields();
    setCustomParseOption(undefined);
    dispatchSetExportMapping({});
  };

  const updateCategory = (event: { target: { value: string } }) => {
    const { value } = event.target;
    setCategory(value);
    dispatchGetFieldList({ entityType: value, search: '' });
    clearFieldSelectorFields();
    dispatchSetExportMapping({
      use_su: value === 'sharingUnit' || undefined,
    });
  };

  const updateSearchValue = useCallback(
    (event: { target: { value: string } }): void => {
      const { value } = event.target;
      setFieldSearchValue(value);
      dispatchGetFieldList({ search: value });
    },
    [setFieldSearchValue, category]
  );

  const updateField = (value: FieldOption) => {
    const { cast, use_su } = exportMapping.payload;
    setListFieldOption(value);
    setField(value.label);
    dispatchSetExportMapping({
      cast,
      field: value.label,
      use_su,
    });
  };

  const resetField = () => {
    const { cast, use_su } = exportMapping.payload;
    setListFieldOption(undefined);
    setField('');
    dispatchSetExportMapping({
      cast,
      use_su,
    });
  };

  const updateCustomParse = (value: { id: number; label: string }) => {
    const { cast } = exportMapping.payload;
    setCustomParseOption(value);
    dispatchSetExportMapping({ cast, custom_parse: value.label || undefined });
  };

  const resetCustomParse = () => {
    const { cast } = exportMapping.payload;
    setCustomParseOption(undefined);
    dispatchSetExportMapping({ cast });
  };

  const { id } = exportMapping;

  return (
    <div className="GDSNExportMappingsListElementItemType">
      <InputWithLabel
        inputId={`gdsn-export-mappings-list-element-${elementName}-type-property-${id}`}
        label="Type of mapping"
      >
        <div
          className="GDSNExportMappingsListElementItemType__typeProperty"
          data-testid={`gdsn-export-mappings-list-element-${elementName}-type-property-${id}`}
        >
          <SimpleSelect
            id={`gdsn-export-mappings-list-element-${elementName}-type-property-${id}`}
            options={itemTypeOptions}
            onSelect={updateItemType}
            value={itemTypeOption}
            placeholder="Select..."
          />
          <span
            className="GDSNExportMappingsListElementItemType__reset-button"
            onClick={resetItemType}
          >
            <i className="mdi mdi-close" />
          </span>
        </div>
      </InputWithLabel>
      {itemTypeOption?.id === 'list' && (
        <>
          <div
            data-testid={`gdsn-export-mappings-list-element-${elementName}-category-property-${id}`}
          >
            <InputWithLabel
              inputId={`gdsn-export-mappings-list-element-${elementName}-category-property-${id}`}
              label="Category"
            >
              <Radio
                id={`gdsn-export-mappings-list-element-${elementName}-category-property-${id}`}
                value={category}
                options={[
                  { label: 'Consumer unit', value: 'consumerUnit' },
                  { label: 'Sharing unit', value: 'sharingUnit' },
                ]}
                onChange={updateCategory}
              />
            </InputWithLabel>
          </div>
          <InputWithLabel
            inputId={`gdsn-export-mappings-list-element-${elementName}-field-selector-${id}`}
            label="Select a field"
          >
            <div
              className="GDSNExportMappingsListElementItemType__fieldSelector"
              data-testid={`gdsn-export-mappings-list-element-${elementName}-field-selector-${id}`}
            >
              <Text
                className="GDSNExportMappingsListElementItemType__fieldSelector__search"
                id="gdsn-export-field-search"
                placeholder="Filter fields by name or tag"
                onChange={updateSearchValue}
                value={fieldSearchValue}
              />
              <div className="GDSNExportMappingsListElementItemType__fieldSelector__simpleSelect">
                <SimpleSelect
                  id={`gdsn-export-mappings-list-element-${elementName}-field-selector-${id}`}
                  options={listFieldOptions}
                  onSelect={updateField}
                  value={listFieldOption}
                  placeholder={
                    listFieldOptions.length === 0
                      ? 'No field found'
                      : 'Select...'
                  }
                  disabled={listFieldOptions.length === 0}
                />
                <span
                  className="GDSNExportMappingsListElementItemType__reset-button"
                  onClick={resetField}
                >
                  <i className="mdi mdi-close" />
                </span>
              </div>
            </div>
          </InputWithLabel>
          <div
            data-testid={`gdsn-export-mappings-list-element-${elementName}-field-${id}`}
          >
            <InputWithLabel
              inputId={`gdsn-export-mappings-list-element-${elementName}-field-${id}`}
              label="Field"
            >
              <Text
                className="GDSNExportMappingsListElementItemType__field__text"
                id={`gdsn-export-mappings-list-element-${elementName}-field-${id}`}
                value={field}
                onChange={() => {}}
                disabled
              />
            </InputWithLabel>
          </div>
        </>
      )}
      {itemTypeOption?.id === 'custom_parse' && (
        <>
          <div
            data-testid={`gdsn-export-mappings-list-element-${elementName}-custom-parse-selector-${id}`}
          >
            <InputWithLabel
              inputId={`gdsn-export-mappings-list-element-${elementName}-custom-parse-selector-${id}`}
              label="Custom parse"
            >
              <div className="GDSNExportMappingsListElementItemType__customParseSelector__simpleSelect">
                <SimpleSelect
                  id={`gdsn-export-mappings-list-element-${elementName}-custom-parse-selector-${id}`}
                  options={customParseOptions}
                  onSelect={updateCustomParse}
                  value={customParseOption}
                  placeholder="Select..."
                />
                <span
                  className="GDSNExportMappingsListElementItemType__reset-button"
                  onClick={resetCustomParse}
                >
                  <i className="mdi mdi-close" />
                </span>
              </div>
            </InputWithLabel>
          </div>
        </>
      )}
    </div>
  );
}
