export const FIELD_SUGGESTION_RECEIVE_LIST = 'FIELD_SUGGESTION_RECEIVE_LIST';
export const FIELD_SUGGESTION_ADD_FILTER = 'FIELD_SUGGESTION_ADD_FILTER';
export const FIELD_SUGGESTION_REMOVE_FILTER = 'FIELD_SUGGESTION_REMOVE_FILTER';
export const FIELD_SUGGESTION_CLEAR_FILTERS = 'FIELD_SUGGESTION_CLEAR_FILTERS';
export const FIELD_SUGGESTION_NEXT_PAGE = 'FIELD_SUGGESTION_NEXT_PAGE';
export const FIELD_SUGGESTION_PREVIOUS_PAGE = 'FIELD_SUGGESTION_PREVIOUS_PAGE';
export const FIELD_SUGGESTION_CHANGE_LIMIT = 'FIELD_SUGGESTION_CHANGE_LIMIT';
export const FIELD_SUGGESTION_CHANGE_SORTING =
  'FIELD_SUGGESTION_CHANGE_SORTING';
export const FIELD_SUGGESTION_CHECK_ROW = 'FIELD_SUGGESTION_CHECK_ROW';
export const FIELD_SUGGESTION_CHECK_ALL_ROWS =
  'FIELD_SUGGESTION_CHECK_ALL_ROWS';
export const FIELD_SUGGESTION_RECEIVE_AGGREGATIONS =
  'FIELD_SUGGESTION_RECEIVE_AGGREGATIONS';
export const FIELD_SUGGESTION_BULK_VALIDATION =
  'FIELD_SUGGESTION_BULK_VALIDATION';
export const FIELD_SUGGESTION_VALIDATION_PROGRESS =
  'FIELD_SUGGESTION_VALIDATION_PROGRESS';
export const FIELD_SUGGESTION_VALIDATION_COMPLETE =
  'FIELD_SUGGESTION_VALIDATION_COMPLETE';
export const FIELD_SUGGESTION_SINGLE_VALIDATION =
  'FIELD_SUGGESTION_SINGLE_VALIDATION';
export const FIELD_SUGGESTION_BULK_EDITION = 'FIELD_SUGGESTION_BULK_EDITION';
export const FIELD_SUGGESTION_EDITION_PROGRESS =
  'FIELD_SUGGESTION_EDITION_PROGRESS';
export const FIELD_SUGGESTION_EDITION_COMPLETE =
  'FIELD_SUGGESTION_EDITION_COMPLETE';
export const FIELD_SUGGESTION_SINGLE_EDITION =
  'FIELD_SUGGESTION_SINGLE_EDITION';
export const FIELD_SUGGESTION_OPEN_MODAL = 'FIELD_SUGGESTION_OPEN_MODAL';
export const FIELD_SUGGESTION_CLOSE_MODAL = 'FIELD_SUGGESTION_CLOSE_MODAL';
export const FIELD_SUGGESTION_UPDATE_SEARCH = 'FIELD_SUGGESTION_UPDATE_SEARCH';
export const RECEIVE_KIND_LEAVES = 'RECEIVE_KIND_LEAVES';
export const RECEIVE_SEGMENT_HIERARCHY = 'RECEIVE_SEGMENT_HIERARCHY';
export const TOGGLE_SHOW_HIERARCHY_CHILDREN = 'TOGGLE_SHOW_HIERARCHY_CHILDREN';
