import { Map } from 'immutable';
import { createSelector } from 'reselect';
import { MODULE_REDUCER_KEY } from 'constants/reducer';

import { ENDPOINT_LIST_STORE_KEY } from '../constants/reducers';

export function selectState(state) {
  return state[MODULE_REDUCER_KEY][ENDPOINT_LIST_STORE_KEY];
}

export function selectList(state) {
  return selectState(state).list;
}

export function selectFiles(state) {
  return selectState(state).files;
}

export function selectPagination(state) {
  return selectState(state).pagination;
}

export function selectModalEndpoint(state) {
  return selectState(state).modal.endpoint;
}

export function selectIsModalOpen(state) {
  return selectState(state).modal.isOpen;
}

export function selectRawFilters(state) {
  return selectState(state).filtersRaw;
}

export const selectFilters = createSelector(
  selectState,
  (endpointState) => endpointState.filters
);

export const selectedOrganizations = createSelector(
  selectFilters,
  (filterState) =>
    filterState.organizations.reduce(
      (prev, curr) => prev.set(curr.value, true),
      Map()
    )
);
