import { Spinner } from '@alkem/react-ui-spinner';
import * as routes from 'constants/routes';
import CoreLayout from 'layouts/CoreLayout';
import { hasAccess } from 'modules/access-policy/common/utils';
import { CommandPalette } from 'modules/command-palette';
import { loadProfile } from 'modules/auth/actions';
import { bool, elementType, func, object } from 'prop-types';
import React, { PureComponent, useMemo } from 'react';
import { map } from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { getUserType } from 'utils/user';
import {
  selectIsAuthenticated,
  selectIsAuthPending,
  selectIsFetchingProfile,
  selectPermissions,
} from '../reducer';
import './ProtectedRoute.scss';

const mapStateToProps = createStructuredSelector({
  isAuthenticated: selectIsAuthenticated,
  isAuthPending: selectIsAuthPending,
  isFetchingProfile: selectIsFetchingProfile,
  user: (state) => state.user,
  userPermissions: selectPermissions,
});

const mapDispatchToProps = {
  loadProfile,
};

const withRestriction = (
  View,
  { accessPolicy, bypassAccessPolicy = false }
) => {
  class ProtectedRoute extends PureComponent {
    static propTypes = {
      isAuthenticated: bool.isRequired,
      isAuthPending: bool.isRequired,
      isFetchingProfile: bool.isRequired,
      user: map,
      userPermissions: object,
      location: object,
      loadProfile: func.isRequired,
    };

    componentDidMount() {
      if (this.props.user.isEmpty()) {
        this.props.loadProfile();
      }
    }

    render() {
      const {
        isAuthenticated,
        isAuthPending,
        isFetchingProfile,
        user,
        userPermissions,
        loadProfile: ignore,
        ...rest
      } = this.props;
      if (isAuthPending || isFetchingProfile) {
        return (
          <center style={{ paddingTop: '100px' }}>
            <Spinner loading big />
          </center>
        );
      }
      if (isAuthenticated) {
        if (
          bypassAccessPolicy ||
          hasAccess(getUserType(user), userPermissions, accessPolicy)
        ) {
          return <View {...rest} />;
        }
        return (
          <div className="ProtectedRoute__accessDenied">
            <h1>Access denied</h1>
          </div>
        );
      }
      return (
        <Redirect
          to={{
            pathname: routes.login,
            state: { from: rest.location },
          }}
        />
      );
    }
  }
  return connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
};

const ProtectedRoute = ({
  isPublic,
  component,
  accessPolicy,
  bypassAccessPolicy,
  ...props
}) => {
  const MemoizedComponent = useMemo(
    () =>
      isPublic
        ? component
        : withRestriction(component, {
            accessPolicy,
            bypassAccessPolicy,
          }),
    [component, accessPolicy, bypassAccessPolicy, isPublic]
  );

  return (
    <Route
      render={(props) => (
        <CoreLayout isPublic={isPublic}>
          <MemoizedComponent {...props} />
          <CommandPalette />
        </CoreLayout>
      )}
      {...props}
    />
  );
};

ProtectedRoute.propTypes = {
  isPublic: bool,
  component: elementType,
  accessPolicy: object,
  bypassAccessPolicy: bool,
};

export default ProtectedRoute;
