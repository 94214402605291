import { Message } from '../types';
import { EdaRmqReplayerQueueErrorContext } from './error-context';
import './error-message.scss';
import { EdaRmqReplayerQueueInvalidMessage } from './invalid-message';

export function EdaRmqReplayerQueueErrorMessage({
  message,
}: {
  message: Message;
}) {
  return (
    <div data-testid="queue-error-message">
      <EdaRmqReplayerQueueErrorContext
        reason={message.first_death_reason}
        context={message.context}
      />
      <div className="EdaRmqReplayerQueueErrorMessage">
        {message.context.exc && (
          <EdaRmqReplayerQueueInvalidMessage context={message.context} />
        )}
        {!message.context.exc && (
          <pre>{JSON.stringify(message.event, null, 2)}</pre>
        )}
      </div>
    </div>
  );
}
