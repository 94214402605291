import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { separateActions } from 'utils/redux';
import { nextPage, previousPage } from '../../actions/users';
import {
  selectTotalUsers,
  selectTotalPages,
  selectCurrentPage,
} from '../../selectors/users';

import './index.scss';

const mapStateToProps = (state) => ({
  totalUsers: selectTotalUsers(state),
  totalPages: selectTotalPages(state),
  currentPage: selectCurrentPage(state),
});

const mapDispatchToProps = {
  nextPage,
  previousPage,
};

export class OrganizationPageUsers extends PureComponent {
  static propTypes = {
    totalUsers: PropTypes.number,
    totalPages: PropTypes.number,
    currentPage: PropTypes.number,
    actions: PropTypes.shape({
      nextPage: PropTypes.func.isRequired,
      previousPage: PropTypes.func.isRequired,
    }).isRequired,
  };

  onNextClick = () => {
    const { totalPages, currentPage } = this.props;
    if (totalPages > currentPage) {
      this.props.actions.nextPage();
    }
  };

  onPreviousClick = () => {
    const { currentPage } = this.props;
    if (currentPage > 1) {
      this.props.actions.previousPage();
    }
  };

  render() {
    const { totalUsers, totalPages, currentPage } = this.props;
    if (!totalUsers) {
      return null;
    }
    const previousClasses = {
      OrganizationPageUserPagination__button: true,
      'OrganizationPageUserPagination__button--disabled': currentPage <= 1,
    };
    const nextClasses = {
      OrganizationPageUserPagination__button: true,
      'OrganizationPageUserPagination__button--disabled':
        currentPage >= totalPages,
    };

    return (
      <div className="OrganizationPageUserPagination">
        <div
          className={classNames(previousClasses)}
          onClick={this.onPreviousClick}
        >
          <i className="mdi mdi-chevron-left " />
        </div>
        {currentPage} / {totalPages} ({totalUsers} users)
        <div className={classNames(nextClasses)} onClick={this.onNextClick}>
          <i className="mdi mdi-chevron-right" />
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(OrganizationPageUsers);
