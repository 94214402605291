export const REDUCER_KEY = 'gln-claim';

export const STATUS_CREATED = { id: 0, label: 'CREATED' };
export const STATUS_ACCEPTED = { id: 1, label: 'ACCEPTED' };
export const STATUS_REFUSED = { id: 2, label: 'REFUSED' };
export const STATUS_ERROR = { id: 3, label: 'ERROR' };
export const STATUS_SKIPPED = { id: 4, label: 'SKIPPED' };

export const STATUS_MAPPING = {
  [STATUS_CREATED.id]: STATUS_CREATED,
  [STATUS_ACCEPTED.id]: STATUS_ACCEPTED,
  [STATUS_REFUSED.id]: STATUS_REFUSED,
  [STATUS_ERROR.id]: STATUS_ERROR,
  [STATUS_SKIPPED.id]: STATUS_SKIPPED,
};
