import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { isEmpty, flatten } from 'lodash';

import Viz from 'viz.js';

import { Button } from '@alkem/react-ui-button';
import { Select } from '@alkem/react-ui-select';

import { getIn } from 'utils/immutable';

import D3Graph from './d3';
import SidePanel from './side-panel';
import { selectNodeIds, toDot } from './utils';

import './graph.scss';

const options = [
  { key: 'important', label: 'Important nodes' },
  { key: 'timings', label: 'Timings' },
  { key: 'disabled', label: '(Disabled)' },
];

class Graph extends PureComponent {
  state = {
    svg: '',
    dot: '',
    selectedNode: {},
    hiddenNodes: [],
    activeNodes: [],
    erroredNodes: [],
    sidePanelCollapsed: true,
    nodeDependencies: {},
    highlightedOption: options[0].key,
    err: null,
    nodeGroup: Map(),
  };

  componentDidMount() {
    this.updateGraph();
  }

  componentDidUpdate(prevProps) {
    this.updateGraph(prevProps);
  }

  updateGraph(prevProps = {}) {
    const { graph, subGraphs, runResults } = this.props;
    if (
      graph !== prevProps.graph ||
      subGraphs !== prevProps.subGraphs ||
      runResults !== prevProps.runResults
    ) {
      let updatedState = {
        importantNodes: this.getImportantNodes(graph, subGraphs),
        erroredNodes: this.getErroredNodes(graph, subGraphs, runResults),
      };

      if (graph !== prevProps.graph) {
        const dot = toDot(graph, subGraphs, []);
        let svg;
        try {
          svg = Viz(dot);
        } catch (err) {
          this.setState({ dot, err }); // eslint-disable-line
          return;
        }
        const nodeDependencies = this.getNodeDependencies(graph, subGraphs);
        updatedState = {
          svg,
          dot,
          err: null,
          selectedNode: {},
          activeNodes: [],
          hiddenNodes: [],
          nodeDependencies,
        };
      } else if (subGraphs !== prevProps.subGraphs) {
        const { hiddenNodes } = this.state;
        const dot = toDot(graph, subGraphs, hiddenNodes);
        let svg;
        try {
          svg = Viz(dot);
        } catch (err) {
          this.setState({ dot, err }); // eslint-disable-line
          return;
        }
        const nodeDependencies = this.getNodeDependencies(graph, subGraphs);
        updatedState = {
          svg,
          dot,
          nodeDependencies,
          err: null,
        };
      }

      this.setState(updatedState); // eslint-disable-line
    }
  }

  onAddToGroup = (nodeIds, group) => {
    let { nodeGroup } = this.state;
    nodeGroup = nodeGroup.withMutations((ng) => {
      nodeIds.forEach((nodeId) => {
        ng.set(nodeId, group);
      });
    });
    this.setState({ nodeGroup }, this.updateSvg);
  };

  onDissolveGroup = (groupName) => {
    let { nodeGroup } = this.state;
    nodeGroup = nodeGroup.filter((g) => g !== groupName);
    this.setState({ nodeGroup }, this.updateSvg);
  };

  onTriggerNodeVisibility = (nodeId) => {
    const { graph, subGraphs } = this.props;
    this.setState((prevState) => {
      const { nodeGroup } = prevState;
      let hiddenNodes = [...prevState.hiddenNodes];

      if (hiddenNodes.includes(nodeId)) {
        hiddenNodes = hiddenNodes.filter((nId) => nId !== nodeId);
      } else {
        hiddenNodes.push(nodeId);
      }

      const dot = toDot(graph, subGraphs, hiddenNodes, nodeGroup.toJS());
      let svg;
      try {
        svg = Viz(dot);
      } catch (err) {
        this.setState({ dot, err });
      }
      return { svg, dot, hiddenNodes, err: null };
    });
  };

  onSidePanelCollapse = (collapsed) => {
    this.setState({ sidePanelCollapsed: collapsed });
  };

  onSelectNode = (nodeId) => {
    const { graph } = this.props;
    const { selectedNode, sidePanelCollapsed, nodeGroup } = this.state;

    let node;
    if (nodeId && nodeId.startsWith('group_')) {
      node = {
        id: nodeId.replace('group_', ''),
        type: 'group',
      };
    } else {
      node = graph.nodes.find((n) => n.id === nodeId);
      if (!node) {
        // Try to find it as a graph
        node = graph.nodes.find(
          (n) => `${n.id}_inputs` === nodeId || `${n.id}_outputs` === nodeId
        );
      }
    }

    let activeNodes = [];
    if (node) {
      activeNodes = selectNodeIds(nodeId, graph, nodeGroup);
    }

    let collapsed = sidePanelCollapsed;
    if (!selectedNode.id && !node) {
      collapsed = true;
    } else if (selectedNode.id && node && selectedNode.id === node.id) {
      collapsed = false;
    }

    this.setState({
      selectedNode: node || {},
      activeNodes: [...activeNodes],
      sidePanelCollapsed: collapsed,
    });
  };

  onHideUnrelatedNodes = (nodeId) => {
    const { graph, subGraphs } = this.props;
    const { nodeGroup } = this.state;

    const node = graph.nodes.find((n) => n.id === nodeId);
    let activeNodes = [];
    if (node) {
      activeNodes = [...selectNodeIds(node.id, graph, nodeGroup)];
    }

    const hiddenNodes = graph.nodes
      .filter((n) => !activeNodes.includes(n.id))
      .map((n) => n.id);

    const dot = toDot(graph, subGraphs, hiddenNodes, nodeGroup.toJS());
    let svg;
    try {
      svg = Viz(dot);
    } catch (err) {
      this.setState({ dot, err });
    }
    this.setState({ hiddenNodes, dot, svg, err: null });
  };

  onSelectOption = (obj) =>
    this.setState((prevState) => ({
      highlightedOption:
        prevState.highlightedOption !== obj.key ? obj.key : options[0].key,
    }));

  getNodeDependencies(graph, subGraphs) {
    const reduce = (g) =>
      g.nodes
        ? g.nodes.reduce((acc, n) => {
            acc[n.id] = (n.dependencies || []).map((d) => d.name);
            return acc;
          }, {})
        : {};
    return Object.assign(
      reduce(graph),
      ...Object.values(subGraphs).map(reduce)
    );
  }

  getImportantNodes(graph, subGraphs) {
    const selectImportantNodes = (g) =>
      g.nodes
        ? (g.nodes.filter((n) => n.isImportant) || []).map((n) => n.id)
        : [];

    return flatten([
      selectImportantNodes(graph),
      Object.values(subGraphs).map(selectImportantNodes),
    ]);
  }

  getErroredNodes(graph, subGraphs, runResults) {
    if (!graph || !graph.nodes || !runResults || !runResults.inspectors) {
      return [];
    }

    let nodes = graph.nodes.concat(
      flatten(Object.values(subGraphs).map((g) => g.nodes))
    );

    // Filter the node that return an error
    nodes = nodes.filter(
      (n) =>
        n.outputs &&
        n.outputs.length > 0 &&
        ['error', '[]error'].includes(n.outputs[n.outputs.length - 1].type)
    );

    const spy = getIn(runResults, ['inspectors', 'spy']) || {};

    return nodes
      .filter(
        (n) => spy[n.id] && !isEmpty(spy[n.id].outputs[n.outputs.length - 1])
      )
      .map((n) => n.id);
  }

  updateSvg = () => {
    const { graph, subGraphs } = this.props;
    const { hiddenNodes, nodeGroup } = this.state;
    const dot = toDot(graph, subGraphs, hiddenNodes, nodeGroup.toJS());
    let svg;
    try {
      svg = Viz(dot);
    } catch (err) {
      this.setState({ dot, err });
    }
    this.setState({ dot, svg });
  };

  renderError() {
    const { err, dot } = this.state;
    if (err.message) {
      return (
        <>
          <div>{err.message}</div>
          <div>
            <pre>{dot}</pre>
          </div>
        </>
      );
    }

    return <>{err.toString()}</>;
  }

  render() {
    const { graph, reloadGraph, runResults, onLoadSubgraph } = this.props;
    const {
      svg,
      selectedNode,
      activeNodes,
      hiddenNodes,
      sidePanelCollapsed,
      nodeDependencies,
      highlightedOption,
      importantNodes,
      err,
      nodeGroup,
      erroredNodes,
    } = this.state;

    return (
      <div>
        <div className="GraphActions">
          <Button onClick={reloadGraph}>
            <i className="mdi mdi-refresh" />
          </Button>
          <span>Highlight:</span>
          <Select
            id="Graph-select-option"
            options={options}
            values={[options.find((o) => o.key === highlightedOption)]}
            onValueAdd={this.onSelectOption}
            onValueDelete={this.onSelectOption}
          />
          {highlightedOption === 'timings' && (
            <div className="flex flex-align-items--center">
              Slow
              <div className="GraphActions__timingGradientLegend" />
              Fast
            </div>
          )}
          {highlightedOption === 'important' && (
            <div className="GraphActions__legend flex flex-align-items--center">
              <div className="flex flex-align-items--center">
                <div className="GraphActions__colorLegend GraphActions__colorLegend--fabf2d" />
                Repository
              </div>
              <div className="flex flex-align-items--center">
                <div className="GraphActions__colorLegend GraphActions__colorLegend--ef7e1d" />
                Service client
              </div>
              <div className="flex flex-align-items--center">
                <div className="GraphActions__colorLegend GraphActions__colorLegend--4cb963" />
                Important
              </div>
            </div>
          )}
        </div>
        {err ? (
          <div className="GraphDotError">{this.renderError(err)}</div>
        ) : (
          <D3Graph
            id="product-graph"
            svg={svg}
            nodeClicked={this.onSelectNode}
            selectedNode={selectedNode}
            activeNodes={activeNodes}
            result={runResults}
            nodeDependencies={nodeDependencies}
            highlightedOption={highlightedOption}
            importantNodes={importantNodes}
            erroredNodes={erroredNodes}
          />
        )}
        <SidePanel
          graph={graph}
          hiddenNodes={hiddenNodes}
          selectedNode={selectedNode || graph}
          runResults={runResults}
          collapsed={sidePanelCollapsed}
          onCollapse={this.onSidePanelCollapse}
          onLoadSubgraph={onLoadSubgraph}
          onTriggerNodeVisibility={this.onTriggerNodeVisibility}
          onSelectNode={this.onSelectNode}
          onHideUnrelatedNodes={this.onHideUnrelatedNodes}
          onAddToGroup={this.onAddToGroup}
          onDissolveGroup={this.onDissolveGroup}
          groups={nodeGroup.valueSeq().toSet().toArray()}
        />
      </div>
    );
  }
}

Graph.propTypes = {
  graph: PropTypes.object.isRequired,
  subGraphs: PropTypes.object.isRequired,
  reloadGraph: PropTypes.func.isRequired,
  onLoadSubgraph: PropTypes.func.isRequired,
  runResults: PropTypes.object.isRequired,
};

export default Graph;
