export const MODULE_STORE_KEY = 'retailerPictureComparator';

export function selectState(state) {
  return state[MODULE_STORE_KEY];
}

export function selectVersions(state) {
  return selectState(state).versions;
}

export function selectTotalResults(state) {
  return selectState(state).totalResults;
}

export function selectLimit(state) {
  return selectState(state).limit;
}

export function selectCurrentPage(state) {
  return selectState(state).currentPage;
}
