import { combineReducers } from 'redux';

import {
  USER_LIST_USER_STORE_KEY,
  USER_LIST_ORGANIZATION_STORE_KEY,
} from '../constants/reducers';
import users from './users';
import organizations from './organizations';

export default combineReducers({
  [USER_LIST_USER_STORE_KEY]: users,
  [USER_LIST_ORGANIZATION_STORE_KEY]: organizations,
});
