import { Radio, Text } from '@alkem/react-ui-inputs';
import { ReferentialAutocomplete } from 'components/autocomplete';
import InputWithLabel from 'components/input-with-label';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GET_REFERENTIAL_CODE_LIST } from '../../../../actions/constants';
import { DECLINABLE_TYPES } from '../../../../constants';
import { selectReferentialCodeOptions } from '../../../../selectors';
import {
  ExportMapping,
  ExportMappingPayload,
  RegularOption,
} from '../../../../types';
import { ExportMappingsSimpleSelector } from '../../simple-selector';
import { dataTypeOptions } from '../utils';
import { ExportMappingsSimpleElementItemValueTypeValueSetter } from './value-setter';

const getInitialDataType = (payload: ExportMappingPayload) => {
  const { cast } = payload;
  if (!cast) return undefined;

  return dataTypeOptions.find((dataTypeOption) => dataTypeOption.id === cast);
};

interface Props {
  elementName: string;
  exportMapping: ExportMapping;
  dispatchSetExportMapping: ({
    cast,
    field,
    use_su,
    referential,
    is_declinable,
    value,
    custom_parse,
  }: ExportMappingPayload) => void;
}
export function ExportMappingsSimpleElementItemValueTypePropsSelector({
  elementName,
  exportMapping,
  dispatchSetExportMapping,
}: Props) {
  const dispatch = useDispatch();
  const referentialCodeOptions = useSelector(selectReferentialCodeOptions);
  const [dataTypeOption, setDataTypeOption] = useState<
    RegularOption | undefined
  >(getInitialDataType(exportMapping.payload));
  const [isDeclinable, setIsDeclinable] = useState(
    exportMapping.payload.is_declinable
  );
  const [referentialSearchValue, setReferentialSearchValue] = useState<
    any | undefined
  >();
  const [referential, setReferential] = useState(
    exportMapping.payload.referential || undefined
  );

  useEffect(() => {
    if (
      exportMapping.payload.referential &&
      !Object.keys(referentialCodeOptions).includes(
        exportMapping.payload.referential
      )
    ) {
      dispatch({
        type: GET_REFERENTIAL_CODE_LIST,
        referential: exportMapping.payload.referential,
      });
    }
  }, []);

  const clearReferentialFields = () => {
    setReferentialSearchValue(undefined);
    setReferential(undefined);
  };

  const updateDataTypeOption = (value: RegularOption) => {
    setDataTypeOption(value);
    setIsDeclinable(undefined);
    clearReferentialFields();
    dispatchSetExportMapping({
      cast: value.id,
    });
  };

  const resetDataTypeOption = () => {
    setDataTypeOption(undefined);
    setIsDeclinable(undefined);
    clearReferentialFields();
    dispatchSetExportMapping({});
  };

  const updateIsDeclinable = (event: { target: { value: string } }) => {
    const { value } = event.target;
    const { cast } = exportMapping.payload;
    const exportMappingDataIsDeclinable = value === 'true' ? true : undefined;
    setIsDeclinable(exportMappingDataIsDeclinable);
    clearReferentialFields();
    dispatchSetExportMapping({
      cast,
      is_declinable: exportMappingDataIsDeclinable,
    });
  };

  const updateReferentialSearchValue = (value: {
    slug: string;
    value: any;
  }) => {
    const { cast, is_declinable, value: paylaodValue } = exportMapping.payload;
    setReferentialSearchValue(value.value);
    setReferential(value.slug);
    if (
      value.slug &&
      !Object.keys(referentialCodeOptions).includes(value.slug)
    ) {
      dispatch({
        type: GET_REFERENTIAL_CODE_LIST,
        referential: value.slug,
      });
    }
    dispatchSetExportMapping({
      cast,
      is_declinable,
      referential: value.slug,
      value: {
        ...(paylaodValue || {}),
        data: undefined,
      },
    });
  };

  const { id } = exportMapping;

  return (
    <div className="GDSNExportMappingsSimpleElementItemValueTypePropsSelector">
      <ExportMappingsSimpleSelector
        label="Type of data"
        testId={`gdsn-export-mappings-simple-element-${elementName}-data-type-property-${id}`}
        options={dataTypeOptions}
        onSelect={updateDataTypeOption}
        option={dataTypeOption}
        onReset={resetDataTypeOption}
      />
      {DECLINABLE_TYPES.includes(dataTypeOption?.id || '') && (
        <div
          className="GDSNExportMappingsSimpleElementItemType__dataIsDeclinable"
          data-testid={`gdsn-export-mappings-simple-element-${elementName}-data-is-declinable-${id}`}
        >
          <InputWithLabel
            inputId={`gdsn-export-mappings-simple-element-${elementName}-data-is-declinable-${id}`}
            label="Is the data declinable ?"
          >
            <Radio
              id={`gdsn-export-mappings-simple-element-${elementName}-data-is-declinable-${id}`}
              value={isDeclinable}
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: undefined },
              ]}
              onChange={updateIsDeclinable}
            />
          </InputWithLabel>
        </div>
      )}
      {(isDeclinable || dataTypeOption?.id === 'entity') && (
        <>
          <div
            className="GDSNExportMappingsSimpleElementItemType__dataReferentialSearchValue"
            data-testid={`gdsn-export-mappings-simple-element-${elementName}-data-referential-search-value`}
          >
            <InputWithLabel
              inputId={`gdsn-export-mappings-simple-element-${elementName}-data-referential-search-value`}
              label="Select a referential"
            >
              <ReferentialAutocomplete
                id={`gdsn-export-mappings-simple-element-${elementName}-data-referential-search-value`}
                value={referentialSearchValue ? [referentialSearchValue] : []}
                onSelect={updateReferentialSearchValue}
                onUnselect={updateReferentialSearchValue}
                searchOnClick
              />
            </InputWithLabel>
          </div>
          <div
            className="GDSNExportMappingsSimpleElementItemType__dataReferential"
            data-testid={`gdsn-export-mappings-simple-element-${elementName}-data-referential-${id}`}
          >
            <InputWithLabel
              inputId={`gdsn-export-mappings-simple-element-${elementName}-data-referential-${id}`}
              label="Referential"
            >
              <Text
                className="GDSNExportMappingsSimpleElementItemType__data-referential"
                id={`gdsn-export-mappings-simple-element-${elementName}-data-referential-${id}`}
                value={referential}
                onChange={() => {}}
                disabled
              />
            </InputWithLabel>
          </div>
        </>
      )}
      {dataTypeOption && (
        <ExportMappingsSimpleElementItemValueTypeValueSetter
          elementName={elementName}
          exportMapping={exportMapping}
          dataTypeOption={dataTypeOption}
          isDeclinable={isDeclinable}
          referential={referential}
          dispatchSetExportMapping={dispatchSetExportMapping}
        />
      )}
    </div>
  );
}
