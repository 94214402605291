import EmptyState from '@alkem/react-ui-empty-state';
import { ErrorFallback } from 'components/error/ErrorFallback';
import * as routes from 'constants/routes';
import Immutable from 'immutable';
import { memo, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { size } from 'utils/immutable';
import { select, showPayload } from '../../actions';
import {
  selectLinks,
  selectSelected,
  selectTransactions,
} from '../../selectors';
import { TransactionGraph } from './graph';
import './list.scss';

export const TransactionExplorerList = memo(() => {
  const dispatch = useDispatch();
  const links = useSelector(selectLinks);
  const transactions = useSelector(selectTransactions);
  const selection = useSelector(selectSelected);

  const dot = useMemo(() => {
    const colors = {
      import: '#3fa2b2',
      share: '#48b071',
      share_media: '#48b071',
      sharingunit: '#48b071',
      share_hierarchy: '#48b071',
      share_validation_bypass: '#48b071',
      archive: '#c69c5d',
    };
    const formatNode = (transactionId) => {
      const transaction = transactions.get(transactionId);
      if (!transaction) {
        return `${transactionId} [ shape=none, margin=0, id=${transactionId}]`;
      }

      const eventType = transaction.getIn(['metadata', 'event', 'type']);

      let color = colors[eventType.toLowerCase()] || 'white';
      let borderColor = '#000000';
      if (selection.size > 0 && !selection.get(transactionId)) {
        color = `${color}40`;
        borderColor = '#AAAAAA';
      }

      const table = `
<TABLE BORDER="0" CELLBORDER="1" CELLSPACING="0" COLOR="${borderColor}">
  <TR>
    <TD ID="title${transactionId}" BGCOLOR="${color}"><FONT COLOR="${borderColor}">
        ${eventType}
        ${transaction.getIn(['metadata', 'event', 'action'])}
    </FONT></TD>
  </TR>
  <TR><TD><FONT COLOR="${borderColor}">${transactionId}</FONT></TD></TR>
</TABLE>
    `;
      return `${transactionId} [ shape=none, margin=0, id=${transactionId}, label=<${table}>];`;
    };

    const formatEdge = (link) => {
      const edge = `${link.get('parent_id')} -> ${link.get('transaction_id')}`;
      let color = '#000000';
      if (
        selection.size > 0 &&
        (!selection.get(link.get('parent_id')) ||
          !selection.get(link.get('transaction_id')))
      ) {
        color = '#AAAAAA';
      }

      let style = 'solid';
      if (link.get('type') === 'child') {
        style = 'dashed';
      }

      return `${edge} [color="${color}", style="${style}"]`;
    };
    return `
digraph {
  bgcolor=transparent;

  ${transactions
    .map((t) => t.get('id'))
    .toArray()
    .map(formatNode)
    .join('\n')}

  ${links.toSet().map(formatEdge).join('\n  ')}
}
    `;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [links, transactions]);

  if (!size(transactions) && !size(links)) {
    return (
      <EmptyState
        title="Nothing to see here"
        text="Hop hop hop! We could not find anything matching what you want"
      />
    );
  }

  return (
    <>
      <div className="TransactionExplorerList__Header">
        <Link
          className="btn btn-secondary"
          to={`${routes.transaction}?q=${transactions.keySeq().join(',')}`}
        >
          See the {transactions.size} transactions in the transaction dashboard
        </Link>
      </div>
      <ErrorBoundary
        FallbackComponent={({ error }: { error: Error | string }) => (
          <ErrorFallback
            error={
              typeof error === 'string'
                ? Object.assign(new Error(error), {
                    stack: [
                      "It probably can't render the graph because of a large number of transactions and links",
                      error,
                    ].join('\n\n'),
                  })
                : error
            }
          />
        )}
      >
        <TransactionGraph
          dot={dot}
          showPayload={(transactionId) => {
            const transaction = transactions.get(transactionId);
            if (!transaction) {
              return;
            }
            dispatch(showPayload(transaction));
          }}
          selectNode={(transactionId) => {
            if (!transactionId || selection.get(transactionId)) {
              // Acts as unselect
              dispatch(select(Immutable.Map()));
              return;
            }
            const edgesBySource = links.reduce(
              (m, l) =>
                m.set(
                  l.get('parent_id'),
                  (m.get(l.get('parent_id')) || Immutable.List())
                    .push(l.get('transaction_id'))
                    .toSet()
                    .toList()
                ),
              Immutable.Map()
            );
            const edgesByTarget = links.reduce(
              (m, l) =>
                m.set(
                  l.get('transaction_id'),
                  (m.get(l.get('transaction_id')) || Immutable.List())
                    .push(l.get('parent_id'))
                    .toSet()
                    .toList()
                ),
              Immutable.Map()
            );
            let newSelection = Immutable.Map().set(transactionId, true);
            const selectPath = (trxId, path) => {
              if (!path || !path.get(trxId)) {
                return;
              }

              path.get(trxId).forEach((targetId) => {
                newSelection = newSelection.set(targetId, true);
                selectPath(targetId, path);
              });
            };
            selectPath(transactionId, edgesBySource);
            selectPath(transactionId, edgesByTarget);
            dispatch(select(newSelection));
          }}
        />
      </ErrorBoundary>
    </>
  );
});
