import { flow, get } from 'lodash/fp';
import {
  APPLICATIONS_REDUCER_KEY,
  ORGANIZATION_PAGE_STORE_KEY,
} from '../constants';
import { OrganizationApplicationState } from '../types';

const selectState: (state: any) => OrganizationApplicationState = get([
  'module',
  ORGANIZATION_PAGE_STORE_KEY,
  APPLICATIONS_REDUCER_KEY,
]);

export const selectIsFetchingApplications = flow(
  selectState,
  (state) => state.isLoading
);

export const selectApplicationsDict = flow(selectState, (state) => state.dict);

export const selectApplicationsVisibleList = flow(
  selectState,
  (state) => state.visibleList
);

export const selectApplicationsHiddenList = flow(
  selectState,
  (state) => state.hiddenList
);

export const selectApplicationsChanges = flow(
  selectState,
  (state) => state.changes
);

export const selectShowMoreApplications = flow(
  selectState,
  (state) => state.showMore
);

export const hasApplicationsChanges = flow(
  selectApplicationsChanges,
  (changes) =>
    Object.values(changes).some((change) => typeof change === 'boolean')
);
