import { Checkbox, Text } from '@alkem/react-ui-inputs';
import { Modal } from '@alkem/react-ui-modal';
import { SimpleSelect } from '@alkem/react-ui-select';
import InputWithLabel from 'components/input-with-label';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dedupe } from 'utils/list';
import { separateActions } from 'utils/redux';
import { closeGDSNExportModal, pushGDSNExport } from '../actions';
import { selectIsGDSNModalOpen } from '../selectors';
import './gdsn.scss';

const documentCommandType = [
  { value: null, label: 'Auto Compute' },
  { value: 'ADD', label: 'ADD' },
  { value: 'CORRECT', label: 'CORRECT' },
  { value: 'CHANGE_BY_REFRESH', label: 'CHANGE_BY_REFRESH' },
];

const mapDispatchToProps = {
  closeGDSNExportModal,
  pushGDSNExport,
};

const mapStateToProps = (state) => ({
  isOpen: selectIsGDSNModalOpen(state),
});

export class GDSNExportFileModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      closeGDSNExportModal: PropTypes.func.isRequired,
      pushGDSNExport: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      productKeyIDs: '',
      dct: null,
      includeData: true,
      includePrice: false,
    };
  }

  getProductKeyIDs = () => {
    return dedupe(
      this.state.productKeyIDs
        .split(',')
        .map((id) => parseInt(id))
        .filter((e) => e)
    );
  };

  pushGDSNExport = () => {
    this.getProductKeyIDs().map((productKeyID) => {
      return this.props.actions.pushGDSNExport(
        productKeyID,
        this.state.dct,
        this.state.includeData,
        this.state.includePrice
      );
    });
    this.props.actions.closeGDSNExportModal();
  };

  onUpdateDCT = (e) => {
    this.setState({ dct: e.value });
  };

  onUpdateProductKeyIDs = (e) => {
    this.setState({ productKeyIDs: e.target.value || '' });
  };

  onIncludePriceUpdate = () => {
    const previousIncludePrice = this.state.includePrice;
    this.setState({ includePrice: !previousIncludePrice });
  };

  onIncludeDataUpdate = () => {
    const previousIncludeData = this.state.includeData;
    this.setState({ includeData: !previousIncludeData });
  };

  render() {
    const { isOpen, actions } = this.props;
    if (!isOpen) {
      return null;
    }
    return (
      <Modal
        title="Force GDSN export (experimental)"
        className="ExportGDSNFileModal"
        confirmButtonText="Export"
        onConfirm={this.pushGDSNExport}
        onClose={actions.closeGDSNExportModal}
      >
        <div className="ExportGDSNFileModal">
          <div className="ExportGDSNFileModal_sep">
            <InputWithLabel
              inputId="export-gdsn-modal-productkeyids-i"
              label="Hierarchies ProductKey IDs"
            >
              <Text
                id="export-gdsn-modal-productkeyids"
                value={this.state.productKeyIDs}
                placeholder="hierarchies productkey ids..."
                onChange={this.onUpdateProductKeyIDs}
              />
            </InputWithLabel>
          </div>
          <div className="ExportGDSNFileModal_sep">
            <InputWithLabel
              inputId="export-gdsn-modal-dct-i"
              label="Document command type"
            >
              <SimpleSelect
                id="export-gdsn-modal-dct"
                options={documentCommandType}
                value={documentCommandType.find(
                  (o) => o.value === this.state.dct
                )}
                onSelect={this.onUpdateDCT}
                autoSize
              />
            </InputWithLabel>
          </div>
          <div className="ExportGDSNFileModal_sep">
            <InputWithLabel inputId="export-gdsn-modal-include-data-i" label="">
              <Checkbox
                id="export-gdsn-modal-include-data"
                checked={this.state.includeData}
                onChange={this.onIncludeDataUpdate}
                label={
                  <div className="EndpointModal_Checkbox">Push Data (CIN)</div>
                }
              />
            </InputWithLabel>
          </div>
          <div className="ExportGDSNFileModal_sep">
            <InputWithLabel
              inputId="export-gdsn-modal-include-price-i"
              label=""
            >
              <Checkbox
                id="export-gdsn-modal-include-price"
                checked={this.state.includePrice}
                onChange={this.onIncludePriceUpdate}
                label={
                  <div className="EndpointModal_Checkbox">Push Price (PSR)</div>
                }
              />
            </InputWithLabel>
          </div>
          <div className="ExportGDSNFileModal_sep">
            <InputWithLabel
              inputId="export-gdsn-modal-include-price-i"
              label="The following product keys will be re-exported:"
            >
              <ul>
                {this.getProductKeyIDs().map((productKeyID) => {
                  return (
                    <li key={`to-reexport-push-productkey-${productKeyID}`}>
                      {productKeyID}
                    </li>
                  );
                })}
              </ul>
            </InputWithLabel>
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(GDSNExportFileModal);
