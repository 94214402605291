import * as events from './events';

export const loadFilters = (transactionId: number) => ({
  type: events.LOAD_TRANSACTION_ID,
  transactionId,
});

export const select = (selection) => ({
  type: events.SELECT,
  selection,
});

export const showPayload = (payload) => ({
  type: events.SHOW_PAYLOAD,
  payload,
});

export const closePayload = () => ({ type: events.CLOSE_MODAL });
