import * as events from 'modules/io/import/constants/events';

// Filters
export const addImportListFilters = (key, value) => ({
  type: events.ADD_IMPORT_LIST_FILTER,
  key,
  value,
});

export const removeImportListFilters = (key, value) => ({
  type: events.REMOVE_IMPORT_LIST_FILTER,
  key,
  value,
});

export const updateImportListFilters = (key, value) => ({
  type: events.UPDATE_IMPORT_LIST_FILTER,
  key,
  value,
});

export const clearImportListFilters = (key, value) => ({
  type: events.CLEAR_IMPORT_LIST_FILTER,
  key,
  value,
});

export const receiveImportListFilters = (filters) => ({
  type: events.RECEIVE_IMPORT_LIST_FILTER,
  filters,
});

export const searchImportReportList = (search) => ({
  type: events.SEARCH_IMPORT_LIST,
  search,
});

export const updateSpider = (name, value, selected) => ({
  type: events.UPDATE_SPIDER,
  name,
  value,
  selected,
});

export const getImportSpider = () => ({
  type: events.GET_IMPORT_SPIDER,
});

export const receiveImportSpider = (filters) => ({
  type: events.RECEIVE_IMPORT_SPIDER,
  filters,
});

// LIST
export const pushImport = (payload) => ({
  type: events.PUSH_IMPORT,
  payload,
});

export const redirectToTransactionDashboard = (productImportID) => ({
  type: events.REDIRECT_TO_TRANSACTION_DASHBOARD,
  productImportID,
});

export const productImportReportGetFile = (index, importFile) => ({
  type: events.PRODUCT_IMPORT_REPORT_GET_FILE,
  index,
  importFile,
});

export const productImportReportGenerate = (pifId) => ({
  type: events.PRODUCT_IMPORT_REPORT_GENERATE,
  pifId,
});

export const setSupportStatus = (importfileId, status) => ({
  type: events.SET_SUPPORT_STATUS,
  importfileId,
  status,
});

export const setImportStatus = (importfileId, status) => ({
  type: events.SET_IMPORT_STATUS,
  importfileId,
  status,
});

export const getImportList = ({ replaceHistory = true }) => ({
  type: events.GET_IMPORT_LIST,
  payload: { replaceHistory },
});

export const importListIsLoading = () => ({
  type: events.IMPORT_LIST_IS_LOADING,
});

export const receiveImportList = (list, totalResults) => ({
  type: events.RECEIVE_IMPORT_LIST,
  payload: {
    list,
    totalResults,
  },
});

export const getImportDetails = (importfileId, filters) => ({
  type: events.GET_IMPORT_DETAILS,
  importfileId,
  filters,
});

export const receiveImportDetails = (importfileId, list) => ({
  type: events.RECEIVE_IMPORT_DETAILS,
  importfileId,
  list,
});

export const nextPage = () => ({
  type: events.NEXT_PAGE_IMPORT_LIST,
});

export const previousPage = () => ({
  type: events.PREVIOUS_PAGE_IMPORT_LIST,
});

export const changeLimit = (limit) => ({
  type: events.CHANGE_LIMIT_IMPORT_LIST,
  limit,
});

export const searchImportList = (search) => ({
  type: events.SEARCH_IMPORT_LIST,
  search,
});
