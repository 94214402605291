import PropTypes from 'prop-types';
import React from 'react';

export default function CellHistorySimple({ data }) {
  const styleDate = {
    display: 'inline-block',
    width: '120px',
    borderRight: '1px solid',
    borderBottom: '1px solid',
    paddingLeft: '4px',
    paddingRight: '4px',
  };
  const styleNumber = {
    display: 'inline-block',
    width: '90px',
    borderRight: '1px solid',
    borderBottom: '1px solid',
    paddingLeft: '4px',
    paddingRight: '4px',
    fontStyle: 'italic',
  };

  let past = 0;

  const indiv = Object.entries(data)
    .sort()
    .map((entry) => {
      const date = new Date(Number(entry[0]));
      const dateName = date.toISOString().slice(0, 10);
      const value = entry[1];
      const upValue = value - past;
      past = value;
      return (
        <div key={dateName}>
          <span style={styleDate}> {dateName} </span>
          <span style={styleNumber}> {value} </span>
          <span style={styleNumber}> {upValue} </span>
        </div>
      );
    });

  const header = (
    <div key="header" style={{ fontWeight: 'bold' }}>
      <span style={styleDate}> Date </span>
      <span style={styleNumber}> Nb Products </span>
      <span style={styleNumber}> Diff </span>
    </div>
  );

  return (
    <div>
      {header}
      {indiv}
    </div>
  );
}

CellHistorySimple.propTypes = {
  data: PropTypes.object,
};
