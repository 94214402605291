import moment from 'moment';
import { takeLatest, select, call, put } from 'redux-saga/effects';

import GdsnApi from 'resources/gdsnApi';
import { saveAs } from 'utils';

import { notificationSuccess, notificationError } from 'actions/notifications';

import * as events from '../constants/events';

import { receiveGDSNRCIList, closeRCIModal, closeGepirModal } from '../actions';

import { selectPagination, selectFilters, selectModal } from '../selectors';
import { formatTime, TIME_FORMAT_DAY } from 'utils/time';

const {
  GET_GDSN_RCI_LIST,
  ADD_GDSN_RCI_LIST_FILTER,
  REMOVE_GDSN_RCI_LIST_FILTER,
  UPDATE_GDSN_RCI_LIST_FILTER,
  CLEAR_GDSN_RCI_LIST_FILTER,
  CHANGE_LIMIT_GDSN_RCI_LIST,
  NEXT_PAGE_GDSN_RCI_LIST,
  PREVIOUS_PAGE_GDSN_RCI_LIST,
  DOWNLOAD_GDSN_RCI_DOCUMENT,
  RECEIVE_GDSN_RCI_LIST_FILTERS,
  GDSN_RCI_MODAL_SAVE,
  GDSN_GEPIR_MODAL_UPLOAD_CSV,
  GDSN_RCI_DELETE,
} = events;

export default function* gdsnRCIMainSaga() {
  yield takeLatest(GDSN_RCI_MODAL_SAVE, saveGDSNRCI);
  yield takeLatest(GDSN_RCI_DELETE, deleteGDSNRCI);
  yield takeLatest(GDSN_GEPIR_MODAL_UPLOAD_CSV, uploadGDSNGepirCSV);
  yield takeLatest(GET_GDSN_RCI_LIST, getGDSNRCIList);
  yield takeLatest(DOWNLOAD_GDSN_RCI_DOCUMENT, downloadGDSNDocument);
  yield takeLatest(
    [
      ADD_GDSN_RCI_LIST_FILTER,
      REMOVE_GDSN_RCI_LIST_FILTER,
      UPDATE_GDSN_RCI_LIST_FILTER,
      CLEAR_GDSN_RCI_LIST_FILTER,
      CHANGE_LIMIT_GDSN_RCI_LIST,
      NEXT_PAGE_GDSN_RCI_LIST,
      PREVIOUS_PAGE_GDSN_RCI_LIST,
      RECEIVE_GDSN_RCI_LIST_FILTERS,
    ],
    getGDSNRCIList
  );
}

function* uploadGDSNGepirCSV(payload) {
  try {
    const response = yield call(
      [GdsnApi, GdsnApi.UploadGEPIRCrawlCSV],
      payload.file
    );
    yield put(
      notificationSuccess(
        `Success: ${response.data.crawl} gtins need to be crawled [others are already ok :)]`
      )
    );
    yield put(closeGepirModal());
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured while pushing your CSV'));
    }
  }
}

function* saveGDSNRCI() {
  try {
    const modal = yield select(selectModal);
    const rci = modal.rci.toJS();
    yield call([GdsnApi, GdsnApi.RegisterRCI], {
      data: rci,
    });
    yield put(
      notificationSuccess(
        'You rci registration was sent to the global registry'
      )
    );
    yield put(closeRCIModal());
    yield call(getGDSNRCIList);
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured while registering RCI'));
    }
  }
}

function* deleteGDSNRCI(event) {
  try {
    yield call([GdsnApi, GdsnApi.CorrectRCI], {
      data: {
        catalogueItem_id: event.rciID,
        discontinuedAt: formatTime(moment(), TIME_FORMAT_DAY),
        documentCommand_type: 'DELETE',
      },
    });
    yield put(
      notificationSuccess('You rci deletion was sent to the global registry')
    );
    yield put(closeRCIModal());
    yield call(getGDSNRCIList);
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured while deleting RCI'));
    }
  }
}

function* getGDSNRCIList() {
  try {
    const pagination = yield select(selectPagination);
    const filters = yield select(selectFilters);
    const response = yield call(
      [GdsnApi, GdsnApi.ListRCI],
      {
        filter_gln: filters.filter_gln.length
          ? filters.filter_gln[0].value
          : '',
        filter_gtin: filters.filter_gtin.length
          ? filters.filter_gtin[0].value
          : '',
      },
      (pagination.currentPage - 1) * pagination.limit,
      pagination.limit
    );
    yield put(
      receiveGDSNRCIList(response.data.data, response.data.totalResults)
    );
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured: getGDSNRCIList'));
    }
  }
}

function* downloadGDSNDocument(payload) {
  try {
    const response = yield call(
      [GdsnApi, GdsnApi.DownloadDocument],
      payload.documentID
    );
    const filename = `gdsn_document_${payload.documentID}.xml`;
    saveAs(response.data, filename);
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured: downloadGDSNDocument'));
    }
  }
}
