import { Modal } from '@alkem/react-ui-modal';
import { SimpleSelect } from '@alkem/react-ui-select';
import { notificationError, notificationSuccess } from 'actions/notifications';
import classNames from 'classnames';
import { OrganizationAutocomplete } from 'components/autocomplete';
import { STATUS_ACTIVE } from 'constants/organization';
import { fromJS } from 'immutable';
import { rangeSpiders } from 'modules/io/import/constants';
import { OrganizationTypeRetailer as retailer } from 'modules/organization-list/constants/types';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import etlApi from 'resources/etlApi';
import './modal.scss';

const mergeModes = [
  {
    value: 'delta',
    label: 'Delta mode',
  },
  {
    value: 'full',
    label: '⚠ File contains whole catalog ⚠',
  },
];

class ImportModalRange extends Component {
  static propTypes = {
    onCloseModal: PropTypes.func,
    dispatch: PropTypes.func,
  };

  state = {
    files: null,
    loading: false,
    config: fromJS({
      mode: 'delta',
    }),
  };

  shouldComponentUpdate() {
    return true;
  }

  onSelectFiles = (files) => {
    this.setState({ files });
  };

  filesSelected = () => {
    const { files } = this.state;
    return !!(files && files.length);
  };

  uploadFiles = () => {
    const { files } = this.state;
    const { dispatch, onCloseModal } = this.props;
    files.forEach((file) => {
      const payload = this.state.config.toJS();
      this.setState({ loading: true });
      etlApi.TargetCatalogUpload(payload, file).then(
        () => {
          this.setState({
            loading: false,
          });
          dispatch(notificationSuccess('File successfully imported'));
          onCloseModal();
        },
        () => {
          dispatch(
            notificationError('There was an error while importing your file')
          );
          this.setState({ loading: false });
          onCloseModal();
        }
      );
    });
  };

  selectOrganization = (organization) => {
    this.setState((prevState) => ({
      organization,
      config: prevState.config.set('organization_id', organization.value.id),
    }));
  };

  onUpdateMergeMode = (e) =>
    this.setState((prevState) => ({
      config: e.value
        ? prevState.config.set('mode', e.value)
        : prevState.config.delete('mode'),
    }));

  onUpdateFormat = (e) =>
    this.setState((prevState) => ({
      config: prevState.config.set('format', e.value),
    }));

  getFormat = () =>
    rangeSpiders.find((o) => o.value === this.state.config.get('format'));

  getMergeMode = () =>
    mergeModes.find((o) => o.value === this.state.config.get('mode'));

  renderParameters() {
    return (
      <div className="row ImportModalRange__params">
        <div className="col-xs-4">
          <SimpleSelect
            id="ImportModalRange-Spider"
            options={rangeSpiders}
            value={this.getFormat()}
            onSelect={this.onUpdateFormat}
            autoSize
            placeholder="Select a format..."
          />
        </div>
        <div className="col-xs-4">
          <SimpleSelect
            id="ImportModalRange-Merge"
            options={mergeModes}
            value={this.getMergeMode()}
            onSelect={this.onUpdateMergeMode}
            autoSize
          />
        </div>
        <div className="col-xs-4">
          <OrganizationAutocomplete
            id="organization-autocomplete"
            value={this.state.organization ? [this.state.organization] : []}
            onSelect={this.selectOrganization}
            onUnselect={this.unselectOrganization}
            placeholder="Select an organization..."
            searchOnClick
            filters={{
              statuses: [STATUS_ACTIVE],
              types: [retailer.id],
            }}
          />
        </div>
      </div>
    );
  }

  renderDrodown() {
    const { files } = this.state;
    if (this.filesSelected()) {
      return (
        <div className="ImportModalRange__summary">
          <div className="ImportModalRange__summaryTitle">
            {`You are about to import ${files.length} file(s):`}
          </div>
          <div className="ImportModalRange__fileSummary">
            {files.map((file) => (
              <div key={file.name}>
                <i className="mdi mdi-file" />
                {file.name}
              </div>
            ))}
          </div>
        </div>
      );
    }
    return (
      <div>
        <Dropzone multiple onDrop={this.onSelectFiles}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div
              {...getRootProps({
                className: classNames(
                  'Dropzone',
                  isDragActive && 'Dropzone--active'
                ),
              })}
            >
              <input {...getInputProps()} />
              <div>
                <b>Drag and drop your file here</b> or select a file from your
                computer...
              </div>
            </div>
          )}
        </Dropzone>
      </div>
    );
  }

  render() {
    const { onCloseModal } = this.props;
    const { files, loading } = this.state;
    return (
      <Modal
        title="Import Range"
        modalStyle="fullHeight"
        confirmButtonText="Upload"
        confirmDisabled={!files}
        isProcessing={loading}
        onConfirm={this.uploadFiles}
        onClose={onCloseModal}
      >
        {this.renderDrodown()}
        {this.renderParameters()}
      </Modal>
    );
  }
}

export default connect()(ImportModalRange);
