import { List } from 'immutable';

export function findDisplayGroupPath(displayGroupId, displayGroup, path = []) {
  // Recursive function to find and return a display group path based on id.
  if (!displayGroup) {
    return null;
  }
  if (displayGroup.get('id') === displayGroupId) {
    return path;
  }
  const paths = (displayGroup.get('children') || List())
    .map((child, index) =>
      findDisplayGroupPath(displayGroupId, child, [
        ...path,
        'children',
        `${index}`,
      ])
    )
    .filter((p) => !!p);
  return paths.size ? paths.first() : null;
}
