// List
export const RECEIVE_EXPORT_LIST = 'RECEIVE_EXPORT_LIST';
export const CHANGE_LIMIT_EXPORT_LIST = 'CHANGE_LIMIT_EXPORT_LIST';
export const NEXT_PAGE_EXPORT_LIST = 'NEXT_PAGE_EXPORT_LIST';
export const PREVIOUS_PAGE_EXPORT_LIST = 'PREVIOUS_PAGE_EXPORT_LIST';
export const GET_EXPORT_LIST = 'GET_EXPORT_LIST';

// Filters
export const ADD_EXPORT_LIST_FILTER = 'ADD_EXPORT_LIST_FILTER';
export const REMOVE_EXPORT_LIST_FILTER = 'REMOVE_EXPORT_LIST_FILTER';
export const UPDATE_EXPORT_LIST_FILTER = 'UPDATE_EXPORT_LIST_FILTER';
export const CLEAR_EXPORT_LIST_FILTER = 'CLEAR_EXPORT_LIST_FILTER';
export const ADD_ONE_EXPORT_LIST_FILTER = 'ADD_ONE_EXPORT_LIST_FILTER';

export const GET_EXPORT_LIST_FILTER = 'GET_EXPORT_LIST_FILTER';
export const EXPORT_LIST_IS_LOADING = 'EXPORT_LIST_IS_LOADING';
export const RECEIVE_EXPORT_LIST_FILTER = 'RECEIVE_EXPORT_LIST_FILTER';

// Search
export const SEARCH_EXPORT_LIST = 'SEARCH_EXPORT_LIST';

// Download
export const DOWNLOAD_EXPORTED_FILE = 'DOWNLOAD_EXPORTED_FILE';

export const GET_EXPORT_FILE_REFERENCE_LIST = 'GET_EXPORT_FILE_REFERENCE_LIST';
export const RECEIVE_EXPORT_FILE_REFERENCE_LIST =
  'RECEIVE_EXPORT_FILE_REFERENCE_LIST';
// Modal
export const EXPORTFILE_MODAL_OPEN = 'EXPORTFILE_MODAL_OPEN';
export const EXPORTFILE_MODAL_CLOSE = 'EXPORTFILE_MODAL_CLOSE';
export const EXPORTFILE_GENERATE = 'EXPORTFILE_GENERATE';

// Modal Potential Suppliers
export const EXPORTFILE_POTENTIAL_SUPPLIER_MODAL_OPEN =
  'EXPORTFILE_POTENTIAL_SUPPLIER_MODAL_OPEN';
export const EXPORTFILE_POTENTIAL_SUPPLIER_MODAL_CLOSE =
  'EXPORTFILE_POTENTIAL_SUPPLIER_MODAL_CLOSE';
export const EXPORTFILE_POTENTIAL_SUPPLIER_GENERATE =
  'EXPORTFILE_POTENTIAL_SUPPLIER_GENERATE';
export const CURRENT_POTENTIAL_SUPPLIER_EXPORTFILE_UPDATE =
  'CURRENT_POTENTIAL_SUPPLIER_EXPORTFILE_UPDATE';
export const POLL_EXPORTFILE_POTENTIAL_SUPPLIER_GENERATION =
  'POLL_EXPORTFILE_POTENTIAL_SUPPLIER_GENERATION';

// Poll
export const POLL_EXPORTFILE_GENERATION = 'POLL_EXPORTFILE_GENERATION';
export const POLL_EXPORTFILE_EVERY_MS = 1000;

export const CURRENT_EXPORTFILE_UPDATE = 'CURRENT_EXPORTFILE_UPDATE';

// GDSN Modal
export const EXPORTGDSNFILE_MODAL_OPEN = 'EXPORTGDSNFILE_MODAL_OPEN';
export const EXPORTGDSNFILE_MODAL_CLOSE = 'EXPORTGDSNFILE_MODAL_CLOSE';
export const EXPORTGDSNFILE_PUSH = 'EXPORTGDSNFILE_PUSH';

export const CANCEL_EXPORT = 'CANCEL_EXPORT';

// View JSON Modal
export const VIEW_JSON_MODAL_OPEN = 'VIEW_JSON_MODAL_OPEN';
export const VIEW_JSON_MODAL_CLOSE = 'VIEW_JSON_MODAL_CLOSE';
