import { MODULE_REDUCER_KEY } from 'constants/reducer';

import { MAPPER_AFS_STORE_KEY } from '../constants/reducers';

export function selectState(state) {
  return state[MODULE_REDUCER_KEY][MAPPER_AFS_STORE_KEY];
}

export function selectSaveInProgress(state) {
  return selectState(state).saveInProgress;
}

export function selectAttributeList(state) {
  return selectState(state).attributeList;
}

export function selectAttributeDetails(state) {
  return selectState(state).selectedAttributeVersions;
}

export function selectFunctionList(state) {
  return selectState(state).functionList;
}

export function selectFunctionDetails(state) {
  return selectState(state).selectedFunctionDetails;
}

export function selectCategoryList(state) {
  return selectState(state).categoryList;
}

export function selectCategoryAttributeList(state) {
  return selectState(state).selectedCategoryAttributeList;
}
