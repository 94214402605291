import { get as _get, keyBy as _keyBy, set as _set } from 'lodash';

export const isImmutable = (o) => !!o && !!o.getIn;

const pathToArray = (path) => (Array.isArray(path) ? path : path.split('.'));

export const get = (o, path) => (isImmutable(o) ? o.get(path) : _get(o, path));

export const getIn = (o, path) =>
  isImmutable(o) ? o.getIn(pathToArray(path)) : _get(o, pathToArray(path));

export const set = (o, path, value) =>
  isImmutable(o) ? o.set(path, value) : _set(o, path, value);

export const setIn = (o, path, value) =>
  isImmutable(o)
    ? o.setIn(pathToArray(path), value)
    : _set(o, pathToArray(path), value);

export const size = (o) => (isImmutable(o) ? o.size : o.length);

export const first = (o) => (isImmutable(o) ? o.first() : o[0]);

export const toJsIfImmutable = (o) => (o && o.toJS ? o.toJS() : o);
export const keyBy = (o, pred) =>
  isImmutable(o)
    ? o.toMap().mapKeys((key, value) => pred(value))
    : _keyBy(o, pred);
