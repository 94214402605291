import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { get, getIn, size, first } from 'utils/immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { createStructuredSelector } from 'reselect';
import { Spinner } from '@alkem/react-ui-spinner';
import { Button, SwitchButton } from '@alkem/react-ui-button';

import { FieldAutocomplete } from 'components/autocomplete';
import { separateActions } from 'utils/redux';
import { getKindList } from 'actions/kinds';

import * as _actions from '../../actions';
import { organizationHasField } from '../../utils';
import {
  selectFields,
  selectFieldsAreLoaded,
  selectRequiredFieldNames,
} from '../../selectors';
import { Field } from './field';
import '../../views.scss';
import { kindsIndexSelector } from 'redux/selectors';
import { CONSUMER_UNIT } from 'constants/fields';

const mapDispatchToProps = { ..._actions, getKindList };

const mapStateToProps = createStructuredSelector({
  fieldsByFieldName: selectFields,
  requiredFieldNames: selectRequiredFieldNames,
  fieldsAreLoaded: selectFieldsAreLoaded,
  kindsIndex: kindsIndexSelector,
});

export class TabFields extends PureComponent {
  static propTypes = {
    fieldsByFieldName: ImmutablePropTypes.map,
    requiredFieldNames: ImmutablePropTypes.set,
    fieldsAreLoaded: PropTypes.bool,
    editedOrganization: ImmutablePropTypes.map,
    fieldEntity: PropTypes.string,
    ruleEntity: PropTypes.number,
    fieldTag: PropTypes.string,
    actions: PropTypes.shape({
      fetchFieldsWithValidationRules: PropTypes.func,
      updateOrganizationFields: PropTypes.func,
      createRule: PropTypes.func,
      updateRules: PropTypes.func,
      deleteRules: PropTypes.func,
      getKindList: PropTypes.func,
    }),
    readOnly: PropTypes.bool.isRequired,
    kindsIndex: PropTypes.object,
  };

  state = {
    hideNonRequired: false,
  };

  componentDidMount() {
    const {
      actions,
      editedOrganization,
      fieldEntity,
      fieldsAreLoaded,
      kindsIndex,
    } = this.props;

    const kindsIndexNotLoaded = Object.keys(kindsIndex).length === 0;

    if (kindsIndexNotLoaded) {
      // needed for proper initial kindtree display (fobidden kinds for validation rules)
      actions.getKindList();
    }

    if (!fieldsAreLoaded) {
      actions.fetchFieldsWithValidationRules(
        editedOrganization.get('id'),
        fieldEntity,
        true
      );
    }
  }

  getFieldRule(fieldName) {
    const { editedOrganization, ruleEntity } = this.props;
    const rules = getIn(editedOrganization, ['rulesByField', fieldName]);
    if (rules && size(rules) > 0) {
      return rules.filter((r) => get(r, 'entityType') === ruleEntity);
    }
    return [];
  }

  addField = (option) => {
    const { editedOrganization, actions } = this.props;
    if (!organizationHasField(editedOrganization, option.value)) {
      actions.updateOrganizationFields(
        editedOrganization
          .get('usesFields')
          .push(
            option.value.set(
              'overridable',
              ['priceWaterfalls', 'hierarchyProduct', 'hierarchy'].includes(
                option.value.get('name')
              )
            )
          )
      );
    }
  };

  deleteField = (fieldName) => {
    const { editedOrganization, actions, fieldEntity } = this.props;
    this.deleteRules(fieldName);
    actions.updateOrganizationFields(
      editedOrganization
        .get('usesFields')
        .filter(
          (f) =>
            f.get('name') !== fieldName || f.get('entityType') !== fieldEntity
        )
    );
  };

  updateRules = (fieldName) => (path, value) => {
    const { ruleEntity, actions } = this.props;
    actions.updateRules(fieldName, ruleEntity, path, value);
  };

  deleteRules = (fieldName) => {
    const {
      ruleEntity,
      actions: { deleteRules },
    } = this.props;
    deleteRules(fieldName, ruleEntity);
  };

  toggleHideNonRequired = (value) => {
    this.setState({ hideNonRequired: value });
  };

  onBulkSetSpecificValue = () => {
    const { editedOrganization, fieldEntity, actions } = this.props;
    actions.updateOrganizationFields(
      editedOrganization
        .get('usesFields')
        .map((f) => f.set('overridable', f.get('entityType') === fieldEntity))
    );
  };

  renderField = (field) => {
    const {
      readOnly,
      editedOrganization,
      fieldEntity,
      ruleEntity,
      requiredFieldNames,
      actions: { createRule },
    } = this.props;
    const fieldName = field.get('name');
    const required = size(this.getFieldRule(fieldName)) > 0;
    if (this.state.hideNonRequired && !required) {
      return null;
    }
    const rules = this.getFieldRule(fieldName);

    return (
      <Field
        key={fieldName}
        field={field}
        readOnly={readOnly}
        organization={editedOrganization}
        fieldEntity={fieldEntity}
        ruleEntity={ruleEntity}
        isRequirable={requiredFieldNames.has(fieldName)}
        createRule={createRule}
        deleteRules={this.deleteRules}
        deleteField={this.deleteField}
        rule={first(rules)}
        onUpdateRuleValue={this.updateRules(fieldName)}
      />
    );
  };

  renderMandatoryFields(fields, entity) {
    // Add mandatory fields
    fields
      .filter((field) => field.get('tags').includes('rfp_mandatory'))
      .map((field) =>
        this.addField({
          key: field.get('id'),
          label: field.get('name'),
          value: field.set('entityType', entity),
        })
      );
  }

  renderFilters() {
    const { ruleEntity } = this.props;
    if (typeof ruleEntity === 'undefined') {
      return null;
    }
    return (
      <div>
        <SwitchButton
          testid="toggle-hide-non-required-fields"
          checked={this.state.hideNonRequired}
          onChange={this.toggleHideNonRequired}
          content="Hide non required"
        />
      </div>
    );
  }

  render() {
    const {
      editedOrganization,
      fieldsByFieldName,
      fieldsAreLoaded,
      fieldEntity,
      fieldTag,
      readOnly,
      kindsIndex,
    } = this.props;
    if (!editedOrganization) {
      return <span> Please select a retailer </span>;
    }

    if (!fieldsAreLoaded) {
      return (
        <div aria-busy title="Fields are loading">
          <Spinner center big />
        </div>
      );
    }

    const kindsIndexHasLoaded = Object.keys(kindsIndex).length > 0;

    const usedFields = get(editedOrganization, 'usesFields')
      .filter((f) => f.get('entityType') === fieldEntity)
      .map((f) => fieldsByFieldName.get(f.get('name')))
      .filter((f) => !!f)
      .sortBy((f) => f.get('name').toLowerCase());

    return (
      <div>
        {fieldEntity === CONSUMER_UNIT && (
          <Button
            id="mark-all-specific-value-button"
            testid="mark-all-specific-value-button"
            content="Mark 'specific value' for all"
            secondary
            onClick={this.onBulkSetSpecificValue}
            disabled={
              editedOrganization &&
              get(editedOrganization, 'usesFields')
                .filter((f) => f.get('entityType') === fieldEntity)
                .every((f) => f.get('overridable'))
            }
          />
        )}
        <div className="OrganizationUsesFieldsView__fields" role="list">
          <div className="OrganizationUsesFieldsView__header">
            <h3>{`${editedOrganization.get('nameLegal')} uses:`}</h3>
            {this.renderFilters()}
          </div>
          <div className="OrganizationUsesFieldsView__fieldAutocomplete">
            {this.renderMandatoryFields(fieldsByFieldName, fieldEntity)}
            {!readOnly && (
              <FieldAutocomplete
                id="field-autocomplete"
                value={[]}
                onSelect={this.addField}
                entityType={fieldEntity}
                organizationId={get(editedOrganization, 'id')}
                searchOnClick
                excludeList={usedFields.toJS()}
                tag={fieldTag}
                limit={100}
              />
            )}
          </div>

          {kindsIndexHasLoaded && usedFields.map(this.renderField).toArray()}
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(TabFields);
