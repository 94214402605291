import Image from 'components/ui/image';
import moment from 'moment';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { size } from 'utils/immutable';
import { v4 as uuid } from 'uuid';
import './line.scss';

export class HistoryDiffLine extends PureComponent<{ diff: any }> {
  static propTypes = {
    diff: PropTypes.object.isRequired,
  };

  renderList(list) {
    let label = list;
    if (!Array.isArray(label)) {
      label = [label];
    }
    label = label.map((l) => {
      if (Array.isArray(l)) {
        return l.map((elt, i) => (
          <span key={uuid()}>
            {i > 0 ? (
              <span className="ProductHistoryDiffCard__separator">
                &nbsp;●&nbsp;
              </span>
            ) : null}
            {elt}
          </span>
        ));
      }
      return l;
    });
    return label.map((l) => (
      <div key={uuid()} className="ProductHistoryDiffCard__label">
        {l}
      </div>
    ));
  }

  renderValue(value, type) {
    const empty = (
      <span className="ProductHistoryDiffCard__empty">(empty)</span>
    );
    switch (type) {
      case 'timestamp':
        return value ? moment.unix(value).format('L LT') : empty;
      case 'time':
        return value ? moment.unix(value).format('L LT') : empty;
      case 'date':
        return value ? moment.unix(value).format('L') : empty;
      case 'iso_date':
        return value ? moment.unix(value).format('L') : empty;
      case 'boolean':
        if (value === true) {
          return 'Yes';
        }
        if (value === false) {
          return 'No';
        }
        return empty;
      case 'list':
        if (!value || size(value) === 0) {
          return empty;
        }
        return (
          <ul>
            {this.renderList(value).map((v) => (
              <li key={uuid()}>{v}</li>
            ))}
          </ul>
        );
      case 'picture':
        if (value === null) {
          return empty;
        }
        return <Image maxWidth={128} maxHeight={128} src={value} />;
      default:
        return value == null || value === '' ? empty : value;
    }
  }

  renderLabel(diff) {
    const pictureURL = diff.pictureURL;
    if (pictureURL) {
      return (
        <div className="ProductHistoryDiffCard__complexLabel">
          <div>
            <Image maxWidth={64} maxHeight={64} src={pictureURL} />
          </div>
          <div className="ProductHistoryDiffCard__labelGroup">
            {this.renderList(diff.label)}
          </div>
        </div>
      );
    }
    return this.renderList(diff.label);
  }

  render() {
    const { diff } = this.props;
    return (
      <div className="ProductHistoryDiffCard__row row">
        <div className="col-xs-4 ProductHistoryDiffCard__key">
          {this.renderLabel(diff)}
        </div>
        <div className="col-xs-4 ProductHistoryDiffCard__value ProductHistoryDiffCard__old">
          {this.renderValue(diff.old, diff.type)}
        </div>
        <div className="col-xs-4 ProductHistoryDiffCard__value ProductHistoryDiffCard__new">
          {this.renderValue(diff.new, diff.type)}
        </div>
      </div>
    );
  }
}
