import { kebabCase as kebabify } from 'lodash';

import { saveAs } from 'utils';
import { toJsIfImmutable } from 'utils/immutable';

import { selectApplicationsDict } from '../selectors/applications';
import { selectOrganization } from '../selectors/infos';
import { selectState as selectRecipients } from '../selectors/recipients';
import { selectSettings } from '../selectors/settings';

export const exportAction = () => (_, getState) => {
  const state = getState();
  const applications = selectApplicationsDict(state);
  const infos = selectOrganization(state);
  const recipients = selectRecipients(state);
  const settings = selectSettings(state);
  const organization = {
    applications: toJsIfImmutable(applications),
    infos: toJsIfImmutable(infos),
    recipients: toJsIfImmutable(recipients),
    settings: toJsIfImmutable(settings),
  };
  const blob = new Blob([JSON.stringify(organization, null, 2)], {
    type: 'application/json',
  });
  saveAs(blob, `organization-${kebabify(organization.infos.nameLegal)}.json`);
};
