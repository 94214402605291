import { takeLatest, select, call, put } from 'redux-saga/effects';

import GdsnApi from 'resources/gdsnApi';
import { saveAs } from 'utils';

import { notificationError } from 'actions/notifications';

import * as events from '../constants/events';

import {
  receiveGDSNPSRList,
  receiveGDSNPSRDetail,
  getGDSNPSRDetail,
} from '../actions';

import { selectPagination, selectFilters } from '../selectors';

const {
  GET_GDSN_PSR_LIST,
  ADD_GDSN_PSR_LIST_FILTER,
  REMOVE_GDSN_PSR_LIST_FILTER,
  UPDATE_GDSN_PSR_LIST_FILTER,
  CLEAR_GDSN_PSR_LIST_FILTER,
  CHANGE_LIMIT_GDSN_PSR_LIST,
  NEXT_PAGE_GDSN_PSR_LIST,
  PREVIOUS_PAGE_GDSN_PSR_LIST,
  DOWNLOAD_GDSN_PSR_DOCUMENT,
  GET_GDSN_PSR_DETAIL,
  DELETE_GDSN_PSR_ITEM,
} = events;

export default function* gdsnPSRMainSaga() {
  yield takeLatest(GET_GDSN_PSR_LIST, getGDSNPSRList);
  yield takeLatest(GET_GDSN_PSR_DETAIL, getGDSNPSRDetailSaga);
  yield takeLatest(DELETE_GDSN_PSR_ITEM, deleteGDSNPSRItem);
  yield takeLatest(DOWNLOAD_GDSN_PSR_DOCUMENT, downloadGDSNDocument);
  yield takeLatest(
    [
      ADD_GDSN_PSR_LIST_FILTER,
      REMOVE_GDSN_PSR_LIST_FILTER,
      UPDATE_GDSN_PSR_LIST_FILTER,
      CLEAR_GDSN_PSR_LIST_FILTER,
      CHANGE_LIMIT_GDSN_PSR_LIST,
      NEXT_PAGE_GDSN_PSR_LIST,
      PREVIOUS_PAGE_GDSN_PSR_LIST,
      DELETE_GDSN_PSR_ITEM,
    ],
    getGDSNPSRList
  );
}

function* getGDSNPSRDetailSaga(payload) {
  try {
    const response = yield call(
      [GdsnApi, GdsnApi.DetailPrice],
      payload.psrID,
      payload.gtin
    );
    yield put(
      receiveGDSNPSRDetail(response.data.data, payload.psrID, payload.gtin)
    );
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured: getGDSNPSRDetailSaga'));
    }
  }
}

function* deleteGDSNPSRItem(payload) {
  try {
    yield call([GdsnApi, GdsnApi.RemoveItem], payload.itemID);
    yield put(getGDSNPSRDetail(payload.psrID, payload.gtin));
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured: deleteGDSNPSRItem'));
    }
  }
}

function* getGDSNPSRList() {
  try {
    const pagination = yield select(selectPagination);
    const filters = yield select(selectFilters);
    const response = yield call([GdsnApi, GdsnApi.ListPrice], {
      filter_gln: filters.filter_gln.length ? filters.filter_gln[0].value : '',
      filter_gtins_in: filters.filter_gtins_in.length
        ? filters.filter_gtins_in[0].value
        : '',
      offset: (pagination.currentPage - 1) * pagination.limit,
      limit: pagination.limit,
    });
    yield put(
      receiveGDSNPSRList(response.data.data, response.data.totalResults)
    );
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured: getGDSNPSRList'));
    }
  }
}

function* downloadGDSNDocument(payload) {
  try {
    const response = yield call(
      [GdsnApi, GdsnApi.DownloadDocument],
      payload.documentID
    );
    const filename = `gdsn_document_psr_${payload.documentID}.xml`;
    saveAs(response.data, filename);
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured: downloadGDSNDocument'));
    }
  }
}
