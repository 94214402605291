import { MODULE_REDUCER_KEY } from 'constants/reducer';
import {
  ORGANIZATION_LIST_STORE_KEY,
  ORGANIZATION_LIST_ORG_STORE_KEY,
} from '../constants/reducers';

export function selectState(state) {
  return state[MODULE_REDUCER_KEY][ORGANIZATION_LIST_STORE_KEY][
    ORGANIZATION_LIST_ORG_STORE_KEY
  ];
}

export function selectSearch(state) {
  return selectState(state).search;
}

export function selectList(state) {
  return selectState(state).list;
}

export function selectEntities(state) {
  return selectState(state).entities;
}

export function selectPagination(state) {
  return selectState(state).pagination;
}

export function selectTotalResults(state) {
  return selectPagination(state).totalResults;
}

export function selectLimit(state) {
  return selectPagination(state).limit;
}

export function selectCurrentPage(state) {
  return selectPagination(state).currentPage;
}

export function selectFilters(state) {
  return selectState(state).filters;
}

export function selectTypeFilters(state) {
  return selectFilters(state).get('types');
}

export function selectStatusFilters(state) {
  return selectFilters(state).get('statuses');
}

export function selectSelectedFilters(state) {
  return selectFilters(state).get('selected');
}

export function selectNetworkFilters(state) {
  return selectFilters(state).get('networks');
}

export function selectGroupFilters(state) {
  return selectFilters(state).get('groups');
}

export function selectTargetMarketFilters(state) {
  return selectFilters(state).get('targetMarkets');
}

export function selectAreOrganizationsLoading(state) {
  return selectState(state).isLoading;
}
