import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ListController } from 'components/ui/list/controller';
import StatusBar from 'components/ui/status-bar';
import { Button } from '@alkem/react-ui-button';

import { selectFiles } from '../../selectors';
import { fetchFiles, fetchOneFile, downloadOneFile } from '../../actions';
import * as types from '../../types';

export function TranslationRow(props) {
  const { file } = props;
  const dispatch = useDispatch();

  return (
    <div className="ImportFileListRowWrapper">
      <div className="ImportFileListRow row">
        <StatusBar type={file.current ? 'SUCCESS' : 'EMPTY'} />
        <div className="col-xs-4">{file.filename}</div>
        <div className="col-xs-1">{file.lang}</div>
        <div className="col-xs-1">{file.key_count}</div>
        <div className="col-xs-3">{file.created_at}</div>
        <div className="col-xs-3 ImportFileListRow__actions">
          {/* {file.current === false && (
            <Button onClick={() => dispatch(updateLatestFile(file.id))}>
              <i className="mdi mdi-power-plug" />
            </Button>
          )} */}
          <Button
            onClick={() => dispatch(fetchOneFile(file.id, file.filename))}
          >
            <i className="mdi mdi-eye" />
          </Button>
          <Button
            onClick={() => dispatch(downloadOneFile(file.id, file.filename))}
          >
            <i className="mdi mdi-download" />
          </Button>
        </div>
      </div>
    </div>
  );
}

export function TranslationList(props) {
  return (
    props.files?.map((file) => (
      <TranslationRow key={`translation-${file.id}`} file={file} />
    )) || <div>No translations found</div>
  );
}

export function TranslationFiles() {
  const filesPage: types.FilesPage | null = useSelector(selectFiles);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFiles());
  }, [dispatch]);

  if (filesPage === null) {
    return null;
  }

  return (
    <ListController
      rowsLength={0}
      currentPage={0}
      totalPages={0}
      totalResults={0}
      limit={100}
      onNext={() => {}}
      onPrev={() => {}}
      type="translationlist"
    >
      <TranslationList files={filesPage.files} />
    </ListController>
  );
}
