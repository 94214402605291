import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { toJsIfImmutable } from 'utils/immutable';
import classNames from 'classnames';

import { connect } from 'react-redux';

import {
  selectFeatureStatus,
  selectSaveErrorMessage,
} from '../../selectors/save-modal';
import {
  STATUS_MAPPING,
  ORGANIZATION_FEATURES_MAPPING,
} from '../../constants/save-modal';

import './summary.scss';

const mapStateToProps = (state) => ({
  featureStatus: selectFeatureStatus(state),
  saveErrorMessage: selectSaveErrorMessage(state),
});

export class OrganizationPageSaveModalSummary extends PureComponent {
  static propTypes = {
    featureStatus: ImmutablePropTypes.map.isRequired,
    saveErrorMessage: PropTypes.string,
  };

  renderFeatureRow([featureKey, featureValue]) {
    const { value: statusValue, withWarning = null } = featureValue;
    const status = STATUS_MAPPING[statusValue];
    const feature = ORGANIZATION_FEATURES_MAPPING[featureKey];

    const iconClasses = {
      OrganizationPageSaveModalSummary__statusIcon: true,
      OrganizationPageSaveModalSummary__withWarning: Boolean(withWarning),
      [`OrganizationPageSaveModalSummary__statusIcon--${status.value}`]: true,
      [status.icon]: true,
    };

    return (
      <li key={featureKey}>
        <i className={classNames(iconClasses)} />
        {feature.label}
        <span className="OrganizationPageSaveModalSummary__withWarning--warning">
          {withWarning}
        </span>
      </li>
    );
  }

  renderErrorMessage(saveErrorMessage) {
    if (!saveErrorMessage) {
      return null;
    }

    return (
      <p className="OrganizationPageSaveModalSummary__ErrorMessage">
        {saveErrorMessage}
      </p>
    );
  }

  render() {
    const { featureStatus, saveErrorMessage } = this.props;
    return (
      <div className="OrganizationPageSaveModalSummary">
        {saveErrorMessage
          ? 'The following information could not be edited:'
          : 'The following information will be edited:'}
        <ul className="OrganizationPageSaveModalSummary__FeatureStatus">
          {Object.entries(toJsIfImmutable(featureStatus)).map(
            this.renderFeatureRow
          )}
          {this.renderErrorMessage(saveErrorMessage)}
        </ul>
      </div>
    );
  }
}

export default connect(mapStateToProps, {})(OrganizationPageSaveModalSummary);
