import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { fromJS, List, Map } from 'immutable';

import allPackagingTypes from 'constants/typePackagings';

import Copyable from 'components/copyable';
import { Modal } from '@alkem/react-ui-modal';
import { Button } from '@alkem/react-ui-button';
import { Text } from '@alkem/react-ui-inputs';
import InputWithLabel from 'components/input-with-label';
import { KindAutocomplete } from 'components/autocomplete';

import FieldPlugins from './plugins';

import './display-info.scss';

export class Translatable extends PureComponent {
  static propTypes = {
    field: PropTypes.object.isRequired,
    prop: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
  };

  render() {
    const { field, prop, children } = this.props;
    const id = field.getIn(['displayInfo', 'id']);
    const value = field.getIn(['displayInfo', prop]);
    if (!id || !value) {
      return children;
    }
    const key = `core.display_info.${id}.${prop}`;
    return (
      <div className="FieldDisplayInfo__translatable">
        {children}
        <Copyable text={key}>
          <i className="mdi mdi-web" data-testid={key}></i>
        </Copyable>
      </div>
    );
  }
}

export default class FieldDisplayInfo extends PureComponent {
  static propTypes = {
    field: PropTypes.object.isRequired,
    path: PropTypes.array.isRequired,
    update: PropTypes.func.isRequired,
    displayHeader: PropTypes.bool,
    isRoot: PropTypes.bool.isRequired,
    isConsumerUnitField: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool,
  };

  static defaultProps = {
    displayHeader: true,
  };

  state = {
    modalOpen: false,
  };

  onOpenModal = () => {
    this.setState({ modalOpen: true });
  };

  onCloseModal = () => {
    this.setState({ modalOpen: false });
  };

  onUpdateLabel = (event) => {
    const { path, update, field } = this.props;
    update(
      path,
      field.updateIn(['displayInfo'], (d) =>
        (d || Map()).set('label', event.target.value)
      )
    );
  };

  onUpdatePlaceHolder = (event) => {
    const { path, update, field } = this.props;
    update(
      path,
      field.updateIn(['displayInfo'], (d) =>
        (d || Map()).set('placeholder', event.target.value)
      )
    );
  };

  onUpdateHelp = (event) => {
    const { path, update, field } = this.props;
    update(
      path,
      field.updateIn(['displayInfo'], (d) =>
        (d || Map()).set('help', event.target.value)
      )
    );
  };

  onUpdateExamples = (event) => {
    const { path, update, field } = this.props;
    update(
      path,
      field.updateIn(['displayInfo'], (d) =>
        (d || Map()).set('examples', event.target.value)
      )
    );
  };

  onUpdateTips = (event) => {
    const { path, update, field } = this.props;
    update(
      path,
      field.updateIn(['displayInfo'], (d) =>
        (d || Map()).set('tips', event.target.value)
      )
    );
  };

  onUpdatePlugins = (value) => {
    const { path, update, field } = this.props;
    update(
      path,
      field.updateIn(['displayInfo'], (d) => (d || Map()).set('plugins', value))
    );
  };

  onAddKind = (data) => {
    if (data && data.value) {
      const { field, path, update } = this.props;
      const applicableForKinds = field.get('applicableForKinds') || List();
      const index = applicableForKinds.findIndex(
        (o) => o.getIn(['kind', 'id']) === data.value.id
      );
      if (index === -1) {
        update(
          [...path, 'applicableForKinds'],
          applicableForKinds.unshift(
            fromJS({
              kind: { id: data.value.id, label: data.value.label },
              exclusion: false,
            })
          )
        );
      }
    }
  };

  onDeleteKind = (id) => () => {
    const { field, path, update } = this.props;
    const applicableForKinds = field.get('applicableForKinds') || List();
    const index = applicableForKinds.findIndex(
      (o) => o.getIn(['kind', 'id']) === id
    );
    if (index !== -1) {
      update([...path, 'applicableForKinds'], applicableForKinds.delete(index));
    }
  };

  renderSpecific() {
    const { field, path, update, isConsumerUnitField } = this.props;
    return (
      <div>
        {isConsumerUnitField && field.get('type') !== 'copy' && (
          <>
            <div className="FieldForm__sectionTitle">Kinds</div>
            <div className="FieldDisplayInfo__addKind">
              <KindAutocomplete
                id="field-kind-add"
                value={[]}
                onSelect={this.onAddKind}
                onUnselect={this.onAddKind}
                placeholder="Add a new kind"
                searchOnClick
              />
            </div>
          </>
        )}
        {field.get('type') !== 'copy' &&
          (field.get('applicableForKinds') || List()).map((kind, index) => (
            <div
              className="FieldDisplayInfo__specificItem"
              key={kind.getIn(['kind', 'id'])}
            >
              <div className="FieldDisplayInfo__specificKindLabel">
                <pre>
                  Kind &nbsp;
                  {kind.getIn(['kind', 'label'])}
                </pre>
                <i
                  className="mdi mdi-delete"
                  onClick={this.onDeleteKind(kind.getIn(['kind', 'id']))}
                  id={`field-applicableForKinds-${index}-delete`}
                />
              </div>
              <FieldDisplayInfo
                field={kind}
                path={[...path, 'applicableForKinds', index]}
                update={update}
                isRoot={false}
                isConsumerUnitField={false}
                displayHeader={false}
              />
            </div>
          ))}
        {isConsumerUnitField && field.get('type') !== 'copy' && (
          <>
            <div className="FieldForm__sectionTitle">Packaging types</div>
            {(field.get('applicableForTypePackagings') || List()).map(
              (pt, index) => (
                <div
                  className="FieldDisplayInfo__specificItem"
                  key={pt.getIn(['typePackaging', 'id'])}
                >
                  <div className="FieldDisplayInfo__specificPackagingTypeLabel">
                    <pre>
                      Packaging type &nbsp;
                      {
                        allPackagingTypes.all[pt.getIn(['typePackaging', 'id'])]
                          .name
                      }
                    </pre>
                  </div>
                  <FieldDisplayInfo
                    field={pt}
                    path={[...path, 'applicableForTypePackagings', index]}
                    update={update}
                    isRoot={false}
                    isConsumerUnitField={false}
                    displayHeader={false}
                  />
                </div>
              )
            )}
          </>
        )}
        <div className="FieldForm__sectionTitle">Organizations</div>
        {(field.get('applicableForOrganizations') || List()).map(
          (org, index) => (
            <div
              className="FieldDisplayInfo__specificItem"
              key={org.getIn(['organization', 'id'])}
            >
              <pre>
                Organization &nbsp;
                {org.getIn(['organization', 'id'])}
              </pre>
              <FieldDisplayInfo
                field={org}
                path={[...path, 'applicableForOrganizations', index]}
                update={update}
                isRoot={false}
                isConsumerUnitField={false}
                displayHeader={false}
              />
            </div>
          )
        )}
      </div>
    );
  }

  render() {
    const { field, path, displayHeader, isRoot, readOnly } = this.props;
    const { modalOpen } = this.state;
    return (
      <div className="FieldDisplayInfo">
        {displayHeader && (
          <div className="FieldForm__sectionTitle">Display information</div>
        )}
        <InputWithLabel
          inputId={`field-${path.join('-')}-displayInfo-label`}
          label={
            field.get('type') === 'copy'
              ? 'Label (for the dashboard only)'
              : 'Label'
          }
        >
          <Translatable field={field} prop="label">
            <Text
              id={`field-${path.join('-')}-displayInfo-label`}
              value={field.getIn(['displayInfo', 'label']) || ''}
              onChange={this.onUpdateLabel}
              disabled={readOnly}
            />
          </Translatable>
        </InputWithLabel>
        {field.get('type') !== 'copy' && (
          <InputWithLabel
            inputId={`field-${path.join('-')}-displayInfo-placeholder`}
            label="Placeholder"
          >
            <Translatable field={field} prop="placeholder">
              <Text
                id={`field-${path.join('-')}-displayInfo-placeholder`}
                value={field.getIn(['displayInfo', 'placeholder']) || ''}
                onChange={this.onUpdatePlaceHolder}
                disabled={readOnly}
              />
            </Translatable>
          </InputWithLabel>
        )}
        {field.get('type') !== 'copy' && (
          <InputWithLabel
            inputId={`field-${path.join('-')}-displayInfo-help`}
            label="Help"
          >
            <Translatable field={field} prop="help">
              <Text
                id={`field-${path.join('-')}-displayInfo-help`}
                value={field.getIn(['displayInfo', 'help']) || ''}
                onChange={this.onUpdateHelp}
                disabled={readOnly}
              />
            </Translatable>
          </InputWithLabel>
        )}
        {field.get('type') !== 'copy' && (
          <InputWithLabel
            inputId={`field-${path.join('-')}-displayInfo-examples`}
            label="Examples"
          >
            <Translatable field={field} prop="examples">
              <Text
                id={`field-${path.join('-')}-displayInfo-examples`}
                value={field.getIn(['displayInfo', 'examples']) || ''}
                onChange={this.onUpdateExamples}
                disabled={readOnly}
              />
            </Translatable>
          </InputWithLabel>
        )}
        {field.get('type') !== 'copy' && (
          <InputWithLabel
            inputId={`field-${path.join('-')}-displayInfo-tips`}
            label="Tips"
          >
            <Translatable field={field} prop="tips">
              <Text
                id={`field-${path.join('-')}-displayInfo-tips`}
                value={field.getIn(['displayInfo', 'tips']) || ''}
                onChange={this.onUpdateTips}
                disabled={readOnly}
              />
            </Translatable>
          </InputWithLabel>
        )}
        {field.get('type') !== 'copy' && (
          <InputWithLabel
            inputId={`field-${path.join('-')}-displayInfo-plugins`}
            label="Plugins"
          >
            <FieldPlugins
              id={`field-${path.join('-')}-displayInfo-plugins`}
              plugins={field.getIn(['displayInfo', 'plugins']) || Map()}
              update={this.onUpdatePlugins}
              readOnly={readOnly}
            />
          </InputWithLabel>
        )}
        {!readOnly && isRoot && (
          <div className="FieldDisplayInfo__specific">
            <Button
              content="Edit specific display information"
              onClick={this.onOpenModal}
              secondary
            />
          </div>
        )}
        {!readOnly && modalOpen && (
          <Modal
            title="Specific display information"
            onConfirm={this.onCloseModal}
            closeButtonText="Close"
            onClose={this.onCloseModal}
          >
            {this.renderSpecific()}
          </Modal>
        )}
      </div>
    );
  }
}
