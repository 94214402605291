import { MODULE_REDUCER_KEY } from 'constants/reducer';
import { flow } from 'lodash';
import { curry, get } from 'lodash/fp';
import {
  PaginationState,
  Rule,
  RuleSet,
  ValidationSelectedFilters,
} from 'modules/validation-dashboard/types';
import { GlobalState } from 'utils/reducers/types';
import { VALIDATION_DASHBOARD_STORE_KEY } from './constants';
import { RuleSetTagsFetchState, ValidationDashboardState } from './moduleState';

const selectValidationState = (state) =>
  state[MODULE_REDUCER_KEY][VALIDATION_DASHBOARD_STORE_KEY];

export const selectRuleSetTags = flow<
  [GlobalState],
  ValidationDashboardState,
  RuleSetTagsFetchState
>(
  selectValidationState,
  (state: ValidationDashboardState) => state?.ruleSetTags
);

export const selectValidationRules = flow<
  [GlobalState],
  ValidationDashboardState,
  Rule[]
>(selectValidationState, (state: ValidationDashboardState) => state?.rules);

export const selectValidationEditingRule = flow<
  [GlobalState],
  ValidationDashboardState,
  Rule
>(
  selectValidationState,
  (state: ValidationDashboardState) => state?.editingRule
);

export const selectValidationIsSaving = curry(
  (ruleId: Rule['id'], state: GlobalState) =>
    flow<[GlobalState], ValidationDashboardState, boolean>(
      selectValidationState,
      get(['isSavingRules', ruleId])
    )(state)
);

export const selectValidationDirtyRule = flow<
  [GlobalState],
  ValidationDashboardState,
  Rule
>(selectValidationState, (state: ValidationDashboardState) => state?.dirtyRule);

export const selectValidationFiltersSearch = flow<
  [GlobalState],
  ValidationDashboardState,
  string
>(
  selectValidationState,
  (state: ValidationDashboardState) => state?.filters?.search
);

export const selectSelectedRules = flow<
  [GlobalState],
  ValidationDashboardState,
  { [id: number]: boolean }
>(
  selectValidationState,
  (state: ValidationDashboardState) => state?.selectedRules || {}
);

export const selectValidationSelectedFilters = flow<
  [GlobalState],
  ValidationDashboardState,
  ValidationSelectedFilters
>(
  selectValidationState,
  (state: ValidationDashboardState) => state?.filters?.selectedFilters
);

export const selectValidationCachedOrganizations = flow<
  [GlobalState],
  ValidationDashboardState,
  { [orgID: string]: string }
>(
  selectValidationState,
  (state: ValidationDashboardState) => state?.cachedOrganizations
);

export const selectValidationPagination = flow<
  [GlobalState],
  ValidationDashboardState,
  PaginationState
>(
  selectValidationState,
  (state: ValidationDashboardState) => state?.pagination
);

export const selectValidationIsLoading = flow<
  [GlobalState],
  ValidationDashboardState,
  boolean
>(selectValidationState, (state: ValidationDashboardState) => state?.isLoading);

export const selectValidationIsLoadingRule = flow<
  [GlobalState],
  ValidationDashboardState,
  boolean
>(
  selectValidationState,
  (state: ValidationDashboardState) => state?.isLoadingRule
);

export const selectValidationIsLoadingDimensions = flow<
  [GlobalState],
  ValidationDashboardState,
  boolean
>(
  selectValidationState,
  (state: ValidationDashboardState) => state?.isLoadingDimensions
);

export const selectValidationRuleSet = flow<
  [GlobalState],
  ValidationDashboardState,
  RuleSet
>(selectValidationState, (state: ValidationDashboardState) => state?.ruleSet);
