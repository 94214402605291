import { Button } from '@alkem/react-ui-button';
import { Spinner } from '@alkem/react-ui-spinner';
import Dropdown from 'components/dropdown';
import { STATUS_DECOMMISSIONED } from 'constants/organization';
import * as routes from 'constants/routes';
import { List } from 'immutable';
import { noop } from 'lodash';
import {
  PERMISSION_LOG_AS_USER_SHOW,
  PERMISSION_LOG_AS_USER_UPDATE,
  PERMISSION_ORGANIZATION_UPDATE,
  PERMISSION_USER_SHOW,
} from 'modules/access-policy/common/constants';
import { User } from 'modules/auth/types';
import { OrganizationPageSettingFlags } from 'modules/settings/organization';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { selectUser } from 'reducers/user';
import { DispatchType } from 'types/redux';
import { isAlkemicsSource } from 'utils/organization';
import {
  getOrganization,
  hasFullPower,
  isAtLeastPlatformAdmin,
} from 'utils/user';
import {
  getManagedOrganizationPermissions,
  retrieveOrganization,
} from '../actions';
import { loadApplications } from '../actions/applications';
import { exportAction } from '../actions/imports';
import { importInfos } from '../actions/infos';
import { openSaveModal } from '../actions/save-modal';
import { importSettings } from '../actions/settings';
import {
  isSaveButtonAvailable,
  selectIsOrganizationLoaded,
  selectManagedOrganizationPermissions,
} from '../selectors';
import { selectIsRetailer, selectOrganization } from '../selectors/infos';
import { selectSettings } from '../selectors/settings';
import OrganizationApplications from './applications';
import OrganizationBilling from './billing';
import OrganizationGLN from './gln';
import { ImportModal } from './import-modal';
import './index.scss';
import OrganizationInfos from './infos';
import { OrganizationLinks } from './links';
import { OrganizationPermissions } from './permissions';
import OrganizationRecipients from './recipients';
import { OrganizationPageResyncModal } from './resync-modal';
import { OrganizationPageReviewTriggerModal } from './review-trigger-modal';
import SaveModal from './save-modal';
import OrganizationSettings from './settings';
import OrganizationListColumnSetting from './settings/listcolumn';
import { OrganizationSSO } from './sso';
import { OrganizationPageUsers } from './users/index';
import { useHistory } from 'react-router';
import qs from 'querystringify';

const OrganizationSpinner = () => (
  <div className="OrganizationPage__spinner">
    <Spinner loading big />
  </div>
);

const OrganizationUserList = ({
  isReadOnly,
  permissions,
  user,
}: {
  isReadOnly: boolean;
  permissions: List<string>;
  user: any;
}) => {
  if (
    !isReadOnly &&
    !hasFullPower(user) &&
    (!permissions || !permissions.contains(PERMISSION_USER_SHOW))
  ) {
    return null;
  }

  const displayLogas =
    hasFullPower(user) ||
    (permissions &&
      (permissions.contains(PERMISSION_LOG_AS_USER_SHOW) ||
        permissions.contains(PERMISSION_LOG_AS_USER_UPDATE)));

  return (
    <OrganizationPageUsers
      isReadOnly={isReadOnly}
      displayLogas={displayLogas}
    />
  );
};

const OrganizationPageContent = ({
  isReadOnly,
  organizationId,
}: {
  isReadOnly: boolean;
  organizationId: number;
}) => {
  const user: User = useSelector(selectUser);
  const settings = useSelector(selectSettings);
  const permissions = useSelector(selectManagedOrganizationPermissions);

  return (
    <div className="OrganizationPage__content">
      <div className="OrganizationPage__column">
        <OrganizationInfos isReadOnly={isReadOnly} />
        <OrganizationLinks user={user} organizationId={organizationId} />
        {hasFullPower(user) && (
          <OrganizationSSO
            organizationId={organizationId}
            isReadOnly={isReadOnly}
          />
        )}
        {settings.some(
          (value, key) => key?.startsWith('permission-v3-') && value
        ) && <OrganizationPermissions organizationId={organizationId} />}
        <OrganizationGLN isReadOnly={isReadOnly} />
        <OrganizationUserList
          isReadOnly={isReadOnly}
          user={user}
          permissions={permissions}
        />
        <OrganizationRecipients isReadOnly={isReadOnly} />
        <OrganizationApplications isReadOnly={isReadOnly} />
        <OrganizationPageSettingFlags
          organizationId={organizationId}
          organizationSettings={settings}
          isReadOnly={isReadOnly}
        />
        {isAlkemicsSource(getOrganization(user)) && (
          <OrganizationBilling
            organizationId={organizationId}
            isReadOnly={isReadOnly}
          />
        )}
      </div>
      <div className="OrganizationPage__column">
        <OrganizationSettings isReadOnly={isReadOnly} />
        <OrganizationListColumnSetting isReadOnly={isReadOnly} />
      </div>
    </div>
  );
};

export const OrganizationPage = React.memo(() => {
  const dispatch: DispatchType = useDispatch();

  const organizationLoaded = useSelector(selectIsOrganizationLoaded);
  const organization = useSelector(selectOrganization);
  const organizationName = organization.get('nameLegal');
  const organizationStatus = organization.get('status');
  const permissions = useSelector(selectManagedOrganizationPermissions);
  const saveButtonAvailable = useSelector(isSaveButtonAvailable);
  const isRetailer = useSelector(selectIsRetailer);
  const user: User = useSelector(selectUser);
  const settings = useSelector(selectSettings);
  const { id: _organizationId } = useParams<{ id: string }>();
  const organizationId = useMemo(
    () => Number(_organizationId) || undefined,
    [_organizationId]
  );

  const [resyncModalOpen, setResyncModalOpen] = useState(false);
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [reviewTriggerModalOpen, setReviewTriggerModalOpen] = useState(false);
  const history = useHistory();

  const isReadOnly = useMemo(
    () =>
      Boolean(
        organizationStatus === STATUS_DECOMMISSIONED ||
          (!hasFullPower(user) &&
            (!permissions ||
              !permissions.contains(PERMISSION_ORGANIZATION_UPDATE)))
      ),
    [organizationStatus, user, permissions]
  );

  const onExport = useCallback(() => {
    dispatch(exportAction());
  }, [dispatch]);

  const exportOptions = useMemo(
    () => [
      {
        key: 'export',
        label: 'Export',
        onClick: onExport,
      },
      {
        key: 'import',
        label: 'Import',
        onClick: () => setImportModalOpen(true),
      },
    ],
    [onExport, setImportModalOpen]
  );

  useEffect(() => {
    if (!organizationId) {
      return;
    }
    dispatch(retrieveOrganization(organizationId));
    dispatch(getManagedOrganizationPermissions(organizationId));
  }, [dispatch, organizationId]);

  const onReindex = useCallback(() => {
    history.push(
      `${routes.indexerQueue}${qs.stringify(
        {
          org: organizationId !== null ? organizationId : null,
          openModal: true,
        },
        true
      )}`
    );
  }, [history]);

  const onImport = useCallback(
    ({ applications, infos, settings }) => {
      Promise.all([
        dispatch(loadApplications(applications)),
        dispatch(importInfos(infos)),
        dispatch(importSettings(settings)),
      ]).then(() => setImportModalOpen(false));
    },
    [dispatch]
  );

  const onSaveButtonClick = () => {
    // console.log('LOL', openSaveModal());
    dispatch(openSaveModal());
  };

  if (!organizationId) {
    return null;
  }

  return (
    <div className="OrganizationPage">
      <SaveModal />
      {resyncModalOpen && (
        <OrganizationPageResyncModal
          organizationId={organizationId}
          scheduleType={isRetailer ? 'target_resync' : null}
          close={() => setResyncModalOpen(false)}
        />
      )}
      {importModalOpen && (
        <ImportModal
          onImport={onImport}
          onClose={() => setImportModalOpen(false)}
        />
      )}
      {reviewTriggerModalOpen && (
        <OrganizationPageReviewTriggerModal
          organizationId={organizationId}
          close={() => setReviewTriggerModalOpen(false)}
        />
      )}

      <header className="OrganizationPage__header">
        <div className="OrganizationPage__actionLeft">
          <Link to={routes.organizations}>Back to list</Link>
          <h1 className="OrganizationPage__title">
            Organization {organizationId}
            {organizationName && ` - ${organizationName}`}
          </h1>
        </div>
        {organizationLoaded && !isReadOnly && (
          <div
            data-testid="right-actions"
            className="OrganizationPage__actionRight"
          >
            <Dropdown
              label="Options"
              options={exportOptions}
              selectOptions={noop}
              rightDropdown
            />
            <Button
              primary
              className="OrganizationPage__reindex"
              content="Reindex"
              onClick={onReindex}
            />
            {isAtLeastPlatformAdmin(user) && (
              <Button
                primary
                className="OrganizationPage__resync"
                content="Resync"
                onClick={() => setResyncModalOpen(true)}
              />
            )}
            {isAtLeastPlatformAdmin(user) && settings.get('review-engine') && (
              <Button
                primary
                className="OrganizationPage__review-trigger"
                content="Review trigger"
                onClick={() => setReviewTriggerModalOpen(true)}
              />
            )}
            <Button
              primary
              className="OrganizationPage__save"
              content="Save"
              testid="save-organization"
              onClick={onSaveButtonClick}
              disabled={!saveButtonAvailable}
            />
          </div>
        )}
      </header>
      <div className="OrganizationPage__container">
        {organizationLoaded ? (
          <OrganizationPageContent
            organizationId={organizationId}
            isReadOnly={isReadOnly}
          />
        ) : (
          <OrganizationSpinner />
        )}
      </div>
    </div>
  );
});

export default OrganizationPage;
