import { AuthApi } from '@alkem/sdk-dashboard';

export const immutableAuthApi = new AuthApi({
  immutable: true,
  cacheEnabled: true,
  acceptLanguage: 'en',
});

export const authApi = new AuthApi({
  cacheEnabled: true,
  acceptLanguage: 'en',
});

export default authApi;
