import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { Button } from '@alkem/react-ui-button';

import NodeInformation from '../node';
import NewGroupModal from './new-group-modal';

import './side-panel.scss';

class SidePanel extends PureComponent {
  state = {
    newGroupModalOpen: false,
  };

  onTriggerNodeVisibility = (nodeId) => () => {
    this.props.onTriggerNodeVisibility(nodeId);
  };

  onHideUnrelated = () => {
    const { selectedNode, onHideUnrelatedNodes } = this.props;
    onHideUnrelatedNodes(selectedNode.id);
  };

  onAddToGroup = (group) => () => {
    const { selectedNode, onAddToGroup } = this.props;
    onAddToGroup([selectedNode.id], group);
  };

  onDissolveGroup = () => {
    const { selectedNode, onDissolveGroup } = this.props;
    onDissolveGroup(selectedNode.id);
  };

  onCreateGroup = (groupName) => {
    const { selectedNode, onAddToGroup } = this.props;
    onAddToGroup([selectedNode.id], groupName);
    this.onCloseNewGroupModal();
  };

  onOpenNewGroupModal = () => {
    this.setState({ newGroupModalOpen: true });
  };

  onCloseNewGroupModal = () => {
    this.setState({ newGroupModalOpen: false });
  };

  renderSummary() {
    const { graph, hiddenNodes, onSelectNode, runResults } = this.props;

    return (
      <div>
        <NodeInformation
          node={graph}
          edges={graph.edges}
          runResults={runResults}
        />
        <h4>
          <strong>Nodes:</strong>
        </h4>
        <ul className="SidePanel__summary">
          <li className="SidePanel__summaryRow SidePanel__summaryRowHeader">
            <span>
              <strong>id</strong>
            </span>
            <span className="justify-self--center">
              <strong>in</strong>
            </span>
            <span className="justify-self--center">
              <strong>out</strong>
            </span>
            <i className="mdi mdi-eye justify-self--center" />
          </li>
          {graph.nodes
            .filter((e, i) => graph.nodes.findIndex((o) => o.id === e.id) === i)
            .sort((a, b) => a.id.localeCompare(b.id))
            .map((node) => (
              <li className="SidePanel__summaryRow" key={node.id}>
                <span
                  className="SidePanel__summaryRowId"
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={() => onSelectNode(node.id)}
                >
                  {node.id}
                </span>
                <span className="justify-self--center">
                  {graph.edges.filter((e) => e.targetId === node.id).length}
                </span>
                <span className="justify-self--center">
                  {graph.edges.filter((e) => e.sourceId === node.id).length}
                </span>
                <i
                  className={classNames('mdi justify-self--center', {
                    'mdi-eye': !hiddenNodes.includes(node.id),
                    'mdi-eye-off': hiddenNodes.includes(node.id),
                  })}
                  onClick={this.onTriggerNodeVisibility(node.id)}
                />
              </li>
            ))}
        </ul>
      </div>
    );
  }

  render() {
    const {
      graph,
      selectedNode,
      onLoadSubgraph,
      runResults,
      collapsed,
      groups,
    } = this.props;
    const { newGroupModalOpen } = this.state;

    if (collapsed) {
      return (
        <div
          className="SidePanel SidePanel--collapsed"
          // eslint-disable-next-line react/jsx-no-bind
          onClick={() => this.props.onCollapse(false)}
        >
          <div className="ProductWorkflowPlayground__panel__content">
            {selectedNode.id ? (
              <span>
                {selectedNode.id}{' '}
                {selectedNode.type ? <code>{selectedNode.type}</code> : null}
              </span>
            ) : (
              <em>No node selected</em>
            )}
          </div>
          <div>
            <button type="button" className="btn btn-link">
              {'<<'}
            </button>
          </div>
        </div>
      );
    }

    let actions = [
      {
        label: 'Hide',
        onClick: this.onTriggerNodeVisibility(selectedNode.id),
      },
      {
        label: 'Hide unrelated nodes',
        onClick: this.onHideUnrelated,
      },
    ];

    if (selectedNode.type !== 'group') {
      actions = actions.concat([
        ...groups.map((g) => ({
          label: `Add node to group ${g}`,
          onClick: this.onAddToGroup(g),
        })),
        {
          label: 'Add to a new group',
          onClick: this.onOpenNewGroupModal,
        },
      ]);
    } else {
      actions.push({
        label: 'Dissolve group',
        onClick: this.onDissolveGroup,
      });
    }

    return (
      <div className="SidePanel">
        {selectedNode.id ? (
          <NodeInformation
            node={selectedNode}
            edges={graph.edges}
            runResults={runResults}
            actions={actions}
          />
        ) : (
          this.renderSummary()
        )}
        <div className="ProductWorkflowPlayground__panel__hideButton">
          {selectedNode.type !== 'cluster' && selectedNode.pkg === 'graphs' && (
            <Button
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() =>
                onLoadSubgraph(selectedNode.filename || selectedNode.type)
              }
              success
              content="Load sub graph"
            />
          )}
          <button
            type="button"
            className="btn btn-link"
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => this.props.onCollapse(true)}
          >
            Hide {'>>'}
          </button>
        </div>
        {newGroupModalOpen && (
          <NewGroupModal
            onClose={this.onCloseNewGroupModal}
            onCreate={this.onCreateGroup}
          />
        )}
      </div>
    );
  }
}

SidePanel.propTypes = {
  graph: PropTypes.object.isRequired,
  hiddenNodes: PropTypes.array,
  selectedNode: PropTypes.object.isRequired,
  runResults: PropTypes.object.isRequired,
  collapsed: PropTypes.bool.isRequired,
  onCollapse: PropTypes.func.isRequired,
  onLoadSubgraph: PropTypes.func,
  onTriggerNodeVisibility: PropTypes.func,
  onSelectNode: PropTypes.func.isRequired,
  onHideUnrelatedNodes: PropTypes.func,
  groups: PropTypes.array,
  onAddToGroup: PropTypes.func.isRequired,
  onDissolveGroup: PropTypes.func.isRequired,
};

SidePanel.defaultProps = {
  hiddenNodes: [],
  onLoadSubgraph() {},
  onTriggerNodeVisibility() {},
  onHideUnrelatedNodes() {},
  groups: [],
};

export default SidePanel;
