import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class ToggableElement extends PureComponent {
  static propTypes = {
    containerCssClasses: PropTypes.string,
    headerCssClasses: PropTypes.string,
    bodyCssClasses: PropTypes.string,
    header: PropTypes.object.isRequired,
    body: PropTypes.object,
    hidden: PropTypes.bool,
    showCaret: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = { isHidden: this.props.hidden };
  }

  toggleHidden = () => {
    this.setState((prevState) => ({
      isHidden: !prevState.isHidden,
    }));
  };

  renderCaret() {
    if (this.props.showCaret) {
      return (
        <i
          className={
            this.state.isHidden ? 'fa fa-caret-right' : 'fa fa-caret-down'
          }
          style={{ float: 'left' }}
        >
          &nbsp; &nbsp;
        </i>
      );
    }
    return null;
  }

  render() {
    return (
      <div className={this.props.containerCssClasses}>
        <div
          className={this.props.headerCssClasses}
          onClick={this.toggleHidden}
        >
          {this.renderCaret()}
          {this.props.header}
        </div>
        <div className={this.props.bodyCssClasses}>
          {!this.state.isHidden && this.props.body}
        </div>
      </div>
    );
  }
}
