import { Text } from '@alkem/react-ui-inputs';
import { Modal } from '@alkem/react-ui-modal';
import InputWithLabel from 'components/input-with-label';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { separateActions } from 'utils/redux';
import { closeRCIModal, saveGDSNRCI, updateGDSNRCIAttr } from '../actions';
import { selectModal } from '../selectors';
import './modal.scss';

const mapStateToProps = (state) => ({
  rci: selectModal(state).rci,
  isOpen: selectModal(state).isOpen,
});

const mapDispatchToProps = {
  saveGDSNRCI,
  updateGDSNRCIAttr,
  closeRCIModal,
};

export class GDSNRCIModal extends Component {
  static propTypes = {
    rci: PropTypes.object,
    isOpen: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      closeRCIModal: PropTypes.func.isRequired,
      saveGDSNRCI: PropTypes.func.isRequired,
      updateGDSNRCIAttr: PropTypes.func.isRequired,
    }).isRequired,
  };

  onChange = (field) => (event) => {
    this.props.actions.updateGDSNRCIAttr([field], event.target.value);
  };

  render() {
    const { actions, rci, isOpen } = this.props;
    if (!isOpen) {
      return null;
    }
    const title = rci.get('id')
      ? `Updating Catalog Item ${rci.get('name')} (${rci.get('id')})`
      : 'Register Catalog Item';
    return (
      <Modal
        title={title}
        onClose={actions.closeRCIModal}
        confirmButtonText="Save"
        onConfirm={actions.saveGDSNRCI}
      >
        <div className="GDSNRCIModal_Body">
          <div className="GDSNRCIModal__Form">
            <div className="GDSNRCIModal__Line">
              <InputWithLabel
                inputId={`rci-${rci.get('id')}-gtin`}
                label="GTIN"
              >
                <Text
                  id={`rci-${rci.get('id')}-gtin`}
                  value={rci.get('gtin')}
                  placeholder="gtin of the product"
                  onChange={this.onChange('gtin')}
                />
              </InputWithLabel>
              <InputWithLabel
                inputId={`rci-${rci.get('id')}-party_owner_gln`}
                label="GLN"
              >
                <Text
                  id={`rci-${rci.get('id')}-party_owner_gln`}
                  value={rci.get('party_owner_gln')}
                  placeholder="GLN of the party"
                  onChange={this.onChange('party_owner_gln')}
                />
              </InputWithLabel>
              <InputWithLabel inputId={`rci-${rci.get('id')}-gpc`} label="GPC">
                <Text
                  id={`rci-${rci.get('id')}-gpc`}
                  value={rci.get('gpc')}
                  placeholder="gpc of the product"
                  onChange={this.onChange('gpc')}
                />
              </InputWithLabel>
              <InputWithLabel
                inputId={`rci-${rci.get('id')}-target_market`}
                label="Target Market"
              >
                <Text
                  id={`rci-${rci.get('id')}-target_market`}
                  value={rci.get('target_market')}
                  placeholder="target market number"
                  onChange={this.onChange('target_market')}
                />
              </InputWithLabel>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(GDSNRCIModal);
