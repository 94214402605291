import { Xpath, XpathList } from '../../../types';
import { useState } from 'react';
import classNames from 'classnames';

import './list-element.scss';

interface Props {
  element: Xpath;
  renderChildren: (children: XpathList) => JSX.Element;
  selectElement: (element: Xpath) => void;
  updateShowModal: (element: Xpath) => void;
}

export function ListElement({
  element,
  renderChildren,
  selectElement,
  updateShowModal,
}: Props) {
  const [showChildren, setShowChildren] = useState(false);

  return (
    <div className="GDSNExportMappingsListElement" data-testid={element.id}>
      <div className="GDSNExportMappingsListElement__listDisplay">
        <i
          data-testid={`chevron-${element.id}`}
          className={classNames({
            mdi: true,
            'mdi-chevron-down': showChildren,
            'mdi-chevron-right': !showChildren,
          })}
          onClick={() => setShowChildren(!showChildren)}
        />
        <div
          className="GDSNExportMappingsListElement__elementName"
          onClick={() => selectElement(element)}
        >
          {element.name}
        </div>
        <i
          className="mdi mdi-plus"
          data-testid={`plus-${element.id}`}
          onClick={() => updateShowModal(element)}
        />
      </div>
      {showChildren && renderChildren(element.children)}
    </div>
  );
}
