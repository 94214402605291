import { MODULE_REDUCER_KEY } from 'constants/reducer';
import {
  ORGANIZATION_LIST_STORE_KEY,
  ORGANIZATION_LIST_TM_STORE_KEY,
} from '../constants/reducers';

export function selectState(state) {
  return state[MODULE_REDUCER_KEY][ORGANIZATION_LIST_STORE_KEY][
    ORGANIZATION_LIST_TM_STORE_KEY
  ];
}

export function selectTargetMarketList(state) {
  return selectState(state).list;
}

export function selectFirstTargetMarket(state) {
  return selectTargetMarketList(state).first();
}
