import { HeaderLayout } from '@alkem/react-layout';
import { Button } from '@alkem/react-ui-button';
import * as routes from 'constants/routes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { edaApi } from 'resources/edaApi';
import { logError } from 'utils';
import { Queue } from '../types';
import './index.scss';
import { EdaRmqReplayerQueue } from './row';

export function EdaRmqReplayer() {
  const [queues, setQueues] = useState<Queue[]>([]);
  const [isLoading, setLoading] = useState(false);

  const maxCountLength = useMemo(() => {
    if (queues.length) {
      return [...queues]
        .sort((a, b) => b.messages_ready - a.messages_ready)[0]
        .messages_ready.toString().length;
    }
    return 1;
  }, [queues]);

  const messageCount = useMemo(
    () => queues.reduce((acc, queue) => acc + queue.messages_ready, 0),
    [queues]
  );

  const onRefresh = useCallback(() => fetchQueues(), []);

  useEffect(() => {
    onRefresh();
  }, [onRefresh]);

  async function fetchQueues() {
    setLoading(true);
    try {
      const res = await edaApi.RmqListErrorQueues();
      setQueues(res.data.data as Queue[]);
    } catch (err) {
      logError(err);
    }
    setLoading(false);
  }

  return (
    <div>
      <HeaderLayout
        title="EDA | RMQ: Replayer"
        backHref={routes.home}
        backMessage="Back home"
        isTitleSmall
      />
      <div className="EdaRmqReplayer__list">
        <div className="EdaRmqReplayer__actions">
          <Button
            onClick={onRefresh}
            disabled={isLoading}
            testid="list-refresh-button"
            secondary
          >
            {isLoading ? (
              <i className="mdi mdi-loading mdi-spin" />
            ) : (
              <i className="mdi mdi-refresh" />
            )}
          </Button>
          {queues.length > 0 && (
            <div className="EdaRmqReplayer__summary">
              <strong>{queues.length}</strong>{' '}
              {queues.length === 1 ? 'queue' : 'queues'} with{' '}
              <strong>{messageCount}</strong>{' '}
              {messageCount === 1 ? 'message' : 'messages'}
            </div>
          )}
        </div>
        {queues.map((queue) => (
          <EdaRmqReplayerQueue
            key={queue.name}
            queue={queue}
            maxCountLength={maxCountLength}
            onRefresh={onRefresh}
          />
        ))}
      </div>
    </div>
  );
}
