export const INIT = 'indexer/pump/INIT';

export const FETCH_BUFFERED_CONFIGS = 'indexer/pump/FETCH_BUFFERED_CONFIGS';

export const CREATE_BUFFERED_CONFIG = 'indexer/pump/CREATE_BUFFERED_CONFIG';
export const RECEIVE_BUFFERED_CONFIGS = 'indexer/pump/RECEIVE_BUFFERED_CONFIGS';
export const UPDATE_BUFFERED_CONFIG = 'indexer/pump/UPDATE_BUFFERED_CONFIG';
export const DELETE_BUFFERED_CONFIG = 'indexer/pump/DELETE_BUFFERED_CONFIG';
export const TOGGLE_BUFFERED_CONFIGS = 'indexer/pump/TOGGLE_BUFFERED_CONFIGS';

export const FETCH_CONFIG_SOURCES = 'indexer/pump/FETCH_CONFIG_SOURCES';
export const RECEIVE_CONFIG_SOURCES = 'indexer/pump/RECEIVE_CONFIG_SOURCES';

export const FETCH_BATCH_CONFIGS = 'indexer/pump/FETCH_BATCH_CONFIGS';
export const CREATE_BATCH_CONFIG = 'indexer/pump/CREATE_BATCH_CONFIG';
export const RECEIVE_BATCH_CONFIGS = 'indexer/pump/RECEIVE_BATCH_CONFIGS';
export const UPDATE_BATCH_CONFIG = 'indexer/pump/UPDATE_BATCH_CONFIG';
export const DELETE_BATCH_CONFIG = 'indexer/pump/DELETE_BATCH_CONFIG';
