import { HeaderLayout } from '@alkem/react-layout';
import { AddButton, Button } from '@alkem/react-ui-button';
import { Text } from '@alkem/react-ui-inputs';
import { displayGroupDashboardAccessPolicy } from 'access-policies';
import { refreshCache } from 'actions/fields';
import { notificationError } from 'actions/notifications';
import * as routes from 'constants/routes';
import { selectUserReadOnlyAccess } from 'modules/auth/selectors';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { separateActions } from 'utils/redux';
import {
  createDisplayGroup,
  loadDisplayGroups,
  updateData,
  updateDisplayGroup,
  deleteDisplayGroup,
  updateFields,
} from './actions';
import { selectDisplayGroups } from './selectors';
import './views.scss';

const mapStateToProps = createStructuredSelector({
  displayGroup: selectDisplayGroups,
  refreshingCache: (state) => state.fields.get('refreshingCache'),
  readOnly: selectUserReadOnlyAccess(displayGroupDashboardAccessPolicy),
});

const mapDispatchToProps = {
  loadDisplayGroups,
  updateFields,
  updateData,
  createDisplayGroup,
  updateDisplayGroup,
  deleteDisplayGroup,
  refreshCache,
  error: notificationError,
};

export class DisplayGroupView extends PureComponent {
  static propTypes = {
    displayGroup: PropTypes.object,
    refreshingCache: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      loadDisplayGroups: PropTypes.func.isRequired,
      updateFields: PropTypes.func.isRequired,
      updateData: PropTypes.func.isRequired,
      createDisplayGroup: PropTypes.func.isRequired,
      updateDisplayGroup: PropTypes.func.isRequired,
      deleteDisplayGroup: PropTypes.func.isRequired,
      refreshCache: PropTypes.func.isRequired,
      error: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = {
    process: 'load',
    editedDisplayGroupId: null,
    path: [], // { id, index, label }
  };

  componentDidMount() {
    this.props.actions
      .loadDisplayGroups()
      .then(this.resetProcessState, this.resetProcessState);
  }

  onSelectRootDisplayGroup = () => {
    if (this.state.process === 'edit') {
      return this.props.actions.error(
        'You have unsaved changes, reload to cancel changes'
      );
    }
    return this.setState({ path: [] });
  };

  onSelectDisplayGroup = (index) => () => {
    const { process, path } = this.state;
    if (process === 'edit') {
      return this.props.actions.error(
        'You have unsaved changes, reload to cancel changes'
      );
    }
    return this.setState({ path: path.slice(0, index + 1) });
  };

  onSelectChildDisplayGroup = (index, label, id) => () => {
    const { process, path } = this.state;
    if (process === 'edit') {
      return this.props.actions.error(
        'You have unsaved changes, reload to cancel changes'
      );
    }
    return this.setState({ path: [...path, { index, label, id }] });
  };

  onAddDisplayGroup = () => {
    if (this.state.process === 'edit') {
      return this.props.actions.error(
        'You have unsaved changes, save to create a display group'
      );
    }
    const displayGroup = {
      parent_id: this.getCurrentDisplayGroup().get('id') || null,
      rank: 10000, // So that it gets added last.
    };
    this.setState({ process: 'create' });
    return this.props.actions
      .createDisplayGroup(displayGroup)
      .then(this.resetProcessState, this.resetProcessState);
  };

  onDeleteDisplayGroup = (displayGroupId) => () => {
    if (this.state.process === 'edit') {
      return this.props.actions.error(
        'You have unsaved changes, save to delete a display group'
      );
    }
    this.setState({ process: 'delete' });
    return this.props.actions
      .deleteDisplayGroup(displayGroupId)
      .then(this.resetProcessState, this.resetProcessState);
  };

  onUpdateDisplayGroupLabel = (displayGroupId) => (event) => {
    this.onUpdateDisplayGroupData(displayGroupId, event, 'label');
  };

  onUpdateDisplayGroupController = (displayGroupId) => (event) => {
    this.onUpdateDisplayGroupData(displayGroupId, event, 'controller');
  };

  onUpdateDisplayGroupRank = (displayGroupId) => (event) => {
    this.onUpdateDisplayGroupData(displayGroupId, event, 'rank');
  };

  onUpdateDisplayGroupData(displayGroupId, event, key) {
    const { editedDisplayGroupId } = this.state;
    if (editedDisplayGroupId && editedDisplayGroupId !== displayGroupId) {
      return this.props.actions.error(
        'You have unsaved changes, save the edited display group to edit something else'
      );
    }
    const path = this.getRealPath();
    const index = this.getCurrentDisplayGroup()
      .get('children')
      .findIndex((dg) => dg.get('id') === displayGroupId);
    if (this.state.process !== 'edit') {
      this.setState({ process: 'edit', editedDisplayGroupId: displayGroupId });
    }
    return this.props.actions.updateData(
      [...path, 'children', `${index}`, key],
      event.target.value
    );
  }

  onUpdateFieldRank = (fieldId) => (event) => {
    if (this.state.editedDisplayGroupId) {
      return this.props.actions.error(
        'You have unsaved changes, save the edited display group to edit something else'
      );
    }
    const path = this.getRealPath();
    const index = this.getCurrentDisplayGroup()
      .get('fields')
      .findIndex((f) => f.getIn(['field', 'id']) === fieldId);
    if (this.state.process !== 'edit') {
      this.setState({ process: 'edit' });
    }
    return this.props.actions.updateData(
      [...path, 'fields', `${index}`, 'rank'],
      event.target.value
    );
  };

  onSave = () => {
    const { editedDisplayGroupId } = this.state;
    this.setState({ process: 'update' });
    if (editedDisplayGroupId) {
      const displayGroup = this.getCurrentDisplayGroup()
        .get('children')
        .find((dg) => dg.get('id') === editedDisplayGroupId);
      if (!displayGroup) {
        return this.props.actions.error('Error saving display group');
      }
      return this.props.actions
        .updateDisplayGroup(displayGroup)
        .then(this.resetProcessState, this.resetProcessState);
    }
    return this.props.actions
      .updateFields(this.getCurrentDisplayGroup())
      .then(this.resetProcessState, this.resetProcessState);
  };

  onRefreshCache = () => {
    this.props.actions.refreshCache();
  };

  getRealPath() {
    const { path } = this.state;
    return [].concat(...path.map((p) => ['children', `${p.index}`]));
  }

  getCurrentDisplayGroup() {
    const { displayGroup } = this.props;
    const { path } = this.state;
    if (!path.length || !displayGroup) {
      return displayGroup;
    }
    const realPath = this.getRealPath();
    return displayGroup.getIn(realPath);
  }

  getSaveButtonProps() {
    const { process } = this.state;
    switch (process) {
      case 'load':
        return {
          content: 'Loading...',
          disabled: true,
          displaySpinner: true,
          plain: true,
        };
      case 'update':
        return {
          content: 'Updating...',
          disabled: true,
          displaySpinner: true,
          success: true,
        };
      case 'edit':
        return {
          content: 'Save',
          disabled: false,
          displaySpinner: false,
          primary: true,
          onClick: this.onSave,
        };
      case 'create':
        return {
          content: 'Creating...',
          disabled: true,
          displaySpinner: true,
          success: true,
        };
      case 'delete':
        return {
          content: 'Deleting...',
          disabled: true,
          displaySpinner: true,
          success: true,
        };
      default:
        return {
          content: 'Synchronized',
          disabled: true,
          displaySpinner: false,
          plain: true,
        };
    }
  }

  resetProcessState = () => {
    if (this.state.process || this.state.editedDisplayGroupId) {
      this.setState({ process: null, editedDisplayGroupId: null });
    }
  };

  renderHeader() {
    const { refreshingCache, readOnly } = this.props;
    const { path } = this.state;

    return (
      <div className="DisplayGroupView__header">
        <div className="DisplayGroupView__path">
          <div
            key="root"
            className="DisplayGroupView__pathNode"
            onClick={this.onSelectRootDisplayGroup}
          >
            Root
          </div>
          {path.map((node, index) => (
            <div
              key={node.id}
              className="DisplayGroupView__pathNode"
              onClick={this.onSelectDisplayGroup(index)}
            >
              <i className="mdi mdi-chevron-right" />
              {node.label}
            </div>
          ))}
        </div>
        {!readOnly && (
          <div>
            <Button id="save-button" {...this.getSaveButtonProps()} />
            <Button
              warning
              id="cache-refresh-button"
              content="Refresh cache"
              onClick={this.onRefreshCache}
              disabled={refreshingCache}
              displaySpinner={refreshingCache}
              className="DisplayGroupView__refreshCacheButton"
            />
          </div>
        )}
      </div>
    );
  }

  renderDisplayGroup = (displayGroup, index) => {
    const { readOnly } = this.props;
    const dgId = displayGroup.get('id');
    const canDelete =
      displayGroup.get('fields').size === 0 &&
      displayGroup.get('children').size === 0;
    return (
      <div className="DisplayGroupView__displayGroup" key={dgId}>
        <div className="DisplayGroupView__displayGroupLabel">
          <Text
            id={`displaygroup-${dgId}-label`}
            value={displayGroup.get('label') || ''}
            onChange={this.onUpdateDisplayGroupLabel(dgId)}
            disabled={readOnly}
          />
        </div>
        <Text
          id={`displaygroup-${dgId}-controller`}
          value={displayGroup.get('controller') || ''}
          onChange={this.onUpdateDisplayGroupController(dgId)}
          disabled={readOnly}
        />
        <Text
          id={`displaygroup-${dgId}-rank`}
          value={displayGroup.get('rank') || ''}
          onChange={this.onUpdateDisplayGroupRank(dgId)}
          disabled={readOnly}
        />
        <div className="DisplayGroupView__displayGroupView">
          <Button
            content="View"
            onClick={this.onSelectChildDisplayGroup(
              index,
              displayGroup.get('label') || '--',
              displayGroup.get('id')
            )}
            link
          />
        </div>
        <Button
          content="Delete"
          id={`displaygroup-${dgId}-delete`}
          onClick={this.onDeleteDisplayGroup(dgId)}
          disabled={!canDelete}
          warning
        />
      </div>
    );
  };

  renderField = (field) => {
    const { readOnly } = this.props;
    const fieldId = field.getIn(['field', 'id']);
    return (
      <div className="DisplayGroupView__field" key={fieldId}>
        <code>{field.getIn(['field', 'name'])}</code>
        <Text
          id={`field-${fieldId}-rank`}
          value={field.get('rank') || ''}
          onChange={this.onUpdateFieldRank(fieldId)}
          disabled={readOnly}
        />
      </div>
    );
  };

  renderContent() {
    const { readOnly } = this.props;
    const currentDisplayGroup = this.getCurrentDisplayGroup();
    if (!currentDisplayGroup) {
      return null;
    }
    return (
      <div className="DisplayGroupView__content">
        {(currentDisplayGroup.get('children') || []).map(
          this.renderDisplayGroup
        )}
        {(currentDisplayGroup.get('fields') || []).map(this.renderField)}
        {!readOnly && (
          <div className="DisplayGroupView__add">
            <AddButton
              label="Add display group"
              onClick={this.onAddDisplayGroup}
            />
          </div>
        )}
      </div>
    );
  }

  render() {
    return (
      <div>
        <HeaderLayout
          title="Product page structure"
          backHref={routes.home}
          backMessage="Back home"
          isTitleSmall
        />
        <div className="container-fluid row">
          <div className="DisplayGroupView col-xs-12">
            {this.renderHeader()}
            {this.renderContent()}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(DisplayGroupView);
