import { createReducer } from 'redux-create-reducer';
import { set } from 'lodash/fp';
import { ActionWithPayload } from 'utils/reducers/types';

import * as events from './events';
import * as types from './types';

export const REDUCER_KEY = 'indexer-pump';

const getInitialState = (): types.PumpState => ({
  bufferedConfigs: null,
  batchConfigs: null,
  configSources: null,
});

export default createReducer(getInitialState(), {
  [events.INIT]: () => getInitialState(),
  [events.RECEIVE_BUFFERED_CONFIGS]: (
    state,
    { payload: configs }: ActionWithPayload<types.BufferedPumpConfig[]>
  ) => set(['bufferedConfigs'], configs, state),
  [events.RECEIVE_BATCH_CONFIGS]: (
    state,
    { payload: configs }: ActionWithPayload<types.BatchPumpConfig[]>
  ) => set(['batchConfigs'], configs, state),
  [events.RECEIVE_CONFIG_SOURCES]: (
    state,
    { payload: sources }: ActionWithPayload<types.ConfigSource[]>
  ) => set(['configSources'], sources, state),
});
