import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { separateActions } from 'utils/redux';

import { Checkbox } from '@alkem/react-ui-inputs';

import { READONLY_FIELDS } from 'constants/organization-settings';
import { updateSettingValue } from 'modules/organization-page/actions/settings';
import { selectEditedOrSourceSetting } from 'modules/organization-page/selectors/settings';
import { List } from 'immutable';

const mapStateToProps = (state) => ({
  readOnlyFieldSetting: selectEditedOrSourceSetting(state, [READONLY_FIELDS]),
});

const mapDispatchToProps = {
  updateSettingValue,
};

export class FieldsReadOnlySettingEditModalCustom extends PureComponent {
  static propTypes = {
    settingAttr: PropTypes.string.isRequired,
    readOnlyFieldSetting: ImmutablePropTypes.map.isRequired,
    actions: PropTypes.shape({
      updateSettingValue: PropTypes.func.isRequired,
    }).isRequired,
  };

  toogleAssetPicture = (event) => {
    this.updateSetting('asset.picture', event.target.checked);
  };

  toogleAssetVideo = (event) => {
    this.updateSetting('asset.video', event.target.checked);
  };

  toogleAssetDocument = (event) => {
    this.updateSetting('asset.document', event.target.checked);
  };

  toogleAssetRecipe = (event) => {
    this.updateSetting('asset.recipe', event.target.checked);
  };

  toogleLifecycle = (event) => {
    this.updateSetting('data.lifecycle', event.target.checked);
  };

  toogleLogisticalUnit = (event) => {
    this.updateSetting('logisticalunit', event.target.checked);
  };

  updateSetting(key, add) {
    const { readOnlyFieldSetting, settingAttr, actions } = this.props;
    const customs = readOnlyFieldSetting.get(settingAttr) || List();

    let newCustoms;
    if (add) {
      newCustoms = customs.push(key);
    } else {
      newCustoms = customs.filter((k) => k !== key);
    }
    actions.updateSettingValue(
      [READONLY_FIELDS],
      readOnlyFieldSetting.set(settingAttr, newCustoms)
    );
  }

  render() {
    const { readOnlyFieldSetting, settingAttr } = this.props;
    const customs = readOnlyFieldSetting.get(settingAttr) || List();
    return (
      <div>
        <div className="FieldsReadOnlySettingEditModal__titleLine">
          <h3>Custom</h3>
        </div>
        <ul className="FieldsReadOnlySettingEditModal__lines">
          <li>
            <Checkbox
              id="data.lifecycle"
              label="data.lifecycle"
              checked={customs.contains('data.lifecycle')}
              onChange={this.toogleLifecycle}
            />
          </li>
          <li className="FieldsReadOnlySettingEditModal__linesSeparator" />
          <li>
            <Checkbox
              id="asset.picture"
              label="asset.picture"
              checked={customs.contains('asset.picture')}
              onChange={this.toogleAssetPicture}
            />
          </li>
          <li>
            <Checkbox
              id="asset.video"
              label="asset.video"
              checked={customs.contains('asset.video')}
              onChange={this.toogleAssetVideo}
            />
          </li>
          <li>
            <Checkbox
              id="asset.recipe"
              label="asset.recipe"
              checked={customs.contains('asset.recipe')}
              onChange={this.toogleAssetRecipe}
            />
          </li>
          <li>
            <Checkbox
              id="asset.document"
              label="asset.document"
              checked={customs.contains('asset.document')}
              onChange={this.toogleAssetDocument}
            />
          </li>
          <li className="FieldsReadOnlySettingEditModal__linesSeparator" />
          <li>
            <Checkbox
              id="logisticalunit"
              label="logisticalunit"
              checked={customs.contains('logisticalunit')}
              onChange={this.toogleLogisticalUnit}
            />
          </li>
        </ul>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(FieldsReadOnlySettingEditModalCustom);
