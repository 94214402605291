import { MODULE_REDUCER_KEY } from 'constants/reducer';

import { IMPORT_LIST_STORE_KEY } from '../constants/reducers';

export function selectState(state) {
  return state[MODULE_REDUCER_KEY][IMPORT_LIST_STORE_KEY];
}

export function selectList(state) {
  return selectState(state).list;
}

export function selectPagination(state) {
  return selectState(state).pagination;
}

export function selectFilters(state) {
  return selectState(state).filters;
}

export function selectFiltersAvailable(state) {
  return selectState(state).filtersAvailable;
}

export function selectSearch(state) {
  return selectState(state).search;
}

export function selectIsModalOpen(state) {
  return selectState(state).modal.isOpen;
}

export function selectModalParameters(state) {
  return selectState(state).modal.parameters;
}

export function selectCurrentExportFile(state) {
  return selectState(state).modal.currentExportFile;
}

export function selectIsExportFileInProgress(state) {
  return selectState(state).modal.isExportFileInProgress;
}

export function selectGtins(state) {
  return selectState(state).gtins;
}

export function selectIsGDSNModalOpen(state) {
  return selectState(state).gdsnModal.isOpen;
}

export function selectRawFilters(state) {
  return selectState(state).rawFilters;
}
