import { Tooltip } from '@alkem/react-ui-tooltip';
import moment from 'moment';

interface Props {
  time?: string;
}

export default function TimeAgo({ time }: Props) {
  if (!time) {
    return null;
  }
  const localTime = moment.utc(time).local();
  const formattedTime = localTime.format('L LT');
  return (
    <>
      <span id="time" data-tip data-for={time}>
        {localTime.fromNow()}
      </span>
      <Tooltip id={time} hoverable>
        {formattedTime}
      </Tooltip>
    </>
  );
}
