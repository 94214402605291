import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';
import listensToClickOutside from 'react-onclickoutside';

import './dropdown.scss';

class Dropdown extends Component {
  static propTypes = {
    id: PropTypes.string,
    testid: PropTypes.string,
    options: PropTypes.oneOfType([PropTypes.array, ImmutablePropTypes.list])
      .isRequired,
    label: PropTypes.node.isRequired,
    theme: PropTypes.string,
    buttonClassName: PropTypes.string,
    closeDropdownOnClickElement: PropTypes.bool,
    rightDropdown: PropTypes.bool,
    selectKey: PropTypes.func.isRequired,
    selectAttrs: PropTypes.func.isRequired,
    selectLabel: PropTypes.func.isRequired,
    selectOptions: PropTypes.func.isRequired,
    selectOnClick: PropTypes.func.isRequired,
    selectClassNames: PropTypes.func,
    onClickOption: PropTypes.func,
    withoutWrappers: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    theme: 'default',
    closeDropdownOnClickElement: true,
    rightDropdown: false,
    selectKey: (opt, key) => opt.key || key,
    selectLabel: (opt) => opt.label,
    selectAttrs: (opt) => opt.attrs,
    selectOptions: (opt) => opt.options,
    selectOnClick: (opt) => opt.onClick,
    selectClassNames: (opt) => opt.classNames,
    withoutWrappers: false,
    buttonClassName: '',
    disabled: false,
  };

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.closeDropdown = this.closeDropdown.bind(this);
    this.onClickOption = this.onClickOption.bind(this);
    this.state = {
      open: false,
    };
  }

  onClickElement(callback) {
    return (evt) => {
      callback(evt);
    };
  }

  onClickOption(option, event) {
    this.props.onClickOption(option, event);
  }

  onClickUl = () => {
    if (this.props.closeDropdownOnClickElement) {
      this.closeDropdown();
    }
  };

  handleClickOutside() {
    this.closeDropdown();
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  closeDropdown() {
    if (this.state.open) {
      this.setState({ open: false });
    }
  }

  toggle() {
    this.setState((prevState) => ({ open: !prevState.open }));
  }

  renderChildren(children) {
    if (!children || children.length === 0 || children.size === 0) {
      return null;
    }
    return <ul>{this.renderNode(children)}</ul>;
  }

  renderNode(options) {
    const {
      selectKey,
      selectLabel,
      selectAttrs,
      selectOptions,
      selectOnClick,
      onClickOption,
      selectClassNames,
      withoutWrappers,
    } = this.props;
    return options.map((option, index) => {
      const onClickOpt = selectOnClick(option);
      const attrs = selectAttrs(option) || {};
      const children = selectOptions(option);
      const label = selectLabel(option);
      const key = selectKey(option, index);
      const className = selectClassNames(option)
        ? classNames(selectClassNames(option))
        : '';

      if (onClickOpt || onClickOption) {
        let onClick;
        if (onClickOption) {
          onClick = this.onClickOption.bind(this, option);
        } else {
          onClick = this.onClickElement(onClickOpt).bind(this);
        }
        return (
          <li key={key} className={className}>
            <button type="button" onClick={onClick} {...attrs}>
              {label}
            </button>
            {this.renderChildren(children)}
          </li>
        );
      }
      return (
        <li key={key} className={className}>
          {withoutWrappers ? label : <span {...attrs}>{label}</span>}
          {this.renderChildren(children)}
        </li>
      );
    });
  }

  render() {
    const componentClasses = {
      Dropdown: true,
      [`Dropdown--${this.props.theme}`]: this.props.theme,
    };

    const listClasses = {
      Dropdown__list: true,
      'Dropdown__list--hidden': !this.state.open,
      rightDropdown: this.props.rightDropdown,
    };

    return (
      <div
        onClick={this.stopPropagation}
        className={classNames(componentClasses)}
        id={this.props.id}
      >
        <button
          type="button"
          className={classNames(
            'Dropdown__button',
            'btn',
            this.props.buttonClassName
          )}
          onClick={this.toggle}
          disabled={this.props.disabled}
          data-testid={this.props.testid}
        >
          {this.props.label}
        </button>
        <ul
          className={classNames(listClasses)}
          onClick={this.onClickUl}
          role="presentation"
        >
          {this.renderNode(this.props.options)}
        </ul>
      </div>
    );
  }
}

export default listensToClickOutside(Dropdown);
