import * as events from '../constants/events';

export const receiveGDSNCISList = (list, totalResults) => ({
  type: events.RECEIVE_GDSN_CIS_LIST,
  payload: {
    list,
    totalResults,
  },
});

export const receiveGDSNCISListFilters = (data) => ({
  type: events.RECEIVE_GDSN_CIS_LIST_FILTERS,
  payload: {
    data,
  },
});

export const getGDSNCISList = () => ({
  type: events.GET_GDSN_CIS_LIST,
});

export const getGDSNCISListFilters = () => ({
  type: events.GET_GDSN_CIS_LIST_FILTERS,
});

export const changeLimit = (limit) => ({
  type: events.CHANGE_LIMIT_GDSN_CIS_LIST,
  limit,
});

export const nextPage = () => ({
  type: events.NEXT_PAGE_GDSN_CIS_LIST,
});

export const previousPage = () => ({
  type: events.PREVIOUS_PAGE_GDSN_CIS_LIST,
});

export const addGDSNCISListFilters = (key, value) => ({
  type: events.ADD_GDSN_CIS_LIST_FILTER,
  key,
  value,
});

export const removeGDSNCISListFilters = (key, value) => ({
  type: events.REMOVE_GDSN_CIS_LIST_FILTER,
  key,
  value,
});

export const updateGDSNCISListFilters = (key, value) => ({
  type: events.UPDATE_GDSN_CIS_LIST_FILTER,
  key,
  value,
});

export const clearGDSNCISListFilters = (key, value) => ({
  type: events.CLEAR_GDSN_CIS_LIST_FILTER,
  key,
  value,
});

export const downloadGDSNDocument = (documentID) => ({
  type: events.DOWNLOAD_GDSN_CIS_DOCUMENT,
  documentID,
});

export const openCISModal = (party) => ({
  type: events.OPEN_GDSN_CIS_MODAL,
  party,
});

export const closeCISModal = () => ({
  type: events.CLOSE_GDSN_CIS_MODAL,
});

export const saveGDSNCIS = () => ({
  type: events.GDSN_CIS_MODAL_SAVE,
});

export const updateGDSNCISAttr = (key, value) => ({
  type: events.GDSN_CIS_ATTR_UPDATE,
  payload: {
    key,
    value,
  },
});
