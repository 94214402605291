import { ResponseWithBody } from '@alkem/sdk-dashboard';
import { notificationError, notificationSuccess } from 'actions/notifications';
import qs from 'querystringify';
import { call, put, race, select, take, takeLatest } from 'redux-saga/effects';
import referentialApi from 'resources/referentialApi';
import { validationApi, validationApiJS } from 'resources/validationApi';
import { logError } from 'utils';
import { forwardTo, history } from 'utils/history';
import { withCatch } from 'utils/saga';
import {
  validationDeleteRules,
  validationDeleteRule,
  validationDeleteRuleDone,
  validationDeleteRulesDone,
  validationFetchingRuleSetTags,
  validationFetchRule,
  validationFetchRuleDimensions,
  validationFetchRuleDimensionsDone,
  validationFetchRuleDone,
  validationFetchRules,
  validationFetchRulesDone,
  validationFetchRuleSet,
  validationFetchRuleSetDone,
  validationFetchRuleSetTagsDone,
  validationInitFiltersDone,
  validationMoveRulesToRuleset,
  validationSaveRuleData,
  validationSaveRuleDone,
  validationSetIsLoading,
  validationSetIsLoadingDimensions,
  validationSetIsLoadingRule,
  validationSetPagination,
} from './actions';
import * as api from './api';
import {
  VALIDATION_ADD_TO_FILTERS,
  VALIDATION_DELETE_RULE,
  VALIDATION_DELETE_RULES,
  VALIDATION_DELETE_RULESET,
  VALIDATION_DUPLICATE_RULE,
  VALIDATION_FETCH_RULE,
  VALIDATION_FETCH_RULE_DIMENSIONS,
  VALIDATION_FETCH_RULE_SET_TAGS,
  VALIDATION_FETCH_RULES,
  VALIDATION_FETCH_RULESET,
  VALIDATION_INIT_FILTERS,
  VALIDATION_INIT_FILTERS_DONE,
  VALIDATION_MOVE_RULES_TO_RULESET,
  VALIDATION_REMOVE_FROM_FILTERS,
  VALIDATION_RESET_PATH_VALUE_FROM_FILTERS,
  VALIDATION_SAVE_RULE_CANCEL,
  VALIDATION_SAVE_RULE_DATA,
  VALIDATION_SEARCH_QUERY,
  VALIDATION_SET_PAGINATION,
} from './constants';
import {
  defaultFiltersValues,
  RuleSetTagsFetchState,
  ValidationFiltersState,
} from './moduleState';
import {
  selectRuleSetTags,
  selectValidationFiltersSearch,
  selectValidationPagination,
  selectValidationSelectedFilters,
} from './selectors';
import {
  FilterValue,
  Rule,
  RuleSet,
  RuleSetTag,
  ValidationRuleSetType,
  ValidationSelectedFilters,
} from './types';
import { getRuleSetLabelForFilter } from './utils';

function* updateURLWithFiltersAndPagination() {
  try {
    const filters = <ValidationSelectedFilters>(
      yield select(selectValidationSelectedFilters)
    );
    const search = yield select(selectValidationFiltersSearch);
    const qsv = {};
    if (search) {
      qsv['q'] = search;
    }
    for (const [key, value] of Object.entries(filters)) {
      if (typeof value === 'boolean') {
        qsv[key] = value;
      } else if (value?.length) {
        if (Array.isArray(value)) {
          qsv[key] = value.map((v) => v?.key || v);
        } else {
          qsv[key] = value;
        }
      }
    }
    history.replace({ search: qs.stringify(qsv) });

    //reset pagination
    yield put(
      validationSetPagination({
        currentPage: 1,
      })
    );
  } catch (error) {
    logError(error);
  }
}

function* fetchRules() {
  const selectedFilters = <ValidationSelectedFilters>(
    yield select(selectValidationSelectedFilters)
  );
  const search = yield select(selectValidationFiltersSearch);
  const pagination = yield select(selectValidationPagination);

  const sort = ['ruleEntityType', '-status', 'id'];

  yield put(validationSetIsLoading(true));
  try {
    const { data, totalResults } = yield call(() =>
      api.fetchRulesList(
        api.convertToQueryFilters(
          selectedFilters,
          search,
          false,
          pagination,
          sort
        )
      )
    );
    yield put(
      validationFetchRulesDone({
        data,
        totalResults,
        totalPages: Math.ceil(totalResults / pagination.limit),
      })
    );
    yield put(validationSetIsLoading(false));
  } catch (error) {
    logError(error);
    yield put(validationSetIsLoading(false));
  }
}

function* fetchRule({
  payload: ruleId,
}: ReturnType<typeof validationFetchRule>) {
  yield put(validationSetIsLoadingRule(true));
  try {
    const rule = yield call(() => api.fetchRule(ruleId));
    if (rule) {
      yield put(validationFetchRuleDone(rule));
    }
  } catch (error) {
    const message = (error as ResponseWithBody<{ message: string }>).data
      .message;
    yield put(notificationError(message));
  } finally {
    yield put(validationSetIsLoadingRule(false));
  }
}

function* fetchRuleDimensions({
  payload: rule,
}: ReturnType<typeof validationFetchRuleDimensions>) {
  yield put(validationSetIsLoadingDimensions(true));
  try {
    const fields_raw_applicability = yield call(() =>
      api.fetchRuleDimensions(rule)
    );
    yield put(validationFetchRuleDimensionsDone(fields_raw_applicability));
  } catch (error) {
    logError(error);
  } finally {
    yield put(validationSetIsLoadingDimensions(false));
  }
}

export function* moveMultipleRulesToRuleset({
  payload: rulesToMove,
}: ReturnType<typeof validationMoveRulesToRuleset>) {
  try {
    yield call(
      [validationApi, 'moveRulesToRuleset'],
      rulesToMove.ruleIds,
      rulesToMove.rulesetId,
      true
    );

    yield put(
      notificationSuccess(
        `The rules (ids: ${rulesToMove.ruleIds.join(
          ', '
        )}) were successfully moved in ruleSet ${rulesToMove.rulesetId}!`
      )
    );
  } catch (error) {
    const message = (error as ResponseWithBody<{ message: string }>).data
      .message;
    yield put(notificationError(message));
  }
}

interface RulesOperationInfo {
  rules: Array<number>;
  message: string;
}

function extractDeleteRulesResponseErrorMessage(
  errors: Array<RulesOperationInfo> | undefined
): string {
  if (!errors) {
    return '';
  }
  return errors
    .map((error) => {
      return `Error "${error.message}" for rule(s): ${error.rules.join(', ')}`;
    })
    .join(' - ');
}

export function* deleteMultipleRules({
  payload: ruleIds,
}: ReturnType<typeof validationDeleteRules>) {
  try {
    const response = yield call(
      [validationApiJS, 'deleteRules'],
      ruleIds,
      true
    );

    const responseData = response.data.data;

    const aggregatedErrorMessage = extractDeleteRulesResponseErrorMessage(
      responseData.failed
    );

    if (aggregatedErrorMessage) {
      yield put(notificationError(aggregatedErrorMessage));
    }
    if (responseData.ok?.length) {
      yield put(validationDeleteRulesDone(responseData.ok));
      yield put(
        notificationSuccess(
          `The rules (ids: ${responseData.ok.join(
            ', '
          )}) were successfully deleted!`
        )
      );
    }
  } catch (error: unknown) {
    yield put(notificationError('An error occurred while deleting rules'));
  }
}

function* saveRule({
  payload: ruleToSave,
}: ReturnType<typeof validationSaveRuleData>) {
  try {
    yield call(
      [validationApiJS, 'put'],
      `/validation/v3/rules/${ruleToSave.id}?update_danger_zone=1&synchronous=1`,
      ruleToSave
    );
  } catch (error: unknown) {
    const expectedError = error as ResponseWithBody<{ message: string }>;
    yield* saveRuleError(
      ruleToSave,
      `An error occured while saving the rule. ${expectedError.data.message}`
    );
    return;
  }

  let ruleAfterSave: Rule | null;
  try {
    ruleAfterSave = yield call(api.fetchRule, ruleToSave.id);
    if (ruleAfterSave === null) {
      throw 'Could not retrieve what was saved just before.';
    }
  } catch (error: unknown) {
    const message =
      typeof error === 'string'
        ? error
        : (error as ResponseWithBody<{ message: string }>).data.message;
    yield* saveRuleError(
      ruleToSave,
      `An error occurred while fetching the rule after save. ${message}`
    );
    return;
  }

  yield put(validationSaveRuleDone(ruleAfterSave));
  yield put(
    notificationSuccess(
      `The rule (id: ${ruleAfterSave.id}) was successfully modified!`
    )
  );
  yield put(validationFetchRuleDone(ruleAfterSave));
  yield put(validationFetchRules());
}

function* duplicateRuleSaga({ payload: ruleIdToDuplicate }) {
  try {
    const { data: rule } = yield call(
      [validationApiJS, 'post'],
      `/validation/v4/rules/${ruleIdToDuplicate}/duplicate?synchronous=1`
    );
    yield call(forwardTo, `/validation/rule/${rule.id}`);
  } catch (error: unknown) {
    const expectedError = error as ResponseWithBody<{ message: string }>;
    yield put(
      notificationError(
        `An error occurred while duplicating a rule. ${expectedError.data.message}`
      )
    );
    return;
  }
}

function* deleteRule({
  payload: ruleIdToDelete,
}: ReturnType<typeof validationDeleteRule>) {
  try {
    yield call(() => validationApi.deleteRule(ruleIdToDelete));

    yield put(
      notificationSuccess(
        `The rule (id: ${ruleIdToDelete}) was successfully deleted`
      )
    );

    yield put(validationDeleteRuleDone(ruleIdToDelete));
  } catch (error: unknown) {
    const expectedError = error as ResponseWithBody<{ message: string }>;
    yield put(
      notificationError(
        `An error occurred while deleting the rule ${ruleIdToDelete}. ${expectedError.data.message}`
      )
    );
  }
}

function* deleteRuleset({
  payload: rulesetIdToDelete,
}: ReturnType<typeof validationDeleteRule>) {
  try {
    const isDeleted = yield call(() =>
      validationApi.deleteRuleSet(rulesetIdToDelete)
    );

    if (isDeleted.data.data) {
      yield put(
        notificationSuccess(
          `The ruleset (id: ${rulesetIdToDelete}) was successfully deleted.`
        )
      );
    } else {
      yield put(
        notificationError(
          `An error occurred while deleting the ruleset ${rulesetIdToDelete}.`
        )
      );
    }
  } catch (error: unknown) {
    yield put(
      notificationError(
        `An error occurred while deleting the ruleset ${rulesetIdToDelete}.`
      )
    );
  }
}

function* fetchRuleSetTags() {
  const ruleSetTags = (yield select(
    selectRuleSetTags
  )) as RuleSetTagsFetchState;

  if (ruleSetTags === 'loading' || Array.isArray(ruleSetTags)) {
    // either already loaded, either loading in progress
    return;
  }

  yield put(validationFetchingRuleSetTags());

  let fetchResultState: RuleSetTagsFetchState = 'loading';

  try {
    const {
      data: { data: ruleSetTags },
    } = (yield call(
      [referentialApi, 'ReferentialGetList'],
      'rulesetstags'
    )) as ResponseWithBody<{ data: RuleSetTag[] }>;

    fetchResultState = ruleSetTags;
  } catch (e) {
    fetchResultState = 'error';
    const typedE = e as ResponseWithBody;
    yield put(
      notificationError(
        'An error occured while loading the rule sets tags: ' +
          typedE.data.message
      )
    );
  } finally {
    yield put(validationFetchRuleSetTagsDone(fetchResultState));
  }
}

export function* fetchRuleSet({
  payload: ruleSetId,
}: ReturnType<typeof validationFetchRuleSet>) {
  try {
    const {
      data: { data: ruleSet },
    } = yield call([validationApiJS, 'fetchRuleSet'], ruleSetId, {
      with_linked_organizations: true,
      with_rules: true,
    });

    if (ruleSet) {
      yield put(validationFetchRuleSetDone(ruleSet));
    }
  } catch (error) {
    const message = (error as ResponseWithBody<{ message: string }>).data
      .message;
    yield put(notificationError(message));
  }
}

export function* initFilters() {
  const urlParams = <{ [key: string]: string }>(
    qs.parse(history.location.search)
  );

  const filters: ValidationFiltersState = {
    search: '',
    selectedFilters: { ...defaultFiltersValues },
  };

  if (urlParams.q) {
    filters.search = urlParams.q;
  }
  if (urlParams.ruleSetIdIn) {
    try {
      const rulesetIds = urlParams.ruleSetIdIn
        .split(',')
        .map((id: string) => parseInt(id))
        .filter((id: number) => !isNaN(id));

      if (rulesetIds.length) {
        const {
          data: { data: rulesets },
        } = yield call([validationApiJS, 'listRuleSetsIn'], rulesetIds);

        filters.selectedFilters.ruleSetIdIn = rulesets.map(
          (ruleset: RuleSet) =>
            ({
              key: ruleset.id,
              value: ruleset,
              label: getRuleSetLabelForFilter(ruleset),
            } as FilterValue)
        );
      }
    } catch (error) {
      logError(error);
    }
  }
  if (urlParams.ruleSetTypeIn) {
    filters.selectedFilters.ruleSetTypeIn = urlParams.ruleSetTypeIn
      .split(',')
      .map((type) => type as ValidationRuleSetType);
  }
  if (urlParams.entityTypeIn) {
    filters.selectedFilters.entityTypeIn = urlParams.entityTypeIn
      .split(',')
      .map((id: string) => parseInt(id));
  }
  if (urlParams.usedByRetailerIdIn) {
    filters.selectedFilters.usedByRetailerIdIn = urlParams.usedByRetailerIdIn
      .split(',')
      .map(
        (id: string) =>
          ({
            key: parseInt(id),
            label: `Organization (ID: ${parseInt(id)})`,
          } as FilterValue)
      );
  }
  if (urlParams.sourceOrganizationIds) {
    filters.selectedFilters.sourceOrganizationIds =
      urlParams.sourceOrganizationIds.split(',').map(
        (id: string) =>
          ({
            key: parseInt(id),
            label: `Organization (ID: ${parseInt(id)})`,
          } as FilterValue)
      );
  }
  if (urlParams.appliesOnPkIdIn) {
    filters.selectedFilters.appliesOnPkIdIn = urlParams.appliesOnPkIdIn
      .split(',')
      .map(
        (id: string) =>
          ({
            key: parseInt(id),
            label: `Product (PkID: ${parseInt(id)})`,
          } as FilterValue)
      );
  }
  if (urlParams.onlyActive) {
    filters.selectedFilters.onlyActive =
      urlParams.onlyActive === 'true' ? true : false;
  }

  yield put(validationInitFiltersDone(filters));
}

function* saveRuleError(rule: Rule, message: string) {
  yield put(validationSaveRuleDone(rule));
  yield put(notificationError(message));
}

export default function* validationDashboardSagas() {
  yield takeLatest(
    [
      VALIDATION_ADD_TO_FILTERS,
      VALIDATION_REMOVE_FROM_FILTERS,
      VALIDATION_RESET_PATH_VALUE_FROM_FILTERS,
      VALIDATION_SEARCH_QUERY,
    ],
    updateURLWithFiltersAndPagination
  );
  yield takeLatest(
    [
      VALIDATION_INIT_FILTERS_DONE,
      VALIDATION_SET_PAGINATION,
      VALIDATION_ADD_TO_FILTERS,
      VALIDATION_REMOVE_FROM_FILTERS,
      VALIDATION_RESET_PATH_VALUE_FROM_FILTERS,
      VALIDATION_SEARCH_QUERY,
      VALIDATION_FETCH_RULES,
    ],
    fetchRules
  );
  yield takeLatest([VALIDATION_INIT_FILTERS], initFilters);
  yield takeLatest(VALIDATION_FETCH_RULE_SET_TAGS, fetchRuleSetTags);
  yield takeLatest(
    VALIDATION_DUPLICATE_RULE,
    withCatch(duplicateRuleSaga, { withNotification: true })
  );
  yield takeLatest(
    VALIDATION_SAVE_RULE_DATA,
    function* (action: ReturnType<typeof validationSaveRuleData>) {
      yield race({
        task: call(saveRule, action),
        cancel: take(VALIDATION_SAVE_RULE_CANCEL),
      });
    }
  );
  yield takeLatest(VALIDATION_FETCH_RULE, fetchRule);
  yield takeLatest(VALIDATION_FETCH_RULE_DIMENSIONS, fetchRuleDimensions);
  yield takeLatest(VALIDATION_DELETE_RULE, deleteRule);
  yield takeLatest(VALIDATION_DELETE_RULESET, deleteRuleset);
  yield takeLatest(
    VALIDATION_MOVE_RULES_TO_RULESET,
    moveMultipleRulesToRuleset
  );
  yield takeLatest(VALIDATION_DELETE_RULES, deleteMultipleRules);
  yield takeLatest(VALIDATION_FETCH_RULESET, fetchRuleSet);
}
