import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Modal } from '@alkem/react-ui-modal';

import { separateActions } from 'utils/redux';
import { Tabs, Tab } from 'components/tabs';
import {
  retrieveFieldMetaDataRoots,
  retrieveFieldTagReferential,
} from 'modules/organization-page/actions/settings';
import {
  selectFieldMetaDataRootsLoaded,
  selectFieldTagReferentialLoaded,
} from 'modules/organization-page/selectors/settings';
import FieldsReadOnlySettingEditModalFieldIds from './field-ids';
import FieldsReadOnlySettingEditModalFieldTags from './field-tags';
import FieldsReadOnlySettingEditModalCustom from './custom';

import './index.scss';

const mapStateToProps = (state) => ({
  fieldMetaDataRootsLoaded: selectFieldMetaDataRootsLoaded(state),
  fieldTagReferentialLoaded: selectFieldTagReferentialLoaded(state),
});

const mapDispatchToProps = {
  retrieveFieldMetaDataRoots,
  retrieveFieldTagReferential,
};

export class FieldsReadOnlySettingEditModal extends PureComponent {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    fieldMetaDataRootsLoaded: PropTypes.bool.isRequired,
    fieldTagReferentialLoaded: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      retrieveFieldMetaDataRoots: PropTypes.func.isRequired,
      retrieveFieldTagReferential: PropTypes.func.isRequired,
    }),
  };

  componentDidMount() {
    const { fieldMetaDataRootsLoaded, fieldTagReferentialLoaded, actions } =
      this.props;
    if (!fieldMetaDataRootsLoaded) {
      actions.retrieveFieldMetaDataRoots();
    }
    if (!fieldTagReferentialLoaded) {
      actions.retrieveFieldTagReferential();
    }
  }

  render() {
    const { onClose } = this.props;
    return (
      <Modal
        title="Read only field configuration"
        modalStyle="fullHeight"
        onClose={onClose}
        hideFooter
      >
        <div className="FieldsReadOnlySettingEditModal__body">
          <Tabs>
            <Tab title="Include">
              <FieldsReadOnlySettingEditModalFieldIds
                settingAttr="add_field_ids"
                withSpecific
              />
              <hr />
              <FieldsReadOnlySettingEditModalFieldTags settingAttr="add_field_tags" />
              <hr />
              <FieldsReadOnlySettingEditModalCustom settingAttr="add_custom" />
            </Tab>
            <Tab title="Exclude">
              <FieldsReadOnlySettingEditModalFieldIds settingAttr="remove_field_ids" />
              <hr />
              <FieldsReadOnlySettingEditModalFieldTags settingAttr="remove_field_tags" />
            </Tab>
          </Tabs>
        </div>
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(FieldsReadOnlySettingEditModal);
