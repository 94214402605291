import { takeLatest, select, call, put, all } from 'redux-saga/effects';

import ExchangeApi from 'resources/exchangeApi';

import { notificationError, notificationSuccess } from 'actions/notifications';

import {
  ENDPOINT_MODAL_SAVE,
  GET_ENDPOINT_LIST,
  GET_ENDPOINT_LIST_FILES,
  GET_ENDPOINT_LIST_FILTERS,
  ADD_ENDPOINT_LIST_FILTER,
  REMOVE_ENDPOINT_LIST_FILTER,
  UPDATE_ENDPOINT_LIST_FILTER,
  CLEAR_ENDPOINT_LIST_FILTER,
  CHANGE_LIMIT_ENDPOINT_LIST,
  NEXT_PAGE_ENDPOINT_LIST,
  PREVIOUS_PAGE_ENDPOINT_LIST,
  ENDPOINT_DELETE,
  ENDPOINT_GMAIL_CONSENT,
} from '../constants/events';

import {
  closeModal,
  receiveEnpointList,
  receiveEnpointListFiles,
  receiveEnpointListFilters,
} from '../actions';

import {
  selectFilters,
  selectPagination,
  selectModalEndpoint,
} from '../selectors';

export default function* endpointMainSaga() {
  yield takeLatest(ENDPOINT_GMAIL_CONSENT, consentToGmail);
  yield takeLatest(ENDPOINT_MODAL_SAVE, saveEndpoint);
  yield takeLatest(GET_ENDPOINT_LIST, getEndpointList);
  yield takeLatest(GET_ENDPOINT_LIST_FILES, getEndpointListFiles);
  yield takeLatest(GET_ENDPOINT_LIST_FILTERS, getEndpointListFilters);
  yield takeLatest(ENDPOINT_DELETE, deleteEndpoint);
  yield takeLatest(
    [
      ADD_ENDPOINT_LIST_FILTER,
      REMOVE_ENDPOINT_LIST_FILTER,
      UPDATE_ENDPOINT_LIST_FILTER,
      CLEAR_ENDPOINT_LIST_FILTER,
      CHANGE_LIMIT_ENDPOINT_LIST,
      NEXT_PAGE_ENDPOINT_LIST,
      PREVIOUS_PAGE_ENDPOINT_LIST,
    ],
    getEndpointList
  );
}

function* consentToGmail(action) {
  try {
    yield call([ExchangeApi, ExchangeApi.GMAILConsent], action.payload);
    yield put(notificationSuccess('Gmail Consent OK'));
    yield call(getEndpointList);
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured: consentToGmail'));
    }
  }
  action.closeModalFunc();
}

function* saveEndpoint() {
  try {
    // Saving endpoint
    const endpoint = yield select(selectModalEndpoint);
    const response =
      endpoint.get('id') === undefined
        ? yield call([ExchangeApi, ExchangeApi.EndpointCreate], endpoint.toJS())
        : yield call(
            [ExchangeApi, ExchangeApi.EndpointUpdate],
            endpoint.toJS()
          );

    yield put(notificationSuccess('Endpoint saved'));

    // Saving endpoint Handlers
    const endpointId = response.data.data.id;
    yield all(
      endpoint
        .get('handlers')
        .toJS()
        .map((h) => {
          if (!h.id === undefined && h.deleted) {
            return null;
          } else if (h.deleted === true) {
            return call(
              [ExchangeApi, ExchangeApi.EndpointHandlerDelete],
              endpointId,
              h.id
            );
          } else if (!h.id) {
            return call(
              [ExchangeApi, ExchangeApi.EndpointHandlerCreate],
              endpointId,
              h
            );
          }
          return call(
            [ExchangeApi, ExchangeApi.EndpointHandlerUpdate],
            endpointId,
            h
          );
        })
    );
    if (endpoint.get('handlers').size > 0) {
      yield put(notificationSuccess('EndpointHandlers saved'));
    }
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured: saveEndpoint'));
    }
  }
  yield put(closeModal());
  yield call(getEndpointList);
}

function* getEndpointList() {
  try {
    const filters = yield select(selectFilters);
    const pagination = yield select(selectPagination);
    const response = yield call([ExchangeApi, ExchangeApi.EndpointList], {
      offset: (pagination.currentPage - 1) * pagination.limit,
      limit: pagination.limit,
      filter_organization_in: filters.organizations.map((o) => o.value),
    });
    yield put(
      receiveEnpointList(response.data.data, response.data.totalResults)
    );
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured: getEndpointList'));
    }
  }
}

function* getEndpointListFiles(action) {
  try {
    const response = yield call(
      [ExchangeApi, ExchangeApi.EndpointListFile],
      action.payload.endpointId,
      action.payload.offset,
      action.payload.limit
    );
    yield put(
      receiveEnpointListFiles(
        response.data.data,
        response.data.totalResults,
        action.payload.endpointId
      )
    );
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured: getEndpointListFiles'));
    }
  }
}

function* getEndpointListFilters() {
  try {
    const response = yield call([ExchangeApi, ExchangeApi.EndpointListFilters]);
    yield put(receiveEnpointListFilters(response.data.data));
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured: getEndpointListFilters'));
    }
  }
}

function* deleteEndpoint(action) {
  try {
    yield call([ExchangeApi, ExchangeApi.EndpointDelete], action.endpointID);
    yield call(getEndpointList);
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured: deleteEndpoint'));
    }
  }
  yield call(getEndpointList);
  yield call(action.hideConfirmDeleteModal);
}
