import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { HeaderLayout } from '@alkem/react-layout';
import { Modal } from '@alkem/react-ui-modal';

import * as routes from 'constants/routes';

import { TranslationFilters } from './filters';
import { TranslationFiles } from './files';
import { selectViewedFile } from '../selectors';
import { discardOneFile } from '../actions';
import Search from 'components/ui/input/search';

import './index.scss';

const mapStateToProps = (state) => ({
  viewedFile: selectViewedFile(state),
});

const mapDispatchToProps = {
  discardOneFile,
};

export class Translation extends PureComponent {
  static propTypes = {
    viewedFile: PropTypes.object,
    discardOneFile: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      modalSearch: '',
    };
  }

  onUpdateSearch = (event) => {
    this.setState({ modalSearch: event });
  };

  renderModal(viewedFile) {
    let filtered = Object.entries(viewedFile.content);
    const { modalSearch } = this.state;
    if (modalSearch) {
      filtered = filtered.filter(
        ([key, value]) =>
          key.includes(modalSearch) || value.includes(modalSearch)
      );
    }
    const length = filtered.length;
    filtered = filtered.slice(0, 100);
    return (
      <Modal
        title={viewedFile.name}
        modalStyle="large"
        hideFooter
        onClose={() => this.props.discardOneFile()}
      >
        <div className="PayloadModal">
          <Search
            query={this.state.modalSearch}
            placeholder="Search..."
            updateSearchQuery={this.onUpdateSearch}
          />
          <div className="PayloadModal__pagination">
            <strong>
              Displaying {filtered.length} of {length} entries.
            </strong>
          </div>
          <table className="PayloadModal__table">
            <thead>
              <tr>
                <th>Key</th>
                <th>Translation</th>
              </tr>
            </thead>
            <tbody>
              {filtered.map(([k, v]) => (
                <tr key={k}>
                  <td>{k}</td>
                  <td>{v}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal>
    );
  }

  render() {
    const headerProps = {
      title: 'Translation dashboard',
      backHref: routes.home,
      backMessage: 'Back home',
      isTitleSmall: true,
    };

    return (
      <div className="Translation__body">
        <HeaderLayout {...headerProps} />
        <div className="container-fluid row">
          <div className="col-md-3">
            <TranslationFilters />
          </div>
          <div className="col-md-9">
            <TranslationFiles />
          </div>
          {this.props.viewedFile && this.renderModal(this.props.viewedFile)}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Translation);
