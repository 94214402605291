import React from 'react';

import { ModuleWrapper } from 'redux/register';

import reducer, { REDUCER_KEY } from './reducers';
import Translation from './components';
import saga from './sagas';

export default function TranslationContainer() {
  return (
    <ModuleWrapper reducerKey={REDUCER_KEY} reducer={reducer} saga={saga}>
      <Translation />
    </ModuleWrapper>
  );
}
