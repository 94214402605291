import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Modal } from '@alkem/react-ui-modal';

import { separateActions } from 'utils/redux';
import { fill } from 'utils/routes';
import * as routes from 'constants/routes';
import Notification from 'components/notification';

import { selectIsModalOpen, selectIsModalLoading } from '../selectors/modal';
import { closeOrganizationCreateModal } from '../actions/modal';
import { create } from '../actions/form';
import Form from './form';

const mapStateToProps = (state) => ({
  isModalOpen: selectIsModalOpen(state),
  isLoading: selectIsModalLoading(state),
});

const mapDispatchToProps = {
  closeOrganizationCreateModal,
  create,
};

class OrganizationCreate extends PureComponent {
  static propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    actions: PropTypes.shape({
      closeOrganizationCreateModal: PropTypes.func.isRequired,
      create: PropTypes.func.isRequired,
    }).isRequired,
  };

  close = () => {
    this.props.actions.closeOrganizationCreateModal();
  };

  create = () => {
    this.props.actions.create().then((org) => {
      this.props.history.push(fill(routes.organization, org.id));
    });
  };

  render() {
    const { isModalOpen, isLoading } = this.props;
    if (!isModalOpen) {
      return null;
    }
    return (
      <Modal
        title="Create organization"
        modalStyle="dynamic"
        className="OrganizationCreate"
        confirmButtonText="Create"
        onConfirm={this.create}
        isProcessing={isLoading}
        onClose={this.close}
      >
        <Notification type="modal" />
        <Form />
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(withRouter(OrganizationCreate));
