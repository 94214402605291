export const start_date = new Date();
export const end_date = new Date();
start_date.setMonth(start_date.getMonth() - 1);
export const dates = [
  {
    name: 'start_date',
    value: start_date.toISOString().substring(0, 10),
    label: 'Date from',
  },
  {
    name: 'end_date',
    value: end_date.toISOString().substring(0, 10),
    label: 'Date to',
  },
];
