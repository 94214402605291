import React from 'react';
import PropTypes from 'prop-types';

import { ModuleWrapper } from 'redux/register';

import { MAPPER_AFS_STORE_KEY } from './constants/reducers';

import reducer from './reducers';
import MapperAFSDashboardView from './components';

export default function MapperAFSDashboardViewContainer({ match }) {
  return (
    <ModuleWrapper reducerKey={MAPPER_AFS_STORE_KEY} reducer={reducer}>
      <MapperAFSDashboardView match={match} />
    </ModuleWrapper>
  );
}

MapperAFSDashboardViewContainer.propTypes = {
  match: PropTypes.object,
};
