import reviewApi from 'resources/reviewApi';
import { EntryTypes } from './entry-types';
import type { ProductTimelineEntryType, ProductTimelineType } from './types';

export const fetchProductTimeline = async (searchQuery: string) => {
  const res = await reviewApi.get<any, ProductTimelineType>(
    `/review/api/v1/${searchQuery}/timeline`
  );

  const entriesToKeep = res.data.entries.filter(
    (entry) => EntryTypes[entry.entry_type].keep === true
  );

  const groupedEntries = entriesToKeep.reduce((acc, event) => {
    const date = event.created_at.split('T')[0];

    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(event);

    return acc;
  }, {});

  const orderedEntries = Object.keys(groupedEntries).reduce(
    (acc: ProductTimelineEntryType[], date: string) => {
      acc.push(
        {
          entry_type: 17,
          created_at: new Date(date).toDateString(),
          txt: new Date(date).toDateString(),
          user_id: 0,
          ts: 0,
        },
        ...groupedEntries[date]
      );
      return acc;
    },
    []
  );

  return orderedEntries;
};

export const triggerReview = (productKeyId: string) =>
  reviewApi.post('/review/process/v1/trigger', {
    product_key_id: productKeyId,
  });
