import { Checkbox } from '@alkem/react-ui-inputs';
import typePackagings from 'constants/typePackagings';
import { fromJS, Set } from 'immutable';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { get } from 'utils/fp';
import { isImmutable } from 'utils/immutable';
import './applicable-packaging-types.scss';

export default class ApplicablePackagingTypes extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    ruleEntityType: PropTypes.number,
    applicablePackagingTypes: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object,
    ]).isRequired,
    forbiddenTypePackagings: ImmutablePropTypes.set.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    forbiddenTypePackagings: Set([]),
    disabled: false,
  };

  onChange = (pt) => () => this.update(pt.id);

  update = (id) => {
    const { applicablePackagingTypes } = this.props;
    if (!isImmutable(applicablePackagingTypes)) {
      if (applicablePackagingTypes.map((pt) => pt.id).includes(id)) {
        return this.props.onChange(
          applicablePackagingTypes.filter((pt) => pt.id !== id)
        );
      } else {
        return this.props.onChange([...applicablePackagingTypes, { id }]);
      }
    }
    const foundIndex = applicablePackagingTypes.findIndex(
      (pt) => pt.get('id') === id
    );
    if (foundIndex !== -1) {
      return this.props.onChange(applicablePackagingTypes.delete(foundIndex));
    }
    return this.props.onChange(applicablePackagingTypes.push(fromJS({ id })));
  };

  renderContent() {
    const {
      applicablePackagingTypes,
      ruleEntityType,
      id,
      forbiddenTypePackagings,
      disabled,
    } = this.props;
    if (
      !applicablePackagingTypes &&
      !(isImmutable(applicablePackagingTypes)
        ? applicablePackagingTypes?.size
        : applicablePackagingTypes?.length)
    ) {
      return 'No packaging type selected, this rule will apply to all';
    }

    return Object.values(typePackagings[ruleEntityType]).map((pt) => {
      const isDisabled = forbiddenTypePackagings.has(pt.id) || disabled;
      return (
        <Checkbox
          key={pt.id}
          id={`${id}-${pt.name}`}
          checked={
            !!applicablePackagingTypes.find((t) => get('id', t) === pt.id)
          }
          onChange={this.onChange(pt)}
          disabled={isDisabled}
          label={pt.name}
        />
      );
    });
  }

  render() {
    if (!typePackagings[this.props.ruleEntityType]) {
      return null;
    }
    return (
      <div className="ApplicablePackagingTypes ApplicablePackagingTypesCompact">
        {this.renderContent()}
      </div>
    );
  }
}
