import { flow, get } from 'lodash/fp';

import { REDUCER_KEY } from '../constants';
import { GDSNImportMappingsState } from '../types';

const selectState: (globalState: any) => GDSNImportMappingsState = get([
  'module',
  REDUCER_KEY,
]);

export const selectSourceField = flow(
  selectState,
  (state) => state.sourceField
);
export const selectFields = flow(selectState, (state) => state.fields);
export const selectFieldCategories = flow(
  selectState,
  (state) => state.fieldCategories
);
export const selectIsSavingInProgress = flow(
  selectState,
  (state) => state.isSavingInProgress
);
export const selectImportMappings = flow(
  selectState,
  (state) => state.importMappings
);
export const selectReferentialCodes = flow(
  selectState,
  (state) => state.referentialCodes
);
