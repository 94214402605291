import { Modal } from '@alkem/react-ui-modal';
import { Spinner } from '@alkem/react-ui-spinner';
import { CodeEditor } from 'components/code-editor';
import { Map } from 'immutable';
import {
  applyTransaction,
  closeActionModal,
} from 'modules/transaction/actions';
import { selectState } from 'modules/transaction/reducer';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { separateActions } from 'utils/redux';
import './actionModal.scss';

const mapStateToProps = (state) => ({
  show: selectState(state).actionModal.show,
  isApplying: selectState(state).actionModal.isApplying,
  transaction: selectState(state).actionModal.transaction,
});

const mapDispatchToProps = {
  onClose: closeActionModal,
  onApply: applyTransaction,
};

class ActionModal extends React.PureComponent {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    isApplying: PropTypes.string.isRequired,
    transaction: PropTypes.object.isRequired,
    readOnly: PropTypes.bool,
    actions: PropTypes.shape({
      onClose: PropTypes.func.isRequired,
      onApply: PropTypes.func.isRequired,
    }),
  };

  static defaultProps = {
    show: false,
    isApplying: '',
    transaction: Map(),
    readOnly: false,
  };

  render() {
    const { actions, isApplying, transaction, readOnly, show } = this.props;

    if (!show) {
      return null;
    }

    const value = JSON.stringify(transaction.get('then'), null, '\t');

    return (
      <Modal
        title="Payload"
        modalStyle="fullHeight"
        onClose={actions.onClose}
        hideFooter
      >
        <div className="TransactionActionModal__Content">
          <div className="flex flex-align-items flex-justify-content--center">
            {!readOnly && (
              <>
                <button
                  type="button"
                  className="btn btn-outline-success"
                  onClick={() => actions.onApply('validate', transaction)}
                  disabled={!!isApplying}
                >
                  Validate
                  {isApplying === 'validate' && <Spinner success small />}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-danger"
                  onClick={() => actions.onApply('refuse', transaction)}
                  disabled={!!isApplying}
                >
                  Reject
                  {isApplying === 'refuse' && <Spinner warning small />}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-warning"
                  onClick={() => actions.onApply('discard', transaction)}
                  disabled={!!isApplying}
                >
                  Discard
                  {isApplying === 'discard' && (
                    <Spinner className="Spinner__outline-warning" small />
                  )}
                </button>
              </>
            )}
          </div>
          <h3>Hooks</h3>
          <CodeEditor
            name="transaction-data"
            className="TransactionActionModal__Hook"
            value={value}
            readOnly
            mode="json"
            theme="monokai"
            showGutter={false}
            width="100%"
            height="100%"
            editorProps={{ $blockScrolling: Infinity }}
          />
        </div>
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(ActionModal);
