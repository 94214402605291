import { computeSelectedFromInclusionsAndExclusions } from 'components/kind-tree/utils';
import { Set as ImmutableSet, Map as ImmutableMap } from 'immutable';
import { KindsIndex } from 'redux/state/kinds';
import { FieldMetadata, KindIds } from './types';

export const computeForbiddenKinds: (
  meta: FieldMetadata,
  kindsIndex: KindsIndex
) => KindIds = (meta, kindsIndex) => {
  const { applicableForKinds: exclusionsAndInclusions } = meta;

  const [applicableForKindsFlattened] =
    computeSelectedFromInclusionsAndExclusions(
      exclusionsAndInclusions,
      kindsIndex
    ) as unknown as [{ [kindId: string]: boolean }];

  // all known kinds are by default not applicable
  const applicabilityForAllKnowKinds = ImmutableMap<string, boolean>(
    Object.keys(kindsIndex).map((k) => [k, false])
  );

  // exceptions :
  const allKnownKindsWithApplicability = applicabilityForAllKnowKinds.merge(
    ImmutableMap(applicableForKindsFlattened)
  );

  // returning only ID of non-applicable kinds
  return ImmutableSet.fromKeys(
    allKnownKindsWithApplicability.filter((v) => !v)
  );
};
