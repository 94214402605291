import { createReducer } from 'redux-create-reducer';
import { fromJS, Map } from 'immutable';

import * as events from '../constants/events';

const initialState = fromJS({
  search: {
    username: null,
    gln: null,
  },
  pagination: {
    totalResults: 0,
    currentPage: 1,
    totalPages: 1,
    limit: 50,
  },
  isLoading: false,
  actionInProgress: false,
  actionnableClaimsOnly: true,
  list: [],
  glnData: {},
});

export default createReducer(initialState, {
  [events.USERNAME_SEARCH_UPDATE]: (state, { search }) =>
    state.setIn(['search', 'username'], search),

  [events.GLN_SEARCH_UPDATE]: (state, { search }) =>
    state.setIn(['search', 'gln'], search),

  [events.GLNCLAIMS_LOADING]: (state, { value }) => state.set('loading', value),

  [events.GLNCLAIMS_ACTION_IN_PROGRESS]: (state, { value }) =>
    state.set('actionInProgress', value),

  [events.GLNCLAIMS_SET_ACTIONNABLE_CLAIMS_ONLY]: (state, { value }) =>
    state.set('actionnableClaimsOnly', value),

  [events.GLNCLAIMS_RECEIVED]: (
    state,
    { list, totalResults, resetPagination }
  ) => {
    const currentPage = state.getIn(['pagination', 'currentPage']);
    const totalPages =
      Math.floor(totalResults / state.getIn(['pagination', 'limit'])) + 1;
    return state.withMutations((newState) =>
      newState
        .set('list', list)
        .setIn(['pagination', 'totalResults'], totalResults)
        .setIn(['pagination', 'totalPages'], totalPages)
        .setIn(['pagination', 'currentPage'], resetPagination ? 1 : currentPage)
    );
  },

  [events.GLNDATA_RECEIVED]: (state, { data }) => {
    const glnData = data.reduce(
      (result, item) => result.set(item.get('gln'), item),
      Map()
    );
    return state.set('glnData', glnData);
  },

  [events.GLNCLAIMS_NEXT_PAGE]: (state) => {
    const currentPage = state.getIn(['pagination', 'currentPage']);
    const totalPages = state.getIn(['pagination', 'totalPages']);
    if (currentPage < totalPages) {
      return state.setIn(['pagination', 'currentPage'], currentPage + 1);
    }
    return state;
  },

  [events.GLNCLAIMS_PREVIOUS_PAGE]: (state) => {
    const currentPage = state.getIn(['pagination', 'currentPage']);
    if (currentPage > 0) {
      return state.setIn(['pagination', 'currentPage'], currentPage - 1);
    }
    return state;
  },

  [events.GLNCLAIMS_CHANGE_LIMIT]: (state, { limit }) =>
    state.withMutations((newState) =>
      newState
        .setIn(['pagination', 'limit'], limit)
        .setIn(['pagination', 'currentPage'], 1)
    ),
});
