import React from 'react';

import { ListRow as Row, ListColumn as Column } from '@alkem/react-ui-list';

import './row.scss';

const BrandClaimHeaderRow = () => (
  <Row className="BrandClaimHeaderRow">
    <Column className="BrandClaimRow__brand BrandClaimRow__header">
      Brand
    </Column>
    <Column className="BrandClaimRow__username BrandClaimRow__header">
      Username
    </Column>
    <Column className="BrandClaimRow__type BrandClaimRow__header">Type</Column>
    <Column className="BrandClaimRow__status BrandClaimRow__header">
      Status
    </Column>
    <Column className="BrandClaimRow__createdAt BrandClaimRow__header">
      <span>Created At</span>
    </Column>
    <Column className="BrandClaimRow__updatedAt BrandClaimRow__header">
      Updated At
    </Column>
    <Column className="BrandClaimRow__actions BrandClaimRow__header">
      Actions
    </Column>
  </Row>
);

export default BrandClaimHeaderRow;
