import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_EXPORT_MAPPING } from '../../../actions/constants';
import { selectCustomParseOptions } from '../../../selectors';
import {
  ExportMapping,
  ExportMappingPayload,
  Option,
  RegularOption,
} from '../../../types';
import { ExportMappingsSimpleSelector } from '../simple-selector';
import { ExportMappingsSimpleElementItemFieldTypePropsSelector } from './field-type-props-selector';
import './simple-element-item-type.scss';
import { itemTypeOptions } from './utils';
import { ExportMappingsSimpleElementItemValueTypePropsSelector } from './value-type-props-selector';

const getInitialItemType = (payload: ExportMappingPayload) => {
  const { field, value, custom_parse } = payload;
  if (custom_parse) {
    return itemTypeOptions[2];
  }
  if (value) {
    return itemTypeOptions[1];
  }
  if (field) {
    return itemTypeOptions[0];
  }
  return undefined;
};
interface Props {
  elementName: string;
  exportMapping: ExportMapping;
}
export function ExportMappingsSimpleElementItemType({
  elementName,
  exportMapping,
}: Props) {
  const dispatch = useDispatch();
  const customParseOptions = useSelector(selectCustomParseOptions);
  const [itemTypeOption, setItemTypeOption] = useState<
    RegularOption | undefined
  >(getInitialItemType(exportMapping.payload));
  const [customParseOption, setCustomParseOption] = useState<
    Option | undefined
  >(
    customParseOptions.find(
      (customParseOption) =>
        customParseOption.label === exportMapping.payload.custom_parse
    )
  );

  const dispatchSetExportMapping = useCallback(
    ({
      cast,
      field,
      use_su,
      referential,
      is_declinable,
      value,
      custom_parse,
    }: ExportMappingPayload) => {
      dispatch({
        type: SET_EXPORT_MAPPING,
        payload: {
          exportMapping: {
            ...exportMapping,
            payload: {
              ...exportMapping.payload,
              cast,
              field,
              use_su,
              referential,
              is_declinable,
              value,
              custom_parse,
            },
          },
        },
      });
    },
    [dispatch, exportMapping]
  );

  const updateItemType = (value: RegularOption) => {
    setItemTypeOption(value);
    setCustomParseOption(undefined);
    dispatchSetExportMapping({});
  };

  const resetItemType = () => {
    setItemTypeOption(undefined);
    setCustomParseOption(undefined);
    dispatchSetExportMapping({});
  };

  const updateCustomParse = (value: { id: number; label: string }) => {
    const { cast } = exportMapping.payload;
    setCustomParseOption(value);
    dispatchSetExportMapping({ cast, custom_parse: value.label || undefined });
  };

  const resetCustomParse = () => {
    const { cast } = exportMapping.payload;
    setCustomParseOption(undefined);
    dispatchSetExportMapping({ cast });
  };

  return (
    <div className="GDSNExportMappingsSimpleElementItemType">
      <ExportMappingsSimpleSelector
        label="Type of mapping"
        testId={`gdsn-export-mappings-simple-element-${elementName}-type-property-${exportMapping.id}`}
        options={itemTypeOptions}
        onSelect={updateItemType}
        option={itemTypeOption}
        onReset={resetItemType}
      />
      {itemTypeOption?.id === 'field' && (
        <ExportMappingsSimpleElementItemFieldTypePropsSelector
          elementName={elementName}
          exportMapping={exportMapping}
          dispatchSetExportMapping={dispatchSetExportMapping}
        />
      )}
      {itemTypeOption?.id === 'value' && (
        <ExportMappingsSimpleElementItemValueTypePropsSelector
          elementName={elementName}
          exportMapping={exportMapping}
          dispatchSetExportMapping={dispatchSetExportMapping}
        />
      )}
      {itemTypeOption?.id === 'custom_parse' && (
        <ExportMappingsSimpleSelector
          label="Custom parse"
          testId={`gdsn-export-mappings-simple-element-${elementName}-custom-parse-${exportMapping.id}`}
          options={customParseOptions}
          onSelect={updateCustomParse}
          option={customParseOption}
          onReset={resetCustomParse}
          placeholder={
            customParseOptions.length === 0
              ? 'No custom parse found'
              : undefined
          }
          disabled={customParseOptions.length === 0}
        />
      )}
    </div>
  );
}
