import { createReducer } from 'utils/reducers';

import { fromJS } from 'immutable';

import {
  receiveReducer,
  initPagination,
  changeLimit,
  nextPage,
  previousPage,
  addFilter,
  removeFilter,
  updateFilter,
  clearFilter,
} from 'utils/reducers/list';

import * as events from '../constants/events';

const LIMIT_STORAGE_KEY = 'gdsnRCIListLimit';
const SORTING_STORAGE_KEY = 'gdsnRCIListSorting';

const initialState = {
  list: [],
  modalGepir: {
    isOpen: false,
  },
  modal: {
    isOpen: false,
    rci: fromJS({
      gtin: null,
      party_owner_gln: null,
      target_market: null,
      gpc: null,
    }),
  },
  filters: {
    filter_gln: [],
    filter_gtin: [],
  },
  pagination: initPagination(LIMIT_STORAGE_KEY, SORTING_STORAGE_KEY),
};

export default createReducer(initialState, {
  [events.RECEIVE_GDSN_RCI_LIST]: (state, action) =>
    receiveReducer(state, action.payload),
  [events.CHANGE_LIMIT_GDSN_RCI_LIST]: (state, action) =>
    changeLimit(state, action, LIMIT_STORAGE_KEY),
  [events.NEXT_PAGE_GDSN_RCI_LIST]: nextPage,
  [events.PREVIOUS_PAGE_GDSN_RCI_LIST]: previousPage,
  [events.RECEIVE_GDSN_RCI_LIST_FILTERS]: (state, action) => {
    const newState = { ...state };
    newState.filtersRaw = fromJS(action.payload.data);
    return newState;
  },
  [events.ADD_GDSN_RCI_LIST_FILTER]: addFilter,
  [events.REMOVE_GDSN_RCI_LIST_FILTER]: removeFilter,
  [events.UPDATE_GDSN_RCI_LIST_FILTER]: updateFilter,
  [events.CLEAR_GDSN_RCI_LIST_FILTER]: clearFilter,
  [events.OPEN_GDSN_GEPIR_MODAL]: (state) => {
    const newState = { ...state };
    newState.modalGepir = {
      isOpen: true,
    };
    return newState;
  },
  [events.CLOSE_GDSN_GEPIR_MODAL]: (state) => {
    const newState = { ...state };
    newState.modalGepir = {
      isOpen: false,
    };
    return newState;
  },
  [events.OPEN_GDSN_RCI_MODAL]: (state, action) => {
    const newState = { ...state };
    newState.modal = {
      isOpen: true,
      rci: initialState.modal.rci.mergeDeep(fromJS(action.payload || {})),
    };
    return newState;
  },
  [events.CLOSE_GDSN_RCI_MODAL]: (state) => {
    const newState = { ...state };
    newState.modal = {
      isOpen: false,
      rci: initialState.modal.rci,
    };
    return newState;
  },
  [events.GDSN_RCI_ATTR_UPDATE]: (state, action) => {
    const newState = { ...state };
    newState.modal = {
      ...state.modal,
      rci: state.modal.rci.setIn(action.payload.key, action.payload.value),
    };
    return newState;
  },
});
