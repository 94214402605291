import { useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import qs from 'querystringify';
import {
  ListAutocomplete,
  OrganizationAutocomplete,
} from 'components/autocomplete';
import { indexerQueue } from 'constants/routes';
import {
  selectSources,
  selectSelectedFilters,
  selectSelectedOrganization,
} from '../../selectors';
import * as actions from '../../actions';
import * as types from '../../types';
import { getSourceLabel } from '../source-utils';

function getQueryParam(value?: string): number | null {
  if (value === null || value === undefined) {
    return null;
  }
  return value.length > 0 ? parseInt(value, 10) : null;
}

function useQueryParams() {
  const { search } = useLocation();
  const { org, source, prio } = qs.parse(search) as any;
  return useMemo(
    () => ({
      org: getQueryParam(org),
      source: getQueryParam(source),
      prio: getQueryParam(prio),
    }),
    [search]
  );
}

export function IndexerQueueFilters() {
  const sources: types.Source[] | null = useSelector(selectSources);
  const selectedFilters: types.SelectedFilters = useSelector(
    selectSelectedFilters
  );
  const selectedOrganization: types.SelectedOrganization | null = useSelector(
    selectSelectedOrganization
  );
  const dispatch = useDispatch();

  const params = useQueryParams();
  const history = useHistory();
  useEffect(() => {
    if (params.source !== selectedFilters.source) {
      dispatch(actions.selectSource(params.source));
    }
    if (params.prio !== selectedFilters.priority) {
      dispatch(actions.selectPriority(params.prio));
    }
  }, [params, selectedFilters]);

  useEffect(() => {
    dispatch(actions.fetchSources());
  }, [dispatch]);

  const onSelectOrganization = useCallback(
    (org) => {
      dispatch(actions.selectOrganization(org));
      history.push(
        `${indexerQueue}${qs.stringify(
          { ...params, org: org !== null ? org.key : null },
          true
        )}`
      );
    },
    [dispatch, actions.selectOrganization, history, params]
  );
  const onUnselectOrganization = useCallback(() => {
    dispatch(actions.selectOrganization(null));
    onSelectOrganization(null);
  }, [dispatch, actions.selectOrganization, onSelectOrganization]);

  const onSelectSource = useCallback(
    (current) => {
      history.push(
        `${indexerQueue}${qs.stringify(
          { ...params, source: current !== null ? current.value : null },
          true
        )}`
      );
    },
    [history, params]
  );
  const onUnselectSource = useCallback(() => {
    onSelectSource(null);
  }, [onSelectSource]);
  const sourceOptions = useMemo(
    () =>
      sources?.map((s) => ({
        key: `${s.id}`,
        value: s.id,
        label: getSourceLabel(s),
      })) || [],
    [sources]
  );
  const selectedSource = useMemo(
    () =>
      selectedFilters.source === null
        ? []
        : sourceOptions.filter((e) => e.value === selectedFilters.source),
    [selectedFilters, sourceOptions]
  );

  const onSelectPriority = useCallback(
    (current) => {
      history.push(
        `${indexerQueue}${qs.stringify(
          { ...params, prio: current != null ? current.value : null },
          true
        )}`
      );
    },
    [history, params]
  );
  const onUnselectPriority = useCallback(() => {
    onSelectPriority(null);
  }, [onSelectPriority]);
  const priorityOptions = useMemo(
    () =>
      [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((p) => ({
        key: `${p}`,
        value: p,
        label: `${p}`,
      })) || [],
    []
  );
  const selectedPriority = useMemo(
    () =>
      selectedFilters.priority === null
        ? []
        : priorityOptions.filter((e) => e.value === selectedFilters.priority),
    [selectedFilters, priorityOptions]
  );

  return (
    <>
      <h3>Organization</h3>
      <OrganizationAutocomplete
        id="indexer-queue-filters-organization"
        onSelect={onSelectOrganization}
        onUnselect={onUnselectOrganization}
        value={selectedOrganization ? [selectedOrganization] : null}
        placeholder="Select organization..."
        searchOnClick
      />
      <br />
      <h3>Source</h3>
      <ListAutocomplete
        id={`indexer-queue-filters-source`}
        onSelect={onSelectSource}
        onUnselect={onUnselectSource}
        values={sourceOptions}
        value={selectedSource}
        placeholder="Select source..."
        searchOnClick
      />
      <br />
      <h3>Priority</h3>
      <ListAutocomplete
        id={`indexer-queue-filters-priority`}
        onSelect={onSelectPriority}
        onUnselect={onUnselectPriority}
        values={priorityOptions}
        value={selectedPriority}
        placeholder="Select priority..."
        searchOnClick
      />
    </>
  );
}
