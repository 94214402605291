import './rule-applier-overview-step.scss';
import { Outcome } from '../type';

type StepProps = {
  name: string;
  expression?: string;
  outcome: Outcome;
};

enum RuleStepStatus {
  error = 'error',
  passed = 'passed',
  failed = 'failed',
  skipped = 'skipped',
}

export const RuleOverviewStep = (props: StepProps) => {
  let expressionStatus: RuleStepStatus = props.outcome?.result
    ? RuleStepStatus.passed
    : RuleStepStatus.failed;
  expressionStatus = props.outcome?.interruption
    ? RuleStepStatus.error
    : expressionStatus;

  const stepStatus: RuleStepStatus = props.expression
    ? expressionStatus
    : RuleStepStatus.skipped;

  return (
    <>
      <h5
        data-testid={`StepOverview${props.name}`}
        className="RuleOverviewStep__Name"
      >
        {props.name}
      </h5>
      <div
        className={`RuleOverviewStep__Overview RuleOverviewStep__Overview--${stepStatus}`}
      >
        <div>
          {props.expression && (
            <>
              <i>Expression used: </i>
              <code className="RuleOverviewStep__Expression">
                {props.expression}
              </code>
            </>
          )}
          {!props.expression && <i>Nothing to evaluate</i>}
        </div>
        <div>
          {!!props.outcome?.interruption && (
            <>
              <i>Outcome: </i>
              <code className="RuleOverviewStep__OutcomeResult">
                {JSON.stringify(
                  props.outcome?.result || { result: true },
                  null
                )}
              </code>
            </>
          )}
          {props.outcome?.interruption && (
            <>
              <div>
                <i>
                  Execution stopped at level{' '}
                  {props.outcome.interruption.call_sequence_number} with error
                </i>
                <code className="RuleOverviewStep__OutcomeResult">
                  {props.outcome.interruption.error_type}
                </code>
                <i>.</i>
              </div>
              <div>
                <i>Message: </i>
                <code className="RuleOverviewStep__OutcomeResult">
                  {props.outcome.interruption.message}
                </code>
              </div>
            </>
          )}
        </div>
        <div className="RuleOverviewStep__Status">
          <span>{stepStatus.toUpperCase()}</span>
        </div>
      </div>
    </>
  );
};
