import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import { Button, SwitchButton } from '@alkem/react-ui-button';
import { SimpleSelect } from '@alkem/react-ui-select';
import { selectOrganization } from '../selectors';
import { changeField, deleteField } from '../actions';
import { FIELD_ROOT } from '../constants';

const FieldSuggestions = ({ organization, field, fieldIndex, onChange }) => {
  const usesFieldsOptions = useMemo(
    () =>
      (organization?.usesFields ?? []).filter(
        ({ entityType }) => entityType === FIELD_ROOT[field.root]?.type
      ),
    [organization, field]
  );
  return (
    <CreatableSelect
      id={`field-suggestions-${fieldIndex}`}
      options={usesFieldsOptions}
      getOptionValue={(field) => field.name}
      getOptionLabel={(field) => field.name}
      getNewOptionData={(name) => ({ name })}
      value={field}
      onChange={(field) => onChange(field?.name ?? null)}
      placeholder={'Select field name...'}
      isSearchable
    />
  );
};

export const Field = React.memo(
  ({ groupFieldIndex, fieldIndex, field }: any) => {
    const dispatch = useDispatch();
    const organization = useSelector(selectOrganization);

    const onChangeField = useCallback(
      (path, value) => {
        dispatch(changeField(groupFieldIndex, fieldIndex, path, value));
      },
      [dispatch, groupFieldIndex, fieldIndex]
    );

    const onDeleteField = useCallback(() => {
      dispatch(deleteField(groupFieldIndex, fieldIndex));
    }, [dispatch, groupFieldIndex, fieldIndex]);

    if (!organization) {
      return null;
    }

    return (
      <div className="OrganizationGroupField__Field">
        <div className="OrganizationGroupField__FieldRoot">
          <SimpleSelect
            id="RootField"
            options={Object.values(FIELD_ROOT).slice(1, 9)}
            value={FIELD_ROOT[field.root]}
            onSelect={(e) => {
              onChangeField('root', e.id);
              onChangeField('name', null);
            }}
          />
        </div>
        <div className="OrganizationGroupField__FieldName">
          <FieldSuggestions
            field={field}
            fieldIndex={fieldIndex}
            organization={organization}
            onChange={(value) => onChangeField('name', value)}
          />
        </div>
        <div className="OrganizationGroupField__FieldOnCreation">
          <SwitchButton
            testid="review-on-creation"
            checked={field.review_on_creation}
            onChange={(e) => onChangeField('review_on_creation', e)}
          />
        </div>
        <div className="OrganizationGroupField__FieldOnUpdate">
          <SwitchButton
            testid="review-on-update"
            checked={field.review_on_update}
            onChange={(e) => onChangeField('review_on_update', e)}
          />
        </div>
        <div className="OrganizationGroupField__FieldDelete">
          <Button warning onClick={onDeleteField}>
            <i className="mdi mdi-trash-can" />
          </Button>
        </div>
      </div>
    );
  }
);
