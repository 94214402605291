import * as events from '../constants/events';

export const receiveGDSNCIList = (list, totalResults) => ({
  type: events.RECEIVE_GDSN_CI_LIST,
  payload: {
    list,
    totalResults,
  },
});

export const getGDSNCIList = () => ({
  type: events.GET_GDSN_CI_LIST,
});

export const loadCINDocuments = (id, onLoadedDocuments) => ({
  type: events.LOAD_GDSN_CI_DETAIL,
  id,
  onLoadedDocuments,
});

export const changeLimit = (limit) => ({
  type: events.CHANGE_LIMIT_GDSN_CI_LIST,
  limit,
});

export const updateFilter = (key, value) => ({
  type: events.UPDATE_GDSN_CI_FILTER,
  key,
  value,
});

export const nextPage = () => ({
  type: events.NEXT_PAGE_GDSN_CI_LIST,
});

export const previousPage = () => ({
  type: events.PREVIOUS_PAGE_GDSN_CI_LIST,
});

export const downloadGDSNDocument = (documentID) => ({
  type: events.DOWNLOAD_GDSN_DOCUMENT,
  documentID,
});

export const openCIHWModal = (cisync) => ({
  type: events.GDSN_CI_OPEN_CIHW_MODAL,
  cisync,
});

export const closeCIHWModal = () => ({
  type: events.GDSN_CI_CLOSE_CIHW_MODAL,
});

export const pushCIHW = (cisyncID, deletionReasonCode) => ({
  type: events.GDSN_CI_PUSH_HIERARCHY_WITHDRAWAL,
  payload: {
    cisyncID,
    deletionReasonCode,
  },
});
