// filters
export const LOAD_FILTERS = 'LOAD_FILTERS';
export const RECEIVE_FILTERS = 'RECEIVE_FILTERS';
export const UPDATE_SELECTED_FILTERS = 'UPDATE_SELECTED_FILTERS';

// list
export const LOAD_IMPORT_LIST = 'LOAD_IMPORT_GENERIC_LIST';
export const LOADING_IMPORT_LIST = 'LOADING_IMPORT_GENERIC_LIST';
export const RECEIVE_IMPORT_LIST = 'RECEIVE_IMPORT_GENERIC_LIST';

// pagination
export const NEXT_PAGE_IMPORT_LIST = 'NEXT_PAGE_IMPORT_GENERIC_LIST';
export const PREVIOUS_PAGE_IMPORT_LIST = 'PREVIOUS_PAGE_IMPORT_GENERIC_LIST';
export const CHANGE_LIMIT_IMPORT_LIST = 'CHANGE_LIMIT_IMPORT_GENERIC_LIST';

// detail
export const LOAD_IMPORT_DETAIL = 'LOAD_IMPORT_GENERIC_DETAIL';
export const RECEIVE_IMPORT_DETAIL = 'RECEIVE_IMPORT_GENERIC_DETAIL';
export const DOWNLOAD_FILE = 'DOWNLOAD_FILE';
