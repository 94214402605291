import {
  CREATE_ORGANIZATION_FIELD_GROUP,
  CREATE_FIELD,
  SAVE_ORGANIZATION_FIELD_GROUP,
  SET_ORGANIZATION,
  ON_FIELD_CHANGE,
  DELETE_ORGANIZATION_FIELD_GROUP,
  ON_ORGANIZATION_FIELD_GROUP_CHANGE,
  DELETE_FIELD,
  LOAD_FIELD_GROUPS_FOR_IMPORT,
  EXPORT_ORGANIZATION_FIELD_GROUP,
  IMPORT_ORGANIZATION_FIELD_GROUP,
  SET_DEFAULT_ORGANIZATION_GROUP,
} from './constants';
import { Organization, OrganizationGroupField } from './type';

export const setOrganization = (organization?: Partial<Organization>) => ({
  type: SET_ORGANIZATION,
  organization,
});

export const loadFieldGroupsForImport = (organizationId) => ({
  type: LOAD_FIELD_GROUPS_FOR_IMPORT,
  organizationId,
});

export const createOrganizationFieldGroup = (organizationId, name) => ({
  type: CREATE_ORGANIZATION_FIELD_GROUP,
  organizationId,
  name,
});

export const organizationFieldGroupChange = (fieldGroupIndex, path, value) => ({
  type: ON_ORGANIZATION_FIELD_GROUP_CHANGE,
  fieldGroupIndex,
  path,
  value,
});

export const saveFieldGroup = (group: OrganizationGroupField) => ({
  type: SAVE_ORGANIZATION_FIELD_GROUP,
  group,
});

export const deleteFieldGroup = (fieldGroupIndex) => ({
  type: DELETE_ORGANIZATION_FIELD_GROUP,
  fieldGroupIndex,
});

export const exportFieldGroup = ({
  group,
  organization,
}: {
  group: OrganizationGroupField;
  organization: Organization;
}) => ({
  type: EXPORT_ORGANIZATION_FIELD_GROUP,
  group,
  organization,
});

export const importFieldGroup = ({
  group,
  organization,
}: {
  group: OrganizationGroupField;
  organization: Organization;
}) => ({
  type: IMPORT_ORGANIZATION_FIELD_GROUP,
  group,
  organization,
});

export const createField = (fieldIndex) => ({
  type: CREATE_FIELD,
  fieldIndex,
});

export const changeField = (fieldGroupIndex, fieldIndex, path, value) => ({
  type: ON_FIELD_CHANGE,
  fieldGroupIndex,
  fieldIndex,
  path,
  value,
});

export const deleteField = (fieldGroupIndex, fieldIndex) => ({
  type: DELETE_FIELD,
  fieldGroupIndex,
  fieldIndex,
});

export const setDefaultFieldGroup = (organizationId, fieldGroupId) => ({
  type: SET_DEFAULT_ORGANIZATION_GROUP,
  organizationId,
  fieldGroupId,
});
