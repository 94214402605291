import { Map } from 'immutable';
import { LISTCOLUMN, READONLY_FIELDS } from 'constants/organization-settings';
import { MODULE_REDUCER_KEY } from 'constants/reducer';
import {
  ORGANIZATION_PAGE_STORE_KEY,
  SETTINGS_REDUCER_KEY,
} from '../constants';

export function selectState(state) {
  return state[MODULE_REDUCER_KEY][ORGANIZATION_PAGE_STORE_KEY][
    SETTINGS_REDUCER_KEY
  ];
}

export function selectSettings(state): Map<string, any> {
  return selectState(state).get('settings');
}

export function selectEditedSettings(state) {
  return selectState(state).get('editedSettings');
}

export function selectEditedOrSourceSetting(state, path) {
  const editedSetting = selectEditedSettings(state).getIn(path);
  if (editedSetting != null) {
    return editedSetting;
  }

  const sourceSetting = selectSettings(state).getIn(path);
  if (sourceSetting != null) {
    return sourceSetting;
  }

  return null;
}

export function hasChange(state) {
  return selectEditedSettings(state).size > 0;
}

export function hasSettingsChange(state) {
  return selectEditedSettings(state).remove(LISTCOLUMN).size > 0;
}

export function hasListColumnChange(state) {
  return selectEditedSettings(state).has(LISTCOLUMN);
}

export function hasReadOnlyFieldChange(state) {
  return selectEditedSettings(state).has(READONLY_FIELDS);
}

// Organization

function selectOrganization(state) {
  return selectState(state).get('organization');
}

export function selectOrganizationType(state) {
  return selectOrganization(state).get('type');
}

export function selectOrganizationId(state) {
  return selectOrganization(state).get('id');
}

// Referential
function selectReferential(state) {
  return selectState(state).get('referentials');
}

export function selectListColumnReferential(state) {
  return selectReferential(state).get('listcolumn');
}

export function selectListReportingReferential(state) {
  return selectReferential(state).get('listreporting');
}

export function selectSuppliersReconciliationKeysReferential(state) {
  return selectReferential(state).get('suppliersReconciliationKeys');
}

export function selectFieldTagReferential(state) {
  return selectReferential(state).get('fieldtags');
}

export function selectFieldTagReferentialLoaded(state) {
  return !!selectReferential(state).get('fieldtags').size;
}

// FieldMetaDataRoots
export function selectFieldMetaDataRoots(state) {
  return selectState(state).get('fieldMetaDataRoots');
}

export function selectFieldMetaDataRootsLoaded(state) {
  return !!selectState(state).get('fieldMetaDataRoots').size;
}
