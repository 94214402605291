// Organization statuses

export const OrganizationStatusInactive = { id: 0, name: 'Inactive' };
export const OrganizationStatusActive = { id: 1, name: 'Active' };
export const OrganizationStatusPendingConfiguration = {
  id: 2,
  name: 'Pending configuration',
};
export const OrganizationStatusDecommissioned = {
  id: 3,
  name: 'Decommissioned',
};

export const OrganizationStatuses = {
  [OrganizationStatusInactive.id]: OrganizationStatusInactive,
  [OrganizationStatusActive.id]: OrganizationStatusActive,
  [OrganizationStatusDecommissioned.id]: OrganizationStatusDecommissioned,
};
