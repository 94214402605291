import classNames from 'classnames';
import { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ListController } from 'components/ui/list/controller';

import {
  selectQueueList,
  selectPage,
  selectDetail,
  selectIsLoading,
} from '../../selectors';
import * as actions from '../../actions';
import * as types from '../../types';
import { Button } from '@alkem/react-ui-button';
import { IndexerQueueOrganizationDetail } from './detail';
import './index.scss';

function OrganizationRow({
  organization,
  showDetail,
}: {
  organization: types.Organization;
  showDetail: boolean;
}) {
  const dispatch = useDispatch();
  const [loadingDetail, setLoadingDetail] = useState<boolean>(false);
  const onToggleDetail = useCallback(() => {
    setLoadingDetail(true);
    if (showDetail) {
      dispatch(actions.fetchDetail(null));
    } else {
      dispatch(actions.fetchDetail(organization.id));
    }
  }, [dispatch, showDetail, organization.id, setLoadingDetail]);
  useEffect(() => {
    setLoadingDetail(false);
  }, [showDetail]);
  return (
    <>
      <div className="IndexerQueueRow">
        <div className="IndexerQueueRow__column IndexerQueueRow__org">{`${organization.name} (${organization.id})`}</div>
        <div className="IndexerQueueRow__column IndexerQueueRow__data">
          {organization.pending.toLocaleString()}
        </div>
        <div className="IndexerQueueRow__column IndexerQueueRow__data">
          {organization.in_progress.toLocaleString()}
        </div>
        <div className="IndexerQueueRow__column IndexerQueueRow__data">
          {organization.total.toLocaleString()}
        </div>
        <div className="IndexerQueueRow__column IndexerQueueRow__exp">
          <Button link onClick={onToggleDetail}>
            <i
              className={classNames(
                'mdi',
                loadingDetail
                  ? `mdi-loading mdi-spin`
                  : `mdi-chevron-${showDetail ? 'down' : 'right'}`
              )}
            />
          </Button>
        </div>
      </div>
      {showDetail && <IndexerQueueOrganizationDetail />}
    </>
  );
}

function OrganizationList({
  organizations,
}: {
  organizations: types.Organization[];
}) {
  const detail = useSelector(selectDetail);
  if (organizations.length === 0) {
    return (
      <>
        Nothing is being indexed for now...
        <i className="mdi mdi-heart-broken" />
      </>
    );
  }
  return (
    <>
      <div className="IndexerQueueHeader">
        <div className="IndexerQueueRow__column IndexerQueueRow__org">
          Organization
        </div>
        <div className="IndexerQueueRow__column IndexerQueueRow__data">
          Pending
        </div>
        <div className="IndexerQueueRow__column IndexerQueueRow__data">
          In progress
        </div>
        <div className="IndexerQueueRow__column IndexerQueueRow__data">
          Total
        </div>
        <div className="IndexerQueueRow__column IndexerQueueRow__exp">
          Details
        </div>
      </div>
      {organizations?.map((organization) => (
        <OrganizationRow
          key={`indexer-queue-${organization.id}`}
          organization={organization}
          showDetail={detail?.id === organization.id}
        />
      )) || <div>No organizations found</div>}
    </>
  );
}

export function IndexerQueueOrganizations() {
  const page: types.Page | null = useSelector(selectPage);
  const isLoading = useSelector(selectIsLoading);
  const organizations: types.Organization[] | null =
    useSelector(selectQueueList);
  const dispatch = useDispatch();

  const onClickRefresh = function () {
    dispatch(actions.fetchQueueList());
    dispatch(actions.fetchQueueStatus());
  };
  useEffect(() => {
    dispatch(actions.fetchQueueList());
  }, [dispatch]);

  const onPreviousPage = useCallback(() => {
    dispatch(actions.previousPage());
  }, [dispatch, actions.previousPage]);
  const onNextPage = useCallback(() => {
    dispatch(actions.nextPage());
  }, [dispatch, actions.nextPage]);
  const onChangePageSize = useCallback(
    (size) => {
      dispatch(actions.changePageSize(size));
    },
    [dispatch, actions.changePageSize]
  );

  return (
    <ListController
      actions={
        <Button
          secondary
          onClick={onClickRefresh}
          className="IndexerQueue__refresh"
          disabled={isLoading}
        >
          <i
            className={classNames(
              'mdi',
              isLoading ? `mdi-loading mdi-spin` : 'mdi-refresh'
            )}
          />
        </Button>
      }
      rowsLength={organizations?.length || 0}
      currentPage={(page?.number || 0) + 1}
      totalPages={page?.total_pages || 0}
      totalResults={page?.total_elements || 0}
      limit={page?.size || 0}
      onNext={onNextPage}
      onPrev={onPreviousPage}
      onLimitChange={onChangePageSize}
      type="indexerQueueList"
    >
      {organizations !== null ? (
        <OrganizationList organizations={organizations} />
      ) : (
        <></>
      )}
    </ListController>
  );
}
