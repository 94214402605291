import { ValidationApi } from '@alkem/sdk-dashboard';

export const validationApi = new ValidationApi({
  immutable: true,
  acceptLanguage: 'en',
});

export const validationApiJS = new ValidationApi({
  immutable: false,
  acceptLanguage: 'en',
});
