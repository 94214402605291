import { HeaderLayout } from '@alkem/react-layout';
import { transactionDashboardAccessPolicy } from 'access-policies';
import { PayloadModal } from 'components/payload-modal';
import Search from 'components/ui/input/search';
import * as routes from 'constants/routes';
import { hasAccess } from 'modules/access-policy/common/utils';
import { selectPermissions } from 'modules/auth/reducer';
import { User, UserPermissions } from 'modules/auth/types';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { selectUser } from 'reducers/user';
import { createStructuredSelector } from 'reselect';
import { separateActions } from 'utils/redux';
import { getUserType } from 'utils/user';
import { closePayloadModal, loadFilters, search, searchQ } from './actions';
import ActionModal from './components/actionModal';
import DiffModal from './components/diffModal';
import TransactionFilters from './components/filters';
import TransactionList from './components/list';
import { selectState } from './reducer';

interface ConnectedProps {
  q: string;
  payloadModal: any;
  user: User;
  permissions: UserPermissions;
}

interface Actions {
  closePayloadModal: () => void;
  search: () => void;
  loadFilters: () => void;
  searchQ: (query: string) => void;
}

interface Props extends ConnectedProps {
  actions: Actions;
}

const mapStateToProps = createStructuredSelector<
  ConnectedProps,
  ConnectedProps
>({
  q: (state) => selectState(state).filters.get('q'),
  payloadModal: (state) => selectState(state).payloadModal,
  user: selectUser,
  permissions: selectPermissions,
});

const mapDispatchToProps: Actions = {
  closePayloadModal,
  loadFilters,
  search,
  searchQ,
};

export function TransactionView({
  actions,
  q,
  payloadModal,
  user,
  permissions,
}: Props) {
  useEffect(() => {
    actions.loadFilters();
  }, [actions]);

  const updateSearchQuery = useCallback(
    (query) => {
      actions.searchQ(query);
    },
    [actions]
  );

  const readOnly = useMemo(
    () =>
      !hasAccess(
        getUserType(user),
        permissions,
        transactionDashboardAccessPolicy,
        'write'
      ),
    [user, permissions]
  );

  return (
    <div>
      <HeaderLayout
        title="Transaction"
        backHref={routes.home}
        backMessage="Back home"
        isTitleSmall
      >
        <Search
          query={q}
          updateSearchQuery={updateSearchQuery}
          placeholder="Search for transaction ids"
        />
      </HeaderLayout>
      <div className="container-fluid row">
        <div className="col-md-3">
          <TransactionFilters />
        </div>
        <div className="col-md-9">
          <TransactionList />
        </div>
      </div>
      <ActionModal readOnly={readOnly} />
      <DiffModal />
      {payloadModal.show && (
        <PayloadModal
          payload={payloadModal.payload}
          onClose={actions.closePayloadModal}
        />
      )}
    </div>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(memo(TransactionView));
