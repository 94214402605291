export const REGEX_GTIN_LIST = /\d{14}(,\d{14})*/g;

export const LANGUAGE_FRENCH = 'fr';
export const LANGUAGE_ENGLISH = 'en';

export const LANGUAGES_FILTERS = [
  {
    id: LANGUAGE_FRENCH,
    name: LANGUAGE_FRENCH,
    label: 'French',
  },
  {
    id: LANGUAGE_ENGLISH,
    name: LANGUAGE_ENGLISH,
    label: 'English',
  },
];

export const FIELD_SUGGESTION_VALIDATION_PENDING = 0;
export const FIELD_SUGGESTION_VALIDATION_VALIDATED = 1;
export const FIELD_SUGGESTION_VALIDATION_REFUSED = 2;
export const FIELD_SUGGESTION_ACCEPTATION_ACCEPTED = 3;
export const FIELD_SUGGESTION_ACCEPTATION_DISMISSED = 4;

export const VALIDATION_STATUS_FILTERS = [
  {
    id: FIELD_SUGGESTION_VALIDATION_PENDING,
    name: 'Pending',
    label: 'Pending',
    statusBarType: 'PENDING',
  },
  {
    id: FIELD_SUGGESTION_VALIDATION_VALIDATED,
    name: 'Validated',
    label: 'Validated',
    statusBarType: 'VALIDATED',
  },
  {
    id: FIELD_SUGGESTION_VALIDATION_REFUSED,
    name: 'Refused',
    label: 'Refused',
    statusBarType: 'REFUSED',
  },
  {
    id: FIELD_SUGGESTION_ACCEPTATION_ACCEPTED,
    name: 'Accepted',
    label: 'Accepted',
    statusBarType: 'ACCEPTED',
  },
  {
    id: FIELD_SUGGESTION_ACCEPTATION_DISMISSED,
    name: 'Dismissed',
    label: 'Dismissed',
    statusBarType: 'DISMISSED',
  },
];

export const PAGINATION_SORT_OPTIONS = [
  {
    id: 'sort-option-global-score-desc',
    label: 'Global Score, desc',
    value: { sortBy: 'metadata.sorting_score', sortOrder: 'desc' },
  },
  {
    id: 'sort-option-global-score-asc',
    label: 'Global Score, asc',
    value: { sortBy: 'metadata.sorting_score', sortOrder: 'asc' },
  },
  {
    id: 'sort-option-proba-desc',
    label: 'Probability, desc',
    value: { sortBy: 'extended_attributes.probability', sortOrder: 'desc' },
  },
  {
    id: 'sort-option-proba-asc',
    label: 'Probability, asc',
    value: { sortBy: 'extended_attributes.probability', sortOrder: 'asc' },
  },
  {
    id: 'sort-option-pv-id-desc',
    label: 'Product Version id, desc',
    value: { sortBy: 'metadata.productversion_id', sortOrder: 'desc' },
  },
  {
    id: 'sort-option-pv-id-asc',
    label: 'Product Version id, asc',
    value: { sortBy: 'metadata.productversion_id', sortOrder: 'asc' },
  },
  {
    id: 'sort-option-suggested-val-desc',
    label: 'Suggested value, desc',
    value: { sortBy: 'field.field_name', sortOrder: 'desc' },
  },
  {
    id: 'sort-option-suggested-asc',
    label: 'Suggested value, asc',
    value: { sortBy: 'field.field_name', sortOrder: 'asc' },
  },
];

export const FIELDMETADATA_KIND_ID = 579;
export const FIELDMETADATA_ISCLASSIFIEDIN_ID = 577;
export const FIELDMETADATA_KIND_ROOT_ID = 80726;

export const MODAL_TYPE_VALIDATE = {
  id: 'validate',
  label: 'Validate',
};

export const MODAL_TYPE_REFUSE = {
  id: 'refuse',
  label: 'Refuse',
};

export const MODAL_TYPE_EDIT = {
  id: 'edit',
  label: 'Edit',
};

export const IS_CURRENT_VALUE_FILTERS_YES = 1;
export const IS_CURRENT_VALUE_FILTERS_NO = 0;

export const IS_CURRENT_VALUE_FILTERS = [
  {
    id: IS_CURRENT_VALUE_FILTERS_YES,
    name: 'Yes',
    label: 'Yes',
  },
  {
    id: IS_CURRENT_VALUE_FILTERS_NO,
    name: 'No',
    label: 'No',
  },
];

export const SOURCE_FILTER_ML = 'ml';
export const SOURCE_FILTER = [
  {
    id: 'ml',
    name: 'ml',
    label: 'Salsify SupplierXM models',
  },
  {
    id: 'carrefour_drive',
    name: 'carrefour_drive',
    label: 'Carrefour Drive',
  },
  {
    id: 'intermarche',
    name: 'intermarche',
    label: 'Intermarche',
  },
  {
    id: 'systemu',
    name: 'systemu',
    label: 'Systeme U',
  },
  {
    id: 'openfoodfacts',
    name: 'openfoodfacts',
    label: 'OpenFoodFacts',
  },
];

export const METRIC_VALUES = [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]
  .reverse()
  .map((number) => ({
    label: `>= ${number}`,
    value: `${number}`,
    id: `${number}`,
    name: `>= ${number}`,
  }));
