import { Authentication } from '@alkem/sdk-dashboard';
import { logout } from 'constants/routes';
import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import Routes from 'routes';
import history from 'utils/history';

export default class Root extends Component {
  componentDidMount() {
    Authentication.addLogoutListener(this.onLogout);
  }

  onLogout() {
    history.push(logout);
  }

  render() {
    return (
      <Router history={history}>
        <Routes />
      </Router>
    );
  }
}
