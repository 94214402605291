import { useCallback, useEffect, useState, ReactNode } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { HeaderLayout } from '@alkem/react-layout';
import { Button } from '@alkem/react-ui-button';

import { getKindList } from 'actions/kinds';
import Search from 'components/ui/input/search';
import * as routes from 'constants/routes';
import { DocumentationModal } from './components/documentation-modal';
import { RuleCreationModal } from './components/rule-creation-modal';
import { ValidationRulesFilters } from './components/rules-filters';
import { ValidationRulesList } from './components/rules-list';

import {
  validationAddToFilters,
  validationClearFilters,
  validationInitFilters,
  validationSearchQuery,
  validationClearSelectedRules,
} from './actions';
import {
  selectValidationFiltersSearch,
  selectValidationRules,
} from './selectors';
import { Rule } from './types';
import { formatRuleSetIdInFilter } from './utils';

export const ValidationDashboardView = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    dispatch(validationInitFilters());
    dispatch(getKindList());
  }, [dispatch]);

  const [shownModal, setShownModal] = useState<
    'documentation' | 'rule-creation' | null
  >(null);

  const hideModal = useCallback(() => setShownModal(null), []);

  const handleRuleCreation = (rule: Rule | undefined) => {
    hideModal();
    if (rule) {
      const { ruleSet } = rule;
      if (ruleSet) {
        dispatch(validationClearFilters());
        dispatch(validationClearSelectedRules());
        dispatch(validationAddToFilters(formatRuleSetIdInFilter(ruleSet)));
        history.push(`/validation/rule/${rule.id}${location.search}`);
      }
    }
  };

  const rules = useSelector(selectValidationRules);

  const q = useSelector(selectValidationFiltersSearch);
  const updateSearchQuery = useCallback(
    (query: string) => {
      dispatch(validationClearSelectedRules());
      dispatch(validationSearchQuery(query));
    },
    [dispatch]
  );

  let modal: ReactNode = null;
  switch (shownModal) {
    case 'documentation':
      modal = <DocumentationModal close={hideModal} />;
      break;
    case 'rule-creation':
      modal = <RuleCreationModal onClose={handleRuleCreation} />;
      break;
    default:
  }

  return (
    <div>
      <HeaderLayout
        title="Validation Dashboard"
        backHref={routes.home}
        backMessage="Back home"
        isTitleSmall
      >
        <Search
          query={q}
          updateSearchQuery={updateSearchQuery}
          placeholder="Search for rule id, name, or any rule content"
        >
          <>
            <Button secondary onClick={() => setShownModal('documentation')}>
              Documentation
            </Button>
            <Button primary onClick={() => setShownModal('rule-creation')}>
              New Rule
            </Button>
          </>
        </Search>
      </HeaderLayout>
      {modal}
      <div className="container-fluid row">
        <div className="col-md-3">
          <ValidationRulesFilters />
        </div>
        <div className="col-md-9">
          <ValidationRulesList rules={rules} />
        </div>
      </div>
    </div>
  );
};
