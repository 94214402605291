const dangerousFields = [
  'enabled',
  'private',
  'specific',
  'isApplicableToLU',
  'isApplicableToDU',
  'applicableForTypePackagings',
  'applicableForKinds',
];

export const dangerousFieldsUpdated = (sourceField, editedField) =>
  dangerousFields.filter(
    (field) => sourceField.get(field) !== editedField.get(field)
  );
export const updateNeedsConfirmation = (sourceField, editedField) => {
  if (!sourceField) {
    return false;
  }
  return dangerousFieldsUpdated(sourceField, editedField).length > 0;
};
