export const SEARCH_IMPORT_LIST = 'SEARCH_IMPORT_LIST';
export const ADD_ONE_OPTION_IMPORT_LIST_FILTER =
  'ADD_ONE_OPTION_IMPORT_LIST_FILTER';

export const NEXT_PAGE_IMPORT_LIST = 'NEXT_PAGE_IMPORT_LIST';
export const PREVIOUS_PAGE_IMPORT_LIST = 'PREVIOUS_PAGE_IMPORT_LIST';
export const CHANGE_LIMIT_IMPORT_LIST = 'CHANGE_LIMIT_IMPORT_LIST';
export const RECEIVE_IMPORT_LIST = 'RECEIVE_IMPORT_LIST';
export const GET_IMPORT_LIST = 'GET_IMPORT_LIST';
export const IMPORT_LIST_IS_LOADING = 'IMPORT_LIST_IS_LOADING';
export const RECEIVE_IMPORT_DETAILS = 'RECEIVE_IMPORT_DETAILS';
export const GET_IMPORT_SPIDER = 'GET_IMPORT_SPIDER';
export const RECEIVE_IMPORT_SPIDER = 'RECEIVE_IMPORT_SPIDER';

export const PUSH_IMPORT = 'PUSH_IMPORT';
export const REDIRECT_TO_TRANSACTION_DASHBOARD =
  'REDIRECT_TO_TRANSACTION_DASHBOARD';
export const PRODUCT_IMPORT_REPORT_GET_FILE = 'PRODUCT_IMPORT_REPORT_GET_FILE';
export const PRODUCT_IMPORT_REPORT_GENERATE = 'PRODUCT_IMPORT_REPORT_GENERATE';
export const SET_SUPPORT_STATUS = 'SET_SUPPORT_STATUS';
export const SET_IMPORT_STATUS = 'SET_IMPORT_STATUS';
export const GET_IMPORT_DETAILS = 'GET_IMPORT_DETAILS';

export const ADD_IMPORT_LIST_FILTER = 'ADD_IMPORT_LIST_FILTER';
export const ADD_ONE_IMPORT_LIST_FILTER = 'ADD_ONE_IMPORT_LIST_FILTER';
export const REMOVE_IMPORT_LIST_FILTER = 'REMOVE_IMPORT_LIST_FILTER';
export const UPDATE_IMPORT_LIST_FILTER = 'UPDATE_IMPORT_LIST_FILTER';
export const CLEAR_IMPORT_LIST_FILTER = 'CLEAR_IMPORT_LIST_FILTER';
export const RECEIVE_IMPORT_LIST_FILTER = 'RECEIVE_IMPORT_LIST_FILTER';
export const UPDATE_SPIDER = 'UPDATE_SPIDER';
