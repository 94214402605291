import rootReducer from 'reducers';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import rootSaga from 'sagas';
import { sagaMiddleware, setSagaMiddlewareAsReady } from './saga';

const initialState = {};
let store;

export function getStore() {
  if (store) {
    return store;
  }
  const middleware = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose)(
    applyMiddleware(
      sagaMiddleware,
      typeof thunk.default === 'function' ? thunk.default : thunk
    )
  );
  store = middleware(createStore)(combineReducers(rootReducer), initialState);
  sagaMiddleware.run(rootSaga);
  setSagaMiddlewareAsReady();
  return store;
}
