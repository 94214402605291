import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { HelpTooltip } from '@alkem/react-ui-helptooltip';

import { separateActions } from 'utils/redux';
import { LISTREPORTING } from 'constants/organization-settings';
import ListMultipleController from './controllers/listmultiple';
import Section from './section';
import { retrieveListReportingReferential } from '../../actions/settings';
import { selectListReportingReferential } from '../../selectors/settings';

const mapStateToProps = (state) => ({
  referential: selectListReportingReferential(state),
});

const mapDispatchToProps = {
  retrieveListReportingReferential,
};

export class OrganizationPageSettingsListReporting extends PureComponent {
  static propTypes = {
    referential: ImmutablePropTypes.list.isRequired,
    isReadOnly: PropTypes.bool,
    actions: PropTypes.shape({
      retrieveListReportingReferential: PropTypes.func.isRequired,
    }).isRequired,
  };

  componentDidMount() {
    this.props.actions.retrieveListReportingReferential();
  }

  formatLabel() {
    return (
      <span>
        Reporting list{' '}
        <HelpTooltip
          id="listreporting-help"
          message="Column to display in the reporting"
        />
      </span>
    );
  }

  render() {
    const { referential, isReadOnly } = this.props;
    return (
      <Section label={this.formatLabel()}>
        <ListMultipleController
          pathsLabels={['Demo', 'Allowed']}
          path={[LISTREPORTING]}
          subPaths={['demo', 'allowed']}
          options={referential}
          optionsLabelPath="code"
          disabled={isReadOnly}
        />
      </Section>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(OrganizationPageSettingsListReporting);
