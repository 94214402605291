import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './tabs.scss';

export default class Tabs extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    actions: PropTypes.node,
  };

  static defaultProps = {
    actions: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      selected: 0,
    };
  }

  getTitles() {
    const { children } = this.props;
    return children.map((child) => child.props.title);
  }

  select = (index) => {
    this.setState({ selected: index });
  };

  renderTabTitle = (title, index) => {
    const selected = index === this.state.selected;
    const className = classNames({ active: selected, 'nav-item': true });
    const id = title.replace(/\s+/, '-');

    return (
      <li className={className} key={title} role="presentation">
        <a
          className="nav-link"
          title={title}
          id={id}
          onClick={(event) => {
            event.preventDefault();
            this.select(index);
          }}
          data-index={index}
        >
          {title}
        </a>
      </li>
    );
  };

  renderTabContent = (index) => {
    const selected = index === this.state.selected;
    const className = classNames({ active: selected });
    const child = this.props.children[index];
    return (
      <div className={className} key={child.props.title}>
        {child}
      </div>
    );
  };

  render() {
    return (
      <div className="Tabs">
        <div className="Tabs_TabBar">
          <ul className="Tabs__titles nav nav-pills nav-justified">
            {this.getTitles().map(this.renderTabTitle)}
          </ul>
          {this.props.actions}
        </div>
        <div className="Tabs__contents">
          {this.renderTabContent(this.state.selected)}
        </div>
      </div>
    );
  }
}
