import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { fromJS } from 'immutable';

import { Text } from '@alkem/react-ui-inputs';
import { Button } from '@alkem/react-ui-button';
import { OrganizationGroupAutocomplete } from 'components/autocomplete';

import './organization-group.scss';

export default class OrganizationGroup extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    group: ImmutablePropTypes.map,
    disabled: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
  };

  static defaultProps = {
    disabled: false,
  };

  state = {
    creating: false,
    name: '',
  };

  onStartCreating = () => {
    this.setState({ creating: true });
  };

  onUpdateName = (event) => {
    this.setState({ name: event.target.value });
  };

  onCreate = () => {
    this.props.onCreate(this.state.name.trim());
    this.setState({ creating: false, name: '' });
  };

  onSelect = (group) => {
    this.props.onSelect(fromJS(group.value));
  };

  onUnselect = () => {};

  formatValue(group) {
    if (!group) {
      return [];
    }
    return [{ ...group.toJS(), label: group.get('name') }];
  }

  render() {
    const { id, group, disabled } = this.props;
    const { creating, name } = this.state;
    return (
      <div className="OrganizationGroup">
        <div className="OrganizationGroup__row">
          <OrganizationGroupAutocomplete
            id={`${id}-autocomplete`}
            disabled={disabled}
            value={this.formatValue(group)}
            onSelect={this.onSelect}
            onUnselect={this.onUnselect}
            searchOnClick
          />
          {!disabled && (
            <i
              className="mdi mdi-plus-circle OrganizationGroup__add"
              onClick={this.onStartCreating}
            />
          )}
        </div>
        {creating && (
          <div className="OrganizationGroup__row">
            <Text
              id={`${id}-name`}
              placeholder="New group name..."
              value={name || ''}
              onChange={this.onUpdateName}
            />
            <Button
              id={`${id}-create`}
              content="Create"
              onClick={this.onCreate}
              disabled={!name.trim()}
              primary
            />
          </div>
        )}
      </div>
    );
  }
}
