import { MODULE_REDUCER_KEY } from 'constants/reducer';
import { List, Map } from 'immutable';
import memoize from 'memoize-one';
import { createSelector } from 'reselect';
import { REDUCER_KEY } from '../constants';

export const selectModuleState = (state) =>
  state[MODULE_REDUCER_KEY][REDUCER_KEY];

export const getReferentials = createSelector(selectModuleState, (state) =>
  state.get('list')
);

export const getRefreshingCache = createSelector(selectModuleState, (state) =>
  state.get('refreshingCache')
);

export const getIsLoading = createSelector(selectModuleState, (state) =>
  state.get('isLoading')
);

export const selectEntityIdsToDelete = createSelector(
  selectModuleState,
  (state) => state.get('entityIdsToDelete')
);

export const getEntitiesByRefIds = createSelector(selectModuleState, (state) =>
  state.get('byId')
);

export const getEntities = createSelector(
  getEntitiesByRefIds,
  (entitiesByRefId) => memoize((refId) => entitiesByRefId.get(refId))
);

export const getGroups = createSelector(selectModuleState, (state) =>
  state.get('referencialGroups')
);

export const getSelectedGroups = createSelector(getGroups, (groups) =>
  groups.filter((group) => group.get('checked')).get(0)
);

export const getSelectedGroupsEntitiesCode = createSelector(
  getGroups,
  (groups) =>
    (groups.filter((group) => group.get('checked')).get(0) || Map())
      .get('entities', List())
      .map((entity) => entity.get('code'))
);

export const getRemainingFieldsFromGroup = createSelector(
  getSelectedGroupsEntitiesCode,
  getEntitiesByRefIds,
  (entitiesCode, entitiesByRefId) => (refId) => {
    const entities = entitiesByRefId.getIn([refId, 'source']) || List();
    return entities.filter(
      (entity) => !entitiesCode.includes(entity.get('code'))
    );
  }
);
