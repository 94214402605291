import { combineReducers } from 'redux';

import {
  DASHBOARD_REDUCER_KEY,
  REPORTING_TABLE_REDUCER_KEY,
} from '../constants';

import dashboardReducer from './dashboard';
import reportingTableReducer from './reporting-table';

export default combineReducers({
  [DASHBOARD_REDUCER_KEY]: dashboardReducer,
  [REPORTING_TABLE_REDUCER_KEY]: reportingTableReducer,
});
