import { lazy } from 'react';

export const EdaEventCapturesModule = lazy(async () => {
  const { EdaEventCapturesEntrypoint } = await import('./captures/components');
  return { default: EdaEventCapturesEntrypoint };
});

export const EdaCapturedEventsModule = lazy(async () => {
  const { EdaCapturedEventsEntrypoint } = await import('./captured-events');
  return { default: EdaCapturedEventsEntrypoint };
});

export const EdaCapturedEventsGraphModule = lazy(async () => {
  const { EdaCapturedEventsGraphEntryPoint } = await import('./graph');
  return { default: EdaCapturedEventsGraphEntryPoint };
});
