import { Textarea } from '@alkem/react-ui-inputs';
import classNames from 'classnames';
import InputWithLabel from 'components/input-with-label';
import { useState } from 'react';

interface Props {
  documentation?: string;
  onUpdateValue: (path, value) => void;
}

export const ValidationRuleDocumentation = ({
  documentation,
  onUpdateValue,
}: Props) => {
  const [isEditable, toggleEditable] = useState(false);
  const onChange = (event) => {
    onUpdateValue(['documentation'], event.target.value);
  };

  return (
    <div
      className={classNames({
        ValidationRule__documentation: true,
        empty: !documentation,
      })}
    >
      <InputWithLabel inputId="rule-doc-edit" label="Documentation">
        {!isEditable ? (
          <div onDoubleClick={() => toggleEditable(!isEditable)}>
            <span>{documentation}</span>
            <span className="empty-content">
              No documentation was provided. Double-click to edit.
            </span>
          </div>
        ) : (
          <Textarea
            id="rule-doc-edit"
            autoFocus
            value={documentation || ''}
            onChange={onChange}
            onBlur={() => toggleEditable(!isEditable)}
          />
        )}
      </InputWithLabel>
    </div>
  );
};
