import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { Modal } from '@alkem/react-ui-modal';

import { ruleEntityTypesMap } from 'modules/validation-dashboard/constants';

export function getFieldsDiff(sourceOrganization, editedOrganization) {
  // Fields.

  const sourceFields = sourceOrganization
    .get('usesFields')
    .map((f) => `${f.get('name')} - ${f.get('entityType')}`)
    .toArray();
  const editedFields = editedOrganization
    .get('usesFields')
    .map((f) => `${f.get('name')} - ${f.get('entityType')}`)
    .toArray();
  const removedFields = new Set(sourceFields);
  for (const f of editedFields) {
    removedFields.delete(f);
  }
  const addedFields = new Set(editedFields);
  for (const f of sourceFields) {
    addedFields.delete(f);
  }

  // Blocking fields.
  const sourceBlockingFields = sourceOrganization
    .get('rules')
    .filter((r) => r.get('restrictionType') === 1)
    .map(
      (r) =>
        `${r.getIn(['template', 'field', 'name'])} - ${
          ruleEntityTypesMap[r.get('entityType')].label
        }`
    );
  const editedBlockingFields = editedOrganization
    .get('rules')
    .filter((r) => r.get('restrictionType') === 1)
    .map(
      (r) =>
        `${r.getIn(['template', 'field', 'name'])} - ${
          ruleEntityTypesMap[r.get('entityType')].label
        }`
    );
  const removedBlockingFields = new Set(sourceBlockingFields);
  for (const f of editedBlockingFields) {
    removedBlockingFields.delete(f);
  }
  const addedBlockingFields = new Set(editedBlockingFields);
  for (const f of sourceBlockingFields) {
    addedBlockingFields.delete(f);
  }

  // Blocking rule sets.
  const sourceBlockingRuleSets = sourceOrganization
    .get('ruleSets')
    .filter((rs) => rs.get('restrictionType') === 1)
    .map((rs) => rs.get('label'))
    .toArray();
  const editedBlockingRuleSets = editedOrganization
    .get('ruleSets')
    .filter((rs) => rs.get('restrictionType') === 1)
    .map((rs) => rs.get('label'))
    .toArray();
  const removedBlockingRuleSets = new Set(sourceBlockingRuleSets);
  for (const l of editedBlockingRuleSets) {
    removedBlockingRuleSets.delete(l);
  }
  const addedBlockingRuleSets = new Set(editedBlockingRuleSets);
  for (const l of sourceBlockingRuleSets) {
    addedBlockingRuleSets.delete(l);
  }

  return {
    addedFields: Array.from(addedFields),
    removedFields: Array.from(removedFields),
    addedBlockingFields: Array.from(addedBlockingFields),
    removedBlockingFields: Array.from(removedBlockingFields),
    addedBlockingRuleSets: Array.from(addedBlockingRuleSets),
    removedBlockingRuleSets: Array.from(removedBlockingRuleSets),
  };
}

export default class ConfirmSaveModal extends PureComponent {
  static propTypes = {
    sourceOrganization: ImmutablePropTypes.map,
    editedOrganization: ImmutablePropTypes.map,
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  render() {
    const { sourceOrganization, editedOrganization, onSave, onClose } =
      this.props;
    const {
      addedFields,
      removedFields,
      addedBlockingFields,
      removedBlockingFields,
      addedBlockingRuleSets,
      removedBlockingRuleSets,
    } = getFieldsDiff(sourceOrganization, editedOrganization);

    const resyncNeeded =
      addedFields.length > 0 ||
      removedBlockingFields.length > 0 ||
      removedBlockingRuleSets.length > 0;
    const manufacturerUpdateNeeded =
      addedBlockingFields.length > 0 || addedBlockingRuleSets.length > 0;
    const cleanNeeded = removedFields.length > 0;

    return (
      <Modal
        danger
        title="Confirm save"
        confirmButtonText="Save"
        onConfirm={onSave}
        onClose={onClose}
      >
        <p>Please confirm the following changes are OK.</p>
        {resyncNeeded && (
          <p>
            <i className="mdi mdi-alert-circle" /> A resync will be scheduled.
          </p>
        )}
        {cleanNeeded && (
          <p>
            ⚠️ For those changes to be effective on already shared products, you
            will need to clean products.
          </p>
        )}
        {manufacturerUpdateNeeded && (
          <p>
            ⚠️ Manufacturers might have to update their products to have those
            kept in sync with this retailer.
          </p>
        )}
        <br />
        {addedFields.length > 0 && (
          <>
            <p>The following fields are added in the view as:</p>
            <ul>
              {addedFields.map((e) => (
                <li key={e}>{e}</li>
              ))}
            </ul>
          </>
        )}
        {removedFields.length > 0 && (
          <>
            <p>The following fields are removed in the view as:</p>
            <ul>
              {removedFields.map((e) => (
                <li key={e}>{e}</li>
              ))}
            </ul>
          </>
        )}
        {addedBlockingFields.length > 0 && (
          <>
            <p>The following fields are required as blocking:</p>
            <ul>
              {addedBlockingFields.map((e) => (
                <li key={e}>{e}</li>
              ))}
            </ul>
          </>
        )}
        {removedBlockingFields.length > 0 && (
          <>
            <p>The following fields are not longer required as blocking:</p>
            <ul>
              {removedBlockingFields.map((e) => (
                <li key={e}>{e}</li>
              ))}
            </ul>
          </>
        )}
        {addedBlockingRuleSets.length > 0 && (
          <>
            <p>The following rule sets are required as blocking:</p>
            <ul>
              {addedBlockingRuleSets.map((e) => (
                <li key={e}>{e}</li>
              ))}
            </ul>
          </>
        )}
        {removedBlockingRuleSets.length > 0 && (
          <>
            <p>The following rule sets are not longer required as blocking:</p>
            <ul>
              {removedBlockingRuleSets.map((e) => (
                <li key={e}>{e}</li>
              ))}
            </ul>
          </>
        )}
      </Modal>
    );
  }
}
