import React from 'react';

import { ModuleWrapper } from 'redux/register';

import { REDUCER_KEY } from './constants';
import reducer from './reducers';
import OrganizationDeactivate from './components';

export default function OrganizationDeactivateContainer(props) {
  return (
    <ModuleWrapper reducerKey={REDUCER_KEY} reducer={reducer}>
      <OrganizationDeactivate {...props} />
    </ModuleWrapper>
  );
}
