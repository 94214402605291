import * as events from '../constants/events';

export const receiveEnpointList = (list, totalResults) => ({
  type: events.RECEIVE_ENDPOINT_LIST,
  payload: {
    list,
    totalResults,
  },
});

export const receiveEnpointListFiles = (files, totalResults, endpointId) => ({
  type: events.RECEIVE_ENDPOINT_LIST_FILES,
  payload: {
    files,
    totalResults,
    endpointId,
  },
});

export const receiveEnpointListFilters = (data) => ({
  type: events.RECEIVE_ENDPOINT_LIST_FILTERS,
  payload: {
    data,
  },
});

export const getEndpointList = () => ({
  type: events.GET_ENDPOINT_LIST,
});

export const getEndpointListFiles = (endpointId, offset, limit) => ({
  type: events.GET_ENDPOINT_LIST_FILES,
  payload: {
    endpointId,
    offset,
    limit,
  },
});

export const getEndpointListFilters = () => ({
  type: events.GET_ENDPOINT_LIST_FILTERS,
});

export const changeLimit = (limit) => ({
  type: events.CHANGE_LIMIT_ENDPOINT_LIST,
  limit,
});

export const nextPage = () => ({
  type: events.NEXT_PAGE_ENDPOINT_LIST,
});

export const previousPage = () => ({
  type: events.PREVIOUS_PAGE_ENDPOINT_LIST,
});

export const openModal = (endpoint) => ({
  type: events.ENDPOINT_MODAL_OPEN,
  payload: endpoint,
});

export const closeModal = () => ({
  type: events.ENDPOINT_MODAL_CLOSE,
});

export const updateEndpoint = (key, value) => ({
  type: events.ENDPOINT_MODAL_UPDATE,
  payload: {
    key,
    value,
  },
});

export const addEndpointListFilters = (key, value) => ({
  type: events.ADD_ENDPOINT_LIST_FILTER,
  key,
  value,
});

export const removeEndpointListFilters = (key, value) => ({
  type: events.REMOVE_ENDPOINT_LIST_FILTER,
  key,
  value,
});

export const updateEndpointListFilters = (key, value) => ({
  type: events.UPDATE_ENDPOINT_LIST_FILTER,
  key,
  value,
});

export const clearEndpointListFilters = (key, value) => ({
  type: events.CLEAR_ENDPOINT_LIST_FILTER,
  key,
  value,
});

export const saveEndpoint = () => ({
  type: events.ENDPOINT_MODAL_SAVE,
});

export const deleteEndpoint = (endpointID, hideConfirmDeleteModal) => ({
  type: events.ENDPOINT_DELETE,
  endpointID,
  hideConfirmDeleteModal,
});

export const consentGMAIL = (
  consentCode,
  redirectURI,
  organizationID,
  closeModalFunc
) => ({
  type: events.ENDPOINT_GMAIL_CONSENT,
  payload: {
    consent_code: consentCode,
    redirect_uri: redirectURI,
    organization_id: organizationID,
  },
  closeModalFunc,
});
