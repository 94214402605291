import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ListController from 'components/ui/list/controller';

import { Spinner } from '@alkem/react-ui-spinner';
import { Button } from '@alkem/react-ui-button';

import { separateActions } from 'utils/redux';

import {
  selectState,
  selectList,
  selectPagination,
  selectModal,
} from '../selectors';

import GDSNPartyListRow from './row';
import GDSNPartyModal from '../modal';

import {
  nextPage,
  previousPage,
  changeLimit,
  getGDSNPartyList,
  openPartyModal,
} from '../actions';

import './list.scss';

const mapStateToProps = (state) => ({
  list: selectList(state),
  isOpen: selectModal(state).isOpen,
  isLoading: selectState(state).isLoading,
  pagination: selectPagination(state),
});

const mapDispatchToProps = {
  getGDSNPartyList,
  nextPage,
  previousPage,
  changeLimit,
  openPartyModal,
};

export class GDSNPartyList extends PureComponent {
  static propTypes = {
    list: PropTypes.array.isRequired,
    isOpen: PropTypes.bool,
    isLoading: PropTypes.bool,
    pagination: PropTypes.object.isRequired,
    actions: PropTypes.shape({
      getGDSNPartyList: PropTypes.func.isRequired,
      nextPage: PropTypes.func.isRequired,
      previousPage: PropTypes.func.isRequired,
      changeLimit: PropTypes.func.isRequired,
      openPartyModal: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    isLoading: true,
  };

  componentDidMount() {
    this.props.actions.getGDSNPartyList();
  }

  renderList() {
    const { list, isLoading } = this.props;
    if (isLoading) {
      return (
        <div className="GDSNPartyList__spinner">
          <Spinner loading big />
        </div>
      );
    }
    return list.map((party) => (
      <GDSNPartyListRow key={party.id} party={party} />
    ));
  }

  actionBar() {
    return (
      <div>
        <Button onClick={this.openModal}>
          <i className="mdi mdi-plus" />
        </Button>
      </div>
    );
  }

  openModal = () => {
    this.props.actions.openPartyModal();
  };

  render() {
    const { pagination, actions, isOpen } = this.props;
    const { currentPage, totalPages, limit, totalResults } = pagination;
    return (
      <div>
        <ListController
          actions={this.actionBar()}
          onNext={actions.nextPage}
          onPrev={actions.previousPage}
          currentPage={currentPage}
          totalPages={totalPages}
          limit={limit}
          totalResults={totalResults}
          onLimitChange={actions.changeLimit}
          rowsLength={0}
          type="gdsnpartylist"
        >
          {this.renderList()}
        </ListController>
        {isOpen && <GDSNPartyModal />}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(GDSNPartyList);
