import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { Text } from '@alkem/react-ui-inputs';

import './entities.scss';

const isValid = (value) =>
  (value.match(/[a-zA-Z]+ [0-9]+/g) || []).length === 1;

class EntitiesFilter extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.node.isRequired,
    onAdd: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    value: ImmutablePropTypes.listOf(PropTypes.string).isRequired,
  };

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);

    this.state = { hasError: false, current: '' };
  }

  onChange(evt) {
    const current = evt.target.value;
    this.setState({ current, hasError: current && !isValid(current) });
  }

  onKeyPress(evt) {
    const { onAdd } = this.props;
    const { current } = this.state;
    if (evt.charCode === 13 && isValid(current)) {
      // on press enter
      onAdd({ key: current, label: current });
      this.setState({ current: '', hasError: false });
    }
  }

  render() {
    const { id, title, value, onRemove } = this.props;
    const { current, hasError } = this.state;

    return (
      <div id={id}>
        <h3 className="ReactUiFilter__filterTitle">{title}</h3>
        <div className="TransactionFilter__Entities">
          <Text
            id={`entity-filter-id-input-${id}`}
            value={current}
            placeholder="Type + id (space separated)"
            onChange={this.onChange}
            onKeyPress={this.onKeyPress}
          />
          {hasError && (
            <div className="TransactionFilter__InvalidEntity">
              <small>Invalid: format is "&lt;type&gt; &lt;id&gt;"</small>
            </div>
          )}
          {value.size > 0 && (
            <ul>
              {value.map((e) => (
                <li
                  key={e}
                  className="flex flex-align-items--center flex-justify-content--space-between"
                >
                  <div>{e}</div>
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={() => onRemove({ key: e, label: e })}
                  >
                    <i className="mdi mdi-close" />
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    );
  }
}
export default EntitiesFilter;
