import { createReducer } from 'redux-create-reducer';
import { fromJS } from 'immutable';
import * as events from '../constants/events';

const initialState = fromJS({
  organizationLoaded: false,
  managedOrganizationPermissions: null,
});

export default createReducer(initialState, {
  [events.RESET_ORGANIZATION]: (state) =>
    state.set('organizationLoaded', false),

  [events.ORGANIZATION_RECEIVED]: (state) =>
    state.set('organizationLoaded', true),

  [events.MANAGED_ORGANIZATION_PERMISSIONS_RECEIVED]: (
    state,
    { permissions }
  ) => state.set('managedOrganizationPermissions', permissions),

  [events.RESET_MANAGED_ORGANIZATION_PERMISSIONS]: (state) =>
    state.set('managedOrganizationPermissions', null),
});
