import React from 'react';
import { NameAutocomplete } from './name-autocomplete';
import fieldsApi from 'resources/fieldsApi';
import { LegacyAutocompleteProps } from '../legacy-autocomplete';

export const FieldTypesAutocomplete = React.memo(
  function FieldTypesAutocomplete(props: LegacyAutocompleteProps) {
    const onSearch = (search) => {
      const parameters: any = { search, limit: 20 };
      return fieldsApi.FieldMetaDataTypesList(parameters);
    };
    return <NameAutocomplete onSearch={onSearch} {...props} />;
  }
);
