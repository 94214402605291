import React from 'react';

import { ModuleWrapper } from 'redux/register';

import reducer, { REDUCER_KEY } from './reducers';
import OrganizationGroups from './components';

export default function OrganizationGroupsContainer() {
  return (
    <ModuleWrapper reducerKey={REDUCER_KEY} reducer={reducer}>
      <OrganizationGroups />
    </ModuleWrapper>
  );
}
