// List
export const RECEIVE_GDSN_PSR_LIST = 'RECEIVE_GDSN_PSR_LIST';
export const GET_GDSN_PSR_LIST = 'GET_GDSN_PSR_LIST';
export const GET_GDSN_PSR_LIST_FILTERS = 'GET_GDSN_PSR_LIST_FILTERS';
export const CHANGE_LIMIT_GDSN_PSR_LIST = 'CHANGE_LIMIT_GDSN_PSR_LIST';
export const NEXT_PAGE_GDSN_PSR_LIST = 'NEXT_PAGE_GDSN_PSR_LIST';
export const PREVIOUS_PAGE_GDSN_PSR_LIST = 'PREVIOUS_PAGE_GDSN_PSR_LIST';

// Filters
export const ADD_GDSN_PSR_LIST_FILTER = 'ADD_GDSN_PSR_LIST_FILTER';
export const REMOVE_GDSN_PSR_LIST_FILTER = 'REMOVE_GDSN_PSR_LIST_FILTER';
export const UPDATE_GDSN_PSR_LIST_FILTER = 'UPDATE_GDSN_PSR_LIST_FILTER';
export const CLEAR_GDSN_PSR_LIST_FILTER = 'CLEAR_GDSN_PSR_LIST_FILTER';

// Document
export const DOWNLOAD_GDSN_PSR_DOCUMENT = 'DOWNLOAD_GDSN_PSR_DOCUMENT';

// Detail
export const GET_GDSN_PSR_DETAIL = 'GET_GDSN_PSR_DETAIL';
export const DELETE_GDSN_PSR_ITEM = 'DELETE_GDSN_PSR_ITEM';
export const RECEIVE_GDSN_PSR_DETAIL = 'RECEIVE_GDSN_PSR_DETAIL';
