import { createReducer } from 'utils/reducers';
import { ActionWithPayload } from 'utils/reducers/types';

import {
  Field,
  Fields,
  FieldCategories,
  GDSNImportMappingsState,
  ImportMapping,
  ImportMappings,
  ReferentialCodes,
} from '../types';

import {
  RECEIVE_FIELD,
  RECEIVE_FIELD_CATEGORIES_LIST,
  RECEIVE_FIELDS_LIST,
  RECEIVE_IMPORT_MAPPINGS_LIST,
  SET_IMPORT_MAPPING_PROPERTY,
  SET_IS_SAVING_IN_PROGRESS,
  RECEIVE_REFERENTIAL_CODES_LIST,
  SET_IMPORT_MAPPING_PROPERTY_IS_TESTED,
} from '../actions/constants';

type State = GDSNImportMappingsState;

const initialState: State = {
  fields: undefined,
  fieldCategories: undefined,
  importMappings: undefined,
  isSavingInProgress: false,
  sourceField: undefined,
  referentialCodes: undefined,
};

export default createReducer<State>(initialState, {
  [RECEIVE_FIELD]: (
    state,
    { payload }: ActionWithPayload<{ sourceField?: Field }>
  ) => ({
    ...state,
    sourceField: payload?.sourceField,
  }),
  [RECEIVE_FIELDS_LIST]: (
    state,
    { payload }: ActionWithPayload<{ fields?: Fields }>
  ) => ({
    ...state,
    fields: payload?.fields,
  }),
  [RECEIVE_FIELD_CATEGORIES_LIST]: (
    state,
    { payload }: ActionWithPayload<{ fieldCategories?: FieldCategories }>
  ) => ({
    ...state,
    fieldCategories: payload?.fieldCategories,
  }),
  [RECEIVE_IMPORT_MAPPINGS_LIST]: (
    state,
    { payload }: ActionWithPayload<{ importMappings?: ImportMappings }>
  ) => ({
    ...state,
    importMappings: payload?.importMappings,
  }),
  [RECEIVE_REFERENTIAL_CODES_LIST]: (
    state,
    {
      payload,
    }: ActionWithPayload<{
      referential: string;
      referentialCodes?: ReferentialCodes;
    }>
  ) => {
    if (!payload) return { ...state };

    const referentialCodes = { ...(state.referentialCodes || {}) };
    referentialCodes[payload.referential] =
      payload.referentialCodes || undefined;
    return {
      ...state,
      referentialCodes,
    };
  },
  [SET_IMPORT_MAPPING_PROPERTY]: (
    state,
    { payload }: ActionWithPayload<{ importMapping: ImportMapping }>
  ) => ({
    ...state,
    importMappings: (state.importMappings || []).map((importMapping) => {
      if (importMapping.id !== payload?.importMapping.id) return importMapping;
      return { ...payload.importMapping, isTested: false };
    }),
  }),
  [SET_IMPORT_MAPPING_PROPERTY_IS_TESTED]: (
    state,
    {
      payload,
    }: ActionWithPayload<{
      importMappingId: string | number;
      isTested: boolean;
    }>
  ) => ({
    ...state,
    importMappings: (state.importMappings || []).map((importMapping) => {
      if (importMapping.id !== payload?.importMappingId) return importMapping;
      return { ...importMapping, isTested: payload?.isTested };
    }),
  }),
  [SET_IS_SAVING_IN_PROGRESS]: (
    state,
    { payload }: ActionWithPayload<{ isSavingInProgress: boolean }>
  ) => ({
    ...state,
    isSavingInProgress: payload?.isSavingInProgress || false,
  }),
});
