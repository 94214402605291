export const GLNCLAIMS_LOADING = 'GLNCLAIM/GLNCLAIMS_LOADING';
export const GLNCLAIMS_ACTION_IN_PROGRESS =
  'GLNCLAIM/GLNCLAIMS_ACTION_IN_PROGRESS';
export const GLNCLAIMS_SET_ACTIONNABLE_CLAIMS_ONLY =
  'GLNCLAIM/GLNCLAIMS_SET_ACTIONNABLE_CLAIMS_ONLY';
export const GLNCLAIMS_RECEIVED = 'GLNCLAIM/GLNCLAIMS_RECEIVED';
export const GLNDATA_RECEIVED = 'GLNCLAIM/GLNDATA_RECEIVED';
export const GLNCLAIMS_NEXT_PAGE = 'GLNCLAIM/GLNCLAIMS_NEXT_PAGE';
export const GLNCLAIMS_PREVIOUS_PAGE = 'GLNCLAIM/GLNCLAIMS_PREVIOUS_PAGE';
export const GLNCLAIMS_CHANGE_LIMIT = 'GLNCLAIM/GLNCLAIMS_CHANGE_LIMIT';
export const USERNAME_SEARCH_UPDATE = 'GLNCLAIM/USERNAME_SEARCH_UPDATE';
export const GLN_SEARCH_UPDATE = 'GLNCLAIM/GLN_SEARCH_UPDATE';
