import React from 'react';

import { ModuleWrapper } from 'redux/register';

import { PCR_MODAL } from './constants/reducers';

import reducer from './reducers';
import PcrModal from './components';

export default function PcrModalContainer(props) {
  return (
    <ModuleWrapper reducerKey={PCR_MODAL} reducer={reducer}>
      <PcrModal {...props} />
    </ModuleWrapper>
  );
}
