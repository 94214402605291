export const RETAILER_PICTURE_COMPARATOR_RECEIVE_VERSIONS =
  'RETAILER_PICTURE_COMPARATOR_RECEIVE_VERSIONS';

export const RETAILER_PICTURE_COMPARATOR_SET_LIMIT =
  'RETAILER_PICTURE_COMPARATOR_SET_LIMIT';

export const RETAILER_PICTURE_COMPARATOR_NEXT_PAGE =
  'RETAILER_PICTURE_COMPARATOR_NEXT_PAGE';

export const RETAILER_PICTURE_COMPARATOR_PREVIOUS_PAGE =
  'RETAILER_PICTURE_COMPARATOR_PREVIOUS_PAGE';
