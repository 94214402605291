import { immutableAuthApi } from 'resources/authApi';
import { immutableReferentialApi } from 'resources/referentialApi';
import { immutableKpiApi } from 'resources/kpiApi';
import { fieldsApiImmutable as fieldsApi } from 'resources/fieldsApi';
import { fromJS } from 'immutable';

import { logError } from 'utils';
import { updateSetting } from 'modules/settings/organization/actions';

import { notificationError } from 'actions/notifications';
import { SUPPLIER_RECONCILIATION_KEYS_REFERENTIAL_NAME } from '../constants';
import * as events from '../constants/events';
import {
  selectEditedSettings,
  selectOrganizationId,
  selectSettings,
} from '../selectors/settings';

export const updateSettingValue = (path, value) => ({
  type: events.UPDATE_SETTINGS_VALUE,
  path,
  value,
});

export const addListSettingValue = (path, value) => ({
  type: events.ADD_LIST_SETTING_VALUE,
  path,
  value,
});

export const removeListSettingValue = (path, value) => ({
  type: events.REMOVE_LIST_SETTING_VALUE,
  path,
  value,
});

export const retrieveListColumnReferential = () => async (dispatch) => {
  try {
    const response = await immutableReferentialApi.getListColumnReferentials();
    dispatch({
      type: events.RECEIVE_LISTCOLUMN_REFERENTIAL,
      referential: response.data.data,
    });
  } catch (exc) {
    dispatch(
      notificationError(
        'Something went wrong when retrieving the referential, call a tech'
      )
    );
  }
};

export const retrieveSuppliersReconciliationKeysReferential =
  () => async (dispatch) => {
    try {
      const response = await immutableReferentialApi.ReferentialGetList(
        SUPPLIER_RECONCILIATION_KEYS_REFERENTIAL_NAME
      );
      dispatch({
        type: events.RECEIVE_SUPPLIER_RECONCILIATION_KEYS_REFERENTIAL,
        referential: response.data.data,
      });
    } catch (exc) {
      dispatch(
        notificationError(
          'Something went wrong when retrieving the referential, call a tech'
        )
      );
    }
  };

export const retrieveListReportingReferential = () => async (dispatch) => {
  try {
    const response = await immutableKpiApi.GetDashboardList();
    const filterResponse = response.data.data.filter(
      (x) => x.getIn(['data', 'reporting', 'enable_on_stream', 0]) === 1
    );
    dispatch({
      type: events.RECEIVE_LISTREPORTING_REFERENTIAL,
      referential: filterResponse,
    });
  } catch (exc) {
    dispatch(
      notificationError(
        'Something went wrong when retrieving the referential, call a tech'
      )
    );
  }
};

export const retrieveFieldTagReferential = () => async (dispatch) => {
  try {
    const response = await immutableReferentialApi.ReferentialGetList(
      'fieldtags'
    );
    dispatch({
      type: events.RECEIVE_FIELDTAG_REFERENTIAL,
      referential: response.data.data,
    });
  } catch (exc) {
    dispatch(
      notificationError(
        'Something went wrong when retrieving the referential, call a tech'
      )
    );
  }
};

export const retrieveFieldMetaDataRoots = () => async (dispatch) => {
  try {
    const response = await fieldsApi.FieldMetaDataRoots();
    dispatch({
      type: events.RECEIVE_FIELDMETADATA_ROOTS,
      roots: response.data.data,
    });
  } catch (exc) {
    logError(exc);
    dispatch(
      notificationError(
        'Something went wrong while retrieving fields metadata roots, call a tech'
      )
    );
  }
};

export const save = () => (dispatch, getState) => {
  const state = getState();
  const organizationId = selectOrganizationId(state);
  const settings = selectEditedSettings(state).toJS();

  const promises = [];
  Object.keys(settings).forEach((key) => {
    let value = settings[key];

    // Convert true/false value to string to avoid backend to do it
    // (Will store them as 'True' / 'False' otherwise)
    if (value === true || value === false) {
      value = value.toString();
    }

    promises.push(
      immutableAuthApi.OrganizationSettingUpsert({
        organizationId,
        key,
        value,
      })
    );
  });

  return Promise.all(promises);
};

export const importSettings = (settings) => (dispatch, getState) => {
  // Settings that are not in the feature and release flags block
  Object.entries(settings).forEach(([path, value]) => {
    dispatch(updateSettingValue(path, fromJS(value)));
  });

  // Feature and release flags block
  const organizationSettings = selectSettings(getState());
  Object.entries(settings).forEach(([key, value]) => {
    dispatch(updateSetting({ key, value, organizationSettings }));
  });
};
