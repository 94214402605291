import List from '@alkem/react-ui-list';
import { Spinner } from '@alkem/react-ui-spinner';
import UserEdit from 'modules/user-update';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { computeTotalPages } from 'utils/list';
import {
  changeUsersLimit,
  getUsers,
  nextUsers,
  previousUsers,
} from '../../actions';
import {
  selectAreUsersLoading,
  selectCurrentPage,
  selectLimit,
  selectList,
  selectPagination,
  selectTotalResults,
} from '../../selectors';
import { getUserId } from '../../utils';
import './index.scss';
import UserRow from './row';

const mapStateToProps = (state) => ({
  list: selectList(state),
  pagination: selectPagination(state),
  limit: selectLimit(state),
  totalResults: selectTotalResults(state),
  currentPage: selectCurrentPage(state),
  isLoading: selectAreUsersLoading(state),
});

const mapDispatchToProps = {
  nextPage: nextUsers,
  previousPage: previousUsers,
  getList: getUsers,
  onLimitChange: changeUsersLimit,
};

class UserList extends Component {
  static propTypes = {
    list: ImmutablePropTypes.list.isRequired,
    pagination: PropTypes.object.isRequired,
    limit: PropTypes.number.isRequired,
    totalResults: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    nextPage: PropTypes.func.isRequired,
    previousPage: PropTypes.func.isRequired,
    getList: PropTypes.func.isRequired,
    onLimitChange: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.onCloseUserEdit = this.onCloseUserEdit.bind(this);
    this.optionsLimit = [50, 100, 200];
  }

  componentDidMount() {
    this.props.getList();
  }

  componentDidUpdate(prevProps) {
    const { pagination, limit, currentPage } = this.props;
    if (
      pagination !== prevProps.pagination ||
      limit !== prevProps.limit ||
      currentPage !== prevProps.currentPage
    ) {
      this.props.getList();
    }
  }

  onCloseUserEdit(isEdited) {
    if (isEdited) {
      this.props.getList();
    }
  }

  getLimitLabel(count) {
    return `${count} per page.`;
  }

  getPageLabel(page, total) {
    return `${page} / ${total} ${total > 1 ? 'pages' : 'page'}.`;
  }

  getEntityLabel(count) {
    return `${count} ${count > 1 ? 'users' : 'user'} in total.`;
  }

  computeTotalPages() {
    return computeTotalPages(this.props.totalResults, this.props.limit);
  }

  renderActions() {
    return (
      this.props.isLoading && (
        <div className="UserList__spinner">
          <Spinner small loading />
        </div>
      )
    );
  }

  render() {
    const { list } = this.props;
    return (
      <div>
        <UserEdit onClose={this.onCloseUserEdit} />
        <List
          currentPage={this.props.currentPage}
          totalPages={this.computeTotalPages()}
          totalResults={this.props.totalResults}
          onNext={this.props.nextPage}
          onPrev={this.props.previousPage}
          limit={this.props.limit}
          onLimitChange={this.props.onLimitChange}
          optionsLimit={this.optionsLimit}
          getLimitLabel={this.getLimitLabel}
          getPageLabel={this.getPageLabel}
          getEntityLabel={this.getEntityLabel}
          bottomActions
          actions={this.renderActions()}
        >
          {list.map((user, index) => (
            <UserRow
              key={`row-${getUserId(user)}`}
              user={user}
              isFirst={index === 0}
            />
          ))}
        </List>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
