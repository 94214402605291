import { Modal } from '@alkem/react-ui-modal';
import Well from '@alkem/react-ui-well';
import { Tab, Tabs } from 'components/tabs';
import { isUndefined, sortBy } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { validationApi } from 'resources/validationApi';
import { ConditionDisplayItem } from '../rule-editor/validation-conditions-display';
import './documentation-modal.scss';

// taken from soon-deprecated module 'validation-rules'
export const fetchDocumentation = async () => {
  try {
    const [mappersResponse, pathsResponse, reducersResponse] =
      await Promise.all([
        validationApi.get('/validation/v4/documentation/mappers'),
        validationApi.get('/validation/v4/documentation/paths'),
        validationApi.get('/validation/v4/documentation/reducers'),
      ]);
    return {
      mappers: mappersResponse.data,
      paths: pathsResponse.data,
      reducers: reducersResponse.data,
    };
  } catch (error) {
    return { error };
  }
};

export class DocumentationModal extends PureComponent {
  static propTypes = {
    close: PropTypes.func.isRequired,
  };

  state = {
    mappersByCategory: null,
    paths: null,
    reducers: null,
    error: null,
  };

  componentDidMount = async () => {
    const {
      mappers: mappersByCategory,
      paths,
      reducers,
      error,
    } = await fetchDocumentation();
    this.setState({ mappersByCategory, paths, reducers, error });
  };

  renderExamples = (examples) => {
    if (examples?.length) {
      return (
        <Well header="Examples" v2>
          <div className="alk-flex-column">
            {examples.map((example) => {
              return (
                <div
                  key={JSON.stringify(example)}
                  className="ValidationDocumentationModal__Example"
                >
                  <div className="alk-flex">
                    <span className="ValidationDocumentationModal__ExampleLabel">
                      Expression:
                    </span>
                    <span>{example.expression}</span>
                  </div>
                  <div className="alk-flex">
                    <span className="ValidationDocumentationModal__ExampleLabel">
                      Values:
                    </span>
                    <ConditionDisplayItem
                      id={JSON.stringify(example)}
                      dict={example.values}
                      withCopyButton={false}
                    />
                  </div>
                  <div className="alk-flex">
                    <span className="ValidationDocumentationModal__ExampleLabel">
                      Expected:
                    </span>
                    <span>{`${example.expected}`}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </Well>
      );
    }
  };

  renderPath = (path) => {
    const { type, syntax, help, sub_parsers: subParsers } = path;
    return (
      <div className="ValidationDocumentationModal__item" key={type}>
        <code>{type}</code>
        <div>
          Usage:{' '}
          <span className="ValidationDocumentationModal__inlineValue">
            <pre className="ValidationDocumentationModal__doc">{syntax}</pre>
          </span>
        </div>
        <div>
          Description:{' '}
          <span className="ValidationDocumentationModal__inlineValue">
            <pre className="ValidationDocumentationModal__doc">{help}</pre>
          </span>
        </div>
        <div className="ValidationDocumentationModal__indented">
          {subParsers.map(this.renderPath)}
        </div>
      </div>
    );
  };

  renderMapper = (mapper) => {
    const { keyword, doc, args, examples } = mapper;
    return (
      <div className="ValidationDocumentationModal__item" key={keyword}>
        <code>{keyword}</code>
        <ul>
          {args.map(({ name, default: defaultValue }) => (
            <li key={name}>
              <code>{name}</code>
              {!isUndefined(defaultValue) && (
                <span className="ValidationDocumentationModal__inlineValue">
                  <pre>({JSON.stringify(defaultValue)})</pre>
                </span>
              )}
            </li>
          ))}
        </ul>
        <pre className="ValidationDocumentationModal__doc">{doc}</pre>
        {this.renderExamples(examples)}
      </div>
    );
  };

  renderReducer = (reducer) => {
    const keyword = reducer[0];
    const data = reducer[1];
    return (
      <div className="ValidationDocumentationModal__item" key={keyword}>
        <code>{keyword}</code>
        <pre className="ValidationDocumentationModal__doc">{data.doc}</pre>
      </div>
    );
  };

  renderBody() {
    const { mappersByCategory, paths, reducers, error } = this.state;
    if (error) {
      return 'An error occured while fetching the documentation.';
    }
    if (mappersByCategory && paths && reducers) {
      return (
        <Tabs>
          {[
            <Tab title="JSON paths" key="__paths__">
              {paths.map(this.renderPath)}
            </Tab>,
          ]
            .concat(
              sortBy(([c]) => c, Object.entries(mappersByCategory)).map(
                ([category, mappers]) => (
                  <Tab title={category} key={category}>
                    {sortBy(({ keyword }) => keyword, mappers).map(
                      this.renderMapper
                    )}
                  </Tab>
                )
              )
            )
            .concat([
              <Tab title="Reducers" key="__reducers__">
                {Object.entries(reducers.reducers).map(this.renderReducer)}
              </Tab>,
            ])}
        </Tabs>
      );
    }
    return 'Loading...';
  }

  render() {
    return (
      <Modal
        title="Documentation"
        modalStyle="large"
        onClose={this.props.close}
        onConfirm={this.props.close}
        className="ValidationDocumentationModal"
        hideCloseButton
      >
        {this.renderBody()}
      </Modal>
    );
  }
}
