import classNames from 'classnames';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './search.scss';

import { Tooltip } from '@alkem/react-ui-tooltip';

export default class Search extends Component {
  static demoProps = [
    {
      query: '',
      placeholder: 'Search ...',
      tooltip: '',
      updateSearchQuery: () => alert('change'), // eslint-disable-line no-alert
    },
  ];

  static propTypes = {
    query: PropTypes.string,
    placeholder: PropTypes.string,
    tooltip: PropTypes.string,
    updateSearchQuery: PropTypes.func.isRequired,
    small: PropTypes.bool,
    wide: PropTypes.bool,
    children: PropTypes.element,
  };

  static defaultProps = {
    small: false,
    wide: false,
  };

  constructor(props) {
    super(props);
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.updateSearchQuery = debounce(this.updateSearchQuery.bind(this), 500);
    this.state = { search: props.query || '' };
  }

  componentDidUpdate(prevProps) {
    const { query } = this.props;
    if (query !== prevProps.query) {
      this.setState({ search: query }); // eslint-disable-line
    }
  }

  onChangeSearch(event) {
    this.setState({ search: event.target.value });
    this.updateSearchQuery();
  }

  clearSearch() {
    this.setState({ search: '' });
    this.updateSearchQuery();
  }

  updateSearchQuery() {
    this.props.updateSearchQuery(this.state.search);
  }

  render() {
    const { small, wide, children } = this.props;
    const searchClass = {
      Search: true,
      'Search--wide': wide,
    };
    const inputClass = {
      Search__input: true,
      'Search__input--small': small,
    };
    const searchIcon = {
      mdi: true,
      'mdi-magnify': true,
      Search__icon: true,
      'Search__icon--small': small,
    };
    const clearSearchIcon = {
      mdi: true,
      'mdi-close': true,
      ClearSearch__icon: true,
      'ClearSearch__icon--small': small,
    };
    const tooltipSearchIcon = {
      mdi: true,
      'mdi-help-circle': true,
      TooltipSearch__icon: true,
      'TooltipSearch__icon--small': small,
    };

    return (
      <div className="alk-flex alk-flex-row alk-flex-center alk-flex-space-between">
        <div className={classNames(searchClass)}>
          <span className={classNames(searchIcon)} />
          <input
            type="text"
            className={classNames(inputClass)}
            placeholder={this.props.placeholder}
            value={this.state.search}
            onChange={this.onChangeSearch}
          />
          {this.state.search && (
            <span
              className={classNames(clearSearchIcon)}
              title="Clear search"
              onClick={this.clearSearch}
            />
          )}
          {this.props.tooltip && !this.state.search && (
            <span
              data-tip
              data-for="search-tooltip"
              className={classNames(tooltipSearchIcon)}
            />
          )}
        </div>
        {this.props.tooltip && (
          <Tooltip id="search-tooltip" place="right">
            {this.props.tooltip}
          </Tooltip>
        )}
        {children}
      </div>
    );
  }
}
