import React, { useCallback, useEffect, useState } from 'react';
import { AddButton, Button } from '@alkem/react-ui-button';
import { Modal } from '@alkem/react-ui-modal';
import { useDispatch, useSelector } from 'react-redux';
import { OrganizationAutocomplete } from 'components/autocomplete';
import { OrganizationGroupField, Organization } from '../type';
import {
  createField,
  deleteFieldGroup,
  exportFieldGroup,
  loadFieldGroupsForImport,
  organizationFieldGroupChange,
  saveFieldGroup,
  setDefaultFieldGroup,
} from '../actions';
import { selectOrganization } from '../selectors';
import { Field } from './field';
import Collapsible from 'components/collapsible';

const ExportFieldGroup = React.memo(
  ({ group }: { group: OrganizationGroupField }) => {
    const dispatch = useDispatch();
    const currentOrganization = useSelector(selectOrganization);
    const [isModalOpen, setModalOpen] = useState(false);
    const [organization, setOrganization] = useState<any>(null);

    useEffect(() => {
      if (!isModalOpen) {
        setOrganization(null);
      }
    }, [isModalOpen]);

    const onSelectOrganization = useCallback(
      (_organization) => {
        setOrganization(_organization);
        dispatch(loadFieldGroupsForImport(_organization.key));
      },
      [dispatch]
    );

    const onExportFieldGroup = useCallback(() => {
      if (organization) {
        dispatch(exportFieldGroup({ group, organization: organization.value }));
        setModalOpen(false);
      }
    }, [dispatch, group, organization]);

    return (
      <div>
        <Button primary onClick={() => setModalOpen(true)}>
          {'Export...'}
        </Button>
        {isModalOpen && (
          <Modal
            title={`Export Group to another Organization`}
            confirmButtonText={`Export`}
            confirmDisabled={!organization}
            shouldCloseOnOverlayClick
            onClose={() => setModalOpen(false)}
            onConfirm={onExportFieldGroup}
            className="ExportFieldGroupModal"
            modalStyle="dynamic"
          >
            <div>
              <strong className="alk-padding-bottom">Organization</strong>
              <OrganizationAutocomplete
                id="export-field-group-organization-autocomplete"
                onSelect={onSelectOrganization}
                onUnselect={() => setOrganization(null)}
                value={organization ? [organization] : null}
                placeholder="Select an organization to export group to..."
                searchOnClick
                withs={{ users: false }}
                excludeList={[
                  { key: currentOrganization?.id, value: currentOrganization },
                ]}
              />
            </div>
          </Modal>
        )}
      </div>
    );
  }
);

export const FieldGroup = React.memo(
  ({
    group,
    index,
    organization,
    default_group,
  }: {
    group: OrganizationGroupField;
    index: number;
    organization: Organization;
    default_group: boolean;
  }) => {
    const dispatch = useDispatch();
    const [isCollapsed, setCollapsed] = useState(false);
    const defaultLabel = default_group && (
      <div
        className="OrganizationGroupField__Default"
        data-testid="default-group-label"
      >
        Default
      </div>
    );

    const onChangeName = useCallback(
      (value: any) => {
        dispatch(organizationFieldGroupChange(index, 'name', value));
      },
      [dispatch, index]
    );

    const onSave = useCallback(() => {
      dispatch(saveFieldGroup(group));
    }, [dispatch, group]);

    const onDelete = useCallback(() => {
      if (confirm('Are you sure ?')) {
        dispatch(deleteFieldGroup(index));
      }
    }, [dispatch, index]);

    const onAddField = useCallback(() => {
      dispatch(createField(index));
    }, [dispatch, index]);

    const onSetDefaultGroup = useCallback(() => {
      dispatch(setDefaultFieldGroup(organization.id, group.id));
    }, [dispatch, organization, group]);

    return (
      <Collapsible
        className="OrganizationGroupField__FieldGroup"
        title={
          <div
            className="OrganizationGroupField__FieldGroup--title"
            onClick={(e) => e.stopPropagation()}
          >
            {defaultLabel}
            <div className="OrganizationGroupField__FieldGroup--name">
              <input
                type="text"
                value={group.name}
                className="OrganizationGroupField__Fields--name form-control"
                onChange={(e) => onChangeName(e.target.value)}
              />
            </div>
            <div>
              <Button primary onClick={onSave}>
                {'Save'}
              </Button>
            </div>
            <ExportFieldGroup group={group} />
            <div>
              <Button warning onClick={onDelete}>
                <i className="mdi mdi-trash-can" />
                {'Delete'}
              </Button>
            </div>
            <div>
              <Button
                secondary
                key={'some'}
                onClick={onSetDefaultGroup} // eslint-disable-line react/jsx-no-bind
              >
                Make Default
              </Button>
            </div>
          </div>
        }
        collapsed={isCollapsed}
        toggle={() => setCollapsed(!isCollapsed)}
      >
        <div className="alk-margin-top--large">
          <div className="alk-flex alk-flex-column">
            <div className="OrganizationGroupField__Field alk-font-weight--bold alk-flex alk-flex-row">
              <div className="OrganizationGroupField__FieldRoot">
                Field root
              </div>
              <div className="OrganizationGroupField__FieldName">
                Field name
              </div>
              <div className="OrganizationGroupField__FieldOnCreation">
                Review on creation
              </div>
              <div className="OrganizationGroupField__FieldOnUpdate">
                Review on update
              </div>
              <div className="OrganizationGroupField__FieldDelete"> </div>
            </div>
            {group.fields.map((field, fieldIndex) => (
              <Field
                key={fieldIndex}
                groupFieldIndex={index}
                fieldIndex={fieldIndex}
                field={field}
              />
            ))}
          </div>
          <div className="OrganizationGroupField__AddField alk-flex">
            <AddButton onClick={onAddField}>Add field</AddButton>
          </div>
        </div>
      </Collapsible>
    );
  }
);
