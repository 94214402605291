import { PermissionConfiguration } from '@alkem/sdk-dashboard';
import { notificationError, notificationSuccess } from 'actions/notifications';
import { authApi } from 'resources/authApi';
import { createAction } from 'utils/reducers';
import {
  FETCH_PERMISSIONS,
  RECEIVE_PERMISSIONS,
  FETCH_ORGANIZATION_CONFIGURATION,
  RECEIVE_ORGANIZATION_CONFIGURATION,
  UPSERT_ORGANIZATION_CONFIGURATION,
  ERROR_PERMISSIONS,
} from '../constants/events';
import {
  selectHasDeletedModuleFlags,
  selectOrganizationConfiguration,
} from '../selectors/permissions';
import { selectOrganization } from '../selectors/infos';

export const receivePermissions =
  createAction<PermissionConfiguration>(RECEIVE_PERMISSIONS);
export const upsertConfiguration = createAction<PermissionConfiguration>(
  UPSERT_ORGANIZATION_CONFIGURATION
);
export const receiveConfiguration =
  createAction<PermissionConfiguration | null>(
    RECEIVE_ORGANIZATION_CONFIGURATION
  );
export const operationError = createAction<Error>(ERROR_PERMISSIONS);

export const loadAllOrganizationPermissions = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_PERMISSIONS });
    const {
      data: { data },
    } = await authApi.OrganizationConfiguration();
    dispatch(receivePermissions(data));
  } catch (exception: any) {
    dispatch(operationError(exception));
    dispatch(
      notificationError('Could not retrieve all permissions configuration')
    );
  }
};

export const loadOrganizationPermissions =
  ({ organizationId }) =>
  async (dispatch) => {
    try {
      dispatch({ type: FETCH_ORGANIZATION_CONFIGURATION });
      const {
        data: { data },
      } = await authApi.OrganizationConfiguration(organizationId);
      dispatch(receiveConfiguration(data));
    } catch (exception: any) {
      dispatch(operationError(exception));
      dispatch(
        notificationError('Could not retrieve permissions configuration')
      );
    }
  };

export const upsertOrganizationPermissions =
  () => async (dispatch, getState) => {
    const state = getState();
    const organizationId = selectOrganization(state).get('id');
    const configuration = selectOrganizationConfiguration(state);
    const hasDeletedModuleFlags = selectHasDeletedModuleFlags(state);
    if (!configuration || hasDeletedModuleFlags) {
      return;
    }
    try {
      const {
        data: { data },
      } = await authApi.OrganizationConfigurationUpdate(
        organizationId,
        configuration
      );
      dispatch(receiveConfiguration(data));
      dispatch(notificationSuccess('Configuration has been updated'));
    } catch (exception: any) {
      dispatch(operationError(exception));
      dispatch(notificationError('Could not update permissions configuration'));
    }
  };
