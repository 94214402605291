import { useState } from 'react';

import './field-selector-item.scss';

interface Props {
  children?: React.ReactNode;
  id: number;
  label?: string;
  name: string;
  select: () => void;
}
export function FieldSelectorItem({
  children,
  id,
  label,
  name,
  select,
}: Props): JSX.Element {
  const [isExpanded, setIsExpanded] = useState(false);

  const onExpand = (): void => {
    setIsExpanded(!isExpanded);
  };

  const chevronClass = `mdi mdi-chevron-${isExpanded ? 'down' : 'right'}`;

  return (
    <div
      className="GDSNImportMappingsFieldSelectorItem"
      id={`field-tree-item-${id}`}
      data-testid={id}
    >
      <div className="GDSNImportMappingsFieldSelectorItem__line">
        {children && (
          <i
            className={chevronClass}
            onClick={onExpand}
            data-testid="chevron"
          />
        )}
        {!children && (
          <i className="mdi mdi-chevron-right GDSNImportMappingsFieldSelectorItem__chevron--hidden" />
        )}
        <div
          className="GDSNImportMappingsFieldSelectorItem__label"
          data-testid={`field-selector-item-label-${id}`}
          onClick={select}
        >
          {label}
          &nbsp;
          <code>{name}</code>
        </div>
      </div>
      {isExpanded && children}
    </div>
  );
}
