import React from 'react';

import { ModuleWrapper } from 'redux/register';

import reducer, { TRANSACTION_DASHBOARD_STORE_KEY } from './reducer';
import TransactionView from './view';

export default function TransactionContainer() {
  return (
    <ModuleWrapper
      reducerKey={TRANSACTION_DASHBOARD_STORE_KEY}
      reducer={reducer}
    >
      <TransactionView />
    </ModuleWrapper>
  );
}
