import { HeaderLayout } from '@alkem/react-layout';
import { Button } from '@alkem/react-ui-button';
import Search from 'components/ui/input/search';
import * as routes from 'constants/routes';
import { useEffect, useState } from 'react';
import { edaApi } from 'resources/edaApi';
import { logError } from 'utils';
import './rmqreplayer.scss';

async function fetchRoutingKeyBindings(setRoutingKeyBindings, search) {
  try {
    const res = await edaApi.RmqListBindings(search);
    setRoutingKeyBindings(res.data.data);
  } catch (err) {
    logError(err);
  }
}

function EventBindingsOverview(params) {
  const queues = params.queues;
  const routingKey = params.name;
  return (
    <div key={routingKey} className="card EventBindingsOverview">
      <div className="EventBindingsOverviewName">
        <b>Event:</b> <u>{routingKey}</u>
      </div>
      <div className="EventBindingsOverviewQueues">
        {queues.map((queue) => (
          <div key={queue} className="EventBindingsOverviewQueue arrow-right">
            <p>{queue}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default function EdaRmqExplorer() {
  const [routingKeyBindings, setRoutingKeyBindings] = useState({});
  const [routingKeySearch, setRoutingKeySearch] = useState(null);
  useEffect(() => {
    fetchRoutingKeyBindings(setRoutingKeyBindings);
  }, []);

  const onUpdateSearch = (e) => {
    setRoutingKeySearch(e);
    fetchRoutingKeyBindings(setRoutingKeyBindings, e);
  };

  return (
    <div>
      <HeaderLayout
        title="EDA | Event listeners"
        backHref={routes.home}
        backMessage="Back home"
        isTitleSmall
      >
        <Search
          query={routingKeySearch}
          placeholder="event name"
          updateSearchQuery={onUpdateSearch}
        />
      </HeaderLayout>
      <div className="container-fluid row">
        <div className="col-xs-12">
          <div>
            <Button
              onClick={() => fetchRoutingKeyBindings(setRoutingKeyBindings)}
            >
              <i className="mdi mdi-refresh" />
            </Button>
          </div>
          {Object.entries(routingKeyBindings).map((binding) => (
            <EventBindingsOverview
              key={binding[0]}
              name={binding[0]}
              queues={binding[1]}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
