import history from 'utils/history';
import { ShowOrganization } from '../components/results/show-organization';
import { GetLogAs } from '../components/results/show-logas';

export const root = {
  title: 'Go to the organisation dashboard',
  command: 'org',
  action: () => {
    history.push('/organization');
  },
};

export const what = {
  title: 'Documentation on Organizations in the context of SXM',
  command: 'org what',
  render: () => (
    <div>
      <p>
        A organization is what it is, just an organization. I mean there are
        retailers, manufacturers, third parties and all that jazz.
      </p>
      <p>
        To read more about how we manage organizations in SXM check out the
        documentation at this link: click here
      </p>
    </div>
  ),
};

export const show = {
  title: 'Preview information about a specific organization',
  help: 'usage: "org show id:123"',
  param: 'id',
  command: 'org show',
  render: ({ id }) => {
    if (id) {
      return <ShowOrganization id={id} />;
    } else {
      return <div>You did not provide an ID</div>;
    }
  },
};

export const goto = {
  title: "Go to a specific organization's configuration page",
  help: 'usage: "org goto id:123"',
  param: 'id',
  command: 'org goto',
  action: ({ id }) => {
    if (id) history.push(`/organization/${id}`);
  },
  actionNewTab: ({ id }) => {
    if (id) window.open(`/organization/${id}`, '_blank').focus();
  },
};

export const logas = {
  title: 'logas an admin user for a specific organization',
  help: 'usage: "org logas id:123"',
  param: 'id',
  command: 'org logas',
  render: ({ id }) => {
    if (id) {
      return <GetLogAs id={id} />;
    } else {
      return <div>You did not provide an orgnization ID</div>;
    }
  },
};

export const commands = [root, what, show, goto, logas];
