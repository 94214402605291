import InputWithLabel from 'components/input-with-label';
import { SimpleSelect } from '@alkem/react-ui-select';
import { Text } from '@alkem/react-ui-inputs';

import './simple-selector.scss';
import React from 'react';

interface Props {
  label: string;
  testId: string;
  options?: any;
  onSelect: (value: any) => void;
  option?: any;
  onReset: () => void;
  placeholder?: string;
  disabled?: boolean;
  searchProps?: {
    onSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
    value?: string;
    placeholder: string;
  };
  displayedValueProps?: {
    label: string;
    value?: string;
  };
}
export function ExportMappingsSimpleSelector({
  label,
  testId,
  options,
  onSelect,
  option,
  onReset,
  placeholder,
  disabled,
  searchProps,
  displayedValueProps: valueProps,
}: Props) {
  return (
    <div className="GDSNExportMappingsSimpleSelector" data-testid={testId}>
      <InputWithLabel
        inputId={`gdsn-export-mappings-simple-selector`}
        label={label}
      >
        {searchProps && (
          <Text
            className="GDSNExportMappingsSimpleSelector__search"
            id="gdsn-export-mappings-simple-selector-search"
            placeholder={searchProps.placeholder}
            onChange={searchProps.onSearch}
            value={searchProps.value || ''}
          />
        )}
        <div className="GDSNExportMappingsSimpleSelector__simpleSelect">
          <SimpleSelect
            id={`gdsn-export-mappings-simple-selector`}
            options={options || []}
            onSelect={onSelect}
            value={option}
            placeholder={placeholder || 'Select...'}
            disabled={disabled}
          />
          <span
            className="GDSNExportMappingsSimpleSelector__reset-button"
            onClick={onReset}
          >
            <i className="mdi mdi-close" />
          </span>
        </div>
      </InputWithLabel>
      {valueProps && (
        <InputWithLabel
          inputId="gdsn-export-mappings-simple-selector-value"
          label={valueProps.label}
        >
          <Text
            className="GDSNExportMappingsSimpleSelector__value"
            id="gdsn-export-mappings-simple-selector-value"
            value={valueProps.value || ''}
            onChange={() => {}}
            disabled
          />
        </InputWithLabel>
      )}
    </div>
  );
}
