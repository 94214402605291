import { ReferentialApi } from '@alkem/sdk-dashboard';

export const immutableReferentialApi = new ReferentialApi({
  immutable: true,
  cacheEnabled: true,
  acceptLanguage: 'noop',
});

export default new ReferentialApi({
  cacheEnabled: true,
  acceptLanguage: 'noop',
});
