// List
export const RECEIVE_GDSN_CIS_LIST = 'RECEIVE_GDSN_CIS_LIST';
export const GET_GDSN_CIS_LIST = 'GET_GDSN_CIS_LIST';
export const GET_GDSN_CIS_LIST_FILTERS = 'GET_GDSN_CIS_LIST_FILTERS';
export const CHANGE_LIMIT_GDSN_CIS_LIST = 'CHANGE_LIMIT_GDSN_CIS_LIST';
export const NEXT_PAGE_GDSN_CIS_LIST = 'NEXT_PAGE_GDSN_CIS_LIST';
export const PREVIOUS_PAGE_GDSN_CIS_LIST = 'PREVIOUS_PAGE_GDSN_CIS_LIST';

// Filters
export const RECEIVE_GDSN_CIS_LIST_FILTERS = 'RECEIVE_GDSN_CIS_LIST_FILTERS';
export const ADD_GDSN_CIS_LIST_FILTER = 'ADD_GDSN_CIS_LIST_FILTER';
export const REMOVE_GDSN_CIS_LIST_FILTER = 'REMOVE_GDSN_CIS_LIST_FILTER';
export const UPDATE_GDSN_CIS_LIST_FILTER = 'UPDATE_GDSN_CIS_LIST_FILTER';
export const CLEAR_GDSN_CIS_LIST_FILTER = 'CLEAR_GDSN_CIS_LIST_FILTER';

export const DOWNLOAD_GDSN_CIS_DOCUMENT = 'DOWNLOAD_GDSN_CIS_DOCUMENT';

// Modal
export const OPEN_GDSN_CIS_MODAL = 'OPEN_GDSN_CIS_MODAL';
export const CLOSE_GDSN_CIS_MODAL = 'CLOSE_GDSN_CIS_MODAL';
export const GDSN_CIS_MODAL_SAVE = 'GDSN_CIS_MODAL_SAVE';
export const GDSN_CIS_ATTR_UPDATE = 'GDSN_CIS_ATTR_UPDATE';
