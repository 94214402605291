import {
  TYPE_ALK_SUPER_ADMIN,
  TYPE_PLATFORM_ADMIN,
  TYPE_SUPPORT,
} from 'constants/user';
import {
  PERMISSION_DELEGATE,
  PERMISSION_ENTITY_ORGANIZATIONS,
  PERMISSION_ENTITY_ORGANIZATION_SEGMENTS,
  PERMISSION_ENTITY_PLATFORMS,
  PERMISSION_MANAGE_GLN,
  PERMISSION_ORGANIZATION_SHOW,
  PERMISSION_PLATFORM_APPLICATION_SHOW,
  PERMISSION_PLATFORM_APPLICATION_UPDATE,
  PERMISSION_PLATFORM_FIELD_SHOW,
  PERMISSION_PLATFORM_FIELD_UPDATE,
  PERMISSION_PLATFORM_GDSN_RCI_SHOW,
  PERMISSION_PLATFORM_KIND_SHOW,
  PERMISSION_PLATFORM_KIND_UPDATE,
  PERMISSION_PLATFORM_ORGANIZATIONGROUP_SHOW,
  PERMISSION_PLATFORM_ORGANIZATIONGROUP_UPDATE,
  PERMISSION_PLATFORM_REFERENTIAL_SHOW,
  PERMISSION_PLATFORM_REFERENTIAL_UPDATE,
  PERMISSION_PLATFORM_SETTING_SHOW,
  PERMISSION_PLATFORM_SETTING_UPDATE,
  PERMISSION_PLATFORM_TRANSACTION_SHOW,
  PERMISSION_PLATFORM_TRANSACTION_UPDATE,
  PERMISSION_PLATFORM_VALIDATION_SHOW,
  PERMISSION_PLATFORM_VALIDATION_UPDATE,
  PERMISSION_PLATFORM_VIEWAS_SHOW,
  PERMISSION_PLATFORM_VIEWAS_UPDATE,
  PERMISSION_PLATFORM_EXCHANGE_SFTP_MANAGE,
  PERMISSION_USER_SHOW,
  PERMISSION_PLATFORM_RABBITMQ_SHOW,
  PERMISSION_PLATFORM_RABBITMQ_UPDATE,
  PERMISSION_ORGANIZATION_FIELD_GROUP_SHOW,
  PERMISSION_ORGANIZATION_FIELD_GROUP_UPDATE,
  PERMISSION_PLATFORM_TRANSLATION_SHOW,
  PERMISSION_PLATFORM_INDEXING_SHOW,
  PERMISSION_PLATFORM_INDEXING_REINDEX,
} from 'modules/access-policy/common/constants';
import { AccessPolicy } from 'modules/access-policy/common/utils';

export const accessPolicyDashboardAccessPolicy: AccessPolicy = {
  userTypes: [TYPE_PLATFORM_ADMIN, TYPE_SUPPORT],
  permissions: {
    read: [[PERMISSION_ENTITY_ORGANIZATION_SEGMENTS, [PERMISSION_DELEGATE]]],
    write: [],
  },
};

export const organizationDashboardAccessPolicy: AccessPolicy = {
  userTypes: [TYPE_PLATFORM_ADMIN, TYPE_SUPPORT],
  permissions: {
    read: [[PERMISSION_ENTITY_ORGANIZATIONS, [PERMISSION_ORGANIZATION_SHOW]]],
    write: [],
  },
};

export const userDashboardAccessPolicy: AccessPolicy = {
  userTypes: [TYPE_PLATFORM_ADMIN, TYPE_SUPPORT],
  permissions: {
    read: [[PERMISSION_ENTITY_ORGANIZATIONS, [PERMISSION_USER_SHOW]]],
    write: [],
  },
};

export const importExportDashboardAccessPolicy: AccessPolicy = {
  userTypes: [TYPE_PLATFORM_ADMIN, TYPE_SUPPORT],
  permissions: {
    read: [[PERMISSION_ENTITY_ORGANIZATIONS, [PERMISSION_ORGANIZATION_SHOW]]],
    write: [],
  },
};

export const gdsnDasshboardAccessPolicy: AccessPolicy = {
  userTypes: [TYPE_PLATFORM_ADMIN, TYPE_SUPPORT],
  permissions: {
    read: [[PERMISSION_ENTITY_ORGANIZATIONS, [PERMISSION_ORGANIZATION_SHOW]]],
    write: [],
  },
};

export const glnDashboardAccessPolicy: AccessPolicy = {
  userTypes: [TYPE_PLATFORM_ADMIN, TYPE_SUPPORT],
  permissions: {
    read: [[PERMISSION_ENTITY_ORGANIZATIONS, [PERMISSION_MANAGE_GLN]]],
    write: [],
  },
};

export const displayGroupDashboardAccessPolicy: AccessPolicy = {
  userTypes: [TYPE_PLATFORM_ADMIN],
  permissions: {
    read: [[PERMISSION_ENTITY_PLATFORMS, [PERMISSION_PLATFORM_FIELD_SHOW]]],
    write: [[PERMISSION_ENTITY_PLATFORMS, [PERMISSION_PLATFORM_FIELD_UPDATE]]],
  },
};

export const fieldDashboardAccessPolicy: AccessPolicy = {
  userTypes: [TYPE_PLATFORM_ADMIN],
  permissions: {
    read: [[PERMISSION_ENTITY_PLATFORMS, [PERMISSION_PLATFORM_FIELD_SHOW]]],
    write: [[PERMISSION_ENTITY_PLATFORMS, [PERMISSION_PLATFORM_FIELD_UPDATE]]],
  },
};

export const settingsDashboardAccessPolicy: AccessPolicy = {
  userTypes: [TYPE_PLATFORM_ADMIN],
  permissions: {
    read: [[PERMISSION_ENTITY_PLATFORMS, [PERMISSION_PLATFORM_SETTING_SHOW]]],
    write: [
      [PERMISSION_ENTITY_PLATFORMS, [PERMISSION_PLATFORM_SETTING_UPDATE]],
    ],
  },
};

export const referentialDashboardAccessPolicy: AccessPolicy = {
  userTypes: [TYPE_PLATFORM_ADMIN],
  permissions: {
    read: [
      [PERMISSION_ENTITY_PLATFORMS, [PERMISSION_PLATFORM_REFERENTIAL_SHOW]],
    ],
    write: [
      [PERMISSION_ENTITY_PLATFORMS, [PERMISSION_PLATFORM_REFERENTIAL_UPDATE]],
    ],
  },
};

export const kindDashboardAccessPolicy: AccessPolicy = {
  userTypes: [TYPE_PLATFORM_ADMIN],
  permissions: {
    read: [
      [
        PERMISSION_ENTITY_PLATFORMS,
        [PERMISSION_PLATFORM_KIND_SHOW, PERMISSION_PLATFORM_FIELD_SHOW],
      ],
    ],
    write: [[PERMISSION_ENTITY_PLATFORMS, [PERMISSION_PLATFORM_KIND_UPDATE]]],
  },
};

export const validationDashboardAccessPolicy: AccessPolicy = {
  userTypes: [TYPE_PLATFORM_ADMIN],
  permissions: {
    read: [
      [
        PERMISSION_ENTITY_PLATFORMS,
        [PERMISSION_PLATFORM_VALIDATION_SHOW, PERMISSION_PLATFORM_FIELD_SHOW],
      ],
    ],
    write: [
      [PERMISSION_ENTITY_PLATFORMS, [PERMISSION_PLATFORM_VALIDATION_UPDATE]],
    ],
  },
};

export const indexerDashboardAccessPolicy: AccessPolicy = {
  userTypes: [TYPE_PLATFORM_ADMIN],
  permissions: {
    read: [[PERMISSION_ENTITY_PLATFORMS, [PERMISSION_PLATFORM_INDEXING_SHOW]]],
    write: [
      [PERMISSION_ENTITY_PLATFORMS, [PERMISSION_PLATFORM_INDEXING_REINDEX]],
    ],
  },
};

export const organizationGroupDashboardAccessPolicy: AccessPolicy = {
  userTypes: [TYPE_PLATFORM_ADMIN],
  permissions: {
    read: [
      [
        PERMISSION_ENTITY_PLATFORMS,
        [PERMISSION_PLATFORM_ORGANIZATIONGROUP_SHOW],
      ],
    ],
    write: [
      [
        PERMISSION_ENTITY_PLATFORMS,
        [PERMISSION_PLATFORM_ORGANIZATIONGROUP_UPDATE],
      ],
    ],
  },
};

export const organizationGroupFieldDashboardAccessPolicy: AccessPolicy = {
  userTypes: [TYPE_PLATFORM_ADMIN, TYPE_SUPPORT],
  permissions: {
    read: [
      [
        PERMISSION_ENTITY_ORGANIZATIONS,
        [PERMISSION_ORGANIZATION_FIELD_GROUP_SHOW],
      ],
    ],
    write: [
      [
        PERMISSION_ENTITY_ORGANIZATIONS,
        [PERMISSION_ORGANIZATION_FIELD_GROUP_UPDATE],
      ],
    ],
  },
};

export const retailerFieldsDashboardAccessPolicy: AccessPolicy = {
  userTypes: [TYPE_PLATFORM_ADMIN],
  permissions: {
    read: [[PERMISSION_ENTITY_PLATFORMS, [PERMISSION_PLATFORM_VIEWAS_SHOW]]],
    write: [[PERMISSION_ENTITY_PLATFORMS, [PERMISSION_PLATFORM_VIEWAS_UPDATE]]],
  },
};

export const applicationDashboardAccessPolicy: AccessPolicy = {
  userTypes: [TYPE_PLATFORM_ADMIN],
  permissions: {
    read: [
      [PERMISSION_ENTITY_PLATFORMS, [PERMISSION_PLATFORM_APPLICATION_SHOW]],
    ],
    write: [
      [PERMISSION_ENTITY_PLATFORMS, [PERMISSION_PLATFORM_APPLICATION_UPDATE]],
    ],
  },
};

export const rabbitmqDashboardAccessPolicy: AccessPolicy = {
  userTypes: [TYPE_PLATFORM_ADMIN],
  permissions: {
    read: [[PERMISSION_ENTITY_PLATFORMS, [PERMISSION_PLATFORM_RABBITMQ_SHOW]]],
    write: [
      [PERMISSION_ENTITY_PLATFORMS, [PERMISSION_PLATFORM_RABBITMQ_UPDATE]],
    ],
  },
};

export const transactionDashboardAccessPolicy: AccessPolicy = {
  userTypes: [TYPE_PLATFORM_ADMIN],
  permissions: {
    read: [
      [PERMISSION_ENTITY_PLATFORMS, [PERMISSION_PLATFORM_TRANSACTION_SHOW]],
    ],
    write: [
      [PERMISSION_ENTITY_PLATFORMS, [PERMISSION_PLATFORM_TRANSACTION_UPDATE]],
    ],
  },
};

export const gdsnRciDashboardAccessPolicy: AccessPolicy = {
  userTypes: [TYPE_PLATFORM_ADMIN],
  permissions: {
    read: [[PERMISSION_ENTITY_PLATFORMS, [PERMISSION_PLATFORM_GDSN_RCI_SHOW]]],
    write: [],
  },
};

export const exchangeDashboardAccessPolicy: AccessPolicy = {
  userTypes: [TYPE_PLATFORM_ADMIN],
  permissions: {
    read: [
      [PERMISSION_ENTITY_PLATFORMS, [PERMISSION_PLATFORM_EXCHANGE_SFTP_MANAGE]],
    ],
    write: [],
  },
};

export const productMaintenanceDashboardAccessPolicy: AccessPolicy = {
  userTypes: [TYPE_ALK_SUPER_ADMIN],
  permissions: {
    read: [],
    write: [],
  },
};

export const productPlaygroundAccessPolicy: AccessPolicy = {
  userTypes: [TYPE_ALK_SUPER_ADMIN],
  permissions: {
    read: [],
    write: [],
  },
};

export const gdsnImportMappingsDashboardAccessPolicy: AccessPolicy = {
  userTypes: [TYPE_ALK_SUPER_ADMIN],
  permissions: {
    read: [],
    write: [],
  },
};

export const gdsnExportMappingsDashboardAccessPolicy: AccessPolicy = {
  userTypes: [TYPE_ALK_SUPER_ADMIN],
  permissions: {
    read: [],
    write: [],
  },
};

export const translationDashboardAccessPolicy: AccessPolicy = {
  userTypes: [TYPE_PLATFORM_ADMIN],
  permissions: {
    read: [
      [PERMISSION_ENTITY_PLATFORMS, [PERMISSION_PLATFORM_TRANSLATION_SHOW]],
    ],
    write: [],
  },
};

export const edaEventCapturesAccessPolicy: AccessPolicy = {
  userTypes: [TYPE_ALK_SUPER_ADMIN],
  permissions: { read: [], write: [] },
};
