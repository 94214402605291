import * as events from '../constants/events';

export const loadFilters = () => ({
  type: events.LOAD_FILTERS,
});

export const receiveFilters = (filters) => ({
  type: events.RECEIVE_FILTERS,
  filters,
});

export const updateFilter = (key, value) => ({
  type: events.UPDATE_SELECTED_FILTERS,
  key,
  value,
});

export const nextPage = () => ({
  type: events.NEXT_PAGE_IMPORT_LIST,
});

export const previousPage = () => ({
  type: events.PREVIOUS_PAGE_IMPORT_LIST,
});

export const changeLimit = (limit) => ({
  type: events.CHANGE_LIMIT_IMPORT_LIST,
  limit,
});

export const getImportList = () => ({
  type: events.LOAD_IMPORT_LIST,
});

export const listIsLoading = () => ({
  type: events.LOADING_IMPORT_LIST,
});

export const receiveImportList = (list, totalResults) => ({
  type: events.RECEIVE_IMPORT_LIST,
  payload: {
    list,
    totalResults,
  },
});

export const loadImportDetail = (importFileId, offset, limit) => ({
  type: events.LOAD_IMPORT_DETAIL,
  importFileId,
  offset,
  limit,
});

export const receiveImportDetail = (importFileId, entities) => ({
  type: events.RECEIVE_IMPORT_DETAIL,
  importFileId,
  entities,
});

export const downloadFile = (importFileId) => ({
  type: events.DOWNLOAD_FILE,
  importFileId,
});
