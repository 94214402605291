import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { HeaderLayout } from '@alkem/react-layout';

import * as routes from 'constants/routes';
import Search from 'components/ui/input/search';

import UserList from '../components/list';
import UserListFilters from '../components/filters';
import {
  selectSearch,
  selectSelectedFilters,
  selectAdminFilters,
  selectOrganizationFilters,
} from '../selectors';
import { searchUsers, getUsers } from '../actions';

const mapStateToProps = createStructuredSelector({
  search: selectSearch,
  selectedFilters: selectSelectedFilters,
  adminFilters: selectAdminFilters,
  organizationsFilters: selectOrganizationFilters,
});

const mapDispatchToProps = {
  searchUsers,
  getUsers,
};

class UserDashboardView extends PureComponent {
  static propTypes = {
    search: PropTypes.string,
    selectedFilters: ImmutablePropTypes.list.isRequired,
    adminFilters: ImmutablePropTypes.map.isRequired,
    organizationsFilters: ImmutablePropTypes.map.isRequired,
    searchUsers: PropTypes.func.isRequired,
    getUsers: PropTypes.func.isRequired,
  };

  constructor(tomate) {
    super(tomate);
    this.onUpdateSearchQuery = this.onUpdateSearchQuery.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.selectedFilters !== prevProps.selectedFilters ||
      this.props.adminFilters !== prevProps.adminFilters ||
      this.props.organizationsFilters !== prevProps.organizationsFilters ||
      this.props.search !== prevProps.search
    ) {
      this.props.getUsers();
    }
  }

  onUpdateSearchQuery(query) {
    this.props.searchUsers(query);
  }

  render() {
    return (
      <div>
        <HeaderLayout
          title="User Dashboard"
          backHref={routes.home}
          backMessage="Back home"
          isTitleSmall
        >
          <Search
            query={this.props.search}
            placeholder="Emails or ids, separated by commas"
            updateSearchQuery={this.onUpdateSearchQuery}
          />
        </HeaderLayout>
        <div className="container-fluid row">
          <div className="col-md-3">
            <UserListFilters />
          </div>
          <div className="col-md-9">
            <UserList />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDashboardView);
