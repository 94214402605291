import {
  CONSUMER_UNIT,
  SHARING_UNIT,
  TARIFF,
  LOGISTICAL_UNIT,
  DISPLAY_UNIT,
  PRODUCT_PICTURE,
  PRODUCT_VIDEO,
  PRODUCT_DOCUMENT,
  RFP_ANSWER,
} from 'constants/fields';

import { ruleEntityTypes } from 'modules/validation-dashboard/constants';

export const restrictionTypes = {
  warning: 0,
  blocking: 1,
};

export const fieldTypeToRuleType = {
  [CONSUMER_UNIT]: ruleEntityTypes.CONSUMER_UNIT.id,
  [SHARING_UNIT]: ruleEntityTypes.SHARING_UNIT.id,
  [TARIFF]: ruleEntityTypes.TARIFF.id,
  [LOGISTICAL_UNIT]: ruleEntityTypes.LOGISTICAL_UNIT.id,
  [DISPLAY_UNIT]: ruleEntityTypes.DISPLAY_UNIT.id,
  [PRODUCT_PICTURE]: ruleEntityTypes.PICTURE.id,
  [PRODUCT_VIDEO]: ruleEntityTypes.VIDEO.id,
  [PRODUCT_DOCUMENT]: ruleEntityTypes.DOCUMENT.id,
  [RFP_ANSWER]: ruleEntityTypes.RFP_ANSWER.id,
};
