export const REFRESH_CACHE = 'fields-page/REFRESH_CACHE';

export const GET_ROOTS_LIST = 'fields-page/GET_ROOTS_LIST';
export const RECEIVE_ROOTS_LIST = 'fields-page/RECEIVE_ROOTS_LIST';
export const CREATE_ROOT = 'fields-page/CREATE_ROOT';

export const GET_FIELDS_LIST = 'fields-page/GET_FIELDS_LIST';
export const RECEIVE_FIELDS_LIST = 'fields-page/RECEIVE_FIELDS_LIST';

export const GET_TAGS = 'fields-page/GET_TAGS';
export const RECEIVE_TAGS = 'fields-page/RECEIVE_TAGS';

export const GET_FIELD = 'fields-page/GET_FIELD';
export const RECEIVE_FIELD = 'fields-page/RECEIVE_FIELD';
export const CREATE_FIELD = 'fields-page/CREATE_FIELD';
export const DISCARD_CHANGES = 'fields-page/DISCARD_CHANGES';
export const UPDATE_FIELD = 'fields-page/UPDATE_FIELD';
export const SAVE_FIELD = 'fields-page/SAVE_FIELD';
export const SET_SAVE_IN_PROGRESS = 'fields-page/SET_SAVE_IN_PROGRESS';
export const DELETE_FIELD = 'fields-page/DELETE_FIELD';
export const SET_DELETE_IN_PROGRESS = 'fields-page/SET_DELETE_IN_PROGRESS';

export const SAVE_BULK_EDIT = 'fields-page/SAVE_BULK_EDIT';
