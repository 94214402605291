import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  selectOrganizationReindexStatistics,
  selectSelectedOrganization,
} from '../../selectors';
import * as actions from '../../actions';
import { Modal } from '@alkem/react-ui-modal';
import Checkbox from '@alkem/react-ui-checkbox';
import { Text } from '@alkem/react-ui-inputs';
import InputWithLabel from 'components/input-with-label';
import { OrganizationAutocomplete } from 'components/autocomplete';

export function ReindexModal({ closeModal }: { closeModal: () => any }) {
  const dispatch = useDispatch();

  const [organization, setOrganization] = useState<{
    value: { id: number; type: number };
  } | null>(null);
  const [reason, setReason] = useState<string>('');
  const [withAllSources, setWithAllSources] = useState<boolean>(false);
  const [withAllTargets, setWithAllTargets] = useState<boolean>(false);
  const [sourceOrganizationIDS, setWithSourceOrganizationIDS] = useState<
    string | null
  >(null);
  const [targetOrganizationIDS, setWithTargetOrganizationIDS] = useState<
    string | null
  >(null);

  const organizationStatistics = useSelector(
    selectOrganizationReindexStatistics
  );
  const selectedOrganization = useSelector(selectSelectedOrganization);

  const maxReindexable = 200000;
  const totalReindaxable = organizationStatistics
    ? organizationStatistics.target_products +
      organizationStatistics.products +
      organizationStatistics.source_products
    : 0;

  const onOrganizationUpdate = (e) => {
    setWithAllSources(false);
    setWithAllTargets(false);
    setWithSourceOrganizationIDS(null);
    setWithTargetOrganizationIDS(null);
    setOrganization(e);
    setReason('');
  };

  useEffect(() => {
    if (organization === null) {
      setOrganization(selectedOrganization);
      return;
    }
    dispatch(
      actions.fetchOrganizationStatistics({
        organization_id: organization.value.id,
        with_all_sources: withAllSources,
        with_source_organization_ids: sourceOrganizationIDS
          ? sourceOrganizationIDS.split(',').map((e) => parseInt(e))
          : [],
        with_all_targets: withAllTargets,
        with_target_organization_ids: targetOrganizationIDS
          ? targetOrganizationIDS.split(',').map((e) => parseInt(e))
          : [],
      })
    );
  }, [
    dispatch,
    organization,
    withAllSources,
    withAllTargets,
    sourceOrganizationIDS,
    targetOrganizationIDS,
    selectedOrganization,
  ]);

  return (
    <Modal
      title={`Reindex Organization`}
      onClose={closeModal}
      confirmButtonText="Reindex"
      confirmDisabled={
        organization === null ||
        totalReindaxable > maxReindexable ||
        totalReindaxable === 0
      }
      onConfirm={() => {
        if (organization === null) {
          return;
        }
        dispatch(
          actions.reindexOrganization({
            organization_id: organization.value.id,
            with_all_sources: withAllSources,
            with_all_targets: withAllTargets,
            with_source_organization_ids: sourceOrganizationIDS
              ? sourceOrganizationIDS.split(',').map((e) => parseInt(e))
              : [],
            with_target_organization_ids: targetOrganizationIDS
              ? targetOrganizationIDS.split(',').map((e) => parseInt(e))
              : [],
            reason: reason,
          })
        );
        closeModal();
      }}
    >
      <>
        <div className="ModalOrganizationReindexRow">
          <h3>Configuration</h3>
        </div>
        <div className="ModalOrganizationReindexRow">
          <InputWithLabel
            inputId={`text-organization-id`}
            label="Organization ID"
          >
            <OrganizationAutocomplete
              id="indexer-reindex-organization"
              withs={{ type: true }}
              onSelect={onOrganizationUpdate}
              onUnselect={() => onOrganizationUpdate(null)}
              value={organization ? [organization] : null}
              placeholder="Select organization..."
              searchOnClick
            />
          </InputWithLabel>
        </div>
        <div className="ModalOrganizationReindexRow">
          <InputWithLabel
            inputId="InputText-organization-reindex-reason"
            label="Why are you reindexing this organization?"
          >
            <Text
              id={`text-reindex-reason`}
              value={reason}
              placeholder="Note: this will be sent to a slack channel for us to better understand the usage."
              onChange={(event) => setReason(event.target.value)}
            />
          </InputWithLabel>
        </div>
        {organization?.value.type === 2 && (
          <>
            <div className="ModalOrganizationReindexRow">
              <InputWithLabel
                inputId={`checkbox-with-all-sources`}
                label="And all source manufacturers products"
              >
                <Checkbox
                  id={`checkbox-with-all-sources`}
                  checked={withAllSources}
                  onChange={() => setWithAllSources(!withAllSources)}
                />
              </InputWithLabel>
            </div>
            <div className="ModalOrganizationReindexRow">
              <InputWithLabel
                inputId={`text-with-source-organization-ids`}
                label="Only from product of manufacturers:"
              >
                <Text
                  id={`text-with-source-organization-ids`}
                  value={sourceOrganizationIDS || ''}
                  onChange={(event) =>
                    setWithSourceOrganizationIDS(event.target.value)
                  }
                  placeholder=", separated manufacturer ids..."
                  type="number"
                />
              </InputWithLabel>
            </div>
          </>
        )}
        {organization?.value.type === 1 && (
          <>
            <div className="ModalOrganizationReindexRow">
              <InputWithLabel
                inputId={`checkbox-with-all-targets`}
                label="And all target retailers products"
              >
                <Checkbox
                  id={`checkbox-with-all-targets`}
                  checked={withAllTargets}
                  onChange={() => setWithAllTargets(!withAllTargets)}
                />
              </InputWithLabel>
            </div>
            <div className="ModalOrganizationReindexRow">
              <InputWithLabel
                inputId={`text-with-target-organization-ids`}
                label="Only for products shared with retailers:"
              >
                <Text
                  id={`text-with-target-organization-ids`}
                  value={targetOrganizationIDS || ''}
                  onChange={(event) =>
                    setWithTargetOrganizationIDS(event.target.value)
                  }
                  placeholder=", separated retailer ids..."
                  type="number"
                />
              </InputWithLabel>
            </div>
          </>
        )}
        {organization && organizationStatistics && (
          <>
            <div className="ModalOrganizationReindexRow">
              <h3>Statistics</h3>
            </div>
            <div className="ModalOrganizationReindexRow">
              <InputWithLabel
                inputId={`organization-stats-products`}
                label="Products:"
              >
                <Text
                  id={`organization-stats-products`}
                  value={organizationStatistics.products}
                  onChange={() => {}}
                  disabled={true}
                  placeholder="loading..."
                  type="number"
                />
              </InputWithLabel>
            </div>
            {organization.value.type === 2 && (
              <div className="ModalOrganizationReindexRow">
                <InputWithLabel
                  inputId={`organization-stats-source-products`}
                  label="From manufacturers:"
                >
                  <Text
                    id={`organization-stats-source-products`}
                    value={organizationStatistics.source_products}
                    onChange={() => {}}
                    disabled={true}
                    placeholder="loading..."
                    type="number"
                  />
                </InputWithLabel>
              </div>
            )}
            {organization.value.type === 1 && (
              <div className="ModalOrganizationReindexRow">
                <InputWithLabel
                  inputId={`organization-stats-target-products`}
                  label="From retailer:"
                >
                  <Text
                    id={`organization-stats-target-products`}
                    value={organizationStatistics.target_products}
                    onChange={() => {}}
                    disabled={true}
                    placeholder="loading..."
                    type="number"
                  />
                </InputWithLabel>
              </div>
            )}
            <div className="ModalOrganizationReindexRow">
              <InputWithLabel
                inputId={`organization-stats-total-products`}
                label="Total:"
              >
                <Text
                  id={`organization-stats-total-products`}
                  value={totalReindaxable}
                  onChange={() => {}}
                  disabled={true}
                  placeholder="loading..."
                  type="number"
                />
              </InputWithLabel>
            </div>
            {totalReindaxable > maxReindexable && (
              <div className="ModalOrganizationReindexRow">
                <InputWithLabel
                  inputId={`organization-stats-total-products-overflow`}
                  label=""
                >
                  <div className="ModalOrganizationReindexRowWarning">
                    <i className="mdi mdi-alert" />
                    {`Indexing more than ${maxReindexable} products is not allowed`}
                  </div>
                </InputWithLabel>
              </div>
            )}
          </>
        )}
      </>
    </Modal>
  );
}
