export const VALIDATION_DASHBOARD_STORE_KEY = 'validation-dashboard';

// Actions
export const VALIDATION_INIT_FILTERS = 'validation-dashboard/init-filters';
export const VALIDATION_INIT_FILTERS_DONE =
  'validation-dashboard/init-filters-done';
export const VALIDATION_MOVE_RULES_TO_RULESET =
  'validation-dashboard/move-rules-to-ruleset';
export const VALIDATION_DELETE_RULES = 'validation-dashboard/delete-rules';
export const VALIDATION_DELETE_RULES_DONE =
  'validation-dashboard/delete-rules-done';
export const VALIDATION_CLEAR_RULES_FILTERS =
  'validation-dashboard/clear-rules-filters';
export const VALIDATION_DELETE_RULE = 'validation-dashboard/delete-rule';
export const VALIDATION_DELETE_RULE_DONE =
  'validation-dashboard/delete-rule-done';
export const VALIDATION_SET_IS_LOADING_RULE =
  'validation-dashboard/set-is-loading-rule';
export const VALIDATION_FETCH_RULE = 'validation-dashboard/fetch-rule';
export const VALIDATION_FETCH_RULE_DONE =
  'validation-dashboard/fetch-rule-done';
export const VALIDATION_EDITED_RULE = 'validation-dashboard/edited-rule';
export const VALIDATION_DIRTY_RULE = 'validation-dashboard/dirty-rule';
export const VALIDATION_FETCH_RULE_DIMENSIONS =
  'validation-dashboard/fetch-rule-dimensions';
export const VALIDATION_FETCH_RULE_DIMENSIONS_DONE =
  'validation-dashboard/fetch-rule-dimensions-done';
export const VALIDATION_DUPLICATE_RULE = 'validation-dashboard/duplicate-rule';
export const VALIDATION_FILL_ORGANIZATIONS_CACHE =
  'validation-dashboard/fill-organizations-cache';
export const VALIDATION_FETCH_RULES = 'validation-dashboard/fetch-rules';
export const VALIDATION_FETCH_RULES_DONE = 'validation-dashboard/list-rules';
export const VALIDATION_FETCH_RULE_SET_TAGS =
  'validation-dashboard/fetch-rule-set-tags';
export const VALIDATION_FETCHING_RULE_SET_TAGS =
  'validation-dashboard/fetching-rule-set-tags';
export const VALIDATION_FETCH_RULE_SET_TAGS_DONE =
  'validation-dashboard/fetch-rule-set-tags-done';
export const VALIDATION_LIST_RULESETS = 'validation-dashboard/list-rulesets';
export const VALIDATION_DELETE_RULESET = 'validation-dashboard/delete-ruleset';
export const VALIDATION_FETCH_RULESET = 'validation-dashboard/fetch-ruleset';
export const VALIDATION_FETCH_RULESET_DONE =
  'validation-dashboard/fetch-ruleset-done';
export const VALIDATION_RESET_RULESET = 'validation-dashboard/reset-ruleset';

export const VALIDATION_ADD_TO_FILTERS = 'validation-dashboard/add-to-filters';
export const VALIDATION_REMOVE_FROM_FILTERS =
  'validation-dashboard/remove-from-filters';
export const VALIDATION_RESET_PATH_VALUE_FROM_FILTERS =
  'validation-dashboard/reset-path-value-from-filters';
export const VALIDATION_SAVE_RULE_DATA = 'validation-dashboard/save-rule-data';
export const VALIDATION_SAVE_RULE_CANCEL =
  'validation-dashboard/save-rule-cancel';
export const VALIDATION_SAVE_RULE_DONE = 'validation-dashboard/save-rule-done';
export const VALIDATION_SEARCH_QUERY = 'validation-dashboard/search-query';
export const VALIDATION_SELECT_RULE = 'validation-dashboard/select-rule';
export const VALIDATION_SET_IS_LOADING = 'validation-dashboard/set-is-loading';
export const VALIDATION_SET_IS_LOADING_DIMENSIONS =
  'validation-dashboard/set-is-loading-dimensions';
export const VALIDATION_SET_PAGINATION = 'validation-dashboard/set-pagination';
export const VALIDATION_TOGGLE_RULES = 'validation-dashboard/toogle-rules';
export const VALIDATION_CLEAR_SELECTED_RULES =
  'validation-dashboard/clear-selected-rules';

interface RuleEntityTypeSpec {
  id: number;
  label: string;
  help?: string;
}

export const ruleEntityTypes: { [key: string]: RuleEntityTypeSpec } = {
  CONSUMER_UNIT: {
    id: 0,
    label: 'Consumer unit',
  },
  SHARING_UNIT: {
    id: 1,
    label: 'Sharing unit',
  },
  LOGISTICAL_UNIT: {
    id: 2,
    label: 'Logistical unit',
    help: 'All logistical units in the hierarchy must validate the rule.',
  },
  LOGISTICAL_HIERARCHY: {
    id: 3,
    label: 'Logistical hierarchy',
    help: 'At least one of the unit (all unit types included) must validate the rule.',
  },
  DISPLAY_UNIT: {
    id: 4,
    label: 'Display unit',
  },
  PRICE_WATERFALL: {
    id: 5,
    label: 'Price waterfall',
    help: 'All price waterfalls must validate the rule.',
  },
  LOGISTICAL_HIERARCHY_TOP_UNIT: {
    id: 6,
    label: 'Logistical hierarchy top unit',
    help: 'The top level of the hierarchy must validate the rule.',
  },
  SHARING_UNITS: {
    id: 7,
    label: 'All sharing units',
    help: 'The rule applies to all sharing units of each organization, passed as a list.',
  },
  TARIFF: {
    id: 10,
    label: 'Tariff',
  },
  PICTURE: {
    id: 11,
    label: 'Picture',
    help: 'All pictures must validate the rule.',
  },
  DOCUMENT: {
    id: 12,
    label: 'Document',
    help: 'All documents must validate the rule.',
  },
  TEXTILE_VARIANT: {
    id: 13,
    label: 'Textile variant',
    help: 'Either the model or all active variants must validate the rule.',
  },
  VIDEO: {
    id: 14,
    label: 'Video',
    help: 'All videos must validate the rule.',
  },
  TARIFFS: {
    id: 15,
    label: 'All tariffs',
    help: 'The rule applies to all tariffs of each organization, passed as a list.',
  },
  RFP_ANSWER: {
    id: 16,
    label: 'RFP Answer',
  },
  PICTURES: {
    id: 17,
    label: 'Pictures',
    help: 'At least one picture must validate the rule.',
  },
  DOCUMENTS: {
    id: 18,
    label: 'Documents',
    help: 'At least one document must validate the rule.',
  },
  VIDEOS: {
    id: 19,
    label: 'Videos',
    help: 'At least one video must validate the rule.',
  },
};

export const VALIDATION_RULESET_TYPE__REGULATORY = 'REGULATORY';
export const VALIDATION_RULESET_TYPE__MATURITY = 'MATURITY';
export const VALIDATION_RULESET_TYPE__STAGE_GATE = 'STAGE_GATE';
export const VALIDATION_RULESET_TYPE__OTHER = 'OTHER';
export const VALIDATION_RULESET_TYPE__DRAFT = 'DRAFT';

export const ruleSetTypes = [
  {
    value: VALIDATION_RULESET_TYPE__REGULATORY,
    label: VALIDATION_RULESET_TYPE__REGULATORY,
  },
  {
    value: VALIDATION_RULESET_TYPE__STAGE_GATE,
    label: VALIDATION_RULESET_TYPE__STAGE_GATE,
  },
  {
    value: VALIDATION_RULESET_TYPE__MATURITY,
    label: VALIDATION_RULESET_TYPE__MATURITY,
  },
  {
    value: VALIDATION_RULESET_TYPE__OTHER,
    label: VALIDATION_RULESET_TYPE__OTHER,
  },
  {
    value: VALIDATION_RULESET_TYPE__DRAFT,
    label: VALIDATION_RULESET_TYPE__DRAFT,
  },
];

export const VALIDATION_RULESET_TYPE_DEADLINE__STATIC = 'static';
export const VALIDATION_RULESET_TYPE_DEADLINE__DYNAMIC = 'dynamic';

export const ruleSetDeadlineTypes = [
  {
    key: VALIDATION_RULESET_TYPE_DEADLINE__STATIC,
    label: VALIDATION_RULESET_TYPE_DEADLINE__STATIC,
  },
  {
    key: VALIDATION_RULESET_TYPE_DEADLINE__DYNAMIC,
    label: VALIDATION_RULESET_TYPE_DEADLINE__DYNAMIC,
  },
];

export const ruleStatuses = {
  [-1]: 'Awaiting application',
  0: 'Success',
  1: 'Failure',
  2: 'Not executed',
  3: 'Bypassed',
  4: 'Internal error',
};

export const ruleTemplateTypes = {
  REQUIRED: 'required',
};

export const ruleTemplateLabels = {
  CUSTOM: 'custom',
};

export const ruleEntityTypesMap = Object.values(ruleEntityTypes).reduce(
  (acc, entityObject) => ({ ...acc, [entityObject.id]: entityObject }),
  {}
);

// Quick filter
export const VALIDATION_FILTER_PRESETS = [
  {
    id: 'consumer-unit',
    label: 'Consumer unit',
    filters: {
      entityTypeIn: [ruleEntityTypes.CONSUMER_UNIT.id],
    },
  },
  {
    id: 'textile-variants',
    label: 'Textile variants',
    filters: {
      entityTypeIn: [ruleEntityTypes.TEXTILE_VARIANT.id],
    },
  },
  {
    id: 'sharing-unit',
    label: 'Sharing unit',
    filters: {
      entityTypeIn: [
        ruleEntityTypes.SHARING_UNIT.id,
        ruleEntityTypes.PRICE_WATERFALL.id,
        ruleEntityTypes.SHARING_UNITS.id,
      ],
    },
  },
  {
    id: 'tariff',
    label: 'Tariff',
    filters: {
      entityTypeIn: [ruleEntityTypes.TARIFF.id, ruleEntityTypes.TARIFFS.id],
    },
  },
  {
    id: 'logistical-hierarchies',
    label: 'Logistical Hierarchies',
    filters: {
      entityTypeIn: [
        ruleEntityTypes.LOGISTICAL_UNIT.id,
        ruleEntityTypes.LOGISTICAL_HIERARCHY.id,
        ruleEntityTypes.LOGISTICAL_HIERARCHY_TOP_UNIT.id,
      ],
    },
  },
  {
    id: 'display-unit',
    label: 'Display unit',
    filters: {
      entityTypeIn: [ruleEntityTypes.DISPLAY_UNIT.id],
    },
  },
  {
    id: 'pictures',
    label: 'Product picture',
    filters: {
      entityTypeIn: [ruleEntityTypes.PICTURE.id, ruleEntityTypes.PICTURES.id],
    },
  },
  {
    id: 'documents',
    label: 'Product document',
    filters: {
      entityTypeIn: [ruleEntityTypes.DOCUMENT.id, ruleEntityTypes.DOCUMENTS.id],
    },
  },
  {
    id: 'videos',
    label: 'Product video',
    filters: {
      entityTypeIn: [ruleEntityTypes.VIDEO.id, ruleEntityTypes.VIDEOS.id],
    },
  },
];
