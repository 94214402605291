import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './pagination.scss';

export default class CatalogAdvancedFilterPagination extends PureComponent {
  static propTypes = {
    page: PropTypes.number,
    itemsPerPage: PropTypes.number,
    total: PropTypes.number,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    page: 1,
    itemsPerPage: 8,
    total: 0,
  };

  onPreviousPage = () => {
    if (!this.isPreviousDisabled()) {
      this.props.onChange(this.props.page - 1);
    }
  };

  onNextPage = () => {
    if (!this.isNextDisabled()) {
      this.props.onChange(this.props.page + 1);
    }
  };

  getTotalPages() {
    return Math.ceil(this.props.total / this.props.itemsPerPage) || 1;
  }

  isPreviousDisabled() {
    return this.props.page <= 1;
  }

  isNextDisabled() {
    return this.props.page >= this.getTotalPages();
  }

  render() {
    return (
      <div className="CatalogAdvancedFilterPagination">
        <i
          className="mdi mdi-24px mdi-arrow-left-bold"
          disabled={this.isPreviousDisabled()}
          onClick={this.onPreviousPage}
        />
        <span className="CatalogAdvancedFilterPagination__pages">
          {`${this.props.page} / ${this.getTotalPages()}`}
        </span>
        <i
          className="mdi mdi-24px mdi-arrow-right-bold"
          disabled={this.isNextDisabled()}
          onClick={this.onNextPage}
        />
      </div>
    );
  }
}
