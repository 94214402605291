// Processing status

export const STATUS_PENDING = { value: 'PENDING', icon: 'mdi mdi-pencil' };
export const STATUS_IN_PROGRESS = {
  value: 'IN_PROGRESS',
  icon: 'mdi mdi-sync',
};
export const STATUS_SUCCESS = { value: 'SUCCESS', icon: 'mdi mdi-check' };
export const STATUS_ERROR = { value: 'ERROR', icon: 'mdi mdi-close' };

export const STATUS_MAPPING = {
  [STATUS_PENDING.value]: STATUS_PENDING,
  [STATUS_IN_PROGRESS.value]: STATUS_IN_PROGRESS,
  [STATUS_SUCCESS.value]: STATUS_SUCCESS,
  [STATUS_ERROR.value]: STATUS_ERROR,
};

// Features block

export const ORGANIZATION_INFOS = {
  value: 'ORGANIZATION_INFOS',
  label: 'Basic information',
};
export const ORGANIZATION_SETTINGS = {
  value: 'ORGANIZATION_SETTINGS',
  label: 'Settings',
};
export const ORGANIZATION_RECIPIENTS = {
  value: 'ORGANIZATION_RECIPIENTS',
  label: 'Recipients',
};
export const ORGANIZATION_APPLICATIONS = {
  value: 'ORGANIZATION_APPLICATIONS',
  label: 'Applications',
};
export const ORGANIZATION_SETTING_FLAGS = {
  value: 'ORGANIZATION_SETTING_FLAGS',
  label: 'Feature and release flags',
};
export const ORGANIZATION_BILLING = {
  value: 'ORGANIZATION_BILLING',
  label: 'Billing',
};
export const ORGANIZATION_PERMISSIONS = {
  value: 'ORGANIZATION_PERMISSIONS',
  label: 'Permissions',
};

export const ORGANIZATION_FEATURES_MAPPING = {
  [ORGANIZATION_INFOS.value]: ORGANIZATION_INFOS,
  [ORGANIZATION_SETTINGS.value]: ORGANIZATION_SETTINGS,
  [ORGANIZATION_RECIPIENTS.value]: ORGANIZATION_RECIPIENTS,
  [ORGANIZATION_APPLICATIONS.value]: ORGANIZATION_APPLICATIONS,
  [ORGANIZATION_SETTING_FLAGS.value]: ORGANIZATION_SETTING_FLAGS,
  [ORGANIZATION_BILLING.value]: ORGANIZATION_BILLING,
  [ORGANIZATION_PERMISSIONS.value]: ORGANIZATION_PERMISSIONS,
};
