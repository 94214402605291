import { Button } from '@alkem/react-ui-button';
import { Checkbox } from '@alkem/react-ui-checkbox';
import Tooltip from '@alkem/react-ui-tooltip';
import InputWithLabel from 'components/input-with-label';
import { fromJS } from 'immutable';
import { isNumber } from 'lodash';
import { validationToggleRules } from 'modules/validation-dashboard/actions';
import { selectSelectedRules } from 'modules/validation-dashboard/selectors';
import { Rule } from 'modules/validation-dashboard/types';
import { stopPropagation } from 'modules/validation-dashboard/utils';
import moment from 'moment';
import { SyntheticEvent, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { KindsIndex } from 'redux/state/kinds';
import { ruleEntityTypesMap, ruleTemplateLabels } from '../../constants';
import { ApplicableKindsSummary } from '../applicable-kinds/applicable-kinds-summary/applicable-kinds-summary';
import { ConditionsDisplay } from '../rule-editor/validation-conditions-display';
import { ValidationRuleHeader } from '../rule-header';

interface Props {
  rule: Rule;
  disabled?: boolean;
  readOnly?: boolean;
  isDirty: boolean;
  showSummary: boolean;
  kinds: KindsIndex;

  deleteRule?: (ruleId: number) => void;
  selectRule: (Rule) => void;
  forbiddenKinds?: object;
  forbiddenTypePackagings?: object;
}
export const ValidationRuleTags = ({
  ruleEntityType,
  ruleId,
  ruleTemplateType,
}: {
  ruleEntityType: any;
  ruleTemplateType: string;
  ruleId?: number;
}) => {
  return (
    <div className="alk-flex alk-flex-row ValidationRuleTags">
      {ruleEntityType && (
        <div
          data-tip
          data-for={`entity-type-${ruleId}`}
          onClick={stopPropagation}
        >
          <div className="ValidationRule__templateType">
            {ruleEntityType.label}
          </div>
          <Tooltip
            sanitize={(label: string) => label.trim()}
            id={`entity-type-${ruleId}`}
            place="right"
          >
            {ruleEntityType.help || ruleEntityType.label}
          </Tooltip>
        </div>
      )}
      {ruleTemplateType === ruleTemplateLabels.CUSTOM && (
        <div
          data-tip
          data-for={`template-type-${ruleId}`}
          onClick={stopPropagation}
        >
          <div className="ValidationRule__templateType">CUSTOM</div>
          <Tooltip
            sanitize={(label: string) => label.trim()}
            id={`template-type-${ruleId}`}
            place="right"
          >
            Rule was not generated but written by POPs team.
          </Tooltip>
        </div>
      )}
    </div>
  );
};
export const getEntityType = (rule: Rule) =>
  isNumber(rule?.ruleEntityType)
    ? ruleEntityTypesMap[rule.ruleEntityType]
    : null;

export const ValidationRuleBeta = ({
  rule,
  disabled,
  isDirty,
  readOnly,
  kinds,
  showSummary = false,
}: Props) => {
  const [summaryShown, setSummaryShown] = useState(false);
  const selectedRules = useSelector(selectSelectedRules);

  const checked = !!selectedRules[rule.id];

  const dispatch = useDispatch();

  useEffect(() => {
    setSummaryShown(showSummary);
  }, [showSummary]);

  const ruleEntityType = getEntityType(rule);
  const history = useHistory();
  const location = useLocation();
  const openRule = () =>
    history.push(`/validation/rule/${rule.id}${location.search}`);

  const handleSummaryToggle = (e: SyntheticEvent<EventTarget>) => {
    e.stopPropagation();
    setSummaryShown((shown) => !shown);
  };

  const handleSelectCheckboxUpdate = (rule, selectedRules) => {
    if (!rule.id) {
      return;
    }

    const ruleToggleState = {
      [rule.id]: rule.id in selectedRules ? !selectedRules[rule.id] : true,
    };
    dispatch(validationToggleRules(ruleToggleState));
  };

  return (
    <div
      className={`ValidationRule alk-flex`}
      key={`${rule.id}`}
      data-testid={`validation-rule-${rule.id}`}
    >
      <div className="ValidationRuleCheckboxContainer">
        <Checkbox
          onChange={() => handleSelectCheckboxUpdate(rule, selectedRules)}
          checked={checked}
          id={'rule-' + rule.id}
          testid={'checkbox-rule-' + rule.id}
        />
      </div>
      <div
        className="ValidationRuleContainer alk-flex alk-flex-row alk-flex-space-between"
        onClick={openRule}
      >
        <ValidationRuleHeader
          rule={rule}
          isDirty={isDirty}
          readOnly={!!readOnly}
          disabled={!!disabled}
          allowDuplicate={rule.template?.label === ruleTemplateLabels.CUSTOM}
          allowDelete={!!rule.ruleSet}
        />
        <div className="ValidationRuleInfos alk-flex alk-flex-space-between">
          <ValidationRuleTags
            ruleEntityType={ruleEntityType}
            ruleId={rule.id}
            ruleTemplateType={rule.template?.label as string}
          />
          <div className="ValidationRuleInfos__contextual">
            <small>Created {moment(rule.createdAt).format('DD/MM/YYYY')}</small>
            &nbsp;
            <small>Updated {moment(rule.updatedAt).format('DD/MM/YYYY')}</small>
            &nbsp;
            <small>
              Enforced by <b>{rule.organizations?.length}</b> organizations
            </small>
          </div>

          <div data-tip data-for={`preview-rule-${rule.id}-button`}>
            <Button small link onClick={handleSummaryToggle}>
              {summaryShown ? '\u25be' : '\u25b8'}
            </Button>
            <Tooltip id={`preview-rule-${rule.id}-button`} place="bottom">
              Display rule content
            </Tooltip>
          </div>
        </div>

        {summaryShown && (
          <div
            className="ValidationRule__summary"
            onClick={stopPropagation}
            data-testid="ValidationRule-summary"
          >
            <ConditionsDisplay
              rule={rule}
              id={`validation-rule-${rule.id}-summary`}
            />
            <hr />

            <InputWithLabel
              inputId={`applicable-kinds-display`}
              label="Applicable kinds"
            >
              <ApplicableKindsSummary
                applicableKinds={fromJS(rule.applicableForKinds)}
                kinds={kinds}
                kindsAreManuallySelected={rule.kindsAreManuallySelected}
              />
            </InputWithLabel>
          </div>
        )}
      </div>
    </div>
  );
};
