import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button } from '@alkem/react-ui-button';
import { HeaderLayout } from '@alkem/react-layout';

import * as routes from 'constants/routes';
import { separateActions } from 'utils/redux';
import Search from 'components/ui/input/search';
import { ReferentialAutocomplete } from 'components/autocomplete';

import { refreshCache } from 'modules/referentials/actions';

import ReferentialPicturesList from './list';
import ReferentialPicturesUploadModal from './modal';
import {
  selectFilterSearch,
  selectFilterReferentialSlug,
  selectRefreshingCache,
} from '../selectors';
import {
  getReferentialEntities,
  updateFilterSearch,
  updateFilterReferentialSlug,
} from '../actions';

import './index.scss';

const mapStateToProps = (state) => ({
  search: selectFilterSearch(state),
  referentialSlug: selectFilterReferentialSlug(state),
  refreshingCache: selectRefreshingCache(state),
});

const mapDispatchToProps = {
  getReferentialEntities,
  updateFilterSearch,
  updateFilterReferentialSlug,
  refreshCache,
};

export class ReferentialPicturesDashboard extends PureComponent {
  static propTypes = {
    search: PropTypes.string,
    referentialSlug: PropTypes.string,
    refreshingCache: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      getReferentialEntities: PropTypes.func.isRequired,
      updateFilterSearch: PropTypes.func.isRequired,
      updateFilterReferentialSlug: PropTypes.func.isRequired,
      refreshCache: PropTypes.func.isRequired,
    }).isRequired,
  };

  componentDidMount() {
    this.props.actions.getReferentialEntities();
  }

  onClearReferentialSlug = () => {
    this.props.actions.updateFilterReferentialSlug(null);
    this.props.actions.getReferentialEntities();
  };

  onUpdateReferentialSlug = (e) => {
    this.props.actions.updateFilterReferentialSlug(e.value.slug);
    this.props.actions.getReferentialEntities();
  };

  onUpdateReferentialSearchQuery = (search) => {
    this.props.actions.updateFilterSearch(search);
    this.props.actions.getReferentialEntities();
  };

  onRefreshCache = () => {
    this.props.actions.refreshCache();
  };

  renderSearchBars() {
    const { refreshingCache } = this.props;
    return (
      <div className="ReferentialPictures__searchContainer">
        <ReferentialAutocomplete
          id="ReferentialPicturesSlugSearch"
          value={
            this.props.referentialSlug
              ? [{ label: this.props.referentialSlug }]
              : []
          }
          onSelect={this.onUpdateReferentialSlug}
          onUnselect={this.onClearReferentialSlug}
          placeholder="Search referentials..."
          searchOnClick
        />
        <Search
          query={this.props.search}
          placeholder="Search entities..."
          updateSearchQuery={this.onUpdateReferentialSearchQuery}
        />
        <Button
          id="cache-refresh-button"
          content="Refresh cache"
          onClick={this.onRefreshCache}
          disabled={refreshingCache}
          displaySpinner={refreshingCache}
          warning
        />
      </div>
    );
  }

  render() {
    return (
      <div>
        <ReferentialPicturesUploadModal />
        <HeaderLayout
          title="Referential Pictures"
          backHref={routes.home}
          backMessage="Back home"
          isTitleSmall
        >
          {this.renderSearchBars()}
        </HeaderLayout>
        <div className="container-fluid row">
          <ReferentialPicturesList />
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(ReferentialPicturesDashboard);
