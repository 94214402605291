import { useEffect, useState } from 'react';
import { Textarea } from '@alkem/react-ui-inputs';
import InputWithLabel from 'components/input-with-label';

import { Xpath } from '../../types';
import { DATETIME_FORMAT, UNLIMITED_DATE, XPATH_TYPES } from '../../constants';
import {
  CHANGE_ORDER_EXPORT_MAPPING,
  GET_EXPORT_MAPPING_LIST,
  RECEIVE_EXPORT_MAPPING_LIST,
  SET_EXPORT_MAPPING,
} from '../../actions/constants';
import { selectExportMapping, selectExportMappings } from '../../selectors';
import { ExportMappingsItem } from './item';
import { useSelector, useDispatch } from 'react-redux';

import './export-mappings.scss';
import moment from 'moment';
import { Button } from '@alkem/react-ui-button';
import { reorderArray } from './utils';

interface Props {
  element: Xpath;
}
export function ExportMappingsList({ element }: Props): JSX.Element {
  const dispatch = useDispatch();
  const exportMappings = useSelector(selectExportMappings);
  const exportMapping = useSelector(selectExportMapping);
  const elementName = element.name;
  const [isChangeOrder, setIsChangeOrder] = useState(false);

  const reOrder = (): void => {
    setIsChangeOrder(true);
  };

  const saveReorder = (): void => {
    setIsChangeOrder(false);
    const orders_to_update: { id: number | undefined; order: number }[] = [];
    for (let i = 0; i < exportMappings.length; i++) {
      if (exportMappings[i].id !== undefined) {
        const item = { id: exportMappings[i].id, order: i };
        orders_to_update.push(item);
      }
    }
    dispatch({
      type: CHANGE_ORDER_EXPORT_MAPPING,
      exportMappingOrders: orders_to_update,
      xpath: element,
    });
  };

  const cancelReorder = (): void => {
    setIsChangeOrder(false);
    dispatch({ type: GET_EXPORT_MAPPING_LIST, xpath: element });
  };

  function changeOrder(index, direction) {
    const newExportMappings = reorderArray(
      { oldIndex: index, newIndex: index + (direction === 'UP' ? -1 : 1) },
      exportMappings
    );
    dispatch({
      type: RECEIVE_EXPORT_MAPPING_LIST,
      payload: {
        exportMappings: newExportMappings,
      },
    });
  }

  useEffect(() => {
    dispatch({
      type: SET_EXPORT_MAPPING,
      payload: {
        exportMapping: {
          date_start: moment().format(DATETIME_FORMAT),
          date_end: UNLIMITED_DATE,
          order: 0,
          xpath_id: element.id,
          payload: {},
        },
      },
    });
  }, [dispatch, element.id]);

  return (
    <div className="GDSNExportMappings">
      <div className="GDSNExportMappings__xpathContainer">
        <InputWithLabel
          inputId={`gdsn-export-mappings-${elementName}-xpath`}
          label="xpath"
        >
          <Textarea
            id={`gdsn-export-mappings-${elementName}-xpath`}
            value={element.xpath}
            autoresize
            disabled
            onChange={() => {}}
          />
        </InputWithLabel>
      </div>
      {element.type !== XPATH_TYPES.module && (
        <div className="GDSNExportMappings_mappingsContainer">
          <div data-testid="gdsn-export-mappings-mappings-container">
            {exportMappings.length > 1 && !isChangeOrder && (
              <Button
                id="reorder-gdsn-export-mapping"
                content="Reorder"
                primary
                onClick={reOrder}
              />
            )}
            {exportMapping && isChangeOrder && (
              <Button
                id="save-reorder-gdsn-export-mapping"
                content="Save"
                primary
                onClick={saveReorder}
              />
            )}
            {exportMapping && isChangeOrder && (
              <Button
                id="cancel-reorder-gdsn-export-mapping"
                content="Cancel"
                primary
                onClick={cancelReorder}
              />
            )}
            {exportMappings.map((mapping, index) => (
              <ExportMappingsItem
                key={mapping.id}
                element={element}
                exportMapping={mapping}
                isChangeOrder={isChangeOrder}
                changeOrder={changeOrder}
                index={index}
                lengthMappings={exportMappings.length}
              />
            ))}
            {exportMapping && !isChangeOrder && (
              <ExportMappingsItem
                key={`new_export_mapping_${element.id}`}
                element={element}
                exportMapping={exportMapping}
                isNew
                changeOrder={changeOrder}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
