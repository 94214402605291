import { useState } from 'react';
import InputWithLabel from 'components/input-with-label';
import { Radio, Textarea } from '@alkem/react-ui-inputs';

import { getStringWithoutLineBreaks } from 'modules/io/gdsn/imports/utils';
import { IMPORT_MAPPING_TYPES_WITH_OPTIONAL_PROPERTY_CUSTOM_PARSE } from '../../../../constants';
import { ImportMappingDetails, Property } from '../../../../types';

interface Props {
  details: ImportMappingDetails;
  dispatchImportMapping: (properties: Property[]) => void;
  fieldName: string;
  type: string;
}
export function ImportMappingsItemOptionalPropertyCustomParse({
  details,
  dispatchImportMapping,
  fieldName,
  type,
}: Props): JSX.Element {
  const [customParse, setCustomParse] = useState(
    details.custom_parse || details.custom_parse_all || ''
  );
  const [mustFindAllNodes, setMustFindAllNodes] = useState(
    details.custom_parse_all ? true : undefined
  );

  const updateCustomParse = (event: { target: { value: string } }) => {
    const importMappingDetailsCustomParse = getStringWithoutLineBreaks(
      event.target.value
    );
    setCustomParse(importMappingDetailsCustomParse);
    dispatchImportMapping([
      {
        name: mustFindAllNodes ? 'custom_parse_all' : 'custom_parse',
        value: importMappingDetailsCustomParse || undefined,
      },
    ]);
  };

  const updateMustFindAllNodes = (event: { target: { value: string } }) => {
    const importMappingDetailsMustFindall =
      event.target.value === 'true' ? true : undefined;
    setMustFindAllNodes(importMappingDetailsMustFindall);
    dispatchImportMapping([
      {
        name: 'custom_parse',
        value: importMappingDetailsMustFindall ? undefined : customParse,
      },
      {
        name: 'custom_parse_all',
        value: importMappingDetailsMustFindall ? customParse : undefined,
      },
    ]);
  };

  return (
    <>
      {IMPORT_MAPPING_TYPES_WITH_OPTIONAL_PROPERTY_CUSTOM_PARSE.includes(
        type
      ) && (
        <>
          <InputWithLabel
            inputId={`gdsn-import-mappings-field-${fieldName}-optional-property-custom-parse`}
            label="Custom parse"
          >
            <Textarea
              id={`gdsn-import-mappings-field-${fieldName}-optional-property-custom-parse`}
              value={customParse}
              autoresize
              onChange={updateCustomParse}
              onKeyDown={(e) => {
                if (e.key === 'Enter') e.preventDefault();
              }}
            />
          </InputWithLabel>
          <InputWithLabel
            inputId={`gdsn-import-mappings-field-${fieldName}-optional-property-must-find-all-nodes`}
            label="Must find all nodes for custom parse"
          >
            <Radio
              id={`gdsn-import-mappings-field-${fieldName}-optional-property-must-find-all-nodes`}
              value={mustFindAllNodes}
              options={[
                { label: 'TRUE', value: true },
                { label: 'FALSE', value: undefined },
              ]}
              onChange={updateMustFindAllNodes}
            />
          </InputWithLabel>
        </>
      )}
    </>
  );
}
