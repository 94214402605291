export const ALLOWED_FIELD_CATEGORIES = ['consumerUnit', 'sharingUnit'];
export const DATETIME_FORMAT = 'YYYY-MM-DD[T00:00:00]';
export const IMPORT_MAPPINGS_DEFAULT_VALUE_TYPES = {
  string: ['string'],
  number: ['int', 'int positive', 'float', 'float positive'],
  boolean: ['boolean', 'ternary'],
  referential_codes_list: ['entity'],
};
export const IMPORT_MAPPING_NOT_SUPPORTED_TYPES = [
  'time',
  'product',
  'productsegment',
  'brand',
  'kind',
  'enum',
  'iso_date',
  'uri_entity',
  'gln',
  'int negative',
  'float negative',
  'text',
  'copy',
];
export const IMPORT_MAPPING_TYPES_WITHOUT_OPTIONAL_PROPERTIES = [
  ...IMPORT_MAPPING_NOT_SUPPORTED_TYPES,
  'timestamp',
  'date',
];
export const IMPORT_MAPPING_TYPES_WITH_OPTIONAL_PROPERTY_UNIT_STRING = [
  'string',
  'int',
  'int positive',
  'float',
  'float positive',
];
export const IMPORT_MAPPING_TYPES_WITH_OPTIONAL_PROPERTY_PREDICATE = [
  'string',
  'list',
  'entity',
];
export const IMPORT_MAPPING_TYPES_WITH_OPTIONAL_PROPERTY_CUSTOM_PARSE = [
  'string',
  'int',
  'int positive',
  'float',
  'float positive',
  'list',
  'entity',
  'dict',
  'boolean',
  'ternary',
];
export const IMPORT_MAPPING_TYPES_WITH_OPTIONAL_PROPERTY_KEEP_HASH = [
  'string',
  'string_list',
];
export const IMPORT_MAPPING_TYPES_WITH_OPTIONAL_PROPERTY_SKIPPABLE_REFERENTIAL_VALUES =
  ['string', 'int', 'int positive', 'float', 'float positive', 'entity'];
export const IMPORT_MAPPING_TYPES_WITH_OPTIONAL_PROPERTY_DISCARDABLE_REFERENTIAL_VALUES =
  IMPORT_MAPPING_TYPES_WITH_OPTIONAL_PROPERTY_SKIPPABLE_REFERENTIAL_VALUES;
export const IMPORT_MAPPING_TYPES_WITH_OPTIONAL_PROPERTY_MUST_RAISE_ERROR =
  IMPORT_MAPPING_TYPES_WITH_OPTIONAL_PROPERTY_SKIPPABLE_REFERENTIAL_VALUES;

export const FIELD_UNITS = {
  consumerUnit: 'CU',
  sharingUnit: 'SU',
  specific: 'SP',
};
export const REDUCER_KEY = 'gdsn-import-mappings';
export const UNLIMITED_DATE = '9999-12-31';

export const XML_SPACE = '  ';
