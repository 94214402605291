import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { get } from 'lodash';

import { getStreamUrl } from 'utils/stream';
import { Checkbox } from '@alkem/react-ui-inputs';

import * as routes from 'constants/routes';
import PublicLayout from 'layouts/PublicLayout';

import { login } from '../actions';
import {
  selectIsAuthenticated,
  selectIsAuthPending,
  selectLoginError,
} from '../reducer';

import './LoginView.scss';

const mapState = createStructuredSelector({
  isAuthenticated: selectIsAuthenticated,
  isAuthPending: selectIsAuthPending,
  loginError: selectLoginError,
});

const mapDispatch = {
  login,
};
export class LoginView extends React.Component {
  static propTypes = {
    location: PropTypes.object,
    isAuthenticated: PropTypes.bool.isRequired,
    isAuthPending: PropTypes.bool.isRequired,
    login: PropTypes.func.isRequired,
    loginError: PropTypes.string,
  };

  state = {
    email: '',
    password: '',
    rememberMe: true,
  };

  login = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.props.login(this.state);
  };

  onChange = (event) => {
    this.setState({ rememberMe: event.target.checked });
  };

  renderError() {
    if (!this.props.loginError) {
      return null;
    }
    return (
      <div id="login-error" className="Login__error alert alert-danger">
        {this.props.loginError}
      </div>
    );
  }

  render() {
    const { isAuthenticated, location } = this.props;
    if (isAuthenticated) {
      return (
        <Redirect to={get(location, 'state.from.pathname') || routes.home} />
      );
    }
    return (
      <PublicLayout>
        <div id="Login" className="card card-block">
          <form onSubmit={this.login} method="post">
            <h1 className="Login__title text-xs-center">Sign In</h1>

            {this.renderError()}

            <div className="row">
              <div className="col-xs-12">
                <input
                  id="email"
                  name="username"
                  type="text"
                  className="form-control Login__input Login__input-email"
                  placeholder="Email"
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                <input
                  id="password"
                  name="password"
                  type="password"
                  className="form-control Login__input Login__input-password"
                  placeholder="Password"
                  value={this.state.password}
                  onChange={(e) => this.setState({ password: e.target.value })}
                />
              </div>
            </div>

            <p className="Login__submit text-xs-center">
              <input
                type="submit"
                id="sign-in"
                className="Button btn btn-primary"
                value="Sign In"
                disabled={this.props.isAuthPending}
              />
            </p>

            <p className="text-xs-center">
              <Checkbox
                id="rememberMe"
                label="Remember me"
                checked={this.state.rememberMe}
                onChange={this.onChange}
              />
            </p>
            <p className="text-xs-center">
              <a href={getStreamUrl('forgot-password')}>Reset password</a>
            </p>
          </form>
        </div>
      </PublicLayout>
    );
  }
}

export default connect(mapState, mapDispatch)(LoginView);
