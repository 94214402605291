import { takeLatest, select, call, put } from 'redux-saga/effects';

import GdsnApi from 'resources/gdsnApi';
import { saveAs } from 'utils';

import { notificationError } from 'actions/notifications';

import * as events from '../constants/events';

import { receiveGDSNRelationList } from '../actions';

import { selectPagination, selectFilters } from '../selectors';

const {
  GET_GDSN_RELATION_LIST,
  ADD_GDSN_RELATION_LIST_FILTER,
  REMOVE_GDSN_RELATION_LIST_FILTER,
  UPDATE_GDSN_RELATION_LIST_FILTER,
  CLEAR_GDSN_RELATION_LIST_FILTER,
  CHANGE_LIMIT_GDSN_RELATION_LIST,
  NEXT_PAGE_GDSN_RELATION_LIST,
  PREVIOUS_PAGE_GDSN_RELATION_LIST,
  DOWNLOAD_GDSN_RELATION_DOCUMENT,
  DELETE_GDSN_RELATION,
} = events;

export default function* gdsnRelationMainSaga() {
  yield takeLatest(GET_GDSN_RELATION_LIST, getGDSNRelationList);
  yield takeLatest(DOWNLOAD_GDSN_RELATION_DOCUMENT, downloadGDSNDocument);
  yield takeLatest(DELETE_GDSN_RELATION, deleteRelation);
  yield takeLatest(
    [
      ADD_GDSN_RELATION_LIST_FILTER,
      REMOVE_GDSN_RELATION_LIST_FILTER,
      UPDATE_GDSN_RELATION_LIST_FILTER,
      CLEAR_GDSN_RELATION_LIST_FILTER,
      CHANGE_LIMIT_GDSN_RELATION_LIST,
      NEXT_PAGE_GDSN_RELATION_LIST,
      PREVIOUS_PAGE_GDSN_RELATION_LIST,
    ],
    getGDSNRelationList
  );
}

function* deleteRelation(action) {
  try {
    yield call([GdsnApi, GdsnApi.DeleteRelation], action.id);
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured: deleteRelation'));
    }
  }
}

function* getGDSNRelationList() {
  try {
    const pagination = yield select(selectPagination);
    const filters = yield select(selectFilters);
    const response = yield call([GdsnApi, GdsnApi.ListRelation], {
      filter_gln: filters.filter_gln.length ? filters.filter_gln[0].value : '',
      offset: (pagination.currentPage - 1) * pagination.limit,
      limit: pagination.limit,
    });
    yield put(
      receiveGDSNRelationList(response.data.data, response.data.totalResults)
    );
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured: getGDSNRelationList'));
    }
  }
}

function* downloadGDSNDocument(payload) {
  try {
    const response = yield call(
      [GdsnApi, GdsnApi.DownloadDocument],
      payload.documentID
    );
    const filename = `gdsn_document_rel_${payload.documentID}.xml`;
    saveAs(response.data, filename);
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured: downloadGDSNDocument'));
    }
  }
}
