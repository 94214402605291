import { Map as ImmutableMap, Set as ImmutableSet } from 'immutable';
import React from 'react';
import { get } from 'utils/immutable';
import ApplicableKinds from './components/applicable-kinds';
import ApplicablePackagingTypes from './components/applicable-packaging-types';
import {
  BooleanControl,
  Props as BooleanControlProps,
} from './components/booleanControl';
import { useChildrenAvailability } from './hooks/useChildrenAvailability';
import './validation-rule-form.scss';

export type PackagingTypes = ImmutableSet<number> | null | 'loading' | 'error';

export type Kinds = ImmutableSet<string> | 'loading' | 'error';

interface Props {
  disabled?: boolean;
  rule: ImmutableMap<string, any>;
  onUpdateValue: (path: string[], value: any) => void;
  kinds?: object;
  forbiddenKinds: Kinds;
  forbiddenPackagingTypes: PackagingTypes;
}

const ValidationRuleForm = (props: Props) => {
  const {
    disabled = false,
    onUpdateValue,
    rule,
    kinds,
    forbiddenKinds,
    forbiddenPackagingTypes,
  } = props;

  const entityType = get(rule, 'entityType');

  const {
    blocking: canBeBlocking,
    bypassable: canBypass,
    kinds: canSetKinds,
    packagingTypes: canSetPackagingTypes,
  } = useChildrenAvailability(rule);

  if (
    typeof forbiddenKinds === 'string' ||
    typeof forbiddenPackagingTypes === 'string'
  ) {
    if (forbiddenKinds === 'error' || forbiddenPackagingTypes === 'error') {
      return <span>[ Error ! ]</span>;
    }
    return <span>[ Loading … ]</span>;
  }

  const ruleId = rule.get('id') || rule.hashCode();

  function handleFieldChange(fieldName: string) {
    return (value: any) => onUpdateValue([fieldName], value);
  }

  const BC = (fieldName: string, props: Partial<BooleanControlProps> = {}) => (
    <BooleanControl
      key={fieldName}
      fieldName={fieldName}
      ruleId={ruleId}
      disabled={disabled}
      value={rule.get(fieldName)}
      onChange={handleFieldChange(fieldName)}
      {...props}
    />
  );

  const children: React.ReactNode[] = [
    BC('status', { label: 'enabled', disabled: true }),
    canBypass && BC('bypassable'),
    canBeBlocking &&
      BC('blocking', {
        value: rule.get('restrictionType'),
        onChange: handleFieldChange('restrictionType'),
      }),
    canSetKinds && (
      <ApplicableKinds
        key="applicable-kinds"
        id={`rule-${ruleId}-applicable-kinds`}
        kinds={kinds}
        applicableKinds={rule.get('applicableForKinds')}
        forbiddenKinds={forbiddenKinds}
        onChange={handleFieldChange('applicableForKinds')}
        disabled={disabled}
      />
    ),
    canSetPackagingTypes && (
      <ApplicablePackagingTypes
        key="applicable-packaging-types"
        id={`rule-${ruleId}-applicable-packaging-types`}
        ruleEntityType={entityType}
        applicablePackagingTypes={rule.get('applicableForTypePackagings')}
        forbiddenTypePackagings={forbiddenPackagingTypes}
        onChange={handleFieldChange('applicableForTypePackagings')}
        disabled={disabled}
      />
    ),
  ];

  return (
    <div
      className="ValidationRule__form"
      data-testid={`validation-rule-form-${ruleId}`}
    >
      {children}
    </div>
  );
};

export default ValidationRuleForm;
