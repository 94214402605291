import { useCallback, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Button } from '@alkem/react-ui-button';
import { notificationError } from 'actions/notifications';
import { Modal } from '@alkem/react-ui-modal';
import classNames from 'classnames';
import { saveAs } from 'utils';
import Dropzone from 'react-dropzone';

import { ImportMappingsItemActivationDates } from '../item-activation-dates';
import { ImportMappingsItemAutomaticProperties } from '../item-automatic-properties';
import { ImportMappingsItemMandatoryProperties } from '../item-mandatory-properties';
import { ImportMappingsItemOptionalProperties } from '../item-optional-properties';
import {
  SAVE_IMPORT_MAPPING,
  SET_IMPORT_MAPPING_PROPERTY,
} from '../../../actions/constants';
import {
  IMPORT_MAPPING_NOT_SUPPORTED_TYPES,
  UNLIMITED_DATE,
} from '../../../constants';
import {
  selectIsSavingInProgress,
  selectReferentialCodes,
  selectSourceField,
} from '../../../selectors';
import { ImportMapping } from '../../../types';

import './import-mappings-item.scss';
import { ImportMappingsItemFallbackProperties } from '../item-fallback-properties';
import { ImportMappingsItemChildren } from '../item-children';
import { ImportMappingsItemWhichCanNotBeCreatedMessage } from '../can-not-be-created-message';
import {
  getChildrenForApi,
  getFallbackForApi,
  getFormattedChildren,
  getFormattedDefaultValue,
  getFormattedFallback,
  getFormattedXpath,
} from '../../../utils';
import { ImportMappingsItemTest } from '../item-test';

interface Props {
  importMapping: ImportMapping;
  otherImportMappingsActivationDates?: { dateStart: string; dateEnd: string }[];
}
export function ImportMappingsItem({
  importMapping,
  otherImportMappingsActivationDates,
}: Props): JSX.Element {
  const dispatch = useDispatch();
  const isSavingInProgress = useSelector(selectIsSavingInProgress);
  const sourceField = useSelector(selectSourceField);
  const referentialCodes = useSelector(selectReferentialCodes);
  const originalStartDate = useRef(moment(importMapping.dateStart)).current;
  const originalEndDate = useRef(moment(importMapping.dateEnd)).current;
  const [isClosed, setIsClosed] = useState(true);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalImport, setShowModalImport] = useState(false);
  const [files, setFiles] = useState<any[]>([]);
  const [isProcessingImport, setIsProcessingImport] = useState(false);

  const canNotBeCreated =
    importMapping?.isNew &&
    (!importMapping.details.cast ||
      IMPORT_MAPPING_NOT_SUPPORTED_TYPES.includes(importMapping.details.cast));

  const openItem = (): void => {
    setIsClosed(false);
  };

  const isDataValid = (): boolean => {
    const errors: string[] = [];
    if (!importMapping.dateEnd) {
      errors.push('A value must be specified for the activation end date.');
    } else if (moment(importMapping.dateStart).isAfter(importMapping.dateEnd)) {
      errors.push(
        'The activation end date must be after the activation start date.'
      );
    }
    if (
      importMapping.details.xpath.filter((xpath) => xpath !== '').length === 0
    ) {
      errors.push(
        'A value must be specified and validated for the mandatory property xpath.'
      );
    }
    if (
      otherImportMappingsActivationDates?.find((activationDates) => {
        if (activationDates.dateStart === activationDates.dateEnd) {
          return (
            (moment(importMapping.dateStart).isSame(
              activationDates.dateStart
            ) &&
              moment(importMapping.dateEnd).isSame(activationDates.dateEnd)) ||
            (moment(importMapping.dateStart).isBefore(
              activationDates.dateStart
            ) &&
              moment(importMapping.dateEnd).isAfter(activationDates.dateEnd))
          );
        } else {
          return (
            moment(importMapping.dateStart).isSameOrBefore(
              activationDates.dateStart
            ) &&
            moment(importMapping.dateEnd).isSameOrAfter(activationDates.dateEnd)
          );
        }
      })
    ) {
      errors.push(
        'The activation dates are in conflict with an other import mapping activation dates.'
      );
    } else if (
      otherImportMappingsActivationDates?.find((activationDates) =>
        moment(importMapping.dateEnd).isBetween(
          activationDates.dateStart,
          activationDates.dateEnd,
          undefined,
          '(]'
        )
      )
    ) {
      errors.push(
        'The activation end date is in conflict with an other import mapping activation dates.'
      );
    } else if (
      otherImportMappingsActivationDates?.find((activationDates) =>
        moment(importMapping.dateStart).isBetween(
          activationDates.dateStart,
          activationDates.dateEnd,
          undefined,
          '[)'
        )
      )
    ) {
      errors.push(
        'The activation start date is in conflict with an other import mapping activation dates.'
      );
    }
    if (errors.length > 0) {
      dispatch(notificationError(errors.join(' + ')));
    }
    return errors.length === 0;
  };

  const save = (): void => {
    setShowModalEdit(false);
    if (isDataValid()) {
      dispatch({
        type: SAVE_IMPORT_MAPPING,
        importMapping,
        field: sourceField,
      });
    }
  };

  const exportImportMapping = (): void => {
    if (isDataValid()) {
      const importMappingToExport = {
        ...importMapping,
        dateStart: importMapping.dateStart,
        dateEnd: importMapping.dateEnd,
        id: undefined,
        details: {
          ...importMapping.details,
          xpath:
            importMapping.details.xpath.length === 1
              ? importMapping.details.xpath[0]
              : importMapping.details.xpath,
          fallback: getFallbackForApi(importMapping.details.fallback),
          children: getChildrenForApi(importMapping.details.children),
        },
      };
      if (importMappingToExport.isNew) delete importMappingToExport.isNew;
      const dataStr = JSON.stringify(importMappingToExport, null, 2);
      const exportFileName = `${importMapping.field}.json`;
      saveAs(
        new Blob([dataStr], { type: 'application/json;charset=utf-8' }),
        exportFileName
      );
    }
  };

  const importImportMapping = useCallback(() => {
    if (files.length !== 1) {
      return;
    }

    setIsProcessingImport(true);
    const reader = new FileReader();
    reader.onload = function () {
      const content = reader.result as string;
      const importedImportMapping = JSON.parse(content);
      if (importedImportMapping.field === importMapping.field && sourceField) {
        const updatedImportMapping = {
          ...importedImportMapping,
          isNew: importMapping.isNew,
          id: importMapping.id,
          details: {
            ...importedImportMapping.details,
            xpath: getFormattedXpath(importedImportMapping.details.xpath),
            fallback: getFormattedFallback(
              importedImportMapping.details.fallback
            ),
            children: getFormattedChildren(
              importedImportMapping.details.children,
              sourceField.children,
              true,
              referentialCodes
            ),
            default: getFormattedDefaultValue(
              true,
              importedImportMapping.details,
              referentialCodes
            ),
          },
        };
        dispatch({
          type: SET_IMPORT_MAPPING_PROPERTY,
          payload: {
            importMapping: {
              ...updatedImportMapping,
            },
          },
        });
        openItem();
      } else {
        dispatch(
          notificationError(
            "The import mapping doesn't concern the same field."
          )
        );
      }
      setIsProcessingImport(false);
      setShowModalImport(false);
    };
    reader.readAsText(files[0]);
  }, [
    dispatch,
    files,
    importMapping.field,
    importMapping.id,
    importMapping.isNew,
    referentialCodes,
    sourceField,
  ]);

  const renderButtonsContainer = (): JSX.Element => {
    return isClosed ? (
      <div className="GDSNImportMappingsItem__buttonsContainer">
        {canNotBeCreated && (
          <Button
            id="Show-gdsn-import-mapping"
            content="Show"
            primary
            onClick={openItem}
          />
        )}
        {!canNotBeCreated && importMapping.isNew && (
          <>
            <Button
              id="import-gdsn-import-mapping"
              content="Import"
              secondary
              disabled={isSavingInProgress}
              onClick={() => setShowModalImport(true)}
            />
            <Button
              id="create-gdsn-import-mapping"
              content="Create"
              primary
              onClick={openItem}
            />
          </>
        )}
        {!canNotBeCreated && !importMapping.isNew && (
          <>
            <Button
              id="import-gdsn-import-mapping"
              content="Import"
              secondary
              disabled={isSavingInProgress}
              onClick={() => setShowModalImport(true)}
            />
            <Button
              id="edit-gdsn-import-mapping"
              content="Edit"
              primary
              onClick={openItem}
              disabled={isSavingInProgress}
            />
          </>
        )}
      </div>
    ) : (
      <div className="GDSNImportMappingsItem__buttonsContainer">
        {canNotBeCreated ? (
          <Button
            id="hide-gdsn-import-mapping"
            content="Hide"
            primary
            onClick={() => setIsClosed(true)}
          />
        ) : (
          <>
            <Button
              id="export-gdsn-import-mapping"
              content="Export"
              secondary
              disabled={isSavingInProgress}
              onClick={exportImportMapping}
            />
            <Button
              id="save-gdsn-import-mapping"
              content="Save"
              primary
              disabled={!importMapping.isTested || isSavingInProgress}
              onClick={() =>
                importMapping.isNew ? save() : setShowModalEdit(true)
              }
            />
          </>
        )}
      </div>
    );
  };
  const fieldName = importMapping.field;
  return (
    <div className="GDSNImportMappingsItem">
      <div
        className={classNames({
          GDSNImportMappingItem__header__closed: isClosed,
          GDSNImportMappingItem__header__opened: !isClosed,
        })}
      >
        <div
          className="GDSNImportMappingItem_validity"
          onClick={() => setIsClosed(!isClosed)}
        >
          <span className="GDSNImportMappingItem__expand-button">
            <i
              className={classNames('mdi', {
                'mdi-chevron-down': isClosed,
                'mdi-chevron-up': !isClosed,
              })}
            />
          </span>
          {canNotBeCreated && "Can't be created"}
          {!canNotBeCreated &&
            !importMapping.isNew &&
            `Valid from ${originalStartDate.format('YYYY-MM-DD')} ${
              originalEndDate.isSame(UNLIMITED_DATE)
                ? 'for an unlimited amount of time'
                : `to ${originalEndDate.format('YYYY-MM-DD')}${
                    originalEndDate.isBefore(moment())
                      ? ' (not valid anymore)'
                      : ''
                  }`
            }`}
        </div>
        {renderButtonsContainer()}
      </div>

      {!isClosed && (
        <>
          <ImportMappingsItemAutomaticProperties
            fieldName={importMapping.field}
            details={importMapping.details}
            isSpecific={importMapping.unit === 'SP'}
          />
          {canNotBeCreated ? (
            <ImportMappingsItemWhichCanNotBeCreatedMessage
              type={importMapping.details.cast}
            />
          ) : (
            <>
              <ImportMappingsItemActivationDates
                importMapping={importMapping}
              />
              <ImportMappingsItemMandatoryProperties
                details={importMapping.details}
                fieldName={fieldName}
                importMapping={importMapping}
              />
              <ImportMappingsItemOptionalProperties
                details={importMapping.details}
                fieldName={fieldName}
                importMapping={importMapping}
              />
              <ImportMappingsItemFallbackProperties
                details={importMapping.details}
                fieldName={fieldName}
                importMapping={importMapping}
              />
              <ImportMappingsItemChildren
                importMapping={importMapping}
                importMappingChildren={importMapping.details.children}
                isFirst
              />
              <ImportMappingsItemTest
                fieldName={fieldName}
                importMapping={importMapping}
              />
            </>
          )}
        </>
      )}
      {showModalEdit && (
        <Modal
          testid="GDSNImportMappingEditItemModal"
          danger
          title="Confirm edition"
          modalStyle="dynamic"
          confirmButtonText="Save"
          onConfirm={save}
          onClose={() => setShowModalEdit(false)}
        >
          <div>
            You're about to edit the existing import mapping. Do you want to
            proceed?
          </div>
        </Modal>
      )}
      {showModalImport && (
        <Modal
          testid="GDSNImportMappingImportItemModal"
          title="Import import mapping"
          modalStyle="fullHeight"
          confirmButtonText="Import"
          onConfirm={importImportMapping}
          onClose={() => setShowModalImport(false)}
          isProcessing={isProcessingImport}
        >
          <Dropzone onDrop={setFiles}>
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div
                {...getRootProps({
                  className: classNames(
                    'Dropzone',
                    isDragActive && 'Dropzone--active'
                  ),
                })}
              >
                <input {...getInputProps()} />
                <div>
                  {files.length > 0 ? (
                    <>File selected: {files[0].name}</>
                  ) : (
                    <>
                      <b>Drag and drop your json file here</b> or select a json
                      file from your computer...
                    </>
                  )}
                </div>
              </div>
            )}
          </Dropzone>
        </Modal>
      )}
    </div>
  );
}
