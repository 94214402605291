export const ORGANIZATION_PAGE_STORE_KEY = 'organization-page';

export const INFOS_REDUCER_KEY = 'infos';
export const MAIN_REDUCER_KEY = 'main';
export const SAVE_MODAL_REDUCER_KEY = 'save-modal';
export const SETTINGS_REDUCER_KEY = 'settings';
export const RECIPIENTS_REDUCER_KEY = 'recipients';
export const USERS_REDUCER_KEY = 'users';
export const APPLICATIONS_REDUCER_KEY = 'applications';
export const BILLING_REDUCER_KEY = 'billing';
export const SSO_REDUCER_KEY = 'sso';
export const PERMISSIONS_REDUCER_KEY = 'permissions';

export const SUPPLIER_RECONCILIATION_KEYS_REFERENTIAL_NAME =
  'suppliersreconciliationkeys';
