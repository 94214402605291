import { takeLatest, call, put } from 'redux-saga/effects';
import { fromJS } from 'immutable';

import GdsnApi from 'resources/gdsnApi';

import { notificationError, notificationSuccess } from 'actions/notifications';

import { SEND_GDSN_PCR } from '../constants/events';

export default function* PCRModalMainSaga() {
  yield takeLatest(SEND_GDSN_PCR, sendGdsnPCR);
}

function* sendGdsnPCR(payload) {
  try {
    let { pcr } = payload;
    if (pcr.get('reason')) {
      pcr = pcr.setIn(
        ['reasons'],
        fromJS([
          {
            actionNeeded: pcr.get('reason'),
            confirmationStatusReasonCode: 'CIC99',
          },
        ])
      );
    }
    yield call([GdsnApi, GdsnApi.SendPCR], pcr);
    yield put(notificationSuccess('PCR successfully send'));
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured: sendGdsnPCR'));
    }
  }
}
