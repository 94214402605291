// List
export const RECEIVE_GDSN_RCI_LIST = 'RECEIVE_GDSN_RCI_LIST';
export const GET_GDSN_RCI_LIST = 'GET_GDSN_RCI_LIST';
export const GET_GDSN_RCI_LIST_FILTERS = 'GET_GDSN_RCI_LIST_FILTERS';
export const CHANGE_LIMIT_GDSN_RCI_LIST = 'CHANGE_LIMIT_GDSN_RCI_LIST';
export const NEXT_PAGE_GDSN_RCI_LIST = 'NEXT_PAGE_GDSN_RCI_LIST';
export const PREVIOUS_PAGE_GDSN_RCI_LIST = 'PREVIOUS_PAGE_GDSN_RCI_LIST';

// Filters
export const RECEIVE_GDSN_RCI_LIST_FILTERS = 'RECEIVE_GDSN_RCI_LIST_FILTERS';
export const ADD_GDSN_RCI_LIST_FILTER = 'ADD_GDSN_RCI_LIST_FILTER';
export const REMOVE_GDSN_RCI_LIST_FILTER = 'REMOVE_GDSN_RCI_LIST_FILTER';
export const UPDATE_GDSN_RCI_LIST_FILTER = 'UPDATE_GDSN_RCI_LIST_FILTER';
export const CLEAR_GDSN_RCI_LIST_FILTER = 'CLEAR_GDSN_RCI_LIST_FILTER';

export const DOWNLOAD_GDSN_RCI_DOCUMENT = 'DOWNLOAD_GDSN_RCI_DOCUMENT';

export const OPEN_GDSN_RCI_MODAL = 'OPEN_GDSN_RCI_MODAL';
export const CLOSE_GDSN_RCI_MODAL = 'CLOSE_GDSN_RCI_MODAL';
export const GDSN_RCI_MODAL_SAVE = 'GDSN_RCI_MODAL_SAVE';
export const GDSN_RCI_ATTR_UPDATE = 'GDSN_RCI_ATTR_UPDATE';
export const GDSN_RCI_DELETE = 'GDSN_RCI_DELETE';

export const OPEN_GDSN_GEPIR_MODAL = 'OPEN_GDSN_GEPIR_MODAL';
export const CLOSE_GDSN_GEPIR_MODAL = 'CLOSE_GDSN_GEPIR_MODAL';
export const GDSN_GEPIR_MODAL_UPLOAD_CSV = 'GDSN_GEPIR_MODAL_UPLOAD_CSV';
