import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import moment from 'moment';

import Date from 'components/ui/input/date';

class FilterDate extends PureComponent {
  static propTypes = {
    filters: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.renderInputDate = this.renderInputDate.bind(this);
  }

  onSelect = (filter) => (value) => {
    const { onChange, name } = this.props;
    const explicitValue = {
      name: filter.name,
      value: value && value.format('YYYY-MM-DD'),
      label: filter.label,
    };
    onChange(name, explicitValue);
  };

  renderInputDate = (filter) => {
    const value = filter.value ? moment(filter.value) : null;
    return (
      <Date
        key={filter.name}
        value={value}
        onSelect={this.onSelect(filter)}
        label={filter.label}
        placeholder="yyyy/mm/dd"
        small
      />
    );
  };

  render() {
    const { filters } = this.props;
    return (
      <div className="FilterDate">{filters.map(this.renderInputDate)}</div>
    );
  }
}

export default FilterDate;
