import { fromJS } from 'immutable';
import { get, isEqual, isEmpty } from 'lodash';
import { isValidJSON } from 'utils/index';
import { getIn, setIn } from 'utils/immutable';

import {
  APP_PERMISSIONS,
  APP_PERMISSION_VALUES,
  NEW_APPLICATION_ID,
  NEW_APPLICATION_NAME,
  VISIBILITY,
} from '../constants';

const trimTrailingSlash = (s) => s.replace(/\/$/, '');

function mapValuesToClient(serverData, organizations, appsHaveOrganizations) {
  const { organizationId, scopes, id: appId } = serverData;
  return fromJS({
    id: serverData.id,
    name: serverData.name,
    type: serverData.type,
    descriptionShort: serverData.descriptionShort,
    description: serverData.description,
    descriptionValue: serverData.descriptionValue,
    logoUrl: trimTrailingSlash(serverData.logoUrl),
    url: trimTrailingSlash(serverData.url),
    color: serverData.color,
    visibility: serverData.visibility,
    organization: createOrganization(organizationId, organizations),
    userId: serverData.userId || null,
    pricingPlan: serverData.pricingPlan,
    permissions: createPermissions(scopes),
    flags: JSON.stringify(scopes.flags),
    basicSettings: JSON.stringify(scopes.basicsettings),
    valueFlags: JSON.stringify(scopes.valueflags),
    reportings: JSON.stringify(scopes.reportings),
    catalogColumns: createCatalogColumns(scopes),
    sourcingColumns: createSourcingColumns(scopes),
    viewAsTag: createViewAsTag(scopes),
    ruleSets: createRuleSets(scopes),
    serverScopes: scopes,
    enabledFor: createEnabledFor(appId, appsHaveOrganizations, organizations),
    featured: serverData.featured,
    categories: serverData.categories,
  });
}

function newAppData() {
  return fromJS({
    id: NEW_APPLICATION_ID,
    name: NEW_APPLICATION_NAME,
    description: '',
    descriptionShort: '',
    descriptionValue: '',
    color: '',
    logoUrl: '',
    smallLogoUrl: '',
    userId: null,
    featured: false,
    url: '',
    pricingPlan: {
      id: 2,
      name: 'Free',
    },
    organization: {},
    visibility: VISIBILITY.ALKEMICS_ONLY,
    enabledFor: {},
    permissions: APP_PERMISSIONS.NONE,
    flags: '',
    basicSettings: '',
    reportings: '',
    valueFlags: '',
    catalogColumns: [],
    sourcingColumns: [],
    categories: [],
    viewAsTag: '',
    ruleSets: [],
    type: 0,
  });
}

function createCatalogColumns(scopes) {
  if (!scopes.listcolumns || !scopes.listcolumns.catalog) {
    return [];
  } else {
    return scopes.listcolumns.catalog;
  }
}

function createSourcingColumns(scopes) {
  if (!scopes.listcolumns || !scopes.listcolumns.sourcing) {
    return [];
  } else {
    return scopes.listcolumns.sourcing;
  }
}

function createOrganization(organizationId, organizations) {
  const organization = organizations.find((o) => o.id === organizationId);
  return {
    id: organizationId,
    nameLegal: get(organization, 'nameLegal'),
  };
}

function createEnabledFor(appId, appsHaveOrganizations, organizations) {
  const appHasOrganizations = appsHaveOrganizations[appId] || [];
  return appHasOrganizations.reduce(
    createEnabledOrganization(organizations),
    {}
  );
}

function createEnabledOrganization(organizations) {
  return (enabledFor, activeOrg) => {
    const org = organizations.find((o) => o.id === activeOrg.organizationId);
    const nextEnabledFor = { ...enabledFor };
    if (org) {
      nextEnabledFor[org.id] = {
        nameLegal: org.nameLegal,
        network: org.network,
        active: activeOrg.active,
      };
    }
    return nextEnabledFor;
  };
}

function createPermissions(scopes) {
  if (!scopes.permissions) {
    return APP_PERMISSIONS.NONE;
  } else if (isEqual(scopes.permissions, APP_PERMISSION_VALUES.READ_WRITE)) {
    return APP_PERMISSIONS.READ_WRITE;
  } else if (isEqual(scopes.permissions, APP_PERMISSION_VALUES.API_ACCESS)) {
    return APP_PERMISSIONS.API_ACCESS;
  }
  return -1;
}

function createViewAsTag(scopes) {
  return getIn(scopes, 'viewAs.tag') || '';
}

function createRuleSets(scopes) {
  return fromJS(scopes.ruleSets || []);
}

// ----------------------------------------------------------------------------

function mapValuesToServer(clientData) {
  const {
    permissions,
    flags,
    basicsettings,
    valueFlags,
    listcolumns,
    ...serverScopes
  } = clientData.serverScopes;
  return {
    id: clientData.id > 0 ? clientData.id : null,
    type: clientData.type,
    name: clientData.name,
    descriptionShort: clientData.descriptionShort,
    description: clientData.description,
    descriptionValue: clientData.descriptionValue,
    logoUrl: trimTrailingSlash(clientData.logoUrl),
    url: trimTrailingSlash(clientData.url),
    color: clientData.color,
    visibility: clientData.visibility,
    organizationId: get(clientData, 'organization.id', null),
    userId: get(clientData, 'user.id', null),
    pricingPlan: clientData.pricingPlan,
    scopes: {
      ...serverScopes,
      ...getScopesPermissions(clientData.permissions),
      ...getScopesFlags(clientData.flags),
      ...getScopesBasicSettings(clientData.basicSettings),
      ...getScopesValueFlags(clientData.valueFlags),
      ...getScopesReportings(clientData.reportings),
      ...getScopesCatalogColumns(
        clientData.catalogColumns,
        clientData.sourcingColumns,
        clientData.serverScopes.listcolumns
      ),
      ...getScopesViewAsTag(clientData.viewAsTag),
      ...getScopesRuleSets(clientData.ruleSets),
    },
    featured: clientData.featured,
    categories: clientData.categories,
  };
}

function getScopesPermissions(permissions) {
  switch (permissions) {
    case APP_PERMISSIONS.API_ACCESS:
      return { permissions: APP_PERMISSION_VALUES.API_ACCESS };
    case APP_PERMISSIONS.READ_WRITE:
      return { permissions: APP_PERMISSION_VALUES.READ_WRITE };
    case APP_PERMISSIONS.NONE:
      return {};
    default:
      throw Error(`Unknown permissions ${permissions} for application.`);
  }
}

function getScopesFlags(flags) {
  if (isEmpty(flags)) {
    return {};
  } else if (isValidJSON(flags)) {
    return { flags: JSON.parse(flags) };
  } else {
    throw Error(`Invalid json flags ${flags} for application.`);
  }
}

function getScopesBasicSettings(basicSettings) {
  if (isEmpty(basicSettings)) {
    return {};
  } else if (isValidJSON(basicSettings)) {
    return { basicsettings: JSON.parse(basicSettings) };
  } else {
    throw Error(`Invalid json basicSettings ${basicSettings} for application.`);
  }
}

function getScopesValueFlags(valueFlags) {
  if (isEmpty(valueFlags)) {
    return {};
  } else if (isValidJSON(valueFlags)) {
    return { valueflags: JSON.parse(valueFlags) };
  } else {
    throw Error(`Invalid json valueFlags ${valueFlags} for application.`);
  }
}

function getScopesReportings(reportings) {
  if (isEmpty(reportings)) {
    return {};
  } else if (isValidJSON(reportings)) {
    return { reportings: JSON.parse(reportings) };
  } else {
    throw Error(`Invalid json reportings ${reportings} for application.`);
  }
}

function getScopesCatalogColumns(
  catalogColumns,
  sourcingColumns,
  oldListColumns
) {
  const result = { ...oldListColumns };
  if (!catalogColumns.isEmpty()) {
    setIn(result, 'catalog', catalogColumns.toJS());
  } else {
    delete result.catalog;
  }
  if (!sourcingColumns.isEmpty()) {
    setIn(result, 'sourcing', sourcingColumns.toJS());
  } else {
    delete result.sourcing;
  }

  if (isEmpty(result)) {
    return {};
  } else {
    return { listcolumns: result };
  }
}

function getScopesViewAsTag(tag) {
  if (!tag) {
    return {};
  }

  return { viewAs: { tag } };
}

function getScopesRuleSets(ruleSets) {
  if (!ruleSets || ruleSets.size === 0) {
    return {};
  }

  return {
    ruleSets: ruleSets
      .map((ruleSet) => ({
        id: ruleSet.get('id'),
        restrictionType: ruleSet.get('restrictionType'),
      }))
      .toArray(),
  };
}

export { mapValuesToClient, mapValuesToServer, newAppData, createEnabledFor };
