import { ApiClient, getServiceUrl } from '@alkem/sdk-dashboard';

export const immutableCoreApi = new ApiClient(getServiceUrl('core'), {
  immutable: true,
  cacheEnabled: true,
  acceptLanguage: 'en',
});

export default new ApiClient(getServiceUrl('core'), {
  cacheEnabled: true,
  acceptLanguage: 'en',
});
