import { AppState } from './state';
import { KindsIndex, KindsTree } from './state/kinds';

export const kindsIndexSelector: (state: AppState) => KindsIndex = ({
  kinds: { indexed },
}) => indexed;

export const kindsTreeSelector: (state: AppState) => KindsTree | undefined = ({
  kinds: { tree },
}) => tree;
