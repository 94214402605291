import React from 'react';
import proptypes from 'prop-types';
import './result.scss';

export function Result({ query, children }) {
  return (
    <div className="CommandPalette__Result">
      <div className="CommandPalette__ResultQuery">{query}</div>
      <div className="CommandPalette__ResultComponent">{children}</div>
    </div>
  );
}

Result.propTypes = {
  query: proptypes.string,
  children: proptypes.element,
};
