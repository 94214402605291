import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import './info.scss';

export default class ListPaginationInfo extends PureComponent {
  static propTypes = {
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
    totalResults: PropTypes.number,
    selected: PropTypes.number,
    type: PropTypes.string,
  };

  static defaultProps = {
    currentPage: 0,
    totalPages: 0,
    totalResults: 0,
    type: 'default',
  };

  getDefaultLabels() {
    const count = this.props.totalResults;
    return {
      singular: `${count} entity in total`,
      plural: `${count} entities in total`,
    };
  }

  getProductLabels() {
    const count = this.props.totalResults;
    return {
      singular: `${count} product in total`,
      plural: `${count} products in total`,
    };
  }

  getLabelsByType(type) {
    switch (type) {
      case 'products':
        return this.getProductLabels();
      default:
        return this.getDefaultLabels();
    }
  }

  render() {
    const { currentPage, totalPages, totalResults, selected, type } =
      this.props;
    const pageLabelData = {
      page: totalPages === 0 ? 0 : currentPage,
      total: totalPages,
    };
    const pageLabel = `${pageLabelData.page} / ${pageLabelData.total} page`;
    const pagesLabel = `${pageLabelData.page} / ${pageLabelData.total} pages`;
    const noOrOneSelectionLabel = `${selected} results displayed`;
    const manySelectionLabel = `${selected} results displayed`;
    const entityLabels = this.getLabelsByType(type);

    return (
      <div className="ListPaginationInfo">
        <div className="ListPaginationInfo__background" />
        <div className="ListPaginationInfo__content">
          <span className="ListPaginationInfo__text ListPaginationInfo__pages">
            {totalPages > 1 ? pagesLabel : pageLabel}
          </span>
          <i className="ListPaginationInfo__circle mdi mdi-checkbox-blank-circle" />
          <span
            className="ListPaginationInfo__text ListPaginationInfo__total"
            data-total={totalResults}
          >
            {totalResults > 1 ? entityLabels.plural : entityLabels.singular}
          </span>
          {selected >= 0
            ? [
                <i
                  key="circle"
                  className="ListPaginationInfo__circle mdi mdi-checkbox-blank-circle"
                />,
                <span
                  key="text"
                  className="ListPaginationInfo__text ListPaginationInfo__selected"
                >
                  {selected > 1 ? manySelectionLabel : noOrOneSelectionLabel}
                </span>,
              ]
            : null}
        </div>
      </div>
    );
  }
}
