import PropTypes from 'prop-types';
import { Autocomplete } from '@alkem/react-ui-autocomplete';
import {
  AutocompleteProps,
  LegacyAutocompleteItem,
  LegacyAutocompleteProps,
} from './legacy-autocomplete';

function mapDataToOption(data) {
  return data.map((result) => ({
    ...result,
    key: result.id,
    value: result,
  }));
}

class LegacyAutocomplete extends Autocomplete {
  static propTypes = {
    ...Autocomplete.propTypes,
    list: PropTypes.any,
    transformData: PropTypes.func,
    onSearch: PropTypes.func,
  };

  static defaultProps = {
    debounceTime: 450,
    excludeList: [],
    options: {},
  };

  constructor(props) {
    super(props);
    // for backward compatibility
    this.getList = this.getList.bind(this);
    this.transformData = this.transformData.bind(this);
    this.onSearch = this.onSearch.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.list && prevProps.list !== this.props.list) {
      this.updateList(this.props.list);
    }
  }

  async getList(search) {
    const promise = this.onSearch(search);
    if (!promise) {
      return null;
    }
    const response = await promise;
    const data = response.data?.data || response.data || response;
    return super.updateList(this.transformData(data));
  }

  onSearch(parameters) {
    if (!this.props.onSearch) {
      throw new Error('not implemented error');
    }
    return this.props.onSearch(parameters);
  }

  transformData(data) {
    return this.props.transformData
      ? this.props.transformData(data)
      : mapDataToOption(data);
  }
}

export {
  AutocompleteProps,
  LegacyAutocompleteItem,
  LegacyAutocompleteProps,
  LegacyAutocomplete,
};

export default LegacyAutocomplete;
