import { createSelector } from 'reselect';
import { keyBy } from 'lodash';

const getModuleState = (state) => state.applications;

export const getApplications = createSelector(getModuleState, (moduleState) =>
  moduleState.get('applications')
);

export const getApplicationsLoading = createSelector(
  getModuleState,
  (moduleState) => moduleState.get('loading')
);

export const getOrganizations = createSelector(getModuleState, (moduleState) =>
  moduleState.get('organizations')
);

export const getCategories = createSelector(getModuleState, (moduleState) =>
  moduleState.get('categories')
);

export const getCategoryOptions = createSelector(getCategories, (categories) =>
  keyBy(
    categories.map((category) => ({ key: category.id, label: category.name })),
    'key'
  )
);

export const getPristine = createSelector(getModuleState, (moduleState) =>
  moduleState.get('pristine')
);

export const getSaving = createSelector(getModuleState, (moduleState) =>
  moduleState.get('saving')
);

export const getFormErrors = createSelector(getModuleState, (moduleState) =>
  moduleState.get('formErrors')
);

export const getCreated = createSelector(getModuleState, (moduleState) =>
  moduleState.get('created')
);

export const getApplicationsHaveOrganizations = createSelector(
  getModuleState,
  (moduleState) => moduleState.get('applicationsHaveOrganizations')
);

export const getSelectedApplicationId = createSelector(
  getModuleState,
  (moduleState) => moduleState.get('selected')
);

export const getOrganizationOption = (organization) => ({
  key: organization.id,
  value: organization,
  label: `${organization.nameLegal} - ${organization.id}`,
});

export const getUserOption = (user) => ({
  key: user.id,
  value: user.username ? user.username : user.id,
  label: `${user.id || ''}${user.username ? ' - ' : ''}${user.username || ''}`,
});

export const getListColumnsReferential = createSelector(
  getModuleState,
  (moduleState) => moduleState.get('listColumnsReferential')
);
