import authApi from 'resources/authApi';
import { notificationError, notificationSuccess } from 'actions/notifications';

import {
  TAG_ON_SHARE,
  UNTAG_ON_CHANGE,
  VALUE_ENABLE,
  WORKFLOW,
  LISTING,
  RECIPIENTS_RULE,
  VALUE_RECIPIENT_RULE_ALL,
  VALUE_RECIPIENT_RULE_RESTRICTED,
  ASSIGNATION,
} from 'constants/organization-settings';

import { createAction } from '../../../utils/actions';
import { ORGANIZATION_CREATE_FORM_UPDATE_INPUT } from '../constants/events';
import {
  selectOrgName,
  selectOrgType,
  selectOrgNetwork,
  selectOrgGroup,
  selectOrgCountries,
  selectOrgListing,
  selectOrgTagOnShare,
  selectOrgUntagOnChange,
  selectOrgRestrictedRecipients,
  selectOrgAssignation,
  selectOrgSource,
} from '../selectors/form';
import { RETAILER } from '../constants/org-types';
import {
  closeOrganizationCreateModal,
  organizationCreateModalIsLoading,
} from './modal';

export const updateFormInput = createAction(
  ORGANIZATION_CREATE_FORM_UPDATE_INPUT
);

const createNetwork = (networkName) => (dispatch) => {
  const promise = authApi.NetworkCreate(networkName);
  promise.then(
    () => {
      dispatch(
        notificationSuccess(
          `Network <${networkName}> successfully created`,
          'modal'
        )
      );
    },
    () => {
      dispatch(
        notificationError(
          `Error while creating network ${networkName}`,
          'modal'
        )
      );
      dispatch(organizationCreateModalIsLoading(false));
    }
  );
  return promise;
};

const organizationCreate = (organization) => (dispatch) => {
  return authApi.OrganizationCreate(organization).then(
    (response) => {
      dispatch(
        notificationSuccess(
          `The organization '${organization.nameLegal}' has been created`
        )
      );
      dispatch(closeOrganizationCreateModal());
      return response.data.data;
    },
    (error) => {
      dispatch(
        notificationError(
          `The creation of organization '${organization.nameLegal}' failed`
        )
      );
      dispatch(organizationCreateModalIsLoading(false));
      return error;
    }
  );
};

export const create = () => (dispatch, getState) => {
  const state = getState();
  const name = (selectOrgName(state) || '').trim();
  const type = selectOrgType(state);
  const network = selectOrgNetwork(state);
  const group = selectOrgGroup(state);
  const countries = selectOrgCountries(state);
  const listing = selectOrgListing(state);
  const tagOnShare = selectOrgTagOnShare(state);
  const untagOnChange = selectOrgUntagOnChange(state);
  const restrictedRecipients = selectOrgRestrictedRecipients(state);
  const assignation = selectOrgAssignation(state);
  const source = selectOrgSource(state);

  const org = {
    nameLegal: name,
    type: type.get('value'),
    network_id: network.get('value'),
    worksOnTargetMarkets: countries,
    group: group ? group.toJS() : null,
    advanced_settings: {},
    source,
  };

  if (type.get('value') === RETAILER.value) {
    if (!group) {
      return dispatch(
        notificationError(
          'Organization group is mandatory for retailers',
          'modal'
        )
      );
    }

    if (listing) {
      org.advanced_settings[WORKFLOW] = LISTING;
    }
    if (tagOnShare) {
      org.advanced_settings[TAG_ON_SHARE] = VALUE_ENABLE;
    }
    if (untagOnChange) {
      org.advanced_settings[UNTAG_ON_CHANGE] = VALUE_ENABLE;
    }
    if (assignation) {
      org.advanced_settings[ASSIGNATION] = VALUE_ENABLE;
    }

    org.advanced_settings[RECIPIENTS_RULE] = restrictedRecipients
      ? VALUE_RECIPIENT_RULE_RESTRICTED
      : VALUE_RECIPIENT_RULE_ALL;
  }

  if (!name) {
    return dispatch(
      notificationError('Organization name is mandatory', 'modal')
    );
  }
  if (!countries || countries.isEmpty()) {
    return dispatch(
      notificationError('Organization country is mandatory', 'modal')
    );
  }

  dispatch(organizationCreateModalIsLoading(true));

  const shouldCreateNetwork = network.get('isNewNetwork');
  if (shouldCreateNetwork === true) {
    // create network first then org
    return dispatch(createNetwork(network.get('key'))).then((response) => {
      const networkId = response.data.data.id;
      org.network_id = networkId;
      return dispatch(organizationCreate(org));
    });
  }
  return dispatch(organizationCreate(org));
};
