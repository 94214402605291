import { SearchApi } from '@alkem/sdk-dashboard';

export const immutableSearchApi = new SearchApi({
  immutable: true,
  cacheEnabled: true,
  acceptLanguage: 'en',
});

export default new SearchApi({
  cacheEnabled: true,
  acceptLanguage: 'en',
});
