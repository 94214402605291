export const BRANDCLAIMS_LOADING = 'BRANDCLAIM/BRANDCLAIMS_LOADING';
export const BRANDCLAIMS_ACTION_IN_PROGRESS =
  'BRANDCLAIM/BRANDCLAIMS_ACTION_IN_PROGRESS';
export const BRANDCLAIMS_SET_ACTIONNABLE_CLAIMS_ONLY =
  'BRANDCLAIM/BRANDCLAIMS_SET_ACTIONNABLE_CLAIMS_ONLY';
export const BRANDCLAIMS_RECEIVED = 'BRANDCLAIM/BRANDCLAIMS_RECEIVED';
export const BRANDCLAIMS_NEXT_PAGE = 'BRANDCLAIM/BRANDCLAIMS_NEXT_PAGE';
export const BRANDCLAIMS_PREVIOUS_PAGE = 'BRANDCLAIM/BRANDCLAIMS_PREVIOUS_PAGE';
export const BRANDCLAIMS_CHANGE_LIMIT = 'BRANDCLAIM/BRANDCLAIMS_CHANGE_LIMIT';
export const USERNAME_SEARCH_UPDATE = 'BRANDCLAIM/USERNAME_SEARCH_UPDATE';
export const BRAND_SEARCH_UPDATE = 'BRANDCLAIM/BRAND_SEARCH_UPDATE';
