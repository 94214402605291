import { Button } from '@alkem/react-ui-button';
import { Checkbox, Text, Textarea } from '@alkem/react-ui-inputs';
import { Modal } from '@alkem/react-ui-modal';
import { SimpleSelect } from '@alkem/react-ui-select';
import InputWithLabel from 'components/input-with-label';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { separateActions } from 'utils/redux';
import { saveEndpoint, updateEndpoint } from '../actions';
import { selectIsModalOpen, selectModalEndpoint } from '../selectors';
import { EndpointModalHandlers } from './handlers';
import './modal.scss';

const mapStateToProps = (state) => ({
  endpoint: selectModalEndpoint(state),
  isOpen: selectIsModalOpen(state),
});

const mapDispatchToProps = {
  updateEndpoint,
  saveEndpoint,
};

const endpointAuthMethodTypes = [
  {
    value: 'key',
    label: 'Public key',
  },
  {
    value: 'password',
    label: 'Password',
  },
];

const endpointRemoteAuthMethodTypes = [
  {
    value: 'key',
    label: 'Private key',
  },
  {
    value: 'password',
    label: 'Password',
  },
  {
    value: 'keyandpassword',
    label: 'Key & Password',
  },
];

const endpointTypes = [
  {
    value: 0,
    label: 'Salsify SupplierXM SFTP',
  },
  {
    value: 1,
    label: 'External',
  },
  {
    value: 3,
    label: 'Erudus API',
  },
  {
    value: 4,
    label: 'Amazon AFS',
  },
  {
    value: 5,
    label: 'BrandBank SOAP',
  },
];

export class EndpointModal extends Component {
  static propTypes = {
    endpoint: PropTypes.object,
    onCloseModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      updateEndpoint: PropTypes.func.isRequired,
      saveEndpoint: PropTypes.func.isRequired,
    }).isRequired,
  };

  onUpdateEndpointActive = () => {
    this.props.actions.updateEndpoint(
      ['active'],
      !this.props.endpoint.get('active')
    );
  };

  onUpdateEndpointConfirm = () => {
    this.props.actions.updateEndpoint(
      ['config', 'confirm'],
      !this.props.endpoint.getIn(['config', 'confirm'])
    );
  };

  onUpdateEndpointName = (e) => {
    this.props.actions.updateEndpoint(['name'], e.target.value);
  };

  onUpdateEndpointDescription = (e) => {
    this.props.actions.updateEndpoint(['description'], e.target.value);
  };

  onUpdateEndpointUsername = (e) => {
    this.props.actions.updateEndpoint(['username'], e.target.value);
  };

  onChangeEndpointType = (e) => {
    this.props.actions.updateEndpoint(['type'], e.value);
  };

  onChangeLocalEndpointAuthMethodType = (e) => {
    this.props.actions.updateEndpoint(['config', 'method'], e.value);
  };

  onUpdateEndpointPassword = (e) => {
    this.props.actions.updateEndpoint(['config', 'password'], e.target.value);
  };

  onUpdateRemoteEndpointSecret = (e) => {
    this.props.actions.updateEndpoint(['config', 'secret'], e.target.value);
  };

  onUpdateRemoteEndpointSecretPassword = (e) => {
    this.props.actions.updateEndpoint(
      ['config', 'secret_password'],
      e.target.value
    );
  };

  isValidUsername = (endpoint) => {
    if (endpoint.get('type') === 0) {
      if (endpoint.get('username').match(/ext_[0-9]+_[0-9]+$/i)) {
        return true;
      }
      return false;
    }
    return true;
  };

  onChangeOrganizationId = (event) => {
    this.props.actions.updateEndpoint(
      ['organization', 'id'],
      event.target.value
    );
  };

  onChangeHostName = (event) => {
    this.props.actions.updateEndpoint(
      ['config', 'hostname'],
      event.target.value
    );
  };

  onChangePort = (event) => {
    this.props.actions.updateEndpoint(['config', 'port'], event.target.value);
  };

  onChangeText = (path, i) => (event) => {
    this.props.actions.updateEndpoint([...path, i], event.target.value);
  };

  onChangeSimpleText = (path) => (event) => {
    this.props.actions.updateEndpoint([...path], event.target.value);
  };

  onClickDeleteButton = (list, path, i) => () => {
    this.props.actions.updateEndpoint(path, list.delete(i));
  };

  onClickAddButton = (list, path) => () => {
    this.props.actions.updateEndpoint(path, list.push(''));
  };

  onChangeSshKeys = (i) => (event) => {
    this.props.actions.updateEndpoint(
      ['config', 'ssh_keys', i],
      event.target.value
    );
  };

  onDeleteSshKey = (sshKeys, i) => () => {
    this.props.actions.updateEndpoint(
      ['config', 'ssh_keys'],
      sshKeys.delete(i)
    );
  };

  onAddSshKey = (sshKeys) => () => {
    this.props.actions.updateEndpoint(['config', 'ssh_keys'], sshKeys.push(''));
  };

  renderGeneralInformation() {
    const { endpoint } = this.props;
    return (
      <div className="EndpointModal__Form">
        <h4>General configuration</h4>
        <div className="EndpointModal__Line">
          <InputWithLabel
            inputId={`endpoint-${endpoint.get('id')}-name`}
            label="Name"
          >
            <Text
              id={`endpoint-${endpoint.get('id')}-name`}
              value={endpoint.get('name')}
              placeholder="name"
              onChange={this.onUpdateEndpointName}
            />
          </InputWithLabel>
        </div>
        <div className="EndpointModal__Line">
          <InputWithLabel
            inputId={`endpoint-${endpoint.get('id')}-description`}
            label="Description"
          >
            <Textarea
              id={`endpoint-${endpoint.get('id')}-description`}
              value={endpoint.get('description')}
              placeholder="description"
              onChange={this.onUpdateEndpointDescription}
              rows={3}
            />
          </InputWithLabel>
        </div>
        <div className="EndpointModal__Line">
          <InputWithLabel
            inputId={`endpoint-${endpoint.get('id')}-organization-id`}
            label="Organization"
          >
            <Text
              id={`endpoint-${endpoint.get('id')}-organization-id`}
              value={endpoint.getIn(['organization', 'id'])}
              placeholder="id"
              onChange={this.onChangeOrganizationId}
            />
          </InputWithLabel>
        </div>
        <div className="EndpointModal__Line">
          <InputWithLabel
            inputId={`endpoint-${endpoint.get('id')}-active`}
            label=""
          >
            <Checkbox
              id={`endpoint-${endpoint.get('id')}-active`}
              checked={endpoint.get('active')}
              onChange={this.onUpdateEndpointActive}
              label={<div className="EndpointModal_Checkbox">Active</div>}
            />
          </InputWithLabel>
        </div>
        <div
          className={`EndpointModal__Line ${
            this.isValidUsername(endpoint) ? '' : 'EndpointModal__LineRemoved'
          }`}
        >
          <InputWithLabel
            inputId={`endpoint-${endpoint.get('id')}-username`}
            label="Username"
          >
            <Text
              id={`endpoint-${endpoint.get('id')}-username`}
              value={endpoint.get('username')}
              placeholder="username"
              onChange={this.onUpdateEndpointUsername}
            />
          </InputWithLabel>
        </div>
      </div>
    );
  }

  renderEndpointConfigurationRemote() {
    const { endpoint } = this.props;
    let endpointLocalAuthSecret;

    if (
      endpoint.getIn(['config', 'method']) === 'key' ||
      endpoint.getIn(['config', 'method']) === 'keyandpassword'
    ) {
      endpointLocalAuthSecret = (
        <Textarea
          id={`endpoint-${endpoint.get('id')}-remote-secret-ta`}
          value={endpoint.getIn(['config', 'secret'])}
          placeholder="SSH private key"
          onChange={this.onUpdateRemoteEndpointSecret}
          rows={3}
        />
      );
    } else if (endpoint.getIn(['config', 'method']) === 'password') {
      endpointLocalAuthSecret = (
        <Text
          id={`endpoint-${endpoint.get('id')}-remote-secret`}
          value={endpoint.getIn(['config', 'secret'])}
          placeholder="password"
          onChange={this.onUpdateRemoteEndpointSecret}
        />
      );
    }

    return (
      <div className="EndpointModal__Form">
        <div className="EndpointModal__Line">
          <InputWithLabel
            inputId={`endpoint-${endpoint.get('id')}-config-hostname`}
            label="Hostname"
          >
            <Text
              id={`endpoint-${endpoint.get('id')}-config-hostname`}
              value={endpoint.getIn(['config', 'hostname'])}
              placeholder="address"
              onChange={this.onChangeHostName}
            />
          </InputWithLabel>
        </div>
        <div className="EndpointModal__Line">
          <InputWithLabel
            inputId={`endpoint-${endpoint.get('id')}-confirm`}
            label=""
          >
            <Checkbox
              id={`endpoint-${endpoint.get('id')}-confirm`}
              checked={endpoint.getIn(['config', 'confirm'])}
              onChange={this.onUpdateEndpointConfirm}
              label={<div className="EndpointModal_Checkbox">Confirm</div>}
            />
          </InputWithLabel>
        </div>
        <div className="EndpointModal__Line">
          <InputWithLabel
            inputId={`endpoint-${endpoint.get('id')}-config-port`}
            label="port"
          >
            <Text
              id={`endpoint-${endpoint.get('id')}-config-port`}
              value={endpoint.getIn(['config', 'port'])}
              placeholder="port"
              onChange={this.onChangePort}
            />
          </InputWithLabel>
        </div>
        <div className="EndpointModal__Line">
          <InputWithLabel
            inputId={`endpoint-${endpoint.get('id')}-config-remoteauth-method`}
            label="ACL Method"
          >
            <SimpleSelect
              id="EndpointModalType"
              options={endpointRemoteAuthMethodTypes}
              value={endpointRemoteAuthMethodTypes.find(
                (o) => o.value === endpoint.getIn(['config', 'method'])
              )}
              onSelect={this.onChangeLocalEndpointAuthMethodType}
              autoSize
            />
          </InputWithLabel>
        </div>
        <div className="EndpointModal__Line">
          <InputWithLabel
            inputId={`endpoint-${endpoint.get('id')}-config-remoteauth-secret`}
            label="ACL Secret"
          >
            {endpointLocalAuthSecret}
          </InputWithLabel>
        </div>
        {endpoint.getIn(['config', 'method']) === 'keyandpassword' && (
          <InputWithLabel
            inputId={`endpoint-${endpoint.get(
              'id'
            )}-config-remoteauth-secret-password`}
            label="ACL Password"
          >
            <Text
              id={`endpoint-${endpoint.get('id')}-remote-secret-password`}
              value={endpoint.getIn(['config', 'secret_password'])}
              placeholder="password"
              onChange={this.onUpdateRemoteEndpointSecretPassword}
            />
          </InputWithLabel>
        )}
        <div className="EndpointModal__Line">
          <InputWithLabel
            inputId={`endpoint-${endpoint.get('id')}-config-kex`}
            label="kex"
          >
            {this.renderTextList(['config', 'kex'], 'kex', 'kex')}
          </InputWithLabel>
        </div>
      </div>
    );
  }

  renderTextList(path, tag, desc) {
    const { endpoint } = this.props;
    const list = endpoint.getIn(path);
    const listJSX = list.map((entry, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={`endpoint-${tag}-${i}`} className="row">
        <div className="col-xs-10">
          <Text
            id={`endpoint-${i}-${tag}`}
            value={entry}
            placeholder={desc}
            onChange={this.onChangeText(path, i)}
            rows={2}
          />
        </div>
        <div className="col-xs-2">
          <Button link onClick={this.onClickDeleteButton(list, path, i)}>
            Delete
          </Button>
        </div>
      </div>
    ));
    return (
      <div>
        {listJSX}
        <div className="EndpointModalHandler__AddHandler">
          <Button
            className="EndpointModal_LinkButton"
            link
            onClick={this.onClickAddButton(list, path)}
          >
            (+) Add
          </Button>
        </div>
      </div>
    );
  }

  renderEndpointConfigurationLocal() {
    const { endpoint } = this.props;
    let endpointLocalAuthSecret;

    if (endpoint.getIn(['config', 'method']) === 'password') {
      endpointLocalAuthSecret = (
        <Text
          id={`endpoint-${endpoint.get('id')}-password`}
          value={endpoint.getIn(['config', 'password'])}
          placeholder="password"
          onChange={this.onUpdateEndpointPassword}
        />
      );
    } else if (endpoint.getIn(['config', 'method']) === 'key') {
      const sshKeys = endpoint.getIn(['config', 'ssh_keys']);
      const endpointLocalAuthSecretKeys = sshKeys.map((entry, i) => (
        <div key={entry} className="row">
          <div className="col-xs-10">
            <Textarea
              id={`endpoint-${i}-pubkey`}
              value={entry}
              placeholder="public key"
              onChange={this.onChangeSshKeys(i)}
              rows={2}
            />
          </div>
          <div className="col-xs-2">
            <Button link onClick={this.onDeleteSshKey(sshKeys, i)}>
              Delete
            </Button>
          </div>
        </div>
      ));
      endpointLocalAuthSecret = (
        <div>
          {endpointLocalAuthSecretKeys}
          <div className="EndpointModalHandler__AddHandler">
            <Button
              className="EndpointModal_LinkButton"
              link
              onClick={this.onAddSshKey(sshKeys)}
            >
              (+) Add
            </Button>
          </div>
        </div>
      );
    }

    return (
      <div className="EndpointModal__Form">
        <div className="EndpointModal__Line">
          <InputWithLabel
            inputId={`endpoint-${endpoint.get('id')}-config-localauth-method`}
            label="ACL Method"
          >
            <SimpleSelect
              id="EndpointModalType"
              options={endpointAuthMethodTypes}
              value={endpointAuthMethodTypes.find(
                (o) => o.value === endpoint.getIn(['config', 'method'])
              )}
              onSelect={this.onChangeLocalEndpointAuthMethodType}
              autoSize
            />
          </InputWithLabel>
        </div>
        <div className="EndpointModal__Line">
          <InputWithLabel
            inputId={`endpoint-${endpoint.get('id')}-config-localauth-secret`}
            label="ACL Secret"
          >
            {endpointLocalAuthSecret}
          </InputWithLabel>
        </div>
        <div className="EndpointModal__Line">
          <InputWithLabel
            inputId={`endpoint-${endpoint.get('id')}-config-extra-event-types`}
            label="Extra event type"
          >
            {this.renderTextList(
              ['config', 'extra_event_types'],
              'extra_event_types',
              'Extra event type'
            )}
          </InputWithLabel>
        </div>
      </div>
    );
  }

  renderEndpointConfigurationErudus() {
    const { endpoint } = this.props;
    return (
      <div className="EndpointModal__Form">
        <div className="EndpointModal__Line">
          <InputWithLabel
            inputId={`endpoint-${endpoint.get('id')}-config-erudus-base-url`}
            label="Base URL"
          >
            <Text
              id={`endpoint-${endpoint.get('id')}-config-erudus-base-url`}
              value={endpoint.getIn(['config', 'base_url'])}
              placeholder="base url"
              onChange={this.onChangeSimpleText(['config', 'base_url'])}
            />
          </InputWithLabel>
        </div>
        <div className="EndpointModal__Line">
          <InputWithLabel
            inputId={`endpoint-${endpoint.get('id')}-config-erudus-id`}
            label="Client ID"
          >
            <Text
              id={`endpoint-${endpoint.get('id')}-config-erudus-id`}
              value={endpoint.getIn(['config', 'client_id'])}
              placeholder="client id"
              onChange={this.onChangeSimpleText(['config', 'client_id'])}
            />
          </InputWithLabel>
        </div>
        <div className="EndpointModal__Line">
          <InputWithLabel
            inputId={`endpoint-${endpoint.get('id')}-config-erudus-secret`}
            label="Client Secret"
          >
            <Text
              id={`endpoint-${endpoint.get('id')}-config-erudus-secret`}
              value={endpoint.getIn(['config', 'client_secret'])}
              placeholder="client secret"
              onChange={this.onChangeSimpleText(['config', 'client_secret'])}
            />
          </InputWithLabel>
        </div>
      </div>
    );
  }

  renderEndpointConfigurationBrandBank() {
    const { endpoint } = this.props;
    return (
      <div className="EndpointModal__Form">
        <div className="EndpointModal__Line">
          <InputWithLabel
            inputId={`endpoint-${endpoint.get('id')}-config-brandbank-token`}
            label="Token"
          >
            <Text
              id={`endpoint-${endpoint.get('id')}-config-brandbank-token`}
              value={endpoint.getIn(['config', 'token'])}
              placeholder="token"
              onChange={this.onChangeSimpleText(['config', 'token'])}
            />
          </InputWithLabel>
        </div>
      </div>
    );
  }

  renderEndpointConfiguration() {
    const { endpoint } = this.props;
    let endpointConfigurator = null;
    if (endpoint.get('type') === 0) {
      endpointConfigurator = this.renderEndpointConfigurationLocal();
    } else if (endpoint.get('type') === 1) {
      endpointConfigurator = this.renderEndpointConfigurationRemote();
    } else if (endpoint.get('type') === 3) {
      endpointConfigurator = this.renderEndpointConfigurationErudus();
    } else if (endpoint.get('type') === 4) {
      endpointConfigurator = null;
    } else if (endpoint.get('type') === 5) {
      endpointConfigurator = this.renderEndpointConfigurationBrandBank();
    }

    return (
      <div className="EndpointModal__Form">
        <h4>Technical configuration</h4>
        <div className="EndpointModal__Line">
          <InputWithLabel
            inputId={`endpoint-${endpoint.get('id')}-type`}
            label="Type"
          >
            <SimpleSelect
              id="EndpointModalType"
              options={endpointTypes}
              value={endpointTypes.find(
                (o) => o.value === endpoint.get('type')
              )}
              onSelect={this.onChangeEndpointType}
              autoSize
            />
          </InputWithLabel>
        </div>
        {endpointConfigurator}
      </div>
    );
  }

  render() {
    const { actions, onCloseModal, endpoint, isOpen } = this.props;
    if (!isOpen) {
      return null;
    }
    const title = endpoint.get('id')
      ? `Updating endpoint ${endpoint.get('name')} (${endpoint.get('id')})`
      : 'New endpoint';
    return (
      <Modal
        modalStyle="fullHeight"
        title={title}
        className="EndpointModal"
        confirmButtonText="Save"
        onConfirm={actions.saveEndpoint}
        onClose={onCloseModal}
      >
        {this.renderGeneralInformation()}
        <hr />
        {this.renderEndpointConfiguration()}
        <hr />
        <EndpointModalHandlers
          endpoint={this.props.endpoint}
          updateEndpoint={this.props.actions.updateEndpoint}
        />
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(EndpointModal);
