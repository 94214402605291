import React from 'react';
import { LegacyAutocomplete, AutocompleteProps } from '../legacy-autocomplete';

type Props = {
  pattern?: (search: string) => RegExp;
  field?: (item: any) => any;
} & Omit<AutocompleteProps, 'onSearch'>;

export const RegExpAutocomplete = React.memo(function RegExpAutocomplete({
  list,
  pattern = (search) => new RegExp(`^${search}`, 'i'),
  field = (item) => item.label,
  transformData = (data) => data,
  ...props
}: Props) {
  const onSearch = (search) => {
    const filter = pattern(search);
    if (search && list) {
      return list.filter((item) => filter.test(field(item)));
    }
    return list;
  };

  return (
    <LegacyAutocomplete
      {...props}
      transformData={transformData}
      onSearch={onSearch}
    />
  );
});
