export const INIT = 'organization-groups/INIT';
export const FETCH_GROUPS = 'organization-groups/FETCH_GROUPS';
export const UPDATE_SEARCH = 'organization-groups/UPDATE_SEARCH';
export const RECEIVE_GROUPS = 'organization-groups/RECEIVE_GROUPS';
export const EDIT = 'organization-groups/EDIT';
export const UPDATE_NAME = 'organization-groups/UPDATE_NAME';
export const UPLOAD_PICTURE = 'organization-groups/UPLOAD_PICTURE';
export const SET_UPLOADING_PICTURE =
  'organization-groups/SET_UPLOADING_PICTURE';
export const SAVE = 'organization-groups/SAVE';
export const SET_SAVING = 'organization-groups/SET_SAVING';
