import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@alkem/react-ui-spinner';
import EmptyState from '@alkem/react-ui-empty-state';
import { connect } from 'react-redux';

import './table-result.scss';
import TableResult from './table-result';

import {
  selectReportingTable,
  getIsLoading,
} from '../selectors/reporting-table';

import {
  DASHBOARD_TYPE_1,
  DASHBOARD_TYPE_2,
  DASHBOARD_TYPE_3,
} from '../constants/dashboard';

const mapStateToProps = (state) => ({
  isLoading: getIsLoading(state),
  reportingTable: selectReportingTable(state),
});

export class ReportingResult extends PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool,
    reportingTable: PropTypes.object,
  };

  extractColumnsFromMetas(metas) {
    return metas.reduce((acc, meta) => {
      if (meta.columns) {
        meta.columns.map((col) => {
          const colKey = `${meta.key}.${col}`;
          let filter = {};
          if (meta.filter[col] !== undefined) {
            filter = meta.filter[col];
          } else if (meta.filter['%(value)s'] !== undefined) {
            filter = JSON.parse(
              JSON.stringify(meta.filter['%(value)s']).replace(
                /%\(value\)s/g,
                col
              )
            );
          }
          acc[colKey] = {
            cell: meta.cell,
            filter,
            label: meta.columns_label[colKey],
            type: meta.type,
          };
          return acc;
        });
      } else {
        acc[meta.key] = {
          cell: meta.cell,
          filter: meta.filter,
          label: meta.columns_label[meta.key],
          type: meta.type,
        };
      }
      return acc;
    }, {});
  }

  renderResult() {
    const report = this.props.reportingTable.toJS();
    let reportDataDetail;
    let reportDataAll;
    if (report.type === DASHBOARD_TYPE_1) {
      reportDataDetail = report.aggregations;
    } else if (report.type === DASHBOARD_TYPE_2) {
      reportDataDetail = report.aggregations.detail;
      reportDataAll = report.aggregations.all;
    } else if (report.type === DASHBOARD_TYPE_3) {
      if (report.aggregations.detail) {
        reportDataDetail = report.aggregations.detail;
        reportDataAll = report.aggregations.all;
      } else {
        reportDataDetail = report.aggregations;
      }
    }

    const columns = this.extractColumnsFromMetas(reportDataDetail.meta);

    return (
      <TableResult
        columns={columns}
        reportDataDetail={reportDataDetail}
        reportDataAll={reportDataAll}
      />
    );
  }

  render() {
    if (this.props.isLoading) {
      return (
        <center style={{ paddingTop: '100px' }}>
          <Spinner loading big />
        </center>
      );
    }
    if (!this.props.reportingTable || this.props.reportingTable.size === 0) {
      return (
        <EmptyState
          title="No report generated"
          text="Use the filter on the left to generate a report"
        />
      );
    }
    return this.renderResult();
  }
}

export default connect(mapStateToProps)(ReportingResult);
