import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as routes from 'constants/routes';

import { HeaderLayout } from '@alkem/react-layout';
import { TwinPanel } from '@alkem/react-ui-twinpanel';

import { Xpath } from '../types';

import './gdsn-export-mappings-view.scss';
import { ElementSelector } from '../components/element-selector';
import {
  GET_CUSTOM_PARSE_LIST,
  GET_FUNCTION_VALUE_LIST,
  GET_XPATH_LIST,
  GET_EXPORT_MAPPING_LIST,
  GET_FUNCTION_NOT_VALUE_LIST,
} from '../actions/constants';
import { selectXpathList } from '../selectors';
import { ExportMappingsList } from '../components/export-mappings';

const headerProps = {
  title: 'GDSN Export Mappings',
  backHref: routes.home,
  backMessage: 'Back home',
  isTitleSmall: true,
};

export default function GDSNExportMappingsView() {
  const dispatch = useDispatch();
  const xpathList = useSelector(selectXpathList);
  const [element, setElement] = useState<Xpath | undefined>(undefined);

  useEffect(() => {
    dispatch({ type: GET_XPATH_LIST });
    dispatch({ type: GET_CUSTOM_PARSE_LIST });
    dispatch({ type: GET_FUNCTION_VALUE_LIST });
    dispatch({ type: GET_FUNCTION_NOT_VALUE_LIST });
  }, [dispatch]);

  const updateElement = (xpath: Xpath) => {
    setElement(xpath);
    dispatch({ type: GET_EXPORT_MAPPING_LIST, xpath });
  };

  return (
    <div className="GDSNExportMappingsView__body">
      <HeaderLayout {...headerProps} />
      <div className="GDSNExportMappingsView__content">
        <TwinPanel
          leftPanel={{
            title: 'Select an element',
            content: (
              <div className="GDSNExportMappingsView__elementSelectorContainer">
                <ElementSelector
                  elements={xpathList}
                  selectElement={updateElement}
                  selectedElement={element}
                />
              </div>
            ),
            className: 'GDSNExportMappingsView__panel--left',
          }}
          rightPanel={{
            title: element?.name || 'No element selected',
            content: (
              <div className="GDSNExportMappingsView__exportMappingsContainer">
                {element && (
                  <ExportMappingsList
                    key={`export_mappings_list_${element.id}`}
                    element={element}
                  />
                )}
              </div>
            ),
            className: 'GDSNExportMappingsView__panel--right',
          }}
        />
      </div>
    </div>
  );
}
