export const SELECT_ORGANIZATION =
  'organization-uses-fields/SELECT_ORGANIZATION';
export const IMPORT_ORGANIZATION =
  'organization-uses-fields/IMPORT_ORGANIZATION';
export const UPDATE_ORGANIZATION_FIELDS =
  'organization-uses-fields/UPDATE_ORGANIZATION_FIELDS';
export const ORGANIZATION_SAVED = 'organization-uses-fields/ORGANIZATION_SAVED';

export const FETCH_RULES = 'organization-uses-fields/FETCH_RULES';
export const RECEIVE_RULES = 'organization-uses-fields/RECEIVE_RULES';
export const FETCH_RULES_DONE = 'organization-uses-fields/FETCH_RULES_DONE';
export const CREATE_RULE = 'organization-uses-fields/CREATE_RULE';
export const UPDATE_RULES = 'organization-uses-fields/UPDATE_RULES';
export const DELETE_RULES = 'organization-uses-fields/DELETE_RULES';
export const UPDATE_RULESETS = 'organization-uses-fields/UPDATE_RULESETS';

export const REFERENTIALS_FETCHED =
  'organization-uses-fields/REFERENTIALS_FETCHED';
export const TOGGLE_REFERENTIAL = 'organization-uses-fields/TOGGLE_REFERENTIAL';
export const SELECT_ROOT_NODE = 'organization-uses-fields/SELECT_ROOT_NODE';
export const REFERENTIAL_SAVED = 'organization-uses-fields/REFERENTIAL_SAVED';
export const REFERENTIALS_IMPORTED =
  'organization-uses-fields/REFERENTIALS_IMPORTED';
export const SUPPLIER_REFERENTIALS_FETCHING =
  'organization-uses-fields/SUPPLIER_REFERENTIALS_FETCHING';
export const SUPPLIER_REFERENTIALS_FETCHED =
  'organization-uses-fields/SUPPLIER_REFERENTIALS_FETCHED';

export const START_SAVING = 'organization-uses-fields/START_SAVING';
export const STOP_SAVING = 'organization-uses-fields/STOP_SAVING';
export const START_IMPORTING = 'organization-uses-fields/START_IMPORTING';
export const STOP_IMPORTING = 'organization-uses-fields/STOP_IMPORTING';
export const START_ADDING = 'organization-uses-fields/START_ADDING';
export const STOP_ADDING = 'organization-uses-fields/STOP_ADDING';
export const START_BRANCHING = 'organization-uses-fields/START_BRANCHING';
export const STOP_BRANCHING = 'organization-uses-fields/STOP_BRANCHING';
export const START_UPDATING = 'organization-uses-fields/START_UPDATING';
export const STOP_UPDATING = 'organization-uses-fields/STOP_UPDATING';

export const START_LOADING_FIELDS_WITH_RULES =
  'organization-uses-fields/START_LOADING_FIELDS_WITH_RULES';
export const RECEIVE_FIELDS_FOR_FIELD_TYPE =
  'organization-uses-fields/RECEIVE_FIELDS_FOR_FIELD_TYPE';
export const START_LOADING_FIELDS_AND_RESTRICTIONS =
  'organization-uses-fields/START_LOADING_FIELDS_AND_RESTRICTIONS';
