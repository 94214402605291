import { notificationError, notificationSuccess } from 'actions/notifications';
import { getFieldSuggestionList } from 'modules/field-suggestion/actions';
import {
  call,
  put,
  putResolve,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import ClassificationApi from 'resources/classificationApi';
import { request } from 'utils/api';
import { getIn } from 'utils/immutable';
import * as events from './events';
import { selectList } from './selectors';

function* refreshFieldSuggestionDashboard(resetPage = false) {
  yield putResolve(getFieldSuggestionList(resetPage));
}

function* validateSingleSuggestion({ entity, validationStatus }) {
  const body = {
    data: {
      product_id: entity.getIn(['metadata', 'product_id']),
      owner_id: entity.getIn(['metadata', 'contentowner', 'id']),
      fieldmetadata_id: entity.getIn(['field', 'fieldmetadata_id']),
      field_id: entity.getIn(['field', 'field_id']),
      field_name: entity.getIn(['field', 'field_name']),
    },
    es_id: entity.get('id'),
    validate: validationStatus,
  };

  const { error } = yield call(
    request,
    ClassificationApi,
    'ValidateFieldSuggestion',
    body
  );
  const event = {
    type: events.FIELD_SUGGESTION_VALIDATION_PROGRESS,
    entityId: entity.get('id'),
  };
  if (error) {
    let err;
    if (error.status === 400) {
      err = `There is a problem with the given information: ${getIn(
        error,
        'data.message'
      )}`;
    } else if (error.status === 403) {
      err = `You do not have the rights to edit this entity: ${getIn(
        error,
        'data.message'
      )}`;
    } else {
      err = `Something went wrong while saving the entity: ${getIn(
        error,
        'data.message'
      )}`;
    }
    event.error = { entity, error: err };
  }
  yield put(event);
  return error;
}

export function* bulkValidateSuggestions(validationStatus) {
  // Iterate on each selected suggestion entity.
  let checkedEntities = yield select(selectList);
  checkedEntities = checkedEntities.filter((e) => e.get('checked'));

  if (!checkedEntities || checkedEntities.isEmpty()) {
    return;
  }

  for (const entity of checkedEntities) {
    yield call(validateSingleSuggestion, { entity, validationStatus });
  }
  yield put({ type: events.FIELD_SUGGESTION_VALIDATION_COMPLETE });
}

function* validateSuggestion({ entity, validationStatus }) {
  const failed = yield call(validateSingleSuggestion, {
    entity,
    validationStatus,
  });

  if (failed) {
    yield put(notificationError('The suggestion validation has failed'));
  } else {
    yield put(notificationSuccess('Successfully validated one suggestion'));
    yield call(refreshFieldSuggestionDashboard);
  }
}

function* cancelableBulkValidateSuggestions(action) {
  yield race({
    task: call(bulkValidateSuggestions, action.validationStatus),
    cancel: take(events.FIELD_SUGGESTION_CLOSE_MODAL),
  });
}

function* editSingleSuggestion({ fieldmetadata, field, entity }) {
  const body = {
    edited_entity: {
      features: entity.toJS().features,
      field: {
        probability: 1,
        is_current_value: 0,
        field_id: field.id,
        field_name: field.label,
        fieldmetadata_id: fieldmetadata.id,
        fieldmetadata_name: fieldmetadata.label,
        status: 1,
      },
      metadata: entity.toJS().metadata,
    },
  };

  const { error } = yield call(
    request,
    ClassificationApi,
    'EditFieldSuggestion',
    body
  );

  const event = {
    type: events.FIELD_SUGGESTION_EDITION_PROGRESS,
    entityId: entity.get('id'),
  };
  if (error) {
    let err;
    if (error.status === 400) {
      err = `There is a problem with the given information: ${getIn(
        error,
        'data.message'
      )}`;
    } else if (error.status === 403) {
      err = `You do not have the rights to edit this entity: ${getIn(
        error,
        'data.message'
      )}`;
    } else {
      err = `Something went wrong while saving the entity: ${getIn(
        error,
        'data.message'
      )}`;
    }
    event.error = { entity, error: err };
  }
  yield put(event);
  return error;
}

export function* bulkEditSuggestions(fieldmetadata, field) {
  // Iterate on each selected suggestion entity.
  let checkedEntities = yield select(selectList);
  checkedEntities = checkedEntities.filter((e) => e.get('checked'));
  if (!checkedEntities || checkedEntities.isEmpty()) {
    return;
  }
  for (const entity of checkedEntities) {
    yield call(editSingleSuggestion, {
      fieldmetadata,
      field,
      entity,
    });
  }

  yield put({ type: events.FIELD_SUGGESTION_EDITION_COMPLETE });
}

function* cancelableBulkEditSuggestions(action) {
  yield race({
    task: call(bulkEditSuggestions, action.fieldmetadata, action.field),
    cancel: take(events.FIELD_SUGGESTION_CLOSE_MODAL),
  });
}

export function* FieldSuggestionDashboardMainSaga() {
  yield takeEvery(
    [
      events.FIELD_SUGGESTION_ADD_FILTER,
      events.FIELD_SUGGESTION_REMOVE_FILTER,
      events.FIELD_SUGGESTION_CLEAR_FILTERS,
      events.FIELD_SUGGESTION_UPDATE_SEARCH,
      events.TOGGLE_SHOW_HIERARCHY_CHILDREN,
    ],
    refreshFieldSuggestionDashboard.bind(this, true)
  );
  yield takeEvery(
    [
      events.FIELD_SUGGESTION_CLOSE_MODAL,
      events.FIELD_SUGGESTION_NEXT_PAGE,
      events.FIELD_SUGGESTION_PREVIOUS_PAGE,
      events.FIELD_SUGGESTION_CHANGE_LIMIT,
      events.FIELD_SUGGESTION_CHANGE_SORTING,
      events.FIELD_SUGGESTION_EDITION_COMPLETE,
    ],
    refreshFieldSuggestionDashboard.bind(this, false)
  );
  yield takeLatest(
    events.FIELD_SUGGESTION_BULK_VALIDATION,
    cancelableBulkValidateSuggestions
  );
  yield takeEvery(
    events.FIELD_SUGGESTION_SINGLE_VALIDATION,
    validateSuggestion
  );
  yield takeLatest(
    events.FIELD_SUGGESTION_BULK_EDITION,
    cancelableBulkEditSuggestions
  );
}
