import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { HelpTooltip } from '@alkem/react-ui-helptooltip';
import { Radio } from '@alkem/react-ui-inputs';

import { separateActions } from 'utils/redux';
import { updateSettingValue } from '../../../actions/settings';
import {
  selectSettings,
  selectEditedSettings,
} from '../../../selectors/settings';

import './enum-radio.scss';

const mapStateToProps = (state) => ({
  settings: selectSettings(state),
  editedSettings: selectEditedSettings(state),
});

const mapDispatchToProps = {
  updateSettingValue,
};

export class OrganizationPageSettingsEnumRadioController extends PureComponent {
  static propTypes = {
    label: PropTypes.string,
    help: PropTypes.string,
    path: PropTypes.arrayOf(PropTypes.string).isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
          .isRequired,
        label: PropTypes.string.isRequired,
        help: PropTypes.string,
      }).isRequired
    ).isRequired,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    settings: ImmutablePropTypes.map.isRequired,
    editedSettings: ImmutablePropTypes.map.isRequired,
    disabled: PropTypes.bool,
    actions: PropTypes.shape({
      updateSettingValue: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.htmlName = props.path.join('.');
  }

  onChange = (event) => {
    const { actions, path } = this.props;
    actions.updateSettingValue(path, event.target.value);
  };

  getCurrentValue() {
    const { defaultValue, settings, editedSettings, path } = this.props;

    const editedValue = editedSettings.getIn(path);
    if (editedValue != null) {
      return editedValue;
    }

    const sourceValue = settings.getIn(path);
    if (sourceValue != null) {
      return sourceValue;
    }

    return defaultValue;
  }

  formatOption = (option) => ({
    value: option.value,
    label: (
      <span>
        {`${option.label} `}
        {!!option.help && (
          <HelpTooltip
            id={`${this.htmlName}-${option.value}`}
            message={option.help}
          />
        )}
      </span>
    ),
  });

  render() {
    const { label, help, options, disabled } = this.props;
    const classes = {
      OrganizationPageSettingEnumRadioController__radio: true,
      'OrganizationPageSettingEnumRadioController__radio--withLabel': !!label,
    };

    return (
      <div className="OrganizationPageSettingEnumRadioController">
        {label && (
          <span className="OrganizationPageSettingEnumRadioController__label">
            {`${label} `}
            {help && (
              <HelpTooltip id={`${this.htmlName}-help`} message={help} />
            )}
          </span>
        )}
        <div className={classNames(classes)}>
          <Radio
            id={`${this.htmlName}-radio`}
            value={this.getCurrentValue()}
            options={options.map(this.formatOption)}
            onChange={this.onChange}
            disabled={disabled}
            vertical
          />
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(OrganizationPageSettingsEnumRadioController);
