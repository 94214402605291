import { useDispatch } from 'react-redux';

import { ImportMappingsItemOptionalPropertyDefaultValue } from './default-value';
import { ImportMappingsItemOptionalPropertyUnitString } from './unit-string';
import { ImportMappingsItemOptionalPropertyPredicate } from './predicate';
import { ImportMappingsItemOptionalPropertyCustomParse } from './custom-parse';
import { ImportMappingsItemOptionalPropertyMustKeepHash } from './must-keep-hash';
import { ImportMappingsItemOptionalPropertySkippableReferentialValues } from './skippable-referential-values';
import { ImportMappingsItemOptionalPropertyDiscardableReferentialValues } from './discardable-referential-values';
import { SET_IMPORT_MAPPING_PROPERTY } from '../../../actions/constants';
import { IMPORT_MAPPING_TYPES_WITHOUT_OPTIONAL_PROPERTIES } from '../../../constants';
import { ImportMapping, ImportMappingDetails, Property } from '../../../types';

import './item-optional-properties.scss';
import { ImportMappingsItemOptionalPropertyMustRaiseError } from './must-raise-error';
import { getUpdatedImportMapping } from '../../../utils';
interface Props {
  details: ImportMappingDetails;
  fieldName: string;
  importMapping: ImportMapping;
  parentNames?: string[];
  parents?: ImportMappingDetails[];
}
export function ImportMappingsItemOptionalProperties({
  details,
  fieldName,
  importMapping,
  parentNames = [],
  parents = [],
}: Props): JSX.Element {
  const dispatch = useDispatch();
  const type = details.cast;
  if (!type || IMPORT_MAPPING_TYPES_WITHOUT_OPTIONAL_PROPERTIES.includes(type))
    return <div />;

  const dispatchImportMapping = (properties: Property[]) => {
    const updatedImportMapping = getUpdatedImportMapping({
      details,
      fieldName,
      importMapping,
      parentNames,
      parents,
      properties,
    });

    dispatch({
      type: SET_IMPORT_MAPPING_PROPERTY,
      payload: {
        importMapping: { ...updatedImportMapping },
      },
    });
  };

  return (
    <div
      data-testid="GDSNImportMappingsItem_optionalProperties"
      className="GDSNImportMappingsItemOptionalProperties"
    >
      <div className="GDSNImportMappingsItemOptionalProperties__sectionTitle">
        Optional properties
      </div>
      <ImportMappingsItemOptionalPropertyDefaultValue
        details={details}
        dispatchImportMapping={dispatchImportMapping}
        fieldName={fieldName}
        type={type}
      />
      <ImportMappingsItemOptionalPropertyUnitString
        details={details}
        dispatchImportMapping={dispatchImportMapping}
        fieldName={fieldName}
        type={type}
      />
      <ImportMappingsItemOptionalPropertyPredicate
        details={details}
        dispatchImportMapping={dispatchImportMapping}
        fieldName={fieldName}
        type={type}
      />
      <ImportMappingsItemOptionalPropertyCustomParse
        details={details}
        dispatchImportMapping={dispatchImportMapping}
        fieldName={fieldName}
        type={type}
      />
      <ImportMappingsItemOptionalPropertyMustKeepHash
        details={details}
        dispatchImportMapping={dispatchImportMapping}
        fieldName={fieldName}
        type={type}
      />
      <ImportMappingsItemOptionalPropertySkippableReferentialValues
        details={details}
        dispatchImportMapping={dispatchImportMapping}
        fieldName={fieldName}
        type={type}
      />
      <ImportMappingsItemOptionalPropertyDiscardableReferentialValues
        details={details}
        dispatchImportMapping={dispatchImportMapping}
        fieldName={fieldName}
        type={type}
      />
      <ImportMappingsItemOptionalPropertyMustRaiseError
        details={details}
        dispatchImportMapping={dispatchImportMapping}
        fieldName={fieldName}
        type={type}
      />
    </div>
  );
}
