import classNames from 'classnames';
import Notification from 'components/notification';
import * as routes from 'constants/routes';
import logo from 'images/logo.png';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { isPreProduction } from 'utils';
import './navbar.scss';

function CoreLayout({ children, isPublic = false }) {
  return (
    <div className="page-container">
      {!isPublic && (
        <div
          className={classNames(
            'Navbar navbar navbar-dark bg-inverse navbar-fixed-top',
            { 'Navbar--PPR': isPreProduction() }
          )}
          role="navigation"
        >
          <Link className="Navbar__logoLink navbar-brand" to={routes.home}>
            <img className="Navbar__logo" src={logo} alt="Salsify" />
          </Link>
        </div>
      )}
      <Notification type="base" />
      <div className="view-container">{children}</div>
    </div>
  );
}

CoreLayout.propTypes = {
  children: PropTypes.node,
  isPublic: PropTypes.bool,
};

export default CoreLayout;
