import {
  zipObject,
  isObject,
  isUndefined,
  isNumber,
  isString,
  without,
} from 'lodash';

import { set, get } from 'utils/immutable';
import { isValidJSON } from 'utils/index';

export function serializeData(entity) {
  let data = get(entity, 'data') || {};
  if (isString(data) && isValidJSON(data)) {
    // in this case data is already serialized and is valid, but we reserialize anyway to make sure it comes out pretty
    data = JSON.parse(data);
  }
  for (const [key, value] of Object.entries(data.toJS ? data.toJS() : data)) {
    // eliminate empty strings
    if (isString(value) && value.trim().length === 0) {
      data = set(data, key, undefined);
    }
    // unserialise inner values
    if (isString(value) && value.match(/\[.*\]/) && isValidJSON(value)) {
      data = set(data, key, JSON.parse(value));
    }
  }
  const prettied = JSON.stringify(data, null, 2);
  return set(entity, 'data', prettied);
}

export function unserializeData(entity) {
  let data = get(entity, 'data') || '{}';
  if (isValidJSON(data)) {
    data = JSON.parse(data);
  }
  return set(entity, 'data', data);
}

export function buildSpreadsheetData(entities, ungroupDataColumns) {
  const _entities = entities.map(unserializeData).map(ungroupDataColumns);
  const headers = collectAppearingHeaders(_entities);

  const values = [headers, ..._entities.map(organize(headers))];
  return { values };
}

export function extractSpreadsheetData(data, groupDataColumns) {
  if (data && data.values.length > 0) {
    const [header, ...rows] = data.values;
    return rows
      .map((row) => zipObject(header, row))
      .map(groupDataColumns)
      .map(serializeData);
  }
  throw new Error('Not able to parse spreadsheet data.');
}

function collectAppearingHeaders(_entities) {
  const headers = Object.keys(Object.assign.apply(Object, [{}, ..._entities]));

  // reordering them
  const primary = ['code', 'label', 'description', 'rank'];
  const rest = without(headers, ...primary).sort();
  return primary.concat(rest);
}

function organize(headers) {
  return (entity) =>
    headers.map((header) => {
      const value = entity[header];
      if (isUndefined(value) || value === null) {
        return '';
      } else if (isString(value) || isNumber(value)) {
        return value;
      } else if (isObject(value)) {
        return JSON.stringify(value);
      }
      throw new Error('Unhandled type');
    });
}
