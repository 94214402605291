import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import * as routes from 'constants/routes';
import { fill } from 'utils/routes';
import './right-panel-category-attributes.scss';
import { Text } from '@alkem/react-ui-inputs';

export default class RightPanelCategoryAttributes extends PureComponent {
  static propTypes = {
    selectedCategoryAttributeList: PropTypes.array,
  };

  render() {
    const { selectedCategoryAttributeList } = this.props;
    if (!selectedCategoryAttributeList) {
      return null;
    }
    return (
      <table className="RightPanel__Categories">
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Version</th>
            <th>Start date</th>
            <th>End date</th>
          </tr>
        </thead>
        <tbody>
          {selectedCategoryAttributeList.map((e) => (
            <tr key={`right-panel-category-attribute-${e.id}`}>
              <td>
                <a href={fill(routes.ioMapperAfsAttribute, e.name)}>
                  <Text
                    id={`right-panel-category-attribute-name-${e.id}`}
                    value={e.name}
                    disabled={true}
                  />
                </a>
              </td>
              <td>
                <Text
                  id={`right-panel-category-attribute-description-${e.id}`}
                  value={e.description}
                  disabled={true}
                />
              </td>
              <td>
                <Text
                  id={`right-panel-category-attribute-version-${e.id}`}
                  value={e.version}
                  disabled={true}
                />
              </td>
              <td>
                <Text
                  id={`right-panel-category-attribute-start-${e.id}`}
                  value={e.start_validity}
                  disabled={true}
                />
              </td>
              <td>
                <Text
                  id={`right-panel-category-attribute-end-${e.id}`}
                  value={e.end_validity}
                  disabled={true}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}
