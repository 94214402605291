import applications from 'modules/applications/reducers';
import auth from 'modules/auth/reducer';
import displayGroups from 'modules/display-groups/reducer';
import entities from 'modules/entities/reducer';
import organizationUsesFields from 'modules/organization-uses-fields/reducer';
import retailerPictureComparator from 'modules/retailer-picture-comparator/reducer';
import rulesetSelector from 'modules/ruleset-selector/reducer';

import fields from './fields';
import kinds from './kinds';
import notifications from './notifications';
import referentials from './referentials';
import user from './user';

export default {
  auth,
  applications,
  entities,
  fields,
  kinds,
  notifications,
  organizationUsesFields,
  referentials,
  user,
  displayGroups,
  retailerPictureComparator,
  rulesetSelector,
};
