export const ATTRIBUTE_LIST_FETCH = 'ATTRIBUTE_LIST_FETCH';
export const ATTRIBUTE_FETCH = 'ATTRIBUTE_FETCH';
export const ATTRIBUTE_LIST_RECEIVE = 'ATTRIBUTE_LIST_RECEIVE';
export const ATTRIBUTE_GET = 'ATTRIBUTE_GET';
export const SAVE_ATTRIBUTE = 'SAVE_ATTRIBUTE';
export const SET_SAVE_IN_PROGRESS = 'SET_SAVE_IN_PROGRESS';
export const DISCARD_CHANGES = 'DISCARD_CHANGES';
export const FUNCTION_LIST_FETCH = 'FUNCTION_LIST_FETCH';
export const FUNCTION_FETCH = 'FUNCTION_FETCH';
export const FUNCTION_LIST_RECEIVE = 'FUNCTION_LIST_RECEIVE';
export const FUNCTION_GET = 'FUNCTION_GET';
export const CATEGORY_LIST_FETCH = 'CATEGORY_LIST_FETCH';
export const CATEGORY_LIST_RECEIVE = 'CATEGORY_LIST_RECEIVE';
export const CATEGORY_FETCH = 'CATEGORY_FETCH';
export const CATEGORY_GET = 'CATEGORY_GET';
