import React from 'react';
import { LegacyAutocompleteProps } from '../legacy-autocomplete';
import { RegExpAutocomplete } from './regexp-autocomplete';

type Props = LegacyAutocompleteProps & { values: any[] };

export const ListAutocomplete = React.memo(function ListAutocomplete({
  values,
  ...props
}: Props) {
  return (
    <RegExpAutocomplete
      {...props}
      transformData={(data) => data}
      list={values}
    />
  );
});
