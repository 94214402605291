import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import './status-bar.scss';

export const STATUS_BAR_DEFAULT = 'DEFAULT';
export const STATUS_BAR_VALIDATED = 'VALIDATED';

export const STATUS_BAR_IN_PROGRESS = 'IN_PROGRESS';
export const STATUS_BAR_SUCCESS = 'SUCCESS';
export const STATUS_BAR_FAILURE = 'FAILURE';
export const STATUS_BAR_ERROR = 'ERROR';
export const STATUS_BAR_REVIEW = 'REVIEW';
export const STATUS_BAR_CONTAINS_ERRORS = 'CONTAINS_ERRORS';
export const STATUS_BAR_EMPTY = 'EMPTY';
export const STATUS_BAR_TIMEOUT = 'TIMEOUT';

const StatusBar = ({ type, filter }) => (
  <div
    className={classNames({
      StatusBar: true,
      [`StatusBar--${type}`]: !!type,
      'StatusBar--filter': !!filter,
    })}
  />
);

StatusBar.propTypes = {
  type: PropTypes.string.isRequired,
  filter: PropTypes.bool,
};

export default StatusBar;
