import { SyntheticEvent } from 'react';
import { RuleSet } from './types';

export const stopPropagation = (e: SyntheticEvent<EventTarget>) => {
  e.stopPropagation();
};

export const getRuleSetLabelForFilter = (ruleSet: RuleSet) => {
  return `${ruleSet.id}: ${ruleSet.label} (${
    ruleSet.type ? ruleSet.type : 'untyped'
  })`;
};

export const formatRuleSetIdInFilter = (ruleSet: RuleSet) => {
  return {
    path: 'ruleSetIdIn',
    value: {
      key: ruleSet.id,
      value: ruleSet,
      label: getRuleSetLabelForFilter(ruleSet),
    },
  };
};
