import { ConceptApi } from '@alkem/sdk-dashboard';

export default new ConceptApi({
  cacheEnabled: true,
  acceptLanguage: 'noop',
});

export const immutableConceptApi = new ConceptApi({
  immutable: true,
  cacheEnabled: true,
});
