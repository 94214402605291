import { Button } from '@alkem/react-ui-button';
import { Spinner } from '@alkem/react-ui-spinner';
import { ListController } from 'components/ui/list/controller';
import { Map } from 'immutable';
import { ImportFileListRow } from 'modules/io/import/list/row';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from 'reducers/user';
import { hasFullPower } from 'utils/user';
import {
  changeLimit,
  getImportDetails,
  getImportList,
  nextPage,
  previousPage,
} from '../actions';
import DropGDSNFileModal from '../modals/gdsn';
import ImportModal from '../modals/import';
import ImportModalRange from '../modals/range';
import {
  selectFilters,
  selectList,
  selectPagination,
  selectState,
} from '../selectors';
import './list.scss';

const ActionsBar = React.memo(() => {
  const [isImportDataModalOpen, setImportDataModalOpen] = useState(false);
  const [isImportRangeModalOpen, setImportRangeModalOpen] = useState(false);
  const [isDropGDSNFileModalOpen, setDropGDSNFileModalOpen] = useState(false);
  const user: Map<string, any> = useSelector(({ user }: any) => user);
  const dispatch = useDispatch();

  const openUploadDataModal = () => setImportDataModalOpen(true);
  const closeUploadDataModal = () => setImportDataModalOpen(false);
  const openUploadRangeModal = () => setImportRangeModalOpen(true);
  const closeUploadRangeModal = () => setImportRangeModalOpen(false);
  const openDropGDSNFileModal = () => setDropGDSNFileModalOpen(true);
  const closeDropGDSNFileModal = () => setDropGDSNFileModalOpen(false);
  const onRefreshClicked = (...e: any) => dispatch(getImportList(...e));

  return (
    <div className="ListController__Actions">
      <div className="ListController__Actions__col">
        <Button onClick={onRefreshClicked}>
          <i className="mdi mdi-refresh" />
        </Button>
        {hasFullPower(user) && (
          <span>
            <span className="ImportFileList__sep" />
            <Button onClick={openUploadDataModal}>
              <span>
                <i className="mdi mdi-cloud-upload" />
                Data
              </span>
            </Button>
            <span className="ImportFileList__sep" />
            <Button onClick={openUploadRangeModal}>
              <span>
                <i className="mdi mdi-cloud-upload" />
                Range
              </span>
            </Button>
            <span className="ImportFileList__sep" />
            <Button onClick={openDropGDSNFileModal}>
              <span>
                <i className="mdi mdi-cloud-upload" />
                GDSN
              </span>
            </Button>
          </span>
        )}
      </div>
      {isImportDataModalOpen && (
        <ImportModal onCloseModal={closeUploadDataModal} />
      )}
      {isImportRangeModalOpen && (
        <ImportModalRange onCloseModal={closeUploadRangeModal} />
      )}
      {isDropGDSNFileModalOpen && (
        <DropGDSNFileModal onCloseModal={closeDropGDSNFileModal} />
      )}
    </div>
  );
});

export const ImportList = React.memo(() => {
  const user = useSelector(selectUser);
  const list: any[] = useSelector(selectList);
  const {
    isLoading = true,
    importDetails,
  }: { isLoading: boolean; importDetails: any[] } = useSelector(selectState);
  const filters: any = useSelector(selectFilters);
  const pagination: any = useSelector(selectPagination);
  const { currentPage, totalPages, limit, totalResults } = pagination;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getImportList({ replaceHistory: false }));
  }, [dispatch]);

  const onNext = () => {
    dispatch(nextPage());
  };

  const onPrev = () => {
    dispatch(previousPage());
  };

  const onSearchDetails = (importFileId, filters) => {
    dispatch(getImportDetails(importFileId, filters));
  };

  const onLimitChange = (limit) => {
    dispatch(changeLimit(limit));
  };

  return (
    <div>
      <ListController
        actions={<ActionsBar />}
        onNext={onNext}
        onPrev={onPrev}
        currentPage={currentPage}
        totalPages={totalPages}
        limit={limit}
        onLimitChange={onLimitChange}
        totalResults={totalResults}
        rowsLength={0}
        type="importfile"
      >
        {isLoading ? (
          <div className="ImportFileList__spinner">
            <Spinner loading big />
          </div>
        ) : (
          list.map((importFile) => (
            <ImportFileListRow
              key={importFile.id}
              filters={filters}
              importfile={importFile}
              importDetails={importDetails[importFile.id]}
              onSearchDetails={onSearchDetails}
              user={user}
            />
          ))
        )}
      </ListController>
    </div>
  );
});

export default ImportList;
