import React from 'react';

import { ModuleWrapper } from 'redux/register';

import { GDSN_CI_LIST_STORE_KEY } from './constants/reducers';

import reducer from './reducers';
import GDSNCIDashboardView from './views';

export default function GDSNCIDashboardViewContainer() {
  return (
    <ModuleWrapper reducerKey={GDSN_CI_LIST_STORE_KEY} reducer={reducer}>
      <GDSNCIDashboardView />
    </ModuleWrapper>
  );
}
