import { useRef, useState } from 'react';
import { Button } from '@alkem/react-ui-button';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import './item.scss';
import { ExportMappingsListElementItemType } from '../list-element-item-type';
import { ExportMappingsItemActivationDates } from '../item-activation-dates';
import { SAVE_EXPORT_MAPPING } from '../../../actions/constants';
import { ExportMappingsItemAutomaticProperties } from '../item-automatic-properties';
import { ExportMapping, Xpath } from '../../../types';
import moment from 'moment';
import { UNLIMITED_DATE, XPATH_TYPES } from '../../../constants';
import { ExportMappingsSimpleElementItemType } from '../simple-element-item-type';
import { ExportMappingsItemConstants } from '../item-constants';

interface Props {
  element: Xpath;
  exportMapping: ExportMapping;
  isNew?: boolean;
  isChangeOrder?: boolean;
  index?: number;
  changeOrder: Function;
  lengthMappings?: number;
}
export function ExportMappingsItem({
  element,
  exportMapping,
  isNew,
  isChangeOrder,
  index,
  changeOrder,
  lengthMappings = 0,
}: Props) {
  const dispatch = useDispatch();
  const originalStartDate = useRef(moment(exportMapping.date_start)).current;
  const originalEndDate = useRef(moment(exportMapping.date_end)).current;
  const [isClosed, setIsClosed] = useState(true);

  const openItem = (): void => {
    setIsClosed(false);
  };

  const getIsReadyForSaving = () => {
    if (!exportMapping) return false;
    if (element.type === XPATH_TYPES.list) {
      const { cast, field, custom_parse } = exportMapping.payload;
      switch (cast) {
        case 'gdsn_node':
          return true;
        case 'list':
          return !!field;
        case undefined:
          return !!custom_parse;
        default:
          return false;
      }
    }
    if (element.type === XPATH_TYPES.simple) {
      const { cast, field, value, is_declinable, referential, custom_parse } =
        exportMapping.payload;
      if (custom_parse) {
        return true;
      }
      let isReady = !!cast;
      if (value) {
        isReady = isReady && !!value.cast && typeof value.data !== 'undefined';
        if (cast === 'entity' || is_declinable) {
          isReady = isReady && !!referential;
          if (value.cast === 'value' && is_declinable) {
            isReady =
              isReady &&
              !!value.cast &&
              typeof value.data?.[0].data !== 'undefined' &&
              !!value.data?.[0].expressedIn?.code;
          }
        }
      } else {
        isReady = isReady && !!field;
      }
      return isReady;
    }
    return false;
  };

  const saveExportMapping = (): void => {
    dispatch({
      type: SAVE_EXPORT_MAPPING,
      exportMapping,
      xpath: element,
    });
    setIsClosed(true);
  };

  const renderButtonsContainer = (): JSX.Element => {
    const isReadyForSaving = getIsReadyForSaving();
    return (
      <div className="GDSNExportMappingsItem__buttonsContainer">
        {isClosed && !isChangeOrder && (
          <Button
            id="create-gdsn-export-mapping"
            content={isNew ? 'Create mapping' : 'Edit'}
            primary
            onClick={openItem}
          />
        )}
        {!isClosed && isNew && !isChangeOrder && (
          <Button
            id="save-gdsn-export-mapping"
            content="Save"
            primary
            disabled={!isReadyForSaving}
            onClick={saveExportMapping}
          />
        )}
        {isChangeOrder && index !== 0 && (
          <i
            data-testid={`up-${index}-${exportMapping.id}`}
            className={classNames('mdi', {
              'mdi-chevron-up': true,
            })}
            id="up-gdsn-export-mapping"
            onClick={() => changeOrder(index, 'UP')}
          />
        )}
        {isChangeOrder && index !== lengthMappings - 1 && (
          <i
            data-testid={`down-${index}-${exportMapping.id}`}
            className={classNames('mdi', {
              'mdi-chevron-down': true,
            })}
            id="down-gdsn-export-mapping"
            onClick={() => changeOrder(index, 'DOWN')}
          />
        )}
      </div>
    );
  };

  return (
    <div className="GDSNExportMappingsItem">
      <div
        className={classNames({
          GDSNExportMappingsItem__header__closed: isClosed,
          GDSNExportMappingsItem__header__opened: !isClosed,
        })}
      >
        <span className="GDSNExportMappingsItem__expand-button">
          <i
            className={classNames('mdi', {
              'mdi-chevron-down': isClosed,
              'mdi-chevron-up': !isClosed,
            })}
            onClick={() => setIsClosed(!isClosed)}
          />
          {!isNew &&
            `${exportMapping.id} - Valid from ${originalStartDate.format(
              'YYYY-MM-DD'
            )} ${
              originalEndDate.isSame(UNLIMITED_DATE)
                ? 'for an unlimited amount of time'
                : `to ${originalEndDate.format('YYYY-MM-DD')}${
                    originalEndDate.isBefore(moment())
                      ? ' (not valid anymore)'
                      : ''
                  }`
            }`}
        </span>
        {renderButtonsContainer()}
      </div>

      {!isClosed && exportMapping && (
        <>
          {element.type === XPATH_TYPES.list && (
            <ExportMappingsListElementItemType
              elementName={element.name}
              exportMapping={exportMapping}
            />
          )}
          {element.type === XPATH_TYPES.simple && (
            <ExportMappingsSimpleElementItemType
              elementName={element.name}
              exportMapping={exportMapping}
            />
          )}
          <ExportMappingsItemActivationDates exportMapping={exportMapping} />
          <ExportMappingsItemAutomaticProperties
            elementName={element.name}
            exportMapping={exportMapping}
          />
          {element.type === XPATH_TYPES.list && (
            <ExportMappingsItemConstants exportMapping={exportMapping} />
          )}
        </>
      )}
    </div>
  );
}
