import { notificationError, notificationSuccess } from 'actions/notifications';
import { Map } from 'immutable';
import { mapValues, some } from 'lodash';
import { flow, map, flatten, values, concat, uniq } from 'lodash/fp';
import ApplicationsApi from 'resources/applicationsApi';
import AuthApi from 'resources/authApi';
import { trimStringValue } from 'utils';
import {
  APPLICATION_SAVED,
  APPLICATION_SAVE_ERROR,
  CREATE_APPLICATION,
  DISABLE_ORGANIZATION_SUCCESS,
  ENABLE_ORGANIZATION_SUCCESS,
  FETCH_DATA_ERROR,
  FETCH_DATA_SUCCESS,
  MARK_APPLICATION_EDITED,
  NEW_APPLICATION_ID,
  RESET_APPLICATION,
  SAVING_APPLICATION,
  SELECT_APPLICATION,
  SET_APPLICATIONS_LOADING,
  SHOW_APP_FORM_ERRORS,
} from '../constants';
import { getApplications } from '../selectors';
import { mapValuesToServer } from '../utils/data';
import { validate } from '../utils/validation';

// plain object actions

export const selectApplication = (applicationId) => ({
  type: SELECT_APPLICATION,
  applicationId,
});

export const markApplicationEdited = (applicationId) => ({
  type: MARK_APPLICATION_EDITED,
  applicationId,
});

export const resetApplication = () => ({
  type: RESET_APPLICATION,
});

export const setApplicationsLoading = (loading) => ({
  type: SET_APPLICATIONS_LOADING,
  loading,
});

export const savingApplication = () => ({
  type: SAVING_APPLICATION,
});

export const createApplication = () => ({
  type: CREATE_APPLICATION,
});

// async actions

export const fetchData = (applicationId) => async (dispatch) => {
  try {
    dispatch(setApplicationsLoading(true));
    const { data: { data: applications = [] } = {} } =
      await ApplicationsApi.ApplicationList({ visibilities: false });
    const { data: { data: appsHaveOrganizations = [] } = {} } =
      await ApplicationsApi.ApplicationHasOrganizationEnabled();
    const organizationIds = flow([
      values,
      flatten,
      concat(applications),
      map(({ organizationId }) => organizationId),
      uniq,
    ])(appsHaveOrganizations);
    const [organizations, categories] = await Promise.all([
      AuthApi.OrganizationListV3(
        { ids: organizationIds },
        { network: true },
        {},
        null,
        organizationIds.length,
        {
          method: 'post',
        }
      ),
      ApplicationsApi.ApplicationCategories(),
    ]);
    dispatch(setApplicationsLoading(false));
    dispatch({
      type: FETCH_DATA_SUCCESS,
      organizations: organizations.data.data,
      applications,
      appsHaveOrganizations,
      categories: categories.data.data,
    });
    if (applicationId > 0) {
      dispatch(selectApplication(applicationId));
    } else if (applicationId === NEW_APPLICATION_ID) {
      dispatch(createApplication());
    }
  } catch (error) {
    dispatch(setApplicationsLoading(false));
    dispatch({
      type: FETCH_DATA_ERROR,
      error: error.message,
    });
    dispatch(notificationError('Failed to load application'));
    throw error;
  }
};

export const validateApplication = (fields) => (dispatch) => {
  const trimmed = mapValues(fields, trimStringValue);
  const errors = validate.requiredFields(trimmed);
  errors.color = validate.colorField(trimmed.color);
  errors.name = validate.nameFieldNotDefault(trimmed.name);
  errors.flags = validate.scopeFlagsJSONFormat(trimmed.flags);
  errors.basicSettings = validate.scopeKeyOnOffJSONFormat(
    trimmed.basicSettings
  );
  errors.valueFlags = validate.scopeKeyOnOffJSONFormat(trimmed.valueFlags);
  if (some(errors)) {
    dispatch({ type: SHOW_APP_FORM_ERRORS, errors });
  } else {
    dispatch(saveApplication(trimmed));
  }
};

export function saveApplication(formValues) {
  return async (dispatch, getState) => {
    try {
      const { id } = formValues;
      const serverScopes = getApplications(getState())
        .getIn([id, 'serverScopes'], Map())
        .toJS();
      const app = mapValuesToServer({ ...formValues, serverScopes });
      const isNew = !app.id;
      const apiCall = isNew
        ? () => ApplicationsApi.ApplicationCreate(app)
        : () => ApplicationsApi.ApplicationUpdate(app);
      const response = await apiCall();
      dispatch({
        type: APPLICATION_SAVED,
        application: response.data.data,
        created: isNew,
      });
      dispatch(
        notificationSuccess(
          `${app.name} appication has been ${isNew ? 'created' : 'saved'}`
        )
      );
    } catch (error) {
      dispatch({
        type: APPLICATION_SAVE_ERROR,
        error: error.message,
      });
      dispatch(notificationError('Failed to save application'));
      throw error;
    }
  };
}

export const enableOrganization =
  (organization, applicationId) => async (dispatch) => {
    try {
      await ApplicationsApi.ApplicationEnable(applicationId, organization.id);
      dispatch(
        notificationSuccess(`Organization ${organization.nameLegal} enabled`)
      );
      dispatch({
        type: ENABLE_ORGANIZATION_SUCCESS,
        payload: { organization, applicationId },
      });
    } catch (error) {
      dispatch(notificationError('Cannot enable Organization'));
      throw error;
    }
  };

export const disableOrganization =
  (organizationId, applicationId) => async (dispatch) => {
    try {
      await ApplicationsApi.ApplicationDisable(applicationId, organizationId);
      dispatch({
        type: DISABLE_ORGANIZATION_SUCCESS,
        payload: { applicationId, organizationId },
      });
      dispatch(notificationSuccess(`Organization ${organizationId} disabled`));
    } catch (error) {
      dispatch(notificationError('Cannot disable Organization'));
      throw error;
    }
  };
