import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

function OrganizationPageSettingsSection({ label, children }) {
  if (
    !children ||
    (Array.isArray(children) && children.filter((elem) => !!elem).length === 0)
  ) {
    return null;
  }
  return (
    <div className="OrganizationPageSettingsSection">
      <div className="OrganizationPageSettingsSection__label">{label}</div>
      <div className="OrganizationPageSettingsSection__settings">
        {children}
      </div>
    </div>
  );
}

OrganizationPageSettingsSection.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.node,
};

export default OrganizationPageSettingsSection;
