export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_ERROR = 'FETCH_DATA_ERROR';
export const SET_APPLICATIONS_LOADING = 'SET_APPLICATIONS_LOADING';

export const SELECT_APPLICATION = 'SELECT_APPLICATION';
export const MARK_APPLICATION_EDITED = 'MARK_APPLICATION_EDITED';
export const RESET_APPLICATION = 'RESET_APPLICATION';
export const CREATE_APPLICATION = 'CREATE_APPLICATION';

export const SAVING_APPLICATION = 'SAVING_APPLICATION';
export const APPLICATION_SAVED = 'APPLICATION_SAVED';
export const APPLICATION_SAVE_ERROR = 'APPLICATION_SAVE_ERROR';

export const SHOW_APP_FORM_ERRORS = 'SHOW_APP_FORM_ERRORS';

export const ENABLE_ORGANIZATION_SUCCESS = 'ENABLE_ORGANIZATION_SUCCESS';
export const DISABLE_ORGANIZATION_SUCCESS = 'DISABLE_ORGANIZATION_SUCCESS';

export const APP_PERMISSIONS = {
  NONE: 0,
  API_ACCESS: 1,
  READ_WRITE: 2,
};

export const APP_PERMISSION_VALUES = {
  API_ACCESS: ['product.show'],
  READ_WRITE: ['product.show', 'product.update', 'product.validate'],
};

export const VISIBILITY = {
  ALKEMICS_ONLY: 0,
  ALKEMICS_AND_MANUFACTURER: 1,
  ALKEMICS_AND_RETAIL: 2,
  ALL: 3,
};

export const NEW_APPLICATION_NAME = '<New Application>';
export const NEW_APPLICATION_ID = -1;
