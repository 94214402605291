export const MODULE_KEY = 'auth';

// action types
export const LOGIN_REQUEST = `${MODULE_KEY}/login-request`;
export const LOGIN_ERROR = `${MODULE_KEY}/login-error`;
export const LOGIN = `${MODULE_KEY}/login`;
export const LOGOUT_REQUEST = `${MODULE_KEY}/logout-request`;
export const LOGOUT = `${MODULE_KEY}/logout`;
export const FETCH_PROFILE = `${MODULE_KEY}/fetch-profile`;
export const RECEIVE_PROFILE = `${MODULE_KEY}/load-profile`;
export const FETCH_PERMISSIONS = `${MODULE_KEY}/fetch-permissions`;
export const RECEIVE_PERMISSIONS = `${MODULE_KEY}/receive-permissions`;
