import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchFieldMetadata } from './fetchFieldMetadata';
import { computeForbiddenKinds } from './forbiddenKinds';
import { computeForbiddenPackagingTypes } from './forbiddenPackagingTypes';
import { kindsIndexSelector } from 'redux/selectors';
import { FieldMetadata, KindIds, PackagingTypes } from './types';

interface Options {
  // skip : set to true to not fetch fieldMetadata
  // this is needed to respect the rules of hook
  // default : false
  skip?: boolean;
  // withForbiddenKinds : set to false to bypass forbiddenKinds computation
  // default : true
  withForbiddenKinds?: boolean;
  // withForbiddenPackagingTypes : set to false to bypass forbiddenPackagingTypes computation
  // default : true
  withForbiddenPackagingTypes?: boolean;
}

interface HookResultNotLoaded {
  status: 'skipped' | 'loading' | 'error';
}

interface HookResultLoaded {
  status: 'loaded';
  fieldMetadata: FieldMetadata;
  forbiddenKinds?: KindIds;
  forbiddenPackagingTypes?: PackagingTypes;
}

export type HookResult = HookResultLoaded | HookResultNotLoaded;

export const useFieldMetadata: (
  fieldId: number,
  entityType?: number,
  options?: Options
) => HookResult = (fieldId, entityType, options = {}) => {
  const {
    skip = false,
    withForbiddenKinds = true,
    withForbiddenPackagingTypes = true,
  } = options;

  const [result, setResult] = useState<HookResult>({ status: 'loading' });

  const kindsIndex = useSelector(kindsIndexSelector);

  useEffect(() => {
    if (skip) {
      setResult({ status: 'skipped' });
      return;
    }

    const fetching = fetchFieldMetadata(fieldId).then(
      (fieldMetadata) => {
        const r: HookResult = { status: 'loaded', fieldMetadata };
        if (withForbiddenKinds) {
          r.forbiddenKinds = computeForbiddenKinds(fieldMetadata, kindsIndex);
        }
        if (withForbiddenPackagingTypes) {
          r.forbiddenPackagingTypes = computeForbiddenPackagingTypes(
            fieldMetadata,
            entityType
          );
        }
        setResult(r);
      },
      () => setResult({ status: 'error' })
    );

    return () => {
      fetching.cancel();
    };
    // see comment about kindsIndex below
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fieldId,
    skip,
    withForbiddenKinds,
    withForbiddenPackagingTypes,
    entityType,
    // kindsIndex,
    // getKindList is called by kindtree component, resulting in kinds to be fetched again and kindsIndex to be a new object
    // it leads to fieldmetadata to be fetch again here, with no Kind being modified whatsoever
    // we cannot do a deep comparison with previous kindsIndex in the effector, it is too costly, even more than requesting field metadata again and again
    // so we ignore kinds modification (rare events), assuming the eventual drift
    // clean solution : fix in kindtree component so its request kinds for good reasons (or don't call it at all and takes the information from upper in hierarchy) ?
  ]);

  return result;
};
