import {
  CONSUMER_UNIT,
  DISPLAY_UNIT,
  LOGISTICAL_UNIT,
  PRODUCT_DOCUMENT,
  PRODUCT_PICTURE,
  PRODUCT_VIDEO,
  RFP_BUSINESS_UNIT,
  RFP_EVENT,
  SHARING_UNIT,
  SUPPLIER,
} from '../../constants/fields';

export const REDUCER_KEY = 'organization-group-field';

export const SET_ORGANIZATION = 'organization-group-field/SET_ORGANIZATION';
export const ORGANIZATION_LOADED =
  'organization-group-field/ORGANIZATION_LOADED';

export const CREATE_ORGANIZATION_FIELD_GROUP =
  'organization-group-field/CREATE_ORGANIZATION_FIELD_GROUP';
export const CREATE_FIELD = 'organization-group-field/CREATE_FIELD';
export const ON_FIELD_CHANGE = 'organization-group-field/ON_FIELD_CHANGE';
export const DELETE_FIELD = 'organization-group-field/DELETE_FIELD';

export const ORGANIZATION_FIELD_GROUP_LOADED =
  'organization-group-field/ORGANIZATION_FIELD_GROUP_LOADED';
export const ORGANIZATION_FIELD_GROUP_CREATED =
  'organization-group-field/ORGANIZATION_FIELD_GROUP_CREATED';
export const SAVE_ORGANIZATION_FIELD_GROUP =
  'organization-group-field/SAVE_ORGANIZATION_FIELD_GROUP';
export const DELETE_ORGANIZATION_FIELD_GROUP =
  'organization-group-field/DELETE_ORGANIZATION_FIELD_GROUP';
export const ON_ORGANIZATION_FIELD_GROUP_CHANGE =
  'organization-group-field/ON_ORGANIZATION_FIELD_GROUP_CHANGE';

export const EXPORT_ORGANIZATION_FIELD_GROUP =
  'organization-group-field/EXPORT_ORGANIZATION_FIELD_GROUP';
export const IMPORT_ORGANIZATION_FIELD_GROUP =
  'organization-group-field/IMPORT_ORGANIZATION_FIELD_GROUP';

export const FIELD_ROOT = {
  0: { id: 0, const: 'FIELD_ROOT_UNKNOWN', label: 'Unknown', type: '' },
  1: {
    id: 1,
    const: 'CONSUMER_UNIT',
    label: 'Consumer unit',
    type: CONSUMER_UNIT,
  },
  2: { id: 2, const: 'PICTURE', label: 'Picture', type: PRODUCT_PICTURE },
  3: { id: 3, const: 'VIDEO', label: 'Video', type: PRODUCT_VIDEO },
  4: { id: 4, const: 'ENRICHED_CONTENT', label: 'Enriched content', type: '' },
  5: { id: 5, const: 'DOCUMENT', label: 'Document', type: PRODUCT_DOCUMENT },
  6: {
    id: 6,
    const: 'SHARING_UNIT',
    label: 'Sharing unit',
    type: SHARING_UNIT,
  },
  7: {
    id: 7,
    const: 'DISPLAY_UNIT',
    label: 'Display unit',
    type: DISPLAY_UNIT,
  },
  8: {
    id: 8,
    const: 'LOGISTICAL_UNIT',
    label: 'Logistical unit',
    type: LOGISTICAL_UNIT,
  },
  9: { id: 9, const: 'RFP_EVENT', label: 'Event', type: RFP_EVENT },
  10: {
    id: 10,
    const: 'RFP_BU',
    label: 'Business unit',
    type: RFP_BUSINESS_UNIT,
  },
  11: { id: 11, const: 'RFP_SUPPLIER', label: 'Supplier', type: SUPPLIER },
  12: {
    id: 12,
    const: 'ORGANIZATION',
    label: 'Organization',
    type: 'organization',
  },
};

export const LOAD_FIELD_GROUPS_FOR_IMPORT =
  'organization-group-field-import/LOAD_FIELD_GROUPS_FOR_IMPORT';
export const FIELD_GROUPS_FOR_IMPORT_LOADED =
  'organization-group-field-import/FIELD_GROUPS_FOR_IMPORT_LOADED';

export const DEFAULT_ORGANIZATION_GROUP_LOADED =
  'organization-group-field/DEFAULT_ORGANIZATION_GROUP_LOADED';
export const SET_DEFAULT_ORGANIZATION_GROUP =
  'organization-group-field/SET_DEFAULT_ORGANIZATION_GROUP';
