export const isEAN = (str) => /^\s*[0-9]+\s*(,\s*[0-9]+\s*)*$/.test(str);

export const constructFilters = (filters) => {
  const filterArgs = {};
  Object.keys(filters).forEach((key) => {
    if (Array.isArray(filters[key])) {
      filterArgs[key] = filters[key].map((filter) => filter.value).join(',');
    } else if (filters[key]) {
      if (Array.isArray(filters[key].value)) {
        filterArgs[key] = filters[key].value.join(',');
      } else {
        filterArgs[key] = filters[key].value;
      }
    }
  });
  return filterArgs;
};
