import { immutableAuthApi } from 'resources/authApi';
import { notificationError } from 'actions/notifications';

import * as events from '../constants/events';

import {
  selectEditedOrSourceRecipients,
  selectRecipients,
  selectEditedRecipients,
  selectOrganizationId,
} from '../selectors/recipients';

export const getRecipients = (organizationId) => (dispatch) =>
  immutableAuthApi
    .OrganizationWorksWithOrganizationForOrganization(
      organizationId,
      { isActive: true },
      { organizationName: true },
      false
    )
    .then(
      (response) => {
        dispatch({
          type: events.RECEIVE_RECIPIENTS,
          recipients: response.data.data,
        });
      },
      () => {
        dispatch(
          notificationError(
            'Something went wrong while retrieving recipient relations, call a tech'
          )
        );
      }
    );

export const addRecipient =
  (targetOrganization, relationType) => (dispatch, getState) => {
    const recipientExists = !!selectEditedOrSourceRecipients(getState()).find(
      (recipient) => recipient.get('organization_id') === targetOrganization.id
    );
    if (recipientExists) {
      dispatch(
        notificationError(
          `Recipient "${targetOrganization.nameLegal}" (${targetOrganization.id}) already present in the list`
        )
      );
      return;
    }

    dispatch({
      type: events.ADD_RECIPIENT,
      targetOrganization,
      relationType,
    });
  };

export const removeRecipient = (targetOrganizationId) => ({
  type: events.REMOVE_RECIPIENT,
  targetOrganizationId,
});

const deleteRecipientRelation = (organizationId, recipient) =>
  immutableAuthApi.OrganizationWorksWithOrganizationDelete(
    organizationId,
    recipient.get('organization_id')
  );

const upsertRecipientRelation = (organizationId, recipient) =>
  immutableAuthApi.OrganizationWorksWithOrganizationUpsert({
    source_organization_id: organizationId,
    target_organization_id: recipient.get('organization_id'),
    relation_type: recipient.get('type'),
    isActive: true,
  });

export const save = () => (dispatch, getState) => {
  const state = getState();
  const sourceRecipients = selectRecipients(state);
  const editedRecipients = selectEditedRecipients(state);

  if (!editedRecipients) {
    return Promise.all([]);
  }

  const toUpsert = editedRecipients.filter(
    (editedRecipient) => !sourceRecipients.includes(editedRecipient)
  );
  const toRemove = sourceRecipients.filter(
    (sourceRecipient) =>
      !editedRecipients.find(
        (editedRecipient) =>
          editedRecipient.get('organization_id') ===
          sourceRecipient.get('organization_id')
      )
  );

  const organizationId = selectOrganizationId(state);
  const removePromises = toRemove
    .map((recipient) => deleteRecipientRelation(organizationId, recipient))
    .toJS();
  const upsertPromises = toUpsert
    .map((recipient) => upsertRecipientRelation(organizationId, recipient))
    .toJS();

  const promises = removePromises.concat(upsertPromises);

  return Promise.all(promises);
};
