export const REDUCER_KEY = 'product-maintenance';

export const LOAD_PRODUCT = 'product-maintenance/LOAD_PRODUCT';
export const PRODUCT_LOADED = 'product-maintenance/PRODUCT_LOADED';

export const UPDATE_PRODUCT = 'product-maintenance/UPDATE_PRODUCT';

export const SAVE = 'product-maintenance/SAVE';
export const SAVE_SUCCESS = 'product-maintenance/SAVE_SUCCESS';
export const SAVE_FAILURE = 'product-maintenance/SAVE_FAILURE';

export const REINDEX = 'product-maintenance/REINDEX';
export const REINDEX_SUCCESS = 'product-maintenance/REINDEX_SUCCESS';
export const REINDEX_FAILURE = 'product-maintenance/REINDEX_FAILURE';

export const RESYNC = 'product-maintenance/RESYNC';
export const RESYNC_SUCCESS = 'product-maintenance/RESYNC_SUCCESS';
export const RESYNC_FAILURE = 'product-maintenance/RESYNC_FAILURE';

export const REVIEW = 'product-maintenance/REVIEW';
export const REVIEW_SUCCESS = 'product-maintenance/REVIEW_SUCCESS';
export const REVIEW_FAILURE = 'product-maintenance/REVIEW_FAILURE';

export const UPDATE_REASON = 'product-maintenance/UPDATE_REASON';

export const maintainableFields = [
  { name: 'isConsumerUnit', type: 'bool' },
  { name: 'isDisplayUnit', type: 'bool' },
  { name: 'isDespatchUnit', type: 'bool' },
  { name: 'typePackaging', type: 'typePackaging' },
];

export const maintainableFieldNames = maintainableFields.map((f) => f.name);

export const typePackagings = [
  {
    id: 0,
    label: 'Each',
    value: 0,
    applicable: (isConsumerUnit: boolean) => isConsumerUnit,
  },
  {
    id: 1,
    label: 'Pack',
    value: 1,
    applicable: () => (isDisplayUnit: boolean) => !isDisplayUnit,
  },
  {
    id: 2,
    label: 'Case',
    value: 2,
    applicable: () => true,
  },
  {
    id: 3,
    label: 'Pallet',
    value: 3,
    applicable: (isConsumerUnit: boolean) => !isConsumerUnit,
  },
];
