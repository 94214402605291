import { ModuleWrapper } from 'redux/register';
import ProductMaintenance from './view';
import { REDUCER_KEY } from './constants';
import saga from './sagas';
import reducer from './reducer';

export default function AccessPolicyListModule() {
  return (
    <ModuleWrapper reducerKey={REDUCER_KEY} reducer={reducer} saga={saga}>
      <ProductMaintenance />
    </ModuleWrapper>
  );
}
