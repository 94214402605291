import { immutableAuthApi as AuthApi } from 'resources/authApi';

export async function getOrganizations(orgIds) {
  try {
    const filters = { ids: orgIds };
    const response = await AuthApi.OrganizationListV3(filters);
    return { response };
  } catch (error) {
    return {
      error: error.data && error.data.message ? error.data.message : error,
    };
  }
}
