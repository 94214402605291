import { FieldsApi } from '@alkem/sdk-dashboard';

export default new FieldsApi({
  cacheEnabled: true,
  acceptLanguage: 'en',
});

// Just a matter of time before we kick the non immutable
export const fieldsApiImmutable = new FieldsApi({
  cacheEnabled: true,
  acceptLanguage: 'en',
  immutable: 'true',
});
