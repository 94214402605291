import React from 'react';

import { ModuleWrapper } from 'redux/register';

import { REDUCER_KEY } from './constants';
import reducer from './reducers';
import OrganizationCreate from './components';

export default function OrganizationCreateContainer() {
  return (
    <ModuleWrapper reducerKey={REDUCER_KEY} reducer={reducer}>
      <OrganizationCreate />
    </ModuleWrapper>
  );
}
