import React from 'react';

import { ModuleWrapper } from 'redux/register';

import { REDUCER_KEY } from './constants';
import reducer from './reducers';
import ReferentialPictures from './components';

export default function ReferentialPicturesDashboardContainer() {
  return (
    <ModuleWrapper reducerKey={REDUCER_KEY} reducer={reducer}>
      <ReferentialPictures />
    </ModuleWrapper>
  );
}
