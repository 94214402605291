import {
  VALIDATION_RULESET_TYPE_DEADLINE__DYNAMIC,
  VALIDATION_RULESET_TYPE_DEADLINE__STATIC,
  VALIDATION_RULESET_TYPE__MATURITY,
  VALIDATION_RULESET_TYPE__REGULATORY,
  VALIDATION_RULESET_TYPE__STAGE_GATE,
} from 'modules/validation-dashboard/constants';
import { RuleSet } from 'modules/validation-dashboard/types';
import moment from 'moment';
import { formatTime, TIME_FORMAT_DAY } from 'utils/time';
import { PostableRuleSet, RuleSetInEdition } from './types';

export const ruleSetIsIncomplete = (ruleSet: RuleSetInEdition): boolean =>
  !ruleSet.name ||
  !ruleSet.type ||
  (ruleSet.type === VALIDATION_RULESET_TYPE__STAGE_GATE &&
    (!ruleSet.deadline_type ||
      (ruleSet.deadline_type === VALIDATION_RULESET_TYPE_DEADLINE__DYNAMIC &&
        (!ruleSet.deadline_delta || !ruleSet.deadline_field))));

export const makePostableRuleSet = (
  ruleSet: RuleSetInEdition
): PostableRuleSet => {
  if (ruleSetIsIncomplete(ruleSet))
    throw "can't make postable rule from incomplete rule";
  const postableRuleSet: PostableRuleSet = {
    label: ruleSet.name,
    type: ruleSet.type,
    tags: ruleSet.tagCodes,
    displayable:
      ruleSet.type === VALIDATION_RULESET_TYPE__MATURITY
        ? ruleSet.displayable
        : true,
  };

  if (ruleSet.type === VALIDATION_RULESET_TYPE__STAGE_GATE) {
    if (ruleSet.deadline_type === VALIDATION_RULESET_TYPE_DEADLINE__STATIC) {
      postableRuleSet.payload = {
        deadline: {
          type: ruleSet.deadline_type,
          value: formatTime(ruleSet.deadline_date || moment(), TIME_FORMAT_DAY),
        },
      };
    } else if (
      ruleSet.deadline_type === VALIDATION_RULESET_TYPE_DEADLINE__DYNAMIC
    ) {
      postableRuleSet.payload = {
        deadline: {
          type: ruleSet.deadline_type,
          field: ruleSet.deadline_field,
          delta: parseInt(ruleSet.deadline_delta || '0', 10),
        },
      };
    }
  }

  return postableRuleSet;
};

export const ruleSetToRuleSetInEdition = (
  ruleSet: RuleSet
): RuleSetInEdition => {
  return {
    name: ruleSet.label,
    type: ruleSet.type,
    tagCodes: ruleSet.tags,
    displayable: ruleSet.displayable,
    deadline_type: ruleSet.payload?.deadline?.type,
    deadline_date: ruleSet.payload?.deadline?.value,
    deadline_field: ruleSet.payload?.deadline?.field,
    deadline_delta: ruleSet.payload?.deadline?.delta,
  };
};

export const initRuleSetInEdition = (): RuleSetInEdition => {
  return {
    name: '',
    type: VALIDATION_RULESET_TYPE__REGULATORY,
    tagCodes: [],
    displayable: true,
    deadline_type: VALIDATION_RULESET_TYPE_DEADLINE__STATIC,
  };
};
