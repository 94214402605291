import { XPATH_TYPES } from '../../constants';
import { Xpath, XpathList } from '../../types';
import { AdvancedModal } from '@alkem/react-ui-modal';
import { Button } from '@alkem/react-ui-button';

import './element-selector.scss';
import { ListElement } from './list-element';
import { useState } from 'react';

interface Props {
  elements: XpathList;
  selectElement: (element: Xpath) => void;
  selectedElement: Xpath | undefined;
}

export function ElementSelector({
  elements,
  selectElement,
  selectedElement,
}: Props) {
  const [showModal, setShowModal] = useState(false);

  const updateShowModal = (element: Xpath) => {
    selectElement(element);
    setShowModal(true);
  };

  const renderModal = (element: Xpath, close: (mustClose: boolean) => void) => {
    return (
      <AdvancedModal
        title={`Create element up or down ${element.name}?`}
        onClose={() => close(false)}
        hideFooter
      >
        <div
          className="GDSNExportMappingsElementSelector__modalButtonsContainer"
          data-testid={`modal-${element.id}`}
        >
          <Button content="Up" />
          <Button content="Down" />
        </div>
      </AdvancedModal>
    );
  };

  const renderChildren = (children: XpathList) => {
    return (
      <div className="GDSNExportMappingsElementSelector__child">
        {children.map((child) => {
          if (child.type === XPATH_TYPES.list) {
            return (
              <ListElement
                key={child.id}
                element={child}
                renderChildren={renderChildren}
                selectElement={selectElement}
                updateShowModal={updateShowModal}
              />
            );
          } else {
            return (
              <div
                className="GDSNExportMappingsElementSelector__childNamesContainer"
                key={child.id}
              >
                <div
                  onClick={() => selectElement(child)}
                  data-testid={child.id}
                >
                  {child.name}
                </div>
                <i
                  className="mdi mdi-plus"
                  data-testid={`plus-${child.id}`}
                  onClick={() => updateShowModal(child)}
                />
              </div>
            );
          }
        })}
      </div>
    );
  };

  return (
    <div className="GDSNExportMappingsElementSelector">
      {elements.map((element) => {
        if (element.type === XPATH_TYPES.module) {
          return (
            <ListElement
              key={element.id}
              element={element}
              renderChildren={renderChildren}
              selectElement={selectElement}
              updateShowModal={updateShowModal}
            />
          );
        } else {
          return;
        }
      })}
      {showModal &&
        selectedElement &&
        renderModal(selectedElement, setShowModal)}
    </div>
  );
}
