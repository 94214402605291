import { PureComponent, FC } from 'react';

import { Button } from '@alkem/react-ui-button';
import { Spinner } from '@alkem/react-ui-spinner';

import JsonDisplayer from 'components/json-displayer';

import './es-content.scss';

import { loadProduct, fetchDocumentation } from '../api';

interface FSProps {
  fieldName: string;
  onClick(): void;
}

const FieldSuggestion: FC<FSProps> = ({
  fieldName,
  onClick,
}: {
  fieldName: string;
  onClick(): void;
}) => (
  <span className="ESContent_fieldSuggestion cursor-pointer" onClick={onClick}>
    {fieldName}
  </span>
);

const isValid = (value: string) => /^[a-zA-Z._]+$/g.test(value);

const joinPath = (path: string | null | undefined, next: string): string =>
  path ? `${path}.${next}` : `${next}`;

const filterFields = (fields: any[], search: string, path?: string | null) => {
  if (!search) {
    return [
      fields.map((f) => ({ name: f.name, path: joinPath(path, f.name) })),
    ];
  }

  const split = search.split('.');
  if (split.length > 1) {
    const current = split[0];
    const rest = search.substr(split[0].length + 1);
    const baseField = fields.find(
      (f) => f.name.toLowerCase() === current.toLowerCase()
    );
    if (!baseField) {
      return [[]];
    }
    return filterFields(baseField.children || [], rest, current);
  }

  const filteredFields = fields.filter((f) =>
    f.name.toLowerCase().startsWith(search.toLowerCase())
  );
  const baseField = filteredFields.length === 1 ? filteredFields[0] : null;
  let subFields: object | null = null;
  if (baseField) {
    const [filteredSubFields] = filterFields(
      baseField.children || [],
      '',
      baseField.name
    );
    if (filteredSubFields.length > 0) {
      subFields = {
        path: path ? `${path}.${search}` : search,
        fields: filteredSubFields,
      };
    }
  }
  return [
    filteredFields.map((f) => ({ name: f.name, path: joinPath(path, f.name) })),
    subFields,
  ];
};

interface Props {
  productKeyId: number;
}

interface State {
  sourceIncludeInput: string;
  filterSourceInclude: string[];
  product: object;
  isLoading: boolean;
  fields: any[];
  fieldsLoaded: boolean;
}

class ESContent extends PureComponent<Props, State> {
  sourceInput: any;

  constructor(props) {
    super(props);

    this.sourceInput = null;

    this.state = {
      sourceIncludeInput: '',
      filterSourceInclude: [],
      product: {},
      isLoading: false,
      fields: [],
      fieldsLoaded: false,
    };
  }

  componentDidMount() {
    this.loadProduct();
    this.loadFields();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.productKeyId !== prevProps.productKeyId ||
      this.state.filterSourceInclude !== prevState.filterSourceInclude
    ) {
      this.loadProduct();
    }
  }

  onUpdateSourceIncludeInput = (evt) => {
    this.setState({ sourceIncludeInput: evt.currentTarget.value });
  };

  onKeyPress = (evt) => {
    const { filterSourceInclude, sourceIncludeInput } = this.state;
    if (
      evt.charCode === 13 &&
      isValid(sourceIncludeInput) &&
      !filterSourceInclude.includes(sourceIncludeInput)
    ) {
      // on press enter
      this.setState({
        filterSourceInclude: [...filterSourceInclude, sourceIncludeInput],
        sourceIncludeInput: '',
      });
    }
  };

  onClickSuggestion = (path: string) => () => {
    this.setState({ sourceIncludeInput: path }, () => {
      if (this.sourceInput) {
        this.sourceInput.focus();
      }
    });
  };

  onRemoveFilter = (filter) => () => {
    this.setState(({ filterSourceInclude }) => ({
      filterSourceInclude: filterSourceInclude.filter((f) => f !== filter),
    }));
  };

  loadProduct = async () => {
    const { productKeyId } = this.props;
    this.setState({ isLoading: true });
    const { filterSourceInclude } = this.state;
    // try catch
    const { product } = await loadProduct(productKeyId, [
      ...filterSourceInclude,
      'product_key',
    ]);
    this.setState({ product, isLoading: false });
  };

  async loadFields() {
    const { fields } = await fetchDocumentation();
    this.setState({ fields, fieldsLoaded: true });
  }

  renderSuggestions() {
    const { sourceIncludeInput, fields } = this.state;

    const [filteredFields, filteredSubFields] = filterFields(
      fields,
      sourceIncludeInput
    );
    if (
      sourceIncludeInput &&
      filteredFields.length === 0 &&
      (!filteredSubFields || filteredSubFields.fields.length === 0)
    ) {
      return (
        <div className="text-muted">
          <em>No subfields</em>
        </div>
      );
    }

    const filteredFieldsElemts = filteredFields.map((f) => (
      <FieldSuggestion
        key={f.name}
        fieldName={f.name}
        onClick={this.onClickSuggestion(f.path)}
      />
    ));

    let filteredSubFieldsElemts: any = null;
    if (filteredSubFields && filteredSubFields.fields.length > 0) {
      filteredSubFieldsElemts = (
        <>
          <span className="mdi mdi-chevron-right" />
          {filteredSubFields.fields.map((f) => (
            <FieldSuggestion
              key={f.name}
              fieldName={f.name}
              onClick={this.onClickSuggestion(f.path)}
            />
          ))}
        </>
      );
    }

    return (
      <div className="ESContent_fields flex flex-align-items--center overflow-scroll">
        {filteredFieldsElemts}
        {filteredSubFieldsElemts}
      </div>
    );
  }

  render() {
    const {
      sourceIncludeInput,
      filterSourceInclude,
      product,
      isLoading,
      fieldsLoaded,
    } = this.state;

    return (
      <>
        <h2>Data from Elastic Search</h2>
        <div>
          <div className="flex flex-align-items--baseline w-100 ESContent__input">
            <span>filter_source_include:</span>
            <div className="w-100 overflow-hidden">
              <input
                type="text"
                value={sourceIncludeInput}
                className="form-control"
                onChange={this.onUpdateSourceIncludeInput}
                onKeyPress={this.onKeyPress}
                placeholder="namePublicShort.data"
                ref={(ref) => {
                  this.sourceInput = ref;
                }}
              />
              <div className="w-100 text-align--right">
                <em>Press enter to add your filter</em>
              </div>
              {fieldsLoaded ? (
                this.renderSuggestions()
              ) : (
                <span>
                  <Spinner small />
                  <em className="text-muted">Loading fields...</em>
                </span>
              )}
            </div>
            <span className="ESContent__input__spinner">
              {isLoading && <Spinner small />}
            </span>
          </div>
          <div className="ESContent__filterSourceInclude">
            {filterSourceInclude.map((f) => (
              <div className="ESContent__filterSourceIncludeElement" key={f}>
                <code>{f}</code>
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={this.onRemoveFilter(f)}
                >
                  <i className="mdi mdi-close red" />
                </button>
              </div>
            ))}
          </div>
          <div className="ESContent__actions">
            <Button
              primary
              onClick={this.loadProduct}
              content={isLoading ? 'Reloading...' : 'Reload'}
              disabled={isLoading}
              displaySpinner={isLoading}
            />
          </div>
        </div>
        <div className="ESContent__content">
          <div className="ESContent__payloadContent">
            <JsonDisplayer value={product} />
          </div>
        </div>
      </>
    );
  }
}

export default ESContent;
