import React from 'react';
import AuthApi from 'resources/authApi';
import { ORGANIZATION_TYPE_THIRD_PARTY } from 'constants/organization';
import {
  LegacyAutocompleteProps,
  LegacyAutocomplete,
} from '../legacy-autocomplete';

type Props = {
  selector: (item: any) => boolean;
} & LegacyAutocompleteProps;

export const ThirdPartyAutocomplete = React.memo(
  function ThirdPartyAutocomplete({ selector, ...props }: Props) {
    const onSearch = (search) => {
      const filters: any = { types: [ORGANIZATION_TYPE_THIRD_PARTY.id] };
      if (search) {
        if (Number.isNaN(Number(search))) {
          filters.nameLegal = search;
        } else {
          filters.ids = [search];
        }
      }
      return AuthApi.OrganizationListV3(filters);
    };

    const transformData = (data) => data.map(selector);

    return (
      <LegacyAutocomplete
        onSearch={onSearch}
        transformData={transformData}
        {...props}
      />
    );
  }
);
