import { State } from './state';

export const MODULE_STORE_KEY = 'rulesetSelector';

// this replaces application global state type until defined
type SuperState = {
  [MODULE_STORE_KEY]: State;
};

export const selectState = ({ [MODULE_STORE_KEY]: s }: SuperState) => s;

export const selectAllRuleSets = (s: SuperState) => {
  return selectState(s).allRulesets;
};
