import * as kindsConstants from 'constants/events/kinds';

import kindApi from 'resources/kindApi';
import conceptApi from 'resources/conceptApi';
import { notificationError, notificationSuccess } from 'actions/notifications';

export const getKind = (id) => async (dispatch, getState) => {
  dispatch({ type: kindsConstants.GET_KIND });

  const path = [];
  findPathTo(id, getState().kinds.tree, path);

  const responses = await Promise.all(
    path.map((_id) =>
      kindApi.KindSpecific(_id, { with_excluded_referentials: true })
    )
  );

  return dispatch({
    type: kindsConstants.RECEIVE_KIND,
    data: responses.map((response) => response.data),
  });
};

export const selectKind = (id) => ({
  type: kindsConstants.SELECT_KIND,
  data: { id },
});

function findPathTo(id, tree, path) {
  if (id === tree.id) {
    path.unshift(tree.id);
    return true;
  }
  const hasAChildOnPath = tree.children.some((child) =>
    findPathTo(id, child, path)
  );
  if (hasAChildOnPath) {
    path.unshift(tree.id);
  }
  return hasAChildOnPath;
}

export const getKindList = () => async (dispatch) => {
  dispatch({ type: kindsConstants.GET_KIND_LIST });

  const response = await conceptApi.ConceptGetByName('kinds', {
    asTree: true,
    includeParent: true,
  });

  // rename root kind 'Product Category' to 'KIND'
  response.data.data[0].name = 'KIND';

  dispatch({
    type: kindsConstants.RECEIVE_KIND_LIST,
    data: response.data.data[0],
  });
};

export const updateKind =
  ({ applicableFields, excludedReferentialEntities, id, label, visible }) =>
  async (dispatch) => {
    try {
      const response = await kindApi.KindUpdate({
        applicableFields,
        excludedReferentialEntities,
        with_excluded_referentials: true,
        id,
        label,
        visible,
      });
      const { data } = response;
      dispatch({ type: kindsConstants.UPDATED_KIND, data });
      dispatch(notificationSuccess('Kind has been updated.'));
      dispatch(getKind(id));
    } catch (error) {
      dispatch(
        notificationError(`Could not update kind: ${error.data.message}`)
      );
    }
  };
