import moment, { Moment } from 'moment';

export const TIME_FORMAT_SECOND = 'ss';
export const TIME_FORMAT_SECOND_NO_DAY = 'ss no day';
export const TIME_FORMAT_MINUTE = 'mm';
export const TIME_FORMAT_MINUTE_LOCALIZED = 'mm locale';
export const TIME_FORMAT_DAY = 'DD';
export const TIME_FORMAT_ISO_8601 = 'iso';

const formatTemplates = {
  [TIME_FORMAT_SECOND]: 'YYYY-MM-DD HH:mm:ss',
  [TIME_FORMAT_SECOND_NO_DAY]: 'HH:mm:ss',
  [TIME_FORMAT_MINUTE]: 'YYYY-MM-DD HH:mm',
  [TIME_FORMAT_MINUTE_LOCALIZED]: 'lll',
  [TIME_FORMAT_DAY]: 'YYYY-MM-DD',
  [TIME_FORMAT_ISO_8601]: undefined,
};

export const formatTime = (m: Moment | string, formatId = TIME_FORMAT_MINUTE) =>
  (moment.isMoment(m) ? m : moment.utc(m).local()).format(
    formatTemplates[formatId]
  );

// to be used in presence of a non-standard datatime string pretending to express an instant in UTC timezone
export const formatTimeImplicitUTC = (
  time: string,
  formatID = TIME_FORMAT_MINUTE
) => formatTime(moment.utc(time).local(), formatID);
