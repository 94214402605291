import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Button } from '@alkem/react-ui-button';

import InputWithLabel from 'components/input-with-label';
import { Text, Checkbox } from '@alkem/react-ui-inputs';

import { SimpleSelect } from '@alkem/react-ui-select';

import { List, fromJS } from 'immutable';

import './modal.scss';

const endpointHandlerDirections = [
  {
    value: 0,
    label: 'Import',
  },
  {
    value: 1,
    label: 'Export',
  },
];

const endpointHandlerTypes = [
  {
    value: 0,
    label: 'Product',
  },
  {
    value: 1,
    label: '[DEPRECATED DO NOT USE] ProductInShop',
  },
  {
    value: 2,
    label: 'ProductExport',
  },
  {
    value: 3,
    label: 'ProductSegment',
  },
  {
    value: 4,
    label: 'ProductRange',
  },
  {
    value: 5,
    label: 'RFPImport',
  },
  {
    value: 6,
    label: 'SupplierDirectory',
  },
];

export class EndpointModalHandlers extends PureComponent {
  static propTypes = {
    endpoint: PropTypes.object,
    updateEndpoint: PropTypes.func.isRequired,
  };

  onClickAddEndpointHandler = () => {
    const { endpoint } = this.props;
    this.props.updateEndpoint(
      ['handlers'],
      endpoint.get('handlers').push(
        fromJS({
          type: 0,
          direction: 0,
          active: false,
          deleted: false,
          config: {
            path: '',
            regexp: '',
            extra: {
              fetcher_name: 'Exchange',
              spider_name: '',
            },
            additional_source_organizations: [],
          },
        })
      )
    );
  };

  onClickDeleteEndointHandler(i, handler) {
    return () =>
      this.props.updateEndpoint(
        ['handlers', i, 'deleted'],
        !handler.get('deleted')
      );
  }

  onClickDeleteFromArray(handler, i, path, index) {
    return () =>
      this.props.updateEndpoint(
        ['handlers', i, ...path],
        handler.getIn(path).delete(index)
      );
  }

  onClickAddToArray(handler, i, path) {
    return () =>
      this.props.updateEndpoint(
        ['handlers', i, ...path],
        handler.getIn(path).push(7)
      );
  }

  onUpdateTextEndpoint(i, path, cast) {
    return (e) =>
      this.props.updateEndpoint(
        ['handlers', i, ...path],
        cast ? cast(e.target.value) : e.target.value
      );
  }

  onUpdateCheckBox(i, path, handler) {
    return () =>
      this.props.updateEndpoint(['handlers', i, ...path], !handler.getIn(path));
  }

  onUpdateSelect(i, path) {
    return (e) => this.props.updateEndpoint(['handlers', i, ...path], e.value);
  }

  getHandlerConfiguration(handler) {
    const { endpoint } = this.props;
    if (endpoint.get('type') === 4) {
      return ['content_provider'];
    }
    if (
      (handler.get('type') === 0 ||
        handler.get('type') === 3 ||
        handler.get('type') === 6) &&
      handler.get('direction') === 0
    ) {
      return [
        'fetcher_name',
        'spider_name',
        'regexp',
        'path',
        'remove_after_download',
      ];
    } else if (
      endpoint.get('type') === 0 &&
      handler.get('type') === 4 &&
      handler.get('direction') === 0
    ) {
      return [
        'fetcher_name',
        'spider_name_in_dict',
        'regexp',
        'file_contains_whole_catalog',
      ];
    } else if (handler.get('type') === 5) {
      return ['regexp', 'spider_name'];
    } else if (
      endpoint.get('type') === 1 &&
      handler.get('type') === 4 &&
      handler.get('direction') === 0
    ) {
      return [
        'fetcher_name',
        'spider_name_in_dict',
        'path',
        'remove_after_download',
        'file_contains_whole_catalog',
      ];
    }
    return null;
  }

  renderHandlerTextLine(handler, i, path, tag, label) {
    return (
      <div className="EndpointModal__Line">
        <InputWithLabel inputId={`endpointhandler-${i}-${tag}`} label={label}>
          <Text
            id={`endpointhandler-${i}-${tag}`}
            value={handler.getIn(path)}
            placeholder={label}
            onChange={this.onUpdateTextEndpoint(i, path)}
          />
        </InputWithLabel>
      </div>
    );
  }

  renderHandlerCheckBox(handler, i, path, tag, label) {
    return (
      <div className="EndpointModal__Line">
        <InputWithLabel inputId={`endpointhandler-${i}-${tag}`} label="">
          <Checkbox
            id={`endpointhandler-${i}-${tag}`}
            checked={handler.getIn(path)}
            onChange={this.onUpdateCheckBox(i, path, handler)}
            label={<div className="EndpointModal_Checkbox">{label}</div>}
          />
        </InputWithLabel>
      </div>
    );
  }

  renderHandlerSelect(handler, i, path, tag, label, options) {
    return (
      <div className="EndpointModal__Line">
        <InputWithLabel inputId={`endpointhandler-${i}-${tag}`} label={label}>
          <SimpleSelect
            id={`endpointhandler-${i}-${tag}`}
            options={options}
            value={options.find((o) => o.value === handler.getIn(path))}
            onSelect={this.onUpdateSelect(i, path)}
            autoSize
          />
        </InputWithLabel>
      </div>
    );
  }

  renderAdditionalSourceOrganizations(handler, i) {
    let additionalSourceOrganizations = null;
    if (handler.getIn(['config', 'additional_source_organizations'])) {
      additionalSourceOrganizations = handler
        .getIn(['config', 'additional_source_organizations'])
        .map((entry, j) => (
          <div
            key={`endpointhandler-${i}-${JSON.stringify(entry)}-aso`}
            className="row"
          >
            <Text
              id={`endpointhandler-${i}-${j}-aso-input`}
              value={entry}
              placeholder="source organization id"
              onChange={this.onUpdateTextEndpoint(
                i,
                ['config', 'additional_source_organizations', j],
                parseInt
              )}
            />
            <Button
              link
              small
              onClick={this.onClickDeleteFromArray(
                handler,
                i,
                ['config', 'additional_source_organizations'],
                j
              )}
            >
              Delete
            </Button>
          </div>
        ));
    } else {
      this.props.updateEndpoint(
        ['handlers', i, 'config', 'additional_source_organizations'],
        List()
      );
    }
    return (
      <div className="EndpointModal__Line">
        <InputWithLabel
          inputId={`endpointhandler-${i}-aso`}
          label="Additional source organizations"
        >
          <hr />
          {additionalSourceOrganizations}
          <hr />
          <Button
            className="EndpointModal_LinkButton"
            link
            onClick={this.onClickAddToArray(handler, i, [
              'config',
              'additional_source_organizations',
            ])}
          >
            (+) Add
          </Button>
        </InputWithLabel>
      </div>
    );
  }

  renderHandler(handler, i) {
    const config = this.getHandlerConfiguration(handler);
    return (
      <div
        key={`endpoint-${i}-handler`}
        className={`EndpointModal__Form ${
          handler.get('deleted') === true ? 'EndpointModal__LineRemoved' : ''
        }`}
      >
        {this.renderHandlerCheckBox(handler, i, ['active'], 'active', 'Active')}
        {this.renderHandlerSelect(
          handler,
          i,
          ['type'],
          'type',
          'Type',
          endpointHandlerTypes
        )}
        {this.renderHandlerSelect(
          handler,
          i,
          ['direction'],
          'direction',
          'Direction',
          endpointHandlerDirections
        )}
        {(!config || config.indexOf('fetcher_name') !== -1) &&
          this.renderHandlerTextLine(
            handler,
            i,
            ['config', 'extra', 'fetcher_name'],
            'fetcher_name',
            'Fetcher'
          )}
        {config &&
          config.indexOf('content_provider') !== -1 &&
          this.renderHandlerTextLine(
            handler,
            i,
            ['config', 'content_provider'],
            'content_provider',
            'Content Provider'
          )}
        {(!config || config.indexOf('spider_name') !== -1) &&
          this.renderHandlerTextLine(
            handler,
            i,
            ['config', 'extra', 'spider_name'],
            'spider_name',
            'Spider'
          )}
        {(!config || config.indexOf('spider_name_in_dict') !== -1) &&
          this.renderHandlerTextLine(
            handler,
            i,
            ['config', 'extra', 'spider', 'name'],
            'name',
            'Spider'
          )}
        {(!config || config.indexOf('regexp') !== -1) &&
          this.renderHandlerTextLine(
            handler,
            i,
            ['config', 'regexp'],
            'regexp',
            'Regexp'
          )}
        {(!config || config.indexOf('filename') !== -1) &&
          this.renderHandlerTextLine(
            handler,
            i,
            ['config', 'filename'],
            'filename',
            'Filename'
          )}
        {(!config || config.indexOf('path') !== -1) &&
          this.renderHandlerTextLine(
            handler,
            i,
            ['config', 'path'],
            'path',
            'Path'
          )}
        {(!config || config.indexOf('shop_id') !== -1) &&
          this.renderHandlerTextLine(
            handler,
            i,
            ['config', 'shop_id'],
            'shop_id',
            'Shop.ID'
          )}
        {(!config || config.indexOf('which') !== -1) &&
          this.renderHandlerTextLine(
            handler,
            i,
            ['config', 'extra', 'which'],
            'which',
            'Which'
          )}
        {(!config || config.indexOf('generic') !== -1) &&
          this.renderHandlerCheckBox(
            handler,
            i,
            ['config', 'extra', 'generic'],
            'generic',
            'Generic'
          )}
        {(!config || config.indexOf('file_contains_whole_catalog') !== -1) &&
          this.renderHandlerCheckBox(
            handler,
            i,
            ['config', 'extra', 'file_contains_whole_catalog'],
            'file_contains_whole_catalog',
            'File contains whole catalog'
          )}
        {handler.getIn(['config', 'extra', 'spider', 'name']) === 'ART04' &&
          handler.get('direction') === 0 &&
          this.renderHandlerCheckBox(
            handler,
            i,
            ['config', 'extra', 'spider', 'compute_upsert_mode'],
            'compute_upsert_mode',
            'Compute upsert mode'
          )}
        {(!config || config.indexOf('remove_after_download') !== -1) &&
          this.renderHandlerCheckBox(
            handler,
            i,
            ['config', 'remove_after_download'],
            'remove_after_download',
            'Remove file after download'
          )}
        {(!config || config.indexOf('file_contains_whole_catalog') !== -1) &&
          this.renderHandlerCheckBox(
            handler,
            i,
            ['config', 'extra', 'internal_reference_buster'],
            'internal_reference_buster',
            'Internal reference buster'
          )}
        {(!config || config.indexOf('upload_with_tmp_name') !== -1) &&
          this.renderHandlerCheckBox(
            handler,
            i,
            ['config', 'upload_with_tmp_name'],
            'upload_with_tmp_name',
            'Upload with tmp name'
          )}
        {handler.get('direction') === 1 &&
          this.renderAdditionalSourceOrganizations(handler, i)}
        <div className="EndpointModal__Line">
          <InputWithLabel
            inputId={`endpointhandler-${i}-delete`}
            label="Action"
          >
            <div className="col-xs-1 EndpointModal_LinkButton">
              <Button
                link
                small
                onClick={this.onClickDeleteEndointHandler(i, handler)}
              >
                {handler.get('deleted') === true ? 'Cancel' : 'Delete'}
              </Button>
            </div>
          </InputWithLabel>
        </div>
        <hr className="EndpointModal__Hr" />
      </div>
    );
  }

  render() {
    const { endpoint } = this.props;
    const endpointHandlers = endpoint
      .get('handlers')
      .map((handler, i) => this.renderHandler(handler, i));
    return (
      <div className="EndpointModal__Form">
        <h4>Handlers</h4>
        {endpointHandlers}
        <div className="EndpointModal__Line EndpointModalHandler__AddHandler">
          <Button link onClick={this.onClickAddEndpointHandler}>
            (+) Add
          </Button>
        </div>
      </div>
    );
  }
}
