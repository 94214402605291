import { ruleTemplateLabels } from 'modules/validation-dashboard/constants';
import { Rule } from 'modules/validation-dashboard/types';

interface Props {
  rule: Rule;
}

export const RuleName = ({ rule }: Props) => {
  const fieldNames = rule.root_fields?.map((fieldName) => (
    <span key={fieldName}>
      <code>{fieldName}</code>{' '}
    </span>
  ));
  let ruleName = '';
  if (rule.name) {
    ruleName = rule.name;
  } else if (rule.template?.label === ruleTemplateLabels.CUSTOM) {
    ruleName = rule.errorMessage;
  } else {
    ruleName = rule.errorMessage.replace('<code>%s</code>', '');
  }
  return (
    <b>
      {ruleName} {fieldNames}
    </b>
  );
};
