import './header.scss';

export function HistoryDiffHeader() {
  return (
    <div className="ProductHistoryDiffCard__header row">
      <div className="col-xs-4">Field name</div>
      <div className="col-xs-4">Old value</div>
      <div className="col-xs-4">New value</div>
    </div>
  );
}
