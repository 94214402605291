import { Button } from '@alkem/react-ui-button';
import { Checkbox } from '@alkem/react-ui-checkbox';
import { Ellitips } from '@alkem/react-ui-ellitips';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import FilterTreeLinkItem from './link';
import './tree.scss';

const listLimit = 10;

class FilterTree extends React.PureComponent {
  static propTypes = {
    filters: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    hasLinks: PropTypes.bool,
  };

  static defaultProps = {
    hasLinks: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.onButtonClick = this.onButtonClick.bind(this);
  }

  onButtonClick() {
    this.setState((prevState) => ({ open: !prevState.open }));
  }

  renderButton() {
    const { filters } = this.props;
    if (filters.length > listLimit && !this.state.open) {
      return (
        <Button
          link
          className="FilterTree__seeMore"
          onClick={this.onButtonClick}
          content="Show more"
        />
      );
    } else if (this.state.open) {
      return (
        <Button
          link
          className="FilterTree__seeLess"
          onClick={this.onButtonClick}
          content="Show less"
        />
      );
    }
    return null;
  }

  renderList() {
    const { hasLinks, filters, name } = this.props;
    let filterList = filters;
    if (hasLinks) {
      filterList = filters.filter((f) => !f.link);
    }
    return filterList.map((filter, i) => {
      if (i >= listLimit && !this.state.open) {
        return null;
      }
      const key = `${name}-${i}`;
      return this.renderFilter(filter, key);
    });
  }

  renderLinkList() {
    const { hasLinks, filters, name, onChange } = this.props;
    if (!hasLinks) {
      return null;
    }
    return (
      <ul className="FilterTree__links">
        {filters
          .filter((f) => f.link)
          .map((filter) => (
            <FilterTreeLinkItem
              key={filter.name}
              tree={filter}
              name={name}
              onChange={onChange}
            />
          ))}
      </ul>
    );
  }

  renderFilter(filter, key) {
    const { name, onChange } = this.props;
    return (
      <FilterTreeItem key={key} tree={filter} name={name} onChange={onChange} />
    );
  }

  render() {
    const more = this.renderButton();
    const list = this.renderList();
    return (
      <div className="FilterTree">
        <ul className="FilterTree__list">{list}</ul>
        {this.renderLinkList()}
        {more}
      </div>
    );
  }
}

export default FilterTree;

function FilterTreeItem(props) {
  const [visible, setVisible] = React.useState(false);

  const onToggle = React.useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  const onChange = React.useCallback(() => {
    const { tree, name } = props;
    props.onChange(name, tree, !props.tree.checked);
  }, [props]);

  const { tree, name } = props;
  if (!tree) {
    return null;
  }

  let toggle;
  if (tree.children && tree.children.length > 0) {
    const iconClasses = {
      TreeItem__chevron: true,
      mdi: true,
      'mdi-chevron-down': visible,
      'mdi-chevron-right': !visible,
    };
    toggle = <span className={classNames(iconClasses)} onClick={onToggle} />;
  }
  const id = `${name}-${tree.value}`;
  const count = tree.count != null ? ` (${tree.count})` : '';
  let { label } = tree;
  if (tree.node) {
    label = tree.node;
  } else if (tree.iconClass) {
    label = (
      <span className="FilterTreeItem__label">
        <span
          className={classNames('mdi', tree.iconClass, 'ItemTree__icon', {
            [`ItemTree__icon--${tree.iconColor}`]: tree.iconColor,
          })}
        />
        {tree.label}
      </span>
    );
  } else {
    label = <Ellitips id={id} label={tree.label + count} place="right" />;
  }

  return (
    <li className="TreeItem">
      {toggle}
      <Checkbox
        type="checkbox"
        onChange={onChange}
        checked={tree.checked}
        id={id}
        label={label}
      />
      {!!(tree.children && tree.children.length && visible) && (
        <div className="TreeItem__children">
          <FilterTree
            filters={tree.children}
            onChange={props.onChange}
            name={props.name}
          />
        </div>
      )}
    </li>
  );
}

FilterTreeItem.propTypes = {
  tree: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
