import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { getIn } from 'utils/immutable';

import { Button, AddButton } from '@alkem/react-ui-button';
import DisplayGroupPickerModal from './display-group-picker-modal';

import './display-group-picker.scss';

export default class DisplayGroupPicker extends PureComponent {
  static propTypes = {
    link: PropTypes.object,
    fieldId: PropTypes.number,
    save: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
    };

    this.onSave = this.onSave.bind(this);
    this.onOpenModal = this.onToggleModal.bind(this, true);
    this.onCloseModal = this.onToggleModal.bind(this, false);
  }

  onToggleModal(isModalOpen) {
    this.setState({ isModalOpen });
  }

  onSave(link) {
    this.onCloseModal();
    this.props.save(link);
  }

  renderLink() {
    const { link, readOnly } = this.props;
    return (
      <div className="DisplayGroupPicker__link">
        <div className="DisplayGroupPicker__linkLabel">
          {getIn(link, ['displayGroup', 'label'])}
        </div>
        {!readOnly && (
          <Button
            id="display-group-edit-btn"
            content="Edit"
            onClick={this.onOpenModal}
            primary
          />
        )}
      </div>
    );
  }

  render() {
    const { link, fieldId, readOnly } = this.props;
    const { isModalOpen } = this.state;
    return (
      <div className="DisplayGroupPicker">
        {!readOnly && !link && (
          <AddButton id="display-group-add-btn" onClick={this.onOpenModal}>
            Place
          </AddButton>
        )}
        {!!link && this.renderLink()}
        {!readOnly && isModalOpen && (
          <DisplayGroupPickerModal
            link={link}
            fieldId={fieldId}
            save={this.onSave}
            cancel={this.onCloseModal}
          />
        )}
      </div>
    );
  }
}
