import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { fromJS } from 'immutable';

import { Button } from '@alkem/react-ui-button';
import { Modal } from '@alkem/react-ui-modal';
import { SimpleSelect } from '@alkem/react-ui-select';
import Well from '@alkem/react-ui-well';

import { OrganizationAutocomplete } from 'components/autocomplete';

import './organizations.scss';

const types = {
  0: { id: 0, label: 'Shared' },
  1: { id: 1, label: 'Private' },
  2: { id: 2, label: 'Public' },
  3: { id: 3, label: 'Exclusive' },
};

export default class FieldOrganizations extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    organizations: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    isConsumerUnitField: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      editedOrganizations: [],
    };

    this.openModal = this.toggleModal.bind(this, true);
  }

  onAddOrganization = (data) => {
    const { isConsumerUnitField } = this.props;
    if (data && data.value) {
      const { editedOrganizations } = this.state;
      const index = editedOrganizations.findIndex(
        (o) => o.getIn(['organization', 'id']) === data.value.id
      );
      if (index === -1) {
        this.setState({
          editedOrganizations: editedOrganizations.unshift(
            fromJS({
              organization: { id: data.value.id },
              type: isConsumerUnitField ? null : types[0].id,
            })
          ),
        });
      }
    }
  };

  onUpdateType = (orgId) => (type) => {
    const { editedOrganizations } = this.state;
    const index = editedOrganizations.findIndex(
      (o) => o.getIn(['organization', 'id']) === orgId
    );
    if (index !== -1) {
      this.setState({
        editedOrganizations: editedOrganizations.setIn(
          [index, 'type'],
          type.id
        ),
      });
    }
  };

  onDelete = (orgId) => () => {
    const { editedOrganizations } = this.state;
    this.setState({
      editedOrganizations: editedOrganizations.filter(
        (org) => org.getIn(['organization', 'id']) !== orgId
      ),
    });
  };

  applyChanges = () => {
    this.props.onChange(this.state.editedOrganizations);
    this.toggleModal(false);
  };

  discardChanges = () => {
    this.toggleModal(false);
  };

  formatOrganization = (value) => ({
    key: value.id,
    value,
    label: `${value.nameLegal ? value.nameLegal : ''} - ${value.id}`,
  });

  toggleModal(modalOpen) {
    this.setState({
      modalOpen,
      editedOrganizations: modalOpen ? this.props.organizations : [],
    });
  }

  renderSummary() {
    const { organizations } = this.props;
    return (
      <Well content={`${organizations.size} organization(s)`}>
        <pre className="FieldOrganizations__selectedList">
          {organizations
            .sortBy((org) => org.getIn(['organization', 'id']))
            .map((org) => (
              <div key={org.getIn(['organization', 'id'])}>
                {types[org.get('type')].label}
                &nbsp;
                {org.getIn(['organization', 'id'])}
              </div>
            ))}
        </pre>
      </Well>
    );
  }

  renderModal() {
    const { readOnly } = this.props;
    const { modalOpen } = this.state;

    if (readOnly) {
      return null;
    }

    return (
      <>
        <Button content="Edit/view" onClick={this.openModal} secondary />
        {modalOpen && (
          <Modal
            title="Select organizations"
            confirmButtonText="Apply"
            onConfirm={this.applyChanges}
            onClose={this.discardChanges}
          >
            {this.renderModalBody()}
          </Modal>
        )}
      </>
    );
  }

  renderModalBody() {
    const { readOnly, isConsumerUnitField } = this.props;
    const { editedOrganizations } = this.state;
    return (
      <div className="FieldOrganizations__modalBody">
        <div className="FieldOrganizations__add">
          <OrganizationAutocomplete
            id="field-organization-add"
            value={[]}
            onSelect={this.onAddOrganization}
            onUnselect={this.onAddOrganization}
            placeholder="Add a new organization"
            searchOnClick
            selector={this.formatOrganization}
            disabled={readOnly}
          />
        </div>
        {editedOrganizations.map((org) => (
          <div
            className="FieldOrganizations__item"
            key={org.getIn(['organization', 'id'])}
          >
            {isConsumerUnitField && (
              <SimpleSelect
                id={`organization-type-${org.getIn(['organization', 'id'])}`}
                options={Object.values(types)}
                value={types[org.get('type')] || null}
                onSelect={this.onUpdateType(org.getIn(['organization', 'id']))}
                autoSize
                disabled={readOnly}
              />
            )}
            <div>{org.getIn(['organization', 'id'])}</div>
            {!readOnly && (
              <span
                className="mdi mdi-delete"
                onClick={this.onDelete(org.getIn(['organization', 'id']))}
              />
            )}
          </div>
        ))}
      </div>
    );
  }

  render() {
    return (
      <div className="FieldOrganizations" id={this.props.id}>
        {this.renderSummary()}
        {this.renderModal()}
      </div>
    );
  }
}
