import { notificationError } from 'actions/notifications';
import { get } from 'lodash';
import {
  call,
  fork,
  put,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { logError } from 'utils';

export * from 'redux-saga/effects';

export function withCatch(
  saga: any,
  {
    withNotification = false,
    errorMessage = '',
    withLog = false,
    errorAction = null,
  }: {
    withNotification?: boolean;
    errorMessage?: string;
    withLog?: boolean;
    errorAction?: string | null;
  } = {}
) {
  return function* withCatchSaga(...args) {
    try {
      yield call(saga, ...args);
    } catch (error) {
      if (withNotification) {
        yield put(
          notificationError(
            errorMessage ||
              get(error, ['data', 'message']) ||
              get(error, ['message'])
          )
        );
        if (errorAction) {
          yield put({ type: errorAction });
        }
      }
      if (withLog) {
        logError(error);
      }
    }
  };
}

export const takeFirst = (pattern, saga, ...args) =>
  fork(function* () {
    while (true) {
      const action = yield take(pattern);
      yield call(saga, ...args.concat(action));
    }
  });

export function takeLatestSafe(action, saga, catchParams?) {
  return takeLatest(action, withCatch(saga, catchParams));
}

export function takeEverySafe(action, saga, catchParams?) {
  return takeEvery(action, withCatch(saga, catchParams));
}
