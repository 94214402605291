import PropTypes from 'prop-types';
import React, { Component } from 'react';

class SelectedFiltersTag extends Component {
  static propTypes = {
    tag: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    removeFilter: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.removeFilter = this.removeFilter.bind(this);
  }

  removeFilter() {
    const { name, tag, removeFilter } = this.props;
    removeFilter(name, tag);
  }

  render() {
    const { tag } = this.props;
    return (
      <div className="SelectedFilters__chip">
        <label htmlFor="selected-filters-tag">{tag.label}</label>
        <span
          id="selected-filters-tag"
          className="SelectedFilters__removeFilter mdi mdi-close-circle"
          onClick={this.removeFilter}
        />
      </div>
    );
  }
}

export default SelectedFiltersTag;
