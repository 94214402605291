import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { separateActions } from 'utils/redux';
import { TwinPanel } from '@alkem/react-ui-twinpanel';
import { HeaderLayout } from '@alkem/react-layout';
import * as routes from 'constants/routes';
import { withRouter } from 'react-router-dom';
import { fill } from 'utils/routes';
import {
  selectAttributeList,
  selectFunctionList,
  selectCategoryList,
  selectAttributeDetails,
  selectFunctionDetails,
  selectCategoryAttributeList,
} from '../selectors';
import {
  getAttributeList,
  getFunctionList,
  getCategoryList,
  getAttribute,
  getFunction,
  getCategoryAttributeList,
  saveAttribute,
} from '../actions';
import LeftPanelHeader from './left-panel-header';
import LeftPanel from './left-panel';
import RightPanelHeader from './right-panel-header';
import RightPanelAttribute from './right-panel-attributes';
import RightPanelFunction from './right-panel-functions';
import RightPanelCategoryAttributes from './right-panel-category-attributes';
import { AFS_OPTIONS } from '../constants/constants';
import './view.scss';
import * as selectors from '../selectors';

const mapStateToProps = (state) => ({
  attributeList: selectAttributeList(state),
  functionList: selectFunctionList(state),
  categoryList: selectCategoryList(state),
  selectedAttributeVersions: selectAttributeDetails(state),
  selectedFunctionDetails: selectFunctionDetails(state),
  selectedCategoryAttributeList: selectCategoryAttributeList(state),
  saveInProgress: selectors.selectSaveInProgress(state),
});

const mapDispatchToProps = {
  getAttributeList,
  getAttribute,
  saveAttribute,
  getFunctionList,
  getFunction,
  getCategoryList,
  getCategoryAttributeList,
};

const options = [
  {
    value: AFS_OPTIONS.CATEGORIES,
    label: 'Product categories',
  },
  {
    value: AFS_OPTIONS.ATTRIBUTES,
    label: 'Attributes',
  },
  {
    value: AFS_OPTIONS.FUNCTIONS,
    label: 'Functions',
  },
];

export class MapperAFSDashboardView extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    attributeList: PropTypes.array,
    functionList: PropTypes.array,
    categoryList: PropTypes.array,
    selectedAttributeVersions: PropTypes.array,
    selectedAttributeName: PropTypes.string,
    sourceAttribute: PropTypes.object,
    editedAttribute: PropTypes.object,
    dispatch: PropTypes.func,
    readOnly: PropTypes.bool,
    saveInProgress: PropTypes.bool,
    selectedFunctionDetails: PropTypes.array,
    selectedFunctionId: PropTypes.number,
    selectedCategoryAttributeList: PropTypes.array,
    selectedCategoryId: PropTypes.number,
    currentVersion: PropTypes.string,
    actions: PropTypes.shape({
      getAttributeList: PropTypes.func.isRequired,
      getAttribute: PropTypes.func.isRequired,
      saveAttribute: PropTypes.func.isRequired,
      getFunctionList: PropTypes.func.isRequired,
      getFunction: PropTypes.func.isRequired,
      getCategoryList: PropTypes.func.isRequired,
      getCategoryAttributeList: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      search: '',
      currentOption: null,
    };
  }

  componentDidMount() {
    if (typeof this.props.match.path !== 'undefined') {
      const path = this.props.match.path;
      const { id, name } = this.props.match.params;
      if (path.includes('categories')) {
        this.onSelectOption(options[AFS_OPTIONS.CATEGORIES]);
        if (typeof id !== 'undefined') {
          this.onSelectCategory(parseInt(id));
        }
      }
      if (path.includes('attributes')) {
        this.onSelectOption(options[AFS_OPTIONS.ATTRIBUTES]);
        if (typeof name !== 'undefined') {
          this.onSelectAttribute(name);
        }
      }
      if (path.includes('functions')) {
        this.onSelectOption(options[AFS_OPTIONS.FUNCTIONS]);
        if (typeof id !== 'undefined') {
          this.onSelectFunction(parseInt(id));
        }
      }
    }
  }

  onSelectAttribute = (attributeName) => {
    this.props.actions.getAttribute(attributeName);
    this.setState({ selectedAttributeName: attributeName });
    this.setState({ currentVersion: null });
    this.props.history.push(fill(routes.ioMapperAfsAttribute, attributeName));
  };

  onSelectFunction = (functionId) => {
    this.props.actions.getFunction(functionId);
    this.setState({ selectedFunctionId: functionId });
    this.props.history.push(fill(routes.ioMapperAfsFunction, functionId));
  };

  onSelectCategory = (categoryId) => {
    this.props.actions.getCategoryAttributeList(categoryId);
    this.setState({ selectedCategoryId: categoryId });
    this.setState({ currentVersion: null });
    this.props.history.push(fill(routes.ioMapperAfsCategory, categoryId));
  };

  onSelectOption = (option) => {
    const { search } = this.state;
    this.setState({ currentOption: option });
    if (option.value === AFS_OPTIONS.CATEGORIES) {
      this.props.actions.getCategoryList();
      this.setState({ selectedCategoryId: null });
      this.props.history.push(fill(routes.ioMapperAfsCategories));
    } else if (option.value === AFS_OPTIONS.ATTRIBUTES) {
      this.props.actions.getAttributeList(search);
      this.setState({ selectedAttributeName: null });
      this.props.history.push(fill(routes.ioMapperAfsAttributes));
    } else if (option.value === AFS_OPTIONS.FUNCTIONS) {
      this.props.actions.getFunctionList();
      this.setState({ selectedFunctionId: null });
      this.props.history.push(fill(routes.ioMapperAfsFunctions));
    }
  };

  onUpdateSearch = (search) => {
    const { currentOption } = this.state;
    this.setState({ search });
    if (currentOption.value === AFS_OPTIONS.CATEGORIES) {
      this.props.actions.getCategoryList(search);
    } else if (currentOption.value === AFS_OPTIONS.ATTRIBUTES) {
      this.props.actions.getAttributeList(search);
    } else if (currentOption.value === AFS_OPTIONS.FUNCTIONS) {
      this.props.actions.getFunctionList(search);
    }
  };

  onSelectVersion = (version) => {
    this.setState({ currentVersion: version });
  };

  getFilteredAttributesOnVersion(attributes) {
    const { currentOption, currentVersion } = this.state;
    if (!attributes) {
      return attributes;
    }
    const sortedAttributes = attributes.sort((a, b) =>
      a.end_validity > b.end_validity ? -1 : 1
    );
    if (currentOption.value === AFS_OPTIONS.CATEGORIES) {
      if (currentVersion) {
        return sortedAttributes.filter(
          (attr) => attr.version === currentVersion.value
        );
      }
    } else if (currentOption.value === AFS_OPTIONS.ATTRIBUTES) {
      if (currentVersion) {
        return sortedAttributes.filter(
          (attr) => attr.version === currentVersion.value
        );
      }
    }
    return sortedAttributes;
  }

  isCategoryMappingStatusOk(attributes) {
    if (attributes) {
      return !attributes.some((attr) => attr.is_attribute_complete === false);
    }
    return false;
  }

  renderLeftHeader() {
    const { search, currentOption } = this.state;
    return (
      <LeftPanelHeader
        options={options}
        currentOption={currentOption}
        search={search}
        updateSearch={this.onUpdateSearch}
        selectOption={this.onSelectOption}
      />
    );
  }

  renderLeftPanel() {
    const { attributeList, functionList, categoryList } = this.props;
    const { currentOption } = this.state;
    if (!currentOption) {
      return null;
    }
    return (
      <LeftPanel
        attributeList={attributeList}
        functionList={functionList}
        categoryList={categoryList}
        currentOption={currentOption}
        selectAttribute={this.onSelectAttribute}
        selectFunction={this.onSelectFunction}
        selectCategory={this.onSelectCategory}
      />
    );
  }

  onSaveAttribute = (editedAttribute) => {
    this.props.actions.saveAttribute(editedAttribute);
  };

  getVersions(attributes) {
    if (!attributes) {
      return [];
    }
    const versionsSet = [...new Set(attributes.map((item) => item.version))];
    const versions = [];
    versionsSet.forEach((element) =>
      versions.push({ value: element, label: element })
    );
    return versions;
  }

  getRightPanelTitle() {
    const { currentOption, currentVersion } = this.state;
    if (!currentOption) {
      return null;
    }
    switch (currentOption.value) {
      case AFS_OPTIONS.ATTRIBUTES: {
        const { selectedAttributeVersions } = this.props;
        const { selectedAttributeName } = this.state;
        if (!selectedAttributeVersions || !selectedAttributeName) {
          return 'Select an attribute';
        }
        let sortedSelectedAttributeVersions = selectedAttributeVersions.sort(
          (a, b) => (a.end_validity > b.end_validity ? -1 : 1)
        );
        let editedAttribute = sortedSelectedAttributeVersions[0] || {};
        let versions = this.getVersions(sortedSelectedAttributeVersions);
        return (
          <RightPanelHeader
            id={editedAttribute.id}
            name={editedAttribute.name}
            status={editedAttribute.is_attribute_complete}
            versions={versions}
            currentVersion={currentVersion}
            selectVersion={this.onSelectVersion}
          />
        );
      }
      case AFS_OPTIONS.FUNCTIONS: {
        const { selectedFunctionDetails } = this.props;
        const { selectedFunctionId } = this.state;
        if (!selectedFunctionDetails || !selectedFunctionId) {
          return 'Select a function';
        }
        return (
          <RightPanelHeader
            id={selectedFunctionDetails[0].id}
            name={selectedFunctionDetails[0].name}
          />
        );
      }
      case AFS_OPTIONS.CATEGORIES: {
        const { categoryList, selectedCategoryAttributeList } = this.props;
        const { selectedCategoryId } = this.state;
        if (!selectedCategoryId || !categoryList) {
          return 'Select a category';
        }
        let selectedCategory =
          categoryList.find((att) => att.id === selectedCategoryId) || {};
        let versions = this.getVersions(selectedCategoryAttributeList);
        return (
          <RightPanelHeader
            id={selectedCategory.id}
            name={selectedCategory.name}
            status={this.isCategoryMappingStatusOk(
              selectedCategoryAttributeList
            )}
            versions={versions}
            currentVersion={currentVersion}
            selectVersion={this.onSelectVersion}
          />
        );
      }
    }

    return currentOption.label;
  }

  renderRightPanel() {
    const {
      selectedAttributeVersions,
      selectedFunctionDetails,
      selectedCategoryAttributeList,
    } = this.props;
    const { currentOption } = this.state;
    if (!currentOption) {
      return null;
    }
    if (currentOption.value === AFS_OPTIONS.ATTRIBUTES) {
      const { selectedAttributeName, currentVersion } = this.state;
      if (!selectedAttributeName || !selectedAttributeVersions) {
        return null;
      }
      if (!this.props.functionList) {
        this.props.actions.getFunctionList();
      }
      return (
        <RightPanelAttribute
          selectedAttributeVersionList={this.getFilteredAttributesOnVersion(
            selectedAttributeVersions
          )}
          currentVersion={currentVersion}
          onSaveAttribute={this.onSaveAttribute}
        />
      );
    }
    if (currentOption.value === AFS_OPTIONS.FUNCTIONS) {
      const { selectedFunctionId } = this.state;
      if (!selectedFunctionId) {
        return null;
      }
      return (
        <RightPanelFunction selectedFunctionList={selectedFunctionDetails} />
      );
    }
    if (currentOption.value === AFS_OPTIONS.CATEGORIES) {
      const { selectedCategoryId, currentVersion } = this.state;
      if (!selectedCategoryId) {
        return null;
      }
      return (
        <RightPanelCategoryAttributes
          selectedCategoryAttributeList={this.getFilteredAttributesOnVersion(
            selectedCategoryAttributeList
          )}
          currentVersion={currentVersion}
        />
      );
    }
  }

  render() {
    const headerProps = {
      title: 'Mapper AFS',
      backHref: routes.home,
      backMessage: 'Back home',
      isTitleSmall: true,
    };
    return (
      <div>
        <HeaderLayout {...headerProps} />
        <div className="AFSPage__content">
          <TwinPanel
            leftPanel={{
              title: this.renderLeftHeader(),
              content: this.renderLeftPanel(),
              className: 'AFSPage__panel--left',
            }}
            rightPanel={{
              title: this.getRightPanelTitle(),
              content: this.renderRightPanel(),
              className: 'AFSPage__panel--right',
            }}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(withRouter(MapperAFSDashboardView));
