import { Chart, ReactGoogleChartEvent } from 'react-google-charts';
import { useState } from 'react';
import { formatGraph, getTimeSpan } from './utils';

export type Trace = {
  [key: string]: {
    start: string;
    end: string;
    delta: number;
    duration: string;
  };
};

type Props = {
  trace: Trace;
};

const options = {
  gantt: {
    barHeight: 10,
  },
  backgroundColor: '000000',
};

const ProductTimeline = ({ trace }: Props) => {
  const { graphs, rows, allTask } = getTimeSpan(trace);
  const [currentData, setCurrentData] = useState(
    formatGraph(allTask, graphs[''], null)
  );

  const selectEvent: ReactGoogleChartEvent = {
    eventName: 'select',
    callback({ chartWrapper }) {
      const selection = chartWrapper.getChart().getSelection();
      if (selection.length === 1) {
        const row = selection[0].row;
        const datatable = chartWrapper.getDataTable();
        if (datatable) {
          const newGraph: string = String(datatable.getValue(row, 0));
          if (newGraph in graphs) {
            const splitNewGraph = newGraph.split('.');
            const newGraphDeps =
              newGraph === ''
                ? []
                : Array.from(Array(splitNewGraph.length).keys()).map(
                    (j) => rows[splitNewGraph.slice(0, j + 1).join('.')]
                  );
            const newData = formatGraph(
              allTask,
              graphs[newGraph],
              newGraphDeps
            );
            chartWrapper.setOption('height', 27 * newData.length);
            setCurrentData(newData);
          }
        }
      }
    },
  };
  const chartEvents = [selectEvent];

  return (
    <div>
      <Chart
        chartType="Gantt"
        width="100%"
        height={27 * currentData.length}
        options={options}
        data={currentData}
        chartEvents={chartEvents}
      />
    </div>
  );
};

export default ProductTimeline;
