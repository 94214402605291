import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Modal } from '@alkem/react-ui-modal';
import { SplitButton } from '@alkem/react-ui-button';
import { SimpleSelect } from '@alkem/react-ui-select';
import { Text } from '@alkem/react-ui-inputs';

import './tree-header.scss';

function validateRootName(name) {
  return /^[a-z]+([A-Z][a-z]+)*$/.test(name); // allow only CamelCase letters only based name
}

export default class FieldTreeHeader extends PureComponent {
  static propTypes = {
    roots: PropTypes.object.isRequired,
    currentRoot: PropTypes.object.isRequired,
    selectRoot: PropTypes.func.isRequired,
    createRoot: PropTypes.func.isRequired,
    createField: PropTypes.func.isRequired,
    canCreate: PropTypes.bool.isRequired,
    refreshCache: PropTypes.func.isRequired,
    startBulkEdit: PropTypes.func.isRequired,
    search: PropTypes.string,
    updateSearch: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
  };

  state = {
    newRootName: '',
    creationModalOpen: false,
  };

  onSelectRoot = (root) => {
    this.props.selectRoot(root);
  };

  onOpenCreateRootModal = () => {
    this.setState({ newRootName: '', creationModalOpen: true });
  };

  onCloseCreateRootModal = () => {
    this.setState({ newRootName: '', creationModalOpen: false });
  };

  onUpdateNewRootName = (event) => {
    this.setState({ newRootName: event.target.value });
  };

  onCreateRoot = () => {
    this.props.createRoot(this.state.newRootName);
    this.onCloseCreateRootModal();
  };

  onUpdateSearch = (event) => {
    this.props.updateSearch(event.target.value);
  };

  renderRootCreationModal() {
    const { newRootName } = this.state;
    const isValid = validateRootName(newRootName);
    return (
      <Modal
        title="New field tree"
        confirmButtonText="Create"
        confirmDisabled={!newRootName || !isValid}
        onConfirm={this.onCreateRoot}
        onClose={this.onCloseCreateRootModal}
      >
        <Text
          id="new-root-name"
          placeholder="Enter the new tree root name"
          invalid={!isValid}
          onChange={this.onUpdateNewRootName}
          value={newRootName}
        />
        {!isValid && <p>Use pascalCase (ex: consumerUnit)</p>}
      </Modal>
    );
  }

  render() {
    const {
      roots,
      currentRoot,
      createField,
      canCreate,
      refreshCache,
      startBulkEdit,
      search,
      readOnly,
    } = this.props;
    const { creationModalOpen } = this.state;
    return (
      <div className="FieldTreeHeader">
        <div className="FieldTreeHeader__root row">
          <div className={readOnly ? 'col-xs-12' : 'col-xs-6'}>
            <SimpleSelect
              id="field-roots"
              options={roots.toJS()}
              value={currentRoot}
              onSelect={this.onSelectRoot}
              autoSize
            />
          </div>
          {!readOnly && (
            <div className="col-xs-6">
              <SplitButton
                id="create-field"
                text="Create new field"
                onClick={createField}
                disabled={!canCreate}
                primary
                alternativeOptions={[
                  {
                    text: 'Create new tree',
                    onClick: this.onOpenCreateRootModal,
                  },
                  {
                    text: 'Refresh cache',
                    onClick: refreshCache,
                  },
                  {
                    text: 'Bulk edit',
                    onClick: startBulkEdit,
                  },
                ]}
              />
            </div>
          )}
        </div>
        <div className="FieldTreeHeader__search">
          <Text
            id="field-search"
            placeholder="Filter fields by name or tag"
            onChange={this.onUpdateSearch}
            value={search}
          />
        </div>
        {creationModalOpen && this.renderRootCreationModal()}
      </div>
    );
  }
}
