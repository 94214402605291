import { Text } from '@alkem/react-ui-inputs';
import { Modal } from '@alkem/react-ui-modal';
import { Spinner } from '@alkem/react-ui-spinner';
import classNames from 'classnames';
import InputWithLabel from 'components/input-with-label';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Dropzone from 'react-dropzone';
import ImmutablePropTypes from 'react-immutable-proptypes';
import './index.scss';

export default class OrganizationGroupEditModal extends PureComponent {
  static propTypes = {
    group: ImmutablePropTypes.map.isRequired,
    uploading: PropTypes.bool.isRequired,
    saving: PropTypes.bool.isRequired,
    updateName: PropTypes.func.isRequired,
    upload: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
  };

  uploadPicture = (pictures) => {
    const { upload } = this.props;
    if (pictures && pictures.length > 0) {
      upload(pictures[0]);
    }
  };

  onUpdateName = (event) => {
    const { updateName } = this.props;
    updateName(event.target.value);
  };

  closeModal = () => {
    const { uploading, saving, close } = this.props;
    if (!uploading && !saving) {
      close();
    }
  };

  renderPictureDrop() {
    const { uploading, group } = this.props;
    if (!group.get('id')) {
      return null;
    }
    return (
      <Dropzone onDrop={this.uploadPicture}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div
            {...getRootProps({
              className: classNames(
                'Dropzone',
                isDragActive && 'Dropzone--active'
              ),
            })}
          >
            {uploading ? (
              <Spinner big />
            ) : (
              <>
                <input {...getInputProps()} />
                <div>
                  <strong>Drop a new picture here</strong> or click to select
                  one
                </div>
              </>
            )}
          </div>
        )}
      </Dropzone>
    );
  }

  render() {
    const { group, uploading, saving, save } = this.props;
    return (
      <Modal
        modalStyle="dynamic"
        title={group.get('name') || 'New group'}
        confirmButtonText="Save"
        isProcessing={uploading || saving}
        onConfirm={save}
        onClose={this.closeModal}
      >
        {this.renderPictureDrop()}
        <div className="OrganizationGroupEditModal__name">
          <InputWithLabel
            inputId="OrganizationGroupEditModal-edit-name"
            label="Name"
          >
            <Text
              id="OrganizationGroupEditModal-edit-name"
              type="text"
              value={group.get('name')}
              onChange={this.onUpdateName}
              disabled={saving}
            />
          </InputWithLabel>
        </div>
      </Modal>
    );
  }
}
