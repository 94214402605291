import {
  ORGANIZATION_TYPE_MAPPING,
  STATUS_ACTIVE,
} from 'constants/organization';
import { fromJS, Set as ImmutableSet, List as ImmutableList } from 'immutable';
import { get, getIn } from 'utils/immutable';
import { State } from './state';

export const MODULE_STORE_KEY = 'organizationUsesFields';

export function selectState(state): State {
  return state[MODULE_STORE_KEY];
}

export function selectSourceOrganization(state) {
  return selectState(state).sourceOrganization;
}

export function selectReferentials(state) {
  return selectState(state).referentials;
}

export function selectEditedOrganization(state) {
  return selectState(state).editedOrganization;
}

export function selectIsFetchingRules(state) {
  return selectState(state).isFetchingRules;
}

export function selectEditedReferentials(state) {
  return selectReferentials(state).edited;
}

export function selectSourceReferentials(state) {
  return selectReferentials(state).source;
}

export function selectIsDirtyReferentials(state) {
  return selectReferentials(state).isDirty;
}

export function selectIsSavingReferentials(state) {
  return selectReferentials(state).isSaving;
}

export function selectIsAddingReferentials(state) {
  return selectReferentials(state).isAdding;
}

export function selectIsImportingReferentials(state) {
  return selectReferentials(state).isImporting;
}

export function selectIsBranchingReferentials(state) {
  return selectReferentials(state).isBranching;
}

export function selectIsUpdatingReferentials(state) {
  return selectReferentials(state).isUpdating;
}

export function selectSelectedRootIdReferentials(state) {
  return selectReferentials(state).selectedRootNodeId;
}

export function selectImportedReferentials(state) {
  return selectReferentials(state).imported;
}

export function getOrganizationOption(organization) {
  const orgId = get(organization, 'id');
  const orgName = get(organization, 'nameLegal');
  const orgType = ORGANIZATION_TYPE_MAPPING[get(organization, 'type')]?.name;
  const status =
    get(organization, 'status') === STATUS_ACTIVE ? 'Active' : 'Inactive';
  const network = getIn(organization, ['network', 'name']);
  return {
    label: [orgName, orgId, orgType, status, `Network: ${network}`].join(' - '),
    value: organization,
    key: orgId,
  };
}

export function selectFields(state, { fieldEntity }) {
  return selectState(state).fields[fieldEntity]?.fieldsByName || fromJS({});
}

export function selectRequiredFieldNames(state, { fieldEntity }) {
  return (
    selectState(state).fields[fieldEntity]?.requiredFieldNames || ImmutableSet()
  );
}

export function selectFieldsAreLoaded(state, { fieldEntity }) {
  return selectState(state).fields[fieldEntity]?.isLoaded || false;
}

export function selectSupplierReferentials(state) {
  return selectState(state).supplierReferentials || ImmutableList();
}
