import { get } from 'utils/immutable';

export const getOrganizationNameLegal = (organization) =>
  get(organization, 'nameLegal');

export const getOrganizationId = (organization) => get(organization, 'id');

export const getOrganizationType = (organization) => get(organization, 'type');

export const getOrganizationUsers = (organization) =>
  get(organization, 'users');

export const getOrganizationStatus = (organization) =>
  get(organization, 'status');

export const getOrganizationNetwork = (organization) =>
  get(organization, 'network');

export const getOrganizationUsersNb = (organization) =>
  get(organization, 'usersCount');
