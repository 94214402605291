import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { HelpTooltip } from '@alkem/react-ui-helptooltip';

import Image from 'components/ui/image';
import StatusBar from 'components/ui/status-bar';

class FilterListItem extends Component {
  static propTypes = {
    filter: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.renderIcon = this.renderIcon.bind(this);
    this.renderCount = this.renderCount.bind(this);
  }

  onChange() {
    const { filter, name, onChange } = this.props;
    // Since it's radio, selected is useless
    onChange(name, filter);
  }

  renderIcon() {
    const { filter } = this.props;
    if (!filter.icon) {
      return null;
    }
    return (
      <span>
        <Image className="FilterList__icon" src={filter.icon} />
      </span>
    );
  }

  renderStatusBar() {
    const { filter } = this.props;
    if (!filter.statusBarType) {
      return null;
    }
    return <StatusBar type={filter.statusBarType} filter />;
  }

  renderCount() {
    const { filter } = this.props;
    if (!filter.count) {
      return null;
    }
    return (
      <span className="FilterList__count">
        &nbsp;(
        {filter.count})
      </span>
    );
  }

  renderLabel() {
    const { filter, name } = this.props;
    if (filter.help) {
      return (
        <span>
          {`${filter.label} `}
          <HelpTooltip
            id={`helptooltip-${name}-${filter.value}`}
            message={filter.help}
          />
        </span>
      );
    }
    return <span>{filter.label}</span>;
  }

  render() {
    const { filter, name } = this.props;
    const id = `${name}-${filter.value}`;
    return (
      <li>
        <input
          type="radio"
          className="FilterList__radio"
          name={name}
          value={filter.value}
          id={id}
          ref={`${name}-${filter.value}`}
          checked={filter.checked}
          onChange={this.onChange}
        />
        <label htmlFor={id} className="FilterList__statusLabel">
          {this.renderStatusBar()}
          {this.renderIcon()}
          {this.renderLabel()}
          {this.renderCount()}
        </label>
      </li>
    );
  }
}

export default FilterListItem;
