import { notificationError, notificationSuccess } from 'actions/notifications';
import { flow, map, sortBy, update } from 'lodash/fp';
import { call, put } from 'redux-saga/effects';
import qs from 'querystringify';
import etlApi from 'resources/etlApi';
import fieldsApi from 'resources/fieldsApi';
import referentialAdminApi from 'resources/referentialAdminApi';
import { logError } from 'utils';
import { request, requestWithHeaders } from 'utils/api';
import { takeEverySafe, takeFirst, takeLatestSafe } from 'utils/saga';
import {
  GET_FIELD,
  GET_FIELDS_LIST,
  GET_FIELD_CATEGORIES_LIST,
  GET_IMPORT_MAPPINGS_LIST,
  GET_REFERENTIAL_CODES_LIST,
  RECEIVE_FIELD,
  RECEIVE_FIELDS_LIST,
  RECEIVE_FIELD_CATEGORIES_LIST,
  RECEIVE_IMPORT_MAPPINGS_LIST,
  RECEIVE_REFERENTIAL_CODES_LIST,
  SAVE_IMPORT_MAPPING,
  SET_IS_SAVING_IN_PROGRESS,
} from '../actions/constants';
import { ALLOWED_FIELD_CATEGORIES } from '../constants';
import { Field, ImportMapping, ImportMappingApi } from '../types';
import {
  getChildrenForApi,
  getFallbackForApi,
  getFormattedImportMappings,
  getReferentials,
} from '../utils';

export default function* mainSaga() {
  yield takeFirst(GET_FIELD_CATEGORIES_LIST, listFieldCategories);
  yield takeLatestSafe(GET_FIELDS_LIST, listFields);
  yield takeLatestSafe(GET_IMPORT_MAPPINGS_LIST, getImportMappingsList);
  yield takeLatestSafe(GET_FIELD, getField);
  yield takeEverySafe(GET_REFERENTIAL_CODES_LIST, getReferentialsList);
  yield takeLatestSafe(SAVE_IMPORT_MAPPING, saveImportMapping);
}

const sortFieldChildren = (field: Field): Field => {
  const children = field?.children;
  if (!children || children.length < 2) {
    return field;
  }

  return update(
    ['children'],
    flow(sortBy(['displayInfo', 'rank']), map(sortFieldChildren)),
    field
  );
};

function* getField({ id }: { id: number }) {
  const { result, error } = yield call(
    requestWithHeaders,
    fieldsApi,
    'getField',
    id
  );
  if (error) {
    logError(error);
    yield put(notificationError('Failed to get field'));
  } else {
    yield put({
      type: RECEIVE_FIELD,
      payload: {
        sourceField: sortFieldChildren(result.data),
      },
    });
  }
}

function* getImportMappingsList({
  field,
  fieldCategory,
}: {
  field: Field;
  fieldCategory: string;
}) {
  if (field) {
    try {
      let limitReached = false;
      let pageCount = 0;
      let importMappings: ImportMappingApi[] = [];
      const limit = 500;
      while (!limitReached) {
        const response = yield call(() =>
          etlApi.get(
            '/etl/v2/gdsndashboard/get/mapping/field',
            qs.stringify(
              {
                field: field.name,
                unit: fieldCategory,
                limit: limit,
                offset: pageCount * limit,
              },
              true
            ),
            false
          )
        );
        importMappings = importMappings.concat(response.data.data);
        limitReached = response.data.data.length < limit;
        pageCount++;
      }
      const referentials = getReferentials([], field);
      const formattedImportMappings = getFormattedImportMappings(
        field,
        fieldCategory,
        importMappings
      );

      for (const referential of referentials) {
        yield put({
          type: GET_REFERENTIAL_CODES_LIST,
          referential,
        });
      }
      yield put({
        type: RECEIVE_IMPORT_MAPPINGS_LIST,
        payload: {
          importMappings: formattedImportMappings,
        },
      });
    } catch (error) {
      logError(error);
      yield put({
        type: RECEIVE_IMPORT_MAPPINGS_LIST,
      });
      yield put(
        notificationError(
          `Failed to get import mappings for field ${field.name}`
        )
      );
    }
  }
}

function* getReferentialsList({ referential }: { referential: string }) {
  try {
    const { result } = yield call(
      request,
      referentialAdminApi,
      'ReferentialGetList',
      referential,
      { lang: 'noop' }
    );
    yield put({
      type: RECEIVE_REFERENTIAL_CODES_LIST,
      payload: {
        referential,
        referentialCodes: result?.map(
          (referentialCode: {
            id: number;
            code: string;
            data: {
              'GDSN3.1'?: string | string[];
              'GDSN2.8': string | string[];
            };
          }) => {
            let gdsnCode: string | undefined = undefined;
            if (referentialCode.data?.['GDSN3.1']) {
              if (typeof referentialCode.data['GDSN3.1'] === 'string') {
                gdsnCode = referentialCode.data['GDSN3.1'];
              } else {
                gdsnCode = referentialCode.data['GDSN3.1']?.[0];
              }
            } else if (referentialCode.data?.['GDSN2.8']) {
              if (typeof referentialCode.data['GDSN2.8'] === 'string') {
                gdsnCode = referentialCode.data['GDSN2.8'];
              } else {
                gdsnCode = referentialCode.data['GDSN2.8']?.[0];
              }
            }
            return {
              id: referentialCode.id,
              label: referentialCode.code,
              gdsnCode,
            };
          }
        ),
      },
    });
  } catch (error) {
    logError(error);
    yield put(
      notificationError(
        `Failed to get referential codes list for referential ${referential}`
      )
    );
  }
}

function* listFields({
  entityType,
  search,
}: {
  entityType: string;
  search: string;
}) {
  const { result, error } = yield call(
    request,
    fieldsApi,
    'listMainFields',
    search,
    [
      'tags',
      'displayInfo',
      'children',
      'type',
      'declinable_by',
      'referential',
      'specific',
      'functional_key',
    ],
    entityType,
    { with_disabled: true, with_specific: true, limit: 100 }
  );
  if (error) {
    logError(error);
    yield put(notificationError('Failed to fetch fields'));
  } else {
    yield put({
      type: RECEIVE_FIELDS_LIST,
      payload: {
        fields: result.map(sortFieldChildren),
      },
    });
  }
}

function* listFieldCategories() {
  const {
    result,
    error,
  }: { result: { id: number; name: string }[]; error: any } = yield call(
    request,
    fieldsApi,
    'FieldMetaDataRoots'
  );
  if (error) {
    logError(error);
    yield put(notificationError('Failed to list field categories'));
  } else {
    yield put({
      type: RECEIVE_FIELD_CATEGORIES_LIST,
      payload: {
        fieldCategories: result
          .filter((fieldCategory) =>
            ALLOWED_FIELD_CATEGORIES.includes(fieldCategory.name)
          )
          .map((fieldCategory) => ({
            id: fieldCategory.id,
            label: fieldCategory.name,
          })),
      },
    });
  }
}

function* saveImportMapping({
  importMapping,
  field,
}: {
  importMapping: ImportMapping;
  field: Field;
}) {
  try {
    yield put({
      type: SET_IS_SAVING_IN_PROGRESS,
      payload: { isSavingInProgress: true },
    });
    const details = {
      ...importMapping.details,
      xpath:
        importMapping.details.xpath.length === 1
          ? importMapping.details.xpath[0]
          : importMapping.details.xpath,
      fallback: getFallbackForApi(importMapping.details.fallback),
      children: getChildrenForApi(importMapping.details.children),
    };
    for (const key of Object.keys(details)) {
      if (
        (!details[key] &&
          ['undefined', 'string'].includes(typeof details[key])) ||
        key === 'functional_key'
      ) {
        delete details[key];
      }
    }

    yield call(() =>
      etlApi.post('/etl/v2/gdsndashboard/post/mapping', {
        ...importMapping,
        date_start: importMapping.dateStart,
        date_end: importMapping.dateEnd,
        id: importMapping.isNew ? undefined : importMapping.id,
        details,
      })
    );
    yield put({
      type: RECEIVE_IMPORT_MAPPINGS_LIST,
      payload: {
        importMappings: [],
      },
    });
    yield getImportMappingsList({ field, fieldCategory: importMapping.unit });
    yield put({
      type: SET_IS_SAVING_IN_PROGRESS,
      payload: { isSavingInProgress: false },
    });
    yield put(notificationSuccess('Import mapping saved'));
  } catch (error) {
    yield put({
      type: SET_IS_SAVING_IN_PROGRESS,
      payload: { isSavingInProgress: false },
    });
    yield put(notificationError('Failed to save import mapping'));
    logError(error);
  }
}
