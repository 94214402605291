import { Modal } from '@alkem/react-ui-modal';
import { SimpleSelect } from '@alkem/react-ui-select';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { separateActions } from 'utils/redux';
import { closeCIHWModal, pushCIHW } from '../actions';
import { selectModal } from '../selectors';
import './modal.scss';

const mapStateToProps = (state) => ({
  cisync: selectModal(state).cisync,
  isOpen: selectModal(state).isOpen,
  isPushing: selectModal(state).isPushing,
});

const mapDispatchToProps = {
  pushCIHW,
  closeCIHWModal,
};

const CIHWDeletionCode = [
  { value: 'HIERARCHY_LINK_CORRECTION', label: 'Hierarchy Link Correction' },
  { value: 'PUBLICATION_WITHDRAWAL', label: 'Publication Withdrawal' },
];

export class GDSNCIHWModal extends PureComponent {
  static propTypes = {
    cisync: PropTypes.object,
    isOpen: PropTypes.bool.isRequired,
    isPushing: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      pushCIHW: PropTypes.func.isRequired,
      closeCIHWModal: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { withdrawalReason: 'HIERARCHY_LINK_CORRECTION' };
  }

  onClickPushCIHW = (cisyncID, reason) => () => {
    this.props.actions.pushCIHW(cisyncID, reason);
  };

  onSelectReason = (e) => {
    this.setState({
      withdrawalReason: e.value,
    });
  };

  render() {
    const { actions, cisync, isOpen, isPushing } = this.props;
    if (!isOpen) {
      return null;
    }
    return (
      <Modal
        title="Hierarchy Withdrawal"
        onClose={actions.closeCIHWModal}
        confirmButtonText="Save"
        onConfirm={this.onClickPushCIHW(cisync.id, this.state.withdrawalReason)}
        isProcessing={isPushing}
      >
        <div className="GDSNCIHWModal_Body">
          <div className="GDSNCIHWModal__Form">
            <div className="GDSNCIHWModal__Line">
              <h3>Information</h3>
              <div className="row">
                <div className="col-xs-3">
                  <div>{cisync.source.name}</div>
                  <div>{cisync.source.gln}</div>
                </div>
                <div className="col-xs-1">
                  <i className="mdi mdi-arrow-right-bold" />
                </div>
                <div className="col-xs-3">
                  <div>
                    <i className="mdi mdi-barcode" />
                    {` ${cisync.gtin}`}
                  </div>
                  <div>
                    <i className="mdi mdi-chemical-weapon" />
                    {` ${cisync.gpc}`}
                  </div>
                  <div>
                    <i className="mdi mdi-earth" />
                    {` ${cisync.target_market}`}
                  </div>
                </div>
                <div className="col-xs-1">
                  <i className="mdi mdi-arrow-right-bold" />
                </div>
                <div className="col-xs-3">
                  <div>{cisync.recipient.name}</div>
                  <div>{cisync.recipient.gln}</div>
                </div>
              </div>
            </div>
            <div className="GDSNCIHWModal__Line">
              <h3>Reason</h3>
              <SimpleSelect
                id={`party-${cisync.id}-partyInRole_code`}
                options={CIHWDeletionCode}
                value={CIHWDeletionCode.find(
                  (o) => o.value === this.state.withdrawalReason
                )}
                onSelect={this.onSelectReason}
                autoSize
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(GDSNCIHWModal);
