export const GET_FIELD = 'io-gdsn-imports/GET_FIELD';
export const GET_FIELDS_LIST = 'io-gdsn-imports/GET_FIELDS_LIST';
export const GET_FIELD_CATEGORIES_LIST =
  'io-gdsn-imports/GET_FIELD_CATEGORIES_LIST';
export const GET_IMPORT_MAPPINGS_LIST =
  'io-gdsn-imports/GET_IMPORT_MAPPINGS_LIST';
export const GET_REFERENTIAL_CODES_LIST =
  'io-gdsn-imports/GET_REFERENTIAL_CODES_LIST';

export const RECEIVE_FIELD = 'io-gdsn-imports/RECEIVE_FIELD';
export const RECEIVE_FIELDS_LIST = 'io-gdsn-imports/RECEIVE_FIELDS_LIST';
export const RECEIVE_FIELD_CATEGORIES_LIST =
  'io-gdsn-imports/RECEIVE_FIELD_CATEGORIES_LIST';
export const RECEIVE_IMPORT_MAPPINGS_LIST =
  'io-gdsn-imports/RECEIVE_IMPORT_MAPPINGS_LIST';
export const RECEIVE_REFERENTIAL_CODES_LIST =
  'io-gdsn-imports/RECEIVE_REFERENTIAL_CODES_LIST';

export const SAVE_IMPORT_MAPPING = 'io-gdsn-imports/SAVE_IMPORT_MAPPING';
export const SET_IMPORT_MAPPING_PROPERTY =
  'io-gdsn-imports/SET_IMPORT_MAPPING_PROPERTY';
export const SET_IMPORT_MAPPING_PROPERTY_IS_TESTED =
  'io-gdsn-imports/SET_IMPORT_MAPPING_PROPERTY_IS_TESTED';
export const SET_IS_SAVING_IN_PROGRESS =
  'io-gdsn-imports/SET_IS_SAVING_IN_PROGRESS';
