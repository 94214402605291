import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button } from '@alkem/react-ui-button';
import { SimpleSelect } from '@alkem/react-ui-select';

import { OrganizationAutocomplete } from 'components/autocomplete';
import { separateActions } from 'utils/redux';
import {
  OWWO_TYPE_PRIVATE_LABEL,
  OWWO_TYPE_INVITED,
} from '../../constants/recipients';
import { addRecipient } from '../../actions/recipients';

const mapDispatchToProps = {
  addRecipient,
};

const relationTypeOptions = [
  { value: OWWO_TYPE_PRIVATE_LABEL.id, label: OWWO_TYPE_PRIVATE_LABEL.label },
  { value: OWWO_TYPE_INVITED.id, label: OWWO_TYPE_INVITED.label },
];

export class OrganizationPageRecipientsForm extends PureComponent {
  static propTypes = {
    actions: PropTypes.shape({
      addRecipient: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedOrganization: null,
      selectedRelationType: null,
    };
  }

  onAdd = () => {
    const { selectedOrganization, selectedRelationType } = this.state;
    this.props.actions.addRecipient(
      selectedOrganization.value,
      selectedRelationType.value
    );

    this.setState({
      selectedOrganization: null,
      selectedRelationType: null,
    });
  };

  onSelectOrganization = (selectedOrganization) => {
    this.setState({ selectedOrganization });
  };

  onSelectRelationType = (selectedRelationType) => {
    this.setState({ selectedRelationType });
  };

  render() {
    const { selectedOrganization, selectedRelationType } = this.state;

    return (
      <div className="OrganizationPageRecipients_form">
        <div className="OrganizationPageRecipients_formOrganization">
          <OrganizationAutocomplete
            id="recipient-target-organization"
            value={selectedOrganization ? [selectedOrganization] : []}
            onSelect={this.onSelectOrganization}
            placeholder="Target Organization"
            searchOnClick
          />
        </div>
        <div className="OrganizationPageRecipients_formRelationType">
          <SimpleSelect
            id="recipient-relation-type"
            value={selectedRelationType}
            options={relationTypeOptions}
            onSelect={this.onSelectRelationType}
            placeholder="Relation type"
            autoSize
          />
        </div>
        <Button
          className="OrganizationPageRecipients_add"
          disabled={!selectedOrganization || !selectedRelationType}
          onClick={this.onAdd}
          content="Add"
          primary
        />
      </div>
    );
  }
}

export default connect(
  null,
  mapDispatchToProps,
  separateActions
)(OrganizationPageRecipientsForm);
