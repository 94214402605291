import qs from 'querystringify';
import { Link } from 'react-router-dom';

import { isAtLeastPlatformAdmin } from 'utils/user';

interface Props {
  user: any;
  organizationId: number;
}

export const OrganizationLinks = ({ user, organizationId }: Props) => {
  if (!isAtLeastPlatformAdmin(user)) {
    return null;
  }

  const query = qs.stringify({ organization_id: organizationId }, true);
  return (
    <div className="OrganizationPageBlock">
      <div className="OrganizationPageBlock__header">
        <h2>Links</h2>
      </div>
      <Link to={`/organization-uses-fields${query}`}>View as</Link>
    </div>
  );
};
