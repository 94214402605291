import 'googleapis'; // eslint-disable-line import/no-unresolved
import { getGoogleApiClientId } from 'utils/google';

export function connect() {
  // api is configured here: https://console.developers.google.com/apis/credentials/
  const { gapi } = window;
  if (!gapi) {
    throw new Error(
      'Google Apis have not been loaded. window.gapi is undefined!'
    );
  }

  // Array of API discovery doc URLs for APIs used by the quickstart
  const DISCOVERY_DOCS = [
    'https://sheets.googleapis.com/$discovery/rest?version=v4',
  ];

  // Authorization scopes required by the API; multiple scopes can be
  // included, separated by spaces.
  const SCOPES = 'https://www.googleapis.com/auth/spreadsheets';

  gapi.load('client:auth2', () => {
    gapi.client.init({
      discoveryDocs: DISCOVERY_DOCS,
      clientId: getGoogleApiClientId(),
      scope: SCOPES,
    });
  });
}

export function loadSpreadsheet(spreadsheetUrl) {
  const { gapi } = window;
  const isSignedIn = gapi.auth2.getAuthInstance().isSignedIn.get();
  if (!isSignedIn) {
    gapi.auth2.getAuthInstance().signIn();
    return null; // google sign-in window will open up, so do nothing
  }

  return readSpreadsheet(spreadsheetUrl);
}

export function exportToSpreadsheet(spreadsheetUrl, data) {
  const { gapi } = window;
  const isSignedIn = gapi.auth2.getAuthInstance().isSignedIn.get();
  if (!isSignedIn) {
    gapi.auth2.getAuthInstance().signIn();
    return null; // google sign-in window will open up, so do nothing
  }

  return writeSpreadsheet(spreadsheetUrl, data);
}

export function extractID(url) {
  // url should look like https://.../spreadsheets/d/<id>/...
  const regexp = new RegExp('^https://.*/spreadsheets/./([^/]+)/.*$');
  if (!url.match(regexp)) {
    throw new Error(
      'Could not extract google spreadsheet ID from URL, it may be invalid'
    );
  }
  return url.replace(regexp, '$1');
}

function readSpreadsheet(url) {
  const { gapi } = window;
  const id = extractID(url);
  return new Promise((resolve, reject) => {
    // need to wrap this in an actual ES5 promise
    gapi.client.sheets.spreadsheets.values
      .get({
        spreadsheetId: id,
        range: 'Feuille 1!A:Z',
      })
      .then(
        (response) => resolve(response.result),
        (response) => reject(response.result.error.message)
      );
  });
}

function writeSpreadsheet(url, data) {
  const { gapi } = window;
  const id = extractID(url);
  return new Promise((resolve, reject) => {
    // need to wrap this in an actual ES5 promise
    gapi.client.sheets.spreadsheets.values
      .update(
        {
          spreadsheetId: id,
          range: 'Feuille 1!A:Z',
          valueInputOption: 'RAW',
        },
        data
      )
      .then(
        (response) => resolve(response.result),
        (response) => reject(response.result.error.message)
      );
  });
}
