import { Map as ImmutableMap } from 'immutable';
import { Checkbox } from '@alkem/react-ui-inputs';

interface Props {
  field: ImmutableMap<string, any>;
  readOnly?: boolean;
  fieldEntity: string;
  ruleEntity?: string;
  isRequirable: boolean;
  hasRules: boolean;
  createRule: () => void;
  deleteRules: (fieldName: string) => void;
}

export const RequiredCheckbox = (props: Props) => {
  const {
    field,
    fieldEntity,
    readOnly = false,
    ruleEntity,
    isRequirable,
    hasRules,
    createRule,
    deleteRules,
  } = props;

  if (typeof ruleEntity === 'undefined') {
    return null;
  }

  const checked = hasRules;
  // Cannot require if not requirable.
  if (!checked && !isRequirable) {
    return null;
  }

  const fieldName = field.get('name');

  const onChange = () => (checked ? deleteRules : createRule)(fieldName);

  return (
    <Checkbox
      id={`rule-${fieldEntity}-${fieldName}-required`}
      checked={checked}
      onChange={onChange}
      label="Required"
      disabled={readOnly}
    />
  );
};
