import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Text } from '@alkem/react-ui-inputs';
import { SimpleSelect } from '@alkem/react-ui-select';

import { getIn } from 'utils/immutable';

import NodeInformation from '../node';

import './node-list.scss';

const argListMatches = (args, query) => {
  if (!args) {
    return false;
  }

  if (!query) {
    return true;
  }

  return args.some(
    (arg) =>
      arg.name.toLowerCase().indexOf(query) >= 0 ||
      arg.type.toLowerCase().indexOf(query) >= 0
  );
};

const filterNodes = (nodes, query) => {
  if (!query) {
    return nodes;
  }

  const queries = query.split(' ');
  return nodes.filter((n) =>
    queries.every((qq) => {
      const q = qq.toLowerCase();
      return (
        n.id.toLowerCase().indexOf(q) >= 0 ||
        n.type.toLowerCase().indexOf(q) >= 0 ||
        (n.doc && n.doc.toLowerCase().indexOf(q) >= 0) ||
        argListMatches(n.inputs, q) ||
        argListMatches(n.outputs, q)
      );
    })
  );
};

const sortNodes = (nodes, sortKey, runResults) => {
  const sortedNodes = [].concat(nodes);
  if (sortKey === 'id') {
    sortedNodes.sort((a, b) => {
      if (a.id === b.id) {
        return 0;
      }
      return a.id < b.id ? -1 : 1;
    });
  } else if (sortKey === 'duration' && runResults) {
    sortedNodes.sort((a, b) => {
      const aTook = getIn(runResults, ['inspectors', 'trace', a.id, 'delta']);
      const bTook = getIn(runResults, ['inspectors', 'trace', b.id, 'delta']);

      if (aTook === bTook) {
        return 0;
      }

      // The other way around between -1 and 1 because we want the longest
      // nodes first
      return aTook < bTook ? 1 : -1;
    });
  }
  return sortedNodes;
};

class NodeList extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      filterKey: '',
      sortKey: 'index',
      sortKeyOptions: ['index', 'id'],
      filteredNodes: props.nodes,
    };
  }

  componentDidUpdate(prevProps) {
    const { nodes, runResults } = this.props;
    if (nodes !== prevProps.nodes) {
      const { filterKey, sortKey } = this.state;
      const filteredNodes = sortNodes(
        filterNodes(nodes, filterKey),
        sortKey,
        runResults
      );
      this.setState({ filteredNodes }); // eslint-disable-line
    }

    if (runResults !== prevProps.runResults) {
      const sortKeyOptions = ['index', 'id'];
      if (runResults) {
        sortKeyOptions.push('duration');
      }
      this.setState({ sortKeyOptions }); // eslint-disable-line
    }
  }

  onFilterKeyChange = (evt) => {
    const { runResults } = this.props;
    const { sortKey } = this.state;
    const filterKey = evt.target.value;
    const filteredNodes = sortNodes(
      filterNodes(this.props.nodes, filterKey),
      sortKey,
      runResults
    );
    this.setState({ filterKey, filteredNodes });
  };

  onSortKeyChange = (option) => {
    const { runResults } = this.props;
    const { filterKey } = this.state;
    const sortKey = option.value;
    const filteredNodes = sortNodes(
      filterNodes(this.props.nodes, filterKey),
      sortKey,
      runResults
    );
    this.setState({ filterKey, filteredNodes });
  };

  render() {
    const { id, edges, runResults } = this.props;
    const { sortKey, sortKeyOptions, filterKey, filteredNodes } = this.state;

    return (
      <div className="NodeList">
        <div className="NodeList__search card">
          <Text
            id={`${id}-search`}
            value={filterKey}
            placeholder="Search nodes..."
            onChange={this.onFilterKeyChange}
          />
          Sort by:{' '}
          <SimpleSelect
            id={`${id}-sort`}
            options={sortKeyOptions.map((v) => ({
              value: v,
              id: v,
              label: v,
            }))}
            value={{
              value: sortKey,
              id: sortKey,
              label: sortKey,
            }}
            onSelect={this.onSortKeyChange}
          />
          <span>{filteredNodes.length} nodes</span>
        </div>
        {filteredNodes && filteredNodes.length > 0 ? (
          filteredNodes.map((node) => (
            <div className="NodeList__Node card" key={node.id}>
              <NodeInformation
                node={node}
                edges={edges}
                runResults={runResults}
              />
            </div>
          ))
        ) : (
          <em className="text-muted">(No nodes)</em>
        )}
      </div>
    );
  }
}

NodeList.propTypes = {
  id: PropTypes.string.isRequired,
  nodes: PropTypes.array.isRequired,
  edges: PropTypes.array,
  runResults: PropTypes.object,
};

NodeList.defaultProps = {
  edges: [],
  runResults: {},
};

export default NodeList;
