import './import-mapping-can-not-be-created-message.scss';

interface Props {
  type?: string;
}
export function ImportMappingsItemWhichCanNotBeCreatedMessage({
  type = 'undefined',
}: Props): JSX.Element {
  return (
    <div className="GDSNImportMappingItemCanNotBeCreated__message">
      {`The type ${type} is not supported yet for the creation of an import mapping. Please contact connector team to make it available.`}
    </div>
  );
}
