import proptypes from 'prop-types';
import './show-user.scss';
import React, { useState, useEffect } from 'react';
import { authApi } from 'resources/authApi';

export function ShowUser({ id }) {
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const USER_STATUSES = {
    0: 'INACTIVE',
    1: 'ACTIVE',
    2: 'PENDING',
  };

  useEffect(() => {
    const init = async () => {
      if (!id) {
        return;
      }

      setIsLoading(true);
      const callUserApi = async () => {
        try {
          const response = await authApi.UserShow(id, {}, true);
          return response.data.data;
        } catch (exception) {
          return;
        }
      };

      const userResult = await callUserApi();
      setUser(userResult);

      setIsLoading(false);
    };
    init();
  }, [id]);

  if (isLoading) {
    return <div>{`Fetching information for user with id: ${id}`}</div>;
  }

  return (
    <div>
      {user ? (
        <div className="ShowUser__container">
          <div className="ShowUser__containerRow">
            <span className="ShowUser__fieldLabel">ID:</span>
            <span className="ShowUser__fieldResult">{user.id}</span>
          </div>
          <div className="ShowUser__containerRow">
            <span className="ShowUser__fieldLabel">Name:</span>
            <span className="ShowUser__fieldResult">{user.username}</span>
          </div>
          <div className="ShowUser__containerRow">
            <span className="ShowUser__fieldLabel">Status:</span>
            <span className="ShowUser__fieldResult">
              {USER_STATUSES[user.status]}
            </span>
          </div>
          <div className="ShowUser__containerRow">
            <span className="ShowUser__fieldLabel">Belongs To:</span>
            <span className="ShowUser__fieldResult">
              {user.belongsTo.map((org) => {
                return (
                  <div key={user.id + '|' + org.id}>
                    {`
                    ${org.nameLegal}
                    (ID:${org.id})
                    (Status: ${org.accepted ? 'ACCEPTED' : 'PENDING ACCEPT'})
                    (Permissions: ${org.permissions.join(',')})
                  `}
                  </div>
                );
              })}
            </span>
          </div>
        </div>
      ) : (
        `no user found for id: ${id}`
      )}
    </div>
  );
}

ShowUser.propTypes = {
  id: proptypes.string,
};
