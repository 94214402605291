import { createReducer, getReducer } from 'utils/reducers';

import * as referentialsConstants from 'constants/events/referentials';

const initialState = {
  lists: {},
};

const receiveReferentialReducer = (state, action) => ({
  lists: { ...state.lists, [action.referential]: action.data },
});

const receiveMultipleReferentialReducer = (state, action) => ({
  lists: { ...state.lists, ...action.data },
});

const onErrorReferentialReducer = (state, action) => ({
  lists: { ...state.lists, [action.referential]: [] },
});
export default createReducer(initialState, {
  [referentialsConstants.GET_REFERENTIAL_LIST]: getReducer(initialState),
  [referentialsConstants.RECEIVE_REFERENTIAL_LIST]: receiveReferentialReducer,
  [referentialsConstants.RECEIVE_MULTIPLE_REFERENTIAL_LIST]:
    receiveMultipleReferentialReducer,
  [referentialsConstants.REFERENTIAL_LIST_ERROR]: onErrorReferentialReducer,
});
