import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputWithLabel from 'components/input-with-label';
import classNames from 'classnames';
import { Textarea } from '@alkem/react-ui-inputs';
import { Button } from '@alkem/react-ui-button';
import { HelpTooltip } from '@alkem/react-ui-helptooltip';

import {
  createXmlFile,
  getStringWithoutLineBreaks,
  getUpdatedImportMapping,
} from '../../../utils';
import { SET_IMPORT_MAPPING_PROPERTY } from '../../../actions/constants';
import { ImportMapping, ImportMappingDetails } from '../../../types';

import './item-mandatory-properties.scss';
import { selectReferentialCodes } from '../../../selectors';

interface Props {
  details: ImportMappingDetails;
  fieldName: string;
  importMapping: ImportMapping;
  parentNames?: string[];
  parents?: ImportMappingDetails[];
}
export function ImportMappingsItemMandatoryProperties({
  details,
  fieldName,
  importMapping,
  parentNames = [],
  parents = [],
}: Props): JSX.Element {
  const dispatch = useDispatch();
  const referentialCodes = useSelector(selectReferentialCodes);
  const [xpaths, setXpaths] = useState(details.xpath);
  const [indexEdition, setIndexEdition] = useState<number | undefined>(
    details.xpath.length === 1 && details.xpath[0] === '' ? 0 : undefined
  );
  const [editedXpath, setEditedXpath] = useState('');

  const setAndDispatchXpath = (value: string[]) => {
    setXpaths(value);
    const updatedImportMapping = getUpdatedImportMapping({
      details,
      fieldName,
      importMapping,
      parentNames,
      parents,
      properties: [{ name: 'xpath', value }],
    });

    dispatch({
      type: SET_IMPORT_MAPPING_PROPERTY,
      payload: {
        importMapping: { ...updatedImportMapping },
      },
    });
  };

  const editXpath = (value: string, index: number): void => {
    setEditedXpath(value);
    setIndexEdition(index);
  };

  const dowloadXml = (xpath: string): void => {
    createXmlFile(
      fieldName,
      details,
      xpath,
      [...parents.slice().reverse()],
      referentialCodes
    );
  };

  const updateEditedXpath = (event: { target: { value: string } }): void => {
    setEditedXpath(getStringWithoutLineBreaks(event.target.value));
  };

  const updateXpaths = (index: number): void => {
    const newXpaths = [...xpaths];
    newXpaths[index] = editedXpath;
    setAndDispatchXpath(newXpaths);
    setIndexEdition(undefined);
  };

  const removeXpathValue = (index: number): void => {
    let newXpaths = [...xpaths];
    setEditedXpath('');
    if (newXpaths.length === 1) {
      newXpaths[0] = '';
      setIndexEdition(0);
    } else {
      newXpaths = newXpaths.filter((_, idx) => idx !== index);
      setIndexEdition(undefined);
    }
    setXpaths(newXpaths);
    setAndDispatchXpath(newXpaths);
  };

  const addXpathValue = (): void => {
    const newXpaths = [...xpaths];
    newXpaths.push('');
    setEditedXpath('');
    setXpaths(newXpaths);
    setIndexEdition(newXpaths.length - 1);
  };

  return (
    <div
      data-testid="GDSNImportMappingsItem_mandatoryProperties"
      className="GDSNImportMappingsItemMandatoryProperties"
    >
      <div className="GDSNImportMappingsItemMandatoryProperties__sectionTitle">
        Mandatory properties
      </div>
      <InputWithLabel
        inputId={`gdsn-import-mappings-field-${fieldName}-automatic-property-xpaths`}
        label={
          <span>
            xpath{' '}
            <HelpTooltip
              id={`gdsn-import-mappings-field-${fieldName}-automatic-property-xpaths`}
              place="top"
              message="Make sure to validate the xpath with the check icon for it to be taken in account"
            />
          </span>
        }
      >
        {xpaths.map((xpath, index) => (
          <div
            key={`gdsn-import-mappings-field-${fieldName}-automatic-property-xpath-${index}`}
            className="GDSNImportMappingsItemMandatoryProperties__sectionTextbox"
          >
            <Textarea
              id={`gdsn-import-mappings-field-${fieldName}-automatic-property-xpath-${index}`}
              value={index === indexEdition ? editedXpath : xpath}
              autoresize
              onChange={updateEditedXpath}
              disabled={index !== indexEdition}
              onKeyDown={(e) => {
                if (e.key === 'Enter') e.preventDefault();
              }}
            />
            <div className="GDSNImportMappingsItemMandatoryProperties__spansContainer">
              {indexEdition === index ? (
                <>
                  <span
                    className={classNames({
                      'mdi mdi-check': true,
                      'GDSNImportMappingsItemMandatoryProperties__span--disabled':
                        editedXpath === '',
                      'GDSNImportMappingsItemMandatoryProperties__span--enabled':
                        editedXpath !== '',
                    })}
                    onClick={() => updateXpaths(index)}
                  />
                  <span
                    className="mdi mdi-delete GDSNImportMappingsItemMandatoryProperties__span--enabled"
                    onClick={() => removeXpathValue(index)}
                  />
                </>
              ) : (
                <>
                  <span
                    className={classNames({
                      'mdi mdi-download': true,
                      'GDSNImportMappingsItemMandatoryProperties__span--disabled':
                        indexEdition !== undefined && xpath !== '',
                      'GDSNImportMappingsItemMandatoryProperties__span--enabled':
                        indexEdition === undefined,
                    })}
                    onClick={() => dowloadXml(xpath)}
                  />
                  <span
                    className={classNames({
                      'mdi mdi-pencil': true,
                      'GDSNImportMappingsItemMandatoryProperties__span--disabled':
                        indexEdition !== undefined,
                      'GDSNImportMappingsItemMandatoryProperties__span--enabled':
                        indexEdition === undefined,
                    })}
                    onClick={() => editXpath(xpath, index)}
                  />
                  <span
                    className={classNames({
                      'mdi mdi-delete': true,
                      'GDSNImportMappingsItemMandatoryProperties__span--disabled':
                        indexEdition !== undefined,
                      'GDSNImportMappingsItemMandatoryProperties__span--enabled':
                        indexEdition === undefined,
                    })}
                    onClick={() => removeXpathValue(index)}
                  />
                </>
              )}
            </div>
          </div>
        ))}
      </InputWithLabel>
      <div className="GDSNImportMappingsItemMandatoryProperties__buttonContainer">
        <Button
          testid="GDSNImportMappingsItemMandatoryProperties__button"
          content="Add xpath"
          onClick={addXpathValue}
          secondary
          disabled={indexEdition !== undefined}
        />
      </div>
    </div>
  );
}
