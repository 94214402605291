import { MODULE_REDUCER_KEY } from 'constants/reducer';
import { REDUCER_KEY } from '../constants';

export function selectState(state) {
  return state[MODULE_REDUCER_KEY][REDUCER_KEY];
}

export function selectIsLoading(state) {
  return selectState(state).get('isLoading');
}

export function selectFilterSearch(state) {
  return selectState(state).getIn(['filters', 'search']);
}

export function selectFilterReferentialSlug(state) {
  return selectState(state).getIn(['filters', 'referentialSlug']);
}

export function selectReferentialEntities(state) {
  return selectState(state).get('list');
}

export function selectModalReferentialEntity(state) {
  return selectState(state).getIn(['modal', 'referentialEntity']);
}

export function selectModalOpened(state) {
  return selectState(state).getIn(['modal', 'opened']);
}

export function selectModalUploading(state) {
  return selectState(state).getIn(['modal', 'uploading']);
}

export function selectRefreshingCache(state) {
  return selectState(state).get('refreshingCache');
}
