import React from 'react';
import AuthApi from 'resources/authApi';
import {
  LegacyAutocompleteProps,
  LegacyAutocomplete,
} from '../legacy-autocomplete';

type Props = {
  selector: (item: any) => boolean;
} & LegacyAutocompleteProps;

export const UserAutocomplete = React.memo(function UserAutocomplete({
  selector,
  ...props
}: Props) {
  const onSearch = (search) => {
    const queries: any = {};
    const filters: any = {};
    if (Number.isNaN(Number(search))) {
      queries.username = search;
    } else {
      filters.ids = search;
    }
    return AuthApi.UserList(queries, filters);
  };

  const transformData = (data) => data.map(selector);

  return (
    <LegacyAutocomplete
      onSearch={onSearch}
      transformData={transformData}
      {...props}
    />
  );
});

export default UserAutocomplete;
