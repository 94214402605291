import moment from 'moment';
import { Context } from '../types';
import './error-context.scss';

export function EdaRmqReplayerQueueErrorContext({
  reason,
  context,
}: {
  reason: string;
  context: Context;
}) {
  return (
    <div className="EdaRmqReplayerQueueErrorContext">
      <div>
        <i className="mdi mdi-calendar" />
        {moment(context.created_at).local().format('YYYY-MM-DD HH:mm:ss')}
        <span className="EdaRmqReplayerQueueErrorContext__elapsed">
          (+
          {moment
            .duration(moment().diff(moment(context.created_at)))
            .humanize()}
          )
        </span>
      </div>
      <div>
        <i className="mdi mdi-skull" /> {reason}
      </div>
      <div>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://app.datadoghq.com/apm/trace/${context.root_request_id}?spanID=${context.request_id}&spanViewType=errors`}
        >
          <i className="mdi mdi-dog" /> Trace
        </a>
      </div>
      <div>{context.event_type}</div>
    </div>
  );
}
