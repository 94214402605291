import { isValidJSON, isHexColor } from 'utils/index';

import { NEW_APPLICATION_NAME } from '../constants';

export const requiredFieldNames = [
  'name',
  'descriptionShort',
  'description',
  'logoUrl',
  'url',
];

// This regExp validate a json list
const validJSONFlagsRegExp = /\["[a-z0-9-]+"(,\s*"[a-z0-9-]+")*\]/;

export const requiredFieldMsg = 'This field is required.';

export const validate = {
  requiredFields: (values) =>
    requiredFieldNames.reduce((errors, fieldName) => {
      if (values[fieldName]) {
        return errors;
      }
      return { ...errors, [fieldName]: requiredFieldMsg };
    }, {}),

  colorField: (value) =>
    !isHexColor(value) && 'Please enter a valid color. (f.e. #268BD2)',

  nameFieldNotDefault: (value) =>
    value === NEW_APPLICATION_NAME &&
    'Please change the default application name.',

  scopeFlagsJSONFormat: (value) =>
    value &&
    (!value.match(new RegExp(validJSONFlagsRegExp)) || !isValidJSON(value)) &&
    'Please enter a valid flags (f.e. ["flag", "my-flag2"])',

  scopeKeyOnOffJSONFormat: (value) => {
    const errorMessage =
      'Please enter a valid value (f.e. [{"key": "k1", "on": "true", "off": "false"}] )';
    if (!value) {
      return false;
    } // No Value is Valid as you want to delete
    if (!isValidJSON(value)) {
      return errorMessage;
    } else {
      const arr = JSON.parse(value);
      return (
        (!Array.isArray(arr) ||
          !arr.every(
            (item) => 'key' in item && 'on' in item && 'off' in item
          )) &&
        errorMessage
      );
    }
  },
};
