import classNames from 'classnames';
import * as routes from 'constants/routes';
import {
  USER_BELONGS_TO_ORGANIZATION_ACCEPTED,
  USER_BELONGS_TO_ORGANIZATION_PENDING,
  USER_BELONGS_TO_ORGANIZATION_REFUSED,
  USER_STATUS_ACCEPTED,
  USER_STATUS_DISABLED,
  USER_STATUS_PENDING,
} from 'constants/user';
import { LogAsButton } from 'modules/log-as-button';
import { searchUsers } from 'modules/user-list/actions';
import { getUserUsername } from 'modules/user-list/utils';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const mapDispatchToProps = {
  searchInUserList: searchUsers,
};

class OrganizationPageUsersRow extends PureComponent {
  static propTypes = {
    user: ImmutablePropTypes.map.isRequired,
    displayLogas: PropTypes.bool,
    searchInUserList: PropTypes.func,
  };

  onGoToUserList = () => {
    this.props.searchInUserList(getUserUsername(this.props.user));
  };

  renderRole() {
    const { user } = this.props;
    if (!user.get('permissions').contains('admin')) {
      return null;
    }
    return <span className="OrganizationPageUsers_columnRoleBadge">Admin</span>;
  }

  renderLogAs() {
    const { user, displayLogas } = this.props;
    if (
      !displayLogas ||
      user.get('status') !== USER_STATUS_ACCEPTED ||
      user.getIn(['belongsTo', 0, 'relation_status']) !==
        USER_BELONGS_TO_ORGANIZATION_ACCEPTED
    ) {
      return null;
    }
    if (user.get('passwordExpired') === true) {
      return <div>Password expired</div>;
    }

    return (
      <LogAsButton
        targetEmail={user.get('username')}
        targetOrganization={user.getIn(['belongsTo', '0', 'id'])}
      />
    );
  }

  render() {
    const { user } = this.props;
    const rowClasses = {
      OrganizationPageUsers_row: true,
      'OrganizationPageUsers_row--disabled':
        user.get('status') === USER_STATUS_DISABLED,
      'OrganizationPageUsers_row--pending':
        user.get('status') === USER_STATUS_PENDING,
      'OrganizationPageUsers_row--relation-refused':
        user.get('status') === USER_STATUS_ACCEPTED &&
        user.getIn(['belongsTo', 0, 'relation_status']) ===
          USER_BELONGS_TO_ORGANIZATION_REFUSED,
      'OrganizationPageUsers_row--relation-pending':
        user.get('status') === USER_STATUS_ACCEPTED &&
        user.getIn(['belongsTo', 0, 'relation_status']) ===
          USER_BELONGS_TO_ORGANIZATION_PENDING,
    };

    return (
      <li className={classNames(rowClasses)}>
        <div className="OrganizationPageUsers_column OrganizationPageUsers_columnUsername">
          <Link to={routes.users} onClick={this.onGoToUserList}>
            {user.get('username')}
          </Link>
        </div>
        <div className="OrganizationPageUsers_column OrganizationPageUsers_columnRole">
          {this.renderRole()}
        </div>
        <div className="OrganizationPageUsers_column OrganizationPageUsers_columnLogAs">
          {this.renderLogAs()}
        </div>
      </li>
    );
  }
}

export default connect(null, mapDispatchToProps)(OrganizationPageUsersRow);
