import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from '@alkem/react-ui-checkbox';
import { HelpTooltip } from '@alkem/react-ui-helptooltip';

import './index.scss';

function OrganizationPageSettingsCheckbox({
  label,
  checked,
  help,
  onChange,
  disabled,
}) {
  return (
    <div className="OrganizationPageSettingsCheckbox">
      <div className="OrganizationPageSettingsCheckbox_checkbox">
        <Checkbox
          id={label}
          onChange={onChange}
          checked={checked}
          disabled={disabled}
        />
      </div>
      <div className="OrganizationPageSettingsCheckbox_label">{label}</div>
      {help && (
        <div className="OrganizationPageSettingsCheckbox_help">
          <HelpTooltip id={`${label}-help`} place="top" message={help} />
        </div>
      )}
    </div>
  );
}

OrganizationPageSettingsCheckbox.propTypes = {
  label: PropTypes.node.isRequired,
  checked: PropTypes.bool.isRequired,
  help: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default OrganizationPageSettingsCheckbox;
