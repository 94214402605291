import qs from 'querystringify';

import productGoApi from 'resources/productGoApi';

export async function loadRoutes() {
  const routes = await Promise.all(
    [
      'application',
      'assignation',
      'auth',
      'billing',
      'buyingunit',
      'classification',
      'contribution',
      'core',
      'etl',
      'exchange',
      'gdsn',
      'kpi',
      'media',
      'message',
      'notification',
      'product',
      'public',
      'search',
      'tracking',
      'transaction',
      'validation',
    ].map((service) =>
      productGoApi
        .get(`/${service}/v1/platform/discover`)
        .then((response) => response.data)
        .catch(() => Promise.resolve({}))
    )
  );
  return Object.assign.apply({}, routes);
}

export async function execureRunnable(runnable) {
  let { address } = runnable;
  if (runnable.params.uriParams) {
    for (const [key, value] of Object.entries(runnable.params.uriParams)) {
      const reg = new RegExp(`{${key}:[ds]}`, 'g');
      address = address.replace(reg, value);
    }
  }
  let response = null;
  if (runnable.method === 'GET') {
    response = await productGoApi.get(
      address,
      qs.stringify(runnable.params.queryParams)
    );
  } else if (runnable.method === 'DELETE') {
    response = await productGoApi.delete(
      address,
      qs.stringify(runnable.params.queryParams)
    );
  } else if (runnable.method === 'POST') {
    if (runnable.params.queryParams) {
      address = `${address}${qs.stringify(runnable.params.queryParams)}`;
    }
    response = await productGoApi.post(address, runnable.params.bodyParams);
  } else if (runnable.method === 'PUT') {
    if (runnable.params.queryParams) {
      address = `${address}${qs.stringify(runnable.params.queryParams)}`;
    }
    response = await productGoApi.put(address, runnable.params.bodyParams);
  }
  return response;
}
