// List
export const RECEIVE_GDSN_PARTY_LIST = 'RECEIVE_GDSN_PARTY_LIST';
export const GET_GDSN_PARTY_LIST = 'GET_GDSN_PARTY_LIST';
export const GET_GDSN_PARTY_LIST_FILTERS = 'GET_GDSN_PARTY_LIST_FILTERS';
export const CHANGE_LIMIT_GDSN_PARTY_LIST = 'CHANGE_LIMIT_GDSN_PARTY_LIST';
export const NEXT_PAGE_GDSN_PARTY_LIST = 'NEXT_PAGE_GDSN_PARTY_LIST';
export const PREVIOUS_PAGE_GDSN_PARTY_LIST = 'PREVIOUS_PAGE_GDSN_PARTY_LIST';

// Filters
export const RECEIVE_GDSN_PARTY_LIST_FILTERS =
  'RECEIVE_GDSN_PARTY_LIST_FILTERS';
export const ADD_GDSN_PARTY_LIST_FILTER = 'ADD_GDSN_PARTY_LIST_FILTER';
export const REMOVE_GDSN_PARTY_LIST_FILTER = 'REMOVE_GDSN_PARTY_LIST_FILTER';
export const UPDATE_GDSN_PARTY_LIST_FILTER = 'UPDATE_GDSN_PARTY_LIST_FILTER';
export const CLEAR_GDSN_PARTY_LIST_FILTER = 'CLEAR_GDSN_PARTY_LIST_FILTER';

export const DOWNLOAD_GDSN_PARTY_DOCUMENT = 'DOWNLOAD_GDSN_PARTY_DOCUMENT';

export const OPEN_GDSN_PARTY_MODAL = 'OPEN_GDSN_PARTY_MODAL';
export const CLOSE_GDSN_PARTY_MODAL = 'CLOSE_GDSN_PARTY_MODAL';
export const GDSN_PARTY_MODAL_SAVE = 'GDSN_PARTY_MODAL_SAVE';
export const GDSN_PARTY_ATTR_UPDATE = 'GDSN_PARTY_ATTR_UPDATE';

export const GDSN_PARTY_SEND_DELETE = 'GDSN_PARTY_SEND_DELETE';
