import { flow, set, update } from 'lodash/fp';
import { createFpReducer } from 'utils/reducers';
import { ActionPayload } from 'utils/reducers/types';
import {
  validationAddToFilters,
  validationDeleteRuleDone,
  validationDeleteRulesDone,
  validationFetchRuleDimensionsDone,
  validationFetchRuleDone,
  validationFetchRulesDone,
  validationFetchRuleSetDone,
  validationFetchRuleSetTagsDone,
  validationFillOrganizationsCache,
  validationInitFiltersDone,
  validationRemoveFromFilters,
  validationSaveRuleData,
  validationSaveRuleDone,
  validationSetDirtyRule,
  validationSetEditedRule,
  validationSetPagination,
  validationToggleRules,
} from './actions';
import {
  VALIDATION_ADD_TO_FILTERS,
  VALIDATION_CLEAR_RULES_FILTERS,
  VALIDATION_CLEAR_SELECTED_RULES,
  VALIDATION_DELETE_RULE_DONE,
  VALIDATION_DIRTY_RULE,
  VALIDATION_EDITED_RULE,
  VALIDATION_FETCHING_RULE_SET_TAGS,
  VALIDATION_FETCH_RULESET_DONE,
  VALIDATION_FETCH_RULES_DONE,
  VALIDATION_FETCH_RULE_DIMENSIONS_DONE,
  VALIDATION_FETCH_RULE_DONE,
  VALIDATION_FETCH_RULE_SET_TAGS_DONE,
  VALIDATION_FILL_ORGANIZATIONS_CACHE,
  VALIDATION_INIT_FILTERS_DONE,
  VALIDATION_REMOVE_FROM_FILTERS,
  VALIDATION_RESET_PATH_VALUE_FROM_FILTERS,
  VALIDATION_RESET_RULESET,
  VALIDATION_SAVE_RULE_CANCEL,
  VALIDATION_SAVE_RULE_DATA,
  VALIDATION_SAVE_RULE_DONE,
  VALIDATION_SEARCH_QUERY,
  VALIDATION_SET_IS_LOADING,
  VALIDATION_SET_IS_LOADING_DIMENSIONS,
  VALIDATION_SET_IS_LOADING_RULE,
  VALIDATION_SET_PAGINATION,
  VALIDATION_TOGGLE_RULES,
  VALIDATION_DELETE_RULES_DONE,
} from './constants';
import {
  defaultFiltersValues,
  emptyRuleset,
  initialState,
  RuleSetTagsFetchState,
  ValidationDashboardState,
} from './moduleState';

const validationFetchRulesDoneReducer = ({
  data: rules,
  totalPages,
  totalResults,
}: ActionPayload<typeof validationFetchRulesDone>) =>
  flow(
    set(['rules'], rules),
    set(['pagination', 'totalPages'], totalPages),
    set(['pagination', 'totalResults'], totalResults)
  );

const validationSetEditedRuleReducer = (
  rule: ActionPayload<typeof validationSetEditedRule>
) => flow(set(['editingRule'], rule), set(['isSavingRules', rule.id], false));

const validationSetPaginationReducer = (
  pagination: ActionPayload<typeof validationSetPagination>,
  state: ValidationDashboardState
) => set(['pagination'], { ...state.pagination, ...pagination });

const validationFillOrganizationsCacheReducer = (
  organizations: ActionPayload<typeof validationFillOrganizationsCache>,
  state
) => {
  const mappedOrganizations = organizations.reduce(
    (acc, org) => ({ ...acc, [org.id]: org.name }),
    state.cachedOrganizations
  );
  return set(['cachedOrganizations'], mappedOrganizations);
};

const validationSetDirtyRuleReducer = (
  rule: ActionPayload<typeof validationSetDirtyRule>
) => set(['dirtyRule'], rule);

const validationFetchRuleDoneReducer = (
  rule: ActionPayload<typeof validationFetchRuleDone>
) => {
  if (!rule.kindsAreManuallySelected) {
    rule.applicableForKinds = [];
  }
  return flow(set(['dirtyRule'], rule), set(['editingRule'], rule));
};

const validationFetchRuleDimensionsDoneReducer = (
  fields_raw_applicability: ActionPayload<
    typeof validationFetchRuleDimensionsDone
  >
) =>
  set(
    ['dirtyRule', 'metadata', 'fields_raw_applicability'],
    fields_raw_applicability
  );

const validationInitFiltersDoneReducer = (
  filters: ActionPayload<typeof validationInitFiltersDone>
) => set(['filters'], filters);

const validationSearchQueryReducer = (search: string) =>
  set(['filters', 'search'], search);

const validationAddToFiltersReducer = ({
  path,
  value,
}: ActionPayload<typeof validationAddToFilters>) => {
  if (typeof value == 'boolean') {
    return update(['filters', 'selectedFilters', path], () => value);
  }

  return update(
    ['filters', 'selectedFilters', path],
    (currentFilters: number[] | string[]) => {
      if (Array.isArray(value)) {
        return [...currentFilters, ...value];
      } else {
        return [...currentFilters, value];
      }
    }
  );
};

const validationRemoveFromFiltersReducer = ({
  path,
  value,
}: ActionPayload<typeof validationRemoveFromFilters>) => {
  return update(['filters', 'selectedFilters', path], (currentFilters: any[]) =>
    currentFilters.filter((f) => {
      if (Array.isArray(value)) {
        return !value.includes(f);
      }
      return f !== value;
    })
  );
};

const validationResetPathValueFromFiltersReducer = ({
  path,
}: ActionPayload<typeof validationRemoveFromFilters>) => {
  return update(
    ['filters', 'selectedFilters', path],
    () => defaultFiltersValues[path]
  );
};

const validationClearRulesFiltersReducer = () =>
  update(['filters', 'selectedFilters'], (currentFilters: any[]) =>
    Object.keys(currentFilters).reduce((nextFilters, filterKey) => {
      nextFilters[filterKey] = defaultFiltersValues[filterKey];
      return nextFilters;
    }, {})
  );

const validationSetIsLoadingReducer = (isLoading: boolean) =>
  set(['isLoading'], isLoading);

const validationSetIsLoadingRuleReducer = (isLoading: boolean) =>
  set(['isLoadingRule'], isLoading);

const validationSetIsLoadingDimensionsReducer = (isLoading: boolean) =>
  set(['isLoadingDimensions'], isLoading);

const validationSaveRuleReducer = (
  rule: ActionPayload<typeof validationSaveRuleData>
) => set(['isSavingRules', rule.id], true);

const validationSaveRuleDoneReducer = (
  rule: ActionPayload<typeof validationSaveRuleDone>
) => set(['isSavingRules', rule.id], false);

const validationFetchingRuleSetTagsReducer = () =>
  set(['ruleSetTags'], 'loading' as RuleSetTagsFetchState);

const validationFetchRuleSetTagsDoneReducer = (
  ruleSetTags: ActionPayload<typeof validationFetchRuleSetTagsDone>
) => set(['ruleSetTags'], ruleSetTags);

const validationDeleteRuleDoneReducer = (
  deletedRuleId: ActionPayload<typeof validationDeleteRuleDone>
) =>
  update(['rules'], (rules) =>
    rules.filter((rule) => rule.id !== deletedRuleId)
  );

const validationDeleteRulesDoneReducer = (
  deletedRuleIds: ActionPayload<typeof validationDeleteRulesDone>
) =>
  update(['rules'], (rules) =>
    rules.filter((rule) => !deletedRuleIds.includes(rule.id))
  );

const validationToggleRulesReducer = (
  rules: ActionPayload<typeof validationToggleRules>
) => {
  return update(
    ['selectedRules'],
    (currentSelectedRules: { [id: number]: boolean }) => {
      const nextSelectedRules = { ...currentSelectedRules };

      for (const ruleId in rules) {
        if (!rules[ruleId] && ruleId in nextSelectedRules) {
          delete nextSelectedRules[ruleId];
        } else if (rules[ruleId]) {
          nextSelectedRules[ruleId] = true;
        }
      }

      return nextSelectedRules;
    }
  );
};

const validationClearSelectedRulesReducer = () => {
  return set(['selectedRules'], {});
};

const validationFetchRuleSetDoneReducer = (
  ruleSet: ActionPayload<typeof validationFetchRuleSetDone>
) => set(['ruleSet'], ruleSet);

const validationResetRuleSetReducer = () => set(['ruleSet'], emptyRuleset);

export default createFpReducer<ValidationDashboardState>(initialState, {
  [VALIDATION_FETCH_RULES_DONE]: validationFetchRulesDoneReducer,
  [VALIDATION_ADD_TO_FILTERS]: validationAddToFiltersReducer,
  [VALIDATION_INIT_FILTERS_DONE]: validationInitFiltersDoneReducer,
  [VALIDATION_FILL_ORGANIZATIONS_CACHE]:
    validationFillOrganizationsCacheReducer,
  [VALIDATION_REMOVE_FROM_FILTERS]: validationRemoveFromFiltersReducer,
  [VALIDATION_RESET_PATH_VALUE_FROM_FILTERS]:
    validationResetPathValueFromFiltersReducer,
  [VALIDATION_SAVE_RULE_DATA]: validationSaveRuleReducer,
  [VALIDATION_SAVE_RULE_DONE]: validationSaveRuleDoneReducer,
  [VALIDATION_SAVE_RULE_CANCEL]: validationSaveRuleDoneReducer,
  [VALIDATION_SET_IS_LOADING]: validationSetIsLoadingReducer,
  [VALIDATION_SET_IS_LOADING_RULE]: validationSetIsLoadingRuleReducer,
  [VALIDATION_SET_IS_LOADING_DIMENSIONS]:
    validationSetIsLoadingDimensionsReducer,
  [VALIDATION_SEARCH_QUERY]: validationSearchQueryReducer,
  [VALIDATION_EDITED_RULE]: validationSetEditedRuleReducer,
  [VALIDATION_DIRTY_RULE]: validationSetDirtyRuleReducer,
  [VALIDATION_FETCH_RULE_DONE]: validationFetchRuleDoneReducer,
  [VALIDATION_FETCH_RULE_DIMENSIONS_DONE]:
    validationFetchRuleDimensionsDoneReducer,
  [VALIDATION_SET_PAGINATION]: validationSetPaginationReducer,
  [VALIDATION_FETCHING_RULE_SET_TAGS]: validationFetchingRuleSetTagsReducer,
  [VALIDATION_FETCH_RULE_SET_TAGS_DONE]: validationFetchRuleSetTagsDoneReducer,
  [VALIDATION_CLEAR_RULES_FILTERS]: validationClearRulesFiltersReducer,
  [VALIDATION_DELETE_RULE_DONE]: validationDeleteRuleDoneReducer,
  [VALIDATION_DELETE_RULES_DONE]: validationDeleteRulesDoneReducer,
  [VALIDATION_TOGGLE_RULES]: validationToggleRulesReducer,
  [VALIDATION_CLEAR_SELECTED_RULES]: validationClearSelectedRulesReducer,
  [VALIDATION_FETCH_RULESET_DONE]: validationFetchRuleSetDoneReducer,
  [VALIDATION_RESET_RULESET]: validationResetRuleSetReducer,
});
