import { get, cloneDeep as clone } from 'lodash';

import { PRODUCTS_SORT_BY, SORT_ORDER } from '@alkem/sdk-dashboard';
import { updateMultipleSelection } from '@alkem/react-ui-choicetree';

import storage from 'utils/storage';

export const receiveReducer = (state, action) => {
  const newState = { ...state };
  newState.list = action.list;
  newState.pagination = { ...state.pagination };
  newState.isLoading = false;
  if (action.resetPagination) {
    newState.pagination.currentPage = 1;
    newState.resetPagination = true;
  } else {
    newState.resetPagination = false;
  }
  if (action.totalResults) {
    newState.pagination.totalResults = action.totalResults;
    newState.pagination.totalPages = Math.ceil(
      action.totalResults / newState.pagination.limit
    );
  }
  return newState;
};

export const getReducer = (state, action) => {
  const newState = { ...state };
  newState.filters = action.filters;
  newState.search = action.search;
  newState.isLoading = true;
  if (action.newList) {
    newState.pagination.currentPage = 1;
    newState.pagination.totalPages = 1;
    newState.totalResults = 0;
  }
  return newState;
};

export const addFilter = (state, { key, value }) => {
  const newState = { ...state };
  newState.filters = { ...state.filters };
  newState.filters[key].push(value);
  return newState;
};

export const updateMultipleFilters = (
  state,
  { key, path, optionsKey, select }
) => {
  const newState = { ...state };
  newState.filters = { ...state.filters };
  const filters = newState.filters[key];
  const selection = filters.map((filter) => filter.path);
  const options = state[optionsKey];
  const newSelection = updateMultipleSelection(
    selection,
    path,
    select,
    options
  );
  const newFilters = newSelection
    .map((selectionPath) => {
      const option = get(options, selectionPath.join('.children.'));
      if (!option) {
        return null;
      }
      return { value: option.key, label: option.label, path: selectionPath };
    })
    .filter((opt) => !!opt);
  newState.filters[key] = newFilters;
  return newState;
};

export const addOneOptionFilter = (state, action) => {
  const newState = { ...state };
  newState.filters = clone(state.filters);
  newState.filters[action.key] = action.value;
  return newState;
};

export const updateFilter = (state, action) => {
  const newState = { ...state };
  newState.filters = clone(state.filters);
  if (action.value && action.value.value) {
    const existingFilter = newState.filters[action.key].find(
      (f) => f.name === action.value.name
    );
    if (existingFilter) {
      existingFilter.value = action.value.value;
    } else {
      newState.filters[action.key].push(action.value);
    }
  } else {
    newState.filters[action.key] = newState.filters[action.key].filter(
      (f) => f.name !== action.value.name
    );
  }
  return newState;
};

export const removeFilter = (state, action) => {
  const newState = { ...state };
  newState.filters = clone(state.filters);
  if (Array.isArray(newState.filters[action.key])) {
    newState.filters[action.key] = newState.filters[action.key].filter(
      (filter) => filter.value !== action.value.value
    );
  } else {
    newState.filters[action.key] = null;
  }
  return newState;
};

export const clearFilter = (state) => {
  const newState = { ...state };
  newState.filters = clone(state.filters);
  Object.keys(newState.filters).forEach((key) => {
    newState.filters[key] = Array.isArray(newState.filters[key]) ? [] : null;
  });
  return newState;
};

export const nextPage = (state) => {
  const newState = { ...state };
  newState.pagination = { ...state.pagination };
  newState.resetPagination = false;
  if (newState.pagination.currentPage < newState.pagination.totalPages) {
    newState.pagination.currentPage += 1;
  }
  return newState;
};

export const previousPage = (state) => {
  const newState = { ...state };
  newState.pagination = { ...state.pagination };
  newState.resetPagination = false;
  if (newState.pagination.currentPage > 1) {
    newState.pagination.currentPage -= 1;
  }
  return newState;
};

export const search = (state, action) => {
  const newState = { ...state };
  newState.search = action.search;
  return newState;
};

export const changeLimit = (state, action, limitStorageKey) => {
  const { limit } = action;
  if (typeof limit !== 'number' || Number.isNaN(limit) || limit <= 0) {
    return state;
  }
  if (limitStorageKey) {
    storage.setItem(limitStorageKey, limit);
  }
  return { ...state, pagination: { ...state.pagination, limit } };
};

export const changeSorting = (state, action, sortingStorageKey) => {
  const { sortBy, sortOrder } = action;
  if (sortingStorageKey) {
    storage.setItem(sortingStorageKey, { sortBy, sortOrder });
  }
  return { ...state, pagination: { ...state.pagination, sortBy, sortOrder } };
};

export const initPagination = (
  limitStorageKey,
  sortingStorageKey,
  defaultSort
) => {
  let limit = 20;
  let sortBy = defaultSort ? defaultSort.sortBy : PRODUCTS_SORT_BY.UPDATED_AT;
  let sortOrder = defaultSort ? defaultSort.sortOrder : SORT_ORDER.DESC;
  if (limitStorageKey) {
    limit = storage.getItem(limitStorageKey, 20);
  }
  if (sortingStorageKey) {
    const sortingValue = storage.getItem(sortingStorageKey, {
      sortBy,
      sortOrder,
    });
    ({ sortBy, sortOrder } = sortingValue);
  }
  return {
    totalResults: 0,
    currentPage: 1,
    totalPages: 1,
    limit,
    sortBy,
    sortOrder,
  };
};

export const resetPagination = (state) => {
  return {
    ...state,
    pagination: {
      ...state.pagination,
      totalResults: 0,
      currentPage: 1,
      totalPages: 1,
    },
  };
};
