import { TurboSelect } from '@alkem/react-ui-turbo-select';
import { CodeEditor } from 'components/code-editor';
import { useState } from 'react';
import { ChoiceTree } from '@alkem/react-ui-choicetree';
import './rule-applier-execution-details.scss';
import { LazyTooltip } from '@alkem/react-ui-tooltip';
import { RuleApplierCallDetail } from './rule-applier-call-detail';
import { DebugInfo } from '../type';

export const RuleApplierExecutionDetails = ({
  debugInfo,
}: {
  debugInfo: DebugInfo;
}) => {
  const remapCallTraceData = (step: string) => {
    const transform = (element) => {
      if (!element) return [];
      return {
        key: element.seq_no,
        label: element.expression,
        isOpen: !!element.raise_flag,
        value: {
          item: element.item,
          typename: element.typename,
          seq_no: element.seq_no,
          raise_flag: !!element.raise_flag,
          children: element.children || [],
          outcome: element.outcome,
          expression: element.expression,
          duration: element.duration,
        },
        children: element.children.map((e) => transform(e)),
      };
    };

    const callTrace =
      step === 'Condition'
        ? debugInfo.call_traces.condition?.call_trace
        : debugInfo.call_traces.validation?.call_trace;
    return [callTrace].map((entry) => transform(entry));
  };
  const extractSymbols = (step: string) => {
    return step === 'Condition'
      ? debugInfo.call_traces.condition?.symbols
      : debugInfo.call_traces.validation?.symbols;
  };

  const extractFinalOutcome = (step: string) => {
    return step === 'Condition'
      ? debugInfo.call_traces.condition?.outcome
      : debugInfo.call_traces.validation?.outcome;
  };
  const computeCallCount = (step: string) => {
    // Call count is given by the highest sequence number.
    const getMaxSeqNo = (element) => {
      if (element.children.length > 0) {
        return Math.max(...element.children.map((e) => getMaxSeqNo(e)));
      }
      return element.seq_no;
    };

    const callTrace =
      step === 'Condition'
        ? debugInfo.call_traces.condition?.call_trace
        : debugInfo.call_traces.validation?.call_trace;
    return getMaxSeqNo(callTrace);
  };

  const itemRenderer = (label: string, item: any) => {
    const className = 'RuleApplierCallTraceDetails__choiceTreeItemMarker--';
    if (item.value.raise_flag) {
      // We may have children which are all params and they do not raise.
      // to be the root cause of the failure, we must have raise_flag and
      // no children with the raise_flag set.
      const rootCause = !item.value.children.filter((e: any) => e.raise_flag)
        .length;
      const marker = className + (rootCause ? 'final' : 'intermediate');
      return (
        <>
          <span className={marker}>&#9679;</span> {label}
        </>
      );
    }

    return (
      <>
        <span className="RuleApplierCallTraceDetails__choiceTreeItemMarker--success">
          &#9679;
        </span>{' '}
        {label}
      </>
    );
  };

  const possibleCallTraces: Array<any> = [];
  // This is used to determine which calltrace we should consider upon loading.
  // We take the one with an outcome, otherwise, the first in the possibleCallTraces array.
  let considerCallTrace: any = null;

  if (debugInfo.call_traces.condition) {
    const callTrace = { id: 1, label: 'Condition' };
    possibleCallTraces.push(callTrace);
    if (debugInfo.call_traces.condition.outcome?.interruption) {
      considerCallTrace = callTrace;
    }
  }
  if (debugInfo.call_traces.validation) {
    const callTrace = { id: 2, label: 'Validation' };
    possibleCallTraces.push(callTrace);
    if (debugInfo.call_traces.validation.outcome?.interruption) {
      considerCallTrace = callTrace;
    }
  }

  considerCallTrace = considerCallTrace || possibleCallTraces[0];

  // Steps are either condition or validation. It is basically the rule's statement being evaluated.
  const [currentStep, setCurrentStep] = useState<any>(considerCallTrace);
  // Current item selected in the tree.
  const [selectedItem, setSelectedItem] = useState<any>([]);
  // For display purpose and to avoid recomputing this information each time we render the component.
  const [callCount, setCallCount] = useState<number>(
    computeCallCount(currentStep.label)
  );

  return (
    <>
      <h4 className="RuleApplierCallTraceDetails__Header">Execution details</h4>
      <h5>Selectors Evaluation</h5>
      <div className="alk-flex">
        <div className="alk-flex alk-flex-1 alk-flex-justify-center">
          <CodeEditor
            name="symbolOutput"
            className="ApplyRuleMode__Symbol"
            value={JSON.stringify(
              extractSymbols(currentStep.label),
              null,
              '   '
            )}
            mode="json"
            theme="monokai"
            width="80%"
            height="300px"
            editorProps={{ $blockScrolling: Infinity }}
            readOnly={true}
            showGutter={false}
            showPrintMargin={false}
          />
        </div>
      </div>
      <div className="RuleApplierCallTraceDetailSelection">
        <div className="alk-flex">
          <div className="alk-flex alk-flex-3 alk-flex-center ">
            Select step:
          </div>
          <div className="alk-flex alk-flex-1" data-testid="callTraceDropDown">
            <LazyTooltip
              id={'explain-call-trace-drop-down'}
              place={'top'}
              tooltipLabel={
                'Select the step you want to analyze and start discovering how your statement has been evaluated.'
              }
            >
              <TurboSelect
                isMulti={false}
                value={currentStep}
                options={possibleCallTraces}
                onChange={(option: any) => {
                  setCurrentStep(option);
                  setCallCount(computeCallCount(option.label));
                  setSelectedItem([]);
                }}
              />
            </LazyTooltip>
          </div>
        </div>
      </div>
      <h5>Details of {currentStep.label} Call Trace</h5>
      <div className="alk-flex">
        <div className="alk-flex alk-flex-1 alk-flex-column alk-flex-justify-center">
          <div className="CallTrace">
            <ChoiceTree
              id={`choicetree-${currentStep.label}`}
              data-testid={`choicetree-${currentStep.label}`}
              options={remapCallTraceData(currentStep.label)}
              onSelect={(element) => {
                setSelectedItem(element);
              }}
              selectors={{
                selectId: (item) => item['key'],
                selectLabel: (item) => item['label'],
              }}
              multiple={false}
              flatten={false}
              extraOptions={{ selectableParent: true }}
              renderItem={itemRenderer}
            />
          </div>
        </div>
        <div className="alk-flex alk-flex-1 alk-flex-column alk-flex-justify-center">
          <RuleApplierCallDetail
            callCount={callCount}
            callDetails={selectedItem.value}
            outcome={extractFinalOutcome(currentStep.label)}
          />
        </div>
      </div>
    </>
  );
};
