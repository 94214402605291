export const defaultFilters = [
  {
    name: 'Owner Status 1',
    default: true,
    key: 'OwnerStatus',
    admin: true,
    filter: { query: 1, fields: ['owner.status'] },
  },
  {
    name: 'Owner Network 1',
    default: true,
    key: 'OwnerNetwork',
    admin: true,
    filter: { query: 1, fields: ['owner.network'] },
  },
  {
    name: 'Is not accept_idle',
    default: true,
    key: 'NotAcceptIdle',
    admin: true,
    filter: { must_not: [{ query: 'accept_idle', fields: ['isTaggedBy'] }] },
  },
  {
    name: 'Is display as target',
    default: true,
    key: 'isDisplayableAsTarget',
    filter: { query: true, fields: ['alkemics.is_displayable_as_target'] },
  },
  {
    name: 'Is not archived',
    default: true,
    key: 'NotArchived',
    filter: { must_not: [{ query: 3, fields: ['alkemics.lifeCycle'] }] },
  },
  {
    name: 'Is consumer Unit',
    default: true,
    key: 'isConsumerUnit',
    group: 'Unit',
    filter: { query: true, fields: ['isConsumerUnit'] },
  },
  {
    name: 'Is display Unit',
    default: true,
    key: 'isDisplayUnit',
    group: 'Unit',
    filter: { query: true, fields: ['isDisplayUnit'] },
  },
  {
    name: 'Workflow - manufacturer',
    default: true,
    key: 'WorkflowManufacturer',
    group: 'Classification Report',
    filter: { query: 'manufacturer', fields: ['workflow'] },
  },
  {
    name: 'Workflow - manufacturer realtime',
    default: true,
    key: 'WorkflowManufacturerRealtime',
    group: 'Classification Report',
    filter: { query: 'manufacturer_for_realtime', fields: ['workflow'] },
  },
  {
    name: 'Workflow - retailer',
    default: true,
    key: 'WorkflowRetailer',
    group: 'Classification Report',
    filter: { query: 'retailer', fields: ['workflow'] },
  },
  {
    name: 'Last week',
    default: true,
    key: 'LastWeek',
    group: 'Classification Report',
    filter: { query: { gte: 'now-2w' }, fields: ['date'], type: 'range' },
  },
  {
    name: 'Language - French',
    default: true,
    key: 'French',
    group: 'Classification Report',
    filter: { query: 'fr', fields: ['language'] },
  },
  {
    name: 'Language - English',
    default: true,
    key: 'English',
    group: 'Classification Report',
    filter: { query: 'en', fields: ['language'] },
  },
];
