import { useMemo } from 'react';
import { get, getIn } from 'utils/immutable';

import { ruleEntityTypes } from 'modules/validation-dashboard/constants';

export const {
  CONSUMER_UNIT: { id: CONSUMER_UNIT },
  DISPLAY_UNIT: { id: DISPLAY_UNIT },
  TEXTILE_VARIANT: { id: TEXTILE_VARIANT },
  SHARING_UNIT: { id: SHARING_UNIT },
  SHARING_UNITS: { id: SHARING_UNITS },
  PRICE_WATERFALL: { id: PRICE_WATERFALL },
  LOGISTICAL_HIERARCHY: { id: LOGISTICAL_HIERARCHY },
  LOGISTICAL_UNIT: { id: LOGISTICAL_UNIT },
  LOGISTICAL_HIERARCHY_TOP_UNIT: { id: LOGISTICAL_HIERARCHY_TOP_UNIT },
} = ruleEntityTypes;

const ENTITYTYPES_ALLOWING_BYPASSABLE_RULES = [CONSUMER_UNIT, DISPLAY_UNIT];
const ENTITYTYPES_ALLOWING_KINDS = [
  CONSUMER_UNIT,
  TEXTILE_VARIANT,
  SHARING_UNIT,
  SHARING_UNITS,
  PRICE_WATERFALL,
  LOGISTICAL_HIERARCHY,
  LOGISTICAL_UNIT,
  LOGISTICAL_HIERARCHY_TOP_UNIT,
];
const ENTITYTYPES_ALLOWING_PACKAGING_TYPES = [
  CONSUMER_UNIT,
  TEXTILE_VARIANT,
  DISPLAY_UNIT,
  LOGISTICAL_HIERARCHY,
  LOGISTICAL_UNIT,
  LOGISTICAL_HIERARCHY_TOP_UNIT,
];

type FieldName = 'blocking' | 'bypassable' | 'kinds' | 'packagingTypes';

type ChildrenAvailability = Record<FieldName, boolean>;

export const useChildrenAvailability: (rule: object) => ChildrenAvailability = (
  rule
) => {
  const availability = useMemo<ChildrenAvailability>(() => {
    const entityType = get(rule, 'entityType');
    return {
      blocking: !getIn(rule, ['ruleSet', 'id']),
      bypassable: ENTITYTYPES_ALLOWING_BYPASSABLE_RULES.includes(entityType),
      kinds: ENTITYTYPES_ALLOWING_KINDS.includes(entityType),
      packagingTypes: ENTITYTYPES_ALLOWING_PACKAGING_TYPES.includes(entityType),
    };
  }, [rule]);

  return availability;
};
