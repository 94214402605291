import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ListController from 'components/ui/list/controller';
import { Button } from '@alkem/react-ui-button';

import { Spinner } from '@alkem/react-ui-spinner';

import { separateActions } from 'utils/redux';

import { selectState, selectList, selectPagination } from '../selectors';

import GDSNCIListRow from './row';
import GDSNCIHWModal from '../modal';

import { nextPage, previousPage, changeLimit, getGDSNCIList } from '../actions';

const mapStateToProps = (state) => ({
  list: selectList(state),
  isLoading: selectState(state).isLoading,
  pagination: selectPagination(state),
});

const mapDispatchToProps = {
  getGDSNCIList,
  nextPage,
  previousPage,
  changeLimit,
};

export class GDSNCIList extends PureComponent {
  static propTypes = {
    list: PropTypes.array.isRequired,
    isLoading: PropTypes.bool,
    pagination: PropTypes.object.isRequired,
    actions: PropTypes.shape({
      getGDSNCIList: PropTypes.func.isRequired,
      nextPage: PropTypes.func.isRequired,
      previousPage: PropTypes.func.isRequired,
      changeLimit: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    isLoading: true,
  };

  componentDidMount() {
    this.props.actions.getGDSNCIList();
  }

  refresh = () => {
    this.props.actions.getGDSNCIList();
  };

  renderHeaders() {
    return (
      <div className="GDSNCIListRowWrapper GDSNCIListHeader row">
        <div className="col-xs-5">
          <div>Owner (GLN)</div>
          <div>↳ Recipient (GLN)</div>
        </div>
        <div className="col-xs-2">
          <div>
            <i className="mdi mdi-barcode" /> GTIN
          </div>
          <div>
            <i className="mdi mdi-chemical-weapon" /> GPC
          </div>
        </div>
        <div className="col-xs-1">Status</div>
        <div className="col-xs-2">
          <div>
            <i className="mdi mdi-note-plus" /> Creation
          </div>
          <div>
            <i className="mdi mdi-sync" /> Last update
          </div>
        </div>
        <GDSNCIHWModal />
      </div>
    );
  }

  renderList() {
    const { list, isLoading } = this.props;
    if (isLoading) {
      return (
        <div className="GDSNCIList__spinner">
          <Spinner loading small />
        </div>
      );
    }
    return list.map((ci) => <GDSNCIListRow key={ci.id} ci={ci} />);
  }

  render() {
    const { pagination, actions } = this.props;
    const { currentPage, totalPages, limit, totalResults } = pagination;
    return (
      <div>
        <ListController
          actions={
            <div>
              <Button onClick={this.refresh}>
                <i className="mdi mdi-refresh" />
              </Button>
            </div>
          }
          onNext={actions.nextPage}
          onPrev={actions.previousPage}
          currentPage={currentPage}
          totalPages={totalPages}
          limit={limit}
          totalResults={totalResults}
          onLimitChange={actions.changeLimit}
          rowsLength={0}
          type="endpointlist"
        >
          <div>{this.renderHeaders()}</div>
          <div>{this.renderList()}</div>
        </ListController>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(GDSNCIList);
