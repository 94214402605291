import { MODULE_REDUCER_KEY } from 'constants/reducer';

import { EXPORT_LIST_STORE_KEY } from '../constants/reducers';

export function selectState(state) {
  return state[MODULE_REDUCER_KEY][EXPORT_LIST_STORE_KEY];
}

export function selectList(state) {
  return selectState(state).list;
}

export function selectPagination(state) {
  return selectState(state).pagination;
}

export function selectFilters(state) {
  return selectState(state).filters;
}

export function selectFiltersAvailable(state) {
  return selectState(state).filtersAvailable;
}

export function selectSearch(state) {
  return selectState(state).search;
}

export function selectIsModalOpen(state) {
  return selectState(state).modal.isOpen;
}

export function selectModalParameters(state) {
  return selectState(state).modal.parameters;
}

export function selectCurrentExportFile(state) {
  return selectState(state).modal.currentExportFile;
}

export function selectIsPotentialSupplierExportFileInProgress(state) {
  return selectState(state).potentialSupplierModal
    .isPotentialSupplierExportFileInProgress;
}

export function selectIsPotentialSupplierModalOpen(state) {
  return selectState(state).potentialSupplierModal.isPotentialSupplierOpen;
}

export function selectPotentialSupplierModalParameters(state) {
  return selectState(state).potentialSupplierModal.parameters;
}

export function selectcurrentPotentialSupplierExportFile(state) {
  return selectState(state).potentialSupplierModal
    .currentPotentialSupplierExportFile;
}

export function selectIsExportFileInProgress(state) {
  return selectState(state).modal.isExportFileInProgress;
}

export function selectReferences(state) {
  return selectState(state).references;
}

export function selectIsGDSNModalOpen(state) {
  return selectState(state).gdsnModal.isOpen;
}

export function selectIsViewJsonModalOpen(state) {
  return selectState(state).viewJsonModal.isOpen;
}

export function selectViewJsonData(state) {
  return selectState(state).viewJsonModal.jsonData;
}
