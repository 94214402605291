import { selectMainState } from '.';
import { MODAL_REDUCER_KEY } from '../constants';

export function selectModalState(state) {
  return selectMainState(state)[MODAL_REDUCER_KEY];
}

export function selectModalUser(state) {
  return selectModalState(state).get('user');
}

export function selectIsModalOpen(state) {
  return selectModalState(state).get('isOpen');
}

export function selectIsModalLoading(state) {
  return selectModalState(state).get('isLoading');
}

export function selectIsEdited(state) {
  return selectModalState(state).get('isEdited');
}

export function selectFormState(state) {
  return selectModalState(state).get('form');
}

export function selectFormFirstname(state) {
  return selectFormState(state).get('firstname');
}

export function selectFormLastname(state) {
  return selectFormState(state).get('lastname');
}

export function selectFormTitle(state) {
  return selectFormState(state).get('title');
}

export function selectFormUsername(state) {
  return selectFormState(state).get('username');
}

export function selectFormPhonenumber(state) {
  return selectFormState(state).get('phonenumber');
}

export function selectFormOrganization(state) {
  return selectFormState(state).get('organization');
}

export function selectFormOrganizationRole(state) {
  return selectFormState(state).get('organizationRole');
}

export function selectFormType(state) {
  return selectFormState(state).get('type');
}

export function selectTokens(state) {
  return selectModalState(state).get('tokens');
}
