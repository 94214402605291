import InputWithLabel from 'components/input-with-label';
import { Text } from '@alkem/react-ui-inputs';
import { SimpleSelect } from '@alkem/react-ui-select';

import './constant.scss';
import {
  ExportMappingConstantCast,
  ExportMappingPayloadConstant,
  Option,
  Options,
} from '../../../../types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectFunctionNotValueOptions } from '../../../../selectors';
import classNames from 'classnames';

type ItemTypeOption = {
  id: string;
  label: string;
};

const constantOptions = [
  { id: 'function', label: 'Function' },
  { id: 'lambda', label: 'Lambda' },
];

const getInitialItemType = (cast: string) => {
  if (!cast) return undefined;
  return constantOptions.find(({ id }) => id === cast);
  return undefined;
};

const getInitialFunctionValue = (
  detail: ExportMappingPayloadConstant,
  functionOptions: Options
) => {
  const { cast, data } = detail;
  if (!cast || cast !== 'function') return undefined;

  return functionOptions.find(
    (functionOption) => functionOption.label === data
  );
};

interface Props {
  name: string;
  details: ExportMappingPayloadConstant;
  removeConstant: Function;
  addConstant: Function;
  isEditable?: boolean;
}
export function ExportMappingsItemConstantItem({
  name,
  details,
  removeConstant,
  addConstant,
  isEditable = false,
}: Props) {
  const [cast, setCast] = useState<ItemTypeOption | undefined>(
    getInitialItemType(details.cast)
  );
  const [nameState, setName] = useState(name);
  const functionOptions = useSelector(selectFunctionNotValueOptions);
  const [functionOption, setFunctionOption] = useState<Option | undefined>(
    getInitialFunctionValue(details, functionOptions)
  );
  const [detailData, setDetailValue] = useState(details.data);

  const updateItemType = (value: ItemTypeOption) => {
    setCast(value);
  };

  const updateFunction = (value: ItemTypeOption) => {
    setFunctionOption(value);
    setDetailValue(value.label);
  };

  return (
    <div
      className="GDSNExportMappingsItemConstant"
      data-testid={`const-${name}-${isEditable}`}
    >
      <div className="GDSNExportMappingsItemConstant__button">
        {isEditable && cast && detailData && nameState && (
          <span
            className={classNames({
              'mdi mdi-check': true,
            })}
            onClick={() => addConstant(nameState, cast.id, detailData)}
          />
        )}
        <span
          data-testid={`remove-${name}`}
          className="GDSNExportMappingsListElementItemType__reset-button"
          onClick={() => removeConstant(name)}
        >
          <i className="mdi mdi-trash-can" />
        </span>
      </div>
      <InputWithLabel
        inputId={`gdsn-export-mappings-item-name`}
        label="Constant name"
      >
        <div className="GDSNExportMappingsItemConstants__name">
          <Text
            className="GDSNExportMappingsItemConstants__name"
            id={`gdsn-export-mappings-item-constant-name`}
            value={nameState}
            onChange={(e) => {
              setName(e.target.value);
            }}
            disabled={!isEditable}
          />
        </div>
      </InputWithLabel>
      <InputWithLabel
        inputId={`gdsn-export-mappings-item-${name}-constants-type`}
        label="Type"
      >
        <div data-testid={`type-${name}`}>
          <SimpleSelect
            id={`gdsn-export-mappings-list-element-${name}-type-property`}
            options={constantOptions}
            onSelect={updateItemType}
            value={cast}
            placeholder="Select..."
            disabled={!isEditable}
          />
        </div>
      </InputWithLabel>
      {cast && cast.id === ExportMappingConstantCast.FUNCTION && (
        <InputWithLabel
          inputId={`gdsn-export-mappings-item-${name}-constants-data`}
          label="Function Name"
        >
          <div data-testid={`function-${name}`}>
            <SimpleSelect
              id={`gdsn-export-mappings-list-element-${name}-funct-property`}
              options={functionOptions}
              onSelect={updateFunction}
              value={functionOption}
              placeholder="Select..."
              disabled={!isEditable}
            />
          </div>
        </InputWithLabel>
      )}
      {cast && cast.id === ExportMappingConstantCast.LAMBDA && (
        <InputWithLabel
          inputId={`gdsn-export-mappings-item-${name}-constants-data`}
          label="Lambda self, hierarchy, su, constants, module"
        >
          <div data-testid={`lambda-${name}`}>
            <Text
              className="GDSNExportMappingsItemConstants__data"
              id={`gdsn-export-mappings-item-${name}-constant-data`}
              value={detailData}
              onChange={(e) => {
                setDetailValue(e.target.value);
              }}
              disabled={!isEditable}
            />
          </div>
        </InputWithLabel>
      )}
    </div>
  );
}
