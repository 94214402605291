import React from 'react';
import PropTypes from 'prop-types';

import { ModuleWrapper } from 'redux/register';

import { ORGANIZATION_PAGE_STORE_KEY } from './constants';
import reducer from './reducers';
import OrganizationPageDashboardView from './components';

export default function OrganizationListContainer({ match }) {
  return (
    <ModuleWrapper reducerKey={ORGANIZATION_PAGE_STORE_KEY} reducer={reducer}>
      <OrganizationPageDashboardView match={match} />
    </ModuleWrapper>
  );
}

OrganizationListContainer.propTypes = {
  match: PropTypes.object,
};
