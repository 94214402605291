import { Application } from '@alkem/sdk-dashboard';
import { APPLICATION_TYPE_UNKNOWN } from 'constants/application';
import { flow, set, unset } from 'lodash/fp';
import { createReducer } from 'utils/reducers';
import { ActionWithPayload } from 'utils/reducers/types';
import { sortAsc } from 'utils/sort';
import {
  FETCH_APPLICATIONS,
  RECEIVE_APPLICATIONS,
  SAVE_APPLICATIONS_DONE,
  SHOW_MORE_APPLICATIONS,
  UPDATE_APPLICATION,
} from '../constants/events';
import { OrganizationApplicationState } from '../types';

const initialState: OrganizationApplicationState = {
  changes: {},
  dict: {},
  hiddenList: [],
  isLoading: false,
  showMore: false,
  visibleList: [],
};

type S = OrganizationApplicationState;

export default createReducer<OrganizationApplicationState>(initialState, {
  [FETCH_APPLICATIONS]: set('isLoading', true),
  [RECEIVE_APPLICATIONS]: (
    state: OrganizationApplicationState,
    { payload: applications = [] }: ActionWithPayload<Application[]>
  ) => {
    const getType = (app: Application) =>
      app.type === APPLICATION_TYPE_UNKNOWN.key ? 99 : app.type;
    const sortedApps = applications
      .sort((a, b) => sortAsc(a.name, b.name))
      .sort((a, b) => sortAsc(getType(a), getType(b)));
    return flow<[S], S, S, S, S>(
      set('isLoading', false),
      set(
        'dict',
        sortedApps.reduce(
          (acc, app) =>
            Object.assign(acc, {
              [app.id]: app,
            }),
          {}
        )
      ),
      set(
        'visibleList',
        sortedApps.filter((app) => app.enabled).map((app) => app.id)
      ),
      set(
        'hiddenList',
        sortedApps.filter((app) => !app.enabled).map((app) => app.id)
      )
    )(state);
  },
  [UPDATE_APPLICATION]: (
    state,
    {
      payload,
    }: ActionWithPayload<{ applicationId: number; isEnabled: boolean }>
  ) => {
    if (payload && state.dict[payload.applicationId]) {
      const app = state.dict[payload.applicationId];
      const path = ['changes', payload.applicationId];
      let updateFunc;
      if (app.enabled === payload.isEnabled) {
        updateFunc = unset(path);
      } else {
        updateFunc = set(path, payload.isEnabled);
      }
      return updateFunc(state);
    }
    return state;
  },
  [SHOW_MORE_APPLICATIONS]: (state) => set('showMore', !state.showMore, state),
  [SAVE_APPLICATIONS_DONE]: flow<[S], S, S>(
    set('changes', initialState.changes),
    set('showMore', false)
  ),
});
