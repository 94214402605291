import { ExportMapping, ExportMappingPayloadConstant } from '../../../types';
import './item-constants.scss';
import { ExportMappingsItemConstantItem } from './constant';
import { SET_EXPORT_MAPPING } from '../../../actions/constants';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { notificationError } from '../../../../../../../actions/notifications';

interface Props {
  exportMapping: ExportMapping;
}
export function ExportMappingsItemConstants({ exportMapping }: Props) {
  const dispatch = useDispatch();
  const [isNew, setIsNew] = useState(false);

  const removeConstant = (name: string): void => {
    if (isNew) {
      setIsNew(false);
    }
    const { constants_for_later_use } = exportMapping.payload;
    if (constants_for_later_use !== undefined) {
      delete constants_for_later_use[name];
      exportMapping.payload.constants_for_later_use = constants_for_later_use;
      dispatch({
        type: SET_EXPORT_MAPPING,
        payload: {
          exportMapping: {
            ...exportMapping,
          },
        },
      });
    }
  };

  const addConstant = (name_const: string, cast: string, data: string) => {
    if (exportMapping.payload.constants_for_later_use === undefined) {
      exportMapping.payload.constants_for_later_use = {};
    }
    if (name_const in exportMapping.payload.constants_for_later_use) {
      dispatch(
        notificationError(
          'This constant already exists. You should remove the existing or create a new one'
        )
      );
    }
    exportMapping.payload.constants_for_later_use[name_const] = {
      cast: cast,
      data: data,
    };

    dispatch({
      type: SET_EXPORT_MAPPING,
      payload: {
        exportMapping: {
          ...exportMapping,
        },
      },
    });
    setIsNew(false);
  };

  const updateShowNewConstant = (): void => {
    setIsNew(true);
  };

  const { constants_for_later_use } = exportMapping.payload;
  const empty_details = {} as ExportMappingPayloadConstant;

  return (
    <div
      data-testid="GDSNExportMappingsItem_constants"
      className="GDSNExportMappingsItemConstants"
    >
      <div className="GDSNExportMappingsItemConstants__sectionTitle">
        Constants usable in childrens
      </div>
      <i
        className="mdi mdi-plus"
        data-testid="plus-constant"
        onClick={() => updateShowNewConstant()}
      />
      {isNew && (
        <ExportMappingsItemConstantItem
          removeConstant={removeConstant}
          name=""
          details={empty_details}
          isEditable={isNew}
          addConstant={addConstant}
        />
      )}
      {constants_for_later_use &&
        Object.entries(constants_for_later_use).map(([key, value]) => (
          <ExportMappingsItemConstantItem
            key={key}
            name={key}
            details={value}
            removeConstant={removeConstant}
            addConstant={addConstant}
          />
        ))}
    </div>
  );
}
