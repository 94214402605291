export const ORGANIZATION_CREATE_RESET = 'ORGANIZATION_CREATE_RESET';

export const ORGANIZATION_CREATE_MODAL_OPEN = 'ORGANIZATION_CREATE_MODAL_OPEN';
export const ORGANIZATION_CREATE_MODAL_CLOSE =
  'ORGANIZATION_CREATE_MODAL_CLOSE';
export const ORGANIZATION_CREATE_MODAL_LOADING =
  'ORGANIZATION_CREATE_MODAL_LOADING';

export const ORGANIZATION_CREATE_FORM_UPDATE_INPUT =
  'ORGANIZATION_CREATE_FORM_UPDATE_INPUT';
