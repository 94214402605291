import * as events from '../constants/events';

export const receiveGDSNRelationList = (list, totalResults) => ({
  type: events.RECEIVE_GDSN_RELATION_LIST,
  payload: {
    list,
    totalResults,
  },
});

export const getGDSNRelationList = () => ({
  type: events.GET_GDSN_RELATION_LIST,
});

export const deleteRelation = (id) => ({
  type: events.DELETE_GDSN_RELATION,
  id,
});

export const getGDSNRelationListFilters = () => ({
  type: events.GET_GDSN_RELATION_LIST_FILTERS,
});

export const changeLimit = (limit) => ({
  type: events.CHANGE_LIMIT_GDSN_RELATION_LIST,
  limit,
});

export const nextPage = () => ({
  type: events.NEXT_PAGE_GDSN_RELATION_LIST,
});

export const previousPage = () => ({
  type: events.PREVIOUS_PAGE_GDSN_RELATION_LIST,
});

export const addGDSNRelationListFilters = (key, value) => ({
  type: events.ADD_GDSN_RELATION_LIST_FILTER,
  key,
  value,
});

export const removeGDSNRelationListFilters = (key, value) => ({
  type: events.REMOVE_GDSN_RELATION_LIST_FILTER,
  key,
  value,
});

export const updateGDSNRelationListFilters = (key, value) => ({
  type: events.UPDATE_GDSN_RELATION_LIST_FILTER,
  key,
  value,
});

export const clearGDSNRelationListFilters = (key, value) => ({
  type: events.CLEAR_GDSN_RELATION_LIST_FILTER,
  key,
  value,
});

export const downloadGDSNDocument = (documentID) => ({
  type: events.DOWNLOAD_GDSN_RELATION_DOCUMENT,
  documentID,
});
