import { notificationError, notificationSuccess } from 'actions/notifications';
import {
  CHANGE_ORDER_EXPORT_MAPPING,
  GET_CUSTOM_PARSE_LIST,
  GET_EXPORT_MAPPING_LIST,
  GET_FIELD_LIST,
  GET_FUNCTION_NOT_VALUE_LIST,
  GET_FUNCTION_VALUE_LIST,
  GET_REFERENTIAL_CODE_LIST,
  GET_XPATH_LIST,
  RECEIVE_CUSTOM_PARSE_OPTIONS,
  RECEIVE_EXPORT_MAPPING_LIST,
  RECEIVE_FIELD_OPTIONS,
  RECEIVE_FUNCTION_NOT_VALUE_OPTIONS,
  RECEIVE_FUNCTION_VALUE_OPTIONS,
  RECEIVE_LIST_FIELD_OPTIONS,
  RECEIVE_REFERENTIAL_CODE_OPTIONS,
  RECEIVE_XPATH_LIST,
  SAVE_EXPORT_MAPPING,
  SET_EXPORT_MAPPING,
  SET_IS_SAVING_EXPORT_MAPPING_IN_PROGRESS,
} from '../actions/constants';
import { logError } from 'utils';
import { takeFirst, takeEverySafe, takeLatestSafe } from 'utils/saga';
import { ExportMapping, Options, Xpath, XpathList } from '../types';
import etlApi from 'resources/etlApi';
import { call, put } from 'redux-saga/effects';
import fieldsApi from 'resources/fieldsApi';
import { request } from 'utils/api';
import { getFieldOptions, getListFieldOptions } from '../utils';
import qs from 'querystringify';
import moment from 'moment';
import { DATETIME_FORMAT, UNLIMITED_DATE } from '../constants';
import referentialAdminApi from 'resources/referentialAdminApi';

export default function* mainSaga() {
  yield takeFirst(GET_XPATH_LIST, getXpathList);
  yield takeFirst(GET_CUSTOM_PARSE_LIST, getCustomParseList);
  yield takeFirst(GET_FUNCTION_VALUE_LIST, getFunctionValueList);
  yield takeFirst(GET_FUNCTION_NOT_VALUE_LIST, getFunctionNotForValueList);
  yield takeEverySafe(GET_REFERENTIAL_CODE_LIST, getReferentialList);
  yield takeLatestSafe(GET_FIELD_LIST, getFieldList);
  yield takeLatestSafe(GET_EXPORT_MAPPING_LIST, getExportMappingList);
  yield takeLatestSafe(SAVE_EXPORT_MAPPING, saveExportMapping);
  yield takeLatestSafe(CHANGE_ORDER_EXPORT_MAPPING, changeOrderExportMapping);
}

function* getCustomParseList() {
  try {
    const response = yield call(() =>
      etlApi.get(
        '/etl/v2/gdsndashboard/export/custom_parse_available',
        '',
        false
      )
    );
    const customParseOptions: Options = response.data.data.map(
      (customParse: string, index: number) => ({
        id: index,
        label: customParse,
      })
    );

    yield put({
      type: RECEIVE_CUSTOM_PARSE_OPTIONS,
      payload: {
        customParseOptions,
      },
    });
  } catch (error) {
    logError(error);
    yield put({
      type: RECEIVE_CUSTOM_PARSE_OPTIONS,
    });
    yield put(notificationError('Failed to get custom parse list'));
  }
}

function* getFunctionValueList() {
  try {
    const response = yield call(() =>
      etlApi.get(
        '/etl/v2/gdsndashboard/export/list_available_function_value',
        '',
        false
      )
    );
    const functionValueOptions: Options = response.data.data.map(
      (functionValue: string, index: number) => ({
        id: index,
        label: functionValue,
      })
    );

    yield put({
      type: RECEIVE_FUNCTION_VALUE_OPTIONS,
      payload: {
        functionValueOptions,
      },
    });
  } catch (error) {
    logError(error);
    yield put({
      type: RECEIVE_FUNCTION_VALUE_OPTIONS,
    });
    yield put(notificationError('Failed to get function value list'));
  }
}

function* getFunctionNotForValueList() {
  try {
    const response = yield call(() =>
      etlApi.get(
        '/etl/v2/gdsndashboard/export/list_available_function',
        '',
        false
      )
    );
    const functionNotValueOptions: Options = response.data.data.map(
      (funct: string, index: number) => ({
        id: index,
        label: funct,
      })
    );

    yield put({
      type: RECEIVE_FUNCTION_NOT_VALUE_OPTIONS,
      payload: {
        functionNotValueOptions,
      },
    });
  } catch (error) {
    logError(error);
    yield put({
      type: RECEIVE_FUNCTION_NOT_VALUE_OPTIONS,
    });
    yield put(notificationError('Failed to get function not value list'));
  }
}

function* getXpathList() {
  try {
    const response = yield call(() =>
      etlApi.get('/etl/v2/gdsndashboard/export/xpaths', '', false)
    );
    const xpathList: XpathList = response.data.data[0];

    yield put({
      type: RECEIVE_XPATH_LIST,
      payload: {
        xpathList,
      },
    });
  } catch (error) {
    logError(error);
    yield put({
      type: RECEIVE_XPATH_LIST,
    });
    yield put(notificationError('Failed to get xpath list'));
  }
}

function* getFieldList({
  entityType,
  search,
  mustGetListFields = false,
}: {
  entityType: string;
  search: string;
  mustGetListFields: boolean;
}) {
  const { result, error } = yield call(
    request,
    fieldsApi,
    'listMainFields',
    search,
    [
      'tags',
      'displayInfo',
      'children',
      'type',
      'declinable_by',
      'referential',
      'specific',
      'functional_key',
    ],
    entityType,
    { with_disabled: true, with_specific: true, limit: 100 }
  );

  if (error) {
    logError(error);
    yield put(notificationError('Failed to fetch fields'));
  } else {
    if (mustGetListFields) {
      const listFieldOptions = getListFieldOptions(result);
      yield put({
        type: RECEIVE_LIST_FIELD_OPTIONS,
        payload: {
          listFieldOptions,
        },
      });
    } else {
      const fieldOptions = getFieldOptions(result);
      yield put({
        type: RECEIVE_FIELD_OPTIONS,
        payload: {
          fieldOptions,
        },
      });
    }
  }
}

function* getExportMappingList({ xpath }: { xpath: Xpath }) {
  try {
    const response = yield call(() =>
      etlApi.get(
        '/etl/v2/gdsndashboard/export/mappings',
        qs.stringify(
          {
            id_xpath: xpath.id,
          },
          true
        ),
        false
      )
    );
    const exportMappings = response.data.data[0];

    yield put({
      type: RECEIVE_EXPORT_MAPPING_LIST,
      payload: {
        exportMappings,
      },
    });
  } catch (error) {
    logError(error);
    yield put({
      type: RECEIVE_EXPORT_MAPPING_LIST,
      payload: {
        exportMappings: [],
      },
    });
    yield put(
      notificationError(`Failed to get export mappings for xpath ${xpath.name}`)
    );
  }
}

function* saveExportMapping({
  exportMapping,
  xpath,
}: {
  exportMapping: ExportMapping;
  xpath: Xpath;
}) {
  try {
    yield put({
      type: SET_IS_SAVING_EXPORT_MAPPING_IN_PROGRESS,
      payload: { isSavingInProgress: true },
    });

    yield call(() =>
      etlApi.post('/etl/v2/gdsndashboard/export/mappings/post', exportMapping)
    );

    yield put({
      type: RECEIVE_EXPORT_MAPPING_LIST,
      payload: {
        exportMappings: [],
      },
    });
    yield put({
      type: SET_EXPORT_MAPPING,
      payload: {
        exportMapping: {
          date_start: moment().format(DATETIME_FORMAT),
          date_end: UNLIMITED_DATE,
          order: 0,
          xpath_id: xpath.id,
          payload: {},
        },
      },
    });
    yield getExportMappingList({ xpath });
    yield put({
      type: SET_IS_SAVING_EXPORT_MAPPING_IN_PROGRESS,
      payload: { isSavingInProgress: false },
    });
    yield put(notificationSuccess('Export mapping saved'));
  } catch (error) {
    yield put({
      type: SET_IS_SAVING_EXPORT_MAPPING_IN_PROGRESS,
      payload: { isSavingInProgress: false },
    });
    yield put(notificationError('Failed to save export mapping'));
    logError(error);
  }
}

function* getReferentialList({ referential }: { referential: string }) {
  try {
    const { result } = yield call(
      request,
      referentialAdminApi,
      'ReferentialGetList',
      referential,
      { lang: 'noop' }
    );

    const referentialCodeOptions = result?.map(
      (referentialCode: { id: number; code: string }) => ({
        id: referentialCode.id,
        label: referentialCode.code,
      })
    );

    yield put({
      type: RECEIVE_REFERENTIAL_CODE_OPTIONS,
      payload: {
        referential,
        referentialCodeOptions,
      },
    });
  } catch (error) {
    logError(error);
    yield put(
      notificationError(
        `Failed to get referential codes list for referential ${referential}`
      )
    );
  }
}

function* changeOrderExportMapping({
  exportMappingOrders,
  xpath,
}: {
  exportMappingOrders: [{ mapping_id: number; order: number }];
  xpath: Xpath;
}) {
  try {
    yield put({
      type: SET_IS_SAVING_EXPORT_MAPPING_IN_PROGRESS,
      payload: { isSavingInProgress: true },
    });

    yield call(() =>
      etlApi.post('/etl/v2/gdsndashboard/export/mappings/changeOrder', {
        mappings: exportMappingOrders,
      })
    );

    yield put({
      type: RECEIVE_EXPORT_MAPPING_LIST,
      payload: {
        exportMappings: [],
      },
    });
    yield put({
      type: SET_EXPORT_MAPPING,
      payload: {
        exportMapping: {
          date_start: moment().format(DATETIME_FORMAT),
          date_end: UNLIMITED_DATE,
          order: 0,
          xpath_id: xpath.id,
          payload: {},
        },
      },
    });
    yield getExportMappingList({ xpath });
    yield put({
      type: SET_IS_SAVING_EXPORT_MAPPING_IN_PROGRESS,
      payload: { isSavingInProgress: false },
    });
    yield put(notificationSuccess('Change order export mapping saved'));
  } catch (error) {
    yield put({
      type: SET_IS_SAVING_EXPORT_MAPPING_IN_PROGRESS,
      payload: { isSavingInProgress: false },
    });
    yield put(notificationError('Failed to change order export mapping'));
    logError(error);
  }
}
