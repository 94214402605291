import { Modal } from '@alkem/react-ui-modal';
import { useState } from 'react';

export function useConfirm({
  title = 'Confirm:',
  body,
  actionLabel,
  isProcessing,
}: {
  title?: string;
  body: React.ReactNode;
  actionLabel: string;
  isProcessing?: boolean;
}): [React.ReactNode, Function] {
  const [{ callback }, setCallback] = useState<{ callback: null | Function }>({
    callback: null,
  });
  const modal = callback ? (
    <Modal
      title={title}
      confirmButtonText={actionLabel}
      isProcessing={isProcessing}
      modalStyle="dynamic"
      onConfirm={() => {
        callback?.();
        setCallback({ callback: null });
      }}
      onClose={() => setCallback({ callback: null })}
      confirmButtonWarning
    >
      {body}
    </Modal>
  ) : null;
  return [modal, (callback: Function) => setCallback({ callback })];
}
