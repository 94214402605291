import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { HelpTooltip } from '@alkem/react-ui-helptooltip';

import { separateActions } from 'utils/redux';
import { getRecipients } from '../../actions/recipients';
import {
  selectEditedOrSourceRecipients,
  selectIsOrganizationRestricted,
  hasChange,
  selectOrganizationId,
} from '../../selectors/recipients';
import RecipientRelation from './relation';
import RecipientForm from './form';

import './index.scss';

const mapStateToProps = (state) => ({
  recipients: selectEditedOrSourceRecipients(state),
  hasRecipientsChange: hasChange(state),
  organizationId: selectOrganizationId(state),
  organizationRestricted: selectIsOrganizationRestricted(state),
});

const mapDispatchToProps = {
  getRecipients,
};

export class OrganizationPageRecipients extends PureComponent {
  static propTypes = {
    hasRecipientsChange: PropTypes.bool,
    organizationId: PropTypes.number,
    recipients: ImmutablePropTypes.list,
    organizationRestricted: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    actions: PropTypes.shape({
      getRecipients: PropTypes.func.isRequired,
    }).isRequired,
  };

  componentDidMount() {
    const { organizationId, organizationRestricted } = this.props;
    if (organizationId && organizationRestricted) {
      this.loadRelations(organizationId);
    }
  }

  componentDidUpdate(prevProps) {
    const { organizationId, organizationRestricted } = this.props;
    if (
      organizationId &&
      organizationRestricted &&
      (organizationId !== prevProps.organizationId ||
        organizationRestricted !== prevProps.organizationRestricted)
    ) {
      this.loadRelations(organizationId);
    }
  }

  loadRelations(organizationId) {
    this.props.actions.getRecipients(organizationId);
  }

  renderRelations() {
    const { recipients, isReadOnly } = this.props;
    if (!recipients) {
      return null;
    }
    return (
      <ul className="OrganizationPageRecipients_relations">
        {recipients.valueSeq().map((relation) => (
          <RecipientRelation
            key={relation.get('organization_id')}
            relation={relation}
            isReadOnly={isReadOnly}
          />
        ))}
      </ul>
    );
  }

  render() {
    const { hasRecipientsChange, organizationRestricted, isReadOnly } =
      this.props;
    if (!organizationRestricted) {
      return null;
    }
    return (
      <div className="OrganizationPageRecipients OrganizationPageBlock">
        <div className="OrganizationPageBlock__header">
          <h2>
            Recipients{' '}
            <HelpTooltip
              id="recipients-help"
              message="Allowed recipients (For restricted recipients only)"
            />
          </h2>
          <span className="OrganizationPageBlock__edited">
            {hasRecipientsChange && (
              <span>
                <i className="mdi mdi-alert" />
                Edited
              </span>
            )}
          </span>
        </div>
        <div className="OrganizationPageRecipients__container">
          {this.renderRelations()}
          {!isReadOnly && <RecipientForm />}
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(OrganizationPageRecipients);
