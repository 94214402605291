import { useState } from 'react';
import InputWithLabel from 'components/input-with-label';
import { Text } from '@alkem/react-ui-inputs';
import { HelpTooltip } from '@alkem/react-ui-helptooltip';

import { IMPORT_MAPPING_TYPES_WITH_OPTIONAL_PROPERTY_DISCARDABLE_REFERENTIAL_VALUES } from '../../../../constants';
import { ImportMappingDetails, Property } from '../../../../types';

interface Props {
  details: ImportMappingDetails;
  dispatchImportMapping: (properties: Property[]) => void;
  fieldName: string;
  type: string;
}
export function ImportMappingsItemOptionalPropertyDiscardableReferentialValues({
  details,
  dispatchImportMapping,
  fieldName,
  type,
}: Props): JSX.Element {
  const [discardableReferentialValues, setDiscardableableReferentialValues] =
    useState(details.discardable_referential_values?.join(',') || '');

  const updateDiscardableReferentialValue = (event: {
    target: { value: string };
  }) => {
    setDiscardableableReferentialValues(event.target.value);
    dispatchImportMapping([
      {
        name: 'discardable_referential_values',
        value:
          event.target.value === ''
            ? undefined
            : event.target.value.split(',').filter((value) => value !== ''),
      },
    ]);
  };

  return (
    <>
      {IMPORT_MAPPING_TYPES_WITH_OPTIONAL_PROPERTY_DISCARDABLE_REFERENTIAL_VALUES.includes(
        type
      ) &&
        (type === 'entity' || details.isDeclinable) && (
          <InputWithLabel
            inputId={`gdsn-import-mappings-field-${fieldName}-optional-property-discardable-referential-values`}
            label={
              <span>
                Discardable referential values{' '}
                <HelpTooltip
                  id={`gdsn-import-mappings-field-${fieldName}-optional-property-predicate-help`}
                  place="top"
                  message="List of codes separated by commas"
                />
              </span>
            }
          >
            <Text
              id={`gdsn-import-mappings-field-${fieldName}-optional-property-discardable-referential-values`}
              value={discardableReferentialValues}
              onChange={updateDiscardableReferentialValue}
            />
          </InputWithLabel>
        )}
    </>
  );
}
