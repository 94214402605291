import { Button } from '@alkem/react-ui-button';
import { Text, Textarea } from '@alkem/react-ui-inputs';
import { Tooltip } from '@alkem/react-ui-tooltip';
import { notificationSuccess } from 'actions/notifications';
import InputWithLabel from 'components/input-with-label';
import copy from 'copy-to-clipboard';
import { Rule } from 'modules/validation-dashboard/types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';
import './validation-conditions.scss';

interface Props {
  id: string;
  rule: Rule;
  initRule: Rule;
  disabled?: boolean;
  updateRule: (path: string[], value: any) => void;
}

export const copyEntryValue = (value: any | undefined, dispatch) => {
  if (value) {
    copy(typeof value === 'string' ? value.trim() : JSON.stringify(value));
    dispatch(notificationSuccess('Copied!'));
  }
};

const reduceToDict = (
  dictEntries: {
    key: string;
    value: string;
  }[]
) =>
  dictEntries.reduce(
    (dict, entry) => ({
      ...dict,
      [entry.key]: entry.value,
    }),
    {}
  );

const ConditionEditorItems = ({
  valuePlaceholder,
  dict = {},
  id,
  updateValue,
}: {
  valuePlaceholder?: string;
  id: string;
  dict: { [key: string]: any };
  updateValue: (value: any) => void;
}) => {
  const dispatch = useDispatch();
  // Entry used for new key-value in dict
  const [newEntry, setNewEntry] = useState({ key: '', value: '' });

  const parseValue = (value: string) => {
    try {
      return JSON.parse(value);
    } catch {
      return value;
    }
  };
  const parseKey = (key: string) => {
    return key.replaceAll(/[^a-zA-Z0-9]| /g, '');
  };

  // Inits the dict entries with dict
  const [dictEntries, setDictEntries] = useState<
    {
      key: string;
      value: string;
      id: string;
    }[]
  >([]);
  useEffect(
    () =>
      setDictEntries(
        Object.entries(dict).map(([key, value]) => ({
          key,
          value,
          id: uuid(),
        }))
      ),
    [dict]
  );

  const isNewEntryValid =
    newEntry.key && !dictEntries.some((entry) => entry.key === newEntry.key);

  //Button actions
  const addEntry = () => {
    if (isNewEntryValid) {
      updateValue({
        ...reduceToDict(dictEntries),
        [newEntry.key]: parseValue(newEntry.value),
      });
      setNewEntry({ key: '', value: '' });
    } else {
      updateValue(reduceToDict(dictEntries));
    }
  };
  const delEntry = (i: number) => {
    dictEntries.splice(i, 1);
    setDictEntries(dictEntries);
    updateValue({ ...reduceToDict(dictEntries) });
  };

  // Updates existing keys
  const setKey = (i: number, newKey: string) => {
    const newDictEntries = [...dictEntries];
    newDictEntries[i].key = newKey;
    setDictEntries(newDictEntries);
  };
  const setValue = (i: number, newValue: string) => {
    const newDictEntries = [...dictEntries];
    newDictEntries[i].value = newValue;
    setDictEntries(newDictEntries);
  };

  return (
    <div className="ConditionEditorItems alk-flex alk-flex-row">
      <div className="flex-grow--1">
        {dictEntries.map(({ key, value, id }, i) => (
          <div key={`entry-${id}`} className="ConditionEditorItem" id={id}>
            <div className="ConditionEditorItem__edit alk-flex alk-flex-row">
              <Text
                className="ConditionEditorItem__key"
                id={`cei-key-${id}`}
                onChange={(event) =>
                  setKey(i, parseKey((event.target as HTMLInputElement).value))
                }
                placeholder="Key (without the $)"
                value={key}
                invalid={!key}
                onBlur={() => addEntry()}
              />
              <div className="ConditionEditorItem__value">
                <Textarea
                  id={`cei-value-${id}`}
                  onChange={(event) =>
                    setValue(i, parseValue(event.target.value))
                  }
                  placeholder={valuePlaceholder || '$.example.JsonPath'}
                  value={
                    typeof value === 'string' ? value : JSON.stringify(value)
                  }
                  autoresize={true}
                  autoresizeDelta="2px"
                  rows={1}
                  invalid={!value}
                  onBlur={() => addEntry()}
                />
              </div>

              <div
                data-tip
                data-for={`delete-entry-${id}-button`}
                className="alk-flex"
              >
                <Button
                  onClick={() => delEntry(i)}
                  className="bg-white small-padding"
                >
                  <i className="mdi mdi-delete"></i>
                </Button>
                <Tooltip id={`delete-entry-${id}-button`} place="bottom">
                  Delete entry
                </Tooltip>
              </div>

              <div
                data-tip
                data-for={`copy-value-${id}-button`}
                className="alk-flex"
              >
                <Button
                  className="bg-white small-padding"
                  onClick={() => copyEntryValue(value, dispatch)}
                  disabled={!value}
                >
                  <i className="text-dark mdi mdi-content-copy"></i>
                </Button>
                <Tooltip id={`copy-value-${id}-button`} place="bottom">
                  {value ? 'Copy value' : 'Nothing to copy'}
                </Tooltip>
              </div>
            </div>
          </div>
        ))}

        <div key="new-entry" className="ConditionEditorItem" id={id}>
          <div className="ConditionEditorItem__edit alk-flex alk-flex-row">
            <Text
              className="ConditionEditorItem__key"
              id={`cei-key-new-${id}`}
              onChange={(event) => {
                setNewEntry({
                  ...newEntry,
                  key: parseKey((event.target as HTMLInputElement).value),
                });
              }}
              placeholder="Key (without the $)"
              value={newEntry.key}
              invalid={
                !newEntry.key || Object.keys(dict).includes(newEntry.key)
              }
            />
            <div className="ConditionEditorItem__value">
              <Textarea
                id={`cei-value-new-${id}`}
                onChange={(event) => {
                  setNewEntry({
                    ...newEntry,
                    value: parseValue(event.target.value),
                  });
                }}
                onBlur={() => addEntry()}
                placeholder={valuePlaceholder || '$.example.JsonPath'}
                value={
                  typeof newEntry.value === 'string'
                    ? newEntry.value
                    : JSON.stringify(newEntry.value)
                }
                autoresize={true}
                autoresizeDelta="2px"
                rows={1}
              />
            </div>

            <div
              data-tip
              data-for={`add-entry-button-${id}`}
              className="alk-flex"
            >
              <Button
                link
                className="small-padding"
                onClick={() => addEntry()}
                disabled={!isNewEntryValid}
              >
                <i className="mdi mdi-plus-circle"></i>
              </Button>
              <Tooltip id={`add-entry-button-${id}`} place="bottom">
                {isNewEntryValid
                  ? 'Add a new entry'
                  : 'Fill with a unique key first'}
              </Tooltip>
            </div>

            <div
              data-tip
              data-for={`copy-value-button-${id}`}
              className="alk-flex"
            >
              <Button
                className="bg-white small-padding"
                onClick={() => copyEntryValue(newEntry.value, dispatch)}
                disabled={!newEntry.value}
              >
                <i className="mdi mdi-content-copy"></i>
              </Button>
              <Tooltip id={`copy-value-button-${id}`} place="bottom">
                {newEntry.value ? 'Copy value' : 'Nothing to copy'}
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export const ConditionsEditor = ({
  rule,
  disabled = false,
  id,
  updateRule,
}: Props) => {
  const dispatch = useDispatch();
  return (
    <div className="ConditionsEditor" id={id}>
      <div className="ConditionEditorItemsList">
        <InputWithLabel inputId="constants-editor" label="Constants">
          <ConditionEditorItems
            valuePlaceholder="Constant value"
            id="constants-editor"
            dict={rule.constants || {}}
            updateValue={(value) => updateRule(['constants'], value)}
          />
        </InputWithLabel>
        <InputWithLabel inputId="selectors-editor" label="Selectors">
          <ConditionEditorItems
            id="selectors-editor"
            dict={rule.selectors || {}}
            updateValue={(value) => updateRule(['selectors'], value)}
          />
        </InputWithLabel>
        <InputWithLabel
          inputId="condition-selectors-editor"
          label="Condition Selectors"
        >
          <ConditionEditorItems
            id="condition-selectors-editor"
            dict={rule.conditionSelectors || {}}
            updateValue={(value) => updateRule(['conditionSelectors'], value)}
          />
        </InputWithLabel>
      </div>

      <hr />

      <InputWithLabel inputId="condition-editor" label="Condition">
        <div className="alk-flex alk-flex-row">
          <Text
            id="condition-editor"
            value={rule.condition || ''}
            onChange={(event) =>
              updateRule(
                ['condition'],
                (event.target as HTMLInputElement).value
              )
            }
            placeholder="Condition filtering products to apply the rule on. e.g. ANY[$a]"
          />
          <Button
            className="bg-white small-padding"
            onClick={() => copyEntryValue(rule.condition, dispatch)}
            disabled={!rule.condition}
          >
            <i className="text-dark mdi mdi-content-copy"></i>
          </Button>
        </div>
      </InputWithLabel>

      <InputWithLabel inputId="validation-editor" label="Validation">
        <div className="alk-flex alk-flex-row">
          <Text
            id="validation-editor"
            value={rule.validation || ''}
            onChange={(event) =>
              updateRule(
                ['validation'],
                (event.target as HTMLInputElement).value
              )
            }
            invalid={!rule.validation}
            placeholder="Valid when this assertion is met. e.g. ANY[$a == false]"
          />
          <Button
            className="bg-white small-padding"
            onClick={() => copyEntryValue(rule.validation, dispatch)}
            disabled={!rule.validation}
          >
            <i className="text-dark mdi mdi-content-copy"></i>
          </Button>
        </div>
      </InputWithLabel>

      <hr />
      <InputWithLabel inputId="error-message-editor" label="Error message">
        <div className="alk-flex alk-flex-row">
          <Textarea
            id={`error-message-editor`}
            value={rule.errorMessage || ''}
            placeholder="Error message that will be displayed on front"
            onChange={(event) =>
              updateRule(
                ['errorMessage'],
                (event.target as HTMLTextAreaElement).value
              )
            }
            disabled={disabled}
          />
          <Button
            className="bg-white small-padding"
            onClick={() => copyEntryValue(rule.errorMessage, dispatch)}
            disabled={!rule.errorMessage}
          >
            <i className="text-dark mdi mdi-content-copy"></i>
          </Button>
        </div>
      </InputWithLabel>
    </div>
  );
};
