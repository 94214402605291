import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Map } from 'immutable';
import ListController from 'components/ui/list/controller';
import { Button } from '@alkem/react-ui-button';
import { Spinner } from '@alkem/react-ui-spinner';
import { getImportList, nextPage, previousPage, changeLimit } from '../actions';
import {
  selectState,
  selectPagination,
  selectFiltersSelected,
} from '../selectors';
import ImportFileRow from './row';
import { hasFullPower } from 'utils/user';
import ImportModalSupplier from '../supplier/modal';

type Context = {
  list: any[];
  listDetail: Map<string, any>;
  listIsLoading: boolean;
};

type Filter = {
  workflow: string;
};

type Pagination = {
  currentPage;
  totalPages;
  limit;
  totalResults;
};

const ImportFileList = () => {
  const { list, listDetail, listIsLoading }: Context = useSelector(selectState);
  const { currentPage, totalPages, limit, totalResults }: Pagination =
    useSelector(selectPagination);
  const dispatch = useDispatch();
  const filters: Filter = useSelector(selectFiltersSelected);

  useEffect(() => {
    dispatch(getImportList());
  }, [dispatch]);

  const renderImportGenericList = () => {
    if (listIsLoading) {
      return (
        <div className="ImportFileList__spinner">
          <Spinner loading big />
        </div>
      );
    } else if (list.length === 0) {
      return (
        <div className="ImportFileList__spinner">
          <h3>
            Nothing imported matching your criterion...{' '}
            <i className="mdi mdi-heart-broken" />
          </h3>
        </div>
      );
    }
    return list.map((importFile) => (
      <ImportFileRow
        key={`import-file-${importFile.id}`}
        importfile={importFile}
        importdetail={listDetail.get(importFile.id)}
      />
    ));
  };

  const ActionsBar = React.memo(() => {
    const [isImportSupplierModalOpen, setImportSupplierModalOpen] =
      useState(false);
    const user: Map<string, any> = useSelector(({ user }: any) => user);

    const openUploadSupplierModal = () => setImportSupplierModalOpen(true);
    const closeUploadSupplierModal = () => setImportSupplierModalOpen(false);

    return (
      <div className="ListController__Actions">
        <div className="ListController__Actions__col">
          <Button onClick={() => dispatch(getImportList())}>
            <i className="mdi mdi-refresh" />
          </Button>
          {hasFullPower(user) &&
            filters.workflow.includes('supplier-directory') && (
              <span>
                <span className="ImportFileList__sep" />
                <Button onClick={openUploadSupplierModal}>
                  <span>
                    <i className="mdi mdi-cloud-upload" />
                    Suppliers
                  </span>
                </Button>
              </span>
            )}
        </div>
        {isImportSupplierModalOpen && (
          <ImportModalSupplier onCloseModal={closeUploadSupplierModal} />
        )}
      </div>
    );
  });

  return (
    <div>
      <ListController
        actions={<ActionsBar />}
        rowsLength={0}
        currentPage={currentPage}
        totalPages={totalPages}
        totalResults={totalResults}
        limit={limit}
        onLimitChange={(limit) => dispatch(changeLimit(limit))}
        onNext={() => dispatch(nextPage())}
        onPrev={() => dispatch(previousPage())}
        type="importfilegeneric"
      >
        {renderImportGenericList()}
      </ListController>
    </div>
  );
};

export default ImportFileList;
export { ImportFileList };
