export const PERMISSION_ENTITY_ORGANIZATION_SEGMENTS = 'organizationSegments';
export const PERMISSION_ENTITY_ORGANIZATIONS = 'organizations';
export const PERMISSION_ENTITY_PLATFORMS = 'platforms';

// OrganizationSegment
export const PERMISSION_DELEGATE = 'organizationsegment.permission.delegate';

// Organization
export const PERMISSION_ORGANIZATION_SHOW = 'organization.show';
export const PERMISSION_ORGANIZATION_UPDATE = 'organization.update';
export const PERMISSION_ORGANIZATION_CREATE = 'organization.create';
export const PERMISSION_USER_SHOW = 'organization.user.show';
export const PERMISSION_USER_UPDATE = 'organization.user.update';
export const PERMISSION_LOG_AS_USER_SHOW = 'organization.user.logas.show';
export const PERMISSION_LOG_AS_USER_UPDATE = 'organization.user.logas.update';
export const PERMISSION_MANAGE_GLN = 'organization.claims.gln.manage';
export const PERMISSION_PRODUCT_UNSHARE = 'organization.product.unshare';
export const PERMISSION_PRODUCT_EXPORT = 'organization.product.export';
export const PERMISSION_MANUFACTURER_IMPORT = 'manufacturer.product.import';
export const PERMISSION_RETAILER_IMPORT = 'retailer.product.import';
export const PERMISSION_ORGANIZATION_FIELD_GROUP_SHOW =
  'organization.fieldgroup.show';
export const PERMISSION_ORGANIZATION_FIELD_GROUP_UPDATE =
  'organization.fieldgroup.update';

// Platform
export const PERMISSION_PLATFORM_FIELD_SHOW = 'field.show';
export const PERMISSION_PLATFORM_FIELD_UPDATE = 'field.update';
export const PERMISSION_PLATFORM_KIND_SHOW = 'kind.show';
export const PERMISSION_PLATFORM_KIND_UPDATE = 'kind.update';
export const PERMISSION_PLATFORM_REFERENTIAL_SHOW = 'referential.show';
export const PERMISSION_PLATFORM_REFERENTIAL_UPDATE = 'referential.update';
export const PERMISSION_PLATFORM_VALIDATION_SHOW = 'validation.show';
export const PERMISSION_PLATFORM_VALIDATION_UPDATE = 'validation.update';
export const PERMISSION_PLATFORM_APPLICATION_SHOW = 'application.show';
export const PERMISSION_PLATFORM_APPLICATION_UPDATE = 'application.update';
export const PERMISSION_PLATFORM_RABBITMQ_SHOW = 'rabbitmq.show';
export const PERMISSION_PLATFORM_RABBITMQ_UPDATE = 'rabbitmq.update';
export const PERMISSION_PLATFORM_TRANSLATION_SHOW = 'translation.show';
export const PERMISSION_PLATFORM_VIEWAS_SHOW = 'viewas.show';
export const PERMISSION_PLATFORM_VIEWAS_UPDATE = 'viewas.update';
export const PERMISSION_PLATFORM_SETTING_SHOW = 'setting.show';
export const PERMISSION_PLATFORM_SETTING_UPDATE = 'setting.update';
export const PERMISSION_PLATFORM_ORGANIZATIONGROUP_SHOW =
  'organizationgroup.show';
export const PERMISSION_PLATFORM_ORGANIZATIONGROUP_UPDATE =
  'organizationgroup.update';
export const PERMISSION_PLATFORM_TRANSACTION_SHOW = 'transaction.show';
export const PERMISSION_PLATFORM_TRANSACTION_UPDATE = 'transaction.update';
export const PERMISSION_PLATFORM_GDSN_RCI_SHOW = 'gdsn.rci.show';
export const PERMISSION_PLATFORM_EXCHANGE_SFTP_MANAGE = 'exchange.sftp.manage';
export const PERMISSION_PLATFORM_NETWORK_CREATE = 'network.create';
export const PERMISSION_PLATFORM_DOCUMENTATION_VIEW_ALL =
  'documentation.view.all';
export const PERMISSION_PLATFORM_ETL_EXPORT_LIST = 'etl.export.list';
export const PERMISSION_PLATFORM_ETL_GENERIC_IMPORT_LIST =
  'etl.generic.import.list';
export const PERMISSION_PLATFORM_INDEXING_SHOW = 'organization.indexing.show';
export const PERMISSION_PLATFORM_INDEXING_REINDEX =
  'organization.indexing.reindex';
