import { startCase, toLower } from 'lodash/fp';

export const STATUS_ACTIVE = 1;
export const STATUS_DECOMMISSIONED = 3;
export const STATUS_INACTIVE = 0;

export const ORGANIZATION_TYPE_MANUFACTURER = { id: 1, name: 'MANUFACTURER' };
export const ORGANIZATION_TYPE_RETAILER = { id: 2, name: 'RETAILER' };
export const ORGANIZATION_TYPE_PRIVATE_LABEL = { id: 3, name: 'PRIVATE LABEL' };
export const ORGANIZATION_TYPE_THIRD_PARTY = { id: 4, name: 'THIRD PARTY' };

export const ORGANIZATION_TYPE_MAPPING = {
  [ORGANIZATION_TYPE_MANUFACTURER.id]: ORGANIZATION_TYPE_MANUFACTURER,
  [ORGANIZATION_TYPE_RETAILER.id]: ORGANIZATION_TYPE_RETAILER,
  [ORGANIZATION_TYPE_PRIVATE_LABEL.id]: ORGANIZATION_TYPE_PRIVATE_LABEL,
  [ORGANIZATION_TYPE_THIRD_PARTY.id]: ORGANIZATION_TYPE_THIRD_PARTY,
};

export const ORGANIZATION_TYPE_ANY = {
  id: undefined,
  label: 'Any',
};

export const ORGANIZATION_TYPES = [
  ORGANIZATION_TYPE_ANY,
  ...Object.values(ORGANIZATION_TYPE_MAPPING).map((orgType) => ({
    id: orgType.id,
    label: startCase(toLower(orgType.name)),
  })),
];

export const SOURCE_ANY = undefined;
export const SOURCE_ALKEMICS = 0;
export const SOURCE_PRODUCTDNA = 1;

export const SOURCES = [
  { id: SOURCE_ALKEMICS, label: 'Alkemics' },
  { id: SOURCE_PRODUCTDNA, label: 'productDNA' },
];

export const SOURCE_OPTIONS = [
  { id: SOURCE_ANY, label: 'Any' },
  { id: SOURCE_ALKEMICS, label: 'Alkemics' },
  { id: SOURCE_PRODUCTDNA, label: 'productDNA' },
];
