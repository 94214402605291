import { List, Set, fromJS } from 'immutable';
import { call, put, select, takeEverySafe } from 'utils/saga';
import * as api from './api';
import * as events from './events';
import {
  selectLinks,
  selectLoadedTraces,
  selectTransactionId,
  selectTransactions,
} from './selectors';

const flattenTransactions = (t) =>
  List([t]).concat(
    t
      .get('children')
      .map((c) => flattenTransactions(c))
      .flatten(1)
  );

export default function* mainSaga() {
  yield takeEverySafe([events.LOAD_TRANSACTION_ID], loadFilters);
  yield takeEverySafe([events.LOAD_TRACE], loadTrace);
  yield takeEverySafe([events.LINKS_LOADED], loadTransactions);
}

function* loadFilters() {
  const transactionId = yield select(selectTransactionId);
  yield call(loadTransaction, { transactionId });
  yield call(loadTrace, { transactionId });
}

function* loadTrace({ transactionId }: { transactionId: number }) {
  const { response } = yield call(api.getTrace, transactionId);
  const traces = response.data.data || List();
  yield put({
    type: events.LINKS_LOADED,
    links: traces.map((trace) => trace.set('type', 'trace')),
  });
}

function* loadTransaction({ transactionId }: { transactionId: number }) {
  const transactions = yield select(selectTransactions);
  if (transactions.has(transactionId)) {
    return;
  }
  yield call(_loadTransactions, [transactionId]);
}

function* loadTransactions() {
  const links = yield select(selectLinks);
  const transactions = yield select(selectTransactions);

  const transactionIds = links
    .reduce(
      (s, link) => s.add(link.get('transaction_id')).add(link.get('parent_id')),
      Set()
    )
    .filter((id) => !transactions.has(id))
    .toArray();

  yield call(_loadTransactions, transactionIds);
}

function* _loadTransactions(transactionIds) {
  if (!transactionIds.length) {
    return;
  }

  const { response } = yield call(api.getTransactions, transactionIds);
  let newTransactions = response.data.data;

  newTransactions = newTransactions.map(flattenTransactions).flatten(1);

  yield put({
    type: events.TRANSACTIONS_LOADED,
    transactions: newTransactions,
  });

  const childrenLinks = newTransactions
    .map((transaction) =>
      transaction.get('children').map((child) =>
        fromJS({
          transaction_id: child.get('id'),
          parent_id: transaction.get('id'),
          type: 'child',
        })
      )
    )
    .flatten(1);

  const parentLinks = newTransactions
    .filter((transaction) => transaction.get('parent_id'))
    .map((transaction) =>
      fromJS({
        transaction_id: transaction.get('id'),
        parent_id: transaction.get('parent_id'),
        type: 'child',
      })
    );

  const links = yield select(selectLinks);

  yield put({
    type: events.LINKS_LOADED,
    links: childrenLinks.concat(links).concat(parentLinks).toSet().toList(),
  });

  const loadedTraces = yield select(selectLoadedTraces);
  const tracesToLoad = childrenLinks
    .map((l) => List([l.get('transaction_id'), l.get('parent_id')]))
    .flatten(1)
    .filter((trxId) => !loadedTraces.get(trxId));

  for (let i = 0; i < tracesToLoad.size; i += 1) {
    yield put({
      type: events.LOAD_TRACE,
      transactionId: tracesToLoad.get(i),
    });
  }
}
