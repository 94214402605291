import { immutableAuthApi } from 'resources/authApi';
import * as events from '../constants';
import {
  selectLimit,
  selectCurrentPage,
  selectTypeFilters,
  selectStatusFilters,
  selectNetworkFilters,
  selectGroupFilters,
  selectTargetMarketFilters,
  selectSearch,
} from '../selectors';

const {
  ORGANIZATIONS_RECEIVE,
  ORGANIZATIONS_NEXT_PAGE,
  ORGANIZATIONS_PREVIOUS_PAGE,
  ORGANIZATIONS_SEARCH,
  ORGANIZATIONS_CHANGE_LIMIT,
  ORGANIZATIONS_FILTERS_ADD_SINGLE,
  ORGANIZATIONS_FILTERS_REMOVE_SINGLE,
  ORGANIZATIONS_FILTERS_CLEAR,
  ORGANIZATIONS_ARE_LOADING,
} = events;

export const computeOffset = (state) => {
  const limit = selectLimit(state);
  const currentPage = selectCurrentPage(state);
  return limit * (currentPage - 1);
};

const constructFilters = (state) => {
  const networks = Object.keys(selectNetworkFilters(state).toJS());
  const groups = Object.keys(selectGroupFilters(state).toJS());
  const targetMarketIds = Object.keys(selectTargetMarketFilters(state).toJS());
  const types = Object.keys(selectTypeFilters(state).toJS());
  const statuses = Object.keys(selectStatusFilters(state).toJS());
  const search = selectSearch(state);

  const res = {};
  if (types.length) {
    res.types = types;
  }
  if (networks.length) {
    res.networks = networks;
  }
  if (targetMarketIds.length) {
    res.targetMarketIds = targetMarketIds;
  }
  if (groups.length) {
    res.groups = groups;
  }
  if (statuses.length) {
    res.statuses = statuses;
  }

  if (search && search.length) {
    search
      .split(',')
      .map((w) => w.trim())
      .forEach((w) => {
        if (w && w.length) {
          if (Number.isNaN(Number(w))) {
            // not a number: namelegal : we only store the last one
            res.nameLegal = w;
          } else {
            // number: filter ids
            res.ids = res.ids ? res.ids : [];
            res.ids.push(w);
          }
        }
      });
  }

  return res;
};

export const receiveOrganizations = (list, totalResults) => ({
  type: ORGANIZATIONS_RECEIVE,
  list,
  totalResults,
});
export const organizationsAreLoading = (payload) => ({
  type: ORGANIZATIONS_ARE_LOADING,
  payload,
});

let getOrganizationsPromise = null;
export const getOrganizations = () => (dispatch, getState) => {
  const state = getState();
  const sort = {};

  const filters = constructFilters(state);
  const withs = {
    type: true,
    network: true,
    status: true,
    settings: true,
    stats: true,
  };

  const limit = selectLimit(state);
  const offset = computeOffset(state);
  if (getOrganizationsPromise === null) {
    dispatch(organizationsAreLoading(true));
    getOrganizationsPromise = immutableAuthApi
      .OrganizationListV3(filters, withs, sort, offset, limit, {
        useCache: false,
      })
      .then(
        (data) => {
          dispatch(organizationsAreLoading(false));
          dispatch(
            receiveOrganizations(data.data.data, data.data.totalResults)
          );
          getOrganizationsPromise = null;
        },
        () => {
          dispatch(organizationsAreLoading(false));
          getOrganizationsPromise = null;
        }
      );
  }
  return getOrganizationsPromise;
};

export const nextOrganizations = () => ({ type: ORGANIZATIONS_NEXT_PAGE });

export const previousOrganizations = () => ({
  type: ORGANIZATIONS_PREVIOUS_PAGE,
});

export const searchOrganizations = (search) => ({
  type: ORGANIZATIONS_SEARCH,
  search,
});

export const changeOrganizationsLimit = (limit) => ({
  type: ORGANIZATIONS_CHANGE_LIMIT,
  limit,
});

export const addFilter = (filterType, filterId, filterValue, filterLabel) => ({
  type: ORGANIZATIONS_FILTERS_ADD_SINGLE,
  filterType,
  filterId,
  filterValue,
  filterLabel,
});

export const removeFilter = (filterType, filterId) => ({
  type: ORGANIZATIONS_FILTERS_REMOVE_SINGLE,
  filterType,
  filterId,
});

export const clearFilters = () => ({ type: ORGANIZATIONS_FILTERS_CLEAR });
