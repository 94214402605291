import { createReducer } from 'utils/reducers';

import {
  initPagination,
  receiveReducer,
  changeLimit,
  nextPage,
  previousPage,
  resetPagination,
} from 'utils/reducers/list';
import { Map, fromJS } from 'immutable';

import history from 'utils/history';
import qs from 'querystringify';

import * as events from '../constants/events';

const LIMIT_STORAGE_KEY = 'importGenericListLimit';
const SORTING_STORAGE_KEY = 'importGenericListSorting';

function getQSorValue(key, cast, defaultValue) {
  const value = qs.parse(history.location.search)[key];
  return value === undefined ? defaultValue : cast(value);
}

const initialState = {
  list: [],
  listDetail: Map(),
  listIsLoading: true,
  filters: {},
  filtersSelected: {
    workflow: null,
    organization_id: null,
    status: null,
    importer: null,
    date_from: null,
    date_to: null,
    import_ids: null,
    identifier: null,
  },
  filtersIsLoading: true,
  pagination: initPagination(LIMIT_STORAGE_KEY, SORTING_STORAGE_KEY),
};

export default createReducer(initialState, {
  [events.LOADING_IMPORT_LIST]: (state) => {
    const newState = { ...state };
    newState.listIsLoading = true;
    return newState;
  },
  [events.RECEIVE_IMPORT_DETAIL]: (state, action) => {
    const newState = { ...state };
    newState.listDetail = newState.listDetail.set(
      action.importFileId,
      action.entities
    );
    return newState;
  },
  [events.RECEIVE_IMPORT_LIST]: (state, action) => {
    const newState = { ...state };
    newState.listIsLoading = false;
    return receiveReducer(newState, action.payload);
  },
  [events.CHANGE_LIMIT_IMPORT_LIST]: (state, action) =>
    changeLimit(state, action, LIMIT_STORAGE_KEY),
  [events.NEXT_PAGE_IMPORT_LIST]: nextPage,
  [events.PREVIOUS_PAGE_IMPORT_LIST]: previousPage,
  [events.RECEIVE_FILTERS]: (state, action) => {
    const newState = { ...state };
    newState.filters = fromJS(action.filters);
    newState.filtersIsLoading = false;

    newState.filtersSelected = {
      workflow: getQSorValue('workflow', String, null),
      organization_id: getQSorValue('organization_id', parseInt, null),
      status: getQSorValue('status', String, null),
      importer: getQSorValue('importer', String, null),
      date_from: getQSorValue('date_from', String, null),
      date_to: getQSorValue('date_to', String, null),
      identifier: getQSorValue('identifier', String, null),
      import_ids: getQSorValue('import_ids', parseInt, null),
    };
    return newState;
  },
  [events.UPDATE_SELECTED_FILTERS]: (state, action) => {
    const newState = { ...state };
    if (action.key === 'import_ids') {
      newState.filtersSelected = {
        workflow: state.filtersSelected.workflow,
        import_ids: action.value,
      };
    } else {
      newState.filtersSelected.import_ids = null;
      newState.filtersSelected[action.key] = action.value;
    }
    return resetPagination(newState);
  },
});
