import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { fromJS } from 'immutable';

import { Checkbox } from '@alkem/react-ui-inputs';

import allPackagingTypes from 'constants/typePackagings';

import './packaging-types.scss';

export default class FieldPackagingTypes extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    packagingTypes: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
  };

  onUpdate = (id) => () => {
    const { packagingTypes, onChange } = this.props;
    const foundIndex = packagingTypes.findIndex(
      (pt) => pt.getIn(['typePackaging', 'id']) === id
    );
    if (foundIndex !== -1) {
      return onChange(packagingTypes.delete(foundIndex));
    }
    return onChange(
      packagingTypes.push(
        fromJS({
          typePackaging: { id },
        })
      )
    );
  };

  renderContent() {
    const { id, packagingTypes, readOnly } = this.props;
    return Object.values(allPackagingTypes.all).map((pt) => (
      <Checkbox
        key={pt.id}
        id={`${id}-${pt.id}`}
        checked={
          !!packagingTypes.find(
            (t) => t.getIn(['typePackaging', 'id']) === pt.id
          )
        }
        onChange={this.onUpdate(pt.id)}
        label={pt.name}
        disabled={readOnly}
      />
    ));
  }

  render() {
    return (
      <div className="FieldPackagingTypes" id={this.props.id}>
        {this.renderContent()}
      </div>
    );
  }
}
