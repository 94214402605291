import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { List } from 'immutable';

import { SimpleSelect } from '@alkem/react-ui-select';

import { separateActions } from 'utils/redux';
import { READONLY_FIELDS } from 'constants/organization-settings';
import { CONSUMER_UNIT } from 'constants/fields';
import fieldsApiImmutable from 'resources/fieldsApi';
import { FieldAutocomplete } from 'components/autocomplete/custom/field-autocomplete';
import { updateSettingValue } from 'modules/organization-page/actions/settings';
import {
  selectEditedOrSourceSetting,
  selectFieldMetaDataRoots,
} from 'modules/organization-page/selectors/settings';
import FieldsReadOnlySettingEditModalFieldIdsLine from './line';

const mapStateToProps = (state) => ({
  readOnlyFieldSetting: selectEditedOrSourceSetting(state, [READONLY_FIELDS]),
  fieldMetaDataRoots: selectFieldMetaDataRoots(state),
});

const mapDispatchToProps = {
  updateSettingValue,
};

export class FieldsReadOnlySettingEditModalFieldIds extends PureComponent {
  static propTypes = {
    settingAttr: PropTypes.string.isRequired,
    readOnlyFieldSetting: ImmutablePropTypes.map.isRequired,
    fieldMetaDataRoots: ImmutablePropTypes.list.isRequired,
    withSpecific: PropTypes.bool,
    actions: PropTypes.shape({
      updateSettingValue: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    withSpecific: false,
  };

  state = {
    newFieldType: { label: CONSUMER_UNIT },
    newField: null,
    fields: List(),
  };

  componentDidMount = () => {
    const { readOnlyFieldSetting, settingAttr } = this.props;
    const fieldIds = readOnlyFieldSetting.get(settingAttr) || List();
    if (fieldIds.isEmpty()) {
      return;
    }
    fieldsApiImmutable
      .FieldMetaDataList({ fieldIds: fieldIds.toJS() })
      .then((response) => {
        this.setState({
          fields: List(
            response.data.data.map((field) => ({
              id: field.id,
              name: field.name,
            }))
          ),
        });
      });
  };

  add = () => {
    const { fields, newField } = this.state;
    const newFields = fields.push({
      id: newField.key,
      name: newField.value.get('name'),
    });
    this.setState({
      fields: newFields,
      newFieldType: { label: CONSUMER_UNIT },
      newField: null,
    });
    this.applyFields(newFields);
  };

  delete = (fieldId) => {
    const { fields } = this.state;
    const newFields = fields.filter((field) => field.id !== fieldId);
    this.setState({ fields: newFields });
    this.applyFields(newFields);
  };

  onSelectFieldMetadataType = (fieldType) => {
    this.setState({ newFieldType: fieldType });
  };

  onSelectField = (field) => {
    this.setState({ newField: field });
  };

  applyFields(newFields) {
    const { actions, readOnlyFieldSetting, settingAttr } = this.props;

    actions.updateSettingValue(
      [READONLY_FIELDS],
      readOnlyFieldSetting.set(
        settingAttr,
        List(newFields.map((field) => field.id))
      )
    );
  }

  render() {
    const { newFieldType, newField, fields } = this.state;
    const { fieldMetaDataRoots, withSpecific } = this.props;
    return (
      <div>
        <div>
          <h3>Fields</h3>
        </div>
        {fields.size ? (
          <ul className="FieldsReadOnlySettingEditModal__lines">
            {fields.map((field) => (
              <FieldsReadOnlySettingEditModalFieldIdsLine
                key={field.id}
                field={field}
                onDelete={this.delete}
              />
            ))}
          </ul>
        ) : (
          <div className="FieldsReadOnlySettingEditModal__empty">None</div>
        )}
        <div className="FieldsReadOnlySettingEditModal__line">
          <SimpleSelect
            id="field-roots"
            options={fieldMetaDataRoots.toJS()}
            value={newFieldType}
            onSelect={this.onSelectFieldMetadataType}
            autoSize
          />
          <FieldAutocomplete
            id="field-autocomplete"
            value={newField ? [{ label: newField.value.get('name') }] : []}
            onSelect={this.onSelectField}
            entityType={newFieldType.label}
            searchOnClick
            excludeList={fields.toJS()}
            withSpecific={withSpecific}
          />{' '}
          <span
            className="FieldsReadOnlySettingEditModal__addButton mdi mdi-check"
            onClick={this.add}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(FieldsReadOnlySettingEditModalFieldIds);
