import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { difference } from 'lodash';
import { Modal } from '@alkem/react-ui-modal';

const mapStateToProps = (state) => ({ referentials: state.referentials.lists });

class SaveModal extends Component {
  static propTypes = {
    fieldUpdates: PropTypes.object,
    referentialValues: PropTypes.object,
    selectedKind: PropTypes.object,
    kindVisibilityChangeRequest: PropTypes.bool,
    onCloseModal: PropTypes.func,
    onSaveClick: PropTypes.func,
    referentials: PropTypes.object,
  };

  diffRefValues(refValues) {
    if (!refValues) {
      return {};
    }
    const diff = {};
    Object.keys(refValues).forEach((referential) => {
      const initials = refValues[referential].initial || [];
      const updates = refValues[referential].updated || [];

      let newlyExcluded = [];
      let noLongerExcluded = [];
      if (this.isFromEnabledField(referential)) {
        noLongerExcluded = this._mapEntitiesIdsToOptions(
          referential,
          difference(initials, updates)
        );
        newlyExcluded = this._mapEntitiesIdsToOptions(
          referential,
          difference(updates, initials)
        );
      }

      if (newlyExcluded.length || noLongerExcluded.length) {
        diff[referential] = { newlyExcluded, noLongerExcluded };
      }
    });
    return diff;
  }

  isFromEnabledField(referentialName) {
    return referentialName in this.props.referentials;
  }

  _mapEntitiesIdsToOptions(referentialName, entitiesIds) {
    const { referentials } = this.props;
    const referential = referentials[referentialName];
    return referential.filter((entity) => entitiesIds.includes(entity.id));
  }

  render() {
    const {
      fieldUpdates,
      referentialValues,
      onCloseModal,
      onSaveClick,
      selectedKind,
      kindVisibilityChangeRequest,
    } = this.props;

    const referentialValuesDiff = this.diffRefValues(referentialValues);

    const body = (
      <div>
        {Object.keys(fieldUpdates).length ? (
          <div>
            Fields added:
            <ul>
              {Object.entries(fieldUpdates).map(([key, value]) =>
                value.value ? (
                  <li key={`fieldAdded_${key}`}>{value.label}</li>
                ) : null
              )}
            </ul>
            Fields removed:
            <ul>
              {Object.entries(fieldUpdates).map(([key, value]) =>
                !value.value ? (
                  <li key={`fieldRemoved_${key}`}>{value.label}</li>
                ) : null
              )}
            </ul>
          </div>
        ) : null}
        {Object.keys(referentialValuesDiff).length ? (
          <div>
            Referential values:
            <ul>
              {Object.entries(referentialValuesDiff).map(([key, value]) => (
                <li key={`refValue_${key}`}>
                  <div>
                    <span>{key}</span>
                    <ul>
                      {value.newlyExcluded.length ? (
                        <li>
                          Newly excluded:{' '}
                          {value.newlyExcluded.map((v) => v.label).join(', ')}
                        </li>
                      ) : null}
                      {value.noLongerExcluded.length ? (
                        <li>
                          No longer excluded:{' '}
                          {value.noLongerExcluded
                            .map((v) => (v.label ? v.label : v.id))
                            .join(', ')}
                        </li>
                      ) : null}
                    </ul>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ) : null}
      </div>
    );

    let visibilityChangeWarning = null;
    if (kindVisibilityChangeRequest) {
      if (selectedKind.visible === false) {
        visibilityChangeWarning = (
          <div>
            <div>
              <strong>
                You are about to make kind “{selectedKind.label}” public!
              </strong>
            </div>
            <div>
              It will be visible to <em>all</em> users.
            </div>
            <div>
              Are you sure the kind is ready to be released and wish to
              continue?
            </div>
          </div>
        );
      } else {
        visibilityChangeWarning = (
          <div>
            <div>
              <strong>
                You are about to hide kind "{selectedKind.label}
                "!
              </strong>
            </div>
            <div>It won't be visible to users any more.</div>
            <div>Are you sure you want to proceed?</div>
          </div>
        );
      }
    }

    return (
      <Modal
        title="Save Kind configuration"
        confirmButtonText="Save"
        onConfirm={onSaveClick}
        onClose={onCloseModal}
      >
        {visibilityChangeWarning || body}
      </Modal>
    );
  }
}

export default connect(mapStateToProps)(SaveModal);
