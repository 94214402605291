import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { HelpTooltip } from '@alkem/react-ui-helptooltip';

import { get, set } from 'utils/immutable';
import { separateActions } from 'utils/redux';
import { SUPPLIER_DIRECTORY } from 'constants/organization-settings';

import LabeledCheckbox from './controllers/checkbox';
import ListMultipleController from './controllers/listmultiple';
import {
  updateSettingValue,
  retrieveSuppliersReconciliationKeysReferential,
} from '../../actions/settings';
import {
  selectEditedOrSourceSetting,
  selectSuppliersReconciliationKeysReferential,
} from '../../selectors/settings';

import './supplier-directory.scss';

const mapStateToProps = (state) => ({
  supplierDirectorySetting: selectEditedOrSourceSetting(state, [
    SUPPLIER_DIRECTORY,
  ]),
  referential: selectSuppliersReconciliationKeysReferential(state),
});

const mapDispatchToProps = {
  updateSettingValue,
  retrieveSuppliersReconciliationKeysReferential,
};

export class OrganizationPageSettingsSupplierDirectory extends PureComponent {
  static propTypes = {
    isReadOnly: PropTypes.bool,
    supplierDirectorySetting: ImmutablePropTypes.map,
    referential: ImmutablePropTypes.list.isRequired,
    actions: PropTypes.shape({
      updateSettingValue: PropTypes.func.isRequired,
      retrieveSuppliersReconciliationKeysReferential: PropTypes.func.isRequired,
    }).isRequired,
  };

  componentDidMount() {
    this.props.actions.retrieveSuppliersReconciliationKeysReferential();
  }

  getSubLabel(option) {
    return option.get('code');
  }

  onEnabledChanged = (enabled) => {
    const { supplierDirectorySetting, actions } = this.props;
    const updatedSetting = fromJS({
      enabled,
      reconciliationKeys:
        get(supplierDirectorySetting, 'reconciliationKeys') || [],
    });
    actions.updateSettingValue([SUPPLIER_DIRECTORY], updatedSetting);
  };

  onAutomaticContactInvitationChanged = (enabled) => {
    const { supplierDirectorySetting, actions } = this.props;
    const updatedSetting = set(
      supplierDirectorySetting,
      'automaticContactInvitation',
      enabled
    );
    actions.updateSettingValue([SUPPLIER_DIRECTORY], updatedSetting);
  };

  render() {
    const { supplierDirectorySetting, isReadOnly, referential } = this.props;
    const enabled = get(supplierDirectorySetting, 'enabled') || false;
    const automaticContactInvitation =
      get(supplierDirectorySetting, 'automaticContactInvitation') || false;

    return (
      <>
        <LabeledCheckbox
          label="Supplier directory"
          onChange={this.onEnabledChanged}
          checked={enabled}
          disabled={isReadOnly}
        />
        {enabled && (
          <div className="OrganizationPageSupplierDirectory__content">
            <LabeledCheckbox
              label="Automatic contact invitation"
              onChange={this.onAutomaticContactInvitationChanged}
              checked={automaticContactInvitation}
              disabled={isReadOnly}
              help="This option is only available for retailers that have not subscribed to Salsify SupplierXM onboarding"
            />
            <div className="OrganizationPageSupplierDirectory__subtitle">
              Reconciliation keys{' '}
              <HelpTooltip
                id="reconciliation-key-help"
                place="top"
                message="Keys used in the reconciliation process to match suppliers and organizations"
              />
            </div>
            <ListMultipleController
              pathsLabels={[]}
              path={[SUPPLIER_DIRECTORY]}
              subPaths={['reconciliationKeys']}
              options={referential}
              optionsIdPath="code"
              disabled={isReadOnly}
            />
          </div>
        )}
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(OrganizationPageSettingsSupplierDirectory);
