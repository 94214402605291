import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { separateActions } from 'utils/redux';
import Filter from 'components/ui/filter';
import Search from 'components/ui/input/search';

import { updateFilter } from '../actions';

import {
  selectFiltersDirection,
  selectFiltersGLN,
  selectFiltersGTIN,
} from '../selectors';

const mapStateToProps = (state) => ({
  filterDirection: selectFiltersDirection(state),
  filterGLN: selectFiltersGLN(state),
  filterGTIN: selectFiltersGTIN(state),
});

const mapDispatchToProps = {
  updateFilter,
};

class GDSNCIListFilters extends PureComponent {
  static propTypes = {
    filterDirection: PropTypes.string.isRequired,
    filterGLN: PropTypes.string,
    filterGTIN: PropTypes.string,
    actions: PropTypes.shape({
      updateFilter: PropTypes.func.isRequired,
    }).isRequired,
  };

  onClickDirectionFilter = (name, newValue) => {
    const { filterDirection } = this.props;
    if (filterDirection !== newValue.value) {
      this.props.actions.updateFilter('direction', newValue.value);
    }
  };

  onFilterGLN = (search) => {
    this.props.actions.updateFilter('filter_gln_in', search);
  };

  onFilterGTIN = (search) => {
    this.props.actions.updateFilter('filter_gtin_in', search);
  };

  render() {
    const { filterDirection, filterGLN, filterGTIN } = this.props;
    const directionList = [
      {
        label: 'Import',
        value: 'read',
        statusBarType: 'EMPTY',
        checked: filterDirection === 'read',
      },
      {
        label: 'Export',
        value: 'write',
        statusBarType: 'EMPTY',
        checked: filterDirection === 'write',
      },
    ];
    return (
      <div>
        <div className="card">
          <Filter
            type="list"
            filters={directionList}
            onChange={this.onClickDirectionFilter}
            name="status"
            title={
              <span>
                <i className="mdi mdi-directions-fork" /> Direction
              </span>
            }
          />
          <hr />
          <Search
            query={filterGLN}
            updateSearchQuery={this.onFilterGLN}
            placeholder="Filter by gln..."
          />
          <hr />
          <Search
            query={filterGTIN}
            updateSearchQuery={this.onFilterGTIN}
            placeholder="Filter by gtin..."
          />
          <hr />
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(GDSNCIListFilters);
