import * as events from '../constants/events';

export const receiveGDSNRCIList = (list, totalResults) => ({
  type: events.RECEIVE_GDSN_RCI_LIST,
  payload: {
    list,
    totalResults,
  },
});

export const receiveGDSNRCIListFilters = (data) => ({
  type: events.RECEIVE_GDSN_RCI_LIST_FILTERS,
  payload: {
    data,
  },
});

export const getGDSNRCIList = () => ({
  type: events.GET_GDSN_RCI_LIST,
});

export const getGDSNRCIListFilters = () => ({
  type: events.GET_GDSN_RCI_LIST_FILTERS,
});

export const changeLimit = (limit) => ({
  type: events.CHANGE_LIMIT_GDSN_RCI_LIST,
  limit,
});

export const nextPage = () => ({
  type: events.NEXT_PAGE_GDSN_RCI_LIST,
});

export const previousPage = () => ({
  type: events.PREVIOUS_PAGE_GDSN_RCI_LIST,
});

export const addGDSNRCIListFilters = (key, value) => ({
  type: events.ADD_GDSN_RCI_LIST_FILTER,
  key,
  value,
});

export const removeGDSNRCIListFilters = (key, value) => ({
  type: events.REMOVE_GDSN_RCI_LIST_FILTER,
  key,
  value,
});

export const updateGDSNRCIListFilters = (key, value) => ({
  type: events.UPDATE_GDSN_RCI_LIST_FILTER,
  key,
  value,
});

export const clearGDSNRCIListFilters = (key, value) => ({
  type: events.CLEAR_GDSN_RCI_LIST_FILTER,
  key,
  value,
});

export const downloadGDSNDocument = (documentID) => ({
  type: events.DOWNLOAD_GDSN_RCI_DOCUMENT,
  documentID,
});

export const openRCIModal = (party) => ({
  type: events.OPEN_GDSN_RCI_MODAL,
  party,
});

export const closeRCIModal = () => ({
  type: events.CLOSE_GDSN_RCI_MODAL,
});

export const saveGDSNRCI = () => ({
  type: events.GDSN_RCI_MODAL_SAVE,
});

export const deleteGDSNRCI = (rciID) => ({
  type: events.GDSN_RCI_DELETE,
  rciID,
});

export const updateGDSNRCIAttr = (key, value) => ({
  type: events.GDSN_RCI_ATTR_UPDATE,
  payload: {
    key,
    value,
  },
});

export const openGepirModal = () => ({
  type: events.OPEN_GDSN_GEPIR_MODAL,
});

export const closeGepirModal = () => ({
  type: events.CLOSE_GDSN_GEPIR_MODAL,
});

export const uploadGepirCSV = (file) => ({
  type: events.GDSN_GEPIR_MODAL_UPLOAD_CSV,
  file,
});
