import { memo, useEffect } from 'react';
import d3 from 'utils/d3';
import './D3Graph.scss';

interface Props {
  id: string;
  svg: string;
  selectNode: Function;
  showPayload: Function;
}

export const D3Graph = memo(({ id, svg, selectNode, showPayload }: Props) => {
  useEffect(() => {
    const selector = `#${id}`;

    d3.select(selector).selectAll('*').remove();

    let g: any = null;
    const zoom = d3
      .zoom()
      .on('zoom', (event) => g.attr('transform', event.transform));
    g = d3.select(selector).html(svg).select('svg').select('g');

    d3.select(`${selector} svg`)
      .call(zoom.transform, () => {
        // Initialize the zoom correctly
        const r = /translate\(([0-9.]+),? ?([0-9.]+)\)/;
        const match = g.attr('transform').match(r);
        if (!match) {
          return null;
        }

        const x = match[1];
        const y = match[2];
        return d3.zoomIdentity.translate(x, y);
      })
      .call(zoom);

    // remove <title>s in svg; graphviz leaves these here and they
    // trigger useless tooltips.
    d3.select(selector).selectAll('title').remove();

    // make sure the svg uses 100% of the viewport, so that pan/zoom works
    // as expected and there's no clipping.
    d3.select(selector)
      .select('svg')
      .attr('width', '100%')
      .attr('height', '700px')
      .on('click', () => {
        selectNode(null);
      }); // :'(

    // Click on a title to highlight branch
    d3.select(selector)
      .selectAll('.node text:nth-of-type(1)')
      .attr('cursor', 'pointer')
      .on('click', function (event) {
        event.stopPropagation();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const transactionId = this.parentElement.attributes.id.value;
        selectNode(parseInt(transactionId, 10));
      });

    // Click on the id to show the payload modal
    d3.select(selector)
      .selectAll('.node text:nth-of-type(2)')
      .attr('cursor', 'pointer')
      .on('click', function (event) {
        event.stopPropagation();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const transactionId = this.parentElement.attributes.id.value;
        showPayload(parseInt(transactionId, 10));
      });
  }, [id, selectNode, showPayload, svg]);

  return (
    <div className="D3Graph">
      <div id={id} />
    </div>
  );
});
