import { HelpTooltip } from '@alkem/react-ui-helptooltip';
import TurboSelect from '@alkem/react-ui-turbo-select';
import {
  PermissionConfiguration,
  PermissionConfigurationRole,
  PermissionConfigurationScope,
} from '@alkem/sdk-dashboard';
import cn from 'classnames';
import { set } from 'lodash/fp';
import { selectSettings } from 'modules/organization-page/selectors/settings';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DispatchType } from 'types/redux';
import {
  loadAllOrganizationPermissions,
  loadOrganizationPermissions,
  upsertConfiguration,
} from '../../actions/permissions';
import {
  selectOrganizationConfiguration,
  selectOrganizationPermissions,
} from '../../selectors/permissions';
import './index.scss';

interface Props {
  organizationId: number;
}

const ValueOrDefault = ({ data, innerProps, children }) => (
  <div
    className={cn(
      innerProps.className,
      'OrganizationPermissions__ValueOrDefault'
    )}
  >
    {data.default_configuration || data.primary ? (
      <>{children[0]}&nbsp;</>
    ) : (
      children
    )}
  </div>
);

export const OrganizationPermissions = ({ organizationId }: Props) => {
  const dispatch: DispatchType = useDispatch();
  const settings = useSelector(selectSettings);
  const permissions = useSelector(selectOrganizationPermissions);
  const configuration = useSelector(selectOrganizationConfiguration);
  const [permissionsConfiguration, setPermissionsConfiguration] =
    useState<PermissionConfiguration | null>(null);
  const [organizationConfiguration, setOrganizationConfiguration] =
    useState<PermissionConfiguration | null>(null);
  const hasModule = useCallback(
    (entityType) => Boolean(settings.get(`permission-v3-${entityType}`)),
    [settings]
  );

  useEffect(() => {
    if (permissions) {
      setPermissionsConfiguration(permissions);
    }
  }, [permissions]);

  useEffect(() => {
    if (configuration) {
      setOrganizationConfiguration(configuration);
    }
  }, [configuration]);

  useEffect(() => {
    dispatch(loadOrganizationPermissions({ organizationId }));
  }, [dispatch, organizationId]);

  useEffect(() => {
    if (!permissions) {
      dispatch(loadAllOrganizationPermissions());
    }
  }, [dispatch, permissions]);

  const onScopesChanged = useCallback(
    (entityType: string, scopes) => {
      dispatch(
        upsertConfiguration(
          set<PermissionConfiguration>(
            ['scopes', entityType],
            scopes,
            organizationConfiguration || {}
          )
        )
      );
    },
    [dispatch, organizationConfiguration]
  );

  const onRolesChanged = useCallback(
    (entityType: string, roles) => {
      dispatch(
        upsertConfiguration(
          set<PermissionConfiguration>(
            ['roles', entityType],
            roles,
            organizationConfiguration || {}
          )
        )
      );
    },
    [dispatch, organizationConfiguration]
  );

  if (!permissionsConfiguration || !organizationConfiguration) {
    return null;
  }

  const scopes = Object.entries(permissionsConfiguration.scopes).filter(
    ([entityType]) => hasModule(entityType)
  );
  const roles = Object.entries(permissionsConfiguration.roles).filter(
    ([entityType]) => hasModule(entityType)
  );
  const hasModules = Boolean(scopes.length || roles.length);
  const hasRoles = hasModules && Boolean(roles.length);
  const hasScopes = hasModules && Boolean(scopes.length);

  return (
    <div className="OrganizationPermissions OrganizationPageBlock">
      <div className="OrganizationPageBlock__header">
        <h2>
          Permissions{' '}
          <HelpTooltip
            id="permissions-help"
            message="When you activate the rich-permission flags with the different entities (tariffs, events, unit of need, ...) you're able to customize the dimensions used in scoping. You're also able to add rights to custom features that have been developed for customers."
          />
        </h2>
      </div>
      {hasScopes && (
        <div className="OrganizationPermissions__section">
          <p>Which dimensions do you want to assign to this organization?</p>
          <table className="OrganizationPermissions__scopes">
            <tbody>
              {scopes.map(([entityType, dimensions = []]) => (
                <tr key={entityType}>
                  <td>
                    {permissionsConfiguration._metadata[entityType].display}
                  </td>
                  <td>
                    <TurboSelect<PermissionConfigurationScope, true | false>
                      options={dimensions.filter(({ primary }) => !primary)}
                      getOptionLabel={({ display, dimension_key }) =>
                        dimensions.filter(
                          (dimension) => dimension.display === display
                        ).length > 1
                          ? `${display} (${dimension_key})`
                          : display || ''
                      }
                      getOptionValue={({ dimension_key }) => dimension_key}
                      value={organizationConfiguration.scopes[entityType]}
                      onChange={(value) => onScopesChanged(entityType, value)}
                      isMulti
                      isSearchable
                      isClearable={false}
                      backspaceRemovesValue={false}
                      components={{
                        MultiValueContainer: ValueOrDefault,
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {hasRoles && (
        <div className="OrganizationPermissions__section">
          <p>Which custom rights do you want to assign to this organization?</p>
          <table className="OrganizationPermissions__roles">
            <tbody>
              {roles.map(([entityType, permissions]) => (
                <tr key={entityType}>
                  <td>
                    {permissionsConfiguration._metadata[entityType].display}
                  </td>
                  <td>
                    <TurboSelect<PermissionConfigurationRole, true | false>
                      options={permissions}
                      getOptionLabel={({ display }) => display || ''}
                      getOptionValue={({ permission_key }) => permission_key}
                      value={organizationConfiguration.roles[entityType]}
                      onChange={(value) => onRolesChanged(entityType, value)}
                      isMulti
                      isSearchable
                      isClearable={false}
                      backspaceRemovesValue={false}
                      components={{
                        MultiValueContainer: ValueOrDefault,
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {!hasModules && (
        <p className="OrganizationPermissions__warning">
          You need to enable at least one module feature flag.
        </p>
      )}
    </div>
  );
};
