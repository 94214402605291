import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { OrganizationAutocomplete } from 'components/autocomplete';

import './autocomplete.scss';

class OrganizationAutocompleteFilter extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.node.isRequired,
    onSelect: PropTypes.func.isRequired,
    onUnselect: PropTypes.func.isRequired,
    value: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        value: PropTypes.any,
        label: PropTypes.node,
      })
    ),
  };

  constructor(props) {
    super(props);

    this.onUnselect = this.onUnselect.bind(this);
  }

  onUnselect({ item }) {
    this.props.onUnselect({ key: parseInt(item.key, 10) });
  }

  render() {
    const { id, title, value, onSelect } = this.props;

    return (
      <div id={id} className="AutocompleteFilter">
        <h3 className="ReactUiFilter__filterTitle">{title}</h3>
        <OrganizationAutocomplete
          id={`list-autocomplete-${id}`}
          className="TransactionFilter__Autocomplete"
          onSelect={onSelect}
          onUnselect={this.onUnselect}
          value={value}
          searchOnClick
        />
      </div>
    );
  }
}
export default OrganizationAutocompleteFilter;
