import { Button } from '@alkem/react-ui-button/src/index';
import { CodeEditor } from 'components/code-editor';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { getIn } from 'utils/immutable';
import { execureRunnable } from '../api';
import './route.scss';

export default class Runnable extends PureComponent {
  static propTypes = {
    config: PropTypes.object.isRequired,
  };

  state = {
    isRunning: false,
    runnableParams: Map(),
  };

  componentDidMount() {
    const runnable = getIn(this.props.config, 'protocols.http.runnable');
    if (runnable) {
      this.setState({
        runnableParams: Map({
          queryParams: JSON.stringify(runnable.query_params || '', null, 2),
          uriParams: JSON.stringify(runnable.uri_params || '', null, 2),
          bodyParams: JSON.stringify(runnable.body_params || '', null, 2),
        }),
      });
    }
  }

  onRun = async () => {
    const http = getIn(this.props.config, 'protocols.http');
    this.setState({ isRunning: true });
    const routes = await execureRunnable({
      address: http.address,
      method: http.method,
      params: this.state.runnableParams
        .map((v) => JSON.parse(v || '{}'))
        .toJS(),
    });
    this.setState({ isRunning: false });
    return routes;
  };

  onChangeUriParams = (v) => {
    this.setState((s) => ({
      runnableParams: s.runnableParams.set('uriParams', v),
    }));
  };

  onChangeQueryParams = (v) => {
    this.setState((s) => ({
      runnableParams: s.runnableParams.set('queryParams', v),
    }));
  };

  onChangeBodyParams = (v) => {
    this.setState((s) => ({
      runnableParams: s.runnableParams.set('bodyParams', v),
    }));
  };

  render() {
    const runnable = getIn(this.props.config, 'protocols.http.runnable');
    const { runnableParams } = this.state;
    return (
      <div className="DiscoverRoute_runnable">
        <div className="DiscoverRoute_runnable__desc">{runnable.desc}</div>
        <div className="DiscoverRoute_runnable__params">
          <div className="params_bloc">
            <span>Uri params</span>
            <CodeEditor
              className="Params__Payload"
              name="uri_param"
              value={runnableParams.get('uriParams')}
              mode="json"
              theme="monokai"
              width="100%"
              height="300px"
              editorProps={{ $blockScrolling: Infinity }}
              onChange={this.onChangeUriParams}
            />
          </div>
          <div className="params_bloc">
            <span>Query params</span>
            <CodeEditor
              className="Query__Payload"
              name="uri_param"
              value={runnableParams.get('queryParams')}
              mode="json"
              theme="monokai"
              width="100%"
              height="300px"
              editorProps={{ $blockScrolling: Infinity }}
              onChange={this.onChangeQueryParams}
            />
          </div>
          <div className="params_bloc">
            <span>Body params</span>
            <CodeEditor
              className="Body__Payload"
              name="uri_param"
              value={runnableParams.get('bodyParams')}
              mode="json"
              theme="monokai"
              width="100%"
              height="300px"
              editorProps={{ $blockScrolling: Infinity }}
              onChange={this.onChangeBodyParams}
            />
          </div>
        </div>
        <Button
          onClick={this.onRun}
          primary
          content="Run"
          disabled={this.state.isRunning}
          displaySpinner={this.state.isRunning}
        />
      </div>
    );
  }
}
