import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import List from '@alkem/react-ui-list';
import { Spinner } from '@alkem/react-ui-spinner';
import { SwitchButton } from '@alkem/react-ui-button';

import { separateActions } from 'utils/redux';
import GLNClaimRow from './row';
import GLNClaimHeaderRow from './header';
import {
  selectGLNClaims,
  selectGLNData,
  selectIsLoading,
  selectSearch,
  selectLimit,
  selectCurrentPage,
  selectTotalPages,
  selectTotalResults,
  selectActionnableClaimsOnly,
} from '../../selectors';
import {
  getGLNClaimsWithData,
  nextPage,
  previousPage,
  onLimitChange,
  setActionnableClaimsOnly,
} from '../../actions';

import './index.scss';

const mapStateToProps = createStructuredSelector({
  glnClaims: selectGLNClaims,
  glnData: selectGLNData,
  limit: selectLimit,
  search: selectSearch,
  totalResults: selectTotalResults,
  currentPage: selectCurrentPage,
  totalPages: selectTotalPages,
  isLoading: selectIsLoading,
  actionnableClaimsOnly: selectActionnableClaimsOnly,
});

const mapDispatchToProps = {
  getGLNClaimsWithData,
  nextPage,
  previousPage,
  onLimitChange,
  setActionnableClaimsOnly,
};

export class GLNClaimList extends Component {
  static propTypes = {
    glnClaims: ImmutablePropTypes.list.isRequired,
    glnData: ImmutablePropTypes.map.isRequired,
    isLoading: PropTypes.bool,
    actionnableClaimsOnly: PropTypes.bool,
    search: PropTypes.object.isRequired,
    limit: PropTypes.number.isRequired,
    totalResults: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    actions: PropTypes.shape({
      getGLNClaimsWithData: PropTypes.func.isRequired,
      nextPage: PropTypes.func.isRequired,
      previousPage: PropTypes.func.isRequired,
      onLimitChange: PropTypes.func.isRequired,
      setActionnableClaimsOnly: PropTypes.func.isRequired,
    }).isRequired,
  };

  componentDidMount() {
    this.props.actions.getGLNClaimsWithData();
  }

  componentDidUpdate(prevProps) {
    const { actions, limit, currentPage, search } = this.props;
    if (
      limit !== prevProps.limit ||
      currentPage !== prevProps.currentPage ||
      search !== prevProps.search
    ) {
      actions.getGLNClaimsWithData(search !== prevProps.search);
    }
  }

  onActionnableItemsOnlyChanged = (checked) => {
    this.props.actions.setActionnableClaimsOnly(checked);
    this.props.actions.getGLNClaimsWithData(true);
  };

  getLimitLabel(count) {
    return `${count} per page.`;
  }

  getPageLabel(page, total) {
    return `${page} / ${total} ${total > 1 ? 'pages' : 'page'}.`;
  }

  getEntityLabel(count) {
    return `${count} claims in total.`;
  }

  renderActions() {
    const { actionnableClaimsOnly } = this.props;
    return (
      <SwitchButton
        checked={actionnableClaimsOnly}
        onChange={this.onActionnableItemsOnlyChanged}
        content="Pending actions only"
      />
    );
  }

  render() {
    const { glnClaims, glnData, isLoading } = this.props;
    return (
      <div className="GLNClaimList">
        <List
          currentPage={this.props.currentPage}
          totalPages={this.props.totalPages}
          totalResults={this.props.totalResults}
          onNext={this.props.actions.nextPage}
          onPrev={this.props.actions.previousPage}
          limit={this.props.limit}
          onLimitChange={this.props.actions.onLimitChange}
          getLimitLabel={this.getLimitLabel}
          getPageLabel={this.getPageLabel}
          getEntityLabel={this.getEntityLabel}
          actions={this.renderActions()}
          bottomActions
        >
          <GLNClaimHeaderRow />
          {isLoading && (
            <div className="GLNClaimList__spinner">
              <Spinner big loading />
            </div>
          )}
          {!isLoading &&
            glnClaims
              .valueSeq()
              .map((claim) => (
                <GLNClaimRow
                  key={`claim-${claim.get('id')}`}
                  glnClaim={claim}
                  glnData={glnData.get(claim.get('value'))}
                />
              ))}
        </List>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(GLNClaimList);
