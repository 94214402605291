import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import CollapsibleReferentialSection from './collapsible-referential-section';
import ReferentialManagementTopNodeAddition from './top-node-addition';
import ReferentialManagementImport from './import';
import ReferentialManagementNodeUpdate from './node-update';

export default class ReferentialManagement extends Component {
  static propTypes = {
    sourceReferentials: ImmutablePropTypes.list,
    isLoading: PropTypes.bool,
    isAdding: PropTypes.bool,
    isImporting: PropTypes.bool,
    isBranching: PropTypes.bool,
    isUpdating: PropTypes.bool,
    imported: ImmutablePropTypes.list,
    addRootNode: PropTypes.func,
    importReferentials: PropTypes.func,
    saveImportedReferentials: PropTypes.func,
    updateReferential: PropTypes.func,
    addChildrenToReferential: PropTypes.func,
  };

  state = {
    add: true,
    update: true,
    import: true,
  };

  toggle = (id) => () =>
    this.setState((prevState) => ({ [id]: !prevState[id] }));

  renderAddTopReferential = () => (
    <ReferentialManagementTopNodeAddition
      isLoading={this.props.isLoading}
      isAdding={this.props.isAdding}
      addRootNode={this.props.addRootNode}
    />
  );

  renderUpdateReferential = () => (
    <ReferentialManagementNodeUpdate
      sourceReferentials={this.props.sourceReferentials}
      isLoading={this.props.isLoading}
      isUpdating={this.props.isUpdating}
      updateReferential={this.props.updateReferential}
      addChildrenToReferential={this.props.addChildrenToReferential}
    />
  );

  renderImportReferentials = () => (
    <ReferentialManagementImport
      sourceReferentials={this.props.sourceReferentials}
      isLoading={this.props.isLoading}
      isImporting={this.props.isImporting}
      isBranching={this.props.isBranching}
      imported={this.props.imported}
      importReferentials={this.props.importReferentials}
      saveImportedReferentials={this.props.saveImportedReferentials}
    />
  );

  render = () => {
    const sections = [
      {
        id: 'add',
        title: 'Add a root referential',
        render: this.renderAddTopReferential,
      },
      {
        id: 'update',
        title: 'Update a referential or add a children (au calme)',
        render: this.renderUpdateReferential,
      },
      {
        id: 'import',
        title: 'Import referentials',
        render: this.renderImportReferentials,
      },
    ];
    return (
      <div>
        {sections.map((section) => (
          <CollapsibleReferentialSection
            key={`CollapsibleReferentialSection-${section.id}`}
            title={section.title}
            collapsed={this.state[section.id]}
            toggle={this.toggle(section.id)}
          >
            {section.render()}
          </CollapsibleReferentialSection>
        ))}
      </div>
    );
  };
}
