import { SwitchButton } from '@alkem/react-ui-button';
import EmptyState from '@alkem/react-ui-empty-state';
import { Spinner } from '@alkem/react-ui-spinner';
import { LazyTooltip } from '@alkem/react-ui-tooltip';
import { validationDashboardAccessPolicy } from 'access-policies';
import classNames from 'classnames';
import InputWithLabel from 'components/input-with-label';
import { validationDashboard } from 'constants/routes';
import { typePackagingsForRuleEntityType } from 'constants/typePackagings';
import { fromJS, Set as ImmutableSet } from 'immutable';
import { difference, isEqual, set } from 'lodash/fp';
import { selectUserReadOnlyAccess } from 'modules/auth/selectors';
import {
  validationFetchRule,
  validationFetchRuleDimensions,
  validationSetDirtyRule,
  validationSetEditedRule,
} from 'modules/validation-dashboard/actions';
import { emptyRule } from 'modules/validation-dashboard/moduleState';
import {
  selectValidationDirtyRule,
  selectValidationEditingRule,
  selectValidationIsLoadingDimensions,
  selectValidationIsLoadingRule,
} from 'modules/validation-dashboard/selectors';
import { Rule } from 'modules/validation-dashboard/types';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Prompt, useParams } from 'react-router-dom';
import { kindsIndexSelector } from 'redux/selectors';
import { KindId } from 'redux/state/kinds';
import history from 'utils/history';
import { ruleEntityTypes, ruleTemplateLabels } from '../../constants';
import { ApplicableKinds } from '../applicable-kinds';
import { ApplicablePackagingTypes } from '../applicable-packaging-types';
import { ValidationRuleHeader } from '../rule-header';
import {
  getEntityType,
  ValidationRuleTags,
} from '../rules-list/validation-rule-beta';
import { ValidationRuleDocumentation } from '../rules-list/validation-rule-documentation';
import { ConditionsDisplay } from './validation-conditions-display';
import { ConditionsEditor } from './validation-conditions-editor';
import { ValidationRuleApplier } from './rule-applier/validation-rule-applier';
import './validation-rule-editor.scss';
import { ValidationRuleOrganizations } from './validation-rule-organizations';
import { ValidationRuleSetInput } from './validation-rule-set-input';

interface Params {
  ruleId?: string;
}

const ValidationRuleBlock = ({
  title = '',
  help,
  children,
  actionButtons,
  className,
}: {
  title?: string;
  help?: string;
  children?: any;
  actionButtons?: any;
  className?: string;
}) => {
  return (
    <div
      className={classNames('ValidationRuleBlock', className)}
      data-testid="validation-rule-block"
    >
      {(help || title || actionButtons) && (
        <div className="ValidationRuleBlock__title alk-flex alk-flex-row alk-flex-baseline alk-flex-space-between">
          <h2>{title}</h2>
          <small>{help}</small>
          <div>{actionButtons}</div>
        </div>
      )}
      {children}
    </div>
  );
};

function canBeBlocking(rule: Rule) {
  return !rule?.ruleSet?.id;
}

function canBypass(rule: Rule) {
  return [
    ruleEntityTypes.CONSUMER_UNIT.id,
    ruleEntityTypes.DISPLAY_UNIT.id,
  ].includes(rule.ruleEntityType as number);
}

// TODO remove false after back modif
function canSetKinds(rule: Rule) {
  return [
    ruleEntityTypes.CONSUMER_UNIT.id,
    ruleEntityTypes.TEXTILE_VARIANT.id,
    ruleEntityTypes.SHARING_UNIT.id,
    ruleEntityTypes.SHARING_UNITS.id,
    ruleEntityTypes.PRICE_WATERFALL.id,
    ruleEntityTypes.LOGISTICAL_HIERARCHY.id,
    ruleEntityTypes.LOGISTICAL_UNIT.id,
    ruleEntityTypes.LOGISTICAL_HIERARCHY_TOP_UNIT.id,
  ].includes(rule.ruleEntityType as number);
}

function canSetPackagingTypes(rule: Rule) {
  return [
    ruleEntityTypes.CONSUMER_UNIT.id,
    ruleEntityTypes.TEXTILE_VARIANT.id,
    ruleEntityTypes.DISPLAY_UNIT.id,
    ruleEntityTypes.LOGISTICAL_HIERARCHY.id,
    ruleEntityTypes.LOGISTICAL_UNIT.id,
    ruleEntityTypes.LOGISTICAL_HIERARCHY_TOP_UNIT.id,
  ].includes(rule.ruleEntityType as number);
}

const renderBackButton = () => (
  <Link to={`${validationDashboard}${history.location.search}`}>
    <i className="mdi mdi-arrow-left" /> Back to results
  </Link>
);

export const ValidationRuleEditor = () => {
  const { ruleId: ruleIdStr } = useParams<Params>();

  const ruleId = ruleIdStr ? parseInt(ruleIdStr) : undefined;

  const isLoading = useSelector(selectValidationIsLoadingRule);
  const dispatch = useDispatch();

  const isLoadingDimensions: boolean = useSelector(
    selectValidationIsLoadingDimensions
  );

  const setRule = useCallback(
    (rule: Rule) => dispatch(validationSetDirtyRule(rule)),
    [dispatch]
  );

  useEffect(() => {
    if (ruleId) {
      dispatch(validationFetchRule(ruleId));
    }
  }, [dispatch, ruleId]);

  const initRule: Rule = useSelector(selectValidationEditingRule);
  const rule: Rule = useSelector(selectValidationDirtyRule);

  const ruleEntityType = getEntityType(rule);

  const isTemplatedRule = rule.template?.label !== ruleTemplateLabels.CUSTOM;
  const isTemplatedRuleFromViewAs = isTemplatedRule && !initRule.ruleSet;

  const userIsReadonly = useSelector(
    selectUserReadOnlyAccess(validationDashboardAccessPolicy)
  );
  const readOnly = userIsReadonly || isTemplatedRule;

  const kinds = useSelector(kindsIndexSelector);

  const allKindsIds = useMemo<KindId[]>(
    () =>
      Object.keys(kinds).map((id) => parseInt(id, 10)) as unknown as KindId[],
    [kinds]
  );

  const forbiddenKinds = useMemo(
    () =>
      ImmutableSet(
        rule.metadata.fields_raw_applicability.generic_kind_ids
          ? difference(
              allKindsIds,
              rule.metadata.fields_raw_applicability.generic_kind_ids
            )
          : []
      ),
    [allKindsIds, rule.metadata.fields_raw_applicability.generic_kind_ids]
  );

  const forbiddenTypePackagings = useMemo(
    () =>
      ImmutableSet(
        rule.metadata.fields_raw_applicability.generic_packaging_type_ids
          ? difference(
              typePackagingsForRuleEntityType(rule.ruleEntityType),
              rule.metadata.fields_raw_applicability.generic_packaging_type_ids
            )
          : []
      ),
    [
      rule.ruleEntityType,
      rule.metadata.fields_raw_applicability.generic_packaging_type_ids,
    ]
  );

  const defaultApplicableKinds = useMemo(
    () =>
      (rule.metadata.fields_raw_applicability.generic_kind_ids || []).map(
        (kindId) => {
          return {
            id: kindId,
          };
        }
      ),
    [rule.metadata.fields_raw_applicability.generic_kind_ids]
  );

  const showRuleApplicationBlock =
    rule?.ruleSet &&
    (canBeBlocking(rule) ||
      canSetPackagingTypes(rule) ||
      canSetKinds(rule) ||
      canBypass(rule));

  const [restrictionType, setRestrictionType] = useState(false);

  const updateRule = (path: string[], value: any) => {
    const tempRule = set(path, value, rule);
    setRule(tempRule);

    if (path.length && ['selectors', 'conditionSelectors'].includes(path[0])) {
      dispatch(validationFetchRuleDimensions(tempRule));
    }
  };

  const updatePackagingTypes = (value: { id: any }[]) => {
    updateRule(['applicableForTypePackagings'], value);
  };

  useEffect(() => {
    return () => {
      dispatch(validationSetEditedRule(emptyRule));
      dispatch(validationSetDirtyRule(emptyRule));
    };
  }, [dispatch]);

  const displayRuleKindWarning = rule?.applicableForKinds.some((kind) =>
    forbiddenKinds.contains(kind.id)
  );
  const displayRulePackagingTypeWarning = rule.applicableForTypePackagings.some(
    (packagingType) => forbiddenTypePackagings.contains(packagingType.id)
  );

  const isDirty = !isEqual(rule, initRule);

  if (rule?.id) {
    return (
      <div className="ValidationRuleEditor">
        <div className="ValidationRuleEditor__header alk-flex alk-flex-column">
          {renderBackButton()}
          <ValidationRuleHeader
            rule={rule}
            isDirty={isDirty}
            readOnly={readOnly}
            withWarning={
              displayRuleKindWarning || displayRulePackagingTypeWarning
            }
            disabled={
              isLoading ||
              (!isTemplatedRuleFromViewAs && !rule.ruleSet) ||
              isLoadingDimensions
            }
            allowSave
            allowToggleStatus
          />
          <div className="ValidationRuleInfos alk-flex alk-flex-row alk-flex-space-between">
            <ValidationRuleTags
              ruleId={ruleId}
              ruleEntityType={ruleEntityType}
              ruleTemplateType={rule.template?.label as string}
            />
            <div className="ValidationRuleInfos__contextual">
              <small>
                Updated on {moment(rule?.updatedAt).format('LL')} | Created on{' '}
                {moment(rule?.createdAt).format('LL')}
              </small>
            </div>
          </div>
        </div>

        <ValidationRuleBlock
          title="Rule set"
          className="RuleSetValidationRuleBlock"
        >
          {isTemplatedRuleFromViewAs ? (
            <div>
              This is a templated rule created when setting the field as
              required in the retailer view-as dashboard. It cannot be
              associated to a rule set.
            </div>
          ) : (
            <ValidationRuleSetInput
              ruleSet={rule.ruleSet}
              onChange={(value) => updateRule(['ruleSet'], value)}
            />
          )}
        </ValidationRuleBlock>

        <ValidationRuleBlock
          title="Rule Conditions"
          help="Where the triggers of the rule are set."
        >
          {readOnly ? (
            <ConditionsDisplay
              id={`validation-rule-${rule.id}-summary`}
              rule={rule}
            />
          ) : (
            <ConditionsEditor
              id={`rule-${rule.id}-conditions-editor`}
              rule={rule}
              initRule={initRule}
              updateRule={updateRule}
            />
          )}
        </ValidationRuleBlock>
        {showRuleApplicationBlock && (
          <ValidationRuleBlock
            title="Rule application"
            help="Where conditions of applicability are defined."
            className="RuleApplicationBlock"
          >
            {canBypass(rule) && (
              <InputWithLabel inputId="rule-bypassable" label="Bypassable">
                <SwitchButton
                  testid="rule-bypassable"
                  checked={rule?.bypassable}
                  onChange={(checked) => updateRule(['bypassable'], checked)}
                />
              </InputWithLabel>
            )}
            {canBeBlocking(rule) && (
              <InputWithLabel inputId="rule-restrictionType" label="Blocking">
                <SwitchButton
                  testid="rule-restrictionType"
                  checked={restrictionType}
                  onChange={(checked) => {
                    updateRule(['restrictionType'], checked ? 1 : 0);
                    setRestrictionType((rt) => !rt);
                  }}
                />
              </InputWithLabel>
            )}
            {canSetKinds(rule) && !isLoadingDimensions && (
              <div className="alk-flex">
                {displayRuleKindWarning && (
                  <LazyTooltip
                    id={'kind-warning'}
                    tooltipLabel="Some kinds you selected will be removed when you will save the rule as they are not applicable on selected fields"
                    className="RuleApplicationBlock__message__warning"
                  >
                    <i
                      className="mdi mdi-24px mdi-alert"
                      data-testid="rule-application-block-warning-kinds"
                    />
                  </LazyTooltip>
                )}
                <InputWithLabel
                  inputId={`rule-${ruleId}-applicable-kinds`}
                  label="Applicable kinds"
                >
                  <ApplicableKinds
                    kinds={kinds}
                    applicableKinds={fromJS(rule.applicableForKinds)}
                    forbiddenKinds={forbiddenKinds}
                    defaultApplicableKinds={fromJS(defaultApplicableKinds)}
                    onChange={(value) =>
                      updateRule(['applicableForKinds'], value.toJS())
                    }
                    disabled={false}
                  />
                </InputWithLabel>
              </div>
            )}

            {canSetPackagingTypes(rule) && !isLoadingDimensions && (
              <div className="alk-flex RuleApplicationBlock__packagingTypes">
                {displayRulePackagingTypeWarning && (
                  <LazyTooltip
                    id={'packaging-type-warning'}
                    tooltipLabel="Some packaging types you selected will be removed when you will save the rule as they are not applicable on selected fields"
                    className="RuleApplicationBlock__message__warning"
                  >
                    <i
                      className="mdi mdi-24px mdi-alert"
                      data-testid="rule-application-block-warning-packaging-types"
                    />
                  </LazyTooltip>
                )}
                <InputWithLabel
                  inputId={`rule-${ruleId}-applicable-packaging-kinds`}
                  label="Applicable Packaging types"
                >
                  <ApplicablePackagingTypes
                    id={`rule-${ruleId}-applicable-packaging-types`}
                    ruleEntityType={rule.ruleEntityType}
                    applicablePackagingTypes={rule.applicableForTypePackagings}
                    forbiddenTypePackagings={forbiddenTypePackagings}
                    onChange={(value) => updatePackagingTypes(value)}
                  />
                </InputWithLabel>
              </div>
            )}
            {isLoadingDimensions && (
              <div className="alk-flex alk-flex-justify-center RuleApplicationBlock__loading">
                <Spinner smallMedium loading />
                <span>Reloading dimensions</span>
              </div>
            )}
          </ValidationRuleBlock>
        )}
        <ValidationRuleBlock
          title={
            rule?.organizations
              ? `Organizations (${rule.organizations.length})`
              : 'Organizations'
          }
          help="Lists all the impacted retailer organizations."
        >
          <ValidationRuleOrganizations
            organizations={rule?.organizations}
            rulesets={rule?.ruleSet ? [rule.ruleSet] : []}
          />
        </ValidationRuleBlock>
        <ValidationRuleBlock
          title="Comment"
          help="Please take the time to fill it with tickets number, rule design explanations and follow-up infos."
        >
          <ValidationRuleDocumentation
            documentation={rule?.documentation}
            onUpdateValue={updateRule}
          />
        </ValidationRuleBlock>
        <ValidationRuleBlock title="Rule tester">
          <ValidationRuleApplier rule={rule} disabled={isLoading} />
        </ValidationRuleBlock>
        <Prompt
          when={isDirty}
          message="Some changes you made have not been saved yet. Are you sure you want to leave the page?"
        />
      </div>
    );
  } else {
    return (
      <>
        <div className="ValidationRuleEditor">
          <div className="ValidationRuleEditor__header alk-flex alk-flex-column">
            {renderBackButton()}
          </div>
        </div>

        {isLoading ? (
          <div className="ValidationRulesList__spinner">
            <Spinner loading big />
          </div>
        ) : (
          <EmptyState title="Rule not found" text="Just check again!" />
        )}
      </>
    );
  }
};
