import 'setup';
import 'index.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';

import { getStore } from 'redux/store';
import Root from 'containers/Root';
import { ErrorFallback } from 'components/error/ErrorFallback';

ReactDOM.render(
  <Provider store={getStore()}>
    <ErrorBoundary
      FallbackComponent={({ error }) => <ErrorFallback error={error} />}
    >
      <Root />
    </ErrorBoundary>
  </Provider>,
  document.getElementById('root')
);
