import history from 'utils/history';
import { ShowUser } from '../components/results/show-user';
import { immutableAuthApi } from 'resources/authApi';
import { getStreamUrl } from 'utils/stream';

export const root = {
  title: 'Go to the user dashboard',
  command: 'usr',
  action: () => {
    history.push(`/user`);
  },
  actionNewTab: () => {
    window.open(`/user`, '_blank').focus();
  },
};

export const what = {
  title: 'Documentation on Users in the context of SXM',
  command: 'usr what',
  render: () => (
    <div>
      <p>A user is any user registered on the SXM platform.</p>
      <p>
        These users can be SXM platform admins, SXM support, Supplier and
        Retailer organization user, etc.
      </p>
    </div>
  ),
};

export const show = {
  title: 'Preview a user',
  command: 'usr show',
  param: 'id',
  help: 'usage: "usr show id:123"',
  render: ({ id }) => {
    if (id) {
      return <ShowUser id={id} />;
    } else {
      return <div>You did not provide a user id. e.g. usr show id:123</div>;
    }
  },
};

export const logas = {
  title: 'Documentation on Users in the context of SXM',
  command: 'usr logas',
  param: 'email',
  help: 'usage: "usr logas email:someone@email.com"',
  action: async ({ email }) => {
    const response = await immutableAuthApi.UserMe();
    const targetUser = await immutableAuthApi.UserList({ username: email });

    const userData = response.data.data?.toJS();
    const targetUserData = targetUser.data.data?.toJS();
    const encodedSource = encodeURIComponent(userData.username);
    const encodedTarget = encodeURIComponent(email);
    const encodedOrganizationId = encodeURIComponent(
      targetUserData[0].belongsTo[0].id
    );

    window.open(
      getStreamUrl(
        `logas?source=${encodedSource}&target=${encodedTarget}&organization=${encodedOrganizationId}`
      )
    );
  },
  actionNewTab: async ({ email }) => {
    const response = await immutableAuthApi.UserMe();
    const targetUser = await immutableAuthApi.UserList({ username: email });

    const userData = response.data.data?.toJS();
    const targetUserData = targetUser.data.data?.toJS();

    const encodedSource = encodeURIComponent(userData.username);
    const encodedTarget = encodeURIComponent(email);
    const encodedOrganizationId = encodeURIComponent(
      targetUserData[0].belongsTo[0].id
    );
    window
      .open(
        getStreamUrl(
          `logas?source=${encodedSource}&target=${encodedTarget}&organization=${encodedOrganizationId}`
        ),
        '_blank'
      )
      .focus();
  },
};

export const commands = [root, what, show, logas];
