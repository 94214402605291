import { call, put, takeEvery } from 'redux-saga/effects';
import { Map } from 'immutable';

import { notificationError } from 'actions/notifications';

import * as api from './api';
import * as events from './events';

function* fetchOrganizations({ entities }) {
  const organizationIds = entities
    .filter((e) => e.get('type').toLowerCase() === 'organization')
    .map((e) => Number(e.get('id')))
    .toSet()
    .toJS();

  if (organizationIds.length === 0) {
    yield put({
      type: events.RECEIVE_ENTITIES,
      key: 'products',
      data: {},
    });
    return;
  }

  const { response, error } = yield call(api.getOrganizations, organizationIds);
  if (error) {
    yield put(notificationError(error));
    return;
  }

  const organizations = response.data.data.reduce(
    (m, o) => m.set(o.get('id'), o),
    Map()
  );

  yield put({
    type: events.RECEIVE_ENTITIES,
    key: 'organizations',
    data: organizations,
  });
}

export default function* mainSaga() {
  yield takeEvery([events.FETCH_ENTITIES], fetchOrganizations);
}
