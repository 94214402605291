import { Map } from 'immutable';

import { notificationSuccess } from 'actions/notifications';
import {
  selectHasSettingFlagChanges,
  saveSettingFlags,
} from 'modules/settings/organization';

import * as events from '../constants/events';
import {
  STATUS_PENDING,
  STATUS_IN_PROGRESS,
  STATUS_SUCCESS,
  STATUS_ERROR,
  ORGANIZATION_INFOS,
  ORGANIZATION_SETTINGS,
  ORGANIZATION_RECIPIENTS,
  ORGANIZATION_APPLICATIONS,
  ORGANIZATION_SETTING_FLAGS,
  ORGANIZATION_BILLING,
  ORGANIZATION_PERMISSIONS,
} from '../constants/save-modal';

import {
  selectFeatureStatus,
  selectOrganizationId,
} from '../selectors/save-modal';
import { retrieveOrganization as getOrganization } from '.';

// Infos
import { hasChange as infosChange } from '../selectors/infos';
import { save as infosSave } from './infos';

// Settings
import { hasChange as settingsChange } from '../selectors/settings';
import { save as settingsSave } from './settings';

// Recipients
import { hasChange as recipientsChange } from '../selectors/recipients';
import { save as recipientsSave } from './recipients';

// Applications
import { hasApplicationsChanges } from '../selectors/applications';
import { saveApplications } from './applications';

// Billing
import { hasBillingChanges } from '../selectors/billing';
import { saveBilling } from './billing';

// Permissions
import {
  selectHasDeletedModuleFlags,
  selectHasPermissionChanges,
} from '../selectors/permissions';
import { upsertOrganizationPermissions } from './permissions';

const featuresDefinition = {
  [ORGANIZATION_INFOS.value]: {
    hasChange: infosChange,
    save: infosSave,
  },
  [ORGANIZATION_SETTINGS.value]: {
    hasChange: settingsChange,
    save: settingsSave,
  },
  [ORGANIZATION_RECIPIENTS.value]: {
    hasChange: recipientsChange,
    save: recipientsSave,
  },
  [ORGANIZATION_APPLICATIONS.value]: {
    hasChange: hasApplicationsChanges,
    save: saveApplications,
  },
  [ORGANIZATION_SETTING_FLAGS.value]: {
    hasChange: selectHasSettingFlagChanges,
    save: saveSettingFlags,
  },
  [ORGANIZATION_BILLING.value]: {
    hasChange: hasBillingChanges,
    save: saveBilling,
  },
  [ORGANIZATION_PERMISSIONS.value]: {
    hasChange: selectHasPermissionChanges,
    withWarning: (state) => {
      if (selectHasDeletedModuleFlags(state)) {
        return 'Your permissions updates will be ignored as you disabled a related feature flag at the same time.';
      }
      return 'Please double-check your modifications before saving. Be careful with updating/deleting permissions, a mistake could delete customer configuration permanently.';
    },
    save: upsertOrganizationPermissions,
  },
};

export const openSaveModal = () => (dispatch, getState) => {
  const state = getState();
  const featureStatus = {};

  Object.keys(featuresDefinition).forEach((key) => {
    if (featuresDefinition[key].hasChange(state)) {
      featureStatus[key] = {
        value: STATUS_PENDING.value,
        withWarning: featuresDefinition[key].withWarning?.(state),
      };
    }
  });

  dispatch({
    type: events.SAVE_MODAL_INIT_FEATURE_STATUS,
    featureStatus: Map(featureStatus),
  });

  dispatch({
    type: events.SAVE_MODAL_TOGGLED,
    open: true,
  });
};

export const closeSaveModal = () => ({
  type: events.SAVE_MODAL_TOGGLED,
  open: false,
});

export const setSaveInProgress = (saving) => ({
  type: events.SAVE_IN_PROGRESS,
  value: saving,
});

export const setSaveErrorMessage = (message) => ({
  type: events.SET_SAVE_ERROR_MESSAGE,
  value: message,
});

const updateFeatureStatus = (feature, status) => ({
  type: events.SAVE_MODAL_UPDATE_FEATURE_STATUS,
  feature,
  status,
});

export const save = () => (dispatch, getState) => {
  const state = getState();
  const organizationId = selectOrganizationId(state);
  const featureStatus = selectFeatureStatus(state);

  dispatch(setSaveInProgress(true));

  const promises = [];
  featureStatus.keySeq().forEach((featureValue) => {
    dispatch(updateFeatureStatus(featureValue, STATUS_IN_PROGRESS.value));
    promises.push(
      dispatch(featuresDefinition[featureValue].save()).then(
        () => {
          dispatch(updateFeatureStatus(featureValue, STATUS_SUCCESS.value));
        },
        (error) => {
          dispatch(updateFeatureStatus(featureValue, STATUS_ERROR.value));
          return Promise.reject(error);
        }
      )
    );
  });

  const all = Promise.all(promises);

  all.then(
    () => {
      dispatch(getOrganization(organizationId));
      dispatch(setSaveInProgress(false));
      dispatch(closeSaveModal());
      dispatch(notificationSuccess('Organization settings have been saved'));
    },
    (error) => {
      dispatch(getOrganization(organizationId));
      dispatch(setSaveInProgress(false));
      dispatch(setSaveErrorMessage(error.data.message));
    }
  );

  return all;
};
