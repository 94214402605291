import { List } from 'immutable';

import { MODULE_REDUCER_KEY } from 'constants/reducer';

import { IMPORT_GENERIC_LIST_STORE_KEY } from '../constants/reducers';

export function selectState(state) {
  return state[MODULE_REDUCER_KEY][IMPORT_GENERIC_LIST_STORE_KEY];
}

export function selectFiltersIsLoading(state) {
  return selectState(state).filtersIsLoading;
}

export function selectWorkflowSelected(state) {
  return selectState(state).filtersSelected.workflow;
}

export function selectWorkflows(state) {
  if (selectState(state).filtersIsLoading === true) {
    return List();
  }
  return selectState(state).filters.get('workflows');
}

export function selectOrganizations(state) {
  return selectState(state).filters.get('organizations');
}

export function selectStatuses(state) {
  return selectState(state).filters.get('statuses');
}

export function selectImporters(state) {
  const workflow = selectWorkflowSelected(state);
  const importers = selectState(state).filters.get('importers_per_workflow');
  return importers.get(workflow);
}

export function selectOrganizationSelected(state) {
  return selectState(state).filtersSelected.organization_id;
}

export function selectStatusSelected(state) {
  return selectState(state).filtersSelected.status;
}

export function selectImporterSelected(state) {
  return selectState(state).filtersSelected.importer;
}

export function selectDateFromSelected(state) {
  return selectState(state).filtersSelected.date_from;
}

export function selectDateToSelected(state) {
  return selectState(state).filtersSelected.date_to;
}

export function selectFiltersSelected(state) {
  return selectState(state).filtersSelected;
}

export function selectSearch(state) {
  return selectState(state).filtersSelected.identifier;
}

export function selectPagination(state) {
  return selectState(state).pagination;
}
