/**
 * An implementation of mergeProps that separates actions passed into
 * mapDispatchToProps into props.actions instead of merging them into component
 * props.
 */
export const separateActions = (
  stateProps: any,
  dispatchProps: any,
  ownProps: any
) => ({
  ...stateProps,
  ...ownProps,
  actions: dispatchProps,
});
