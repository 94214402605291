import {
  RuleSet,
  ValidationRuleStatus,
} from 'modules/validation-dashboard/types';

export type RuleInEdition = {
  name: string;
  ruleEntityType: number;
  ruleSet?: RuleSet;
};

type PostableRule = Omit<RuleInEdition, 'ruleSet' | 'name'> & {
  ruleSet: { id: number };

  entityType: number;
  template: { type: 'required' };
  status: ValidationRuleStatus;
  bypassable: boolean;
  errorMessage: string;
};

export const ruleIsIncomplete = ({ name, ruleSet }: RuleInEdition): boolean =>
  !name || !ruleSet;

export const makePostableRule = (rule: RuleInEdition): PostableRule => {
  const { name, ruleSet, ruleEntityType } = rule;
  if (!ruleSet) throw "can't make postable rule from incomplete rule";
  return {
    ruleSet: { id: ruleSet.id },
    ruleEntityType,
    entityType: ruleEntityType,
    bypassable: false,
    errorMessage: name, // temporary until name is receivable by the back
    status: ValidationRuleStatus.Disabled,
    template: { type: 'required' },
  };
};
