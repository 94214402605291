import { MODULE_REDUCER_KEY } from 'constants/reducer';
import { ORGANIZATION_TYPE_RETAILER } from 'constants/organization';
import { ORGANIZATION_PAGE_STORE_KEY, INFOS_REDUCER_KEY } from '../constants';

export function selectState(state) {
  return state[MODULE_REDUCER_KEY][ORGANIZATION_PAGE_STORE_KEY][
    INFOS_REDUCER_KEY
  ];
}

export function selectOrganization(state) {
  return selectState(state).get('organization');
}

export function selectEditedOrganization(state) {
  return selectState(state).get('editedOrganization');
}

export function selectEditedOrSourceOrganizationField(state, fieldName) {
  const editedOrg = selectEditedOrganization(state);
  if (editedOrg.has(fieldName)) {
    return editedOrg.get(fieldName);
  }
  return selectOrganization(state).get(fieldName);
}

export function selectOrganizationField(state, fieldName) {
  return selectOrganization(state).get(fieldName);
}

export function hasChange(state) {
  return selectEditedOrganization(state).size > 0;
}

export function selectIsRetailer(state) {
  return (
    selectOrganizationField(state, 'type') === ORGANIZATION_TYPE_RETAILER.id
  );
}
