import { ReferentialAdminApi } from '@alkem/sdk-dashboard';

export const immutableReferentialAdminApi = new ReferentialAdminApi({
  immutable: true,
  cacheEnabled: false,
  acceptLanguage: 'en',
});

export default new ReferentialAdminApi({
  cacheEnabled: false,
  acceptLanguage: 'en-US',
});
