import { call, put, select, takeLatest } from 'redux-saga/effects';
import { withCatch } from 'utils/saga';
import { isNumber } from 'lodash/fp';
import {
  SET_ORGANIZATION,
  SAVE_ORGANIZATION_FIELD_GROUP,
  CREATE_ORGANIZATION_FIELD_GROUP,
  ORGANIZATION_FIELD_GROUP_LOADED,
  DELETE_ORGANIZATION_FIELD_GROUP,
  FIELD_GROUPS_FOR_IMPORT_LOADED,
  LOAD_FIELD_GROUPS_FOR_IMPORT,
  EXPORT_ORGANIZATION_FIELD_GROUP,
  IMPORT_ORGANIZATION_FIELD_GROUP,
  ORGANIZATION_LOADED,
  DEFAULT_ORGANIZATION_GROUP_LOADED,
  SET_DEFAULT_ORGANIZATION_GROUP,
} from './constants';
import {
  selectOrganization,
  selectGroups,
  selectGroupsForImport,
} from './selectors';
import { notificationSuccess } from 'actions/notifications';
import {
  createOrganizationGroup,
  listOrganizationGroup,
  updateOrganizationGroup,
  deleteOrganizationGroup,
  loadOrganization,
  getDefaultOrganizationGroup,
  setDefaultOrganizationGroup,
} from './api';
import { Organization, OrganizationGroupField } from './type';

function* createOrganizationFieldGroup({
  organizationId,
  name,
}: {
  name: string;
  organizationId: number;
}) {
  const { data: group } = yield call(
    createOrganizationGroup,
    organizationId,
    name
  );
  yield put(notificationSuccess('Organization field group has been created.'));
  yield call(loadOrganizationFieldGroups);
  return group;
}

function* saveOrganizationFieldGroup({ group }) {
  yield call(updateOrganizationGroup, group.id, {
    id: group.id,
    name: group.name,
    fields: group.fields.filter((field) => field.name && isNumber(field.root)),
  });
  yield put(notificationSuccess('Organization field group has been saved.'));
  yield call(loadOrganizationFieldGroups);
}

function findUniqueGroupName(groupName, existingGroups = [], i = 1) {
  if (i === 1 && !existingGroups.find(({ name }) => groupName === name)) {
    return groupName;
  }
  const dedupeGroupName = `${groupName} (${i})`;
  if (!existingGroups.find(({ name }) => dedupeGroupName === name)) {
    return dedupeGroupName;
  }
  return findUniqueGroupName(groupName, existingGroups, i + 1);
}

export function* importOrganizationFieldGroup({
  organization,
  group: fromGroup,
}: {
  group: OrganizationGroupField;
  organization: Organization;
}) {
  const groupName = `${organization.id} ${fromGroup.name}`;
  const existingGroups = yield select(selectGroups);
  const dedupeGroupName = findUniqueGroupName(groupName, existingGroups);
  const { data: group } = yield call(
    createOrganizationGroup,
    organization.id,
    dedupeGroupName,
    fromGroup.fields
  );
  yield put(notificationSuccess('Organization field group has been imported.'));
  yield call(loadOrganizationFieldGroups);
  return group;
}

export function* exportOrganizationFieldGroup({
  organization,
  group: fromGroup,
}: {
  group: OrganizationGroupField;
  organization: Organization;
}) {
  const groupName = `${organization.id} ${fromGroup.name}`;
  const existingGroups = yield select(selectGroupsForImport);
  const dedupeGroupName = findUniqueGroupName(groupName, existingGroups);
  const { data: group } = yield call(
    createOrganizationGroup,
    organization.id,
    dedupeGroupName,
    fromGroup.fields
  );
  yield put(notificationSuccess('Organization field group has been exported.'));
  yield call(loadOrganizationFieldGroups);
  return group;
}

function* deleteOrganizationFieldGroup({ fieldGroupIndex }) {
  const groups = yield select(selectGroups);
  yield call(deleteOrganizationGroup, groups[fieldGroupIndex].id);
  yield put(notificationSuccess('Organization field group has been deleted.'));
  yield call(loadOrganizationFieldGroups);
}

function* loadFieldGroupsByOrganizationId({ organizationId }) {
  const response = yield call(listOrganizationGroup, {
    organization_id: organizationId,
  });
  const groups = response.data.data;
  return {
    groups,
  };
}

export function* setOrganization({ organization: _organization }) {
  if (!_organization?.id) {
    return;
  }
  const organization = yield call(loadOrganization, _organization.id);
  yield put({
    type: ORGANIZATION_LOADED,
    organization,
  });
  yield call(loadOrganizationFieldGroups);
}

export function* loadOrganizationFieldGroups() {
  const organization = yield select(selectOrganization);
  if (!organization) {
    return;
  }
  const { groups } = yield call(loadFieldGroupsByOrganizationId, {
    organizationId: organization.id,
  });
  yield put({
    type: ORGANIZATION_FIELD_GROUP_LOADED,
    groups,
  });
  yield call(loadDefaultFieldOrganizationGroup, {
    organizationId: organization.id,
  });
}

function* loadFieldGroupsForImport({ organizationId }) {
  const { groups } = yield call(loadFieldGroupsByOrganizationId, {
    organizationId,
  });
  yield put({ type: FIELD_GROUPS_FOR_IMPORT_LOADED, groups });
}

function* loadDefaultFieldOrganizationGroup({
  organizationId,
}: {
  organizationId: number;
}) {
  const { data } = yield call(getDefaultOrganizationGroup, organizationId);
  yield put({
    type: DEFAULT_ORGANIZATION_GROUP_LOADED,
    data,
  });
}

function* setDefaultFieldOrganizationGroup({
  organizationId,
  fieldGroupId,
}: {
  organizationId: number;
  fieldGroupId: number;
}) {
  yield call(setDefaultOrganizationGroup, organizationId, fieldGroupId);
  yield put(notificationSuccess('Default group has been set.'));
  yield call(loadOrganizationFieldGroups);
}

export default function* mainSaga() {
  yield takeLatest(
    [SET_ORGANIZATION],
    withCatch(setOrganization, { withNotification: true })
  );
  yield takeLatest(
    [LOAD_FIELD_GROUPS_FOR_IMPORT],
    withCatch(loadFieldGroupsForImport, { withNotification: true })
  );
  yield takeLatest(
    [CREATE_ORGANIZATION_FIELD_GROUP],
    withCatch(createOrganizationFieldGroup, { withNotification: true })
  );
  yield takeLatest(
    [SAVE_ORGANIZATION_FIELD_GROUP],
    withCatch(saveOrganizationFieldGroup, { withNotification: true })
  );
  yield takeLatest(
    [DELETE_ORGANIZATION_FIELD_GROUP],
    withCatch(deleteOrganizationFieldGroup, { withNotification: true })
  );
  yield takeLatest(
    [IMPORT_ORGANIZATION_FIELD_GROUP],
    withCatch(importOrganizationFieldGroup, { withNotification: true })
  );
  yield takeLatest(
    [EXPORT_ORGANIZATION_FIELD_GROUP],
    withCatch(exportOrganizationFieldGroup, { withNotification: true })
  );
  yield takeLatest(
    [SET_DEFAULT_ORGANIZATION_GROUP],
    withCatch(setDefaultFieldOrganizationGroup, { withNotification: true })
  );
}
