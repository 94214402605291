import { Button } from '@alkem/react-ui-button';
import { Modal } from '@alkem/react-ui-modal';
import Image from 'components/ui/image';
import missingImg from 'images/missing.png';
import fetch from 'isomorphic-fetch';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import mediaApi from 'resources/mediaApi';
import { formatMediaUrl } from 'utils/media';
import AddOrganizationPicture from './add';
import './media-organization.scss';

export class MediaOrganizationCard extends PureComponent {
  static propTypes = {
    organizationId: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  };

  state = {
    deleteModalState: false,
    addPictureModalState: false,
    loadingDelete: false,
    displayDelete: true,
    invalidatingCache: false,
  };

  labels = {
    profile: 'Company Profile',
    mail: 'Email',
    square: 'Organization Logo',
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  openAddPictureModal = () => {
    this.setState({ addPictureModalState: true });
  };

  hideAddPictureModal = ({ success }) => {
    this.setState({
      addPictureModalState: false,
      ...(success && { displayDelete: true }),
    });

    this.invalidateMediaCache();
  };

  hideDeleteConfirmationModal = () => {
    this.setState({ deleteModalState: false, loadingDelete: false });
    this.invalidateMediaCache();
  };

  openDeleteConfirmationModal = () => {
    this.setState({ deleteModalState: true });
  };

  deletePicture = () => {
    const { organizationId, type } = this.props;
    this.setState({ loadingDelete: true, displayDelete: false });
    return mediaApi
      .OrganizationPictureDelete(organizationId, type)
      .then(this.hideDeleteConfirmationModal)
      .catch(this.hideDeleteConfirmationModal);
  };

  formatMediaUrl(organizationId, type) {
    if (type === 'mail') {
      return formatMediaUrl(
        `/mail/ressources/retailers/logo_${organizationId}.png?${Date.now()}`
      );
    }
    return formatMediaUrl(
      `/organization/picture/${organizationId}/${type}.png?${Date.now()}`
    );
  }

  invalidateMediaCache() {
    const { organizationId, type } = this.props;
    const mediaUrl = this.formatMediaUrl(organizationId, type);
    const invalidatingDone = () => {
      if (this._isMounted) {
        this.setState({ invalidatingCache: false });
      }
    };
    this.setState({ invalidatingCache: true });
    return fetch(mediaUrl, {
      method: 'GET',
      mode: 'no-cors',
      cache: 'reload',
    })
      .then(invalidatingDone)
      .catch(invalidatingDone);
  }

  render() {
    const { organizationId, type } = this.props;
    const { invalidatingCache } = this.state;
    return (
      <div className="MediaOrganization__card">
        <h3>{this.labels[type]}</h3>
        <div className="MediaOrganization__image">
          {!invalidatingCache && (
            <Image
              src={this.formatMediaUrl(organizationId, type)}
              fallbackSrc={missingImg}
              onError={() => this.setState({ displayDelete: false })}
            />
          )}
        </div>
        <div className="MediaOrganization__actions">
          {this.state.displayDelete && (
            <Button
              secondary
              content="Delete"
              onClick={this.openDeleteConfirmationModal}
            />
          )}
          <Button primary content="Upload" onClick={this.openAddPictureModal} />
        </div>
        {this.state.deleteModalState && (
          <Modal
            modalStyle="dynamic"
            title="Delete picture"
            isProcessing={this.state.loadingDelete}
            onConfirm={this.deletePicture}
            onClose={this.hideDeleteConfirmationModal}
          >
            Are you sure you want to delete this picture ?
          </Modal>
        )}
        {this.state.addPictureModalState && (
          <AddOrganizationPicture
            onClose={this.hideAddPictureModal}
            organizationId={organizationId}
            type={type}
          />
        )}
      </div>
    );
  }
}
export default MediaOrganizationCard;
