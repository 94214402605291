import PropTypes from 'prop-types';
import React, { Component } from 'react';
import missingImg from 'images/missing.png';

class Image extends Component {
  static propTypes = {
    src: PropTypes.string.isRequired,
    fallbackSrc: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
    onError: PropTypes.func,
  };

  static defaultProps = {
    maxWidth: '100%',
    maxHeight: '100%',
    fallbackSrc: null,
    className: '',
  };

  constructor(props) {
    super(props);
    this.handleError = this.handleError.bind(this);
    this.state = {
      displayFallback: false,
      displayMissingImage: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { displayMissingImage } = nextState.displayMissingImage;
    if (displayMissingImage !== this.state.displayMissingImage) {
      return true;
    }
    return Object.keys(nextProps).some(
      (key) => nextProps[key] !== this.props[key]
    );
  }

  componentDidUpdate(prevProps) {
    const { src } = this.props;
    if (src !== prevProps.src) {
      this.setState({ displayMissingImage: false }); // eslint-disable-line
    }
  }

  handleError() {
    const { displayFallback } = this.state;
    this.setState({
      displayMissingImage: displayFallback,
      displayFallback: true,
    });
    this.props.onError();
  }

  convertNumber(value) {
    if (typeof value === 'number' || parseInt(value, 10).toString() === value) {
      return `${value}px`;
    }
    return value;
  }

  render() {
    const { width, height, maxWidth, maxHeight, className, src, fallbackSrc } =
      this.props;
    const { displayMissingImage, displayFallback } = this.state;

    const styles = {
      maxHeight: this.convertNumber(maxHeight),
      maxWidth: this.convertNumber(maxWidth),
    };

    let imgSrc;
    if (displayMissingImage) {
      imgSrc = missingImg;
    } else if (displayFallback) {
      imgSrc = fallbackSrc;
    } else {
      imgSrc = src;
    }

    return (
      <img
        src={imgSrc}
        width={width}
        height={height}
        style={styles}
        onError={this.handleError}
        className={className}
        alt=""
      />
    );
  }
}

export default Image;
