import { Button } from '@alkem/react-ui-button';
import { Spinner } from '@alkem/react-ui-spinner';
import ListController from 'components/ui/list/controller';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { map } from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { separateActions } from 'utils/redux';
import { PERMISSION_PRODUCT_EXPORT } from 'modules/access-policy/common/constants';
import { selectPermissions } from 'modules/auth/reducer';
import { hasFullPower } from 'utils/user';

import {
  changeLimit,
  getExportList,
  nextPage,
  openGDSNExportModal,
  openModal,
  openPotentialSupplierModal,
  previousPage,
} from '../actions';
import ExportFileModal from '../modal';
import { ExportPotentialSupplierFileModal } from '../modal/potentialSupplier';
import GDSNExportFileModal from '../modal/gdsn';
import ViewJsonModal from '../modal/viewJson';
import {
  selectList,
  selectPagination,
  selectState,
  selectIsModalOpen,
  selectIsPotentialSupplierModalOpen,
  selectFilters,
  selectIsViewJsonModalOpen,
} from '../selectors';
import './list.scss';
import ExportListRow from './row';

const mapStateToProps = (state) => ({
  list: selectList(state),
  isLoading: selectState(state).isLoading,
  filters: selectFilters(state),
  pagination: selectPagination(state),
  user: state.user,
  isExportModalOpen: selectIsModalOpen(state),
  isViewJsonModalOpen: selectIsViewJsonModalOpen(state),
  isExportSupplierModalOpen: selectIsPotentialSupplierModalOpen(state),
  userPermissions: selectPermissions(state),
});

const mapDispatchToProps = {
  getExportList,
  nextPage,
  previousPage,
  changeLimit,
  openModal,
  openPotentialSupplierModal,
  openGDSNExportModal,
};

export class ExportList extends PureComponent {
  static propTypes = {
    list: PropTypes.array.isRequired,
    isLoading: PropTypes.bool,
    filters: PropTypes.object.isRequired,
    pagination: PropTypes.object.isRequired,
    user: map.isRequired,
    isExportModalOpen: PropTypes.bool.isRequired,
    isViewJsonModalOpen: PropTypes.bool.isRequired,
    isExportSupplierModalOpen: PropTypes.bool.isRequired,
    userPermissions: PropTypes.object.isRequired,
    actions: PropTypes.shape({
      getExportList: PropTypes.func.isRequired,
      nextPage: PropTypes.func.isRequired,
      previousPage: PropTypes.func.isRequired,
      changeLimit: PropTypes.func.isRequired,
      openModal: PropTypes.func.isRequired,
      openPotentialSupplierModal: PropTypes.func.isRequired,
      openGDSNExportModal: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    isLoading: true,
  };

  componentDidMount() {
    this.props.actions.getExportList({ replaceHistory: true });
  }

  openModal = () => {
    this.props.actions.openModal(null);
  };
  openPotentialSupplierModal = () => {
    this.props.actions.openPotentialSupplierModal(null);
  };

  renderList() {
    const { list, isLoading } = this.props;
    if (isLoading) {
      return (
        <div className="ExportList__spinner">
          <Spinner loading big />
        </div>
      );
    } else if (list.length === 0) {
      return (
        <div className="ExportList__spinner">
          <h3>
            No export matching your criterion...{' '}
            <i className="mdi mdi-heart-broken" />
          </h3>
        </div>
      );
    }
    return list.map((exportFile) => (
      <ExportListRow
        key={exportFile.id}
        exportFile={exportFile}
        filters={this.props.filters}
      />
    ));
  }

  render() {
    const { pagination, actions, userPermissions, user } = this.props;
    const canExport =
      hasFullPower(user) ||
      userPermissions.organizations.includes(PERMISSION_PRODUCT_EXPORT);
    const { currentPage, totalPages, limit, totalResults } = pagination;
    return (
      <div>
        <ListController
          actions={
            <div>
              {canExport && (
                <Button onClick={this.props.actions.openGDSNExportModal}>
                  <i className="mdi mdi-sim" /> GDSN
                </Button>
              )}
              <span className="ExportFileList__sep" />
              {canExport && (
                <Button onClick={this.openModal}>
                  <i className="mdi mdi-file-export" />
                </Button>
              )}
              <span className="ExportFileList__sep" />
              {canExport && (
                <Button onClick={this.openPotentialSupplierModal}>
                  <i className="mdi mdi-file-export" /> SUPPLIERS
                </Button>
              )}
              <span className="ExportFileList__sep" />
              <Button onClick={this.props.actions.getExportList}>
                <i className="mdi mdi-refresh" />
              </Button>
            </div>
          }
          onNext={actions.nextPage}
          onPrev={actions.previousPage}
          currentPage={currentPage}
          totalPages={totalPages}
          limit={limit}
          totalResults={totalResults}
          onLimitChange={actions.changeLimit}
          rowsLength={0}
          type="ExportList"
        >
          {this.renderList()}
        </ListController>
        {this.props.isExportModalOpen && <ExportFileModal />}
        {this.props.isViewJsonModalOpen && <ViewJsonModal />}
        {this.props.isExportSupplierModalOpen && (
          <ExportPotentialSupplierFileModal />
        )}
        {canExport && <GDSNExportFileModal />}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(ExportList);
