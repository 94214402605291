import { Textarea } from '@alkem/react-ui-inputs';
import { SimpleSelect } from '@alkem/react-ui-select';
import { CountryAutocomplete } from 'components/autocomplete';
import {
  ORGANIZATION_TYPE_MAPPING,
  ORGANIZATION_TYPE_RETAILER,
  SOURCES,
  STATUS_ACTIVE,
  STATUS_DECOMMISSIONED,
  STATUS_INACTIVE,
} from 'constants/organization';
import { fromJS } from 'immutable';
import { PERMISSION_PLATFORM_ORGANIZATIONGROUP_UPDATE } from 'modules/access-policy/common/constants';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { isImmutable } from 'utils/immutable';
import { separateActions } from 'utils/redux';

import { selectUserHasPlatformPermission } from 'modules/auth/selectors';
import { selectOrganizationId } from 'modules/organization-page/selectors/settings';
import { formatTime, TIME_FORMAT_MINUTE_LOCALIZED } from 'utils/time';
import {
  addTargetMarket,
  createGroup,
  removeTargetMarket,
  updateInfosField,
} from '../../actions/infos';
import {
  hasChange,
  selectEditedOrSourceOrganizationField,
  selectOrganizationField,
} from '../../selectors/infos';
import './index.scss';
import MediaOrganizationCard from './media-organization/card';
import OrganizationGroup from './organization-group';

const mapStateToProps = (state) => ({
  organizationId: selectOrganizationId(state),
  organizationInfosEdited: hasChange(state),
  nameLegal: selectOrganizationField(state, 'nameLegal'),
  type: selectOrganizationField(state, 'type'),
  status: selectOrganizationField(state, 'status'),
  network: selectOrganizationField(state, 'network'),
  group: selectEditedOrSourceOrganizationField(state, 'group'),
  canUpdateGroup: selectUserHasPlatformPermission(
    PERMISSION_PLATFORM_ORGANIZATIONGROUP_UPDATE,
    state
  ),
  worksOnTargetMarkets: selectEditedOrSourceOrganizationField(
    state,
    'worksOnTargetMarkets'
  ),
  supportsLocales: selectEditedOrSourceOrganizationField(
    state,
    'supportsLocales'
  ),
  identifierLegal: selectOrganizationField(state, 'identifierLegal'),
  address: selectOrganizationField(state, 'address'),
  identifierCity: selectOrganizationField(state, 'identifierCity'),
  postCode: selectOrganizationField(state, 'postCode'),
  country: selectOrganizationField(state, 'country'),
  createdAt: selectOrganizationField(state, 'createdAt'),
  user: state.user,
  source: selectEditedOrSourceOrganizationField(state, 'source'),
  help: selectEditedOrSourceOrganizationField(state, 'help'),
  decommissionReason: selectOrganizationField(state, 'decommission_reason'),
});

const mapDispatchToProps = {
  createGroup,
  addTargetMarket,
  removeTargetMarket,
  updateInfosField,
};

export class OrganizationPageInfos extends PureComponent {
  static propTypes = {
    organizationId: PropTypes.number.isRequired,
    organizationInfosEdited: PropTypes.bool,
    nameLegal: PropTypes.string,
    type: PropTypes.number,
    status: PropTypes.number,
    network: ImmutablePropTypes.map,
    group: ImmutablePropTypes.map,
    canUpdateGroup: PropTypes.bool,
    worksOnTargetMarkets: ImmutablePropTypes.list,
    supportsLocales: ImmutablePropTypes.list,
    identifierLegal: PropTypes.string,
    address: PropTypes.string,
    identifierCity: PropTypes.string,
    postCode: PropTypes.string,
    country: PropTypes.string,
    createdAt: PropTypes.string,
    actions: PropTypes.shape({
      createGroup: PropTypes.func.isRequired,
      addTargetMarket: PropTypes.func.isRequired,
      removeTargetMarket: PropTypes.func.isRequired,
      updateInfosField: PropTypes.func.isRequired,
    }).isRequired,
    user: ImmutablePropTypes.map,
    source: PropTypes.number,
    help: PropTypes.string,
    decommissionReason: PropTypes.string,
    isReadOnly: PropTypes.bool,
  };

  onCreateGroup = async (name) => {
    const response = await this.props.actions.createGroup(name);
    this.onGroupSelect(fromJS(response.data));
  };

  onGroupSelect = (group) => {
    this.props.actions.updateInfosField({
      path: ['group'],
      value: group,
    });
  };

  onTargetMarketSelect = (countries = []) => {
    this.props.worksOnTargetMarkets.forEach((country) => {
      if (!countries.find((c) => c.id === country.id)) {
        this.props.actions.removeTargetMarket(country);
      }
    });
    countries.forEach((country) => {
      if (!this.props.worksOnTargetMarkets.find((c) => c.id === country.id)) {
        this.props.actions.addTargetMarket(fromJS(country));
      }
    });
  };

  onSourceSelect = (source) => {
    this.props.actions.updateInfosField({
      value: source.id,
      path: ['source'],
    });
  };

  onHelpChanged = (event) => {
    this.props.actions.updateInfosField({
      value: event.target.value,
      path: ['help'],
    });
  };

  isRetailer() {
    const { type } = this.props;
    return type === ORGANIZATION_TYPE_RETAILER.id;
  }

  formatNetwork(network) {
    if (!network) {
      return null;
    }
    return `${network.get('name')} (${network.get('id')})`;
  }

  formatStatus(status) {
    if (status === STATUS_ACTIVE) {
      return <span className="OrganizationPageInfo__value--green">ACTIVE</span>;
    } else if (status === STATUS_INACTIVE) {
      return <span className="OrganizationPageInfo__value--red">INACTIVE</span>;
    } else if (status === STATUS_DECOMMISSIONED) {
      return (
        <span className="OrganizationPageInfo__value--bold">
          DECOMMISSIONED
        </span>
      );
    }
    return 'UNKNOWN';
  }

  formatType(type) {
    const typeDef = ORGANIZATION_TYPE_MAPPING[type];
    return `${typeDef ? typeDef.name : 'afUNKNOWN'} (${type})`;
  }

  formatValueForAutocomplete(value) {
    if (!value) {
      return [];
    }
    return (isImmutable(value) ? value.toJS() : value).map(({ id }) => id);
  }

  formatGroup(group) {
    if (!group) {
      return [];
    }
    return [{ ...group.toJS(), label: group.get('name') }];
  }

  formatLocales(locales) {
    if (!locales) {
      return '';
    }
    return locales
      .map((locale) => ` ${locale.get('label')} (${locale.get('code')})`)
      .join();
  }

  renderSimpleField(label, value) {
    return (
      <li className="OrganizationPageInfo__row">
        <div className="OrganizationPageInfo__label">{label}</div>
        <div className="OrganizationPageInfo__value">{value}</div>
      </li>
    );
  }

  renderLogo(organizationId) {
    return (
      <li className="OrganizationPageInfo__row">
        <div className="OrganizationPageInfo__label">Logo</div>
        <div className="OrganizationPageInfo__value__logo alk-flex">
          <MediaOrganizationCard
            organizationId={organizationId}
            type="profile"
          />
          <MediaOrganizationCard organizationId={organizationId} type="mail" />
          <MediaOrganizationCard
            organizationId={organizationId}
            type="square"
          />
        </div>
      </li>
    );
  }

  renderGroup() {
    if (!this.isRetailer()) {
      return null;
    }
    const { group, canUpdateGroup, isReadOnly } = this.props;
    // Group is updatable if not set and user is superadmin, or PA with group.update
    const enabled = !isReadOnly && !group && canUpdateGroup;
    return (
      <li className="OrganizationPageInfo__row">
        <div className="OrganizationPageInfo__label">Group</div>
        <div className="OrganizationPageInfo__value">
          <OrganizationGroup
            id="organization-create-group"
            group={group}
            disabled={!enabled}
            onSelect={this.onGroupSelect}
            onCreate={this.onCreateGroup}
          />
        </div>
      </li>
    );
  }

  renderTargetMarketDropdown() {
    const { worksOnTargetMarkets, user, isReadOnly } = this.props;
    return (
      <li className="OrganizationPageInfo__row">
        <div className="OrganizationPageInfo__label">Target Market</div>
        <div className="OrganizationPageInfo__value">
          {worksOnTargetMarkets && (
            <CountryAutocomplete
              id="organization-create-country-autocomplete"
              disabled={isReadOnly}
              value={this.formatValueForAutocomplete(
                this.props.worksOnTargetMarkets
              )}
              onChange={this.onTargetMarketSelect}
              user={user}
              isSearchable
              isClearable={false}
              isMulti
            />
          )}
        </div>
      </li>
    );
  }

  renderSource() {
    const { source, isReadOnly } = this.props;
    return (
      <li className="OrganizationPageInfo__row">
        <div className="OrganizationPageInfo__label">Source</div>
        <div className="OrganizationPageInfo__value">
          <SimpleSelect
            id="organization-create-source-select"
            options={SOURCES}
            value={SOURCES.find((s) => s.id === source)}
            onSelect={this.onSourceSelect}
            disabled={isReadOnly}
            autoSize
          />
        </div>
      </li>
    );
  }

  renderHelp() {
    if (!this.isRetailer()) {
      return null;
    }
    const { help, isReadOnly } = this.props;
    return (
      <li className="OrganizationPageInfo__row">
        <div className="OrganizationPageInfo__label">Help</div>
        <div className="OrganizationPageInfo__value">
          <Textarea
            id="organization-help-textarea"
            value={help}
            placeholder="Help (in English) to be displayed in publication page when this organization is a recipient."
            onChange={this.onHelpChanged}
            rows={2}
            disabled={isReadOnly}
          />
        </div>
      </li>
    );
  }

  render() {
    return (
      <div className="OrganizationPageInfo OrganizationPageBlock">
        <div className="OrganizationPageBlock__header">
          <h2>Infos</h2>
          <span className="OrganizationPageBlock__edited">
            {this.props.organizationInfosEdited && (
              <span>
                <i className="mdi mdi-alert" />
                Edited
              </span>
            )}
          </span>
        </div>
        <ul className="OrganizationPageInfo__list">
          {this.renderLogo(this.props.organizationId)}
          {this.renderSimpleField('Name Legal', this.props.nameLegal)}
          {this.renderSimpleField('Type', this.formatType(this.props.type))}
          {this.renderSimpleField(
            'Status',
            this.formatStatus(this.props.status)
          )}
          {this.props.status === STATUS_DECOMMISSIONED &&
            this.renderSimpleField(
              'Decommission reason',
              this.props.decommissionReason
            )}
          {this.renderSimpleField(
            'Network',
            this.formatNetwork(this.props.network)
          )}
          {this.renderGroup()}
          {this.renderTargetMarketDropdown()}
          {this.renderSimpleField(
            'Locales',
            this.formatLocales(this.props.supportsLocales)
          )}
          {this.renderSimpleField('Identifier', this.props.identifierLegal)}
          {this.renderSimpleField('Address', this.props.address)}
          {this.renderSimpleField('City', this.props.identifierCity)}
          {this.renderSimpleField('PostCode', this.props.postCode)}
          {this.renderSimpleField('Country', this.props.country)}
          {this.renderSimpleField(
            'Created at',
            formatTime(this.props.createdAt, TIME_FORMAT_MINUTE_LOCALIZED)
          )}
          {this.renderSource()}
          {this.renderHelp()}
        </ul>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(OrganizationPageInfos);
