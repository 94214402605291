import { notificationError, notificationSuccess } from 'actions/notifications';
import { getProductKeyLabel } from 'components/autocomplete/custom/product-key-autocomplete';
import { fromJS, List, Map } from 'immutable';
import { set } from 'lodash/fp';
import { selectUserHasFullPower } from 'modules/auth/selectors';
import {
  EVENT_ACTIONS,
  EVENT_TYPES,
  FILTER_PRESETS,
  STATUSES,
} from 'modules/transaction/constants';
import qs from 'querystringify';
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { logError } from 'utils';
import history from 'utils/history';
import * as api from './api';
import * as events from './events';
import { baseFilters, selectState } from './reducer';

function* searchSaga(resetPagination) {
  yield put({ type: events.TRANSACTION_LOAD, isLoading: true });
  const pagination = yield select((state) => selectState(state).pagination);
  const filters = yield select((state) => selectState(state).filters);
  const superAdmin = yield select(selectUserHasFullPower);

  const { response, error } = yield call(
    api.search,
    filters.toJS(),
    pagination,
    resetPagination
  );
  yield put({ type: events.TRANSACTION_LOAD, isLoading: false });
  if (error) {
    yield put(notificationError(error));
    return;
  }

  const { data: list, totalResults, aggregations } = response.data;
  yield put({
    type: events.TRANSACTION_RECEIVE_LIST,
    list,
    totalResults,
    resetPagination,
  });
  yield put({
    type: events.TRANSACTION_RECEIVE_AGGREGATIONS,
    aggregations,
  });

  if (superAdmin) {
    const entities = list
      .reduce(
        (l, trx) => l.concat(trx.getIn(['metadata', 'entities']) || List()),
        List()
      )
      .toList();
    yield put({
      type: events.TRANSACTION_FETCH_EXTRAS,
      entities,
    });
  }
}

function* applySaga({ transaction, action }) {
  const { error } = yield call(api.apply, transaction.get('id'), action);
  if (error) {
    yield put(notificationError(error));
    return;
  }

  yield put({ type: events.TRANSACTION_ACTION_MODAL_CLOSE });
  yield put(notificationSuccess('It was a success'));
}

function* reindexSaga({ transactionId }) {
  const { error } = yield call(api.index, [transactionId]);
  if (error) {
    yield put(notificationError(error));
    return;
  }
  yield put(notificationSuccess('It was a success'));
}

function* fetchOrganizations({ entities }) {
  const organizationIds = entities
    .filter((e) => e.get('type').toLowerCase() === 'organization')
    .map((e) => Number(e.get('id')))
    .toSet()
    .toJS();

  if (organizationIds.length === 0) {
    yield put({
      type: events.TRANSACTION_RECEIVE_EXTRAS,
      key: 'products',
      data: {},
    });
    return;
  }

  const { response, error } = yield call(api.getOrganizations, organizationIds);
  if (error) {
    yield put(notificationError(error));
    return;
  }

  const organizations = response.data.data.reduce(
    (m, o) => m.set(o.get('id'), o),
    Map()
  );

  yield put({
    type: events.TRANSACTION_RECEIVE_EXTRAS,
    key: 'organizations',
    data: organizations,
  });
}

function* fetchProducts({ entities }) {
  const productIds = entities
    .filter((e) => e.get('type').toLowerCase() === 'product')
    .map((e) => Number(e.get('id')))
    .toSet()
    .toJS();

  if (productIds.length === 0) {
    yield put({
      type: events.TRANSACTION_RECEIVE_EXTRAS,
      key: 'products',
      data: {},
    });
    return;
  }

  const { response, error } = yield call(api.getProducts, productIds);
  if (error) {
    yield put(
      notificationError(`error retrieving products information: ${error}`)
    );
    return;
  }

  const products = response.data.data.reduce(
    (m, p) => m.set(p.getIn(['specializes', 'id']), p),
    Map()
  );

  yield put({
    type: events.TRANSACTION_RECEIVE_EXTRAS,
    key: 'products',
    data: products,
  });
}

function* fetchExtras({ entities }) {
  const productKeyIds = entities
    .filter((e) => e.get('type').toLowerCase() === 'productkey')
    .map((e) => Number(e.get('id')))
    .toSet()
    .toArray();

  let enrichedEntities = entities;
  if (productKeyIds.length > 0) {
    const { response, error } = yield call(
      api.listProductKeysFromIds,
      productKeyIds
    );
    if (error) {
      yield put(
        notificationError(`error retrieving product keys information: ${error}`)
      );
      return;
    }

    const productKeys = response.data;
    yield put({
      type: events.TRANSACTION_RECEIVE_EXTRAS,
      key: 'productKeys',
      data: productKeys.reduce((acc, pk) => set(pk.id, pk, acc), {}),
    });

    enrichedEntities = enrichedEntities
      .concat(
        fromJS(productKeys.map((k) => ({ type: 'product', id: k.product_id })))
      )
      .concat(
        fromJS(
          productKeys.map((k) => ({
            type: 'organization',
            id: k.organization_id,
          }))
        )
      );
  }

  yield all([
    call(fetchOrganizations, { entities: enrichedEntities }),
    call(fetchProducts, { entities: enrichedEntities }),
  ]);
}

// Checks
const notEmpty = (l) => l.size > 0;
const present = (o) => o && o.get('id') && o.get('id') > 0;

// Transforms
const toArray = (l) => l.toArray();
const toId = (o) => o.get('id');
const toCompactEntity = (role) => (l) =>
  l
    .map((e) => {
      const split = e.split(' ');
      return `${split[0]}:${parseInt(split[1], 10)}:${role}`;
    })
    .toArray();

// Parsers
const ident = (x) => x;
const fromCommaSeparated = (mapFunc) => (s) =>
  fromJS(s.split(',')).map(mapFunc);
const fromId = (id) => ({ id: parseInt(id, 10) });
const fromCompactEntity = (entities) =>
  fromCommaSeparated((ce) => {
    const split = ce.split(':');
    return `${split[0]} ${split[1]}`;
  })(entities);

// Formatters
const formatList = (filterType, formatLabelFunc) => (list) =>
  list.map((elt) =>
    fromJS({
      filterType,
      filterId: elt,
      filterLabel: formatLabelFunc(elt),
    })
  );
const formatStatuses = formatList(
  'statuses',
  (elt) => `Status: ${STATUSES[elt].label}`
);
const formatEventTypes = formatList(
  'eventTypes',
  (elt) => `Event type: ${EVENT_TYPES[elt]}`
);
const formatEventActions = formatList(
  'eventActions',
  (elt) => `Event action: ${EVENT_ACTIONS[elt]}`
);
const formatFromId = (filterType) => (obj) => {
  if (!obj || !obj.get('id')) {
    return List();
  }

  return fromJS([
    {
      filterType,
      filterId: obj.get('id'),
      filterLabel: obj.get('name') || `${filterType}: ${obj.get('id')}`,
    },
  ]);
};
const formatSourceEntities = formatList(
  'sourceEntities',
  (elt) => `From: ${elt}`
);
const formatTargetEntities = formatList(
  'targetEntities',
  (elt) => `To: ${elt}`
);

const filterFuncs = [
  {
    key: 'q',
    check: (q) => q && q.length > 0,
    transform: ident,
    parse: ident,
  },
  {
    key: 'preset',
    check: (p) => p && p.get('key') !== 'custom',
    transform: (p) => p.get('key'),
    parse: (key) => fromJS(FILTER_PRESETS.find((fp) => fp.key === key)),
  },
  {
    key: 'statuses',
    check: notEmpty,
    transform: toArray,
    parse: fromCommaSeparated(ident),
    format: formatStatuses,
  },
  {
    key: 'eventTypes',
    check: notEmpty,
    transform: toArray,
    parse: fromCommaSeparated(ident),
    format: formatEventTypes,
  },
  {
    key: 'eventActions',
    check: notEmpty,
    transform: toArray,
    parse: fromCommaSeparated(ident),
    format: formatEventActions,
  },

  {
    key: 'sourceOrganization',
    check: present,
    transform: toId,
    parse: fromId,
    format: formatFromId('sourceOrganization'),
  },
  {
    key: 'targetOrganization',
    check: present,
    transform: toId,
    parse: fromId,
    format: formatFromId('targetOrganization'),
  },
  {
    key: 'sourceProductKey',
    check: present,
    transform: toId,
    parse: fromId,
    format: formatFromId('sourceProductKey'),
  },
  {
    key: 'targetProductKey',
    check: present,
    transform: toId,
    parse: fromId,
    format: formatFromId('targetProductKey'),
  },

  {
    key: 'sourceEntities',
    check: notEmpty,
    transform: toCompactEntity(0),
    parse: fromCompactEntity,
    format: formatSourceEntities,
  },
  {
    key: 'targetEntities',
    check: notEmpty,
    transform: toCompactEntity(1),
    parse: fromCompactEntity,
    format: formatTargetEntities,
  },

  {
    key: 'createdAtFrom',
    check: present,
    transform: toId,
    parse: fromId,
    format: formatFromId('createdAtTo'),
  },
  {
    key: 'createdAtTo',
    check: present,
    transform: toId,
    parse: fromId,
    format: formatFromId('createdAtFrom'),
  },
  {
    key: 'dataType',
    check: (o) => !!o,
    transform: (o) => o,
    parse: (o) => o,
    format: (o) =>
      o
        ? fromJS([
            {
              filterType: 'dataType',
              filterId: o,
              filterLabel: `Data type: ${o}`,
            },
          ])
        : fromJS([]),
  },
];

function cleanFilters(filters) {
  const clean = {};

  filterFuncs.forEach(({ key, check, transform }) => {
    if (check(filters.get(key))) {
      clean[key] = transform(filters.get(key));
    }
  });

  return clean;
}

function* saveFiltersInURL() {
  let filters = yield select((state) => selectState(state).filters);
  filters = yield call(cleanFilters, filters);

  yield call(history.replace, {
    pathname: history.location.pathname,
    query: filters,
    search: qs.stringify(filters),
  });
}

function parseFilters(urlFilters) {
  const filters = {};
  Object.keys(urlFilters).forEach((key) => {
    const func = filterFuncs.find((f) => f.key === key);
    if (!func || !func.parse) {
      return;
    }

    filters[key] = func.parse(urlFilters[key]);
  });

  return filters;
}

function* loadFiltersFromURL() {
  const urlFilters = qs.parse(history.location.search);

  let filters = fromJS(parseFilters(urlFilters));

  if (filters.getIn(['sourceOrganization', 'id'])) {
    const { response } = yield call(api.getOrganizations, [
      filters.getIn(['sourceOrganization', 'id']),
    ]);
    if (response && response.data.data.size > 0) {
      const orgs = response.data.data;
      filters = filters.setIn(
        ['sourceOrganization', 'name'],
        `From: ${orgs.getIn([0, 'nameLegal'])} - ${orgs.getIn([0, 'id'])}`
      );
    }
  }

  if (filters.getIn(['targetOrganization', 'id'])) {
    const { response } = yield call(api.getOrganizations, [
      filters.getIn(['targetOrganization', 'id']),
    ]);
    if (response && response.data.data.size > 0) {
      const orgs = response.data.data;
      filters = filters.setIn(
        ['targetOrganization', 'name'],
        `To: ${orgs.getIn([0, 'nameLegal'])} - ${orgs.getIn([0, 'id'])}`
      );
    }
  }

  if (filters.getIn(['sourceProductKey', 'id'])) {
    const { response } = yield call(api.getProductKeys, [
      filters.getIn(['sourceProductKey', 'id']),
    ]);
    if (response && response.data.data.size > 0) {
      const products = response.data.data;
      filters = filters.setIn(
        ['sourceProductKey', 'name'],
        `From: ${getProductKeyLabel(products.first())}`
      );
    }
  }

  if (filters.getIn(['targetProductKey', 'id'])) {
    const { response } = yield call(api.getProductKeys, [
      filters.getIn(['targetProductKey', 'id']),
    ]);
    if (response && response.data.data.size > 0) {
      const products = response.data.data;
      filters = filters.setIn(
        ['targetProductKey', 'name'],
        `To: ${getProductKeyLabel(products.first())}`
      );
    }
  }

  yield put({
    type: events.TRANSACTION_FILTERS_LOADED,
    filters: baseFilters.merge(filters),
  });
}

function* computeSelectedFilters() {
  const filters = yield select((state) => selectState(state).filters);
  const selectedFilters = filters.reduce((l, filter, key) => {
    const func = filterFuncs.find((f) => f.key === key);
    if (!func || !func.format) {
      return l;
    }

    return l.concat(func.format(filter));
  }, List());

  yield put({
    type: events.TRANSACTION_UPDATE_SELECTED_FILTERS,
    selectedFilters,
  });
}

function* restoreHierarchySaga({ transaction }) {
  const { error } = yield call(api.restoreHierarchies, [transaction.get('id')]);
  if (error) {
    logError(error);
    yield put(notificationError(error));
  } else {
    yield put(
      notificationSuccess('It was a success! You can refresh the list')
    );
  }
}

export default function* mainSaga() {
  yield takeLatest(
    [
      events.TRANSACTION_SEARCH,
      events.TRANSACTION_SEARCH_ID,
      events.TRANSACTION_ADD_FILTER,
      events.TRANSACTION_REMOVE_FILTER,
      events.TRANSACTION_CLEAR_FILTERS,
      events.TRANSACTION_FILTERS_LOADED,
      events.TRANSACTION_SELECT_PRESET,
    ],
    searchSaga.bind(this, true)
  );

  yield takeLatest(
    [
      events.TRANSACTION_SEARCH_ID,
      events.TRANSACTION_ADD_FILTER,
      events.TRANSACTION_REMOVE_FILTER,
      events.TRANSACTION_CLEAR_FILTERS,
      events.TRANSACTION_SELECT_PRESET,
      events.TRANSACTION_FILTERS_LOADED,
    ],
    computeSelectedFilters
  );

  yield takeLatest(
    [
      events.TRANSACTION_NEXT_PAGE,
      events.TRANSACTION_PREVIOUS_PAGE,
      events.TRANSACTION_CHANGE_LIMIT,
    ],
    searchSaga.bind(this, false)
  );

  yield takeEvery([events.TRANSACTION_ACTION_MODAL_APPLY], applySaga);
  yield takeEvery([events.TRANSACTION_REINDEX], reindexSaga);

  yield takeLatest([events.TRANSACTION_FETCH_EXTRAS], fetchExtras);

  yield takeLatest(
    [
      events.TRANSACTION_SEARCH_ID,
      events.TRANSACTION_ADD_FILTER,
      events.TRANSACTION_REMOVE_FILTER,
      events.TRANSACTION_CLEAR_FILTERS,
      events.TRANSACTION_SELECT_PRESET,
    ],
    saveFiltersInURL
  );

  yield takeLatest([events.TRANSACTION_LOAD_FILTERS], loadFiltersFromURL);

  yield takeEvery([events.TRANSACTION_RESTORE_HIERARCHY], restoreHierarchySaga);
}
