import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from '@alkem/react-ui-modal';

function StackTraceModal(props) {
  const { onCloseModal, stackTrace } = props;
  return (
    <Modal title="Info" modalStyle="dynamic" onClose={onCloseModal} hideFooter>
      <code>
        <pre>{stackTrace}</pre>
      </code>
    </Modal>
  );
}

StackTraceModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  stackTrace: PropTypes.string.isRequired,
};

export default StackTraceModal;
