import * as events from '../constants/events';

// Data events
export const getAttributeList = (attributeSearch) => ({
  type: events.ATTRIBUTE_LIST_FETCH,
  attributeSearch,
});

export const getAttribute = (selectedAttributeName) => ({
  type: events.ATTRIBUTE_FETCH,
  selectedAttributeName,
});

export const saveAttribute = (editedAttribute) => ({
  type: events.SAVE_ATTRIBUTE,
  editedAttribute,
});

export const getFunctionList = (functionSearch) => ({
  type: events.FUNCTION_LIST_FETCH,
  functionSearch,
});

export const getFunction = (selectedFunctionId) => ({
  type: events.FUNCTION_FETCH,
  selectedFunctionId,
});

export const getCategoryList = (categorySearch) => ({
  type: events.CATEGORY_LIST_FETCH,
  categorySearch,
});

export const getCategoryAttributeList = (selectedCategoryId) => ({
  type: events.CATEGORY_FETCH,
  selectedCategoryId,
});
