import { Button } from '@alkem/react-ui-button';
import { Tooltip } from '@alkem/react-ui-tooltip';
import InputWithLabel from 'components/input-with-label';
import { isEqual } from 'lodash';
import { Rule } from 'modules/validation-dashboard/types';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import { copyEntryValue } from './validation-conditions-editor';
import './validation-conditions.scss';

interface Props {
  rule: Rule;
  id: string;
}

function syntaxHighlight(json: string) {
  json = json
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;');
  json = json.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
    function (match) {
      let cls = 'number';
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = 'key';
        } else {
          cls = 'string';
        }
      } else if (/true|false/.test(match)) {
        cls = 'boolean';
      } else if (/null/.test(match)) {
        cls = 'null';
      }
      return `<span class="${cls}"> ${match}</span>`;
    }
  );
  return `<div>${json}</div>`;
}

export const ConditionDisplayItem = ({
  id,
  dict,
  withCopyButton = true,
}: {
  id: string;
  dict: { [key: string]: any };
  withCopyButton?: boolean;
}) => {
  // Inits the dict entries with dict
  const dictEntries = useMemo<
    {
      key: string;
      value: any;
      entryId: string;
    }[]
  >(
    () =>
      Object.entries(dict).map(([key, value]) => ({
        key,
        value,
        entryId: uuid(),
      })),
    [dict]
  );
  const dispatch = useDispatch();
  return (
    <div
      className="ConditionDisplayItem alk-flex alk-flex-column flex-grow--1"
      id={id}
    >
      {dictEntries.map(({ key, value, entryId }) => (
        <div
          key={`entry-${entryId}`}
          className="ConditionDisplayItem__display alk-flex alk-flex-row alk-flex-baseline"
        >
          <code>${key}</code>&nbsp;
          <span
            className="pre"
            dangerouslySetInnerHTML={{
              __html:
                typeof value === 'string'
                  ? value.trim()
                  : syntaxHighlight(JSON.stringify(value, undefined, 2)),
            }}
          />
          {withCopyButton && (
            <div data-tip data-for={`copy-value-${id}-button`}>
              <Button
                small
                link
                className="bg-white small-padding"
                onClick={() => copyEntryValue(value, dispatch)}
              >
                <i className="text-dark mdi mdi-content-copy"></i>
              </Button>
              <Tooltip id={`copy-value-${id}-button`} place="bottom">
                Copy value
              </Tooltip>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

const ConditionDisplayText = ({
  rule,
  path,
  dispatch,
  pre,
}: {
  rule: Rule;
  path: string;
  dispatch: any;
  pre?: boolean;
}) => {
  return (
    <div className="ConditionDisplayText alk-flex alk-flex-row alk-flex-baseline">
      <p
        className={classNames({ pre })}
        dangerouslySetInnerHTML={{ __html: rule[path] }}
      />
      <div data-tip data-for={`copy-value-${path}-button`}>
        <Button
          small
          link
          className="bg-white small-padding"
          onClick={() => copyEntryValue(rule[path], dispatch)}
        >
          <i className="text-dark mdi mdi-content-copy"></i>
        </Button>
        <Tooltip id={`copy-value-${path}-button`} place="left">
          Copy value
        </Tooltip>
      </div>
    </div>
  );
};

export const ConditionsDisplay = ({ rule, id }: Props) => {
  const dispatch = useDispatch();
  return (
    <div className="ConditionsDisplay" id={id}>
      {rule.constants && !isEqual(rule.constants, {}) && (
        <InputWithLabel inputId="constants-display" label="Constants">
          <ConditionDisplayItem id="constants-display" dict={rule.constants} />
        </InputWithLabel>
      )}
      {rule.selectors && !isEqual(rule.selectors, {}) && (
        <InputWithLabel inputId="selectors-display" label="Selectors">
          <ConditionDisplayItem id="selectors-display" dict={rule.selectors} />
        </InputWithLabel>
      )}
      {rule.conditionSelectors && !isEqual(rule.conditionSelectors, {}) && (
        <InputWithLabel
          inputId="condition-selectors-display"
          label="Condition Selectors"
        >
          <ConditionDisplayItem
            id="condition-selectors-display"
            dict={rule.conditionSelectors}
          />
        </InputWithLabel>
      )}

      <hr />

      {rule.condition && (
        <InputWithLabel inputId="condition-display" label="Condition">
          <ConditionDisplayText
            path="condition"
            rule={rule}
            pre
            dispatch={dispatch}
          />
        </InputWithLabel>
      )}
      {rule.validation && (
        <InputWithLabel inputId="validation-display" label="Validation">
          <ConditionDisplayText
            path="validation"
            rule={rule}
            pre
            dispatch={dispatch}
          />
        </InputWithLabel>
      )}
      <hr />

      {rule.errorMessage && (
        <InputWithLabel inputId="error-message-display" label="Error message">
          <ConditionDisplayText
            path="errorMessage"
            rule={rule}
            dispatch={dispatch}
          />
        </InputWithLabel>
      )}
    </div>
  );
};
