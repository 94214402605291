import {
  CheckboxFilter,
  Filter,
  Filters,
  FiltersBlock,
  SelectedFilters,
} from '@alkem/react-ui-list-filter';
import { Button } from '@alkem/react-ui-button';
import { Tooltip } from '@alkem/react-ui-tooltip';
import classNames from 'classnames';
import { ListAutocomplete } from 'components/autocomplete';
import { Map } from 'immutable';
import {
  addFilter,
  clearFilters,
  removeFilter,
  selectPreset,
} from 'modules/transaction/actions';
import {
  EVENT_ACTIONS,
  EVENT_ACTIONS_FILTERS,
  EVENT_TYPES,
  EVENT_TYPES_FILTERS,
  FILTER_PRESETS,
  STATUSES_FILTERS,
} from 'modules/transaction/constants';
import { selectState } from 'modules/transaction/reducer';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { separateActions } from 'utils/redux';
import { get } from 'utils/immutable';
import AutocompleteFilter from './autocomplete';
import OrganizationAutocompleteFilter from './autocomplete/organization';
import ProductKeyAutocompleteFilter from './autocomplete/product-key';
import DateFilter from './date';
import EntitiesFilter from './entities';
import './filters.scss';

const mapStateToProps = (state) => ({
  filters: selectState(state).filters,
  aggregations: selectState(state).aggregations,
});

const mapDispatchToProps = {
  addFilter,
  removeFilter,
  clearFilters,
  selectPreset,
};

const sourceOrganizationTitle = (
  <div className="TransactionFilters__helped">
    From Organization
    <div data-tip data-for="source-organization-filter-tooltip">
      <i className="mdi mdi-help-circle" />
      <Tooltip id="source-organization-filter-tooltip" place="right">
        Source organization in the entities.
        <br />
        <strong>For example: </strong>
        in a share transaction, that would be the manufacturer
      </Tooltip>
    </div>
  </div>
);

const targetOrganizationTitle = (
  <div className="TransactionFilters__helped">
    To Organization
    <div data-tip data-for="target-organization-filter-tooltip">
      <i className="mdi mdi-help-circle" />
      <Tooltip id="target-organization-filter-tooltip" place="right">
        Target organization in the entities.
        <br />
        <strong>For example: </strong>
        in a share transaction, that would be the retailer
      </Tooltip>
    </div>
  </div>
);

const productKeyTitle = (prefix) => (
  <div className="TransactionFilters__helped">
    <div>
      <div>{prefix} Product</div>
      <div className="TransactionFilters__helped__subtext">
        (view tooltip for usage)
      </div>
    </div>
    <div data-tip data-for="product-key-filter-tooltip">
      <i className="mdi mdi-help-circle" />
      <Tooltip id="product-key-filter-tooltip" place="right">
        <br />
        GTIN: just enter the number
        <br />
        Product Key: use one colon ":" before the id e.g. :123456
        <br />
        Product Id: use two colons "::" before the id e.g. ::123456
      </Tooltip>
    </div>
  </div>
);

const sourceEntitiesTitle = (
  <div className="TransactionFilters__helped">
    Source entities
    <div data-tip data-for="source-entities-filter-tooltip">
      <i className="mdi mdi-help-circle" />
      <Tooltip id="source-entities-filter-tooltip" place="right">
        Additional source entities
        <br />
        The expected format is: '&lt;type&gt; &lt;id&gt;'
      </Tooltip>
    </div>
  </div>
);

const targetEntitiesTitle = (
  <div className="TransactionFilters__helped">
    Target entities
    <div data-tip data-for="target-entities-filter-tooltip">
      <i className="mdi mdi-help-circle" />
      <Tooltip id="target-entities-filter-tooltip" place="right">
        Additional target entities
        <br />
        The expected format is: '&lt;type&gt; &lt;id&gt;'
      </Tooltip>
    </div>
  </div>
);

export class TransactionFilters extends PureComponent {
  static propTypes = {
    filters: ImmutablePropTypes.map.isRequired,
    aggregations: PropTypes.object.isRequired,

    actions: PropTypes.shape({
      addFilter: PropTypes.func.isRequired,
      removeFilter: PropTypes.func.isRequired,
      clearFilters: PropTypes.func.isRequired,
      selectPreset: PropTypes.func.isRequired,
    }),
  };

  static defaultProps = {
    filters: Map(),
  };

  constructor(props) {
    super(props);

    this.removeFromSelected = this.removeFromSelected.bind(this);

    this.onStatusFilterChange = this.onStatusFilterChange.bind(this);

    this.onSelectEventType = this.onSelect.bind(
      this,
      'eventTypes',
      'Event type: '
    );
    this.onUnselectEventType = this.onUnselect.bind(this, 'eventTypes');
    this.onSelectEventAction = this.onSelect.bind(
      this,
      'eventActions',
      'Event action: '
    );
    this.onUnselectEventAction = this.onUnselect.bind(this, 'eventActions');

    this.onSelectDataType = this.onSelect.bind(this, 'dataType', 'Data type: ');
    this.onUnselectDataType = this.onUnselect.bind(this, 'dataType');

    this.onSelectSourceOrganization = this.onSelect.bind(
      this,
      'sourceOrganization',
      'From: '
    );
    this.onUnselectSourceOrganization = this.onUnselect.bind(
      this,
      'sourceOrganization'
    );

    this.onSelectTargetOrganization = this.onSelect.bind(
      this,
      'targetOrganization',
      'To: '
    );
    this.onUnselectTargetOrganization = this.onUnselect.bind(
      this,
      'targetOrganization'
    );

    this.onSelectProduct = this.onSelect.bind(this, 'product', 'From: ');
    this.onUnselectProduct = this.onUnselect.bind(this, 'product');

    this.onSelectTargetProduct = this.onSelect.bind(
      this,
      'targetProduct',
      'To: '
    );
    this.onUnselectTargetProduct = this.onUnselect.bind(this, 'targetProduct');

    this.onSelectSourceProductKey = this.onSelect.bind(
      this,
      'sourceProductKey',
      'From: '
    );
    this.onUnselectSourceProductKey = this.onUnselect.bind(
      this,
      'sourceProductKey'
    );

    this.onSelectTargetProductKey = this.onSelect.bind(
      this,
      'targetProductKey',
      'To: '
    );
    this.onUnselectTargetProductKey = this.onUnselect.bind(
      this,
      'targetProductKey'
    );

    this.onAddSourceEntity = this.onSelect.bind(
      this,
      'sourceEntities',
      'From: '
    );
    this.onRemoveSourceEntity = this.onUnselect.bind(this, 'sourceEntities');

    this.onAddTargetEntity = this.onSelect.bind(this, 'targetEntities', 'To: ');
    this.onRemoveTargetEntity = this.onUnselect.bind(this, 'targetEntities');

    this.onSelectCreatedAtFrom = this.onSelect.bind(
      this,
      'createdAtFrom',
      'Created after: '
    );
    this.onUnselectCreatedAtFrom = this.onUnselect.bind(this, 'createdAtFrom');

    this.onSelectCreatedAtTo = this.onSelect.bind(
      this,
      'createdAtTo',
      'Created before: '
    );
    this.onUnselectCreatedAtTo = this.onUnselect.bind(this, 'createdAtTo');
  }

  onStatusFilterChange(filter, checked) {
    if (checked) {
      this.props.actions.addFilter(
        'statuses',
        filter.id,
        `Statuses: ${filter.label}`
      );
    } else {
      this.props.actions.removeFilter('statuses', filter.id);
    }
  }

  onSelect(filterType, labelPrefix, { key, label }) {
    this.props.actions.addFilter(filterType, key, `${labelPrefix}${label}`);
  }

  onUnselect(filterType, { key }) {
    this.props.actions.removeFilter(filterType, key);
  }

  isFilterSelected(filter, selection) {
    return selection.get(filter.id);
  }

  buildSelector(filterType) {
    return {
      selectId: (filter) => `${filterType}-${filter.id}`,
      selectNode: (filter) => filter.label,
    };
  }

  selectSelectedFilterId(filter) {
    return `${filter.get('filterType')}-${filter.get('filterId')}`;
  }

  selectSelectedFilterLabel(filter) {
    return filter.get('filterLabel');
  }

  removeFromSelected(filter) {
    this.props.actions.removeFilter(
      filter.get('filterType'),
      filter.get('filterId')
    );
  }

  renderStatusFilter(exclude = []) {
    const { filters } = this.props;

    const statuses = STATUSES_FILTERS.filter(
      (s) => exclude.indexOf(s.id) === -1
    );

    const renderSingleStatusFilter = (props) => {
      const classes = {
        TransactionFilters__statusBar: true,
        [`TransactionFilters__statusBar--${props.filter.statusbar}`]: true,
      };
      return (
        <div className="FieldSuggestionFilters__singleStatus">
          <span className={classNames(classes)} />
          <CheckboxFilter {...props} />
        </div>
      );
    };

    let selection = Map();
    filters.get('statuses').forEach((s) => {
      selection = selection.set(`${s}`, true);
    });

    return (
      <Filter
        title="Status"
        id="transaction-filter-status"
        filters={statuses}
        selection={selection}
        selectors={this.buildSelector('statuses')}
        isSelected={this.isFilterSelected}
        Item={renderSingleStatusFilter} // eslint-disable-line react/jsx-no-bind
        onChange={this.onStatusFilterChange}
      />
    );
  }

  renderEventTypeFilter(match = '') {
    const { filters } = this.props;

    const value = filters
      .get('eventTypes')
      .map((type) => ({
        key: type,
        value: type,
        label: EVENT_TYPES[type] || type,
      }))
      .toJS();

    let values = EVENT_TYPES_FILTERS;
    if (match) {
      values = EVENT_TYPES_FILTERS.filter(
        (f) => (f.key.match(match) || []).length === 1
      );
    }

    return (
      <AutocompleteFilter
        id="transaction-filter-event-types"
        title="Event Types"
        onSelect={this.onSelectEventType}
        onUnselect={this.onUnselectEventType}
        values={values}
        value={value}
      />
    );
  }

  renderEventActionFilter() {
    const { filters } = this.props;

    const value = filters
      .get('eventActions')
      .map((type) => ({
        key: type,
        value: type,
        label: EVENT_ACTIONS[type] || type,
      }))
      .toJS();

    return (
      <AutocompleteFilter
        id="transaction-filter-event-actions"
        title="Event Actions"
        onSelect={this.onSelectEventAction}
        onUnselect={this.onUnselectEventAction}
        values={EVENT_ACTIONS_FILTERS}
        value={value}
      />
    );
  }

  renderDataTypeFilter() {
    const { filters, aggregations } = this.props;

    const value = filters.get('dataType')
      ? [
          {
            key: filters.get('dataType'),
            value: filters.get('dataType'),
            label: filters.get('dataType'),
          },
        ]
      : [];
    const values = (get(aggregations, ['dataTypes', 'buckets']) || [])
      .filter((dt) => dt.key)
      .map((dt) => ({
        key: dt.key,
        value: dt.key,
        label: `${dt.key} (${dt.doc_count})`,
      }));

    return (
      <div className="AutocompleteFilter">
        <h3 className="ReactUiFilter__filterTitle">Data type</h3>
        <ListAutocomplete
          id="transaction-filter-data-type"
          className="TransactionFilter__Autocomplete"
          onSelect={this.onSelectDataType}
          onUnselect={this.onUnselectDataType}
          values={values}
          value={value}
          searchOnClick
        />
      </div>
    );
  }

  renderSourceOrganizationFilter(title = sourceOrganizationTitle) {
    const { filters } = this.props;

    const value = [];
    const so = filters.get('sourceOrganization');
    if (so.size) {
      value.push({
        key: so.get('id').toString(),
        value: so.get('name'),
        label: so.get('name'),
      });
    }

    return (
      <OrganizationAutocompleteFilter
        id="transaction-filter-source-organization"
        title={title}
        onSelect={this.onSelectSourceOrganization}
        onUnselect={this.onUnselectSourceOrganization}
        value={value}
      />
    );
  }

  renderTargetOrganizationFilter() {
    const { filters } = this.props;

    const value = [];
    const so = filters.get('targetOrganization');
    if (so.size) {
      value.push({
        key: so.get('id').toString(),
        value: so.get('name'),
        label: so.get('name'),
      });
    }

    return (
      <OrganizationAutocompleteFilter
        id="transaction-filter-target-organization"
        title={targetOrganizationTitle}
        onSelect={this.onSelectTargetOrganization}
        onUnselect={this.onUnselectTargetOrganization}
        value={value}
      />
    );
  }

  renderSourceProductKeyFilter() {
    const { filters } = this.props;

    const value = [];
    const so = filters.get('sourceProductKey');
    if (so && so.size) {
      value.push({
        key: so.get('id').toString(),
        value: so.get('name'),
        label: so.get('name'),
      });
    }

    return (
      <ProductKeyAutocompleteFilter
        id="transaction-filter-source-product"
        title={productKeyTitle('From ')}
        onSelect={this.onSelectSourceProductKey}
        onUnselect={this.onUnselectSourceProductKey}
        value={value}
        organizationId={filters.getIn(['sourceOrganization', 'id'])}
      />
    );
  }

  renderTargetProductKeyFilter() {
    const { filters } = this.props;

    const value = [];
    const so = filters.get('targetProductKey');
    if (so && so.size) {
      value.push({
        key: so.get('id').toString(),
        value: so.get('name'),
        label: so.get('name'),
      });
    }

    return (
      <ProductKeyAutocompleteFilter
        id="transaction-filter-target-product"
        title={productKeyTitle('To ')}
        onSelect={this.onSelectTargetProductKey}
        onUnselect={this.onUnselectTargetProductKey}
        value={value}
        organizationId={filters.getIn(['targetOrganization', 'id'])}
      />
    );
  }

  renderSourceEntitiesFilter() {
    const { filters } = this.props;

    return (
      <EntitiesFilter
        id="transaction-filter-source-entities"
        title={sourceEntitiesTitle}
        onAdd={this.onAddSourceEntity}
        onRemove={this.onRemoveSourceEntity}
        value={filters.get('sourceEntities')}
      />
    );
  }

  renderTargetEntitiesFilter() {
    const { filters } = this.props;

    return (
      <EntitiesFilter
        id="transaction-filter-target-entities"
        title={targetEntitiesTitle}
        onAdd={this.onAddTargetEntity}
        onRemove={this.onRemoveTargetEntity}
        value={filters.get('targetEntities')}
      />
    );
  }

  renderCreatedAtFilters() {
    const { filters } = this.props;

    return (
      <div>
        <DateFilter
          id="transaction-filters-created-at-from"
          title="Created after"
          value={filters.getIn(['createdAtFrom', 'id'])}
          onSelect={this.onSelectCreatedAtFrom}
          onUnselect={this.onUnselectCreatedAtFrom}
        />
        <DateFilter
          id="transaction-filters-created-at-to"
          title="Created before"
          value={filters.getIn(['createdAtTo', 'id'])}
          onSelect={this.onSelectCreatedAtTo}
          onUnselect={this.onUnselectCreatedAtTo}
        />
      </div>
    );
  }

  renderFilters() {
    return (
      <div>
        {this.renderStatusFilter()}
        {this.renderEventTypeFilter()}
        {this.renderEventActionFilter()}
        {this.renderDataTypeFilter()}
        {this.renderSourceOrganizationFilter()}
        {this.renderTargetOrganizationFilter()}
        {this.renderSourceProductKeyFilter()}
        {this.renderTargetProductKeyFilter()}
        {this.renderSourceEntitiesFilter()}
        {this.renderTargetEntitiesFilter()}
        {this.renderCreatedAtFilters()}
      </div>
    );
  }

  render() {
    const { actions, filters } = this.props;

    return (
      <Filters className="TransactionFilters">
        <SelectedFilters
          id="transaction-filters"
          title="Selected filters"
          emptyLabel="No selected filters"
          removeLabel="Remove"
          filters={filters.get('selected')}
          selectId={this.selectSelectedFilterId}
          selectLabel={this.selectSelectedFilterLabel}
          onRemoveAll={actions.clearFilters}
          onRemove={this.removeFromSelected}
        />
        <FiltersBlock>
          <h3 className="ReactUiFilter__filterTitle">Quick Filters</h3>
          <div className="TransactionFilters__presetsSelect">
            {FILTER_PRESETS.map((preset) => (
              <Button
                secondary
                key={preset.key}
                onClick={() => this.props.actions.selectPreset(preset)} // eslint-disable-line react/jsx-no-bind
              >
                {preset.label}
              </Button>
            ))}
          </div>
          {this.renderFilters()}
        </FiltersBlock>
      </Filters>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(TransactionFilters);
