import { HeaderLayout } from '@alkem/react-layout';
import { Button } from '@alkem/react-ui-button';
import { Radio } from '@alkem/react-ui-inputs';
import { SimpleSelect } from '@alkem/react-ui-select';
import classnames from 'classnames';
import {
  OrganizationAutocomplete,
  ProductKeyAutocomplete,
} from 'components/autocomplete';
import { home } from 'constants/routes';
import { get } from 'lodash/fp';
import qs from 'querystringify';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Tooltip } from '@alkem/react-ui-tooltip';
import history from 'utils/history';
import {
  loadProduct,
  reindexProduct,
  resyncProduct,
  save,
  triggerReview,
  updateProduct,
  updateReason,
} from './actions';
import { typePackagings } from './constants';
import ESContent from './es-content';
import {
  selectIsReindexing,
  selectIsResyncing,
  selectIsReviewing,
  selectIsSaving,
  selectProduct,
  selectReason,
} from './selectors';
import './view.scss';
import { getProductKeyLabel } from 'components/autocomplete/custom/product-key-autocomplete';
import { fromJS } from 'immutable';

const yesNo = [
  { value: true, label: 'yes' },
  { value: false, label: 'no' },
];

interface Props {
  product: any;
  reason: string;
  isSaving: boolean;
  isReindexing: boolean;
  isResyncing: boolean;
  isReviewing: boolean;
  onSelectProduct: (productKeyId: number | null) => void;
  onUpdateProduct: (k: string, value: any) => void;
  onSave: () => void;
  onReindex: () => void;
  onResync: () => void;
  onReviewTrigger: () => void;
  onUpdateReason: (reason: string) => void;
}

interface State {
  organization: any;
}

interface QueryString {
  productKeyId: string;
}

interface ExtraParams {
  allow_not_consumer_units: boolean;
  filter_is_displayable_as_target: boolean;
  advanced_search?: any;
}

const mapStateToProps = createStructuredSelector({
  product: selectProduct,
  reason: selectReason,
  isSaving: selectIsSaving,
  isReindexing: selectIsReindexing,
  isResyncing: selectIsResyncing,
  isReviewing: selectIsReviewing,
});

const mapDispatchToProps = {
  onSelectProduct: loadProduct,
  onUpdateProduct: updateProduct,
  onSave: save,
  onReindex: reindexProduct,
  onResync: resyncProduct,
  onReviewTrigger: triggerReview,
  onUpdateReason: updateReason,
};

class ProductMaintenance extends PureComponent<Props, State> {
  public state = {
    organization: null,
  };

  public componentDidMount() {
    const query = qs.parse(history.location.search) as QueryString;
    if (query && query.productKeyId) {
      this.props.onSelectProduct(parseInt(query.productKeyId, 10));
    }
  }

  private onSelectOrganization = (organization?) => {
    this.setState({ organization });
    this.onSelectProduct(null);
  };

  private onSelectProduct = (opt?) => {
    this.props.onSelectProduct(opt?.key);
  };

  private onChangeIsConsumerUnit = (evt) => {
    const value = evt.target.value === 'true';
    this.props.onUpdateProduct('isConsumerUnit', value);
    if (value) {
      this.props.onUpdateProduct('isDisplayUnit', false);
    }
  };

  private onChangeBoolean = (fieldName) => (evt) => {
    this.props.onUpdateProduct(fieldName, evt.target.value === 'true');
  };

  private onChangeTypePackaging = ({ id }) => {
    this.props.onUpdateProduct('typePackaging', { id });
  };

  private onUpdateReason = (evt) => {
    this.props.onUpdateReason(evt.target.value);
  };

  private renderHeader() {
    return (
      <HeaderLayout
        title="Product maintenance"
        backHref={home}
        backMessage="Back home"
        isTitleSmall
      />
    );
  }

  private renderProduct() {
    const {
      product,
      onSave,
      onReindex,
      onResync,
      onReviewTrigger,
      isSaving,
      isReindexing,
      isResyncing,
      isReviewing,
      reason,
    } = this.props;

    if (!product || !product.product_key) {
      return null;
    }

    const allowedTypePackagings = typePackagings.filter((tp) =>
      tp.applicable(get('isConsumerUnit', product))
    );

    const currentTypePackagingIsAllowed = !!allowedTypePackagings.find(
      (tp) => product?.typePackaging?.id === tp.id
    );

    return (
      <div className="ProductMaintenance">
        <div className="ProductMaintenance__actions flex flex-justify-content--end">
          <Button
            primary
            onClick={onReindex}
            content="Reindex"
            disabled={isReindexing}
            displaySpinner={isReindexing}
          />
          <Button
            primary
            onClick={onResync}
            content="Resync"
            disabled={isResyncing}
            displaySpinner={isResyncing}
          />
          <Button
            primary
            onClick={onReviewTrigger}
            content="Review trigger"
            disabled={isReviewing}
            displaySpinner={isReviewing}
          />
        </div>
        <div className="ProductMaintenance__field row flex-align-items--center">
          <span className="col-xs-4 text-align--right">Is a consumer unit</span>
          <Radio
            id="isConsumerUnit-radio"
            className="col-xs-8"
            value={get('isConsumerUnit', product) || false}
            options={yesNo}
            onChange={this.onChangeIsConsumerUnit}
          />
        </div>
        <div className="ProductMaintenance__field row flex-align-items--center">
          <span className="col-xs-4 text-align--right">
            Is a display shipper
          </span>
          <Radio
            id="isDisplayUnit-radio"
            className="col-xs-8"
            value={get('isDisplayUnit', product) || false}
            options={yesNo}
            onChange={this.onChangeBoolean('isDisplayUnit')}
            disabled={get('isConsumerUnit', product)}
          />
        </div>
        <div className="ProductMaintenance__field row flex-align-items--center">
          <span className="col-xs-4 text-align--right">Is a dispatch unit</span>
          <Radio
            id="isDespatchUnit-radio"
            className="col-xs-8"
            value={get('isDespatchUnit', product) || false}
            options={yesNo}
            onChange={this.onChangeBoolean('isDespatchUnit')}
          />
        </div>
        <div
          className={classnames(
            'ProductMaintenance__field row flex-align-items--center',
            { error: !currentTypePackagingIsAllowed }
          )}
        >
          <span className="col-xs-4 text-align--right">Packaging type</span>
          <div className="col-xs-8">
            <SimpleSelect
              id="typePackagings"
              options={allowedTypePackagings}
              value={typePackagings.find(
                (tp) => product?.typePackaging?.id === tp.id
              )}
              onSelect={this.onChangeTypePackaging}
            />
          </div>
          {!currentTypePackagingIsAllowed && (
            <div className="col-xs-8 offset-xs-4">
              <div>
                <i className="mdi mdi-alert" />
                This packaging type is not valid for the consumer unit / display
                unit combo.
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-justify-content--end ProductMaintenance__saveBloc">
          <div className={classnames({ error: !reason })}>
            <div className="flex flex-align-items--center ProductMaintenance__saveBloc">
              <span>Reason:</span>
              <input
                type="text"
                value={reason}
                className="form-control"
                onChange={this.onUpdateReason}
                placeholder="ESC-123"
              />
            </div>
            {!reason && (
              <div>
                <i className="mdi mdi-alert" />
                Fill the reason, typically the ESC ticket number (ESC-123)
              </div>
            )}
          </div>
          <Button
            primary
            className="ProductMaintenance__save"
            onClick={onSave}
            content="Save"
            disabled={isSaving || !currentTypePackagingIsAllowed || !reason}
            displaySpinner={isSaving}
          />
        </div>
      </div>
    );
  }

  private renderESContent() {
    const { product } = this.props;
    if (!product || !product.product_key) {
      return null;
    }

    return (
      <div className="ProductMaintenance">
        {' '}
        <ESContent productKeyId={product.product_key.id} />
      </div>
    );
  }

  public render() {
    const { product } = this.props;
    const { organization } = this.state;
    const extraParams: ExtraParams = {
      allow_not_consumer_units: true,
      filter_is_displayable_as_target: false,
    };

    const organizationId = get('key', organization);
    if (organizationId) {
      extraParams.advanced_search = {
        query: organizationId,
        fields: ['owner.id'],
      };
    }

    return (
      <div>
        {this.renderHeader()}
        <div className="ProductMaintenance flex flex-align-items--center">
          <OrganizationAutocomplete
            id="organization-list-autocomplete"
            className="ProductMaintenance__OrganizationSearch"
            onSelect={this.onSelectOrganization}
            onUnselect={() => {
              this.onSelectOrganization(null);
              this.onSelectProduct(null);
            }}
            value={organization ? [organization] : null}
            placeholder="Scope to organization..."
            searchOnClick
          />
          <ProductKeyAutocomplete
            id="product-maintenance-product-key-autocomplete"
            className="ProductMaintenance__ProductKeySearch flex-grow--1"
            onSelect={this.onSelectProduct}
            onUnselect={() => this.onSelectProduct(null)}
            value={
              product && product.product_key
                ? [
                    {
                      key: product.product_key_id,
                      value: product.product_key_id,
                      label: getProductKeyLabel(fromJS(product)),
                    },
                  ]
                : null
            }
            extraParams={extraParams}
            placeholder="Select product to update (GTIN - retailer orgID - TM - manuf orgID - product name)"
            searchOnClick
          />
          <div data-tip data-for="product-key-filter-tooltip">
            <i className="mdi mdi-24px mdi-help-circle" />
            <Tooltip id="product-key-filter-tooltip" place="right">
              GTIN: just enter the number
              <br />
              Product Key: use one colon ":" before the id e.g. :123456
              <br />
              Product Id: use two colons "::" before the id e.g. ::123456
            </Tooltip>
          </div>
        </div>
        {this.renderProduct()}
        {this.renderESContent()}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductMaintenance);
