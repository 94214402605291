import { createReducer } from 'utils/reducers';

import { fromJS } from 'immutable';

import {
  receiveReducer,
  initPagination,
  changeLimit,
  nextPage,
  previousPage,
  addFilter,
  removeFilter,
  updateFilter,
  clearFilter,
} from 'utils/reducers/list';

import * as events from '../constants/events';

const LIMIT_STORAGE_KEY = 'gdsnPSRListLimit';
const SORTING_STORAGE_KEY = 'gdsnPSRListSorting';

const initialState = {
  list: [],
  listDetail: fromJS({}),
  filters: {
    filter_gln: [],
    filter_gtins_in: [],
  },
  pagination: initPagination(LIMIT_STORAGE_KEY, SORTING_STORAGE_KEY),
};

export default createReducer(initialState, {
  [events.RECEIVE_GDSN_PSR_LIST]: (state, action) =>
    receiveReducer(state, action.payload),
  [events.CHANGE_LIMIT_GDSN_PSR_LIST]: (state, action) =>
    changeLimit(state, action, LIMIT_STORAGE_KEY),
  [events.NEXT_PAGE_GDSN_PSR_LIST]: nextPage,
  [events.PREVIOUS_PAGE_GDSN_PSR_LIST]: previousPage,
  [events.ADD_GDSN_PSR_LIST_FILTER]: addFilter,
  [events.REMOVE_GDSN_PSR_LIST_FILTER]: removeFilter,
  [events.UPDATE_GDSN_PSR_LIST_FILTER]: updateFilter,
  [events.CLEAR_GDSN_PSR_LIST_FILTER]: clearFilter,
  [events.GET_GDSN_PSR_LIST]: (state) => {
    const newState = { ...state };
    newState.list = [];
    newState.listDetail = fromJS({});
    return newState;
  },
  [events.RECEIVE_GDSN_PSR_DETAIL]: (state, action) => {
    const newState = { ...state };
    const ns = state.listDetail.set(
      `${action.psrID}-${action.gtin}`,
      fromJS(action.data)
    );
    newState.listDetail = ns;
    return newState;
  },
});
