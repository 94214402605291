import { List } from 'immutable';
import { createReducer } from 'redux-create-reducer';
import {
  FILTERED_ORGANIZATIONS_GET,
  FILTERED_ORGANIZATIONS_RECEIVE,
  ORGANIZATIONS_RECEIVE,
} from '../constants/organizations';

const initialState = {
  list: List(),
  filteredList: List(),
  search: '',
};

export default createReducer(initialState, {
  [ORGANIZATIONS_RECEIVE]: (state, action) => {
    const newState = { ...state };
    newState.list = action.list;
    return newState;
  },
  [FILTERED_ORGANIZATIONS_GET]: (state, action) => {
    const newState = { ...state };
    newState.search = action.search;
    return newState;
  },
  [FILTERED_ORGANIZATIONS_RECEIVE]: (state, action) => {
    const newState = { ...state };
    newState.filteredList = action.list;
    return newState;
  },
});
