import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { isEmpty, keyBy, mapValues } from 'lodash';

import { HeaderLayout } from '@alkem/react-layout';
import { TwinPanel } from '@alkem/react-ui-twinpanel';
import { Button, SwitchButton } from '@alkem/react-ui-button';
import { Checkbox } from '@alkem/react-ui-inputs';

import { selectUserReadOnlyAccess } from 'modules/auth/selectors';
import { kindDashboardAccessPolicy } from 'access-policies';

import * as routes from 'constants/routes';
import { getKindList, updateKind, getKind, selectKind } from 'actions/kinds';
import { getFieldList, refreshCache } from 'actions/fields';
import { notificationError } from 'actions/notifications';
import FieldList from 'components/fieldlist';
import SaveModal from 'components/savemodal';
import KindTree from 'components/kind-tree';
import './KindDashboardView.scss';

const mapStateToProps = (state) => ({
  rawKinds: state.kinds,
  rawFields: state.fields.get('source'),
  selectedKindId: state.kinds.selected,
  refreshingCache: state.fields.get('refreshingCache'),
  readOnly: selectUserReadOnlyAccess(kindDashboardAccessPolicy)(state),
});

export class KindDashboardView extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    rawKinds: PropTypes.object,
    rawFields: ImmutablePropTypes.list,
    selectedKindId: PropTypes.number,
    refreshingCache: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      // kindId#applicableFields map
      applicableFieldsIndex: {},
      // The displayed kind
      selectedKind: null,
      // Visibility change request
      kindVisibilityChangeRequest: null,
      // Updates per kindId and fieldId
      kindFieldUpdates: {},
      // Flag to keep track of updates on kinds
      kindHasUpdates: {},
      // Excluded referentials indexed by kind id,
      excludedReferentialValues: {},
      // Save modal flag
      modalIsOpen: false,
      hideOrganizationSpecificFields: true,
      hideDisabledFields: true,
    };
    this.onKindChange = this.onKindChange.bind(this);
    this.onKindFieldChange = this.onKindFieldChange.bind(this);
    this.onKindReferentialValueChange =
      this.onKindReferentialValueChange.bind(this);
    this.onSaveButtonClick = this.onSaveButtonClick.bind(this);
    this.onDiscardButtonClick = this.onDiscardButtonClick.bind(this);
    this.toggleOrganizationSpecificFields =
      this.toggleOrganizationSpecificFields.bind(this);
    this.toggleDisabledFields = this.toggleDisabledFields.bind(this);
    this.toggleVisibility = this.toggleVisibility.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onSaveUpdates = this.onSaveUpdates.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getKindList());
    dispatch(getFieldList());
  }

  componentDidUpdate(prevProps) {
    const { rawKinds, selectedKindId } = this.props;
    if (
      rawKinds !== prevProps.rawKinds ||
      selectedKindId !== prevProps.selectedKindId
    ) {
      if (rawKinds && !isEmpty(rawKinds.tree)) {
        const { kindsApplicableFields, excludedReferentialValues } =
          this.formatChildren([rawKinds.tree], [], {});

        const selectedKind = rawKinds.indexed[selectedKindId];
        // eslint-disable-next-line
        this.setState({
          applicableFieldsIndex: kindsApplicableFields,
          excludedReferentialValues,
          kindVisibilityChangeRequest: false,
        });
        if (selectedKind) {
          // eslint-disable-next-line
          this.setState({
            selectedKind: {
              value: selectedKind.id,
              label: selectedKind.label,
              visible: !!selectedKind.visible,
            },
          });
        }
      }
    }
  }

  onKindChange(selected) {
    const selectedKindId = Object.keys(selected)[0];
    const { dispatch, rawKinds } = this.props;
    const { kindHasUpdates, selectedKind } = this.state;
    if (kindHasUpdates && selectedKind && kindHasUpdates[selectedKind.value]) {
      dispatch(
        notificationError(
          'A kind is being edited, please save or discard your changes before moving to another kind.'
        )
      );
    } else {
      const tree = rawKinds.indexed[selectedKindId];
      this.setState({
        selectedKind: { value: tree.id, label: tree.name },
      });
      dispatch(getKind(tree.id));
      dispatch(selectKind(tree.id));
    }
  }

  onFieldChange = (fieldId, value) => {
    const { selectedKind } = this.state;
    return this.onKindFieldChange(selectedKind.value, fieldId, value);
  };

  onReferentialValueChange = (referentialName, value) => {
    const { selectedKind } = this.state;
    return this.onKindReferentialValueChange(
      selectedKind.value,
      referentialName,
      value
    );
  };

  onKindFieldChange(kindId, field, value) {
    const { kindFieldUpdates, applicableFieldsIndex, kindHasUpdates } =
      this.state;
    if (!kindFieldUpdates[kindId]) {
      kindFieldUpdates[kindId] = {};
    }
    kindHasUpdates[kindId] = true;

    kindFieldUpdates[kindId][field.id] = { label: field.name, value };
    if (value === true) {
      applicableFieldsIndex[kindId].all.push(field.id);
    } else {
      const ind = applicableFieldsIndex[kindId].all.indexOf(field.id);
      applicableFieldsIndex[kindId].all.splice(ind, 1);
    }

    this.setState({ applicableFieldsIndex, kindFieldUpdates, kindHasUpdates });
  }

  onKindReferentialValueChange(kindId, referentialName, selectedValues) {
    const { excludedReferentialValues, kindHasUpdates } = this.state;
    kindHasUpdates[kindId] = true;
    if (!excludedReferentialValues[kindId][referentialName]) {
      excludedReferentialValues[kindId][referentialName] = {};
      excludedReferentialValues[kindId][referentialName].initial = [];
    }
    excludedReferentialValues[kindId][referentialName].updated =
      selectedValues.map((v) => v.id);
    this.setState({ excludedReferentialValues });
  }

  onCloseModal() {
    this.setState({
      modalIsOpen: false,
      kindVisibilityChangeRequest: false,
    });
  }

  onRefreshCache = () => {
    const { dispatch } = this.props;
    dispatch(refreshCache());
  };

  onSaveUpdates() {
    const { rawKinds } = this.props;
    const {
      selectedKind,
      kindVisibilityChangeRequest,
      kindFieldUpdates,
      excludedReferentialValues,
      kindHasUpdates,
    } = this.state;

    const kind = rawKinds.indexed[selectedKind.value];

    const filterFieldFromKind = (myId) =>
      kind.applicableFields.filter(
        (applicableField) => applicableField.field.id !== myId
      );

    const fieldUpdates = kindFieldUpdates[selectedKind.value] || {};
    const referentialValues = excludedReferentialValues[selectedKind.value];

    // Check fields
    Object.entries(fieldUpdates).forEach(([fieldId, fieldInfo]) => {
      // Added fields
      const intFieldId = parseInt(fieldId, 10);

      if (fieldInfo.value) {
        if (kind.applicableFields.find((x) => x.field.id === intFieldId)) {
          // Remove exclusion
          kind.applicableFields = filterFieldFromKind(intFieldId);
        } else {
          // Add field
          kind.applicableFields.push({
            field: { id: intFieldId, label: fieldInfo.label },
            exclusion: false,
          });
        }
      }
      // Removed fields
      if (!fieldInfo.value) {
        if (kind.applicableFields.find((x) => x.field.id === intFieldId)) {
          // Remove from applicable fields
          kind.applicableFields = filterFieldFromKind(intFieldId);
        } else {
          // Exclusion
          kind.applicableFields.push({
            field: { id: intFieldId, label: fieldInfo.label },
            exclusion: true,
          });
        }
      }
    });

    kind.excludedReferentialEntities = [];
    // Handle referential values
    Object.entries(referentialValues).forEach(
      ([referentialName, valuesLists]) => {
        kind.excludedReferentialEntities.push({
          referential: referentialName,
          entity_ids: valuesLists.updated,
        });
      }
    );
    if (kindVisibilityChangeRequest) {
      kind.visible = !kind.visible;
    }
    this.props.dispatch(updateKind(kind));
    // Should be done upon success through notifications
    kindHasUpdates[selectedKind.value] = false;
    kindFieldUpdates[selectedKind.value] = {};
    this.setState({ kindHasUpdates, kindFieldUpdates });
    this.onCloseModal();
  }

  onSaveButtonClick() {
    this.setState({ modalIsOpen: true });
  }

  onDiscardButtonClick() {
    const { kindHasUpdates, kindFieldUpdates, selectedKind } = this.state;

    kindHasUpdates[selectedKind.value] = false;
    kindFieldUpdates[selectedKind.value] = {};
    this.setState({ kindHasUpdates, kindFieldUpdates, selectedKind: null });
  }

  getApplicableFieldIds(applicabledFields) {
    const getId = (items) => items.map((fieldData) => fieldData.field.id);

    const applicable = applicabledFields
      ? applicabledFields.filter((item) => item.exclusion === false)
      : [];
    const excluded = applicabledFields
      ? applicabledFields.filter((item) => item.exclusion === true)
      : [];

    return { applicable: getId(applicable), excluded: getId(excluded) };
  }

  getKindParents(kindId) {
    const { rawKinds } = this.props;
    const parents = [];
    let id = kindId;
    while (rawKinds.indexed[id].parent) {
      ({ id } = rawKinds.indexed[id].parent);
      parents.push(id);
    }
    return parents;
  }

  formatChildren(children, heritedApplicableFields) {
    let kindsApplicableFields = {};
    let excludedReferentialValues = {};

    children.forEach((child) => {
      let allApplicableFields;
      if (child.applicableFields) {
        const applicableFields = this.getApplicableFieldIds(
          child.applicableFields
        );

        let filteredHeritedApplicableFields = heritedApplicableFields;
        if (applicableFields.excluded.length) {
          // Filter out from herited applicable fields
          filteredHeritedApplicableFields =
            filteredHeritedApplicableFields.filter(
              (fieldId) =>
                applicableFields.excluded.findIndex(
                  (excludedId) => fieldId === excludedId
                ) === -1
            );
        }

        allApplicableFields = filteredHeritedApplicableFields.concat(
          applicableFields.applicable
        );

        kindsApplicableFields[child.id] = {
          specific: [
            ...applicableFields.excluded,
            ...applicableFields.applicable,
          ],
          all: allApplicableFields,
        };

        excludedReferentialValues[child.id] = mapValues(
          keyBy(child.excludedReferentialEntities, 'referential'),
          ({ entity_ids: entityIds }) => ({
            initial: entityIds,
            updated: entityIds,
          })
        );
      }
      if (child.children.length) {
        const formattedChildren = this.formatChildren(
          child.children,
          allApplicableFields
        );
        kindsApplicableFields = Object.assign(
          kindsApplicableFields,
          formattedChildren.kindsApplicableFields
        );
        excludedReferentialValues = Object.assign(
          excludedReferentialValues,
          formattedChildren.excludedReferentialValues
        );
      }
    });
    return {
      kindsApplicableFields,
      excludedReferentialValues,
    };
  }

  toggleOrganizationSpecificFields() {
    this.setState((prevState) => ({
      hideOrganizationSpecificFields: !prevState.hideOrganizationSpecificFields,
    }));
  }

  toggleDisabledFields() {
    this.setState((prevState) => ({
      hideDisabledFields: !prevState.hideDisabledFields,
    }));
  }

  toggleVisibility() {
    this.setState({
      kindVisibilityChangeRequest: true,
      modalIsOpen: true,
    });
  }

  filterOutOrganizationSpecific(rawFields) {
    return rawFields.filter((field) => !field.get('specific'));
  }

  filterOutDisabledFields(rawFields) {
    const { applicableFieldsIndex, selectedKind } = this.state;
    const applicableFields = applicableFieldsIndex[selectedKind.value].all;
    return rawFields.filter((field) =>
      applicableFields.includes(field.get('id'))
    );
  }

  renderKindTree() {
    let selection;
    if (this.props.selectedKindId) {
      selection = { [this.props.selectedKindId]: true };
    }
    return (
      <KindTree
        multiple={false}
        onSelectionChange={this.onKindChange}
        selection={selection}
      />
    );
  }

  renderFieldList() {
    let { rawFields } = this.props;
    const { readOnly } = this.props;
    const {
      selectedKind,
      applicableFieldsIndex,
      excludedReferentialValues,
      hideOrganizationSpecificFields,
      hideDisabledFields,
    } = this.state;

    if (!selectedKind || !applicableFieldsIndex[selectedKind.value]) {
      return <div>Select a kind ...</div>;
    }

    // TODO: when !applicableFieldsIndex[selectedKind.value]) if should rather show a spinner
    if (hideOrganizationSpecificFields) {
      rawFields = this.filterOutOrganizationSpecific(rawFields);
    }

    if (hideDisabledFields) {
      rawFields = this.filterOutDisabledFields(rawFields);
    }

    return (
      <FieldList
        fieldMetadata={rawFields.toJS()}
        onFieldChange={this.onFieldChange}
        onReferentialValueChange={this.onReferentialValueChange}
        applicableFields={applicableFieldsIndex[selectedKind.value].all}
        excludedReferentialValues={excludedReferentialValues}
        selectedKind={selectedKind}
        selectedKindParents={this.getKindParents(selectedKind.value)}
        specificFields={applicableFieldsIndex[selectedKind.value].specific}
        readOnly={readOnly}
      />
    );
  }

  renderFieldListTitle() {
    const { refreshingCache, readOnly } = this.props;
    const {
      selectedKind,
      kindHasUpdates,
      hideOrganizationSpecificFields,
      hideDisabledFields,
    } = this.state;
    let saveButtonElement = null;
    let discardButtonElement = null;
    let specificFieldsToggle = null;
    let disabledFieldsToggle = null;
    let visibilityToggle = null;
    if (selectedKind) {
      visibilityToggle = (
        <SwitchButton
          content="Publicly visible"
          disabled={
            kindHasUpdates[selectedKind.value] ||
            !selectedKind ||
            !selectedKind.label ||
            readOnly
          }
          onChange={this.toggleVisibility}
          checked={selectedKind.visible}
          id="switchbutton-toggleVisible"
        />
      );
      saveButtonElement = !readOnly && (
        <Button
          primary
          key="save"
          disabled={!kindHasUpdates[selectedKind.value]}
          onClick={this.onSaveButtonClick}
        >
          Save
        </Button>
      );
      discardButtonElement = !readOnly && (
        <Button
          danger
          key="discard"
          disabled={!kindHasUpdates[selectedKind.value]}
          onClick={this.onDiscardButtonClick}
        >
          Discard
        </Button>
      );
      specificFieldsToggle = (
        <Checkbox
          label="Hide organization specific"
          onChange={this.toggleOrganizationSpecificFields}
          checked={hideOrganizationSpecificFields}
          id="checkbox-toggleHideOrganizationSpecificFields"
          className="FieldList__checkbox"
          disabled={readOnly}
        />
      );
      disabledFieldsToggle = (
        <Checkbox
          label="Hide unchecked fields"
          onChange={this.toggleDisabledFields}
          checked={hideDisabledFields}
          id="checkbox-toggleHideDisabledFieldsFields"
          className="FieldList__checkbox"
          disabled={readOnly}
        />
      );
    }

    return (
      <span className="KindDashboardView__title">
        <span>
          {`Find a field: ${(selectedKind && selectedKind.label) || '...'}`}
        </span>
        <span>{visibilityToggle}</span>
        <span className="KindDashboardView__actions">
          {disabledFieldsToggle}
          {specificFieldsToggle}
          {saveButtonElement}
          {discardButtonElement}
          {!readOnly && (
            <Button
              id="cache-refresh-button"
              content="Refresh cache"
              onClick={this.onRefreshCache}
              disabled={refreshingCache}
              displaySpinner={refreshingCache}
              warning
            />
          )}
        </span>
      </span>
    );
  }

  render() {
    const {
      selectedKind,
      kindVisibilityChangeRequest,
      kindFieldUpdates,
      excludedReferentialValues,
      modalIsOpen,
    } = this.state;
    const headerProps = {
      title: 'Kind dashboard',
      backHref: routes.home,
      backMessage: 'Back home',
      isTitleSmall: true,
    };
    return (
      <div className="KindDashboardView__body">
        <HeaderLayout {...headerProps} />
        <div className="KindDashboardView__content">
          <TwinPanel
            leftPanel={{
              title: 'Kinds tree',
              content: this.renderKindTree(),
            }}
            rightPanel={{
              title: this.renderFieldListTitle(),
              content: this.renderFieldList(),
              className: 'KindDashboardView__panel--right',
            }}
          />
        </div>
        {selectedKind && modalIsOpen && (
          <SaveModal
            fieldUpdates={kindFieldUpdates[selectedKind.value] || {}}
            referentialValues={excludedReferentialValues[selectedKind.value]}
            selectedKind={selectedKind}
            kindVisibilityChangeRequest={kindVisibilityChangeRequest}
            onSaveClick={this.onSaveUpdates}
            onCloseModal={this.onCloseModal}
          />
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps)(KindDashboardView);
