export const TYPE_ALK_SERVICE = 1;
export const TYPE_ALK_SUPER_ADMIN = 2;
export const TYPE_SUPPORT = 3;
export const TYPE_PLATFORM_ADMIN = 4;
export const TYPE_USER = 5;

export const USER_STATUS_DISABLED = 0;
export const USER_STATUS_ACCEPTED = 1;
export const USER_STATUS_PENDING = 2;

export const ORGANIZATION_ROLE_USER = 'user';
export const ORGANIZATION_ROLE_ADMIN = 'admin';

export const USER_BELONGS_TO_ORGANIZATION_REFUSED = 0;
export const USER_BELONGS_TO_ORGANIZATION_ACCEPTED = 1;
export const USER_BELONGS_TO_ORGANIZATION_PENDING = 2;
