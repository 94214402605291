import { createReducer } from 'redux-create-reducer';
import { fromJS } from 'immutable';

import * as events from './events';
import { findDisplayGroupPath } from './utils';

const getInitialState = () => ({
  displayGroup: null,
});

export default createReducer(getInitialState(), {
  [events.DISPLAY_GROUPS_LOAD]: (state, { displayGroups }) => ({
    ...state,
    displayGroup: fromJS({
      id: 0,
      label: 'Root',
      children: displayGroups,
    }),
  }),
  [events.DISPLAY_GROUPS_REFRESH_ONE]: (state, { fields, displayGroupId }) => ({
    ...state,
    displayGroup: state.displayGroup.setIn(
      [...findDisplayGroupPath(displayGroupId, state.displayGroup), 'fields'],
      fields
    ),
  }),
  [events.DISPLAY_GROUPS_UPDATE_DATA]: (state, { path, value }) => ({
    ...state,
    displayGroup: state.displayGroup.setIn(path, value),
  }),
});
