import * as events from 'constants/events/fields';

import { notificationError, notificationSuccess } from 'actions/notifications';

import { fieldsApiImmutable as fieldsApi } from 'resources/fieldsApi';

let getFieldMetaDataListPromise;

export const getFieldList = (rootId) => (dispatch) => {
  // Handle cancel
  if (getFieldMetaDataListPromise) {
    getFieldMetaDataListPromise.cancel();
  }

  getFieldMetaDataListPromise = fieldsApi.FieldMetaDataList({
    links: true,
    rootId,
  });

  getFieldMetaDataListPromise.then((response) => {
    const { data } = response.data;
    return dispatch({ type: events.RECEIVE_FIELD_LIST, data });
  });
  return { type: events.GET_FIELD_LIST };
};

export const refreshCache = () => async (dispatch) => {
  dispatch({ type: events.REFRESHING_CACHE, refreshing: true });
  try {
    await fieldsApi.get('/core/v3/metadatacache/refresh');
    dispatch(notificationSuccess('Cache refreshed'));
  } catch (err) {
    dispatch(notificationError(`Error refreshing cache: ${err}`));
    console.error(err); // eslint-disable-line
  }
  dispatch({ type: events.REFRESHING_CACHE, refreshing: false });
};
