import { ValidationFilters } from '@alkem/sdk-dashboard';
import {
  PaginationState,
  Rule,
  toQueryId,
  ValidationSelectedFilters,
} from 'modules/validation-dashboard/types';
import authApi from 'resources/authApi';
import { validationApiJS } from 'resources/validationApi';
import { calculateOffset, logError } from 'utils';
import { validationFillOrganizationsCache } from './actions';

function hasNoFilters(filters: ValidationFilters) {
  return Object.values(filters).every(
    (v) => v === undefined || (Array.isArray(v) && !v?.length) || v === ''
  );
}

interface PaginatedResponse {
  data: { data: Rule[]; totalResults: number };
}

export async function fetchRulesList(filters: ValidationFilters) {
  if (!hasNoFilters(filters)) {
    const response = (await validationApiJS.listCachedRules(filters)) as {
      data: any;
    } as PaginatedResponse;
    return response.data;
  }
  return { data: [], totalResults: 0 };
}

export function convertToQueryFilters(
  selectedFilters: ValidationSelectedFilters,
  search: string,
  forceUnion: boolean,
  pagination?: PaginationState,
  sort?: string[]
) {
  const filters: ValidationFilters = {
    ruleSetIdIn: selectedFilters.ruleSetIdIn?.map(toQueryId),
    ruleSetTypeIn: selectedFilters.ruleSetTypeIn,
    entityTypeIn: selectedFilters.entityTypeIn,
    onlyActive: selectedFilters.onlyActive,
    [forceUnion ? 'usedByAllRetailerIdIn' : 'usedByAnyRetailerIdIn']:
      selectedFilters.usedByRetailerIdIn?.map(toQueryId),
  };
  if (selectedFilters.appliesOnPkIdIn.length > 0) {
    filters.appliesOnPkId = toQueryId(selectedFilters.appliesOnPkIdIn[0]);
  }
  if (search) {
    filters.search = search;
  }
  if (pagination) {
    filters.limit = pagination.limit;
    filters.offset = calculateOffset(pagination.currentPage, pagination.limit);
  }
  if (sort?.length) {
    filters.sort = sort;
  }
  return filters;
}

export async function fetchRule(ruleId: number): Promise<Rule | null> {
  const response = await fetchRulesList({ ruleId });
  return response.data.length ? response.data[0] : null;
}

export async function fetchRuleDimensions(rule: Rule) {
  const response = await validationApiJS.fetchRuleDimensions(rule);
  return response.data;
}

export const validationFecthOrganizationsCache =
  (ids: string[]) => async (dispatch) => {
    try {
      if (ids.length) {
        const resp = await authApi.OrganizationListV3({ ids });
        const organizations = resp.data.data.map((org) => ({
          id: org.id,
          name: org.nameLegal,
        }));
        dispatch(validationFillOrganizationsCache(organizations));
      }
    } catch (error) {
      logError(error);
    }
  };
