import React from 'react';
import referentialApi from 'resources/referentialApi';
import {
  LegacyAutocomplete,
  LegacyAutocompleteProps,
} from '../legacy-autocomplete';

export const KindAutocomplete = React.memo(function KindAutocomplete(
  props: LegacyAutocompleteProps
) {
  const onSearch = (search) => {
    const localParameters: any = { search, limit: 20 };
    localParameters.all_entities = 1;
    localParameters.include_parent = 1;
    return referentialApi.ReferentialGetList('kinds', localParameters);
  };

  return <LegacyAutocomplete onSearch={onSearch} {...props} />;
});
