import React from 'react';
import { ModuleWrapper } from 'redux/register';

import FieldsPage from './components/page';
import reducer from './reducers';
import { REDUCER_KEY } from './selectors';

export default function FieldPageContainer() {
  return (
    <ModuleWrapper reducerKey={REDUCER_KEY} reducer={reducer}>
      <FieldsPage />
    </ModuleWrapper>
  );
}

export { default as FieldsSaga } from './sagas';
