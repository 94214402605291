export const FILTER_APPEND = 'REPORTING_CONFIG/FILTER_APPEND';
export const FILTER_REMOVE = 'REPORTING_CONFIG/FILTER_REMOVE';
export const DASHBOARD_LIST_RECEIVED =
  'REPORTING_CONFIG/DASHBOARD_LIST_RECEIVED';

export const REPORTING_TABLE_RECEIVED =
  'REPORTING_CONFIG/REPORTING_TABLE_RECEIVED';
export const REPORTING_TABLE_FETCH = 'REPORTING_CONFIG/REPORTING_TABLE_FETCH';
export const REPORTING_TABLE_DONE = 'REPORTING_CONFIG/REPORTING_TABLE_DONE';
export const REPORTING_TABLE_DOWNLOAD =
  'REPORTING_CONFIG/REPORTING_TABLE_DOWNLOAD';
export const REPORTING_TABLE_DOWNLOAD_DONE =
  'REPORTING_CONFIG/REPORTING_TABLE_DOWNLOAD_DONE';
