import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List, Map } from 'immutable';

import { calculateOffset } from 'utils';
import { size } from 'utils/immutable';
import { toComparable } from 'utils/string';

import './filter.scss';
import SearchInput from './search';
import Item from './item';
import Pagination from './pagination';

export default class CatalogAdvancedFilter extends PureComponent {
  static propTypes = {
    filterKey: PropTypes.string.isRequired,
    filterLabel: PropTypes.string.isRequired,
    filters: PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.array]),
    selectedFilterMap: ImmutablePropTypes.map,
    aggregations: ImmutablePropTypes.map,
    searchQuery: PropTypes.string,
    searchPlaceholder: PropTypes.string,
    page: PropTypes.number,
    itemsPerPage: PropTypes.number,
    selectors: PropTypes.shape({
      selectId: PropTypes.func.isRequired,
      selectLabel: PropTypes.func.isRequired,
      selectIconClass: PropTypes.func,
      selectChildren: PropTypes.func,
    }).isRequired,
    onFilter: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onChangePage: PropTypes.func,
    withPagination: PropTypes.bool,
    withTree: PropTypes.bool,
  };

  static defaultProps = {
    filters: List(),
    selectedFilterMap: Map(),
    aggregations: Map(),
    searchQuery: '',
    page: 1,
    itemsPerPage: 8,
    withPagination: true,
    withTree: false,
    selectors: {
      selectId: (filter) => filter.get('id'),
      selectLabel: (filter) => filter.get('name'),
    },
  };

  onSearch = (query) => {
    if (this.props.page > 1) {
      this.props.onChangePage(this.props.filterKey, 1);
    }
    this.props.onFilter(this.props.filterKey, query);
  };

  onSelect = (filter, selected, path) => {
    const { selectId, selectLabel } = this.props.selectors;
    this.props.onChange({
      key: this.props.filterKey,
      value: selectId(filter),
      label: `${this.props.filterLabel}: ${selectLabel(filter)}`,
      add: selected,
      data: path,
    });
  };

  onChangePage = (newPage) => {
    this.props.onChangePage(this.props.filterKey, newPage);
  };

  getAggregation = (filter) =>
    this.props.aggregations.get(this.props.selectors.selectId(filter));

  getKey = (filter) =>
    `catalog-advanced-filter-${
      this.props.filterKey
    }-${this.props.selectors.selectId(filter)}`;

  getFilters() {
    let { filters } = this.props;
    if (size(filters) === 0) {
      return { filters, total: 0 };
    }
    if (this.props.searchQuery) {
      const { selectLabel } = this.props.selectors;
      filters = filters.filter((filter) => {
        const label = toComparable(selectLabel(filter));
        const search = toComparable(this.props.searchQuery);
        return label.indexOf(search) >= 0;
      });
    }
    const total = size(filters);
    if (this.props.withPagination) {
      const offset = calculateOffset(this.props.page, this.props.itemsPerPage);
      filters = filters.slice(offset, offset + this.props.itemsPerPage);
    }
    return {
      filters,
      total,
    };
  }

  isSelected = (filter) =>
    !!this.props.selectedFilterMap.get(this.props.selectors.selectId(filter));

  isIncludedInSelectedPath = (filter) =>
    this.props.withTree &&
    this.props.selectedFilterMap
      .valueSeq()
      .some((val) => val.includes(this.props.selectors.selectId(filter)));

  isPartiallySelected = (filter) =>
    this.props.withTree &&
    !this.isSelected(filter) &&
    this.isIncludedInSelectedPath(filter);

  render() {
    const { filters, total } = this.getFilters();
    return (
      <div className="CatalogAdvancedFilter">
        <SearchInput
          query={this.props.searchQuery}
          placeholder={this.props.searchPlaceholder}
          onSearch={this.onSearch}
        />
        <ul className="CatalogAdvancedFilter__items">
          {filters.map((filter) => (
            <Item
              key={this.getKey(filter)}
              filterKey={this.props.filterKey}
              filter={filter}
              aggregation={this.getAggregation(filter)}
              selected={this.isSelected(filter)}
              partiallySelected={this.isPartiallySelected(filter)}
              selectors={this.props.selectors}
              onSelect={this.onSelect}
              getKey={this.getKey}
              getAggregation={this.getAggregation}
              isSelected={this.isSelected}
              isPartiallySelected={this.isPartiallySelected}
              withTree={this.props.withTree}
              refreshTreePath={
                this.isIncludedInSelectedPath(filter)
                  ? this.props.selectedFilterMap
                  : undefined
              }
            />
          ))}
        </ul>
        {size(filters) === 0 && <p>No result found</p>}
        {this.props.withPagination && (
          <Pagination
            page={this.props.page}
            itemsPerPage={this.props.itemsPerPage}
            total={total}
            onChange={this.onChangePage}
          />
        )}
      </div>
    );
  }
}
