import { fromJS, Set } from 'immutable';
import { set } from 'lodash/fp';
import { createReducer } from 'redux-create-reducer';
import { getIn, keyBy } from 'utils/immutable';
import * as events from './events';

const getInitialState = () => ({
  sourceOrganization: null,
  editedOrganization: null,
  referentials: {
    source: null,
    edited: null,
    isDirty: false,
    selectedRootNodeId: null,
    imported: null,
    // state of the different actions (avoid concurrency)
    isSaving: false,
    isImporting: false,
    isAdding: false,
    isBranching: false,
    isUpdating: false,
  },
  fields: {
    // <fieldType>: {
    //   fieldsByName: {fieldName->fieldObject},
    //   requiredFieldNames: Set(),
    //   isLoaded: false,
    // }
  },
  isFetchingRules: false,
});

const resetReferentialsTree = (state) => ({
  ...state.referentials,
  edited: state.referentials.source,
  isDirty: false,
  isSaving: false,
  selectedRootNodeId: null,
});

export default createReducer(getInitialState(), {
  [events.SELECT_ORGANIZATION]: (state, action) => ({
    ...state,
    sourceOrganization: action.organization,
    editedOrganization: action.organization,
    // Reset referentials on organization selection
    referentials: resetReferentialsTree(state),
    fieldsWithRules: {},
  }),
  [events.IMPORT_ORGANIZATION]: (state, action) => ({
    ...state,
    editedOrganization: action.organization,
  }),
  [events.UPDATE_ORGANIZATION_FIELDS]: (state, action) => ({
    ...state,
    editedOrganization: state.editedOrganization.set(
      'usesFields',
      action.value
    ),
  }),
  [events.ORGANIZATION_SAVED]: (state) => ({
    ...state,
    sourceOrganization: state.editedOrganization,
  }),

  [events.RECEIVE_RULES]: (state, { rules, ruleSets }) => {
    const rulesByField = rules.groupBy((rule) =>
      getIn(rule, 'template.field.name')
    );

    return {
      ...state,
      sourceOrganization: state.sourceOrganization
        .set('rules', rules)
        .set('rulesByField', rulesByField)
        .set('ruleSets', ruleSets),

      editedOrganization: state.editedOrganization
        .set('rules', rules)
        .set('rulesByField', rulesByField)
        .set('ruleSets', ruleSets),
    };
  },
  [events.UPDATE_RULES]: (state, { fieldName, entityType, path, value }) => {
    const rules = getIn(state.editedOrganization, 'rules').map((rule) => {
      if (
        rule.getIn(['template', 'field', 'name']) === fieldName &&
        rule.get('entityType') === entityType
      ) {
        return rule.setIn(path, value);
      }
      return rule;
    });
    const editedOrganization = updateRules(state.editedOrganization, rules);
    return { ...state, editedOrganization };
  },
  [events.DELETE_RULES]: (state, { fieldName, entityType }) => {
    const rules = getIn(state.editedOrganization, 'rules').filter(
      (rule) =>
        rule.getIn(['template', 'field', 'name']) !== fieldName ||
        rule.get('entityType') !== entityType
    );
    const editedOrganization = updateRules(state.editedOrganization, rules);
    return { ...state, editedOrganization };
  },
  [events.CREATE_RULE]: (state, { createdRule }) => {
    const rules = getIn(state.editedOrganization, 'rules').push(createdRule);

    const editedOrganization = updateRules(state.editedOrganization, rules);

    return { ...state, editedOrganization };
  },

  // RULE SETS
  [events.UPDATE_RULESETS]: (state, { ruleSets }) => {
    const editedOrganization = updateRuleSets(
      state.editedOrganization,
      ruleSets
    );
    return { ...state, editedOrganization };
  },

  // REFERENTIALS
  [events.REFERENTIALS_FETCHED]: (state, { referentials }) => ({
    ...state,
    referentials: {
      ...state.referentials,
      source: referentials,
      edited: referentials,
      isDirty: false,
      imported: null,
    },
  }),
  [events.TOGGLE_REFERENTIAL]: (state, { referential, value }) => {
    const newState = { ...state };
    // add or remove organization from list
    const editedOrganizationId = state.editedOrganization.get('id');
    const editedOrganizationIndex = referential.organizations.findIndex(
      (organization) => organization.organization_id === editedOrganizationId
    );
    if (editedOrganizationIndex === -1 && value) {
      referential.organizations.push({
        organization_id: editedOrganizationId,
      });
    } else if (editedOrganizationIndex > -1 && !value) {
      referential.organizations.splice(editedOrganizationIndex, 1);
    }
    // replace node in tree
    const { referentials } = replaceReferential(
      state.referentials.edited,
      referential
    );
    newState.referentials.edited = referentials;
    // Mark as dirty
    newState.referentials.isDirty = true;
    return newState;
  },
  [events.SELECT_ROOT_NODE]: (state, { nodeId }) => {
    const newState = { ...state };
    // clean change and select new top node
    newState.referentials = resetReferentialsTree(state);
    newState.referentials.selectedRootNodeId = nodeId;
    return newState;
  },
  [events.REFERENTIAL_SAVED]: (state) => {
    const newState = { ...state };
    newState.referentials.source = newState.referentials.edited;
    newState.referentials.isDirty = false;
    return newState;
  },
  [events.START_SAVING]: (state) => ({
    ...state,
    referentials: { ...state.referentials, isSaving: true },
  }),
  [events.STOP_SAVING]: (state) => ({
    ...state,
    referentials: { ...state.referentials, isSaving: false },
  }),
  [events.START_IMPORTING]: (state) => ({
    ...state,
    referentials: { ...state.referentials, isImporting: true },
  }),
  [events.STOP_IMPORTING]: (state) => ({
    ...state,
    referentials: { ...state.referentials, isImporting: false },
  }),
  [events.START_ADDING]: (state) => ({
    ...state,
    referentials: { ...state.referentials, isAdding: true },
  }),
  [events.STOP_ADDING]: (state) => ({
    ...state,
    referentials: { ...state.referentials, isAdding: false },
  }),
  [events.START_BRANCHING]: (state) => ({
    ...state,
    referentials: { ...state.referentials, isBranching: true },
  }),
  [events.STOP_BRANCHING]: (state) => ({
    ...state,
    referentials: { ...state.referentials, isBranching: false },
  }),
  [events.START_UPDATING]: (state) => ({
    ...state,
    referentials: { ...state.referentials, isUpdating: true },
  }),
  [events.STOP_UPDATING]: (state) => ({
    ...state,
    referentials: { ...state.referentials, isUpdating: false },
  }),
  [events.REFERENTIALS_IMPORTED]: (state, { importedReferentials }) => ({
    ...state,
    referentials: { ...state.referentials, imported: importedReferentials },
  }),
  [events.FETCH_RULES]: set(['isFetchingRules'], true),
  [events.FETCH_RULES_DONE]: set(['isFetchingRules'], false),

  // Fields with rules
  [events.START_LOADING_FIELDS_AND_RESTRICTIONS]: (state, { fieldType }) => ({
    ...state,
    fields: {
      ...state.fields,
      [fieldType]: {
        fieldsByName: fromJS({}),
        requiredFieldNames: Set(),
        isLoaded: false,
      },
    },
  }),
  [events.RECEIVE_FIELDS_FOR_FIELD_TYPE]: (
    state,
    { fieldType, fields, requiredFieldNames }
  ) => ({
    ...state,
    fields: {
      ...state.fields,
      [fieldType]: {
        fieldsByName: keyBy(fields, (f) => f.get('name')),
        requiredFieldNames: Set(requiredFieldNames),
        isLoaded: true,
      },
    },
  }),

  // SUPPLIER REFERENTIALS
  [events.SUPPLIER_REFERENTIALS_FETCHED]: (state) => ({
    ...state,
    supplierReferentials: 'fetching',
  }),
  [events.SUPPLIER_REFERENTIALS_FETCHED]: (
    state,
    { supplierReferentials }
  ) => ({
    ...state,
    supplierReferentials,
  }),
});

function updateRules(editedOrganization, rules) {
  const rulesByField = rules.groupBy((rule) =>
    getIn(rule, 'template.field.name')
  );

  return editedOrganization
    .set('rules', rules)
    .set('rulesByField', rulesByField);
}

function updateRuleSets(editedOrganization, rulesSets) {
  return editedOrganization.set('ruleSets', rulesSets);
}

function replaceReferential(referentials, referential) {
  const matchIndex = referentials.findIndex(
    (ref) => ref.get('id') === referential.id
  );
  if (matchIndex > -1) {
    return {
      referentials: referentials.set(matchIndex, fromJS(referential)),
      found: true,
    };
  }
  for (let i = 0; i < referentials.size; i += 1) {
    const children = replaceReferential(
      getIn(referentials, [i, 'children']),
      referential
    );
    if (children.found) {
      return {
        referentials: referentials.setIn(
          [i, 'children'],
          children.referentials
        ),
        found: true,
      };
    }
  }
  return { referentials, found: false };
}
