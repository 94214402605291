import { ChangeEvent } from 'react';
import InputWithLabel from 'components/input-with-label';
import { Checkbox } from '@alkem/react-ui-inputs';

export interface Props {
  disabled?: boolean;
  fieldName: string;
  label?: string;
  ruleId: number | string;
  value: number; // 0/1
  onChange: (value: number) => void;
}

export const BooleanControl = (props: Props) => {
  const {
    disabled = false,
    fieldName,
    ruleId,
    onChange,
    value,
    label = fieldName,
  } = props;

  const handleChange = (event: ChangeEvent<Element>) => {
    const checked = (event.currentTarget as HTMLInputElement).checked;
    onChange(checked ? 1 : 0);
  };

  return (
    <InputWithLabel inputId={`rule-${ruleId}-${fieldName}`} label={label}>
      <Checkbox
        id={`rule-${ruleId}-${fieldName}`}
        checked={value}
        onChange={handleChange}
        label={<div className="ValidationRule__checkbox" />}
        disabled={disabled}
      />
    </InputWithLabel>
  );
};
