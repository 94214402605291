import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { Spinner } from '@alkem/react-ui-spinner';
import { Filter } from '@alkem/react-ui-filter';
import { TwinPanel } from '@alkem/react-ui-twinpanel';
import { Button, AddButton } from '@alkem/react-ui-button';
import { Text } from '@alkem/react-ui-inputs';

import AddFieldModal from './addFieldModal';

import {
  getReferentialGroups,
  selectReferentialGroup,
  createReferentialGroup,
  addEntitiesToReferentialGroup,
  deleteEntitiesFromReferentialGroup,
} from '../../actions';
import {
  getGroups,
  getIsLoading,
  getSelectedGroups,
  getRemainingFieldsFromGroup,
} from '../../selectors';

import './index.scss';

const mapStateToProps = (state, { referential }) => ({
  referentialGroups: getGroups(state),
  isLoading: getIsLoading(state),
  selectedGroup: getSelectedGroups(state),
  remainingFields: getRemainingFieldsFromGroup(state)(referential.get('id')),
});

export class ReferentialGroup extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    selectedGroup: ImmutablePropTypes.map,
    referential: ImmutablePropTypes.map.isRequired,
    referentialGroups: ImmutablePropTypes.list.isRequired,
    remainingFields: ImmutablePropTypes.list.isRequired,
    isLoading: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool.isRequired,
  };

  state = {
    isCreatingNewGroup: false,
    newGroupName: null,
    addFieldModalOpen: false,
    entityToDelete: null,
  };

  componentDidMount() {
    const { dispatch, referential } = this.props;
    dispatch(getReferentialGroups(referential));
  }

  onReferentialSelect = (key, group) => {
    this.props.dispatch(selectReferentialGroup(fromJS(group)));
  };

  onCreateGroup = () => {
    const { dispatch, referential } = this.props;
    const { newGroupName } = this.state;
    dispatch(createReferentialGroup(referential, newGroupName));
    this.setState({ isCreatingNewGroup: false });
  };

  onDelete = (entity) => () => {
    const { dispatch, referential, selectedGroup } = this.props;
    dispatch(
      deleteEntitiesFromReferentialGroup(referential, selectedGroup, entity)
    );
  };

  onCloseModal = () => this.setState({ addFieldModalOpen: false });

  addFields = (fields) => {
    const { dispatch, referential, selectedGroup } = this.props;
    dispatch(addEntitiesToReferentialGroup(referential, selectedGroup, fields));
    this.onCloseModal();
  };

  openFieldModal = () => this.setState({ addFieldModalOpen: true });

  setIsCreatingNewGroup = () => this.setState({ isCreatingNewGroup: true });

  setNewGroupName = (e) => this.setState({ newGroupName: e.target.value });

  renderReferentialList() {
    const { referentialGroups, readOnly } = this.props;
    const { newGroupName, isCreatingNewGroup } = this.state;
    return (
      <div id="ReferentialsList">
        {!isCreatingNewGroup && !readOnly && (
          <AddButton onClick={this.setIsCreatingNewGroup}>Add</AddButton>
        )}
        {isCreatingNewGroup && (
          <div className="ReferentialsList__createGroup">
            <Text
              id="referential-group-group-name"
              value={newGroupName}
              placeholder="new group name"
              onChange={this.setNewGroupName}
            />
            <Button
              id="ReferentialDetail__ResetButton"
              onClick={this.onCreateGroup}
              key="discard"
              primary
              content="ok"
              disabled={newGroupName === ''}
            />
          </div>
        )}
        <Filter
          name="Referential"
          type="list"
          filters={referentialGroups.toJS().map((g) => ({
            ...g,
            label: g.value,
          }))}
          onChange={this.onReferentialSelect}
        />
      </div>
    );
  }

  renderReferentialTitle() {
    const { selectedGroup } = this.props;
    let content = 'Pick a referential on the left';
    if (selectedGroup) {
      content = selectedGroup.get('label');
    }

    return (
      <div className="ReferentialDetail__Header">
        <h2 className="ReferentialDetail__Title">{content}</h2>
      </div>
    );
  }

  renderReferential() {
    const { selectedGroup, readOnly } = this.props;
    const { addFieldModalOpen, entityToDelete } = this.state;
    if (!selectedGroup) {
      return null;
    }
    return (
      <div>
        {!readOnly && <AddButton onClick={this.openFieldModal}>Add</AddButton>}
        <div className="ReferentialGroup__rows">
          {selectedGroup.get('entities').map((entity) => (
            <div key={entity.get('code')} className="ReferentialGroup__row">
              <span>{entity.get('code')}</span>
              <span>{entity.get('label')}</span>
              {entityToDelete &&
                entityToDelete.get('id') === entity.get('id') &&
                !readOnly && (
                  <div className="ReferentialGroup__row_delete">
                    <span>
                      <i
                        className="mdi mdi-close ReferentialGroup__row_delete--revertDelete"
                        onClick={() => this.setState({ entityToDelete: null })}
                      />
                    </span>
                    <span>
                      <i
                        className="mdi mdi-check ReferentialGroup__row_delete--acceptDelete"
                        onClick={this.onDelete(entityToDelete)}
                      />
                    </span>
                  </div>
                )}
              {(!entityToDelete ||
                entityToDelete.get('id') !== entity.get('id')) &&
                !readOnly && (
                  <div className="ReferentialGroup__row_delete">
                    <i
                      className="mdi mdi-delete"
                      onClick={() => this.setState({ entityToDelete: entity })}
                    />
                  </div>
                )}
            </div>
          ))}
          {addFieldModalOpen && (
            <AddFieldModal
              fields={this.props.remainingFields}
              onAdd={this.addFields}
              onClose={this.onCloseModal}
            />
          )}
        </div>
      </div>
    );
  }

  render() {
    if (this.props.isLoading) {
      return (
        <center style={{ paddingTop: '100px' }}>
          <Spinner loading big />
        </center>
      );
    }
    if (!this.props.referentialGroups) {
      return null;
    }
    return (
      <div>
        <TwinPanel
          leftPanel={{
            title: 'Referentials',
            content: this.renderReferentialList(),
          }}
          rightPanel={{
            title: this.renderReferentialTitle(),
            content: this.renderReferential(),
            className: 'FieldDashboardView__panel--right',
          }}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps)(ReferentialGroup);
