import { Text } from '@alkem/react-ui-inputs';
import { Modal } from '@alkem/react-ui-modal';
import { SimpleSelect } from '@alkem/react-ui-select';
import InputWithLabel from 'components/input-with-label';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { separateActions } from 'utils/redux';
import { closeCISModal, saveGDSNCIS, updateGDSNCISAttr } from '../actions';
import { selectModal } from '../selectors';
import './modal.scss';

const mapStateToProps = (state) => ({
  cis: selectModal(state).cis,
});

const mapDispatchToProps = {
  saveGDSNCIS,
  updateGDSNCISAttr,
  closeCISModal,
};

const documentCommandTypes = [
  {
    value: 'ADD',
    label: 'ADD',
  },
  {
    value: 'DELETE',
    label: 'DELETE',
  },
];

export class GDSNCISModal extends Component {
  static propTypes = {
    cis: PropTypes.object,
    actions: PropTypes.shape({
      closeCISModal: PropTypes.func.isRequired,
      saveGDSNCIS: PropTypes.func.isRequired,
      updateGDSNCISAttr: PropTypes.func.isRequired,
    }).isRequired,
  };

  onGlnChange = (event) => {
    this.props.actions.updateGDSNCISAttr(['gln'], event.target.value);
  };

  onDataRecipientChange = (event) => {
    this.props.actions.updateGDSNCISAttr(['dataRecipient'], event.target.value);
  };

  onTargetMarketCountryCodeChange = (event) => {
    this.props.actions.updateGDSNCISAttr(
      ['targetMarketCountryCode'],
      event.target.value
    );
  };

  onDocumentCommandTypeChange = (event) => {
    this.props.actions.updateGDSNCISAttr(['documentCommand_type'], event.value);
  };

  onGpcCategoryCodeChange = (event) => {
    this.props.actions.updateGDSNCISAttr(
      ['gpcCategoryCode'],
      event.target.value
    );
  };

  onGtinChange = (event) => {
    this.props.actions.updateGDSNCISAttr(['gtin'], event.target.value);
  };

  render() {
    const { actions, cis } = this.props;
    const title = cis.get('id')
      ? `Updating Subscription ${cis.get('name')} (${cis.get('id')})`
      : 'Create Subscription';
    return (
      <Modal
        title={title}
        confirmButtonText="Save"
        onConfirm={actions.saveGDSNCIS}
        onClose={actions.closeCISModal}
      >
        <div className="GDSNCISModal_Body">
          <div className="GDSNCISModal__Form">
            <div className="GDSNCISModal__Line">
              <InputWithLabel
                inputId={`cis-${cis.get('id')}-gln`}
                label="Manufacturer GLN (source)"
              >
                <Text
                  id={`cis-${cis.get('id')}-gln`}
                  value={cis.get('gln')}
                  placeholder="Manufacturer GLN (source)"
                  onChange={this.onGlnChange}
                />
              </InputWithLabel>
              <InputWithLabel
                inputId={`cis-${cis.get('id')}-dataRecipient`}
                label="Retailer GLN (recipient)"
              >
                <Text
                  id={`cis-${cis.get('id')}-dataRecipient`}
                  value={cis.get('dataRecipient')}
                  placeholder="Retailer GLN (recipient)"
                  onChange={this.onDataRecipientChange}
                />
              </InputWithLabel>
              <InputWithLabel
                inputId={`cis-${cis.get('id')}-targetMarketCountryCode`}
                label="Target market"
              >
                <Text
                  id={`cis-${cis.get('id')}-targetMarketCountryCode`}
                  value={cis.get('targetMarketCountryCode')}
                  placeholder="Target market *"
                  onChange={this.onTargetMarketCountryCodeChange}
                />
              </InputWithLabel>
              <InputWithLabel
                inputId={`cis-${cis.get('id')}-documentCommand_type`}
                label="Command Type"
                extraInputClassNames={{ EnpointModal_SelectMargin: true }}
              >
                <SimpleSelect
                  id={`cis-${cis.get('id')}-documentCommand_type_slct`}
                  options={documentCommandTypes}
                  value={documentCommandTypes.find(
                    (o) => o.value === cis.get('documentCommand_type')
                  )}
                  onSelect={this.onDocumentCommandTypeChange}
                  autoSize
                />
              </InputWithLabel>
              <hr />
              <InputWithLabel
                inputId={`cis-${cis.get('id')}-gpcCategoryCode`}
                label="GPC"
              >
                <Text
                  id={`cis-${cis.get('id')}-gpcCategoryCode`}
                  value={cis.get('gpcCategoryCode')}
                  placeholder="GPC *"
                  onChange={this.onGpcCategoryCodeChange}
                />
              </InputWithLabel>
              <InputWithLabel
                inputId={`cis-${cis.get('id')}-gtin`}
                label="GTIN"
              >
                <Text
                  id={`cis-${cis.get('id')}-gtin`}
                  value={cis.get('gtin')}
                  placeholder="GTIN *"
                  onChange={this.onGtinChange}
                />
              </InputWithLabel>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(GDSNCISModal);
