import { createReducer } from 'redux-create-reducer';
import { fromJS } from 'immutable';

import {
  USER_UPDATE_MODAL_OPEN,
  USER_UPDATE_MODAL_CLOSE,
  USER_UPDATE_MODAL_LOADING,
  USER_UPDATE_RESET,
  USER_UPDATE_FORM_UPDATE_INPUT,
  USER_UPDATE_MODAL_TOKENS_RECEIVED,
} from '../constants/events';

const initialState = fromJS({
  user: null,
  isOpen: false,
  isLoading: false,
  isEdited: false,
  form: {
    firstname: null,
    lastname: null,
    title: null,
    username: null,
    phonenumber: null,
    organization: null,
    organizationRole: null,
  },
});

export default createReducer(initialState, {
  [USER_UPDATE_FORM_UPDATE_INPUT]: (state, { payload }) =>
    state.setIn(['form', payload.key], payload.value),
  [USER_UPDATE_MODAL_OPEN]: (state, { payload }) => {
    const belongsTo = payload.getIn(['belongsTo', 0]);
    const organization = {};
    const organizationRole = {};

    if (belongsTo) {
      organization.key = belongsTo.get('id');
      organization.value = belongsTo.get('id');
      organization.label = belongsTo.get('nameLegal');
      organization.source = belongsTo.get('source');
      const role =
        belongsTo.get('permissions').size &&
        belongsTo.getIn(['permissions', 0]);
      if (role) {
        organizationRole.key = role;
        organizationRole.value = role;
        organizationRole.label = role.toUpperCase();
      }
    }

    return state
      .set('isOpen', true)
      .set('isLoading', false)
      .set('isEdited', false)
      .set('user', payload)
      .setIn(['form', 'firstname'], payload.get('firstname'))
      .setIn(['form', 'lastname'], payload.get('lastname'))
      .setIn(['form', 'title'], payload.get('title'))
      .setIn(['form', 'username'], payload.get('username'))
      .setIn(['form', 'phonenumber'], payload.get('phonenumber'))
      .setIn(['form', 'organization'], fromJS(organization))
      .setIn(['form', 'organizationRole'], fromJS(organizationRole))
      .setIn(['form', 'type'], payload.get('type'));
  },
  [USER_UPDATE_MODAL_CLOSE]: (state, { payload = false }) =>
    state.set('isOpen', false).set('isEdited', payload),
  [USER_UPDATE_MODAL_LOADING]: (state, { payload }) =>
    state.set('isLoading', payload),
  [USER_UPDATE_RESET]: () => initialState,
  [USER_UPDATE_MODAL_TOKENS_RECEIVED]: (state, { payload }) =>
    state.set('tokens', payload),
});
