import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { separateActions } from 'utils/redux';
import SelectedFilters from 'components/ui/selected-filters';

import { ListAutocomplete } from 'components/autocomplete';

import { selectFilters } from '../selectors';

import {
  addGDSNPSRListFilters,
  removeGDSNPSRListFilters,
  updateGDSNPSRListFilters,
  clearGDSNPSRListFilters,
} from '../actions';

const mapStateToProps = (state) => ({
  filters: selectFilters(state),
});

const mapDispatchToProps = {
  addGDSNPSRListFilters,
  removeGDSNPSRListFilters,
  updateGDSNPSRListFilters,
  clearGDSNPSRListFilters,
};

class GDSNPSRListFilters extends PureComponent {
  static propTypes = {
    filters: PropTypes.object.isRequired,
    actions: PropTypes.shape({
      addGDSNPSRListFilters: PropTypes.func.isRequired,
      removeGDSNPSRListFilters: PropTypes.func.isRequired,
      updateGDSNPSRListFilters: PropTypes.func.isRequired,
      clearGDSNPSRListFilters: PropTypes.func.isRequired,
    }).isRequired,
  };

  componentDidMount() {}

  onAddGLN = (search) => {
    this.props.actions.addGDSNPSRListFilters('filter_gln', {
      label: search,
      value: search,
    });
  };

  onAddGTIN = (search) => {
    this.props.actions.addGDSNPSRListFilters('filter_gtins_in', {
      label: search,
      value: search,
    });
  };

  render() {
    const { filters } = this.props;
    return (
      <div>
        <SelectedFilters
          filters={filters}
          removeFilter={this.props.actions.removeGDSNPSRListFilters}
          removeAllFilters={this.props.actions.clearGDSNPSRListFilters}
        />
        <h4>GLN</h4>
        <div className="card">
          <ListAutocomplete
            id="list-autocomplete-gln-source"
            onAdd={this.onAddGLN}
            values={[]}
            value={filters.filter_gln}
            addMissingOptionLabel="Search..."
          />
        </div>
        <h4>GTIN</h4>
        <div className="card">
          <ListAutocomplete
            id="list-autocomplete-gln-recipient"
            onAdd={this.onAddGTIN}
            values={[]}
            value={filters.filter_gtins_in}
            addMissingOptionLabel="Search..."
          />
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(GDSNPSRListFilters);
