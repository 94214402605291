import { MODULE_REDUCER_KEY } from 'constants/reducer';
import { REDUCER_KEY } from './reducers';
import { get } from 'lodash/fp';

export function selectState(state) {
  return state[MODULE_REDUCER_KEY][REDUCER_KEY];
}

export function selectConfigSources(state) {
  return get(['configSources'], selectState(state));
}

export function selectBufferedConfigs(state) {
  return get(['bufferedConfigs'], selectState(state));
}

export function selectBatchConfigs(state) {
  return get(['batchConfigs'], selectState(state));
}

export function selectAvailableBufferedConfigSources(state) {
  const used = selectBufferedConfigs(state).map((c) => c.source);
  return selectConfigSources(state).filter((s) => !used.includes(s.id));
}

export function selectAvailableBatchConfigSources(state) {
  const used = selectBatchConfigs(state).map((c) => c.source);
  return selectConfigSources(state).filter((s) => !used.includes(s.id));
}
