import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Map } from 'immutable';
import classNames from 'classnames';

import { Checkbox } from '@alkem/react-ui-checkbox';

import { size } from 'utils/immutable';

import './item.scss';

export default class CatalogAdvancedFilterItem extends PureComponent {
  static propTypes = {
    filter: PropTypes.oneOfType([ImmutablePropTypes.map, PropTypes.object])
      .isRequired,
    filterKey: PropTypes.string.isRequired,
    aggregation: ImmutablePropTypes.map,
    selected: PropTypes.bool,
    partiallySelected: PropTypes.bool,
    disabled: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
    selectors: PropTypes.shape({
      selectId: PropTypes.func.isRequired,
      selectLabel: PropTypes.func.isRequired,
      selectIconClass: PropTypes.func,
      selectChildren: PropTypes.func,
    }).isRequired,
    withTree: PropTypes.bool,
    getKey: PropTypes.func,
    getAggregation: PropTypes.func,
    isSelected: PropTypes.func,
    isPartiallySelected: PropTypes.func,
    refreshTreePath: ImmutablePropTypes.map,
    collapsed: PropTypes.bool,
  };

  static defaultProps = {
    aggregation: Map(),
    selectors: {
      selectId: (filter) => filter.get('id'),
      selectLabel: (filter) => filter.get('name'),
    },
    disabled: false,
    withTree: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      collapsed: typeof props.collapsed === 'boolean' ? props.collapsed : true,
    };
  }

  onSelect = () => {
    this.props.onSelect(
      this.props.filter,
      !this.props.isSelected(this.props.filter),
      [this.props.selectors.selectId(this.props.filter)]
    );
  };

  onSelectChild = (child, selected, path = []) => {
    this.props.onSelect(child, !this.props.isSelected(child), [
      this.props.selectors.selectId(this.props.filter),
      ...path,
    ]);
  };

  onCollapse = () => {
    this.setState((state) => ({
      collapsed: !state.collapsed,
    }));
  };

  render() {
    const { selectId, selectLabel } = this.props.selectors;
    const selectIconClass =
      this.props.selectors.selectIconClass || function () {};
    const selectChildren =
      this.props.selectors.selectChildren || function () {};
    const count = this.props.aggregation.get('doc_count');
    const filterChildren = selectChildren(this.props.filter);
    const hasChildren = !!filterChildren && size(filterChildren) > 0;
    return (
      <div className="CatalogAdvancedFilterItem__wrapper">
        <div className="CatalogAdvancedFilterItem">
          {this.props.withTree && (
            <i
              className={classNames('CatalogAdvancedFilterItem__chevron mdi', {
                'mdi-chevron-right': hasChildren && this.state.collapsed,
                'mdi-chevron-down': hasChildren && !this.state.collapsed,
              })}
              onClick={this.onCollapse}
            />
          )}
          <Checkbox
            id={`catalog-advanced-filter-${this.props.filterKey}-${selectId(
              this.props.filter
            )}`}
            label={
              <span className="CatalogAdvancedFilterItem__content">
                {!!selectIconClass(this.props.filter) && (
                  <i
                    className={classNames(
                      'CatalogAdvancedFilterItem__icon',
                      selectIconClass(this.props.filter)
                    )}
                  />
                )}
                <span className="CatalogAdvancedFilterItem__label">
                  <span>{selectLabel(this.props.filter)}</span>
                  {count >= 0 && <span>{` (${count})`}</span>}
                </span>
              </span>
            }
            checked={!!this.props.selected}
            partiallyChecked={!!this.props.partiallySelected}
            disabled={!!this.props.disabled}
            onChange={this.onSelect}
          />
        </div>
        {this.props.withTree && hasChildren && !this.state.collapsed && (
          <ul className="CatalogAdvancedFilter__items CatalogAdvancedFilter__items--children">
            {filterChildren.map((child) => (
              <CatalogAdvancedFilterItem
                key={this.props.getKey(child)}
                filterKey={this.props.filterKey}
                filter={child}
                aggregation={this.props.getAggregation(child)}
                selected={this.props.isSelected(child) || !!this.props.selected}
                disabled={!!this.props.selected}
                partiallySelected={this.props.isPartiallySelected(child)}
                selectors={this.props.selectors}
                onSelect={this.onSelectChild}
                getKey={this.props.getKey}
                getAggregation={this.props.getAggregation}
                isSelected={this.props.isSelected}
                isPartiallySelected={this.props.isPartiallySelected}
                withTree={this.props.withTree}
                refreshTreePath={this.props.refreshTreePath}
                collapsed={this.props.collapsed}
              />
            ))}
          </ul>
        )}
      </div>
    );
  }
}
