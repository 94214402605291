import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { CodeEditor } from 'components/code-editor';
import InputWithLabel from 'components/input-with-label';
import { Text } from '@alkem/react-ui-inputs';
import { Button } from '@alkem/react-ui-button';
import { notificationError } from 'actions/notifications';
import etlApi from 'resources/etlApi';
import { ImportMapping } from '../../../types';

import './item-test.scss';
import { getChildrenForApi, getFallbackForApi } from '../../../utils';
import { SET_IMPORT_MAPPING_PROPERTY_IS_TESTED } from '../../../actions/constants';

interface Props {
  fieldName: string;
  importMapping: ImportMapping;
}
export function ImportMappingsItemTest({
  fieldName,
  importMapping,
}: Props): JSX.Element {
  const dispatch = useDispatch();
  const [xmlTest, setXmlTest] = useState('');
  const [jsonResult, setJsonResult] = useState('');
  const [targetMarket, setTargetMarket] = useState('250');
  const [isTesting, setIsTesting] = useState(false);

  const setImportMappingIsTested = () => {
    dispatch({
      type: SET_IMPORT_MAPPING_PROPERTY_IS_TESTED,
      payload: {
        importMappingId: importMapping.id,
        isTested: true,
      },
    });
  };

  const createJson = async () => {
    setIsTesting(true);

    try {
      const details = {
        ...importMapping.details,
        xpath:
          importMapping.details.xpath.length === 1
            ? importMapping.details.xpath[0]
            : importMapping.details.xpath,
        fallback: getFallbackForApi(importMapping.details.fallback),
        children: getChildrenForApi(importMapping.details.children),
      };
      for (const key of Object.keys(details)) {
        if (
          !details[key] &&
          ['undefined', 'string'].includes(typeof details[key])
        ) {
          delete details[key];
        }
      }
      const res = await etlApi.post(
        '/etl/v2/gdsndashboard/test/mapping',
        {
          xml_test: xmlTest,
          target_market: targetMarket,
          db_field_unit: importMapping.unit,
          db_field_name: importMapping.field,
          db_field_date_limit: importMapping.dateStart,
          db_field_details: {
            ...details,
          },
        },
        false
      );
      setJsonResult(JSON.stringify(res.data, null, 2));
      setImportMappingIsTested();
    } catch (error) {
      dispatch(
        notificationError(
          'There was an error while testing. Have a look at your console to find out what it was.'
        )
      );
      console.error(error); // eslint-disable-line no-console
    } finally {
      setIsTesting(false);
    }
  };

  return (
    <div
      className="GDSNImportMappingsItemTest"
      data-testid="GDSNImportMappingsItem_test"
    >
      <div className="GDSNImportMappingsItemTest__sectionTitle">Test</div>
      <InputWithLabel
        inputId={`gdsn-import-mappings-field-${fieldName}-test`}
        label="Target market"
      >
        <Text
          id={`gdsn-import-mappings-field-${fieldName}-test`}
          value={targetMarket}
          onChange={(event: { target: { value: string } }) =>
            setTargetMarket(event.target.value)
          }
        />
      </InputWithLabel>
      <div className="GDSNImportMappingsItemTest__codeEditorsContainer">
        <CodeEditor
          name="xmlTest"
          className="GDSNImportMappingsItemTest__Editor__xml"
          value={xmlTest}
          mode="xml"
          theme="monokai"
          width="50%"
          height="300px"
          editorProps={{ $blockScrolling: Infinity }}
          onChange={setXmlTest}
          placeholder="Write your xml here"
        />
        <CodeEditor
          name="jsonResult"
          className="GDSNImportMappingsItemTest__Editor__json"
          value={jsonResult}
          mode="json"
          theme="monokai"
          width="50%"
          height="300px"
          editorProps={{ $blockScrolling: Infinity }}
          placeholder="The json result will be displayed here"
        />
      </div>
      <div className="GDSNImportMappingsItemTest__buttonsContainer">
        <Button
          content="Test"
          onClick={createJson}
          primary
          disabled={isTesting}
          displaySpinner={isTesting}
        />
      </div>
    </div>
  );
}
