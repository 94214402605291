import * as events from '../constants/events';

export const receiveGDSNPartyList = (list, totalResults) => ({
  type: events.RECEIVE_GDSN_PARTY_LIST,
  payload: {
    list,
    totalResults,
  },
});

export const receiveGDSNPartyListFilters = (data) => ({
  type: events.RECEIVE_GDSN_PARTY_LIST_FILTERS,
  payload: {
    data,
  },
});

export const getGDSNPartyList = () => ({
  type: events.GET_GDSN_PARTY_LIST,
});

export const getGDSNPartyListFilters = () => ({
  type: events.GET_GDSN_PARTY_LIST_FILTERS,
});

export const changeLimit = (limit) => ({
  type: events.CHANGE_LIMIT_GDSN_PARTY_LIST,
  limit,
});

export const nextPage = () => ({
  type: events.NEXT_PAGE_GDSN_PARTY_LIST,
});

export const previousPage = () => ({
  type: events.PREVIOUS_PAGE_GDSN_PARTY_LIST,
});

export const addGDSNPartyListFilters = (key, value) => ({
  type: events.ADD_GDSN_PARTY_LIST_FILTER,
  key,
  value,
});

export const removeGDSNPartyListFilters = (key, value) => ({
  type: events.REMOVE_GDSN_PARTY_LIST_FILTER,
  key,
  value,
});

export const updateGDSNPartyListFilters = (key, value) => ({
  type: events.UPDATE_GDSN_PARTY_LIST_FILTER,
  key,
  value,
});

export const clearGDSNPartyListFilters = (key, value) => ({
  type: events.CLEAR_GDSN_PARTY_LIST_FILTER,
  key,
  value,
});

export const downloadGDSNDocument = (documentID) => ({
  type: events.DOWNLOAD_GDSN_PARTY_DOCUMENT,
  documentID,
});

export const openPartyModal = (party) => ({
  type: events.OPEN_GDSN_PARTY_MODAL,
  party,
});

export const closePartyModal = () => ({
  type: events.CLOSE_GDSN_PARTY_MODAL,
});

export const saveGDSNParty = () => ({
  type: events.GDSN_PARTY_MODAL_SAVE,
});

export const updateGDSNPartyAttr = (key, value) => ({
  type: events.GDSN_PARTY_ATTR_UPDATE,
  payload: {
    key,
    value,
  },
});

export const sendDeleteParty = (party) => ({
  type: events.GDSN_PARTY_SEND_DELETE,
  party,
});
