import { createReducer } from 'redux-create-reducer';
import { set } from 'lodash/fp';
import { OrganizationGroupFieldState } from './type';
import {
  CREATE_FIELD,
  ON_FIELD_CHANGE,
  ORGANIZATION_FIELD_GROUP_LOADED,
  SET_ORGANIZATION,
  ON_ORGANIZATION_FIELD_GROUP_CHANGE,
  DELETE_FIELD,
  FIELD_GROUPS_FOR_IMPORT_LOADED,
  LOAD_FIELD_GROUPS_FOR_IMPORT,
  ORGANIZATION_LOADED,
  DEFAULT_ORGANIZATION_GROUP_LOADED,
} from './constants';

const initialState: OrganizationGroupFieldState = {
  organization: undefined,
  groups: [],
  importModal: undefined,
  defaultFieldGroup: 0,
};

export default createReducer(initialState, {
  [SET_ORGANIZATION]: (_, { organization }) => ({
    ...initialState,
    organization,
  }),
  [ORGANIZATION_LOADED]: (state, { organization }) => ({
    ...state,
    organization,
  }),
  [ORGANIZATION_FIELD_GROUP_LOADED]: (state, { groups }) => ({
    ...state,
    groups,
  }),
  [DEFAULT_ORGANIZATION_GROUP_LOADED]: (state, { data }) => {
    return {
      ...state,
      defaultFieldGroup: data,
    };
  },
  [LOAD_FIELD_GROUPS_FOR_IMPORT]: set('importModal', {}),
  [FIELD_GROUPS_FOR_IMPORT_LOADED]: (state, { groups }) => ({
    ...state,
    importModal: {
      groups,
    },
  }),
  [CREATE_FIELD]: (state, action) => {
    const field = {
      name: '',
      root: undefined,
      review_on_creation: false,
      review_on_update: false,
    };
    state.groups[action.fieldIndex].fields.push(field);
    return {
      ...state,
      groups: set(
        [action.fieldIndex, 'fields'],
        state.groups[action.fieldIndex].fields,
        state.groups
      ),
    };
  },
  [ON_FIELD_CHANGE]: (state, action) => {
    const { fieldGroupIndex, fieldIndex, path, value } = action;
    const fields = set(
      [fieldIndex, path],
      value,
      state.groups[fieldGroupIndex].fields
    );
    return {
      ...state,
      groups: set([fieldGroupIndex, 'fields'], fields, state.groups),
    };
  },
  [DELETE_FIELD]: (state, action) => {
    const { fieldGroupIndex, fieldIndex } = action;
    const fields = state.groups[fieldGroupIndex].fields;
    fields.splice(fieldIndex, 1);
    return {
      ...state,
      groups: set([fieldGroupIndex, 'fields'], fields, state.groups),
    };
  },
  [ON_ORGANIZATION_FIELD_GROUP_CHANGE]: (state, action) => {
    const { fieldGroupIndex, path, value } = action;
    const group = set(path, value, state.groups[fieldGroupIndex]);
    return { ...state, groups: set([fieldGroupIndex], group, state.groups) };
  },
});
