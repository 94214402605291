import { combineReducers } from 'redux';
import {
  APPLICATIONS_REDUCER_KEY,
  BILLING_REDUCER_KEY,
  INFOS_REDUCER_KEY,
  MAIN_REDUCER_KEY,
  PERMISSIONS_REDUCER_KEY,
  RECIPIENTS_REDUCER_KEY,
  SAVE_MODAL_REDUCER_KEY,
  SETTINGS_REDUCER_KEY,
  SSO_REDUCER_KEY,
  USERS_REDUCER_KEY,
} from '../constants';
import applicationsReducer from './applications';
import billingReducer from './billing';
import infosReducer from './infos';
import mainReducer from './main';
import recipientsReducer from './recipients';
import saveModalReducer from './save-modal';
import settingsReducer from './settings';
import usersReducer from './users';
import { ssoReducer } from './sso';
import { permissionsReducer } from './permissions';

export default combineReducers({
  [MAIN_REDUCER_KEY]: mainReducer,
  [SAVE_MODAL_REDUCER_KEY]: saveModalReducer,
  [INFOS_REDUCER_KEY]: infosReducer,
  [SETTINGS_REDUCER_KEY]: settingsReducer,
  [RECIPIENTS_REDUCER_KEY]: recipientsReducer,
  [USERS_REDUCER_KEY]: usersReducer,
  [APPLICATIONS_REDUCER_KEY]: applicationsReducer,
  [BILLING_REDUCER_KEY]: billingReducer,
  [SSO_REDUCER_KEY]: ssoReducer,
  [PERMISSIONS_REDUCER_KEY]: permissionsReducer,
});
