import { Map } from 'immutable';
import { createReducer } from 'utils/reducers';
import { LOGOUT, RECEIVE_PROFILE } from 'modules/auth/constants';

const initialState = Map({});

export default createReducer(initialState, {
  [RECEIVE_PROFILE]: (state, action) => state.merge(action.user),
  [LOGOUT]: () => initialState,
});

export const selectUser = (state) => state.user;
