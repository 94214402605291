// List
export const RECEIVE_GDSN_RELATION_LIST = 'RECEIVE_GDSN_RELATION_LIST';
export const GET_GDSN_RELATION_LIST = 'GET_GDSN_RELATION_LIST';
export const GET_GDSN_RELATION_LIST_FILTERS = 'GET_GDSN_RELATION_LIST_FILTERS';
export const CHANGE_LIMIT_GDSN_RELATION_LIST =
  'CHANGE_LIMIT_GDSN_RELATION_LIST';
export const NEXT_PAGE_GDSN_RELATION_LIST = 'NEXT_PAGE_GDSN_RELATION_LIST';
export const PREVIOUS_PAGE_GDSN_RELATION_LIST =
  'PREVIOUS_PAGE_GDSN_RELATION_LIST';

// Filters
export const ADD_GDSN_RELATION_LIST_FILTER = 'ADD_GDSN_RELATION_LIST_FILTER';
export const REMOVE_GDSN_RELATION_LIST_FILTER =
  'REMOVE_GDSN_RELATION_LIST_FILTER';
export const UPDATE_GDSN_RELATION_LIST_FILTER =
  'UPDATE_GDSN_RELATION_LIST_FILTER';
export const CLEAR_GDSN_RELATION_LIST_FILTER =
  'CLEAR_GDSN_RELATION_LIST_FILTER';

// Document
export const DOWNLOAD_GDSN_RELATION_DOCUMENT =
  'DOWNLOAD_GDSN_RELATION_DOCUMENT';

// Relation
export const DELETE_GDSN_RELATION = 'DELETE_GDSN_RELATION';
