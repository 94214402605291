import { Modal } from '@alkem/react-ui-modal';
import { CodeEditor } from 'components/code-editor';
import copy from 'copy-to-clipboard';
import PropTypes from 'prop-types';
import React from 'react';

const onClickCopyToClipboard = (value) => () => {
  copy(value);
};

const ErrorModal = (props) => {
  let formattedMessage;
  try {
    formattedMessage = JSON.stringify(JSON.parse(props.errorMessage), null, 4);
  } catch (error) {
    formattedMessage = props.errorMessage;
  }
  return (
    <Modal
      title="Error message"
      confirmButtonText={
        <div>
          <span>Copy to clipboard</span>{' '}
          <i className="mdi mdi-clipboard-text" />
        </div>
      }
      onConfirm={onClickCopyToClipboard(props.errorMessage)}
      closeButtonText="Close"
      onClose={props.onCloseModal}
    >
      {props.errorMessage && (
        <span>
          <pre>{formattedMessage}</pre>
          <br />
        </span>
      )}
      {props.stackTrace && (
        <blockquote>
          <pre className="StackTraceModal_Code">
            <code>{props.stackTrace}</code>
          </pre>
        </blockquote>
      )}
      {props.payload !== undefined && (
        <span>
          <h3>Pushed payload</h3>
          <CodeEditor
            wrapEnabled
            name="payload-info"
            className="PayloadDetailsErrorModal"
            value={JSON.stringify(props.payload, null, 2)}
            mode="json"
            theme="monokai"
            width="100%"
            height={props.stackTrace || props.errorMessage ? '60%' : '100%'}
            fontSize="18px"
            readOnly
            editorProps={{ $blockScrolling: Infinity }}
          />
        </span>
      )}
    </Modal>
  );
};

ErrorModal.propTypes = {
  errorMessage: PropTypes.string,
  onCloseModal: PropTypes.func,
  stackTrace: PropTypes.string,
  payload: PropTypes.object,
};

export default ErrorModal;
