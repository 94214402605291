import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { separateActions } from 'utils/redux';

import { Map } from 'immutable';
import OrganizationFilter from 'components/ui/filter/organization';
import Filter from 'components/ui/filter';

import { updateFilter } from '../actions';
import {
  selectOrganizations,
  selectStatuses,
  selectImporters,
  selectOrganizationSelected,
  selectStatusSelected,
  selectImporterSelected,
  selectDateFromSelected,
  selectDateToSelected,
} from '../selectors';

const mapStateToProps = (state) => ({
  organizations: selectOrganizations(state),
  statuses: selectStatuses(state),
  importers: selectImporters(state),
  filter_organization_id: selectOrganizationSelected(state),
  filter_status: selectStatusSelected(state),
  filter_importer: selectImporterSelected(state),
  filter_date_from: selectDateFromSelected(state),
  filter_date_to: selectDateToSelected(state),
});

const mapDispatchToProps = {
  updateFilter,
};

class ImportGenericFilters extends PureComponent {
  static propTypes = {
    organizations: PropTypes.object,
    statuses: PropTypes.object,
    importers: PropTypes.object,
    filter_organization_id: PropTypes.number,
    filter_status: PropTypes.string,
    filter_importer: PropTypes.string,
    filter_date_from: PropTypes.string,
    filter_date_to: PropTypes.string,
    actions: PropTypes.shape({
      updateFilter: PropTypes.func.isRequired,
    }).isRequired,
  };

  onClickOrganizationFilter = (filter, newValue) => {
    this.props.actions.updateFilter(
      'organization_id',
      newValue === false ? null : filter.get('id')
    );
  };

  onClickStatusFilter = (name, newValue) => {
    this.props.actions.updateFilter(
      'status',
      newValue.value === 'ALL' ? null : newValue.value
    );
  };

  onClickImporterFilter = (name, newValue) => {
    this.props.actions.updateFilter(
      'importer',
      newValue.checked === true ? null : newValue.value
    );
  };

  onClickDateFilter = (name, newValue) => {
    this.props.actions.updateFilter(newValue.name, newValue.value);
  };

  render() {
    const {
      organizations,
      statuses,
      importers,
      filter_organization_id,
      filter_status,
      filter_importer,
      filter_date_from,
      filter_date_to,
    } = this.props;

    let organizationSelected = Map();
    if (filter_organization_id) {
      organizationSelected = organizationSelected.set(
        filter_organization_id,
        true
      );
    }

    const statusesList = statuses.toJS().map((e) => ({
      label: e,
      value: e,
      statusBarType: e,
      checked: e === filter_status,
    }));
    statusesList.unshift({
      label: 'ALL',
      value: 'ALL',
      statusBarType: 'EMPTY',
      checked: filter_status === null,
    });

    const importersList = importers.toJS().map((e) => ({
      label: e,
      value: e,
      statusBarType: e,
      checked: e === filter_importer,
    }));

    return (
      <div>
        <div className="card">
          <OrganizationFilter
            id="import-generic-filter-organization"
            filters={organizations}
            selection={organizationSelected}
            onChange={this.onClickOrganizationFilter}
            title={
              <span>
                <i className="mdi mdi-account-group" /> Organizations
              </span>
            }
          />
          <Filter
            type="list"
            filters={statusesList}
            onChange={this.onClickStatusFilter}
            name="status"
            title={
              <span>
                <i className="mdi mdi-traffic-light" /> Status
              </span>
            }
          />
          <Filter
            type="tree"
            filters={importersList}
            onChange={this.onClickImporterFilter}
            name="status"
            title={
              <span>
                <i className="mdi mdi-file-xml" /> Importers
              </span>
            }
          />
          <Filter
            type="date"
            filters={[
              {
                name: 'date_from',
                value: filter_date_from,
                label: 'Date from',
              },
              { name: 'date_to', value: filter_date_to, label: 'Date to' },
            ]}
            onChange={this.onClickDateFilter}
            name="dates"
            title={
              <span>
                <i className="mdi mdi-calendar-search" /> Dates
              </span>
            }
          />
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(ImportGenericFilters);
