import React from 'react';

import { ModuleWrapper } from 'redux/register';

import { ENDPOINT_LIST_STORE_KEY } from './constants/reducers';

import reducer from './reducers';
import EndpointDashboardView from './views';

export default function EndpointDashboardViewContainer() {
  return (
    <ModuleWrapper reducerKey={ENDPOINT_LIST_STORE_KEY} reducer={reducer}>
      <EndpointDashboardView />
    </ModuleWrapper>
  );
}
