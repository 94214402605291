export const USER_UPDATE_RESET = 'USER_UPDATE_RESET';

export const USER_UPDATE_MODAL_OPEN = 'USER_UPDATE_MODAL_OPEN';
export const USER_UPDATE_MODAL_CLOSE = 'USER_UPDATE_MODAL_CLOSE';
export const USER_UPDATE_MODAL_LOADING = 'USER_UPDATE_MODAL_LOADING';

export const USER_UPDATE_FORM_UPDATE_INPUT = 'USER_UPDATE_FORM_UPDATE_INPUT';
export const USER_UPDATE_LOAD_USER_INFO = 'USER_UPDATE_LOAD_USER_INFO';

export const USER_UPDATE_MODAL_TOKENS_RECEIVED =
  'USER_UPDATE_MODAL_TOKENS_RECEIVED';
