import { createReducer } from 'redux-create-reducer';
import { fromJS } from 'immutable';

import { SOURCE_ALKEMICS } from 'constants/organization';
import { setIn } from 'utils/immutable';

import {
  ORGANIZATION_CREATE_FORM_UPDATE_INPUT,
  ORGANIZATION_CREATE_RESET,
} from '../constants/events';
import { MANUFACTURER } from '../constants/org-types';

const initialState = fromJS({
  name: '',
  type: MANUFACTURER,
  network: undefined,
  group: null,
  worksOnTargetMarkets: [],
  listing: false,
  untagOnChange: false,
  tagOnShare: false,
  displayActiveRangeOnly: false,
  restrictedRecipients: false,
  assignation: false,
  source: SOURCE_ALKEMICS,
});

export default createReducer(initialState, {
  [ORGANIZATION_CREATE_FORM_UPDATE_INPUT]: (state, { payload }) =>
    setIn(state, payload.key, payload.value),
  [ORGANIZATION_CREATE_RESET]: () => initialState,
});
