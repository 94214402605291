// List
export const TRANSACTION_SEARCH = 'TRANSACTION_SEARCH';
export const TRANSACTION_LOAD = 'TRANSACTION_LOAD';
export const TRANSACTION_RECEIVE_LIST = 'TRANSACTION_RECEIVE_LIST';
export const TRANSACTION_RECEIVE_AGGREGATIONS =
  'TRANSACTION_RECEIVE_AGGREGATIONS';

// Pagination
export const TRANSACTION_CHANGE_LIMIT = 'TRANSACTION_CHANGE_LIMIT';
export const TRANSACTION_PREVIOUS_PAGE = 'TRANSACTION_PREVIOUS_PAGE';
export const TRANSACTION_NEXT_PAGE = 'TRANSACTION_NEXT_PAGE';

// Filters
export const TRANSACTION_SEARCH_ID = 'TRANSACTION_SEARCH_ID';
export const TRANSACTION_ADD_FILTER = 'TRANSACTION_ADD_FILTER';
export const TRANSACTION_REMOVE_FILTER = 'TRANSACTION_REMOVE_FILTER';
export const TRANSACTION_CLEAR_FILTERS = 'TRANSACTION_CLEAR_FILTERS';
export const TRANSACTION_SELECT_PRESET = 'TRANSACTION_SELECT_PRESET';

export const TRANSACTION_LOAD_FILTERS = 'TRANSACTION_LOAD_FILTERS';
export const TRANSACTION_FILTERS_LOADED = 'TRANSACTION_FILTERS_LOADED';
export const TRANSACTION_UPDATE_SELECTED_FILTERS =
  'TRANSACTION_UPDATE_SELECTED_FILTERS';

// Payload modal
export const TRANSACTION_PAYLOAD_MODAL_CLOSE =
  'TRANSACTION_PAYLOAD_MODAL_CLOSE';
export const TRANSACTION_PAYLOAD_MODAL_SHOW = 'TRANSACTION_PAYLOAD_MODAL_SHOW';

// Diff modal
export const TRANSACTION_DIFF_MODAL_CLOSE = 'TRANSACTION_DIFF_MODAL_CLOSE';
export const TRANSACTION_DIFF_MODAL_SHOW = 'TRANSACTION_DIFF_MODAL_SHOW';

// Apply
export const TRANSACTION_ACTION_MODAL_CLOSE = 'TRANSACTION_ACTION_MODAL_CLOSE';
export const TRANSACTION_ACTION_MODAL_SHOW = 'TRANSACTION_ACTION_MODAL_SHOW';
export const TRANSACTION_ACTION_MODAL_APPLY = 'TRANSACTION_ACTION_MODAL_APPLY';

// Extra
export const TRANSACTION_FETCH_EXTRAS = 'TRANSACTION_FETCH_EXTRAS';
export const TRANSACTION_RECEIVE_EXTRAS = 'TRANSACTION_RECEIVE_EXTRAS';

// MISC
export const TRANSACTION_RESTORE_HIERARCHY = 'TRANSACTION_RESTORE_HIERARCHY';
export const TRANSACTION_REINDEX = 'TRANSACTION_REINDEX';
