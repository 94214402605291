import { PasswordInput } from '@alkem/react-ui-inputs';
import { Text } from '@alkem/react-ui-inputs/src/text/index';
import { Modal } from '@alkem/react-ui-modal';
import { Tipster } from '@alkem/react-ui-tipster';
import { Tooltip } from '@alkem/react-ui-tooltip';
import cn from 'classnames';
import InputWithLabel from 'components/input-with-label';
import Notification from 'components/notification';
import * as routes from 'constants/routes';
import { debounce } from 'lodash';
import { searchUsers } from 'modules/user-list/actions';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  addUserToOrganization,
  createUser,
  passwordCheck,
  userCheck,
  usernameCheck,
} from '../../actions/users';
import {
  selectExistingUsers,
  selectIsLoading,
  selectPasswordErrors,
  selectUsernameErrors,
} from '../../selectors/users';
import './modal.scss';

type Props = {
  closeModal: () => void;
  organizationId: string | number;
};

const AddUserModal = ({ closeModal, organizationId }: Props) => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const passwordErrors: any[] = useSelector(selectPasswordErrors);
  const usernameErrors = useSelector(selectUsernameErrors);
  const existingUsers: any[] = useSelector(selectExistingUsers);
  const isLoading: boolean = useSelector(selectIsLoading);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const usernameCheckDebounce = useCallback(
    debounce((username) => dispatch(usernameCheck(username)), 500),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const passwordCheckDebounce = useCallback(
    debounce((password) => dispatch(passwordCheck(password)), 500),
    []
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const userCheckDebounce = useCallback(
    debounce((user) => dispatch(userCheck(user)), 500),
    []
  );

  const onCreate = () => {
    if (existingUsers.length) {
      dispatch(
        addUserToOrganization({ userId: existingUsers[0].id, organizationId })
      );
    } else {
      dispatch(createUser({ username, password, organizationId }));
    }
  };

  return (
    <Modal
      className="AddUserModal"
      modalStyle="dynamic"
      title="Add a user"
      confirmButtonText={existingUsers.length ? 'Add user anyway' : 'Create'}
      onConfirm={onCreate}
      onClose={closeModal}
      confirmDisabled={
        isLoading ||
        !username ||
        !!usernameErrors ||
        ((passwordErrors || !password) && !existingUsers.length)
      }
      isProcessing={isLoading}
    >
      <Notification type="modal" />
      {usernameErrors && (
        <Tooltip
          className="AddUserModal__tooltip--error"
          id="username-check-errors"
          place="top"
          delayShow={0}
        >
          {usernameErrors}
        </Tooltip>
      )}
      <div data-for="username-check-errors" data-tip>
        <InputWithLabel
          extraLabelClassNames={{ AddUserModal__label: true }}
          extraInputClassNames={{
            AddUserModal__input: true,
            'AddUserModal__input--error': usernameErrors,
          }}
          inputId=""
          label="Username"
        >
          <Text
            id="new-user-username"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
              usernameCheckDebounce(e.target.value);
              userCheckDebounce(e.target.value);
            }}
            placeholder="test@salsify.com"
          />
        </InputWithLabel>
      </div>
      <InputWithLabel
        inputId=""
        label="Password"
        extraLabelClassNames={{ AddUserModal__label: true }}
        extraInputClassNames={{ AddUserModal__input: true }}
      >
        <PasswordInput
          inputClassName={cn({
            'AddUserModal__input--error': passwordErrors,
          })}
          id="new-user-password"
          onChange={(e) => {
            setPassword(e.target.value);
            passwordCheckDebounce(e.target.value);
          }}
          value={existingUsers.length ? '************' : password}
          disabled={!!existingUsers.length}
          eyePassword={!existingUsers.length}
          requirements={!existingUsers.length}
          requirementsLabels={{
            minChars: 'Min 8 characters',
            charCases: 'Upper and lower cases',
            numbers: 'Numbers',
            specials: 'Special character',
          }}
        />
      </InputWithLabel>
      {!!existingUsers.length && (
        <InputWithLabel
          extraLabelClassNames={{ AddUserModal__label: true }}
          extraInputClassNames={{ AddUserModal__input: true }}
          inputId=""
          label=""
        >
          <div className="AddUserModal__userTipster">
            <Tipster type="information">
              <div>
                The user already belongs to {existingUsers.length} organizations
              </div>
              <Link
                to={routes.users}
                onClick={() => dispatch(searchUsers(username))}
              >
                See all organizations in the user dashboard
              </Link>
            </Tipster>
          </div>
        </InputWithLabel>
      )}
    </Modal>
  );
};

export { AddUserModal };
