import { MODULE_REDUCER_KEY } from 'constants/reducer';
import {
  ORGANIZATION_PAGE_STORE_KEY,
  SAVE_MODAL_REDUCER_KEY,
} from '../constants';

export function selectState(state) {
  return state[MODULE_REDUCER_KEY][ORGANIZATION_PAGE_STORE_KEY][
    SAVE_MODAL_REDUCER_KEY
  ];
}

export function selectSaveInProgress(state) {
  return selectState(state).get('saveInProgress');
}

export function selectIsModalOpened(state) {
  return selectState(state).get('isModalOpened');
}

export function selectFeatureStatus(state) {
  return selectState(state).get('featureStatus');
}

export function selectOrganizationId(state) {
  return selectState(state).get('organizationId');
}

export function selectSaveErrorMessage(state) {
  return selectState(state).get('saveErrorMessage');
}
