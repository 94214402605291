// List
export const RECEIVE_ENDPOINT_LIST = 'RECEIVE_ENDPOINT_LIST';
export const RECEIVE_ENDPOINT_LIST_FILES = 'RECEIVE_ENDPOINT_LIST_FILES';
export const GET_ENDPOINT_LIST = 'GET_ENDPOINT_LIST';
export const GET_ENDPOINT_LIST_FILES = 'GET_ENDPOINT_LIST_FILES';

// Filters
export const CHANGE_LIMIT_ENDPOINT_LIST = 'CHANGE_LIMIT_ENDPOINT_LIST';
export const NEXT_PAGE_ENDPOINT_LIST = 'NEXT_PAGE_ENDPOINT_LIST';
export const PREVIOUS_PAGE_ENDPOINT_LIST = 'PREVIOUS_PAGE_ENDPOINT_LIST';

export const ADD_ENDPOINT_LIST_FILTER = 'ADD_ENDPOINT_LIST_FILTER';
export const REMOVE_ENDPOINT_LIST_FILTER = 'REMOVE_ENDPOINT_LIST_FILTER';
export const UPDATE_ENDPOINT_LIST_FILTER = 'UPDATE_ENDPOINT_LIST_FILTER';
export const CLEAR_ENDPOINT_LIST_FILTER = 'CLEAR_ENDPOINT_LIST_FILTER';

export const GET_ENDPOINT_LIST_FILTERS = 'GET_ENDPOINT_LIST_FILTERS';
export const RECEIVE_ENDPOINT_LIST_FILTERS = 'RECEIVE_ENDPOINT_LIST_FILTERS';

// Modal
export const ENDPOINT_MODAL_OPEN = 'ENDPOINT_MODAL_OPEN';
export const ENDPOINT_MODAL_CLOSE = 'ENDPOINT_MODAL_CLOSE';

export const ENDPOINT_MODAL_UPDATE = 'ENDPOINT_MODAL_UPDATE';
export const ENDPOINT_MODAL_SAVE = 'ENDPOINT_MODAL_SAVE';

export const ENDPOINT_DELETE = 'ENDPOINT_DELETE';

// GMAIL
export const ENDPOINT_GMAIL_CONSENT = 'ENDPOINT_GMAIL_CONSENT';
