import {
  LISTCOLUMN,
  ALLOWED_COLUMN,
  REQUESTED_SUPPLIER_INTERNAL_ID,
  REQUESTED_PRODUCTS,
  INFORMATION_REQUEST,
} from 'constants/organization-settings';
import { useSelector } from 'react-redux';
import SimpleSettingController from './controllers/simple';
import { List, Map, Set } from 'immutable';
import {
  selectListColumnReferential,
  selectSettings,
  selectEditedSettings,
} from 'modules/organization-page/selectors/settings';

const OPTIONS_DEPENDING_ON_IR = [
  REQUESTED_PRODUCTS,
  REQUESTED_SUPPLIER_INTERNAL_ID,
];

const InformationRequest = (props: { disabled: boolean }) => {
  const { disabled } = props;

  const referential: List<any> | null = useSelector(
    selectListColumnReferential
  );
  const editedSettings: any = useSelector(selectEditedSettings);
  const settings: any = useSelector(selectSettings);

  const getOptionIds = () => {
    const optionIds = referential
      ?.filter((option) => OPTIONS_DEPENDING_ON_IR.includes(option.get('code')))
      .map((option) => option.get('id'));
    return optionIds;
  };

  const getCheckedValueSyncOther = (checkedValue: boolean) => {
    const optionIds = getOptionIds();
    let settingListColumn =
      !editedSettings.isEmpty() &&
      !editedSettings.getIn([LISTCOLUMN], Map()).isEmpty()
        ? editedSettings.getIn([LISTCOLUMN], Map())
        : settings.getIn([LISTCOLUMN], Map());
    if (checkedValue) {
      settingListColumn = settingListColumn.set(
        ALLOWED_COLUMN,
        Set(
          settingListColumn.get(ALLOWED_COLUMN, List()).concat(optionIds)
        ).toList()
      );
    } else {
      settingListColumn = settingListColumn.set(
        ALLOWED_COLUMN,
        settingListColumn
          .get(ALLOWED_COLUMN, List())
          .filter((id) => !optionIds?.includes(id))
      );
    }

    return settingListColumn;
  };

  return (
    <SimpleSettingController
      label="Request Engine"
      help="Define if the retailer can send product requests to suppliers"
      path={[INFORMATION_REQUEST]}
      checkedValue={true}
      uncheckedValue={false}
      disabled={disabled}
      syncOthers={[
        {
          path: [LISTCOLUMN],
          checkedValue: getCheckedValueSyncOther(true),
          uncheckedValue: getCheckedValueSyncOther(false),
        },
      ]}
    />
  );
};

export default InformationRequest;
