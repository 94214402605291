import { MODULE_REDUCER_KEY } from 'constants/reducer';
import { REDUCER_KEY } from './reducers';
import { get } from 'lodash/fp';

export function selectState(state) {
  return state[MODULE_REDUCER_KEY][REDUCER_KEY];
}

export function selectSelectedFilters(state) {
  return get(['selectedFilters'], selectState(state));
}

export function selectIsLoading(state) {
  return get(['loading'], selectState(state));
}

export function selectQueueList(state) {
  return get(['organizations'], selectState(state));
}

export function selectDetail(state) {
  return get(['detail'], selectState(state));
}

export function selectSources(state) {
  return get(['sources'], selectState(state));
}

export function selectPage(state) {
  return get(['page'], selectState(state));
}

export function selectPageRequest(state) {
  return get(['pageRequest'], selectState(state));
}

export function selectQueueStatus(state) {
  return get(['status'], selectState(state));
}

export function selectOrganizationReindexStatistics(state) {
  return get(['organizationReindexStatistics'], selectState(state));
}

export function selectSelectedOrganization(state) {
  return get(['selectedOrganization'], selectState(state));
}
