import { CancelablePromise } from 'cancelable-promise';
import authApi from 'resources/authApi';
import { createAction } from 'utils/reducers';
import {
  UPDATE_GROUP_RELATION,
  BILLING_RESET_CHANGES,
} from '../constants/events';
import { selectBillingChanges } from '../selectors/billing';
import { selectOrganization } from '../selectors/infos';

export const updateGroupRelation = createAction(UPDATE_GROUP_RELATION);

export const saveBilling = () => async (dispatch, getState) => {
  const state = getState();
  const organizationId = selectOrganization(state).get('id');
  const changes = selectBillingChanges(state);

  const [added, removed] = Object.entries(changes).reduce(
    ([add, remove], [groupId, enabled]) =>
      enabled
        ? [[...add, Number(groupId)], remove]
        : [add, [...remove, Number(groupId)]],
    [[] as number[], [] as number[]]
  );

  const calls: CancelablePromise<any>[] = [];
  if (added.length) {
    calls.push(
      authApi.addOrganizationGroupRelations(added, true, organizationId)
    );
  }
  if (removed.length) {
    calls.push(
      authApi.removeOrganizationGroupRelations(removed, true, organizationId)
    );
  }
  await Promise.all(calls);

  dispatch({ type: BILLING_RESET_CHANGES });
};
