import moment from 'moment';
import { set } from 'lodash/fp';
import { saveAs } from 'utils';

import {
  DATETIME_FORMAT,
  IMPORT_MAPPINGS_DEFAULT_VALUE_TYPES,
  UNLIMITED_DATE,
  XML_SPACE,
} from './constants';
import {
  Field,
  ImportMapping,
  ImportMappingApi,
  ImportMappingDetails,
  ImportMappingDetailsChildren,
  ImportMappingDetailsChildrenApi,
  ImportMappingDetailsDefault,
  ImportMappingDetailsFallback,
  ImportMappingDetailsFallbackApi,
  Property,
  ReferentialCodes,
} from './types';

export const getStringWithoutLineBreaks = (value: string): string => {
  return value.replace(/(?:\r\n|\r|\n)/g, ' ');
};

export const getFormattedXpath = (
  xpaths: string | string[] | undefined
): string[] => {
  if (!xpaths) return [''];
  if (typeof xpaths === 'string') return [xpaths];
  const newXpath = xpaths.filter((xpath) => xpath && xpath !== '');
  if (newXpath.length === 0) return [''];
  return newXpath;
};

export const getFormattedFallback = (
  fallback?: ImportMappingDetailsFallbackApi,
  fallbackList: ImportMappingDetailsFallback[] = [],
  index = 0
): ImportMappingDetailsFallback[] => {
  if (!fallback) return [];
  const newFallback: ImportMappingDetailsFallback = {
    index,
    xpath: getFormattedXpath(fallback.xpath),
    custom_parse: fallback.custom_parse,
    custom_parse_all: fallback.custom_parse_all,
    mandatory: fallback.mandatory,
    message: fallback.message,
  };
  fallbackList.push(newFallback);
  if (fallback.fallback) {
    getFormattedFallback(fallback.fallback, fallbackList, index + 1);
  }
  return fallbackList;
};

export const getFormattedDefaultValue = (
  mustRedefineDefaultForEntities: boolean,
  importMappingChild: ImportMappingDetails,
  referentialCodes?: { [key: string]: ReferentialCodes | undefined }
) => {
  if (
    !mustRedefineDefaultForEntities ||
    !(
      importMappingChild.cast === 'entity' &&
      !!importMappingChild.referential &&
      typeof importMappingChild.default === 'object' &&
      !!importMappingChild.default.code
    )
  ) {
    return importMappingChild.default;
  }
  let defaultCode: ImportMappingDetailsDefault = undefined;
  if (
    referentialCodes &&
    importMappingChild.cast === 'entity' &&
    importMappingChild.referential
  ) {
    if (
      typeof importMappingChild.default === 'object' &&
      importMappingChild.default.code
    ) {
      const importMappingDefaultCode = importMappingChild.default.code;
      const code = referentialCodes[importMappingChild.referential]?.find(
        (referentialCode) => referentialCode.label === importMappingDefaultCode
      );
      if (code) {
        defaultCode = {
          id: code.id,
          code: code.label,
        };
      }
    }
  }
  return defaultCode;
};

export const getCastFromField = (field: Field): string | undefined => {
  let cast = field?.type;
  if (cast === 'product') cast = 'dict';
  if (cast === 'text') cast = 'string';
  return cast;
};

export const getFormattedImportMappingDetailsMissingPropertiesFromField = (
  details: ImportMappingDetails,
  field: Field
): ImportMappingDetails => {
  const formattedImportMappingDetails = { ...details };
  if (!details.referential && field?.referential) {
    formattedImportMappingDetails.referential = field.referential;
  }
  if (!details.cast && field?.type) {
    formattedImportMappingDetails.cast = getCastFromField(field);
  }
  if (
    !details.isDeclinable &&
    field?.type !== 'copy' &&
    !!field?.declinable_by
  ) {
    formattedImportMappingDetails.isDeclinable = true;
    formattedImportMappingDetails.referential = field.declinable_by;
  }
  formattedImportMappingDetails.functional_key = field?.functional_key;
  return formattedImportMappingDetails;
};

export const getFormattedChildren = (
  importMappingChildren?: ImportMappingDetailsChildrenApi,
  fieldChildren?: Field[],
  mustRedefineDefaultForEntities: boolean = false,
  referentialCodes?: { [key: string]: ReferentialCodes | undefined }
): ImportMappingDetailsChildren | undefined => {
  if (!fieldChildren || fieldChildren.length === 0) return undefined;
  const children: ImportMappingDetailsChildren = {};
  if ((fieldChildren || []).length !== 0) {
    for (const fieldChild of fieldChildren || []) {
      const childName = fieldChild?.name;
      if (childName && importMappingChildren?.[childName]) {
        const importMappingChild = { ...importMappingChildren[childName] };
        children[childName] = {
          ...importMappingChild,
          xpath: getFormattedXpath(importMappingChild.xpath),
          fallback: getFormattedFallback(importMappingChild.fallback),
          children: getFormattedChildren(
            importMappingChild.children,
            fieldChild.children,
            mustRedefineDefaultForEntities,
            referentialCodes
          ),
        };
        children[childName] =
          getFormattedImportMappingDetailsMissingPropertiesFromField(
            children[childName],
            fieldChild
          );
        children[childName].default = getFormattedDefaultValue(
          mustRedefineDefaultForEntities,
          children[childName],
          referentialCodes
        );
      } else if (childName) {
        children[childName] = {
          cast: getCastFromField(fieldChild),
          xpath: [''],
          fallback: [],
          children: getFormattedChildren(undefined, fieldChild.children),
          functional_key: fieldChild.functional_key,
        };
        if (fieldChild.type === 'entity')
          children[childName].referential = fieldChild.referential;
        if (fieldChild.type !== 'copy' && fieldChild.declinable_by) {
          children[childName].isDeclinable = true;
          children[childName].referential = fieldChild.declinable_by;
        }
      }
    }
  }
  return children;
};

export const getUpdatedImportMapping = ({
  details,
  fieldName,
  importMapping,
  parentNames,
  parents,
  properties,
}: {
  details: ImportMappingDetails;
  fieldName: string;
  importMapping: ImportMapping;
  parentNames: string[];
  parents: ImportMappingDetails[];
  properties: Property[];
}): ImportMapping => {
  let updatedImportMapping = { ...importMapping };
  if (parentNames.length === 0) {
    updatedImportMapping = set(['details'], details, updatedImportMapping);
    for (const property of properties) {
      updatedImportMapping.details[property.name] = property.value;
    }
  } else {
    if (parentNames.length === 1 && updatedImportMapping.details.children) {
      updatedImportMapping = set(
        ['details', 'children', fieldName],
        details,
        updatedImportMapping
      );
      for (const property of properties) {
        updatedImportMapping = set(
          ['details', 'children', fieldName, property.name],
          property.value,
          updatedImportMapping
        );
      }
    } else if (parents) {
      const newParents = [...parents];
      const newParentNames = [...parentNames];
      newParents.pop();
      newParentNames.pop();
      let newDetails: ImportMappingDetails | undefined;
      for (let i = 0; i < newParentNames.length; i++) {
        const parent = { ...newParents[i] };
        if (i === 0) {
          newDetails = {
            ...parent,
            children: {
              ...parent.children,
              [fieldName]: {
                ...details,
              },
            },
          };
          if (newDetails.children) {
            for (const property of properties) {
              newDetails = set(
                ['children', fieldName, property.name],
                property.value,
                newDetails
              );
            }
          }
        } else {
          if (newDetails) {
            newDetails = {
              ...parent,
              children: {
                ...parent.children,
                [newParentNames[i - 1]]: {
                  ...newDetails,
                },
              },
            };
          }
        }
      }
      if (updatedImportMapping.details.children && newDetails) {
        updatedImportMapping = set(
          ['details', 'children', newParentNames[newParentNames.length - 1]],
          newDetails,
          updatedImportMapping
        );
      }
    }
  }
  return updatedImportMapping;
};

export const getFallbackForApi = (
  fallbacks: ImportMappingDetailsFallback[]
): ImportMappingDetailsFallbackApi | undefined => {
  const filteredFallbacks = fallbacks.filter(
    (fallback) => !(fallback.xpath.length === 1 && fallback.xpath[0] === '')
  );
  if (filteredFallbacks.length === 0) return undefined;
  const formattedFallbacks = filteredFallbacks.map((fallback) => ({
    ...fallback,
    xpath: fallback.xpath.length === 1 ? fallback.xpath[0] : fallback.xpath,
  }));
  let fallbackForApi: ImportMappingDetailsFallbackApi | undefined = undefined;
  for (let i = formattedFallbacks.length - 1; i > -1; i--) {
    fallbackForApi = {
      xpath: formattedFallbacks[i].xpath,
      custom_parse: formattedFallbacks[i].custom_parse,
      custom_parse_all: formattedFallbacks[i].custom_parse_all,
      mandatory: formattedFallbacks[i].mandatory,
      message: formattedFallbacks[i].message,
      fallback: fallbackForApi,
    };
    for (const key of Object.keys(fallbackForApi)) {
      if (
        !fallbackForApi[key] &&
        ['undefined', 'string'].includes(typeof fallbackForApi[key])
      ) {
        delete fallbackForApi[key];
      }
    }
  }
  return fallbackForApi;
};

export const getChildrenForApi = (
  children?: ImportMappingDetailsChildren
): ImportMappingDetailsChildrenApi | undefined => {
  if (!children) return undefined;
  const childrenForApi: ImportMappingDetailsChildrenApi = {};
  for (const [key, child] of Object.entries(children)) {
    if (!(child.xpath.length === 1 && child.xpath[0] === '')) {
      const newChild = { ...child };
      childrenForApi[key] = {
        ...newChild,
        xpath: child.xpath.length === 1 ? child.xpath[0] : child.xpath,
        fallback: getFallbackForApi(child.fallback),
        children: getChildrenForApi(child.children),
      };
      for (const key2 of Object.keys(childrenForApi[key])) {
        if (
          !childrenForApi[key][key2] &&
          ['undefined', 'string'].includes(typeof childrenForApi[key][key2])
        ) {
          delete childrenForApi[key][key2];
        }
      }
      if (Object.keys(childrenForApi[key]).includes('functional_key')) {
        delete childrenForApi[key]['functional_key'];
      }
    }
  }

  if (Object.keys(childrenForApi).length === 0) return undefined;
  return childrenForApi;
};

export const getReferentials = (
  referentials: string[],
  field: Field
): string[] => {
  if (!field) return referentials;
  if (
    field.type === 'entity' &&
    field.referential &&
    !referentials.includes(field.referential)
  ) {
    referentials.push(field.referential);
  } else if (
    field.declinable_by &&
    !referentials.includes(field.declinable_by)
  ) {
    referentials.push(field.declinable_by);
  }
  if (!field.children || field.children.length === 0) return referentials;
  let newReferentials = [...referentials];
  for (const fieldChild of field.children || []) {
    if (fieldChild) {
      newReferentials = getReferentials(newReferentials, fieldChild);
    }
  }
  return newReferentials;
};

export const getFormattedImportMappings = (
  field: Field,
  fieldCategory: string,
  importMappingsApi: ImportMappingApi[],
  nowTestTime?: string
): ImportMapping[] => {
  if (!field) return [];

  const formattedImportMappings = importMappingsApi
    .map((importMapping) => {
      const formattedImportMapping = {
        ...importMapping,
        dateEnd: moment(importMapping.dateEnd).format(DATETIME_FORMAT),
        dateStart: moment(importMapping.dateStart).format(DATETIME_FORMAT),
        details: {
          ...importMapping.details,
          xpath: getFormattedXpath(importMapping.details.xpath),
          fallback: getFormattedFallback(importMapping.details.fallback),
          children: getFormattedChildren(
            importMapping.details.children,
            field.children
          ),
        },
        isTested: false,
      };
      formattedImportMapping.details =
        getFormattedImportMappingDetailsMissingPropertiesFromField(
          formattedImportMapping.details,
          field
        );
      return formattedImportMapping;
    })
    .sort(
      (importA, importB) =>
        new Date(importB.dateStart).getTime() -
        new Date(importA.dateStart).getTime()
    );

  const hasUnlimitedEndDate = !!formattedImportMappings.find(({ dateEnd }) =>
    moment(dateEnd).isSame(UNLIMITED_DATE)
  );
  if (!hasUnlimitedEndDate) {
    let dateStart = formattedImportMappings[0]?.dateEnd
      ? moment(formattedImportMappings[0]?.dateEnd).format(DATETIME_FORMAT)
      : moment(nowTestTime).format(DATETIME_FORMAT);
    if (moment(dateStart).isBefore(moment(nowTestTime))) {
      dateStart = moment(nowTestTime).format(DATETIME_FORMAT);
    }
    formattedImportMappings.unshift({
      isNew: true,
      id: `new-mapping-${field.name}`,
      dateEnd: moment(UNLIMITED_DATE).format(DATETIME_FORMAT),
      dateStart,
      field: field.name,
      details: {
        cast: getCastFromField(field),
        xpath: [''],
        fallback: [],
        children: getFormattedChildren(undefined, field.children),
        functional_key: field.functional_key,
      },
      unit: fieldCategory,
      isTested: false,
    });

    if (field.type === 'entity')
      formattedImportMappings[0].details.referential = field.referential;
    if (field.type !== 'copy' && field.declinable_by) {
      formattedImportMappings[0].details.isDeclinable = true;
      formattedImportMappings[0].details.referential = field.declinable_by;
    }
  }

  return formattedImportMappings;
};

export const createXmlTags = (
  details: ImportMappingDetails,
  tagName: string,
  otherTags: string[],
  referentialCodes?: { [key: string]: ReferentialCodes | undefined },
  index = 0
) => {
  let openingTag = `<${tagName}>`;
  let closingTag = `</${tagName}>`;
  let hasOtherTagWithValue = false;
  let otherTag: string | undefined = undefined;
  let otherTagValue: string | undefined = undefined;

  if (openingTag.includes('{urn:gs1:gdsn:')) {
    const moduleSnakeCase = tagName.replace('{urn:gs1:gdsn:', '').split(':')[0];
    const moduleCamelCase = tagName.split('}')[1];
    openingTag = `<${moduleSnakeCase}:${moduleCamelCase} xmlns:${moduleSnakeCase}="urn:gs1:gdsn:${moduleSnakeCase}:xsd:3" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="urn:gs1:gdsn:${moduleSnakeCase}:xsd:3 http://www.g${
      global.env === 'ppr' ? 'ds' : 's1global'
    }registry.net/3.1/schemas/gs1/gdsn/${moduleCamelCase}.xsd">`;
    closingTag = `</${moduleSnakeCase}:${moduleCamelCase}>`;
  } else if (openingTag.includes('os:')) {
    const moduleCamelCase = tagName.replace('os:', '');
    openingTag = `<os:${moduleCamelCase} xmlns:os="http://www.1sync.org" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.1sync.org http://www.1worldsync.com/schemas/item/extensions/2.0/${moduleCamelCase}.xsd">`;
    closingTag = `</os:${moduleCamelCase}>`;
  } else if (
    openingTag.includes('[@') ||
    (otherTags.length === 0 && (details.unitString || details.isDeclinable))
  ) {
    const splittedTagName = tagName.split('[@');
    openingTag = `<${splittedTagName[0]}`;
    if (otherTags.length === 0) {
      let gdsnCode = details.referential
        ? referentialCodes?.[details.referential]?.find(
            (referentialCode) => !!referentialCode.gdsnCode
          )?.gdsnCode
        : undefined;
      if (details.referential === 'languages') {
        gdsnCode = 'fr';
      }
      if (details.unitString) {
        openingTag += ` ${details.unitString}="${gdsnCode || ''}"`;
      } else if (details.isDeclinable) {
        if (
          details.cast &&
          IMPORT_MAPPINGS_DEFAULT_VALUE_TYPES.string.includes(details.cast) &&
          details.referential === 'languages'
        ) {
          openingTag += ' languageCode="fr"';
        } else if (
          details.cast &&
          IMPORT_MAPPINGS_DEFAULT_VALUE_TYPES.number.includes(details.cast)
        ) {
          openingTag += ` measurementUnitCode="${gdsnCode || ''}"`;
        }
      }
    }
    openingTag += `${
      splittedTagName[1]
        ? ` ${splittedTagName[1].substring(0, splittedTagName[1].length - 1)}`
        : ''
    }>`;
    closingTag = `</${splittedTagName[0]}>`;
  } else if (openingTag.includes('[')) {
    const splittedTagName = tagName.split('[');
    openingTag = `<${splittedTagName[0]}>`;
    closingTag = `</${splittedTagName[0]}>`;
    hasOtherTagWithValue = true;
    const splittedOtherTag = splittedTagName[1]
      .substring(0, splittedTagName[1].length - 1)
      .split('=');
    otherTag = splittedOtherTag[0];
    otherTagValue = splittedOtherTag[1].substring(
      1,
      splittedOtherTag[1].length - 1
    );
  }

  if (otherTags.length === 0 && !hasOtherTagWithValue) {
    let value: string = '';
    if (details.cast && !details.custom_parse && !details.custom_parse_all) {
      if (IMPORT_MAPPINGS_DEFAULT_VALUE_TYPES.string.includes(details.cast)) {
        value = 'test string';
      } else if (
        IMPORT_MAPPINGS_DEFAULT_VALUE_TYPES.number.includes(details.cast)
      ) {
        value = '20';
      } else if (
        IMPORT_MAPPINGS_DEFAULT_VALUE_TYPES.boolean.includes(details.cast)
      ) {
        value = 'TRUE';
      } else if (
        IMPORT_MAPPINGS_DEFAULT_VALUE_TYPES.referential_codes_list.includes(
          details.cast
        )
      ) {
        const referential_with_gdsnCode = details.referential
          ? referentialCodes?.[details.referential]?.find(
              (referentialCode) => !!referentialCode.gdsnCode
            )
          : undefined;
        value = referential_with_gdsnCode?.gdsnCode || '';
      } else if (['date', 'timestamp'].includes(details.cast)) {
        value = '2023-02-21T00:00:00+00:00';
      }
    }
    return `${openingTag}${value}${closingTag}`;
  }

  let xmlTags = openingTag;
  if (hasOtherTagWithValue) {
    xmlTags +=
      '\n' +
      XML_SPACE.repeat(index + 3) +
      `<${otherTag}>${otherTagValue}</${otherTag}>`;
  }
  if (otherTags[0] !== '..' && otherTags.length !== 0) {
    xmlTags +=
      '\n' +
      `${XML_SPACE.repeat(index + 3)}${createXmlTags(
        details,
        otherTags[0],
        otherTags.slice(1),
        referentialCodes,
        index + 1
      )}`;
  }
  xmlTags += '\n' + `${XML_SPACE.repeat(index + 2)}${closingTag}`;
  if (hasOtherTagWithValue && otherTags[0] === '..') {
    xmlTags +=
      '\n' +
      `${XML_SPACE.repeat(index + 2)}${createXmlTags(
        details,
        otherTags[1],
        otherTags.slice(2),
        referentialCodes,
        index
      )}`;
  }
  return xmlTags;
};

export const createXmlFile = (
  fieldName: string,
  details: ImportMappingDetails,
  xpath: string,
  importMappingsDetails: ImportMappingDetails[],
  referentialCodes?: { [key: string]: ReferentialCodes | undefined }
): void => {
  let xpathList = importMappingsDetails
    .slice()
    .map((importMappingDetails) => importMappingDetails.xpath[0]);
  xpathList.push(xpath);
  xpathList = xpathList
    .filter((xpath) => xpath !== '.')
    .map((xpath) => {
      if (xpath.substring(0, 2) === './') {
        return xpath.substring(2, xpath.length);
      }
      return xpath;
    });
  const xmlTags = xpathList
    .join('/')
    .replaceAll('{http://www.1sync.org}', 'os:')
    .split('/');
  let xmlForTest = `<catalogueItem>\n${XML_SPACE}<tradeItem>\n${XML_SPACE.repeat(
    2
  )}`;
  xmlForTest += createXmlTags(
    details,
    xmlTags[0],
    xmlTags.slice(1),
    referentialCodes
  );
  xmlForTest += `\n${XML_SPACE}</tradeItem>\n</catalogueItem>`;

  const exportFileName = `${fieldName}.xml`;
  saveAs(
    new Blob([xmlForTest], { type: 'application/xml;charset=utf-8' }),
    exportFileName
  );
};
