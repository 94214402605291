import { toPairs, filter, flow, get, isEmpty, negate } from 'lodash/fp';
import { selectSettingChanges } from 'modules/settings/organization/selectors';
import { OrganizationPermissionsState } from '../types';
import {
  PERMISSIONS_REDUCER_KEY,
  ORGANIZATION_PAGE_STORE_KEY,
} from '../constants';

const selectState: (state: any) => OrganizationPermissionsState = get([
  'module',
  ORGANIZATION_PAGE_STORE_KEY,
  PERMISSIONS_REDUCER_KEY,
]);

export const selectOrganizationPermissions = flow(
  selectState,
  (state) => state.permissions
);
export const selectOrganizationConfiguration = flow(
  selectState,
  (state) => state.configuration
);

export const selectHasPermissionChanges = flow(
  selectState,
  (state) => state.isDirty
);

export const selectHasDeletedModuleFlags = flow(
  selectSettingChanges,
  toPairs,
  filter(([p, c]) => p.startsWith('permission-v3-') && !c),
  negate(isEmpty)
);
