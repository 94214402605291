import { settingsDashboardAccessPolicy } from 'access-policies';
import { Map } from 'immutable';
import { hasAccess } from 'modules/access-policy/common/utils';
import { selectPermissions } from 'modules/auth/reducer';
import { User, UserPermissions } from 'modules/auth/types';
import { lazy, memo, useMemo } from 'react';
import { connect } from 'react-redux';
import { selectUser } from 'reducers/user';
import { createStructuredSelector } from 'reselect';
import AuthApi from 'resources/authApi';
import { logError } from 'utils';
import { ActionWithPayload } from 'utils/reducers/types';
import { getUserType } from 'utils/user';
import {
  selectHasSettingFlagChanges,
  selectOrganizationId,
  selectSettingChanges,
} from './selectors';

const SettingsByOrganization = lazy(() => import('./module'));

export const saveSettingFlags =
  () =>
  async (
    _: (action: ActionWithPayload) => void,
    getState: () => any
  ): Promise<any> => {
    const state = getState();
    if (selectHasSettingFlagChanges(state)) {
      const changes = selectSettingChanges(state);
      const organizationId = selectOrganizationId(state);
      try {
        await AuthApi.put('/auth/v2/organization/settings', {
          organization_id: organizationId,
          settings: Object.entries(changes).map(([key, value]) => ({
            setting_key: key,
            value,
          })),
        });
      } catch (error) {
        logError(error);
        throw error;
      }
    }
  };

interface Props {
  organizationId: number;
  organizationSettings: Map<string, any>;
  hasChanges: boolean;
  user: User;
  userPermissions: UserPermissions;
  isReadOnly: boolean;
}

type ConnectedProps = Pick<Props, 'hasChanges' | 'user' | 'userPermissions'>;

export const OrganizationPageSettingFlags = connect(
  createStructuredSelector<ConnectedProps, ConnectedProps>({
    hasChanges: selectHasSettingFlagChanges,
    user: selectUser,
    userPermissions: selectPermissions,
  })
)(
  memo(function OrganizationPageSettingFlagsWrapper({
    organizationId,
    organizationSettings,
    hasChanges,
    user,
    userPermissions,
    isReadOnly,
  }: Props) {
    const hasReadAccess = useMemo(
      () =>
        hasAccess(
          getUserType(user),
          userPermissions,
          settingsDashboardAccessPolicy,
          'read'
        ),
      [user, userPermissions]
    );
    const hasWriteAccess =
      useMemo(
        () =>
          hasAccess(
            getUserType(user),
            userPermissions,
            settingsDashboardAccessPolicy,
            'write'
          ),
        [user, userPermissions]
      ) && !isReadOnly;

    if (!hasReadAccess && !hasWriteAccess) {
      return null;
    }
    return (
      <div className="OrganizationSettingFlags OrganizationPageBlock">
        <div className="OrganizationPageBlock__header">
          <h2>Feature and release flags</h2>
          <span className="OrganizationPageBlock__edited">
            {hasChanges && (
              <span>
                <i className="mdi mdi-alert" />
                Edited
              </span>
            )}
          </span>
        </div>
        <SettingsByOrganization
          organizationId={organizationId}
          organizationSettings={organizationSettings}
          readOnly={!hasWriteAccess}
        />
      </div>
    );
  })
);

export { selectHasSettingFlagChanges } from './selectors';
