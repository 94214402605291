import qs from 'querystringify';
import { put, call, takeEvery } from 'redux-saga/effects';
import { logError } from 'utils';

import { notificationSuccess, notificationError } from 'actions/notifications';
import indexerApi from 'resources/indexerApi';
import { requestWithHeaders } from 'utils/api';

import * as events from './events';
import * as actions from './actions';

export default function* mainSaga() {
  yield takeEvery(events.FETCH_BUFFERED_CONFIGS, fetchBufferedConfigs);
  yield takeEvery(events.CREATE_BUFFERED_CONFIG, createBufferedConfig);
  yield takeEvery(events.UPDATE_BUFFERED_CONFIG, updateBufferedConfig);
  yield takeEvery(events.DELETE_BUFFERED_CONFIG, deleteBufferedConfig);
  yield takeEvery(events.FETCH_CONFIG_SOURCES, fetchConfigSource);
  yield takeEvery(events.TOGGLE_BUFFERED_CONFIGS, toggleAllBufferedConfigs);

  yield takeEvery(events.FETCH_BATCH_CONFIGS, fetchBatchConfigs);
  yield takeEvery(events.CREATE_BATCH_CONFIG, createBatchConfig);
  yield takeEvery(events.UPDATE_BATCH_CONFIG, updateBatchConfig);
  yield takeEvery(events.DELETE_BATCH_CONFIG, deleteBatchConfig);
}

function* fetchBufferedConfigs() {
  const { result, error } = yield call(
    requestWithHeaders,
    indexerApi,
    'get',
    `/indexer/config/buffered/v1/list`
  );
  if (error) {
    logError(error);
    yield put(notificationError('Failed to load pump list'));
  } else {
    yield put(actions.receivedBufferedConfigs(result.data));
  }
}

function* fetchConfigSource() {
  const { result, error } = yield call(
    requestWithHeaders,
    indexerApi,
    'get',
    '/indexer/config/sources/v1/all'
  );
  if (error) {
    logError(error);
    yield put(notificationError('Failed to load source list'));
  } else {
    yield put(actions.receivedConfigSources(result.data));
  }
}

function* createBufferedConfig(action: any) {
  const { error } = yield call(
    requestWithHeaders,
    indexerApi,
    'post',
    `/indexer/config/buffered/v1/create`,
    action.payload
  );
  if (error) {
    logError(error);
    yield put(notificationError('Failed to create pump'));
  } else {
    yield put(notificationSuccess('Pump was successfully created'));
    yield put({ type: events.FETCH_BUFFERED_CONFIGS });
  }
}

function* updateBufferedConfig(action: any) {
  const { error } = yield call(
    requestWithHeaders,
    indexerApi,
    'put',
    `/indexer/config/buffered/v1/update`,
    action.payload
  );
  if (error) {
    logError(error);
    yield put(notificationError('Failed to update pump'));
  } else {
    yield put(notificationSuccess('Pump was successfully updated'));
    yield put({ type: events.FETCH_BUFFERED_CONFIGS });
  }
}

function* deleteBufferedConfig(action: any) {
  const { error } = yield call(
    requestWithHeaders,
    indexerApi,
    'delete',
    `/indexer/config/buffered/v1/${action.payload.source}`
  );
  if (error) {
    logError(error);
    yield put(notificationError('Failed to delete pump'));
  } else {
    yield put(notificationSuccess('Pump was successfully deleted'));
    yield put({ type: events.FETCH_BUFFERED_CONFIGS });
  }
}

function* toggleAllBufferedConfigs(action: any) {
  const { error } = yield call(
    requestWithHeaders,
    indexerApi,
    'put',
    `/indexer/config/buffered/v1/toggle-all${qs.stringify(
      { status: action.payload },
      true
    )}`
  );
  if (error) {
    logError(error);
    yield put(notificationError('Failed to toggle all buffers'));
  } else {
    yield put(notificationSuccess('All buffers were updated'));
    yield put({ type: events.FETCH_BUFFERED_CONFIGS });
  }
}

function* fetchBatchConfigs() {
  const { result, error } = yield call(
    requestWithHeaders,
    indexerApi,
    'get',
    `/indexer/config/batched/v1/list`
  );
  if (error) {
    logError(error);
    yield put(notificationError('Failed to load pump list'));
  } else {
    yield put(actions.receivedBatchConfigs(result.data));
  }
}

function* createBatchConfig(action: any) {
  const { error } = yield call(
    requestWithHeaders,
    indexerApi,
    'post',
    `/indexer/config/batched/v1/create`,
    action.payload
  );
  if (error) {
    logError(error);
    yield put(notificationError('Failed to create pump'));
  } else {
    yield put(notificationSuccess('Pump was successfully created'));
    yield put({ type: events.FETCH_BATCH_CONFIGS });
  }
}

function* updateBatchConfig(action: any) {
  const { error } = yield call(
    requestWithHeaders,
    indexerApi,
    'put',
    `/indexer/config/batched/v1/update`,
    action.payload
  );
  if (error) {
    logError(error);
    yield put(notificationError('Failed to update pump'));
  } else {
    yield put(notificationSuccess('Pump was successfully updated'));
    yield put({ type: events.FETCH_BATCH_CONFIGS });
  }
}

function* deleteBatchConfig(action: any) {
  const { error } = yield call(
    requestWithHeaders,
    indexerApi,
    'delete',
    `/indexer/config/batched/v1/${action.payload.source}`
  );
  if (error) {
    logError(error);
    yield put(notificationError('Failed to delete pump'));
  } else {
    yield put(notificationSuccess('Pump was successfully deleted'));
    yield put({ type: events.FETCH_BATCH_CONFIGS });
  }
}
