import { Text } from '@alkem/react-ui-inputs';
import classnames from 'classnames';
import { CodeEditor } from 'components/code-editor';
import { fromJS, Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { isValidJSON } from 'utils';

export default class ReferentialEntity extends PureComponent {
  static propTypes = {
    index: PropTypes.number.isRequired,
    source: PropTypes.object.isRequired,
    edited: PropTypes.object.isRequired,
    isNewlyCreated: PropTypes.bool.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
  };

  onUpdateCode = (event) =>
    this.props.onUpdate(this.props.index, 'code', event.target.value);

  onUpdateLabel = (event) =>
    this.props.onUpdate(this.props.index, 'label', event.target.value);

  onUpdateDescription = (event) =>
    this.props.onUpdate(this.props.index, 'description', event.target.value);

  onUpdateRank = (event) =>
    this.props.onUpdate(this.props.index, 'rank', event.target.value);

  onUpdateData = (value) => {
    if (isValidJSON(value)) {
      this.props.onUpdate(
        this.props.index,
        'data',
        fromJS(JSON.parse(value)) || Map()
      );
    }
  };

  onDelete = () =>
    this.props.onDelete(this.props.index, this.props.isNewlyCreated);

  render() {
    const { index, source, edited, isNewlyCreated, readOnly } = this.props;

    const codeClasses = classnames({
      ReferentialEntity__code: true,
      ReferentialEntity__input: true,
      'ReferentialEntity__input--dirty':
        edited.get('code') !== source.get('code'),
      'ReferentialEntity__input--invalid': !edited.get('code'),
    });
    const labelClasses = classnames({
      ReferentialEntity__label: true,
      ReferentialEntity__input: true,
      'ReferentialEntity__input--dirty':
        edited.get('label') !== source.get('label'),
    });
    const descriptionClasses = classnames({
      ReferentialEntity__description: true,
      ReferentialEntity__input: true,
      'ReferentialEntity__input--dirty':
        edited.get('description') !== source.get('description'),
    });
    const rankClasses = classnames({
      ReferentialEntity__rank: true,
      ReferentialEntity__input: true,
      'ReferentialEntity__input--dirty':
        edited.get('rank') !== source.get('rank'),
    });
    const data = JSON.stringify(edited.get('data').toJS(), null, '\t');
    const dataClasses = classnames({
      ReferentialEntity__data: true,
      ReferentialEntity__input: true,
      'ReferentialEntity__input--dirty':
        data !== JSON.stringify(source.get('data'), null, '\t'),
    });

    return (
      <tr id={`referential-entity-${index}`} className="ReferentialEntity">
        <td className={codeClasses}>
          <Text
            id={`referential-entity-${index}-code`}
            value={edited.get('code') || ''}
            placeholder="Code"
            onChange={this.onUpdateCode}
            disabled={!isNewlyCreated || readOnly}
          />
        </td>
        <td className={labelClasses}>
          <Text
            id={`referential-entity-${index}-label`}
            value={edited.get('label') || ''}
            placeholder="Label"
            onChange={this.onUpdateLabel}
            disabled={readOnly}
          />
        </td>
        <td className={descriptionClasses}>
          <Text
            id={`referential-entity-${index}-decription`}
            value={edited.get('description') || ''}
            placeholder="Description"
            onChange={this.onUpdateDescription}
            disabled={readOnly}
          />
        </td>
        <td className={rankClasses}>
          <Text
            id={`referential-entity-${index}-rank`}
            value={edited.get('rank') || ''}
            placeholder="Rank"
            type="number"
            onChange={this.onUpdateRank}
            disabled={readOnly}
          />
        </td>
        <td className={dataClasses}>
          <CodeEditor
            name={`referential-entity-${index}-data`}
            value={data}
            onChange={this.onUpdateData}
            showPrintMargin={false}
            showGutter={false}
            width="100%"
            minLines={5}
            maxLines={10}
            mode="json"
            theme="tomorrow"
            editorProps={{ $blockScrolling: Infinity }}
            readOnly={readOnly}
          />
        </td>
        {!readOnly && (
          <td className="ReferentialEntity__delete">
            {(isNewlyCreated || window.env === 'ppr') && (
              <i className="mdi mdi-delete" onClick={this.onDelete} />
            )}
          </td>
        )}
      </tr>
    );
  }
}
