import { Button, SwitchButton } from '@alkem/react-ui-button';
import { CheckboxFilter, Filter, Filters } from '@alkem/react-ui-list-filter';
import { LazyTooltip } from '@alkem/react-ui-tooltip';
import { ProductKeyAutocomplete } from 'components/autocomplete/custom/product-key-autocomplete';
import { RulesetAutocomplete } from 'components/autocomplete/custom/ruleset-autocomplete';
import memoize from 'memoize-one';
import { OrganizationAutocomplete } from 'modules/organization-uses-fields/components/organization-autocomplete';
import {
  validationAddToFilters,
  validationClearFilters,
  validationRemoveFromFilters,
  validationClearSelectedRules,
} from 'modules/validation-dashboard/actions';
import { selectValidationSelectedFilters } from 'modules/validation-dashboard/selectors';
import { FilterValue, RuleSet } from 'modules/validation-dashboard/types';
import { formatRuleSetIdInFilter } from 'modules/validation-dashboard/utils';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ruleEntityTypes, ruleSetTypes } from '../../constants';
import { RuleSetCreationModal, RuleSetEditionModal } from '../rule-set-modal';
import { ActiveFilterLabel } from './ActiveFilterLabel';
import { DashboardBlock } from './DashboardBlock';
import './validation-rules-filters.scss';

const EditRuleSetButton = ({ displayTooltipAndDisableButton, onClick }) => {
  const button = (
    <Button primary onClick={onClick} disabled={displayTooltipAndDisableButton}>
      Edit rule set
    </Button>
  );

  if (displayTooltipAndDisableButton) {
    return (
      <LazyTooltip
        id="tooltip_update_rule_set"
        tooltipLabel="Select exactly one ruleset to edit"
      >
        {button}
      </LazyTooltip>
    );
  } else {
    return button;
  }
};

export const ValidationRulesFilters = () => {
  const dispatch = useDispatch();

  const entityTypesFilters = Object.values(ruleEntityTypes);
  const selection = useSelector(selectValidationSelectedFilters);
  const [showInactives, toggleShowInactives] = useState(false);

  const addFilter = (path, value) => {
    dispatch(validationClearSelectedRules());
    dispatch(validationAddToFilters({ path, value }));
  };

  const delFilter = (path, value) => {
    dispatch(validationClearSelectedRules());
    dispatch(validationRemoveFromFilters({ path, value }));
  };
  const onSelectOrganization = memoize((value) =>
    addFilter('usedByRetailerIdIn', value)
  );

  const onSelectUniqueProductKey = (value: FilterValue) => {
    delFilter('appliesOnPkIdIn', selection.appliesOnPkIdIn[0]);
    addFilter('appliesOnPkIdIn', value);
  };

  const [openedModal, setOpenedModal] = useState<any>(null);

  const handleRuleSetModalClosed = (ruleSet: RuleSet | undefined) => {
    setOpenedModal(null);
    if (ruleSet) {
      // place filter in the correct context
      dispatch(validationClearFilters());
      dispatch(validationAddToFilters(formatRuleSetIdInFilter(ruleSet)));
    }
  };

  const editionModal = (
    <RuleSetEditionModal
      onClose={handleRuleSetModalClosed}
      ruleSetToEdit={selection.ruleSetIdIn[0]?.value}
    />
  );

  const creationModal = (
    <RuleSetCreationModal onClose={handleRuleSetModalClosed} />
  );

  return (
    <Filters className="ValidationRulesFilters">
      <DashboardBlock title="Active Filters" secondary>
        {Object.entries(selection).map(([key, filters]) =>
          filters?.map?.((filter) => (
            <ActiveFilterLabel
              key={`${filter.key || filter}-${key}`}
              onDelete={() => delFilter(key, filter)}
              filterKey={key}
              filter={filter}
            />
          ))
        )}
        {selection.onlyActive && (
          <ActiveFilterLabel
            key={'activefilterlabel-onlyactive'}
            onDelete={() => addFilter('onlyActive', false)}
            filterKey={'onlyActive'}
            filter={'true'}
          />
        )}
      </DashboardBlock>
      <DashboardBlock title="Rule Sets">
        <RulesetAutocomplete
          onSelect={(value) => addFilter('ruleSetIdIn', value)}
          onRemove={(value) => delFilter('ruleSetIdIn', value)}
          value={selection.ruleSetIdIn}
          rulesFilter={selection}
        />
        <div className="container row buttons">
          <EditRuleSetButton
            onClick={() => setOpenedModal(editionModal)}
            displayTooltipAndDisableButton={
              selection.ruleSetIdIn.length !== 1 ||
              !selection.ruleSetIdIn[0]?.value
            }
          ></EditRuleSetButton>
          <Button primary onClick={() => setOpenedModal(creationModal)}>
            New rule set
          </Button>
        </div>
        {openedModal}
      </DashboardBlock>
      <DashboardBlock title="Rule set types" expandable={87}>
        <Filter
          id="validation-filter-ruleset-type"
          Item={CheckboxFilter}
          filters={ruleSetTypes.map((rs) => ({
            label: rs.label,
            id: rs.value,
          }))}
          selection={selection.ruleSetTypeIn}
          selectors={{ selectId: (f) => f.id, selectNode: (f) => f.label }}
          isSelected={(filter, selection) => selection.includes(filter.id)}
          onChange={(filter) =>
            selection.ruleSetTypeIn?.includes(filter.id)
              ? delFilter('ruleSetTypeIn', filter.id)
              : addFilter('ruleSetTypeIn', filter.id)
          }
          withPadding={false}
        />
      </DashboardBlock>
      <DashboardBlock title="Organization">
        <OrganizationAutocomplete
          id={`list-autocomplete-validation-filter-target-organization`}
          className="ValidationFilter__Autocomplete"
          placeholder="Select a retailer organization..."
          onSelect={(value) => onSelectOrganization(value)}
          onUnselect={(index) =>
            delFilter('usedByRetailerIdIn', selection.usedByRetailerIdIn[index])
          }
          value={selection.usedByRetailerIdIn}
          excludeList={selection.usedByRetailerIdIn}
          showInactives={showInactives}
          searchOnClick
          multiple
        />
        <SwitchButton
          testid="toggle-show-inactives"
          content="Show inactive organizations"
          checked={showInactives}
          onChange={(isSelected) => toggleShowInactives(isSelected)}
        />
      </DashboardBlock>
      <DashboardBlock title="Rules applied on product">
        <ProductKeyAutocomplete
          className="ValidationFilter__Autocomplete"
          id="validation-dashboard-product-key-autocomplete"
          placeholder="Enter a GTIN or ':pkID' ..."
          onSelect={(value) => onSelectUniqueProductKey(value)}
          onUnselect={() =>
            delFilter('appliesOnPkIdIn', selection.appliesOnPkIdIn[0])
          }
          value={selection.appliesOnPkIdIn}
          searchOnClick
        />
      </DashboardBlock>
      <DashboardBlock title="Entity types" expandable={87}>
        <Filter
          id="validation-filter-entity-type"
          Item={CheckboxFilter}
          filters={entityTypesFilters}
          selection={selection.entityTypeIn}
          selectors={{ selectId: (f) => f.id, selectNode: (f) => f.label }}
          isSelected={(filter, selection) => selection.includes(filter.id)}
          onChange={(filter) =>
            selection.entityTypeIn?.includes(filter.id)
              ? delFilter('entityTypeIn', filter.id)
              : addFilter('entityTypeIn', filter.id)
          }
          withPadding={false}
        />
      </DashboardBlock>

      <DashboardBlock title="Show only active rules">
        <SwitchButton
          testid="toggle-show-inactives-rules"
          content="Show only active rules"
          checked={selection.onlyActive}
          onChange={(isSelected) => addFilter('onlyActive', isSelected)}
        />
      </DashboardBlock>
    </Filters>
  );
};
