import { MODULE_REDUCER_KEY } from 'constants/reducer';
import { REDUCER_KEY } from './reducers';
import { get } from 'lodash/fp';

export function selectState(state) {
  return state[MODULE_REDUCER_KEY][REDUCER_KEY];
}

export function selectFilters(state) {
  return get(['filters'], selectState(state));
}

export function selectSelectedFilters(state) {
  return get(['selectedFilters'], selectState(state));
}

export function selectFiles(state) {
  return get(['files'], selectState(state));
}

export function selectViewedFile(state) {
  return get(['viewedFile'], selectState(state));
}
