import { ApiClient, getServiceUrl } from '@alkem/sdk-dashboard';
import CancelablePromise from 'cancelable-promise';

class ProductGoApi extends ApiClient {
  getSvg(body) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/text;charset=UTF-8');
    const params = this.formatParams({
      body,
      headers,
      method: 'post',
    });
    return new CancelablePromise((resolve, reject) => {
      const url = `${this.host}/product/v2/graphs/svg`;
      fetch(url, params).then(
        (response) => {
          if (response.status === 204) {
            resolve(response);
          } else if (response.ok) {
            response.blob().then((data) => {
              const reader = new FileReader();
              reader.addEventListener('loadend', () =>
                resolve({
                  status: response.status,
                  headers: response.headers,
                  data: reader.result,
                })
              );
              reader.readAsText(data);
            });
          } else {
            response.json().then((data) => {
              this.throwError(response, data, url, params);
              reject({
                status: response.status,
                headers: response.headers,
                data,
              });
            });
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  listGraphs() {
    const path = '/product/v2/graphs';
    return this.get(path);
  }
}

export default new ProductGoApi(getServiceUrl('product'), {
  cacheEnabled: false,
  acceptLanguage: 'en',
});

export const createProductApi = (host) =>
  new ProductGoApi(host, {
    cacheEnabled: false,
    acceptLanguage: 'en',
  });
