import { Modal } from '@alkem/react-ui-modal';
import { notificationError, notificationSuccess } from 'actions/notifications';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import gdsnApi from 'resources/gdsnApi';
import { ImportDropzone } from '../../dropzone';
import './modal.scss';

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

class DropGDSNFileModal extends Component {
  static propTypes = {
    onCloseModal: PropTypes.func,
    dispatch: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      files: null,
      loading: false,
    };
  }

  shouldComponentUpdate() {
    return true;
  }

  onSelectFiles = (files) => {
    this.setState({ files });
  };

  filesSelected = () => {
    const { files } = this.state;
    return !!(files && files.length);
  };

  uploadFiles = () => {
    const { files } = this.state;
    const { dispatch, onCloseModal } = this.props;
    files.forEach((file) => {
      this.setState({ loading: true });
      gdsnApi.GdsnPushAS2({}, file).then(
        () => {
          this.setState({
            loading: false,
          });
          dispatch(notificationSuccess('File successfully imported'));
          onCloseModal();
        },
        () => {
          dispatch(
            notificationError('There was an error while importing your file')
          );
          this.setState({ loading: false });
          onCloseModal();
        }
      );
    });
  };

  renderDrodown() {
    const { files } = this.state;
    if (this.filesSelected()) {
      return (
        <div className="ImportModal__summary">
          <div className="ImportModal__summaryTitle">
            {`You are about to import ${files.length} file(s):`}
          </div>
          <div className="ImportModal__fileSummary">
            {files.map((file) => (
              <div key={file.name}>
                <i className="mdi mdi-file" />
                {file.name}
              </div>
            ))}
          </div>
        </div>
      );
    }
    return <ImportDropzone onSelectFiles={this.onSelectFiles} withWarning />;
  }

  render() {
    const { onCloseModal } = this.props;
    const { files, loading } = this.state;
    return (
      <Modal
        title="Drop any GDSN File for dispatching"
        confirmButtonText="Drop"
        confirmDisabled={!files}
        isProcessing={loading}
        onConfirm={this.uploadFiles}
        onClose={onCloseModal}
      >
        {this.renderDrodown()}
      </Modal>
    );
  }
}

export default connect(mapDispatchToProps)(DropGDSNFileModal);
