import { createAction } from 'utils/actions';

import { immutableAuthApi } from 'resources/authApi';

import { notificationError, notificationSuccess } from 'actions/notifications';
import * as events from '../constants/events';
import { selectGLNModalGLN } from '../selectors/gln';
import { selectOrganizationId } from '../selectors/recipients';

export const openGLNModal = createAction(events.OPEN_GLN_MODAL);

export const closeGLNModal = createAction(events.CLOSE_GLN_MODAL);

export const onChangeGLN = (event) => async (dispatch) => {
  const { value } = event.target;
  dispatch({
    type: events.ON_CHANGE_GLN,
    value,
  });
};

export const isValidGln = (gln) => {
  if (gln.length !== 13) {
    return false;
  }
  let i = 0;
  while (i < gln.length) {
    if (gln[i] < '0' || gln[i] > '9') {
      return false;
    }
    i += 1;
  }
  return true;
};

export const addGLN = () => (dispatch, getState) => {
  const state = getState();
  const gln = selectGLNModalGLN(state);
  const organizationId = selectOrganizationId(state);
  if (!isValidGln(gln)) {
    dispatch(notificationError('Invalid GLN.'));
    dispatch(closeGLNModal());
  } else {
    immutableAuthApi
      .UserClaimGlnCreate({ gln, organization_id: organizationId })
      .then(
        () => {
          dispatch(closeGLNModal());
          dispatch(
            notificationSuccess('Your request has been sent for validation')
          );
        },
        (error) => {
          if (error.status === 409) {
            dispatch(notificationError(error.data.message));
          } else {
            dispatch(
              notificationError(
                'Could not send your request. Please contact the support'
              )
            );
          }
        }
      );
  }
};

export const removeGLN = (glnID) => (dispatch) => {
  immutableAuthApi.OrganizationOwnsGlnDelete(glnID).then(
    () => {
      dispatch(
        notificationSuccess(
          'The GLN was successfully removed from the organization'
        )
      );
      dispatch({
        type: events.ORGANIZATION_GLN_REMOVED,
        glnID,
      });
    },
    (error) => {
      if (error.status === 403) {
        notificationError(
          "You don't have the permission to remove GLN from this organization."
        );
      } else {
        dispatch(
          notificationError(
            'Could not send your request. Please contact the dev team:',
            error
          )
        );
      }
    }
  );
};
