import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as routes from 'constants/routes';

import { HeaderLayout } from '@alkem/react-layout';
import { TwinPanel } from '@alkem/react-ui-twinpanel';

import {
  GET_FIELD,
  GET_FIELDS_LIST,
  GET_FIELD_CATEGORIES_LIST,
  GET_IMPORT_MAPPINGS_LIST,
  RECEIVE_FIELDS_LIST,
} from '../actions/constants';
import { FieldSelector } from '../components/field-selector';
import { FieldSelectorHeader } from '../components/field-selector/header';
import { ImportMappingsList } from '../components/import-mappings';
import {
  selectFields,
  selectFieldCategories,
  selectSourceField,
  selectImportMappings,
} from '../selectors';
import { Field, FieldCategory } from '../types';

import './gdsn-import-mappings-view.scss';
import { FIELD_UNITS } from '../constants';

const headerProps = {
  title: 'GDSN Import Mappings',
  backHref: routes.home,
  backMessage: 'Back home',
  isTitleSmall: true,
};

export function GDSNImportMappingsView(): JSX.Element {
  const dispatch = useDispatch();
  const fields = useSelector(selectFields);
  const fieldCategories = useSelector(selectFieldCategories);
  const importMappings = useSelector(selectImportMappings);
  const sourceField = useSelector(selectSourceField);
  const [currentFieldCategory, setCurrentFieldCategory] = useState<
    FieldCategory | undefined
  >(undefined);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    dispatch({ type: GET_FIELD_CATEGORIES_LIST });
  }, [dispatch]);

  useEffect(() => {
    const currentFieldCategory = fieldCategories?.[0];
    if (currentFieldCategory) {
      dispatch({
        type: GET_FIELDS_LIST,
        entityType: currentFieldCategory.label,
        search: searchValue,
      });
      setCurrentFieldCategory(currentFieldCategory);
    } else {
      setCurrentFieldCategory(undefined);
    }
  }, [fieldCategories, dispatch, searchValue]);

  const selectField = (field: Field): void => {
    if (field && currentFieldCategory) {
      dispatch({ type: GET_FIELD, id: field.id });
      dispatch({
        type: GET_IMPORT_MAPPINGS_LIST,
        field,
        fieldCategory:
          FIELD_UNITS[field.specific ? 'specific' : currentFieldCategory.label],
      });
    }
  };

  const selectFieldCategory = (root: FieldCategory): void => {
    setCurrentFieldCategory(root);
    dispatch({ type: RECEIVE_FIELDS_LIST, fields: [] });
    dispatch({
      type: GET_FIELDS_LIST,
      entityType: root.label,
      search: searchValue,
    });
  };

  const updateSearchValue = (searchValue: string): void => {
    setSearchValue(searchValue);
    dispatch({
      type: GET_FIELDS_LIST,
      entityType: currentFieldCategory?.label,
      search: searchValue,
    });
  };

  const renderFieldSelectorHeader = (): JSX.Element => {
    if (!fieldCategories || !currentFieldCategory) {
      return <div />;
    }
    return (
      <FieldSelectorHeader
        currentFieldCategory={currentFieldCategory}
        fieldCategories={fieldCategories}
        searchValue={searchValue}
        selectFieldCategory={selectFieldCategory}
        updateSearchValue={updateSearchValue}
      />
    );
  };

  const renderFieldSelector = (): JSX.Element => {
    return (
      <div className="GDSNImportMappingsView__fieldSelectorContainer">
        {renderFieldSelectorHeader()}
        {!fields ? (
          <div />
        ) : (
          <FieldSelector fields={fields} selectField={selectField} />
        )}
      </div>
    );
  };

  const renderImportMappings = (): JSX.Element => (
    <div className="GDSNImportMappingsView__importMappingsContainer">
      {!importMappings ? (
        <div />
      ) : (
        <ImportMappingsList importMappings={importMappings} />
      )}
    </div>
  );

  return (
    <div className="GDSNImportMappingsView__body">
      <HeaderLayout {...headerProps} />
      <div className="GDSNImportMappingsView__content">
        <TwinPanel
          leftPanel={{
            title: 'Select a field',
            content: renderFieldSelector(),
            className: 'GDSNImportMappingsView__panel--left',
          }}
          rightPanel={{
            title: sourceField?.name || 'No field selected',
            content: renderImportMappings(),
            className: 'GDSNImportMappingsView__panel--right',
          }}
        />
      </div>
    </div>
  );
}
