import React from 'react';
import PropTypes from 'prop-types';

import { ModuleWrapper } from 'redux/register';

import { REPORTING_CONFIG_STORE_KEY } from './constants';
import reducer from './reducers';
import ReportingDashboard from './components';

export default function ReportingHandlingView({ location }) {
  return (
    <ModuleWrapper reducerKey={REPORTING_CONFIG_STORE_KEY} reducer={reducer}>
      <ReportingDashboard location={location} />
    </ModuleWrapper>
  );
}

ReportingHandlingView.propTypes = {
  location: PropTypes.object,
};
