import RangePicker from 'react-daterange-picker';
import { result } from 'lodash';
import { Moment } from 'moment';
import Checkbox from '@alkem/react-ui-checkbox';
import InputWithLabel from 'components/input-with-label';

import './date-selector.scss';
interface Props {
  date?: Moment;
  isUnlimited?: boolean;
  label: string;
  name: string;
  selectDate: (date: Moment) => void;
  setIsUnlimited?: (checked: boolean) => void;
  showCalendar: boolean;
  toggleShowCalendar: () => void;
}

export function ImportMappingsDateSelector({
  date,
  isUnlimited,
  label,
  name,
  selectDate,
  setIsUnlimited,
  showCalendar,
  toggleShowCalendar,
}: Props): JSX.Element {
  const onSelectDate = (date: Moment): void => {
    toggleShowCalendar();
    selectDate(date);
  };

  const formattedDate = date?.format('LL') || '';

  const updateShowCalendar = () => {
    toggleShowCalendar();
    if (!!setIsUnlimited && isUnlimited) setIsUnlimited(false);
  };

  return (
    <div
      data-testid="GDSNImportMappingsItem_dateSelector"
      className="GDSNImportMappingsItemDateSelector"
    >
      <InputWithLabel
        inputId={`gdsn-import-mappings-field-${name}-activation-date-selector`}
        label={label}
      >
        {!!setIsUnlimited && (
          <Checkbox
            id={`gdsn-import-mappings-field-${name}-activation-date-unlimited`}
            checked={isUnlimited}
            onChange={setIsUnlimited}
            label={
              <div className="GDSNImportMappingsItemDateSelectorInput__checkbox">
                Is unlimited
              </div>
            }
          />
        )}
        <div className="GDSNImportMappingsItemDateSelectorInput">
          <div className="GDSNImportMappingsItemDateSelectorInput__body">
            <span
              data-testid={`gdsn-import-mappings-field-${name}-activation-date-selector-input`}
              className="GDSNImportMappingsItemDateSelectorInput__wrapped"
              onClick={updateShowCalendar}
            >
              <input
                className="GDSNImportMappingsItemDateSelectorInput__input"
                placeholder="Date..."
                value={formattedDate}
                readOnly
              />
              <span className="GDSNImportMappingsItemDateSelectorInput__calendarIcon mdi mdi-calendar-blank" />
            </span>
          </div>
          {showCalendar && (
            <div
              data-testid={`gdsn-import-mappings-field-${name}-activation-date-selector-calendar`}
              className="GDSNImportMappingsItemDateSelectorInput__calendar"
            >
              <RangePicker
                numberOfCalendars={1}
                selectionType="single"
                onSelect={onSelectDate}
                value={(result(date, 'isValid') && date) || null}
              />
            </div>
          )}
        </div>
      </InputWithLabel>
    </div>
  );
}
