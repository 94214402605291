import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { isFunction } from 'utils';
import LimitSelect from 'components/ui/pagination/limit';
import SortSelect from 'components/ui/pagination/sort';
import PaginationButton from 'components/ui/pagination/button';

import './pagination.scss';

class Pagination extends PureComponent {
  static propTypes = {
    onPrevClick: PropTypes.func.isRequired,
    onNextClick: PropTypes.func.isRequired,
    onLimitChange: PropTypes.func,
    onSortChange: PropTypes.func,
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    limit: PropTypes.number,
    limitExtraOptions: PropTypes.object,
    sortOptions: PropTypes.array,
    sortBy: PropTypes.string,
    sortOrder: PropTypes.string,
    sortExtraOptions: PropTypes.object,
  };

  static defaultProps = {
    limitExtraOptions: {},
    sortExtraOptions: {},
  };

  constructor(props) {
    super(props);
    this.onLimitChange = this.onLimitChange.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.state = {};
  }

  onLimitChange(limit) {
    const { onLimitChange } = this.props;
    if (isFunction(onLimitChange)) {
      onLimitChange(limit);
    }
  }

  onSortChange({ sortBy, sortOrder }) {
    const { onSortChange } = this.props;
    if (isFunction(onSortChange)) {
      onSortChange(sortBy, sortOrder);
    }
  }

  render() {
    const {
      onPrevClick,
      onNextClick,
      currentPage,
      totalPages,
      limit,
      limitExtraOptions,
      sortBy,
      sortOrder,
      sortOptions,
      sortExtraOptions,
    } = this.props;

    // No totalPages means no results
    if (totalPages === 0) {
      return null;
    }

    const disabled = {
      onPrev: false,
      onNext: false,
    };
    if (currentPage === 1) {
      disabled.onPrev = true;
    }
    if (currentPage === totalPages) {
      disabled.onNext = true;
    }

    return (
      <div className="Pagination">
        {sortOptions && (
          <SortSelect
            options={sortOptions}
            sortBy={sortBy}
            sortOrder={sortOrder}
            onChange={this.onSortChange}
            extraOptions={sortExtraOptions}
          />
        )}
        {limit > 0 && (
          <LimitSelect
            limit={limit}
            onChange={this.onLimitChange}
            extraOptions={limitExtraOptions}
          />
        )}
        <div className="btn-group Pagination__btnGroup" role="group">
          <PaginationButton
            type="previous"
            disabled={disabled.onPrev}
            onClick={onPrevClick}
          />
          <PaginationButton
            type="next"
            disabled={disabled.onNext}
            onClick={onNextClick}
          />
        </div>
      </div>
    );
  }
}

export default Pagination;
