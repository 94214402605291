import { useState } from 'react';
import { HelpTooltip } from '@alkem/react-ui-helptooltip';
import { useSelector } from 'react-redux';
import { selectSettings } from '../../../selectors/settings';
import classNames from 'classnames';
import { Radio } from '@alkem/react-ui-inputs';
import './enum-radio.scss';

const formatOption = (option, baseId) => ({
  value: option.value,
  label: (
    <span>
      {`${option.label} `}
      {!!option.help && (
        <HelpTooltip id={`${baseId}-${option.value}`} message={option.help} />
      )}
    </span>
  ),
});

export const RadioController = (props) => {
  const { id, options, onChange, valueGetter, disabled, label, help } = props;

  const settings = useSelector(selectSettings);
  const [value, setValue] = useState(valueGetter(settings));

  const classes = {
    OrganizationPageSettingEnumRadioController__radio: true,
    'OrganizationPageSettingEnumRadioController__radio--withLabel': !!label,
  };

  const handleChange = (event) => {
    const newValue = event.currentTarget.value;
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <div className="OrganizationPageSettingEnumRadioController">
      {label && (
        <span className="OrganizationPageSettingEnumRadioController__label">
          {`${label} `}
          {help && <HelpTooltip id={`${id}-help`} message={help} />}
        </span>
      )}
      <div className={classNames(classes)}>
        <Radio
          id={id}
          value={value}
          options={options.map(formatOption)}
          onChange={handleChange}
          disabled={disabled}
          vertical
        />
      </div>
    </div>
  );
};
