import { createAction } from '../../../utils/actions';
import {
  ORGANIZATION_CREATE_MODAL_OPEN,
  ORGANIZATION_CREATE_MODAL_CLOSE,
  ORGANIZATION_CREATE_MODAL_LOADING,
} from '../constants/events';

export const openOrganizationCreateModal = createAction(
  ORGANIZATION_CREATE_MODAL_OPEN
);
export const closeOrganizationCreateModal = createAction(
  ORGANIZATION_CREATE_MODAL_CLOSE
);
export const organizationCreateModalIsLoading = createAction(
  ORGANIZATION_CREATE_MODAL_LOADING
);
