import { commands as userCommands } from './user';
import { commands as organizationCommands } from './organization';
import { commands as defaultCommands } from './default';
import { commands as referentialCommands } from './referential';

export const commands = [
  ...defaultCommands,
  ...userCommands,
  ...organizationCommands,
  ...referentialCommands,
];
