import * as events from '../constants/events';

export const receiveGDSNPSRList = (list, totalResults) => ({
  type: events.RECEIVE_GDSN_PSR_LIST,
  payload: {
    list,
    totalResults,
  },
});

export const getGDSNPSRList = () => ({
  type: events.GET_GDSN_PSR_LIST,
});

export const getGDSNPSRListFilters = () => ({
  type: events.GET_GDSN_PSR_LIST_FILTERS,
});

export const changeLimit = (limit) => ({
  type: events.CHANGE_LIMIT_GDSN_PSR_LIST,
  limit,
});

export const nextPage = () => ({
  type: events.NEXT_PAGE_GDSN_PSR_LIST,
});

export const previousPage = () => ({
  type: events.PREVIOUS_PAGE_GDSN_PSR_LIST,
});

export const addGDSNPSRListFilters = (key, value) => ({
  type: events.ADD_GDSN_PSR_LIST_FILTER,
  key,
  value,
});

export const removeGDSNPSRListFilters = (key, value) => ({
  type: events.REMOVE_GDSN_PSR_LIST_FILTER,
  key,
  value,
});

export const updateGDSNPSRListFilters = (key, value) => ({
  type: events.UPDATE_GDSN_PSR_LIST_FILTER,
  key,
  value,
});

export const clearGDSNPSRListFilters = (key, value) => ({
  type: events.CLEAR_GDSN_PSR_LIST_FILTER,
  key,
  value,
});

export const downloadGDSNDocument = (documentID) => ({
  type: events.DOWNLOAD_GDSN_PSR_DOCUMENT,
  documentID,
});

export const getGDSNPSRDetail = (psrID, gtin) => ({
  type: events.GET_GDSN_PSR_DETAIL,
  psrID,
  gtin,
});

export const receiveGDSNPSRDetail = (data, psrID, gtin) => ({
  type: events.RECEIVE_GDSN_PSR_DETAIL,
  data,
  psrID,
  gtin,
});

export const deleteGDSNPSRItem = (itemID, psrID, gtin) => ({
  type: events.DELETE_GDSN_PSR_ITEM,
  itemID,
  psrID,
  gtin,
});
