import { Button } from '@alkem/react-ui-button';
import { Modal } from '@alkem/react-ui-modal';
import { CodeEditor } from 'components/code-editor';
import { fromJS } from 'immutable';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { isValidJSON } from 'utils';
import './plugins.scss';

export default class FieldPlugins extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    plugins: PropTypes.object,
    update: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      plugins: props.plugins,
      modalOpen: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { plugins } = this.props;
    if (plugins !== prevProps.plugins) {
      this.setState({ plugins }); // eslint-disable-line
    }
  }

  onOpenModal = () => {
    this.setState({ modalOpen: true });
  };

  onCloseModal = () => {
    this.setState({ modalOpen: false, plugins: this.props.plugins });
  };

  onSave = () => {
    this.props.update(this.state.plugins);
    this.onCloseModal();
  };

  onUpdate = (value) => {
    if (isValidJSON(value)) {
      this.setState({ plugins: fromJS(JSON.parse(value)) || Map() });
    }
  };

  renderPlugins() {
    const { plugins } = this.state;
    return (
      <div className="FieldPlugins__body">
        <CodeEditor
          name="plugins"
          className="FieldPlugins__content"
          value={JSON.stringify(plugins.toJS(), null, '\t')}
          onChange={this.onUpdate}
          showPrintMargin={false}
          showGutter={false}
          width="100%"
          minLines={5}
          maxLines={10}
          mode="json"
          theme="tomorrow"
          editorProps={{ $blockScrolling: Infinity }}
        />
        Here are the most common options that can be used:
        <div className="FieldPlugins__helpHeader">
          <code>options</code>
        </div>
        <div className="FieldPlugins__helpValue">
          <code>cacheFullList: true</code>: cache initial result of empty query
          (useful for fields listing lots of values, and whose referential
          values don't change)
        </div>
        <div className="FieldPlugins__helpValue">
          <code>readOnly: true</code>: make a field always read only
        </div>
        <div className="FieldPlugins__helpValue">
          <code>richContent: true</code>: enable rich content for that field
          (text only)
        </div>
        <div className="FieldPlugins__helpValue">
          <code>singleItem: true</code>: allows only one item to be filled
          (lists only)
        </div>
        <div className="FieldPlugins__helpValue">
          <code>visible: false</code>: the front is aware of the field but it's
          hidden
        </div>
        <div className="FieldPlugins__helpValue">
          <div className="FieldPlugins__helpHeader">
            <code>display</code>
          </div>
          <div className="FieldPlugins__helpValue">
            <code>label: false</code>: only display the input and hide the label
          </div>
          <div className="FieldPlugins__helpValue">
            <code>values: "code"</code>: display the code rather than the label
            for autocompletes
          </div>
          <div className="FieldPlugins__helpValue">
            <code>values: "labelWithCode"</code>: display "label (code)" for
            autocompletes
          </div>
        </div>
        <div className="FieldPlugins__helpValue">
          <code>mandatory: true</code>: make a field appears as mandatory (*)
        </div>
        <div className="FieldPlugins__helpHeader">
          <code>inputKind</code>
        </div>
        <div className="FieldPlugins__helpValue">
          <code>kind: "someSpecificInputKind"</code>: override the default
          display
        </div>
        <div className="FieldPlugins__helpValue">
          <code>full: true</code>: make the list display all possible values
          (have to set <code>mainKey</code> as well)
        </div>
        <div className="FieldPlugins__helpValue">
          <code>mainKey: "isConceptualizedBy"</code>: when <code>full</code> is
          set, fill from this field's values
        </div>
        <div className="FieldPlugins__helpValue">
          <code>pictureKind: "Label"</code>: to display images from the
          referential picture dashboard
        </div>
        <div className="FieldPlugins__helpValue">
          <code>defaultValue: 1</code>: override the default value
        </div>
      </div>
    );
  }

  render() {
    const { readOnly } = this.props;
    const { modalOpen } = this.state;
    return (
      <div className="FieldPlugins" id={this.props.id}>
        {!readOnly && (
          <>
            <Button content="Edit" onClick={this.onOpenModal} secondary />
            {modalOpen && (
              <Modal
                title="Plugins"
                confirmButtonText="Save"
                onConfirm={this.onSave}
                closeButtonText="Close"
                onClose={this.onCloseModal}
              >
                {this.renderPlugins()}
              </Modal>
            )}
          </>
        )}
      </div>
    );
  }
}
