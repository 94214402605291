import { isEqual } from 'lodash';
import { flow, set } from 'lodash/fp';
import { createReducer } from 'utils/reducers';
import {
  FETCH_PERMISSIONS,
  RECEIVE_PERMISSIONS,
  RECEIVE_ORGANIZATION_CONFIGURATION,
  FETCH_ORGANIZATION_CONFIGURATION,
  UPSERT_ORGANIZATION_CONFIGURATION,
} from '../constants/events';
import { OrganizationPermissionsState } from '../types';

const initialState: OrganizationPermissionsState = {
  permissions: null,
  configuration: null,
  isDirty: false,
};

export const permissionsReducer = createReducer<OrganizationPermissionsState>(
  initialState,
  {
    [FETCH_PERMISSIONS]: set('permissions', null),
    [RECEIVE_PERMISSIONS]: (state, { payload: permissions = null }) =>
      set('permissions', permissions, state),
    [FETCH_ORGANIZATION_CONFIGURATION]: set('configuration', null),
    [UPSERT_ORGANIZATION_CONFIGURATION]: (
      state,
      { payload: configuration = null }
    ) =>
      flow(
        set('configuration', configuration),
        set('isDirty', !isEqual(configuration, state))
      )(state),
    [RECEIVE_ORGANIZATION_CONFIGURATION]: (
      state,
      { payload: configuration = null }
    ) =>
      flow(set('configuration', configuration), set('isDirty', false))(state),
  }
);
