import { CodeEditor } from 'components/code-editor';
import { PureComponent } from 'react';
import './json-displayer.scss';
import { filterOnKeys } from './utils';

interface Props {
  value: object | any[];
}

interface State {
  keyFilter: string;
}

class JsonDisplayer extends PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      keyFilter: '',
    };
  }

  onKeyFilterChange = (evt) => {
    this.setState({ keyFilter: evt.target.value });
  };

  render() {
    const { value } = this.props;
    const { keyFilter } = this.state;

    return (
      <>
        <div className="ace-monokai flex flex-align-items--center">
          <span>
            <i className="mdi mdi-magnify" />
          </span>
          <input
            type="text"
            value={keyFilter}
            className="form-control ace-monokai-form"
            onChange={this.onKeyFilterChange}
            placeholder="Search on the keys"
          />
        </div>
        <CodeEditor
          name="payload"
          className="JsonDisplayer__AceEditor"
          value={JSON.stringify(filterOnKeys(value, keyFilter), null, '\t')}
          readOnly
          mode="json"
          theme="monokai"
          width="inital"
          height="inital"
          editorProps={{ $blockScrolling: Infinity }}
        />
      </>
    );
  }
}

export default JsonDisplayer;
