import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import classNames from 'classnames';

import { isValidJSON } from 'utils';

import { Button } from '@alkem/react-ui-button';
import { Text, Textarea } from '@alkem/react-ui-inputs';

export default class ReferentialManagementTopNodeAddition extends PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool,
    isAdding: PropTypes.bool,
    addRootNode: PropTypes.func,
  };

  state = {
    code: null,
    label: null,
    data: null,
  };

  onChange = (nodeKey) => (event) => {
    const { value } = event.target;
    this.setState({ [nodeKey]: value });
  };

  onAdd = () => {
    this.props.addRootNode({
      ...this.state,
      data: JSON.parse(this.state.data),
    });
    this.setState({
      code: null,
      label: null,
      data: null,
    });
  };

  render = () => {
    const { isAdding, isLoading } = this.props;
    const { code, label, data } = this.state;
    const dataClasses = classNames({
      'OrganizationUsesFieldsView__referential__data--invalid':
        data !== null && !isValidJSON(data),
    });
    return (
      <div className="OrganizationUsesFieldsView__referentials__add_root">
        <div className="OrganizationUsesFieldsView__referential__update__row">
          <div>Code</div>
          <div>Label</div>
          <div>Data</div>
        </div>
        <div className="OrganizationUsesFieldsView__referential__update__row">
          <div>
            <Text
              id="organization-referentials-add-root-code"
              onChange={this.onChange('code')}
              value={code}
            />
          </div>
          <div>
            <Text
              id="organization-referentials-add-root-label"
              onChange={this.onChange('label')}
              value={label}
            />
          </div>
          <div className={dataClasses}>
            <Textarea
              id="organization-referentials-add-root-data"
              onChange={this.onChange('data')}
              value={data}
            />
          </div>
          <div>
            <Button
              id="organization-referentials-add-root-button"
              onClick={this.onAdd}
              content="Add top referential"
              disabled={isLoading || !code || !label}
              displaySpinner={isAdding}
              primary
            />
          </div>
        </div>
      </div>
    );
  };
}
