import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Map } from 'immutable';
import { HelpTooltip } from '@alkem/react-ui-helptooltip';
import { LISTCOLUMN } from 'constants/organization-settings';
import ListMultipleController from './controllers/listmultiple';
import { retrieveListColumnReferential } from '../../actions/settings';
import {
  selectListColumnReferential,
  hasListColumnChange,
  selectSettings,
} from '../../selectors/settings';
import './listcolumn.scss';

const reviewEngineColumns = [100457713, 100588237, 100588425];

export const OrganizationPageSettingsListColumn = React.memo(
  ({ isReadOnly }: { isReadOnly?: boolean }) => {
    const dispatch = useDispatch();
    const settings = useSelector(selectSettings);
    const listColumnSettingEdited: boolean = useSelector(hasListColumnChange);
    const referential: any[] = useSelector(selectListColumnReferential);
    const hasReviewEngine = useMemo(
      () => settings.get('review-engine'),
      [settings]
    );
    const paths = useMemo(
      () => ({
        allowed: 'Catalog',
        sourcing: 'Sourcing',
        assignation: 'Assignation',
        ...(hasReviewEngine ? { review: 'Review' } : {}),
      }),
      [hasReviewEngine]
    );

    useEffect(() => {
      dispatch(retrieveListColumnReferential());
    }, [dispatch]);

    const getSubLabel = useCallback((option: Map<'code', string>) => {
      return option.get('code');
    }, []);

    const checkReviewEngine = ({ id, subPath, checked }) => {
      if (
        hasReviewEngine &&
        subPath === 'review' &&
        !checked &&
        reviewEngineColumns.includes(id)
      ) {
        return confirm(
          'This could impact the way products to be reviewed are displayed on this organization, are you sure you want to continue ?'
        );
      }
      return true;
    };

    return (
      <div className="OrganizationPageListColumnSetting OrganizationPageBlock">
        <div className="OrganizationPageBlock__header">
          <h2>
            Setting: List columns{' '}
            <HelpTooltip
              id="listcolumn-help"
              message="Fields available to display on catalog"
            />
          </h2>
          <span className="OrganizationPageBlock__edited">
            {listColumnSettingEdited && (
              <span>
                <i className="mdi mdi-alert" />
                Edited
              </span>
            )}
          </span>
        </div>
        <div className="OrganizationPageListColumnSetting__content">
          <ListMultipleController
            pathsLabels={Object.values(paths)}
            path={[LISTCOLUMN]}
            subPaths={Object.keys(paths)}
            options={referential}
            getSubLabel={getSubLabel}
            disabled={isReadOnly}
            onChange={checkReviewEngine}
          />
        </div>
      </div>
    );
  }
);

export default OrganizationPageSettingsListColumn;
