export const home = '/';
export const login = '/login';
export const logout = '/logout';
export const kinds = '/kinds';
export const fields = '/fields/:view';
export const transactionExplorer = '/transaction-explorer';
export const transaction = '/transaction';
export const referentials = '/referentials';
export const classification = '/classification';
export const organizationUsesFields = '/organization-uses-fields';
export const organization = '/organization/:id';
export const organizations = '/organization';
export const organizationGroups = '/organization-groups';
export const organizationGroupFieldRoot = '/organization-group-field';
export const organizationGroupField = `${organizationGroupFieldRoot}/:organizationId`;
export const reportingHandling = '/reporting-handling';
export const applicationsRoot = '/applications';
export const applications = `${applicationsRoot}/:id?`;
export const displayGroups = '/display-groups';
export const ioImport = '/io/import';
export const ioImportV2 = '/io/importv2';
export const ioImportGeneric = '/io/import/generic';
export const ioExport = '/io/export';
export const ioEndpoints = '/io/endpoints';
export const ioGdsnCi = '/io/gdsn/ci';
export const ioGdsnCis = '/io/gdsn/cis';
export const ioGdsnParty = '/io/gdsn/party';
export const ioGdsnRci = '/io/gdsn/rci';
export const ioGdsnPsr = '/io/gdsn/psr';
export const ioGdsnRelation = '/io/gdsn/relation';
export const ioGdsnImports = '/io/gdsn/imports';
export const ioGdsnExports = '/io/gdsn/exports';
export const ioMapperAfs = '/io/mapper/afs';
export const ioMapperAfsCategories = '/io/mapper/afs/categories';
export const ioMapperAfsCategory = '/io/mapper/afs/categories/:id';
export const ioMapperAfsAttributes = '/io/mapper/afs/attributes';
export const ioMapperAfsAttribute = '/io/mapper/afs/attributes/:name';
export const ioMapperAfsFunctions = '/io/mapper/afs/functions';
export const ioMapperAfsFunction = '/io/mapper/afs/functions/:id';
export const validationDashboard = '/validation/dashboard';
export const validationRuleEditor = '/validation/rule/:ruleId';
export const users = '/user';
export const pictureComparator = '/retailer/picture-comparator';
export const fieldSuggestion = '/fieldsuggestion';
export const productWorkflowPlay = '/product-workflow/play';
export const brandClaims = '/brand/claims';
export const glnClaims = '/gln/claims';
export const referentialPictures = '/referential/pictures';
export const servicesDiscover = '/services/discover';
export const servicesOpenapi = '/services/openapi';
export const edaRmqReplayer = '/eda/rabbitmq/replayer';
export const edaRmqExplorer = '/eda/rabbitmq/explorer';
export const edaRmqViewer = '/eda/rabbitmq/viewer';
export const edaEventCaptures = '/eda/events/captures';
export const edaCapturedEvents = '/eda/events/capture/:captureId';
export const edaCapturedEventsGraph =
  '/eda/events/capture/:captureId/graph/:rootRequestId';
export const indexerQueue = '/indexer/queue';
export const indexerConfig = '/indexer/pumpconfig';
export const rabbitmq = '/rabbitmq';
export const fieldsPage = '/fields';
export const settings = '/settings';
export const setting = `${settings}/:id`;
export const accessPolicy = '/access-policy';
export const accessPolicyEdit = `${accessPolicy}/:id`;
export const productMaintenance = '/product-maintenance';
export const translation = '/translation';
export const productReview = '/product-review';
