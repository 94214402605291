import { MediaApi } from '@alkem/sdk-dashboard';

export const immutableMediaApi = new MediaApi({
  immutable: true,
  cacheEnabled: true,
  acceptLanguage: 'en',
});

export default new MediaApi({
  cacheEnabled: true,
  acceptLanguage: 'en',
});
