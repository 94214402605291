import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { SimpleSelect } from '@alkem/react-ui-select';
import { Text } from '@alkem/react-ui-inputs';
import './left-panel-header.scss';

export default class LeftPanelHeader extends PureComponent {
  static propTypes = {
    options: PropTypes.array.isRequired,
    currentOption: PropTypes.object,
    selectOption: PropTypes.func.isRequired,
    search: PropTypes.string,
    updateSearch: PropTypes.func.isRequired,
  };

  onUpdateSearch = (event) => {
    this.props.updateSearch(event.target.value);
  };

  render() {
    const { options, currentOption, search } = this.props;
    return (
      <div className="LeftPanelHeader">
        <div className={'col-xs-14'}>
          <SimpleSelect
            id="left-panel-options"
            options={options}
            value={currentOption}
            onSelect={this.props.selectOption}
            autoSize
          />
        </div>
        <div className="LeftPanelHeader__search">
          <Text
            id="left-panel-search"
            placeholder="Search"
            onChange={this.onUpdateSearch}
            value={search}
          />
        </div>
      </div>
    );
  }
}
