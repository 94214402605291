import { Button } from '@alkem/react-ui-button';
import EmptyState from '@alkem/react-ui-empty-state';
import { Spinner } from '@alkem/react-ui-spinner';
import ListController from 'components/ui/list/controller';
import { validationDashboard } from 'constants/routes';
import { isEqual, noop } from 'lodash';
import {
  validationFetchRules,
  validationSelectRule,
  validationSetPagination,
  validationToggleRules,
  validationClearSelectedRules,
  validationDeleteRules,
  validationMoveRulesToRuleset,
  validationClearFilters,
  validationAddToFilters,
} from 'modules/validation-dashboard/actions';
import {
  selectSelectedRules,
  selectValidationDirtyRule,
  selectValidationIsLoading,
  selectValidationPagination,
  selectValidationSelectedFilters,
} from 'modules/validation-dashboard/selectors';
import { Rule, RuleSet } from 'modules/validation-dashboard/types';
import { ruleEntityTypesMap } from '../../constants';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router';
import { ValidationRuleEditor } from '../rule-editor';
import { ValidationRuleBeta } from './validation-rule-beta';
import './validation-rules-list.scss';
import { kindsIndexSelector } from 'redux/selectors';
import { Checkbox } from '@alkem/react-ui-checkbox';
import Dropdown from 'components/dropdown';
import { BulkEditProps, BulkEditRulesModal } from '../bulk-edit-rules-modal';

export enum BulkEditRulesMode {
  MOVE = 'move',
  DELETE = 'delete',
}

interface Props {
  rules: Rule[];
}

export const ValidationRulesList = ({ rules }: Props) => {
  const dispatch = useDispatch();

  const selectedFilters = useSelector(selectValidationSelectedFilters);

  const onSelectRule = useCallback(
    (ruleId: number | string) => {
      dispatch(validationSelectRule(ruleId));
    },
    [dispatch]
  );

  const [isSelectRulesetModalOpen, openSelectRulesetModal] = useState(false);

  const [bulkModalMode, setBulkModalMode] = useState(BulkEditRulesMode.MOVE);

  const selectedRules = useSelector(selectSelectedRules);
  const selectedRulesQty = Object.values(selectedRules).filter(
    (value) => value
  ).length;
  const isLoading = useSelector(selectValidationIsLoading);
  const pagination = useSelector(selectValidationPagination);
  const dirtyRule = useSelector(selectValidationDirtyRule);
  const kinds = useSelector(kindsIndexSelector);
  const [showAllSummaries, setShowAllSummaries] = useState(false);

  const bulkOptions = useMemo(
    () => [
      {
        key: 'moveselectedrules',
        label: `Move ${selectedRulesQty} rule(s) to another ruleset`,
        onClick: () => {
          setBulkModalMode(BulkEditRulesMode.MOVE);
          openSelectRulesetModal(true);
        },
      },
      {
        key: 'deleteselectedrules',
        label: `Delete ${selectedRulesQty} rule(s)`,
        onClick: () => {
          setBulkModalMode(BulkEditRulesMode.DELETE);
          openSelectRulesetModal(true);
        },
      },
    ],
    [selectedRulesQty]
  );

  const changePage = useCallback(
    (currentPage: number) => {
      dispatch(validationClearSelectedRules());
      dispatch(
        validationSetPagination({
          currentPage,
        })
      );
    },
    [dispatch]
  );

  const setItemsPerPage = useCallback(
    (limit: number) => {
      dispatch(validationClearSelectedRules());
      dispatch(
        validationSetPagination({
          currentPage: 1,
          limit,
        })
      );
    },
    [dispatch]
  );

  const sortedRules = useMemo(
    () =>
      rules?.reduce((acc, rule) => {
        if (!acc[Number(rule.ruleEntityType)]) {
          acc[Number(rule.ruleEntityType)] = [];
        }
        acc[Number(rule.ruleEntityType)].push(rule);
        return acc;
      }, {}),
    [rules]
  );

  function isBulkSelectCheckboxPartiallyChecked(rules, selectedRulesQty) {
    if (!selectedRulesQty || selectedRulesQty === rules.length) {
      return false;
    } else {
      return true;
    }
  }

  function isBulkSelectCheckboxChecked(rules, selectedRulesQty) {
    if (!selectedRulesQty || selectedRulesQty !== rules.length) {
      return false;
    } else {
      return true;
    }
  }

  const isBulkSelectCheckboxCheckedStatus = isBulkSelectCheckboxChecked(
    rules,
    selectedRulesQty
  );
  const isBulkSelectCheckboxPartiallyCheckedStatus =
    isBulkSelectCheckboxPartiallyChecked(rules, selectedRulesQty);

  function handleBulkSelectCheckboxUpdate(checked, minus, rules) {
    const ruleToggleStates = {};
    const selectedState = !checked && !minus;
    for (const rule of rules) {
      ruleToggleStates[rule.id] = selectedState;
    }

    dispatch(validationToggleRules(ruleToggleStates));
  }

  function nextPage() {
    if (pagination.currentPage < pagination.totalPages) {
      changePage(pagination.currentPage + 1);
    } else {
      changePage(1);
    }
  }

  function prevPage() {
    if (pagination.currentPage > 1) {
      changePage(pagination.currentPage - 1);
    } else {
      changePage(pagination.totalPages);
    }
  }

  function filterSelectedRules(rules, selectedRules): Array<Rule> {
    return rules.filter((rule) => {
      return Object.keys(selectedRules).includes(rule.id.toString());
    });
  }

  const setFilter = (path, value, isOnlyActive) => {
    dispatch(validationClearFilters());
    dispatch(validationClearSelectedRules());
    dispatch(validationAddToFilters({ path, value }));
    if (!isOnlyActive) {
      dispatch(
        validationAddToFilters({ path: 'onlyActive', value: isOnlyActive })
      );
    }
  };

  const bulkEditModalPropsForMode = (
    mode: BulkEditRulesMode,
    rules: Array<Rule>,
    selectedRules
  ): BulkEditProps => {
    const commonProps = {
      id: 'rules-to-ruleset-modal',
      rules: filterSelectedRules(rules, selectedRules),
      onCloseAction: () => {
        openSelectRulesetModal(false);
      },
    };

    switch (mode) {
      case BulkEditRulesMode.MOVE:
        return {
          ...commonProps,
          title: 'Assign rule(s) to a new ruleset',
          eligibleRulesHeader:
            'These rules will be assigned to the selected ruleset:',
          ineligibleRulesHeader:
            "These rules won't be assigned to the selected ruleset:",
          confirmButtonText: 'Move rules to ruleset',
          requiresRuleSetSelection: true,
          performAction: (
            selectedRuleIds: number[],
            selectedRuleSet: RuleSet | undefined
          ) => {
            dispatch(
              validationMoveRulesToRuleset({
                ruleIds: selectedRuleIds,
                rulesetId: selectedRuleSet ? selectedRuleSet.id : 0,
              })
            );

            setFilter(
              'ruleSetIdIn',
              selectedRuleSet,
              selectedFilters.onlyActive
            );
          },
        };
      case BulkEditRulesMode.DELETE:
        return {
          ...commonProps,
          title: 'Delete rule(s)',
          eligibleRulesHeader: 'These rules will be deleted:',
          ineligibleRulesHeader: "These rules won't be deleted:",
          confirmButtonText: 'Delete rules',
          requiresRuleSetSelection: false,
          performAction: (selectedRuleIds: number[]) => {
            dispatch(validationDeleteRules(selectedRuleIds));
            dispatch(validationClearSelectedRules());
          },
        };
      default:
        throw Error(`No properties  defined for mode ${mode}`);
    }
  };

  return (
    <div className="ValidationRulesList">
      <Switch>
        <Route
          path={`/validation/rule/:ruleId`}
          component={ValidationRuleEditor}
        />
        <Route path={`${validationDashboard}`}>
          <ListController
            currentPage={pagination.currentPage}
            totalPages={pagination.totalPages}
            totalResults={pagination.totalResults}
            type="default"
            sortBy={pagination.sortBy}
            sortOrder={pagination.sortOrder}
            limit={pagination.limit}
            onNext={() => nextPage()}
            onPrev={() => prevPage()}
            onLimitChange={(value) => setItemsPerPage(value)}
            rowsLength={rules.length}
            actions={
              <>
                <div className="ValidationRuleGeneralCheckboxContainer">
                  <Checkbox
                    checked={isBulkSelectCheckboxCheckedStatus}
                    partiallyChecked={
                      isBulkSelectCheckboxPartiallyCheckedStatus
                    }
                    onChange={() =>
                      handleBulkSelectCheckboxUpdate(
                        isBulkSelectCheckboxCheckedStatus,
                        isBulkSelectCheckboxPartiallyCheckedStatus,
                        rules
                      )
                    }
                    id={'general-validation-dashboard'}
                    testid={'checkbox-general-validation-dashboard'}
                  />
                </div>
                <Button
                  onClick={() => dispatch(validationFetchRules())}
                  secondary
                  testid="ValidationRulesListRefresh"
                >
                  <i className="mdi mdi-refresh" />
                </Button>
                <Button
                  onClick={() => setShowAllSummaries(!showAllSummaries)}
                  secondary
                  testid="ValidationRulesListShowAllSummaries"
                >
                  {showAllSummaries ? 'Hide summaries' : 'Show summaries'}
                </Button>
                <Dropdown
                  disabled={!selectedRulesQty}
                  buttonClassName={'btn-secondary'}
                  label={'Bulk actions'}
                  options={bulkOptions}
                  selectOptions={noop}
                  rightDropdown
                  closeDropdownOnClickElement
                  id="validation-rules-bulkaction-dropdown"
                />
              </>
            }
          >
            <div className="ValidationRulesList__sheet">
              {isLoading && (
                <div className="ValidationRulesList__spinner">
                  <Spinner loading big />
                </div>
              )}
              {!isLoading &&
                Object.entries(sortedRules).map(([key, mapRules]: any) => {
                  if (!rules?.length) {
                    return (
                      <div
                        key="no-rules"
                        className="ValidationRulesList--empty"
                      >
                        <EmptyState
                          title="Nothing to see here!"
                          text="No rule matches the selected filters. Try again"
                        />
                      </div>
                    );
                  }
                  return (
                    <div key={`category-${key}`}>
                      <div
                        className="ValidationRulesList__headerLabel"
                        data-testid="rule-entity-type-separator"
                      >
                        {ruleEntityTypesMap[key].label}
                      </div>
                      <div>
                        {mapRules.map((rule) => (
                          <ValidationRuleBeta
                            key={`rule-${rule.id}`}
                            rule={rule}
                            selectRule={() => onSelectRule(rule.id)}
                            isDirty={
                              dirtyRule.id === rule.id &&
                              !isEqual(dirtyRule, rule)
                            }
                            showSummary={showAllSummaries}
                            kinds={kinds}
                          />
                        ))}
                      </div>
                    </div>
                  );
                })}
            </div>
          </ListController>
        </Route>
      </Switch>
      {isSelectRulesetModalOpen && (
        <BulkEditRulesModal
          {...bulkEditModalPropsForMode(bulkModalMode, rules, selectedRules)}
        />
      )}
    </div>
  );
};
