import { notificationError } from 'actions/notifications';

import displayGroupApi from 'resources/displayGroupApi';
import * as events from './events';

export const loadDisplayGroups = () => (dispatch) =>
  displayGroupApi.listAdmin().then(
    (response) =>
      dispatch({
        type: events.DISPLAY_GROUPS_LOAD,
        displayGroups: response.data.data,
      }),
    () =>
      dispatch(
        notificationError(
          'An error occured while loading the product page structure'
        )
      )
  );

export const updateData = (path, value) => (dispatch) =>
  dispatch({
    type: events.DISPLAY_GROUPS_UPDATE_DATA,
    path,
    value,
  });

export const createDisplayGroup = (displayGroup) => (dispatch) =>
  displayGroupApi.create(displayGroup).then(
    () => dispatch(loadDisplayGroups()),
    (error) =>
      dispatch(
        notificationError(
          `An error occured while creating display group ${displayGroup.get(
            'label'
          )}. ${error.data.message}`
        )
      )
  );

export const deleteDisplayGroup = (displayGroupId) => (dispatch) =>
  displayGroupApi.delete(`/core/v3/displaygroups/${displayGroupId}`).then(
    () => dispatch(loadDisplayGroups()),
    (error) =>
      dispatch(
        notificationError(
          `An error occured while deleting the display group: ${error.data.message}`
        )
      )
  );

export const updateDisplayGroup = (displayGroup) => (dispatch) => {
  const displayGroupId = displayGroup.get('id');
  const data = displayGroup.toJS();
  return displayGroupApi.update(displayGroupId, data).then(
    () => dispatch(loadDisplayGroups()),
    (error) =>
      dispatch(
        notificationError(
          `An error occured while updating display group ${displayGroup.get(
            'label'
          )}. ${error.data.message}`
        )
      )
  );
};

export const updateFields = (displayGroup) => (dispatch) => {
  const displayGroupId = displayGroup.get('id');
  const fields = displayGroup.get('fields').toJS();
  return displayGroupApi.updateFields(displayGroupId, fields).then(
    (response) =>
      dispatch({
        type: events.DISPLAY_GROUPS_REFRESH_ONE,
        displayGroupId,
        fields: response.data.data,
      }),
    (error) =>
      dispatch(
        notificationError(
          `An error occured while updating the fields order. ${error.data.message}`
        )
      )
  );
};
