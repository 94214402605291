import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import './modal.scss';
import { TurboSelect } from '@alkem/react-ui-turbo-select';
import { Modal } from '@alkem/react-ui-modal';
import * as api from '../../constants/api';
import EtlApi from 'resources/etlApi';
import qs from 'querystringify';
import ListInput from './editor';

export default class AttributeModal extends PureComponent {
  static propTypes = {
    attribute: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    onSaveAttribute: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      functionList: null,
      editedAttribute: this.props.attribute,
    };
  }

  selectFunction = (selectedFunction) => {
    const { editedAttribute } = this.state;
    this.setState({
      editedAttribute: { ...editedAttribute, function: selectedFunction },
    });
  };

  save = () => {
    this.closeModalEdit();
    this.onSaveAttribute();
  };

  closeModalEdit = () => {
    this.props.onClose();
  };

  onSaveAttribute = () => {
    this.props.onSaveAttribute(this.state.editedAttribute);
  };

  loadFunctionList = async (functionSearch) => {
    const response = await EtlApi.get(
      api.ETL_API_LIST_FUNCTIONS,
      qs.stringify({ search: functionSearch }, true),
      false
    );
    return response.data.data.map((f) => ({
      id: f.id,
      name: f.name,
      description: f.description,
    }));
  };

  onUpdateParameters = (parameters) => {
    const { editedAttribute } = this.state;
    this.setState({
      editedAttribute: { ...editedAttribute, parameters: parameters },
    });
  };

  render() {
    const { editedAttribute } = this.state;
    if (!editedAttribute) {
      return null;
    }
    return (
      <Modal
        title={'Edit attribute ' + editedAttribute.name}
        modalStyle="large"
        confirmButtonText="Save"
        onConfirm={this.save}
        closeButtonText="Cancel"
        onClose={this.closeModalEdit}
      >
        <div>
          Mapping function
          <div>
            <TurboSelect
              loadOptions={this.loadFunctionList}
              getOptionLabel={({ name }) => `${name}`}
              getOptionValue={({ id }) => `${id}`}
              value={editedAttribute.function}
              isClearable={false}
              isMulti={false}
              isSearchable={true}
              isDisabled={false}
              onChange={this.selectFunction}
            />
            {editedAttribute.function && (
              <span className="Modal__span">
                {editedAttribute.function.description}
              </span>
            )}
          </div>
          <ListInput
            list={editedAttribute.parameters}
            onUpdate={this.onUpdateParameters}
            label="Parameters"
          />
        </div>
      </Modal>
    );
  }
}
