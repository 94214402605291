import { Link } from 'react-router-dom';
import classNames from 'classnames';

interface Card {
  name: string; // eslint-disable-line no-restricted-globals
  path: string;
  external?: boolean;
}

interface Props {
  card: Card;
  isFavorite: boolean;
  onFavorite: (card: Card, isFavorite: boolean) => void;
}

function HomeCard({ card, isFavorite, onFavorite }: Props) {
  return (
    <div className="HomeView__wrapper col-lg-4 col-md-6 col-xs-12">
      <div className="HomeView__card">
        {card.external ? (
          <a href={card.path} className="HomeView__link">
            {card.name}
          </a>
        ) : (
          <Link to={card.path} className="HomeView__link">
            {card.name}
          </Link>
        )}

        <i
          className={classNames(
            'HomeView__fav mdi',
            isFavorite ? 'HomeView__fav--select mdi-star' : 'mdi-star-outline'
          )}
          onClick={() => onFavorite(card, !isFavorite)}
        />
      </div>
    </div>
  );
}

export default HomeCard;
