import { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Text, Radio } from '@alkem/react-ui-inputs';
import InputWithLabel from 'components/input-with-label';
import * as actions from '../actions';
import {
  selectConfigSources,
  selectAvailableBatchConfigSources,
  selectAvailableBufferedConfigSources,
} from '../selectors';
import { BufferedPumpConfig, BatchPumpConfig, PumpType } from '../types';
import './index.scss';
import { Modal } from '@alkem/react-ui-modal';
import Checkbox from '@alkem/react-ui-checkbox';
import { SimpleSelect } from '@alkem/react-ui-select';

export function PumpConfigModal({
  type,
  config,
  closeModal,
}: {
  type: PumpType | null;
  config: BufferedPumpConfig | BatchPumpConfig | null;
  closeModal: () => any;
}) {
  const create = type === null;
  const dispatch = useDispatch();
  const allSources = useSelector(selectConfigSources);

  const [selectedType, setType] = useState<string | null>(type);
  const [source, setSource] = useState<number | null>(
    config ? config.source : null
  );
  // Reset the source when the type changes.
  useEffect(() => {
    if (create) {
      setSource(null);
    }
  }, [selectedType, create]);

  const availableBufferedSources = useSelector(
    selectAvailableBufferedConfigSources
  );
  const availableBatchSources = useSelector(selectAvailableBatchConfigSources);
  const availableSources = useMemo(
    () =>
      selectedType === 'buffered'
        ? availableBufferedSources
        : availableBatchSources,
    [availableBufferedSources, availableBatchSources, selectedType]
  );
  const sourceOptions = useMemo(
    () =>
      availableSources.map((e) => ({
        key: e.id,
        label: e.label,
      })),
    [availableSources]
  );
  const selectedSource = useMemo(() => {
    const found = allSources.find((s) => s.id === source);
    if (found) {
      return { key: found.id, label: found.label };
    }
    return null;
  }, [allSources, source]);
  const [pumpEnabled, setPumpEnabled] = useState<boolean>(
    config ? config.enabled : false
  );
  const [bufferEnabled, setBufferEnabled] = useState<boolean>(
    config && 'buffer_enabled' in config ? config.buffer_enabled : false
  );
  const [acceptableDelay, setAcceptableDelay] = useState<number>(
    config && 'acceptable_delay' in config ? config.acceptable_delay : 10
  );
  const [productsPerMessage, setProductsPerMessage] = useState<number>(
    config ? config.products_per_message : 20
  );
  const [messagesPerPump, setMessagesPerPump] = useState<number>(
    config && 'messages_per_pump' in config ? config.messages_per_pump : 50
  );
  const [feederEnabled, setFeederEnabled] = useState<boolean>(
    config && 'feeder_enabled' in config ? config.feeder_enabled : false
  );
  const [quota, setQuota] = useState<number>(
    config && 'quota' in config ? config.quota : 20
  );
  return (
    <Modal
      title={`Buffered Pump config`}
      onClose={closeModal}
      confirmButtonText={create ? 'Create' : 'Update'}
      confirmDisabled={selectedType === null || source === null}
      onConfirm={() => {
        if (source === null) {
          return;
        }
        if (selectedType === 'buffered') {
          dispatch(
            (create
              ? actions.createBufferedConfig
              : actions.updateBufferedConfig)({
              source: source,
              enabled: pumpEnabled,
              buffer_enabled: bufferEnabled,
              acceptable_delay: acceptableDelay,
              products_per_message: productsPerMessage,
              messages_per_pump: messagesPerPump,
            })
          );
        } else if (selectedType === 'batched') {
          dispatch(
            (create ? actions.createBatchConfig : actions.updateBatchConfig)({
              source: source,
              enabled: pumpEnabled,
              feeder_enabled: feederEnabled,
              quota: quota,
              products_per_message: productsPerMessage,
            })
          );
        }
        closeModal();
      }}
    >
      <>
        <InputWithLabel inputId="pump-config-type" label="Pump type">
          <Radio
            id="pump-config-type"
            value={selectedType}
            options={[
              { label: 'Buffered', value: 'buffered' },
              { label: 'Batched', value: 'batched' },
            ]}
            onChange={(event) => setType(event.target.value)}
            disabled={!create}
          />
        </InputWithLabel>
        <InputWithLabel inputId="pump-config-source" label="Source">
          <SimpleSelect
            id="pump-config-source"
            options={sourceOptions}
            value={selectedSource}
            onSelect={(event) => setSource(event.key)}
            autoSize
            disabled={!create}
          />
        </InputWithLabel>
        <InputWithLabel inputId="pump-config-enabled" label="Pump enabled">
          <Checkbox
            id="pump-config-enabled"
            checked={pumpEnabled}
            onChange={() => setPumpEnabled(!pumpEnabled)}
          />
        </InputWithLabel>
        {selectedType === 'buffered' && (
          <InputWithLabel
            inputId="pump-config-buffer-enabled"
            label="Buffering enabled"
          >
            <Checkbox
              id="pump-config-buffer-enabled"
              checked={bufferEnabled}
              onChange={() => setBufferEnabled(!bufferEnabled)}
            />
          </InputWithLabel>
        )}
        {selectedType === 'batched' && (
          <InputWithLabel
            inputId="pump-config-feeder-enabled"
            label="Feeder enabled"
          >
            <Checkbox
              id="pump-config-feeder-enabled"
              checked={feederEnabled}
              onChange={() => setFeederEnabled(!feederEnabled)}
            />
          </InputWithLabel>
        )}
        <InputWithLabel
          inputId="pump-config-products-per-message"
          label="Products / message"
        >
          <Text
            id="pump-config-products-per-message"
            value={productsPerMessage}
            onChange={(event) =>
              setProductsPerMessage(parseInt(event.target.value, 10))
            }
            type="number"
          />
        </InputWithLabel>
        {selectedType === 'buffered' && (
          <InputWithLabel
            inputId="pump-config-messages-per-pump"
            label="Messages / pump"
          >
            <Text
              id="pump-config-messages-per-pump"
              value={messagesPerPump}
              onChange={(event) =>
                setMessagesPerPump(parseInt(event.target.value, 10))
              }
              type="number"
            />
          </InputWithLabel>
        )}
        {selectedType === 'batched' && (
          <InputWithLabel inputId="pump-config-quota" label="Quota">
            <Text
              id="pump-config-quota"
              value={quota}
              onChange={(event) => setQuota(parseInt(event.target.value, 10))}
              type="number"
            />
          </InputWithLabel>
        )}
        {selectedType === 'buffered' && (
          <InputWithLabel
            inputId="pump-config-acceptable-delay"
            label="Acceptable delay (s)"
          >
            <Text
              id="pump-config-acceptable-delay"
              value={acceptableDelay}
              onChange={(event) =>
                setAcceptableDelay(parseInt(event.target.value, 10))
              }
              type="number"
            />
          </InputWithLabel>
        )}
      </>
    </Modal>
  );
}
