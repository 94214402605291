import Image from 'components/ui/image';
import missingImg from 'images/missing.png';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { formatMediaUrl } from 'utils/media';
import { separateActions } from 'utils/redux';
import { openModal } from '../../actions';
import './item.scss';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  openModal,
};

export class ReferentialPictureItem extends PureComponent {
  static propTypes = {
    referentialEntity: ImmutablePropTypes.map.isRequired,
    actions: PropTypes.shape({
      openModal: PropTypes.func.isRequired,
    }).isRequired,
  };

  onUpload = () => {
    const { actions, referentialEntity } = this.props;
    actions.openModal(referentialEntity);
  };

  render() {
    const { referentialEntity } = this.props;
    return (
      <div className="ReferentialPictureItem">
        <div className="ReferentialPictureItem__picture">
          <Image
            src={formatMediaUrl(
              `/api/1/concept/${referentialEntity.get(
                'id'
              )}/picture/logo/original.png?d=${Date.now()}`
            )}
            fallbackSrc={missingImg}
          />
        </div>
        <div className="ReferentialPictureItem__upload">
          <i className="mdi mdi-upload" onClick={this.onUpload} />
        </div>
        <hr />
        <div className="ReferentialPictureItem__name">
          {referentialEntity.get('label')}
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(ReferentialPictureItem);
