import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import d3 from 'utils/d3';
import { getIn } from 'utils/immutable';
import './d3.scss';

/* eslint-disable */
// https://gist.github.com/mjackson/5311256#file-color-conversion-algorithms-js-L119
function hsvToRgb(h, s, v) {
  let r, g, b;

  let i = Math.floor(h * 6);
  let f = h * 6 - i;
  let p = v * (1 - s);
  let q = v * (1 - f * s);
  let t = v * (1 - (1 - f) * s);

  switch (i % 6) {
    case 0:
      (r = v), (g = t), (b = p);
      break;
    case 1:
      (r = q), (g = v), (b = p);
      break;
    case 2:
      (r = p), (g = v), (b = t);
      break;
    case 3:
      (r = p), (g = q), (b = v);
      break;
    case 4:
      (r = t), (g = p), (b = v);
      break;
    case 5:
      (r = v), (g = p), (b = q);
      break;
    default:
      break;
  }

  return [Math.floor(r * 255), Math.floor(g * 255), Math.floor(b * 255)];
}
/* eslint-enable */

function componentToHex(c) {
  const hex = c.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
}

function rgbToHex(r, g, b) {
  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
}

function hsvToHex(h, s, v) {
  const [r, g, b] = hsvToRgb(h, s, v);
  return rgbToHex(r, g, b);
}

const isNodeActive = (nodeId, activeNodes) =>
  activeNodes.indexOf(nodeId) >= 0 ||
  activeNodes.indexOf(`group_${nodeId}`) >= 0;

class D3Graph extends PureComponent {
  componentDidMount() {
    const { svg } = this.props;
    if (svg) {
      this.renderD3();
      this.highlightSelectedOption();
    }
  }

  componentDidUpdate(prevProps) {
    const { activeNodes, id, selectedNode, result, svg } = this.props;
    if (
      activeNodes !== prevProps.activeNodes ||
      selectedNode !== prevProps.selectedNode
    ) {
      const selector = `#${id}`;

      d3.select(selector).selectAll('.node, .edge').attr('opacity', 1);

      d3.select(selector)
        .selectAll('.node')
        .selectAll('text')
        .attr('font-weight', 'normal');

      d3.select(selector)
        .selectAll('.node')
        .filter(
          (d, i, nodes) => selectedNode.id && selectedNode.id === nodes[i].id
        )
        .selectAll('text')
        .attr('font-weight', 'bold');

      d3.select(selector)
        .selectAll('.node')
        .filter(
          (d, i, nodes) => selectedNode.id && selectedNode.id === nodes[i].id
        )
        .selectAll('polygon')
        .attr('stroke-width', 2);

      d3.select(selector)
        .selectAll('.node')
        .filter(
          (d, i, nodes) =>
            activeNodes.length && !isNodeActive(nodes[i].id, activeNodes)
        )
        .attr('opacity', 0.2);

      d3.select(selector)
        .selectAll('.edge')
        .filter((d, i, nodes) => {
          const split = nodes[i].id.split('->');
          return (
            activeNodes.length &&
            (!isNodeActive(split[0], activeNodes) ||
              !isNodeActive(split[1], activeNodes))
          );
        })
        .attr('opacity', 0.2);
    }

    if (result !== prevProps.result) {
      this.highlightSelectedOption();
    }

    if (svg !== prevProps.svg) {
      this.renderD3();
    }

    this.highlightSelectedOption();
  }

  showTimings = () => {
    const { id, result, erroredNodes } = this.props;
    const selector = `#${id}`;

    const traces = getIn(result, ['inspectors', 'trace']) || {};

    let maxDelta = 0;
    Object.values(traces).forEach((v) => {
      if (v.delta > maxDelta) {
        maxDelta = v.delta;
      }
    });

    d3.select(selector)
      .selectAll('.node')
      .each(function (d, i, nodes) {
        const delta = getIn(result, [
          'inspectors',
          'trace',
          nodes[i].id,
          'delta',
        ]);
        let fill;
        if (typeof delta !== 'undefined') {
          // divide by because we want from red (0°) to green (120°): 0 -> 1/3
          // Friendly reminder: 120/360 = 1/3
          const hue = (maxDelta - delta) / (3 * maxDelta);
          fill = hsvToHex(hue, 1, 1);
        }
        d3.select(this).select('polygon').style('fill', fill);
      });

    d3.select(selector)
      .selectAll('.node')
      .each(function (d, i, nodes) {
        const isErrored = erroredNodes.includes(nodes[i].id);
        d3.select(this)
          .selectAll('polygon')
          .style('stroke', isErrored ? 'red' : 'black')
          .style('stroke-width', isErrored ? 2 : 1);
      });
  };

  highlightImportant = () => {
    const { id, nodeDependencies, importantNodes } = this.props;
    const selector = `#${id}`;

    d3.select(selector)
      .selectAll('.node')
      .each(function (d, i, nodes) {
        if (
          (nodeDependencies[nodes[i].id] || []).some((n) =>
            n.toLowerCase().includes('repository')
          )
        ) {
          d3.select(this).select('polygon').style('fill', '#fabf2d');
        }
      })
      .each(function (d, i, nodes) {
        if ((nodeDependencies[nodes[i].id] || []).includes('serviceClient')) {
          d3.select(this).select('polygon').style('fill', '#ef7e1d');
        }
      })
      .each(function (d, i, nodes) {
        if (importantNodes.includes(nodes[i].id)) {
          d3.select(this).select('polygon').style('fill', '#4cb963');
        }
      });
  };

  highlightSelectedOption() {
    const { id, highlightedOption } = this.props;
    const selector = `#${id}`;

    d3.select(selector)
      .selectAll('.node')
      .each(function (d, i, nodes) {
        const isGroup = nodes[i].id.startsWith('group_');
        d3.select(this)
          .select('polygon')
          .style('fill', isGroup ? 'transparent' : '#4DCBD8');
      })
      .selectAll('polygon')
      .style('stroke', 'black')
      .style('stroke-width', 1);

    switch (highlightedOption) {
      case 'timings':
        this.showTimings();
        break;
      case 'important':
        this.highlightImportant();
        break;
      default:
        break;
    }
  }

  // This function was greatly inspired by
  // https://github.com/28mm/blast-radius/blob/master/blastradius/server/static/js/blast-radius.js
  renderD3 = () => {
    const { id, svg, nodeClicked } = this.props;
    const selector = `#${id}`;

    d3.select(selector).selectAll('*').remove();

    let g;
    const zoom = d3
      .zoom()
      .on('zoom', (event) => g.attr('transform', event.transform));
    g = d3.select(selector).html(svg).select('svg').select('g');

    d3.select(`${selector} svg`)
      .call(zoom.transform, () => {
        // Initialize the zoom correctly
        const r = /translate\(([0-9.]+),? ?([0-9.]+)\)/;
        const match = g.attr('transform').match(r);
        if (!match) {
          return null;
        }

        const x = match[1];
        const y = match[2];
        return d3.zoomIdentity.translate(x, y);
      })
      .call(zoom);

    // remove <title>s in svg; graphviz leaves these here and they
    // trigger useless tooltips.
    d3.select(selector).selectAll('title').remove();

    // make sure the svg uses 100% of the viewport, so that pan/zoom works
    // as expected and there's no clipping.
    d3.select(selector)
      .select('svg')
      .on('click', () => {
        nodeClicked(null);
      });

    d3.select(selector)
      .selectAll('.node')
      .attr('cursor', 'pointer')
      .on('click', function (event) {
        event.stopPropagation();
        // ctrlKey || metaKey
        const nodeId = this.attributes.id.value;
        nodeClicked(nodeId);
      });
  };

  render() {
    const { id } = this.props;

    return (
      <div className="D3Graph">
        <div id={id} />
      </div>
    );
  }
}

D3Graph.propTypes = {
  id: PropTypes.string.isRequired,
  activeNodes: PropTypes.array.isRequired,
  selectedNode: PropTypes.object.isRequired,
  svg: PropTypes.string.isRequired,
  nodeClicked: PropTypes.func.isRequired,
  result: PropTypes.object,
  nodeDependencies: PropTypes.object.isRequired,
  highlightedOption: PropTypes.string,
  importantNodes: PropTypes.array, // eslint-disable-line react/no-unused-prop-types
  erroredNodes: PropTypes.array, // eslint-disable-line react/no-unused-prop-types
};

D3Graph.defaultProps = {
  result: {},
  highlightedOption: null,
  importantNodes: [],
  erroredNodes: [],
};

export default D3Graph;
