import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ListController from 'components/ui/list/controller';

import { Button } from '@alkem/react-ui-button';
import { Spinner } from '@alkem/react-ui-spinner';

import { separateActions } from 'utils/redux';

import {
  selectState,
  selectList,
  selectPagination,
  selectListDetail,
} from '../selectors';

import GDSNPSRListRow from './row';

import {
  nextPage,
  previousPage,
  changeLimit,
  getGDSNPSRList,
} from '../actions';

import './list.scss';

const mapStateToProps = (state) => ({
  list: selectList(state),
  listDetail: selectListDetail(state),
  isLoading: selectState(state).isLoading,
  pagination: selectPagination(state),
});

const mapDispatchToProps = {
  getGDSNPSRList,
  nextPage,
  previousPage,
  changeLimit,
};

export class GDSNPSRList extends PureComponent {
  static propTypes = {
    list: PropTypes.array.isRequired,
    listDetail: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    pagination: PropTypes.object.isRequired,
    actions: PropTypes.shape({
      getGDSNPSRList: PropTypes.func.isRequired,
      nextPage: PropTypes.func.isRequired,
      previousPage: PropTypes.func.isRequired,
      changeLimit: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    isLoading: true,
  };

  componentDidMount() {
    this.props.actions.getGDSNPSRList();
  }

  refresh = () => {
    this.props.actions.getGDSNPSRList();
  };

  renderList() {
    const { list, listDetail, isLoading } = this.props;
    if (isLoading) {
      return (
        <div className="GDSNPSRList__spinner">
          <Spinner loading big />
        </div>
      );
    }
    let i = 0;
    return list.map((psr) => {
      i += 1;
      return (
        <GDSNPSRListRow
          key={`${psr.id}-${i}`}
          psr={psr}
          detail={listDetail.get(`${psr.id}-${psr.gtin}`)}
        />
      );
    });
  }

  render() {
    const { pagination, actions } = this.props;
    const { currentPage, totalPages, limit, totalResults } = pagination;
    return (
      <div>
        <ListController
          actions={
            <div>
              <Button onClick={this.refresh}>
                <i className="mdi mdi-refresh" />
              </Button>
            </div>
          }
          onNext={actions.nextPage}
          onPrev={actions.previousPage}
          currentPage={currentPage}
          totalPages={totalPages}
          limit={limit}
          totalResults={totalResults}
          onLimitChange={actions.changeLimit}
          rowsLength={0}
          type="psrlist"
        >
          {this.renderList()}
        </ListController>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(GDSNPSRList);
