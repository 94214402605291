import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '@alkem/react-ui-button';

export default function PaginationButton({ type, disabled, onClick }) {
  const chevron = type === 'next' ? 'right' : 'left';
  const btnProps = disabled ? { disabled: true } : { onClick };
  return (
    <Button secondary className="Pagination__btn" {...btnProps}>
      <span className={`mdi mdi-chevron-${chevron} Pagination__chevron`} />
    </Button>
  );
}

PaginationButton.propTypes = {
  type: PropTypes.oneOf(['next', 'previous']).isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};
