import { MODULE_REDUCER_KEY } from 'constants/reducer';
import {
  REPORTING_CONFIG_STORE_KEY,
  DASHBOARD_REDUCER_KEY,
} from '../constants';

export function selectState(state) {
  return state[MODULE_REDUCER_KEY][REPORTING_CONFIG_STORE_KEY][
    DASHBOARD_REDUCER_KEY
  ];
}

export function selectDashboardList(state) {
  return selectState(state).get('dashboardList');
}

export function selectAvsFilter(state) {
  return selectState(state).get('avsFilter');
}
