import { compose } from 'lodash/fp';
import moment from 'moment';
import { Subscription } from './types';
import { getEndDate, getGroupLimit, getOfferName } from './utils';

const formatGroupLimit = (subscription: Subscription) => {
  const limit = getGroupLimit(subscription);
  if (limit === -1) {
    return '';
  } else {
    return ` / ${limit}`;
  }
};

const formatEndDate = compose(
  (timestamp: number) => moment.unix(timestamp).format('DD MMM YYYY'),
  getEndDate
);

interface Props {
  subscription: Subscription | null;
  groupsActivated: number;
}

export default ({ subscription, groupsActivated }: Props) =>
  (subscription && (
    <b>
      Offer: {getOfferName(subscription)} • {groupsActivated}
      {formatGroupLimit(subscription)} • until {formatEndDate(subscription)}
    </b>
  )) || <i>no subscription</i>;
