import * as events from '../constants/events';

export const updatePCR = (key, value) => ({
  type: events.PCR_UPDATE,
  payload: {
    key,
    value,
  },
});

export const sendGdsnPCR = (pcr) => ({
  type: events.SEND_GDSN_PCR,
  pcr,
});
