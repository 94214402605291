import { createReducer } from 'redux-create-reducer';
import { fromJS, Map } from 'immutable';

import * as referentialConstants from 'modules/referentials/constants';
import * as events from '../constants/events';

const initialState = fromJS({
  filters: {
    search: null,
    referentialSlug: null,
  },
  modal: {
    opened: false,
    referentialEntity: null,
    uploading: false,
  },
  isLoading: false,
  list: [],
  refreshingCache: false,
});

export default createReducer(initialState, {
  [events.SEARCH_UPDATE]: (state, { search }) =>
    state.setIn(['filters', 'search'], search),

  [events.TYPE_UPDATE]: (state, { referentialSlug }) =>
    state.setIn(['filters', 'referentialSlug'], referentialSlug),

  [events.LOADING]: (state, { loading }) => state.set('isLoading', loading),

  [events.RECEIVED]: (state, { list }) => state.set('list', list),

  [events.REFERENTIAL_PICTURES_SET_MODAL_UPLOADING]: (state, { uploading }) =>
    state.setIn(['modal', 'uploading'], uploading),

  [events.OPEN_MODAL]: (state, { referentialEntity }) =>
    state.set(
      'modal',
      Map({
        opened: true,
        referentialEntity,
        uploading: false,
      })
    ),

  [events.CLOSE_MODAL]: (state) =>
    state.set(
      'modal',
      Map({
        opened: false,
        referentialEntity: null,
        uploading: false,
      })
    ),

  [referentialConstants.REFRESHING_REFERENTIAL_CACHE]: (
    state,
    { refreshing }
  ) => state.set('refreshingCache', refreshing),
});
