import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ListController from 'components/ui/list/controller';

import { Spinner } from '@alkem/react-ui-spinner';
import { Button } from '@alkem/react-ui-button';

import { separateActions } from 'utils/redux';

import {
  selectState,
  selectList,
  selectPagination,
  selectModal,
} from '../selectors';

import GDSNCISModal from '../modal';

import GDSNCISListRow from './row';

import {
  nextPage,
  previousPage,
  changeLimit,
  getGDSNCISList,
  openCISModal,
} from '../actions';

import './list.scss';

const mapStateToProps = (state) => ({
  list: selectList(state),
  isOpen: selectModal(state).isOpen,
  isLoading: selectState(state).isLoading,
  pagination: selectPagination(state),
});

const mapDispatchToProps = {
  getGDSNCISList,
  nextPage,
  previousPage,
  changeLimit,
  openCISModal,
};

export class GDSNCISList extends PureComponent {
  static propTypes = {
    list: PropTypes.array.isRequired,
    isLoading: PropTypes.bool,
    pagination: PropTypes.object.isRequired,
    isOpen: PropTypes.bool,
    actions: PropTypes.shape({
      getGDSNCISList: PropTypes.func.isRequired,
      nextPage: PropTypes.func.isRequired,
      previousPage: PropTypes.func.isRequired,
      changeLimit: PropTypes.func.isRequired,
      openCISModal: PropTypes.func.isRequired,
      closeModal: PropTypes.func,
    }).isRequired,
  };

  static defaultProps = {
    isLoading: true,
  };

  componentDidMount() {
    this.props.actions.getGDSNCISList();
  }

  refresh = () => {
    this.props.actions.getGDSNCISList();
  };

  renderHeaders() {
    return (
      <div className="GDSNCIListRowWrapper GDSNCIListHeader row">
        <div className="col-xs-3">
          <div>
            <i className="mdi mdi-domain" /> Recipient party
          </div>
          <div>
            <i className="mdi mdi-apps" /> Recipient GLN
          </div>
          <div>
            <i className="mdi mdi-steam" /> Datapool
          </div>
        </div>
        <div className="col-xs-2 text-center">
          <i className="mdi mdi-sync" /> Last update
        </div>
        <div className="col-xs-5">
          <div>
            <i className="mdi mdi-domain" /> Source party
          </div>
          <div>Source GLN | Target Market | GPC | GTIN</div>
          <div>
            <i className="mdi mdi-steam" /> Datapool
          </div>
        </div>
        <div className="col-xs-1"></div>
      </div>
    );
  }

  renderList() {
    const { list, isLoading } = this.props;
    if (isLoading) {
      return (
        <div className="GDSNCISList__spinner">
          <Spinner loading big />
        </div>
      );
    }
    return list.map((cis) => <GDSNCISListRow key={cis.id} cis={cis} />);
  }

  actionBar() {
    return (
      <div>
        <Button onClick={this.refresh}>
          <i className="mdi mdi-refresh" />
        </Button>
        <Button onClick={this.openModal}>
          <i className="mdi mdi-plus" />
        </Button>
      </div>
    );
  }

  openModal = () => {
    this.props.actions.openCISModal();
  };

  render() {
    const { pagination, actions, isOpen } = this.props;
    const { currentPage, totalPages, limit, totalResults } = pagination;
    return (
      <div>
        <ListController
          actions={this.actionBar()}
          onNext={actions.nextPage}
          onPrev={actions.previousPage}
          currentPage={currentPage}
          totalPages={totalPages}
          limit={limit}
          totalResults={totalResults}
          onLimitChange={actions.changeLimit}
          rowsLength={0}
          type="endpointlist"
        >
          <div>{this.renderHeaders()}</div>
          <div>{this.renderList()}</div>
        </ListController>
        {isOpen && <GDSNCISModal onCloseModal={actions.closeModal} />}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(GDSNCISList);
