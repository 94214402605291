import { Trace } from './ProductTimeline';

const columns = [
  { type: 'string', label: 'Task ID' },
  { type: 'string', label: 'Task Name' },
  { type: 'string', label: 'Resource' },
  { type: 'date', label: 'Start Date' },
  { type: 'date', label: 'End Date' },
  { type: 'number', label: 'Duration' },
  { type: 'number', label: 'Percent Complete' },
  { type: 'string', label: 'Dependencies' },
];

type Node = {
  taskId: string;
  taskName: string | undefined;
  resource: string;
  start: Date;
  end: Date;
  duration: number;
  percentComplete: number;
  dependencies: string;
};

export const getTimeSpan = (trace: Trace) => {
  const traceKeys = Object.keys(trace);

  const rows: { [key: string]: Node } = Object.fromEntries(
    traceKeys.map((fullKey) => {
      const { start, end, delta } = trace[fullKey];
      const splittedNodeKeys = fullKey.split('.');
      const nodeKey = splittedNodeKeys.pop();
      const graphKey = splittedNodeKeys.join('.');
      return [
        fullKey,
        {
          taskId: fullKey,
          taskName: nodeKey,
          resource: graphKey,
          start: new Date(start),
          end: new Date(end),
          duration: delta,
          percentComplete: 100,
          dependencies: '',
        },
      ];
    })
  );

  const minDate = Math.min(
    ...Object.values(rows).map((element) => Number(element.start))
  );
  const maxDate = Math.max(
    ...Object.values(rows).map((element) => Number(element.end))
  );
  Object.values(rows).forEach((element) => {
    element.start = new Date(Number(element.start) - minDate);
    element.end = new Date(Number(element.end) - minDate);
  });

  const graphs = Object.values(rows).reduce((acc, element) => {
    (acc[element.resource] = acc[element.resource] || []).push(element);
    element[7] = '';
    return acc;
  }, {});

  const allTask: Node = {
    taskId: '',
    taskName: 'All',
    resource: 'root',
    start: new Date(0),
    end: new Date(maxDate - minDate),
    duration: maxDate - minDate,
    percentComplete: 100,
    dependencies: '',
  } as const;

  return { graphs, rows, allTask };
};

const formatData = (node) => {
  return [
    node.taskId,
    node.taskName,
    node.resource,
    node.start,
    node.end,
    node.duration,
    node.percentComplete,
    node.dependencies,
  ];
};

export const formatGraph = (allTask, graph, newGraph) => {
  const res = [columns, formatData(allTask)];
  if (newGraph) {
    res.push(...newGraph.map((r) => formatData(r)));
  }
  if (graph) {
    res.push(...graph.map((r) => formatData(r)));
  }
  return res;
};
