import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { SimpleSelect } from '@alkem/react-ui-select';

import './sort.scss';

const defaultExtraOptions = {
  i13Action: 'sortBy',
  i13Category: 'filter',
};

export default class SortSelect extends PureComponent {
  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.shape({
          sortBy: PropTypes.string,
          sortOrder: PropTypes.string,
        }),
      })
    ).isRequired,
    sortBy: PropTypes.string.isRequired,
    sortOrder: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    extraOptions: PropTypes.object,
  };

  static defaultProps = {
    extraOptions: {},
  };

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange({ value } = {}) {
    this.props.onChange(value);
  }

  findSelectedValue() {
    const { options, sortBy, sortOrder } = this.props;
    return options.find(
      (opt) => opt.value.sortBy === sortBy && opt.value.sortOrder === sortOrder
    );
  }

  render() {
    const { options, extraOptions } = this.props;
    return (
      <div className="SortSelect">
        <span className="SortSelect__label">Sort by</span>
        <SimpleSelect
          id="listSorting"
          options={options}
          value={this.findSelectedValue()}
          onSelect={this.onChange}
          extraOptions={{ ...defaultExtraOptions, ...extraOptions }}
          autoSize
        />
      </div>
    );
  }
}
