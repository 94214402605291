import { get } from 'lodash/fp';

export const getGoogleConfig = (...path: string[]) =>
  get(['appconfig', 'google', ...path], global) ||
  get(['alk_conf', ...path], global);

export const getGoogleApiClientId = () =>
  getGoogleConfig('apiClientId') || getGoogleConfig('google_api_client_id');

export const getGoogleLoginClientId = () =>
  getGoogleConfig('loginClientId') || getGoogleConfig('google_login_client_id');
