import './ErrorFallback.scss';

export function ErrorFallback({ error }: { error: Error }) {
  return (
    <div className="ErrorFallback">
      <div className="ErrorFallback__content">
        <h1 className="ErrorFallback__title">
          <i className="mdi mdi-bug" /> Something went wrong
        </h1>
        <pre className="ErrorFallback__stack">{error.stack}</pre>
      </div>
    </div>
  );
}
