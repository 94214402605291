import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import './item.scss';

export default class FieldTreeItem extends PureComponent {
  static propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    children: PropTypes.node,
    select: PropTypes.func.isRequired,
  };

  state = {
    expanded: false,
  };

  onSelect = () => {
    const { id, select } = this.props;
    select(id);
  };

  onExpand = () => {
    this.setState((prevState) => ({ expanded: !prevState.expanded }));
  };

  render() {
    const { id, name, label, children } = this.props;
    const { expanded } = this.state;
    const chevronClass = expanded
      ? 'mdi mdi-chevron-down'
      : 'mdi mdi-chevron-right';
    return (
      <div className="FieldTreeItem" id={`field-tree-item-${id}`}>
        <div className="FieldTreeItem__line">
          {children && <i className={chevronClass} onClick={this.onExpand} />}
          {!children && (
            <i className="mdi mdi-chevron-right FieldTreeItem__chevron--hidden" />
          )}
          <div className="FieldTreeItem__label" onClick={this.onSelect}>
            {label}
            &nbsp;
            <code>{name}</code>
          </div>
        </div>
        {expanded && children}
      </div>
    );
  }
}
