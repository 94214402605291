// Organization types

export const OrganizationTypeManufacturer = { id: 1, name: 'Manufacturer' };
export const OrganizationTypeRetailer = { id: 2, name: 'Retailer' };
export const OrganizationTypePrivateLabel = { id: 3, name: 'Private Label' };
export const OrganizationTypeThirdParty = { id: 4, name: 'Third Party' };
export const OrganizationTypeWhiteLabel = { id: 5, name: 'White Label' };

export const OrganizationTypes = {
  [OrganizationTypeManufacturer.id]: OrganizationTypeManufacturer,
  [OrganizationTypeRetailer.id]: OrganizationTypeRetailer,
  [OrganizationTypePrivateLabel.id]: OrganizationTypePrivateLabel,
  [OrganizationTypeThirdParty.id]: OrganizationTypeThirdParty,
  [OrganizationTypeWhiteLabel.id]: OrganizationTypeWhiteLabel,
};
