import { createReducer } from 'redux-create-reducer';
import * as events from '../constants/events';

const initialState = {
  attributeList: null,
  functionList: null,
  categoryList: null,
  selectedAttributeVersions: null,
  selectedFunctionDetails: null,
  selectedCategoryAttributeList: null,
  saveInProgress: false,
};

export default createReducer(initialState, {
  [events.ATTRIBUTE_LIST_RECEIVE]: (state, { attributeList }) => ({
    ...state,
    attributeList,
  }),
  [events.ATTRIBUTE_GET]: (state, { selectedAttributeVersions }) => ({
    ...state,
    selectedAttributeVersions,
  }),
  [events.FUNCTION_LIST_RECEIVE]: (state, { functionList }) => ({
    ...state,
    functionList,
  }),
  [events.FUNCTION_GET]: (state, { selectedFunctionDetails }) => ({
    ...state,
    selectedFunctionDetails,
  }),
  [events.CATEGORY_LIST_RECEIVE]: (state, { categoryList }) => ({
    ...state,
    categoryList,
  }),
  [events.CATEGORY_GET]: (state, { selectedCategoryAttributeList }) => ({
    ...state,
    selectedCategoryAttributeList,
  }),
  [events.SET_SAVE_IN_PROGRESS]: (state, { saveInProgress }) => ({
    ...state,
    saveInProgress,
  }),
});
