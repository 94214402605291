import { createReducer } from 'redux-create-reducer';
import { fromJS } from 'immutable';

import {
  ORGANIZATION_DEACTIVATE_MODAL_OPEN,
  ORGANIZATION_DEACTIVATE_MODAL_CLOSE,
  ORGANIZATION_DEACTIVATE_MODAL_LOADING,
} from '../constants/events';

const initialState = fromJS({
  organization: null,
  isOpen: false,
  isLoading: false,
});

export default createReducer(initialState, {
  [ORGANIZATION_DEACTIVATE_MODAL_OPEN]: (state, { payload }) =>
    state.set('isOpen', true).set('organization', payload.payload),
  [ORGANIZATION_DEACTIVATE_MODAL_CLOSE]: (state) =>
    state
      .set('isOpen', false)
      .set('isLoading', false)
      .set('organization', null),
  [ORGANIZATION_DEACTIVATE_MODAL_LOADING]: (state, { payload }) =>
    state.set('isLoading', payload),
});
