import {
  BillingProductType,
  BillingSubscription,
  OrganizationGroupRelationStatus,
} from '@alkem/sdk-dashboard';
import { compose, get, size } from 'lodash/fp';
import { Group } from './types';

export function findSubscription(subscriptions: BillingSubscription[]) {
  return subscriptions.find(
    (sub) =>
      get(['plan', 'product', 'type'], sub) === BillingProductType.CHANNEL_OFFER
  );
}

export const isActiveGroupStatus = (status: number | undefined) =>
  status === (OrganizationGroupRelationStatus.ACCEPTED as number) ||
  status === (OrganizationGroupRelationStatus.DEACTIVATION_REQUESTED as number);

export const isActiveGroup = ({ status }: Group) => isActiveGroupStatus(status);

export const isGroupDeactivationRequested = ({ status }: Group) =>
  status === (OrganizationGroupRelationStatus.DEACTIVATION_REQUESTED as number);

export const getOfferName = get('plan.name');

export const getGroupLimit = get('plan.product.channel_limit');

export const getActiveGroupSize = compose(size, (groups, changes) =>
  groups.filter((group) =>
    group.id in changes ? changes[group.id] : isActiveGroup(group)
  )
);

export const getEndDate = compose(get('end_date'));
