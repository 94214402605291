import { DoubleSelect } from '@alkem/react-ui-doubleselect';
import { Checkbox } from '@alkem/react-ui-inputs';
import { Table } from '@alkem/react-ui-table';
import { getReferentials } from 'actions/referentials';
import { difference, pick, union } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss';

const mapStateToProps = (state) => ({ referentials: state.referentials.lists });

class FieldList extends Component {
  static propTypes = {
    fieldMetadata: PropTypes.array.isRequired,
    onFieldChange: PropTypes.func.isRequired,
    onReferentialValueChange: PropTypes.func.isRequired,
    applicableFields: PropTypes.array,
    excludedReferentialValues: PropTypes.object,
    specificFields: PropTypes.array,
    dispatch: PropTypes.func,
    referentials: PropTypes.object,
    selectedKind: PropTypes.object,
    selectedKindParents: PropTypes.array,
    readOnly: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    applicableFields: [],
    specificFields: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      headers: [
        { name: 'name', value: '' },
        { name: 'check', value: '' },
      ],
      fieldReferentialMap: {},
    };
  }

  componentDidMount() {
    const { fieldMetadata } = this.props;
    fieldMetadata.forEach((field) => {
      this.getFieldReferential(field);
    });
  }

  componentDidUpdate(prevProps) {
    const { fieldMetadata } = this.props;
    if (fieldMetadata !== prevProps.fieldMetadata) {
      fieldMetadata.forEach((field) => {
        this.getFieldReferential(field);
      });
    }
  }

  onDoubleSelectChange = (referentialName) => (item) => {
    const { onReferentialValueChange } = this.props;
    onReferentialValueChange(referentialName, item);
  };

  onCheckboxChange = (field) => (event) => {
    const { onFieldChange } = this.props;
    onFieldChange(field, event.target.checked);
  };

  getFieldLabel(field) {
    return `${field.displayInfo ? field.displayInfo.label : 'Unknown'} (${
      field.name
    })`;
  }

  getFieldReferentialName(field) {
    if (field.type === 'list' || field.type === 'dict') {
      let returnReferentials = {};
      field.children.forEach((child) => {
        const referential = this.getFieldReferentialName(child);
        if (referential) {
          returnReferentials = { ...returnReferentials, ...referential };
        }
      });
      return returnReferentials;
    }

    if (field.type !== 'entity') {
      return {};
    }
    const label = this.getFieldLabel(field);
    return { [label]: field.referential };
  }

  getFieldReferential(field) {
    const { dispatch } = this.props;
    const referentialNames = this.getFieldReferentialName(field);
    if (Object.keys(referentialNames).length) {
      const { fieldReferentialMap } = this.state;
      fieldReferentialMap[field.id] = referentialNames;

      // Dispatch referential load
      Object.keys(referentialNames).forEach((subFieldName) => {
        const referentialName = referentialNames[subFieldName];
        const alreadyLoaded = referentialName in this.props.referentials;
        if (!alreadyLoaded) {
          dispatch(getReferentials([referentialName]));
        }
      });
      this.setState({ fieldReferentialMap });
    }
  }

  getExcludedEntities(referentialName) {
    const { referentials } = this.props;

    const referential = referentials[referentialName];
    if (!referential) {
      return null;
    }

    return this._mapEntitiesIdsToOptions(
      referentialName,
      this._getOwnExcludedEntities(referentialName)
    );
  }

  getIncludableEntities(referentialName) {
    const { referentials } = this.props;

    const referential = referentials[referentialName];
    if (!referential) {
      return null;
    }

    const excluded = union(
      this._getInheritedExcludedEntities(referentialName),
      this._getOwnExcludedEntities(referentialName)
    );

    const all = referential.map((entity) => entity.id);
    return this._mapEntitiesIdsToOptions(
      referentialName,
      difference(all, excluded)
    );
  }

  _mapEntitiesIdsToOptions(referentialName, entitiesIds) {
    const { referentials } = this.props;
    const referential = referentials[referentialName];
    return referential.filter((entity) => entitiesIds.includes(entity.id));
  }

  _getOwnExcludedEntities(referentialName) {
    const { excludedReferentialValues, selectedKind } = this.props;

    const excludedForSelectedKind =
      excludedReferentialValues[selectedKind.value];

    if (referentialName in excludedForSelectedKind) {
      return excludedForSelectedKind[referentialName].updated;
    }

    return [];
  }

  _getInheritedExcludedEntities(referentialName) {
    const { excludedReferentialValues, selectedKindParents } = this.props;

    let excludedForParentKinds = pick(
      excludedReferentialValues,
      selectedKindParents
    );
    excludedForParentKinds = Object.values(excludedForParentKinds).map(
      (excludedByReferential) =>
        excludedByReferential[referentialName] &&
        excludedByReferential[referentialName].updated
    );

    return union(...excludedForParentKinds);
  }

  renderOneReferentialDoubleList(referentialName) {
    const { referentials, readOnly } = this.props;

    const referential = referentials[referentialName];
    if (!referential) {
      return null;
    }

    const includableEntitiesForThisReferential =
      this.getIncludableEntities(referentialName);
    const excludedEntitiesForThisReferential =
      this.getExcludedEntities(referentialName);

    return (
      <div>
        {referential.length ? (
          <DoubleSelect
            primaryButtonText="Exclude"
            secondaryButtonText="No longer exclude"
            onChange={this.onDoubleSelectChange(referentialName)}
            options={includableEntitiesForThisReferential}
            selectedOptions={excludedEntitiesForThisReferential}
            disabled={readOnly}
          />
        ) : (
          <em>{`Empty referential: ${referentialName}`}</em>
        )}
      </div>
    );
  }

  renderReferentialDoubleList(field) {
    const { referentials } = this.props;
    const { fieldReferentialMap } = this.state;

    const referentialNames = fieldReferentialMap[field.id] || {};
    const nReferentials = Object.keys(referentialNames).length;

    // Return null if field is not linked to a referential or if referentials are not loaded yet.
    if (!Object.keys(referentials).length || !nReferentials) {
      return null;
    }

    return (
      <div>
        {Object.entries(referentialNames).map(
          ([subFieldName, referentialName]) => (
            <div className="fieldList__doublelist" key={subFieldName}>
              {nReferentials !== 1 ? (
                <div className="fieldList__subfieldlabel">
                  Subfield: {subFieldName}
                </div>
              ) : null}
              {this.renderOneReferentialDoubleList(referentialName)}
            </div>
          )
        )}
      </div>
    );
  }

  render() {
    const { fieldMetadata, applicableFields, specificFields, readOnly } =
      this.props;
    const { headers } = this.state;

    const specificTableItems = [];
    const inheritedTableItems = [];

    let nSpecificFieldsChecked = 0;
    let nInheritedFieldsChecked = 0;

    fieldMetadata.forEach((field) => {
      const fieldIsApplicable =
        applicableFields.find((x) => x === field.id) === field.id;
      const fieldIsSpecific =
        specificFields.find((x) => x === field.id) === field.id;

      const tableItem = {
        name: {
          content: (
            <div>
              <div className="fieldList__fieldlabel">
                {this.getFieldLabel(field)}
              </div>
              {fieldIsApplicable
                ? this.renderReferentialDoubleList(field)
                : null}
            </div>
          ),
        },
        check: {
          content: (
            <Checkbox
              label={<div className="FieldList__checkbox" />}
              checked={fieldIsApplicable}
              onChange={this.onCheckboxChange(field)}
              id={`checkbox_${field.id}`}
              className="FieldList__checkbox"
              disabled={readOnly}
            />
          ),
        },
      };
      if (fieldIsSpecific) {
        specificTableItems.push(tableItem);
        nSpecificFieldsChecked += fieldIsApplicable;
      } else {
        inheritedTableItems.push(tableItem);
        nInheritedFieldsChecked += fieldIsApplicable;
      }
    });

    return (
      <div>
        <div className="fieldSubList__title">
          Specific fields: {nSpecificFieldsChecked} /{' '}
          {specificTableItems.length}
        </div>
        <Table
          headers={headers}
          items={specificTableItems}
          rowClassName="FieldList__row"
          cellClassName="FieldList__row"
          alternateColors
        />
        <div className="fieldSubList__title">
          Inherited fields: {nInheritedFieldsChecked} /{' '}
          {inheritedTableItems.length}
        </div>
        <Table
          headers={headers}
          items={inheritedTableItems}
          rowClassName="FieldList__row"
          cellClassName="FieldList__row"
          alternateColors
        />
      </div>
    );
  }
}

export default connect(mapStateToProps)(FieldList);
