import classNames from 'classnames';
import * as routes from 'constants/routes';
import { useEffect, useState, lazy, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { HeaderLayout } from '@alkem/react-layout';
import * as actions from '../actions';
import {
  selectBufferedConfigs,
  selectConfigSources,
  selectBatchConfigs,
} from '../selectors';
import { BufferedPumpConfig, BatchPumpConfig } from '../types';
import './index.scss';
import { Button } from '@alkem/react-ui-button';
import { useConfirm } from 'components/use-confirm';
import { PumpConfigModal } from './config-modal';

const PumpImage = lazy(() => import('./logo'));

function getStatusClassnames(enabled: boolean): string {
  return classNames('mdi', {
    'mdi-checkbox-marked-circle': enabled,
    'IndexerPumpConfig__status--ok': enabled,
    'mdi-close-circle': !enabled,
    'IndexerPumpConfig__status--ko': !enabled,
  });
}

export function BufferedPumpConfigRow({
  config,
}: {
  config: BufferedPumpConfig;
}) {
  const dispatch = useDispatch();
  const [modalOpen, openModal] = useState<boolean>(false);
  const pumpConfigSources = useSelector(selectConfigSources);
  const source = pumpConfigSources.find((e) => e.id === config.source);
  const [confirmDeleteModal, openConfirmDelete] = useConfirm({
    body: (
      <div>
        <p>Do you want to delete the following pump?</p>
        <pre>{source.label}</pre>
      </div>
    ),
    actionLabel: 'Delete',
  });

  return (
    <>
      <div className="IndexerPumpConfig__row">
        <div className="IndexerPumpConfig__column IndexerPumpConfig__source">
          {source.label}
        </div>
        <div className="IndexerPumpConfig__column IndexerPumpConfig__status">
          <i className={getStatusClassnames(config.enabled)} />
        </div>
        <div className="IndexerPumpConfig__column IndexerPumpConfig__status">
          <i className={getStatusClassnames(config.buffer_enabled)} />
        </div>
        <div className="IndexerPumpConfig__column IndexerPumpConfig__data">
          {config.products_per_message}
        </div>
        <div className="IndexerPumpConfig__column IndexerPumpConfig__data">
          {config.messages_per_pump}
        </div>
        <div className="IndexerPumpConfig__column IndexerPumpConfig__data">
          {config.acceptable_delay}s
        </div>
        <div className="IndexerPumpConfig__column IndexerPumpConfig__actions">
          <Button secondary onClick={() => openModal(true)}>
            <i className="mdi mdi-pen" />
          </Button>
          <Button
            warning
            onClick={() =>
              openConfirmDelete(() =>
                dispatch(actions.deleteBufferedConfig(config))
              )
            }
          >
            <i className="mdi mdi-trash-can" />
          </Button>
        </div>
      </div>
      {confirmDeleteModal}
      {modalOpen && (
        <PumpConfigModal
          type="buffered"
          config={config}
          closeModal={() => openModal(false)}
        />
      )}
    </>
  );
}

export function BatchPumpConfigRow({ config }: { config: BatchPumpConfig }) {
  const dispatch = useDispatch();
  const [modalOpen, openModal] = useState<boolean>(false);
  const pumpConfigSources = useSelector(selectConfigSources);
  const source = pumpConfigSources.find((e) => e.id === config.source);
  const [confirmDeleteModal, openConfirmDelete] = useConfirm({
    body: (
      <div>
        <p>Do you want to delete the following pump?</p>
        <pre>{source.label}</pre>
      </div>
    ),
    actionLabel: 'Delete',
  });

  return (
    <>
      <div className="IndexerPumpConfig__row">
        <div className="IndexerPumpConfig__column IndexerPumpConfig__source">
          {source.label}
        </div>
        <div className="IndexerPumpConfig__column IndexerPumpConfig__status">
          <i className={getStatusClassnames(config.enabled)} />
        </div>
        <div className="IndexerPumpConfig__column IndexerPumpConfig__status">
          <i className={getStatusClassnames(config.feeder_enabled)} />
        </div>
        <div className="IndexerPumpConfig__column IndexerPumpConfig__data">
          {config.products_per_message}
        </div>
        <div className="IndexerPumpConfig__column IndexerPumpConfig__data">
          {config.quota}
        </div>
        <div className="IndexerPumpConfig__column IndexerPumpConfig__actions">
          <Button secondary onClick={() => openModal(true)}>
            <i className="mdi mdi-pen" />
          </Button>
          <Button
            warning
            onClick={() =>
              openConfirmDelete(() =>
                dispatch(actions.deleteBatchConfig(config))
              )
            }
          >
            <i className="mdi mdi-trash-can" />
          </Button>
        </div>
      </div>
      {confirmDeleteModal}
      {modalOpen && (
        <PumpConfigModal
          type="batched"
          config={config}
          closeModal={() => openModal(false)}
        />
      )}
    </>
  );
}

function BufferedPumpConfigLister(props: any) {
  const dispatch = useDispatch();
  const enableAllBuffers = useCallback(() => {
    dispatch(actions.toggleAllBufferedConfigs(true));
  }, [dispatch]);
  const disableAllBuffers = useCallback(() => {
    dispatch(actions.toggleAllBufferedConfigs(false));
  }, [dispatch]);
  return (
    <>
      <div className="IndexerPumpConfig__header">
        <div className="IndexerPumpConfig__column IndexerPumpConfig__column--title IndexerPumpConfig__source">
          Buffered
        </div>
        <div className="IndexerPumpConfig__column IndexerPumpConfig__status">
          Pump
        </div>
        <div className="IndexerPumpConfig__column IndexerPumpConfig__status">
          Buffer &nbsp;
          <Button success onClick={enableAllBuffers}>
            <i className={getStatusClassnames(true)} />
          </Button>
          &nbsp;
          <Button warning onClick={disableAllBuffers}>
            <i className={getStatusClassnames(false)} />
          </Button>
        </div>
        <div className="IndexerPumpConfig__column IndexerPumpConfig__data">
          Products / message
        </div>
        <div className="IndexerPumpConfig__column IndexerPumpConfig__data">
          Messages / pump
        </div>
        <div className="IndexerPumpConfig__column IndexerPumpConfig__data">
          Acceptable delay
        </div>
        <div className="IndexerPumpConfig__column IndexerPumpConfig__actions">
          Actions
        </div>
      </div>
      {props.configs?.map((config) => (
        <BufferedPumpConfigRow
          key={`config-${config.source}`}
          config={config}
        />
      )) || <div>No configuration found</div>}
    </>
  );
}

function BatchPumpConfigLister(props: any) {
  return (
    <>
      <div className="IndexerPumpConfig__header">
        <div className="IndexerPumpConfig__column IndexerPumpConfig__column--title IndexerPumpConfig__source">
          Batched
        </div>
        <div className="IndexerPumpConfig__column IndexerPumpConfig__status">
          Pump
        </div>
        <div className="IndexerPumpConfig__column IndexerPumpConfig__status">
          Feeder
        </div>
        <div className="IndexerPumpConfig__column IndexerPumpConfig__data">
          Products / message
        </div>
        <div className="IndexerPumpConfig__column IndexerPumpConfig__data">
          Messages quota
        </div>
        <div className="IndexerPumpConfig__column IndexerPumpConfig__actions">
          Actions
        </div>
      </div>
      {props.configs?.map((config) => (
        <BatchPumpConfigRow key={`config-${config.source}`} config={config} />
      )) || <div>No configuration found</div>}
    </>
  );
}

export default function PumpConfigList() {
  const bufferedPumpConfigs = useSelector(selectBufferedConfigs);
  const batchPumpConfigs = useSelector(selectBatchConfigs);
  const pumpConfigSources = useSelector(selectConfigSources);
  const dispatch = useDispatch();
  const [createModalOpen, openCreateModal] = useState<boolean>(false);

  useEffect(() => {
    dispatch(actions.fetchConfigSources());
    dispatch(actions.fetchBufferedConfigs());
    dispatch(actions.fetchBatchConfigs());
  }, [dispatch]);

  if (
    batchPumpConfigs === null ||
    bufferedPumpConfigs === null ||
    pumpConfigSources === null
  ) {
    return null; // Loading.
  }
  const headerProps = {
    title: <PumpImage />,
    backHref: routes.home,
    backMessage: 'Back home',
    isTitleSmall: true,
  };

  return (
    <div>
      <HeaderLayout {...headerProps}>
        <Button
          onClick={() => openCreateModal(true)}
          className="IndexerPumpConfig__createBtn"
        >
          <i className="mdi mdi-plus" />
          &nbsp;New config
        </Button>
      </HeaderLayout>
      <div className="IndexerPumpConfigList">
        <BufferedPumpConfigLister configs={bufferedPumpConfigs} />
        <BatchPumpConfigLister configs={batchPumpConfigs} />
      </div>
      {createModalOpen && (
        <PumpConfigModal
          type={null}
          config={null}
          closeModal={() => openCreateModal(false)}
        />
      )}
    </div>
  );
}
