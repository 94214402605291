import { KpiApi } from '@alkem/sdk-dashboard';

export const immutableKpiApi = new KpiApi({
  immutable: true,
  cacheEnabled: true,
  acceptLanguage: 'noop',
});

export default new KpiApi({
  cacheEnabled: true,
  acceptLanguage: 'noop',
});
