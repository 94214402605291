export const REDUCER_KEY = 'brand-claim';

export const TYPE_CREATION = { id: 0, label: 'Creation' };
export const TYPE_ATTRIBUTION = { id: 1, label: 'Attribution' };

export const TYPE_MAPPING = {
  [TYPE_CREATION.id]: TYPE_CREATION,
  [TYPE_ATTRIBUTION.id]: TYPE_ATTRIBUTION,
};

export const STATUS_CREATED = { id: 0, label: 'CREATED' };
export const STATUS_ACCEPTED = { id: 1, label: 'ACCEPTED' };
export const STATUS_REFUSED = { id: 2, label: 'REFUSED' };
export const STATUS_ERROR = { id: 3, label: 'ERROR' };
export const STATUS_DELETED = { id: 4, label: 'DELETED' };

export const STATUS_MAPPING = {
  [STATUS_CREATED.id]: STATUS_CREATED,
  [STATUS_ACCEPTED.id]: STATUS_ACCEPTED,
  [STATUS_REFUSED.id]: STATUS_REFUSED,
  [STATUS_ERROR.id]: STATUS_ERROR,
  [STATUS_DELETED.id]: STATUS_DELETED,
};
