import { HeaderLayout } from '@alkem/react-layout';
import { Button, SwitchButton } from '@alkem/react-ui-button';
import { Spinner } from '@alkem/react-ui-spinner';
import { retailerFieldsDashboardAccessPolicy } from 'access-policies';
import classNames from 'classnames';
import { Tab, Tabs } from 'components/tabs';
import {
  CONSUMER_UNIT,
  DISPLAY_UNIT,
  LOGISTICAL_UNIT,
  PRODUCT_DOCUMENT,
  PRODUCT_PICTURE,
  PRODUCT_VIDEO,
  RFP_ANSWER,
  RFP_BUSINESS_UNIT,
  RFP_EVENT,
  SHARING_UNIT,
  SUPPLIER,
  TARIFF,
} from 'constants/fields';
import * as routes from 'constants/routes';
import { fromJS, is } from 'immutable';
import memoize from 'memoize-one';
import { PERMISSION_PLATFORM_REFERENTIAL_UPDATE } from 'modules/access-policy/common/constants';
import {
  selectUserHasPlatformPermission,
  selectUserReadOnlyAccess,
} from 'modules/auth/selectors';
import { ruleEntityTypes } from 'modules/validation-dashboard/constants';
import PropTypes from 'prop-types';
import qs from 'querystringify';
import React, { PureComponent } from 'react';
import Dropzone from 'react-dropzone';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { selectUser } from 'reducers/user';
import { createStructuredSelector } from 'reselect';
import { saveAs } from 'utils';
import history from 'utils/history';
import { get } from 'utils/immutable';
import { separateActions } from 'utils/redux';
import * as moduleActions from './actions';
import BulkFieldModal from './components/bulkModal';
import CleanProductsModal from './components/cleanProductsModal';
import ConfirmSaveModal, {
  getFieldsDiff,
} from './components/confirm-save-modal';
import { OrganizationAutocomplete } from './components/organization-autocomplete';
import TabFields from './components/tabs/tab-fields';
import { TabReferentials } from './components/tabs/tab-referentials';
import TabRuleSets from './components/tabs/tab-rule-sets';
import TabSupplierReferential from './components/tabs/tab-supplier-referential';
import {
  getOrganizationOption,
  selectEditedOrganization,
  selectIsFetchingRules,
  selectSourceOrganization,
} from './selectors';
import { organizationHasField } from './utils';
import './views.scss';

const mapStateToProps = createStructuredSelector({
  sourceOrganization: selectSourceOrganization,
  editedOrganization: selectEditedOrganization,
  isFetchingRules: selectIsFetchingRules,
  user: selectUser,
  hasReferentialUpdatePermission: (state) =>
    selectUserHasPlatformPermission(
      PERMISSION_PLATFORM_REFERENTIAL_UPDATE,
      state
    ),
  readOnly: (state) =>
    selectUserReadOnlyAccess(retailerFieldsDashboardAccessPolicy, state),
});

const mapDispatchToProps = {
  ...moduleActions,
};

export class OrganizationUsesFieldsView extends PureComponent {
  static propTypes = {
    sourceOrganization: ImmutablePropTypes.map,
    editedOrganization: ImmutablePropTypes.map,
    hasReferentialUpdatePermission: PropTypes.bool.isRequired,
    location: PropTypes.object,
    actions: PropTypes.shape({
      updateOrganizationFields: PropTypes.func,
      selectOrganization: PropTypes.func,
      importOrganization: PropTypes.func,
      loadOrganization: PropTypes.func,
      saveOrganization: PropTypes.func,
      cleanViewAs: PropTypes.func,
      refreshCache: PropTypes.func,
    }),
    user: PropTypes.object.isRequired,
    readOnly: PropTypes.bool.isRequired,
    isFetchingRules: PropTypes.bool,
  };

  state = {
    saving: false,
    refreshing: false,
    tagModalOpen: false,
    cleanProductsModalOpen: false,
    confirmSaveModalOpen: false,
    showInactives: false,
  };

  componentDidMount() {
    const { actions } = this.props;

    const query = qs.parse(history.location.search);
    if (query?.organization_id) {
      actions.loadOrganization(query.organization_id);
    }
  }

  getSaveButtonProps() {
    const { sourceOrganization, editedOrganization } = this.props;
    const { saving } = this.state;
    if (saving) {
      return {
        content: 'Saving',
        disabled: true,
        displaySpinner: true,
        primary: true,
      };
    }
    if (is(sourceOrganization, editedOrganization)) {
      return {
        content: 'Saved',
        disabled: true,
        displaySpinner: false,
        plain: true,
      };
    }
    return {
      content: 'Save',
      disabled: false,
      displaySpinner: false,
      primary: true,
    };
  }

  getSelectedOrganization = memoize(
    (organization) =>
      organization ? [getOrganizationOption(organization)] : [],
    ([newOrg], [lastOrg]) => get(newOrg, 'id') === get(lastOrg, 'id')
  );

  addFields = (fields) => {
    const { editedOrganization, actions } = this.props;
    const newFields = fields.filter(
      (field) => !organizationHasField(editedOrganization, field)
    );

    actions.updateOrganizationFields(
      editedOrganization.get('usesFields').concat(newFields)
    );
    this.setState({ tagModalOpen: false });
  };

  save = async () => {
    const { actions } = this.props;
    this.closeSaveConfirmModal();
    this.setState({ saving: true });
    await actions.saveOrganization();
    this.setState({ saving: false });
  };

  onSave = async () => {
    const { sourceOrganization, editedOrganization } = this.props;
    const {
      addedFields,
      removedFields,
      addedBlockingFields,
      removedBlockingFields,
      addedBlockingRuleSets,
      removedBlockingRuleSets,
    } = getFieldsDiff(sourceOrganization, editedOrganization);
    if (
      addedFields.length > 0 ||
      removedFields.length > 0 ||
      addedBlockingFields.length > 0 ||
      removedBlockingFields.length > 0 ||
      addedBlockingRuleSets.length > 0 ||
      removedBlockingRuleSets.length > 0
    ) {
      this.openSaveConfirmModal();
    } else {
      await this.save();
    }
  };

  onRefreshCache = async () => {
    const { editedOrganization, actions } = this.props;
    this.setState({ refreshing: true });
    await actions.refreshCache(editedOrganization.get('id'));
    this.setState({ refreshing: false });
  };

  selectOrganization = (option) => {
    this.props.actions.selectOrganization(option ? option.value : null);
  };

  unselectOrganization = () => {
    this.selectOrganization(null);
  };

  openTagModal = () => this.setState({ tagModalOpen: true });

  closeTagModal = () => this.setState({ tagModalOpen: false });

  onCleanViewAs = () => {
    this.setState({ cleanProductsModalOpen: true });
  };

  closeCleanProductsModal = () => {
    this.setState({ cleanProductsModalOpen: false });
  };

  openSaveConfirmModal = () => this.setState({ confirmSaveModalOpen: true });

  closeSaveConfirmModal = () => this.setState({ confirmSaveModalOpen: false });

  onExport = () => {
    const { editedOrganization } = this.props;
    const blob = new Blob([JSON.stringify(editedOrganization, null, 2)], {
      type: 'application/json',
    });
    saveAs(blob, `view-as-${editedOrganization.get('nameLegal')}.json`);
  };

  onImport = (files) => {
    const { actions } = this.props;
    if (files.length !== 1) {
      return;
    }

    const reader = new FileReader();
    this.setState({ loading: true });
    reader.onload = function () {
      const content = reader.result;
      const organization = fromJS(JSON.parse(content));
      actions.updateOrganizationFields(organization.get('usesFields'));
    };
    reader.readAsText(files[0]);
  };

  renderHeader() {
    return (
      <HeaderLayout
        title="Fields used by retailers"
        backHref={routes.home}
        backMessage="Back home"
        isTitleSmall
      />
    );
  }

  renderTabs() {
    /**
     * If you need to add a TabFields, with the required checkbox, add it to the
     * mapping here: import { fieldTypeToRuleType } from './constants';
     */
    const { editedOrganization, readOnly, hasReferentialUpdatePermission } =
      this.props;

    if (!editedOrganization) {
      return null;
    }

    const children = [
      <Tab title="Consumer unit" key="tab-consumer-unit">
        <TabFields
          editedOrganization={editedOrganization}
          fieldEntity={CONSUMER_UNIT}
          ruleEntity={ruleEntityTypes.CONSUMER_UNIT.id}
          readOnly={readOnly}
        />
      </Tab>,
    ];

    if (editedOrganization.getIn(['settings', 'listing']) === 'enable') {
      children.push(
        <Tab title="Sharing unit" key="tab-sharing-unit">
          <TabFields
            editedOrganization={editedOrganization}
            fieldEntity={SHARING_UNIT}
            ruleEntity={ruleEntityTypes.SHARING_UNIT.id}
            readOnly={readOnly}
          />
        </Tab>
      );
    }
    if (editedOrganization.getIn(['settings', 'tariff'])) {
      children.push(
        <Tab title="Tariff" key="tab-sharing-unit-tariff">
          <TabFields
            editedOrganization={editedOrganization}
            fieldEntity={TARIFF}
            ruleEntity={ruleEntityTypes.TARIFF.id}
            readOnly={readOnly}
          />
        </Tab>
      );
    }
    if (
      editedOrganization.getIn(['settings', 'supplier-directory', 'enabled'])
    ) {
      children.push(
        <Tab title="Supplier" key="tab-supplier">
          <TabFields
            editedOrganization={editedOrganization}
            fieldEntity={SUPPLIER}
            readOnly={readOnly}
          />
        </Tab>
      );
      if (hasReferentialUpdatePermission) {
        children.push(
          <Tab title="Supplier Referential" key="tab-supplier-referential">
            <TabSupplierReferential
              editedOrganization={editedOrganization}
              readOnly={readOnly}
            />
          </Tab>
        );
      }
    }
    children.push(
      <Tab title="Logistical hierarchy" key="tab-logistical-hierarchy">
        <TabFields
          editedOrganization={editedOrganization}
          fieldEntity={LOGISTICAL_UNIT}
          ruleEntity={ruleEntityTypes.LOGISTICAL_UNIT.id}
          readOnly={readOnly}
        />
      </Tab>
    );
    children.push(
      <Tab title="Display unit" key="tab-display-unit">
        <TabFields
          editedOrganization={editedOrganization}
          fieldEntity={DISPLAY_UNIT}
          ruleEntity={ruleEntityTypes.DISPLAY_UNIT.id}
          readOnly={readOnly}
        />
      </Tab>
    );
    children.push(
      <Tab title="Product picture" key="tab-productpicture">
        <TabFields
          editedOrganization={editedOrganization}
          fieldEntity={PRODUCT_PICTURE}
          ruleEntity={ruleEntityTypes.PICTURE.id}
          readOnly={readOnly}
        />
      </Tab>
    );
    children.push(
      <Tab title="Product document" key="tab-productdocument">
        <TabFields
          editedOrganization={editedOrganization}
          fieldEntity={PRODUCT_DOCUMENT}
          ruleEntity={ruleEntityTypes.DOCUMENT.id}
          readOnly={readOnly}
        />
      </Tab>
    );
    children.push(
      <Tab title="Product video" key="tab-productvideo">
        <TabFields
          editedOrganization={editedOrganization}
          fieldEntity={PRODUCT_VIDEO}
          ruleEntity={ruleEntityTypes.VIDEO.id}
          readOnly={readOnly}
        />
      </Tab>
    );
    if (editedOrganization.getIn(['settings', 'rfpV2'])) {
      children.push(
        <Tab title="Rfp Event" key="tab-rfp-event">
          <TabFields
            editedOrganization={editedOrganization}
            fieldEntity={RFP_EVENT}
            readOnly={readOnly}
          />
        </Tab>
      );
      children.push(
        <Tab title="Rfp BU" key="tab-rfp-bu">
          <TabFields
            editedOrganization={editedOrganization}
            fieldEntity={RFP_BUSINESS_UNIT}
            readOnly={readOnly}
          />
        </Tab>
      );
      children.push(
        <Tab title="Rfp Answer" key="tab-rfp-answer">
          <TabFields
            editedOrganization={editedOrganization}
            fieldEntity={RFP_ANSWER}
            ruleEntity={ruleEntityTypes.RFP_ANSWER.id}
            readOnly={readOnly}
          />
        </Tab>
      );
    }
    children.push(
      <Tab title="Rulesets" key="tab-rulesets">
        <TabRuleSets readOnly={readOnly} />
      </Tab>
    );

    if (hasReferentialUpdatePermission) {
      children.push(
        <Tab title="Referentials" key="tab-referentials">
          <TabReferentials />
        </Tab>
      );
    }

    return (
      <Tabs
        actions={
          !readOnly && (
            <div>
              <Dropzone
                id="import-button"
                accept={{ 'application/json': ['.json'] }}
                onDrop={this.onImport}
                disabled={!editedOrganization}
              >
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <div
                    {...getRootProps({
                      className: classNames(
                        'dropzone Button btn btn-secondary',
                        isDragActive && 'dropzone--active'
                      ),
                    })}
                  >
                    <input {...getInputProps()} />
                    <span>Import</span>
                  </div>
                )}
              </Dropzone>
              <Button
                id="export-button"
                content="Export"
                secondary
                onClick={this.onExport}
              />
              <Button
                id="bulk-load-button"
                content="Bulk load"
                secondary
                onClick={this.openTagModal}
                disabled={!editedOrganization}
              />
            </div>
          )
        }
      >
        {children}
      </Tabs>
    );
  }

  renderSpinner() {
    return (
      <div
        id="is-loading-spinner"
        className="OrganizationUsesFieldsView__Spinner"
      >
        <Spinner center />
      </div>
    );
  }

  renderSaveButton() {
    return (
      <Button
        id="save-button"
        onClick={this.onSave}
        {...this.getSaveButtonProps()}
      />
    );
  }

  renderRefreshCache() {
    const { editedOrganization } = this.props;
    const { refreshing } = this.state;
    return (
      <Button
        id="refresh-cache-button"
        onClick={this.onRefreshCache}
        content={refreshing ? 'Refreshing...' : 'Refresh cache'}
        secondary
        disabled={
          !editedOrganization || !editedOrganization.get('id') || refreshing
        }
      />
    );
  }

  renderCleanViewAsButton() {
    const { editedOrganization } = this.props;
    return (
      <Button
        id="clean-view-as-button"
        onClick={this.onCleanViewAs}
        content="Clean products"
        secondary
        disabled={
          !editedOrganization ||
          !editedOrganization.get('id') ||
          editedOrganization.getIn(['network', 'id']) === 1
        }
      />
    );
  }

  renderRetailerAutocomplete() {
    const { editedOrganization } = this.props;
    const { showInactives } = this.state;
    return (
      <div>
        <OrganizationAutocomplete
          id="organization-autocomplete"
          value={this.getSelectedOrganization(editedOrganization)}
          onSelect={this.selectOrganization}
          onUnselect={this.unselectOrganization}
          placeholder="Select a retailer..."
          showInactives={showInactives}
          searchOnClick
        />
        <SwitchButton
          testid="toggle-show-inactives"
          content="Show inactive organizations"
          checked={showInactives}
          onChange={(isSelected) =>
            this.setState({ showInactives: isSelected })
          }
        />
      </div>
    );
  }

  render() {
    const {
      sourceOrganization,
      editedOrganization,
      readOnly,
      isFetchingRules,
    } = this.props;
    const { tagModalOpen, cleanProductsModalOpen, confirmSaveModalOpen } =
      this.state;
    return (
      <div>
        {this.renderHeader()}
        <div className="container-fluid row">
          <div className="OrganizationUsesFieldsView col-xs-12">
            <div className="flex">
              <div className="flex-grow--1">
                {this.renderRetailerAutocomplete()}
              </div>
              {!readOnly && (
                <div className="OrganizationUsesFieldsView__Actions">
                  {this.renderSaveButton()}
                  {this.renderRefreshCache()}
                  {this.renderCleanViewAsButton()}
                </div>
              )}
            </div>
            {!isFetchingRules && editedOrganization && this.renderTabs()}
          </div>
        </div>
        {tagModalOpen && (
          <BulkFieldModal
            editedOrganization={editedOrganization}
            onAdd={this.addFields}
            onClose={this.closeTagModal}
          />
        )}
        {cleanProductsModalOpen &&
          editedOrganization &&
          editedOrganization.getIn(['network', 'id']) !== 1 && (
            <CleanProductsModal
              onClose={this.closeCleanProductsModal}
              organizationId={editedOrganization.get('id')}
            />
          )}
        {confirmSaveModalOpen && (
          <ConfirmSaveModal
            sourceOrganization={sourceOrganization}
            editedOrganization={editedOrganization}
            onSave={this.save}
            onClose={this.closeSaveConfirmModal}
          />
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(OrganizationUsesFieldsView);
