import authApi from 'resources/authApi';
import billingApi from 'resources/billingApi';
import { logError } from 'utils';
import { Group, GroupOriginal } from './types';
import { findSubscription, isActiveGroupStatus } from './utils';

export async function fetchSubscription(organizationId: number) {
  const { response, error } = await billingApi.fetchSubscriptions({
    organizationId,
  });
  if (response) {
    const subscriptions = response.data.data || [];
    const subscription = findSubscription(subscriptions);
    if (subscription) {
      return subscription;
    } else {
      return null;
    }
  }
  if (error) {
    logError(error);
  }
  return null;
}

const formatGroup = (
  group: GroupOriginal,
  hasPayingRecipient: boolean
): Group => ({
  id: group.id,
  label: group.name.trim(),
  status: group.relation_status,
  hasPayingRecipient,
});

export async function fetchGroups(organizationId: number): Promise<Group[]> {
  try {
    const { data: recipients } = await authApi.RecipientsList({
      filters: {
        hasGroup: true,
        worksWithOrganization: false,
      },
      withs: {
        group: true,
        groupRelation: true,
        isPaying: true,
        organizationId,
      },
    });

    const groups: { [key: number]: Group } = {};
    recipients.forEach((r) => {
      if (
        r.isPayingRecipient ||
        (r.group && isActiveGroupStatus(r.group?.relation_status as number))
      ) {
        const groupID: number = r.group?.id as number;
        if (!groups[groupID]) {
          groups[groupID] = formatGroup(
            r.group as GroupOriginal,
            r.isPayingRecipient as boolean
          );
        } else if (r.isPayingRecipient) {
          groups[groupID].hasPayingRecipient = r.isPayingRecipient;
        }
      }
    });
    return Object.values(groups);
  } catch (error) {
    logError(error);
    return [];
  }
}
