import { useState } from 'react';
import { Button } from '@alkem/react-ui-button';

export const ShowMore = ({ label = 'more', children }) => {
  const [showMore, toggleShowMore] = useState(false);
  return (
    <>
      <Button
        primary
        className="SettingOrganizationMain__showMore"
        onClick={() => toggleShowMore(!showMore)}
      >
        {!showMore ? (
          <>
            <i className="mdi mdi-chevron-down" />
            <span>Show {label}</span>
            <i className="mdi mdi-chevron-down" />
          </>
        ) : (
          <>
            <i className="mdi mdi-chevron-right" />
            <span>Hide {label}</span>
            <i className="mdi mdi-chevron-left" />
          </>
        )}
      </Button>
      {showMore && children}
    </>
  );
};
