import { createReducer } from 'utils/reducers';

import {
  receiveReducer,
  initPagination,
  changeLimit,
  nextPage,
  previousPage,
  addFilter,
  removeFilter,
  updateFilter,
  clearFilter,
} from 'utils/reducers/list';

import * as events from '../constants/events';

const LIMIT_STORAGE_KEY = 'gdsnRelationListLimit';
const SORTING_STORAGE_KEY = 'gdsnRelationListSorting';

const initialState = {
  list: [],
  filters: {
    filter_gln: [],
    filter_gtins_in: [],
  },
  pagination: initPagination(LIMIT_STORAGE_KEY, SORTING_STORAGE_KEY),
};

export default createReducer(initialState, {
  [events.RECEIVE_GDSN_RELATION_LIST]: (state, action) =>
    receiveReducer(state, action.payload),
  [events.CHANGE_LIMIT_GDSN_RELATION_LIST]: (state, action) =>
    changeLimit(state, action, LIMIT_STORAGE_KEY),
  [events.NEXT_PAGE_GDSN_RELATION_LIST]: nextPage,
  [events.PREVIOUS_PAGE_GDSN_RELATION_LIST]: previousPage,
  [events.ADD_GDSN_RELATION_LIST_FILTER]: addFilter,
  [events.REMOVE_GDSN_RELATION_LIST_FILTER]: removeFilter,
  [events.UPDATE_GDSN_RELATION_LIST_FILTER]: updateFilter,
  [events.CLEAR_GDSN_RELATION_LIST_FILTER]: clearFilter,
  [events.GET_GDSN_RELATION_LIST]: (state) => {
    const newState = { ...state };
    newState.list = [];
    return newState;
  },
});
