import { Modal } from '@alkem/react-ui-modal';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { separateActions } from 'utils/redux';
import { closeGepirModal, uploadGepirCSV } from '../actions';
import { selectModalGepir } from '../selectors';

const mapStateToProps = (state) => ({
  isOpen: selectModalGepir(state).isOpen,
});

const mapDispatchToProps = {
  uploadGepirCSV,
  closeGepirModal,
};

export class GDSNGepirModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      uploadGepirCSV: PropTypes.func.isRequired,
      closeGepirModal: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = {
    files: null,
  };

  onUpload = () => {
    this.props.actions.uploadGepirCSV(this.state.files[0]);
  };

  onSelectFiles = (files) => {
    this.setState({ files });
  };

  renderSummary() {
    const { files } = this.state;
    return (
      <div className="ImportModal__summary">
        <div className="ImportModal__fileSummary">
          {files.map((file) => (
            <div key={file.name}>
              <i className="mdi mdi-file" />
              {file.name}
            </div>
          ))}
        </div>
      </div>
    );
  }

  renderDropZone() {
    return (
      <Dropzone onDrop={this.onSelectFiles}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div
            {...getRootProps({
              className: classNames(
                'Dropzone',
                isDragActive && 'Dropzone--active'
              ),
            })}
          >
            <input {...getInputProps()} />
            <div>
              <b>Drag and drop your file here</b> or select a file from your
              computer...
            </div>
          </div>
        )}
      </Dropzone>
    );
  }

  render() {
    const { actions, isOpen } = this.props;
    const { files } = this.state;
    if (!isOpen) {
      return null;
    }
    return (
      <Modal
        title="GEPIR Crawl from CSV"
        confirmButtonText="Upload"
        confirmDisabled={this.state.files === null}
        onConfirm={this.onUpload}
        onClose={actions.closeGepirModal}
      >
        {files ? this.renderSummary() : this.renderDropZone()}
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(GDSNGepirModal);
