import { get, getIn } from 'utils/immutable';

export const getUserFullName = (user) => {
  const lastname = get(user, 'lastname');
  const firstname = get(user, 'firstname');

  const fullname = `${firstname} ${lastname}`.trim();
  return fullname.length > 0 ? fullname : 'N/A';
};

export const getUserId = (user) => get(user, 'id');

export const getUserOrganizationNameLegal = (user) =>
  getIn(user, 'belongsTo.0.nameLegal');

const getUserOrganization = (user) => getIn(user, 'belongsTo.0');

export const getUserOrganizationId = (user) =>
  get(getUserOrganization(user), 'id');

export const getUserUsername = (user) => get(user, 'username');

export const getUserPhoneNumber = (user) => {
  const phonenumber = get(user, 'phonenumber');
  return phonenumber && phonenumber.length > 0 ? phonenumber : 'N/A';
};

export const getUserLastLoggedIn = (user) => get(user, 'lastLoggedInAt');

// has ubto.admin
export const isAdmin = (user) => {
  const org = getUserOrganization(user);
  if (!org) {
    return false;
  }
  return get(org, 'permissions').includes('admin');
};
