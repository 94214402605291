// MAIN

export const RESET_ORGANIZATION = 'ORGANIZATION/RESET_ORGANIZATION';
export const RESET_MANAGED_ORGANIZATION_PERMISSIONS =
  'ORGANIZATION/RESET_MANAGED_ORGANIZATION_PERMISSIONS';
export const ORGANIZATION_RECEIVED = 'ORGANIZATION/ORGANIZATION_RECEIVED';
export const MANAGED_ORGANIZATION_PERMISSIONS_RECEIVED =
  'ORGANIZATION/MANAGED_ORGANIZATION_PERMISSIONS_RECEIVED';

// SAVE MODAL

export const SAVE_MODAL_TOGGLED = 'ORGANIZATION/SAVE_MODAL_TOGGLED';
export const SAVE_IN_PROGRESS = 'ORGANIZATION/SAVE_IN_PROGRESS';
export const SET_SAVE_ERROR_MESSAGE = 'ORGANIZATION/SET_SAVE_ERROR_MESSAGE';
export const SAVE_MODAL_INIT_FEATURE_STATUS =
  'ORGANIZATION/SAVE_MODAL_INIT_FEATURE_STATUS';
export const SAVE_MODAL_UPDATE_FEATURE_STATUS =
  'ORGANIZATION/SAVE_MODAL_UPDATE_FEATURE_STATUS';

// SETTINGS

export const UPDATE_SETTINGS_VALUE = 'ORGANIZATION/UPDATE_SETTINGS_VALUE';
export const ADD_LIST_SETTING_VALUE = 'ORGANIZATION/ADD_LIST_SETTING_VALUE';
export const REMOVE_LIST_SETTING_VALUE =
  'ORGANIZATION/REMOVE_LIST_SETTING_VALUE';
export const RECEIVE_LISTCOLUMN_REFERENTIAL =
  'ORGANIZATION/RECEIVE_LISTCOLUMN_REFERENTIAL';
export const RECEIVE_LISTREPORTING_REFERENTIAL =
  'ORGANIZATION/RECEIVE_LISTREPORTING_REFERENTIAL';
export const RECEIVE_FIELDTAG_REFERENTIAL =
  'ORGANIZATION/RECEIVE_FIELDTAG_REFERENTIAL';
export const RECEIVE_FIELDMETADATA_ROOTS =
  'ORGANIZATION/RECEIVE_FIELDMETADATA_ROOTS';
export const RECEIVE_SUPPLIER_RECONCILIATION_KEYS_REFERENTIAL =
  'ORGANIZATION/RECEIVE_SUPPLIER_RECONCILIATION_KEYS_REFERENTIAL';

// INFOS

export const ORGANIZATION_INFOS_ADD_TARGET_MARKET =
  'ORGANIZATION/ORGANIZATION_INFOS_ADD_TARGET_MARKET';
export const ORGANIZATION_INFOS_REMOVE_TARGET_MARKET =
  'ORGANIZATION/ORGANIZATION_INFOS_REMOVE_TARGET_MARKET';
export const ORGANIZATION_INFOS_UPDATE_FIELD =
  'ORGANIZATION/ORGANIZATION_INFOS_UPDATE_FIELD';
export const ORGANIZATION_INFOS_SAVED = 'ORGANIZATION/ORGANIZATION_INFO_SAVED';

// RECIPIENTS

export const RECEIVE_RECIPIENTS = 'ORGANIZATION/RECEIVE_RECIPIENTS';
export const ADD_RECIPIENT = 'ORGANIZATION/ADD_RECIPIENT';
export const REMOVE_RECIPIENT = 'ORGANIZATION/REMOVE_RECIPIENT';

// USERS

export const RECEIVE_USERS = 'ORGANIZATION/RECEIVE_USERS';
export const USERS_SET_PAGE = 'ORGANIZATION/USERS_SET_PAGE';
export const PASSWORD_CHECK = 'organization/user/password-check';
export const USERNAME_CHECK = 'organization/user/username-check';
export const USER_CHECK = 'organization/user/user-check';
export const RESET_MODAL = 'organization/user/reset-modal';
export const SET_LOADING = 'organization/user/set-loading';
export const SET_MODAL_OPEN = 'organization/user/set-modal-open';

// GLN

export const OPEN_GLN_MODAL = 'OPEN_GLN_MODAL';
export const CLOSE_GLN_MODAL = 'CLOSE_GLN_MODAL';
export const ON_CHANGE_GLN = 'ON_CHANGE_GLN';
export const ORGANIZATION_GLN_REMOVED = 'ORGANIZATION_GLN_REMOVED';

// APPLICATIONS

export const FETCH_APPLICATIONS = 'organization/fetch-applications';
export const RECEIVE_APPLICATIONS = 'organization/receive-applications';
export const UPDATE_APPLICATION = 'organization/update-application';
export const SHOW_MORE_APPLICATIONS = 'organization/show-more-applications';
export const SAVE_APPLICATIONS_DONE = 'organization/save-applications-done';

// BILLING

export const UPDATE_GROUP_RELATION = 'UPDATE_GROUP_RELATION';
export const BILLING_RESET_CHANGES = 'BILLING_RESET_CHANGES';

// SSO

export const FETCH_SSO = 'organization/fetch-sso';
export const RECEIVE_SSO = 'organization/receive-sso';
export const CREATE_SSO = 'organization/create-sso';
export const DELETE_SSO = 'organization/delete-sso';
export const UPDATE_SSO = 'organization/update-sso';
export const ERROR_SSO = 'organization/error-sso';

// Permissions

export const FETCH_PERMISSIONS = 'organization/fetch-permissions';
export const FETCH_ORGANIZATION_CONFIGURATION =
  'organization/fetch-configuration';
export const RECEIVE_PERMISSIONS = 'organization/receive-permissions';
export const RECEIVE_ORGANIZATION_CONFIGURATION =
  'organization/receive-configuration';
export const UPSERT_ORGANIZATION_CONFIGURATION =
  'organization/upsert-configuration';
export const ERROR_PERMISSIONS = 'organization/error-permissions';
