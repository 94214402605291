import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Modal } from '@alkem/react-ui-modal';
import { SimpleSelect } from '@alkem/react-ui-select';
import InputWithLabel from 'components/input-with-label';
import { Text } from '@alkem/react-ui-inputs';

import { connect } from 'react-redux';
import { separateActions } from 'utils/redux';
import './index.scss';

import { updatePCR, sendGdsnPCR } from '../actions';

import { selectState } from '../selectors';

const mapStateToProps = (state) => ({
  pcr: selectState(state).pcr,
});

const mapDispatchToProps = {
  updatePCR,
  sendGdsnPCR,
};

const pcrStatus = [
  {
    value: 'RECEIVED',
    label: 'RECEIVED',
  },
  {
    value: 'ACCEPTED',
    label: 'ACCEPTED',
  },
  {
    value: 'REJECTED',
    label: 'REJECTED',
  },
  {
    value: 'SYNCHRONISED',
    label: 'SYNCHRONISED',
  },
  {
    value: 'REVIEW',
    label: 'REVIEW',
  },
];

const reasonNeededStatus = ['REVIEW', 'REJECTED'];

export class PcrModal extends Component {
  static propTypes = {
    pcr: PropTypes.object,
    psrItemId: PropTypes.number,
    psrSyncId: PropTypes.number,
    onClose: PropTypes.func.isRequired,
    actions: PropTypes.shape({
      updatePCR: PropTypes.func.isRequired,
      sendGdsnPCR: PropTypes.func.isRequired,
    }).isRequired,
  };

  componentDidMount() {
    const { psrItemId, psrSyncId } = this.props;
    if (psrItemId) {
      this.props.actions.updatePCR(['psr_item_id'], psrItemId);
    }
    if (psrSyncId) {
      this.props.actions.updatePCR(['psr_sync_id'], psrSyncId);
    }
  }

  onPSRSyncIdChange = (event) => {
    this.props.actions.updatePCR(['psr_sync_id'], event.target.value);
    this.props.actions.updatePCR(['psr_item_id'], null);
  };

  onPSRItemIdChange = (event) => {
    this.props.actions.updatePCR(['psr_item_id'], event.target.value);
    this.props.actions.updatePCR(['psr_sync_id'], null);
  };

  onStatusChange = (event) => {
    this.props.actions.updatePCR(['status'], event.value);
  };

  onReasonChange = (event) => {
    this.props.actions.updatePCR(['reason'], event.target.value);
  };

  sendPCR = () => {
    const { actions, pcr, onClose } = this.props;
    actions.sendGdsnPCR(pcr);
    onClose();
  };

  render() {
    const { pcr, onClose } = this.props;
    return (
      <Modal
        title="Send PCR"
        confirmButtonText="Send"
        onClose={onClose}
        onConfirm={this.sendPCR}
      >
        <div className="GDSNPCRModal_Body">
          <div className="GDSNPCRModal__Form">
            <div className="GDSNPCRModal__Line">
              {!pcr.get('psr_item_id') && (
                <InputWithLabel
                  inputId="psr-sync-id"
                  label="PSR syncronisation id"
                >
                  <Text
                    id="psr-sync-id"
                    value={pcr.get('psr_sync_id')}
                    placeholder="You should at least have one PSR syncronisation id or PSR item id"
                    onChange={this.onPSRSyncIdChange}
                  />
                </InputWithLabel>
              )}
              {!pcr.get('psr_sync_id') && (
                <InputWithLabel inputId="psr-item-id" label="PSR item id">
                  <Text
                    id="psr-item-id"
                    value={pcr.get('psr_item_id')}
                    placeholder="You should at least have one PSR syncronisation id or PSR item id"
                    onChange={this.onPSRItemIdChange}
                  />
                </InputWithLabel>
              )}
              <InputWithLabel
                inputId="status"
                label="Status"
                extraInputClassNames={{ EnpointModal_SelectMargin: true }}
              >
                <SimpleSelect
                  id="cis-status_slct"
                  options={pcrStatus}
                  value={pcrStatus.find((o) => o.value === pcr.get('status'))}
                  onSelect={this.onStatusChange}
                  autoSize
                />
              </InputWithLabel>
              {reasonNeededStatus.includes(pcr.get('status')) && (
                <InputWithLabel inputId="reason" label="Reason text">
                  <Text
                    id="reason"
                    value={pcr.get('reason')}
                    placeholder="(Optional) Free text message to explain the reason of the REJECTED/REVIEW"
                    onChange={this.onReasonChange}
                  />
                </InputWithLabel>
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(PcrModal);
