export const CONSUMER_UNIT = 'consumerUnit';
export const SHARING_UNIT = 'sharingUnit';
export const TARIFF = 'tariff';
export const SUPPLIER = 'supplier';
export const LOGISTICAL_UNIT = 'logisticalUnit';
export const DISPLAY_UNIT = 'displayUnit';
export const PRODUCT_PICTURE = 'productPicture';
export const PRODUCT_VIDEO = 'productVideo';
export const PRODUCT_DOCUMENT = 'productDocument';
export const RFP_EVENT = 'rfpEvent';
export const RFP_BUSINESS_UNIT = 'rfpBU';
export const RFP_ANSWER = 'rfpAnswer';

export const MANDATORY_VIEW_AS_FIELDS = [
  'tags',
  'isPackshot',
  'canFilesBeEdited',
  'isFileBackgroundTransparent',
  'ipUsageRights',
  'sequenceNumber',
  'pictureEffectiveStartDate',
  'pictureEffectiveEndDate',
  'pictureAngleVerticalCode',
  'pictureAngleHorizontalCode',
  'fileTypeCode',
  'productFaceCode',
  'fileContentTypeCode',
  'webOptimizedTypeCode',
];
