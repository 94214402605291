import React from 'react';

import { ModuleWrapper } from 'redux/register';

import { GDSN_PSR_LIST_STORE_KEY } from './constants/reducers';

import reducer from './reducers';
import GDSNPSRDashboardView from './views';

export default function GDSNPSRDashboardViewContainer() {
  return (
    <ModuleWrapper reducerKey={GDSN_PSR_LIST_STORE_KEY} reducer={reducer}>
      <GDSNPSRDashboardView />
    </ModuleWrapper>
  );
}
