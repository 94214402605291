import { AddButton } from '@alkem/react-ui-button';
import { fromJS, List, Map } from 'immutable';
import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import FieldDisplayInfo from './display-info';
import './field-form.scss';
import FieldTechnicalInfo from './technical-info';
import FieldVisibilityInfo from './visibility-info';
import ChildField from './child-field';

export default class FieldForm extends PureComponent {
  static propTypes = {
    field: PropTypes.object.isRequired,
    path: PropTypes.array.isRequired,
    update: PropTypes.func.isRequired,
    tags: PropTypes.array,
    isRoot: PropTypes.bool.isRequired,
    isCreation: PropTypes.bool.isRequired,
    isConsumerUnitField: PropTypes.bool.isRequired,
    displayHeader: PropTypes.bool,
    readOnly: PropTypes.bool,
  };

  static defaultProps = {
    displayHeader: true,
  };

  onAddChild = () => {
    const { field, path, update } = this.props;
    update(
      [...path, 'children'],
      (field.get('children') || List()).push(
        fromJS({
          name: null,
          description: null,
          specific: false,
          type: null,
          functional_key: false,
          declinable_by: null,
          referential: null,
          uri: null,
          index: false,
          nullable: true,
          enabled: false,
          private: false,
          isApplicableToLU: false,
          isApplicableToDU: false,
          displayInfo: {},
        })
      )
    );
  };

  onMoveChildUp = (index) => () => {
    const { path, field, update } = this.props;
    const children = field.get('children');
    if (index <= 0) {
      return;
    }

    // Update children with new rank
    const child = children.get(index);
    const previousChild = children.get(index - 1);

    update(
      [...path, 'children'],
      children
        .set(index, previousChild)
        .set(index - 1, child)
        .map((c, rank) =>
          c.update('displayInfo', (di) =>
            di ? di.set('rank', rank) : Map({ rank })
          )
        )
    );
  };

  onMoveChildDown = (index) => () => {
    const { path, field, update } = this.props;
    const children = field.get('children');
    if (index >= children.size) {
      return;
    }

    // Update children with new rank
    const child = children.get(index);
    const previousChild = children.get(index + 1);

    update(
      [...path, 'children'],
      children
        .set(index, previousChild)
        .set(index + 1, child)
        .map((c, rank) =>
          c.update('displayInfo', (di) =>
            di ? di.set('rank', rank) : Map({ rank })
          )
        )
    );
  };

  renderChild = (field, index) => {
    const { path, update, isConsumerUnitField } = this.props;
    return (
      <ChildField
        key={`${index}-${field.get('id')}`}
        path={path}
        field={field}
        childIndex={index}
        onToggle={update}
        onMoveUp={this.onMoveChildUp(index)}
        onMoveDown={this.onMoveChildDown(index)}
      >
        <FieldForm
          field={field}
          path={[...path, 'children', index]}
          update={update}
          isRoot={false}
          isCreation={isUndefined(field.get('id'))}
          isConsumerUnitField={isConsumerUnitField}
          displayHeader={false}
        />
      </ChildField>
    );
  };

  renderAddChildButton() {
    const { field, readOnly } = this.props;
    if (readOnly || !['list', 'dict'].includes(field.get('type'))) {
      return null;
    }
    return (
      <div className="FieldForm__addChild">
        <AddButton label="Add a child" onClick={this.onAddChild} />
      </div>
    );
  }

  renderChildren() {
    const { field } = this.props;
    return (
      <div className="FieldForm__children">
        {!!field.get('children') && field.get('children').map(this.renderChild)}
        {this.renderAddChildButton()}
      </div>
    );
  }

  render() {
    const {
      field,
      isRoot,
      tags,
      isCreation,
      isConsumerUnitField,
      path,
      update,
      displayHeader,
      readOnly,
    } = this.props;
    return (
      <div className="FieldForm">
        <FieldTechnicalInfo
          field={field}
          path={path}
          update={update}
          isCreation={isCreation}
          displayHeader={displayHeader}
          readOnly={readOnly}
        />
        {this.renderChildren()}
        <FieldVisibilityInfo
          field={field}
          path={path}
          update={update}
          tags={tags}
          isRoot={isRoot}
          isConsumerUnitField={isConsumerUnitField}
          displayHeader={displayHeader}
          readOnly={readOnly}
        />
        <FieldDisplayInfo
          field={field}
          path={path}
          update={update}
          isRoot={isRoot}
          isConsumerUnitField={isConsumerUnitField}
          displayHeader={displayHeader}
          readOnly={readOnly}
        />
      </div>
    );
  }
}
