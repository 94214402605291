export const GET_CUSTOM_PARSE_LIST = 'io-gdsn-exports/GET_CUSTOM_PARSE_LIST';
export const GET_EXPORT_MAPPING_LIST =
  'io-gdsn-exports/GET_EXPORT_MAPPING_LIST';
export const GET_FIELD_LIST = 'io-gdsn-exports/GET_FIELD_LIST';
export const GET_FUNCTION_VALUE_LIST =
  'io-gdsn-exports/GET_FUNCTION_VALUE_LIST';
export const GET_FUNCTION_NOT_VALUE_LIST =
  'io-gdsn-exports/GET_FUNCTION_NOT_VALUE_LIST';
export const GET_REFERENTIAL_CODE_LIST =
  'io-gdsn-exports/GET_REFERENTIAL_CODE_LIST';
export const GET_XPATH_LIST = 'io-gdsn-exports/GET_XPATH_LIST';

export const RECEIVE_CUSTOM_PARSE_OPTIONS =
  'io-gdsn-exports/RECEIVE_CUSTOM_PARSE_OPTIONS';
export const RECEIVE_EXPORT_MAPPING_LIST =
  'io-gdsn-exports/RECEIVE_EXPORT_MAPPING_LIST';
export const RECEIVE_LIST_FIELD_OPTIONS =
  'io-gdsn-exports/RECEIVE_LIST_FIELD_OPTIONS';
export const RECEIVE_FIELD_OPTIONS = 'io-gdsn-exports/RECEIVE_FIELD_OPTIONS';
export const RECEIVE_FUNCTION_VALUE_OPTIONS =
  'io-gdsn-exports/RECEIVE_FUNCTION_VALUE_OPTIONS';
export const RECEIVE_FUNCTION_NOT_VALUE_OPTIONS =
  'io-gdsn-exports/RECEIVE_FUNCTION_NOT_VALUE_OPTIONS';
export const RECEIVE_REFERENTIAL_CODE_OPTIONS =
  'io-gdsn-exports/RECEIVE_REFERENTIAL_CODE_OPTIONS';
export const RECEIVE_XPATH_LIST = 'io-gdsn-exports/RECEIVE_XPATH_LIST';

export const SAVE_EXPORT_MAPPING = 'io-gdsn-exports/SAVE_EXPORT_MAPPING';
export const CHANGE_ORDER_EXPORT_MAPPING =
  'io-gdsn-exports/CHANGE_ORDER_EXPORT_MAPPING';

export const SET_EXPORT_MAPPING = 'io-gdsn-exports/SET_EXPORT_MAPPING';
export const SET_IS_SAVING_EXPORT_MAPPING_IN_PROGRESS =
  'io-gdsn-exports/SET_IS_SAVING_EXPORT_MAPPING_IN_PROGRESS';
