import CancelablePromise from 'cancelable-promise';
import { immutableAuthApi } from 'resources/authApi';
import { GROUPS_RECEIVE } from '../constants/groups';
import { selectGroupList } from '../selectors/groups';

export const receiveGroups = (list) => ({
  type: GROUPS_RECEIVE,
  list,
});

export const getGroups = () => (dispatch, getState) => {
  const groups = selectGroupList(getState());
  if (groups.size > 0) {
    return new CancelablePromise((resolve) => resolve(groups));
  }

  return immutableAuthApi.listOrganizationGroups('').then((response) => {
    const { data } = response.data;
    dispatch(receiveGroups(data));
    return data;
  });
};
