import { Button } from '@alkem/react-ui-button';
import { isFunction } from 'utils';

type Props = {
  isLoading: boolean;
  onActivate?: (e?: any) => void;
  onDeactivate?: (e?: any) => void;
  onAccept?: (e?: any) => void;
  onExpireTokens?: (e?: any) => void;
  tokensCount: number;
};

function UpdateUserFooter({
  isLoading,
  onActivate,
  onDeactivate,
  onAccept,
  onExpireTokens,
  tokensCount,
}: Props) {
  return (
    <>
      {isFunction(onActivate) && (
        <Button
          content="Activate"
          onClick={onActivate}
          disabled={isLoading}
          warning
        />
      )}
      {isFunction(onDeactivate) && (
        <Button
          content="Deactivate"
          onClick={onDeactivate}
          disabled={isLoading}
          warning
        />
      )}
      {isFunction(onAccept) && (
        <Button
          content="Accept"
          onClick={onAccept}
          disabled={isLoading}
          warning
        />
      )}
      {isFunction(onExpireTokens) && (
        <Button
          content={`Expire ${tokensCount} tokens`}
          onClick={onExpireTokens}
          disabled={isLoading}
          warning
        />
      )}
    </>
  );
}

export default UpdateUserFooter;
export { UpdateUserFooter };
