import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Spinner } from '@alkem/react-ui-spinner';

import StatusBar from 'components/ui/status-bar';
import { separateActions } from 'utils/redux';

import './row.scss';

import {
  downloadGDSNDocument,
  openCIHWModal,
  loadCINDocuments,
  updateFilter,
} from '../../actions';

import { selectFiltersDirection } from '../../selectors';
import { formatTimeImplicitUTC, TIME_FORMAT_MINUTE } from 'utils/time';

const mapStateToProps = (state) => ({
  direction: selectFiltersDirection(state),
});

const mapDispatchToProps = {
  downloadGDSNDocument,
  openCIHWModal,
  loadCINDocuments,
  updateFilter,
};

export class GDSNCIListRow extends PureComponent {
  static propTypes = {
    ci: PropTypes.object.isRequired,
    direction: PropTypes.string.isRequired,
    actions: PropTypes.shape({
      downloadGDSNDocument: PropTypes.func.isRequired,
      openCIHWModal: PropTypes.func.isRequired,
      loadCINDocuments: PropTypes.func.isRequired,
      updateFilter: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      documents: undefined,
    };
  }

  setDocuments = (documents) => {
    this.setState(() => ({ documents: documents }));
  };

  onClickExpand = () => {
    const { documents } = this.state;
    if (documents === undefined) {
      this.props.actions.loadCINDocuments(this.props.ci.id, this.setDocuments);
    }
    this.setState((state) => ({ isExpanded: !state.isExpanded }));
  };

  onClickDirectLink = () => {
    this.props.actions.updateFilter('filter_id_in', this.props.ci.id);
  };

  onClickHierarchyWithdrawal = () => {
    this.props.actions.openCIHWModal(this.props.ci);
  };

  getDocumentState(doc) {
    return doc.transaction.ack.status !== 'ACCEPTED' ? 'FAILURE' : 'SUCCESS';
  }

  downloadGDSNDocument = (doc) => () =>
    this.props.actions.downloadGDSNDocument(doc);

  renderDocuments() {
    const { documents } = this.state;
    let lines = null;
    if (Array.isArray(documents) && documents.length > 0) {
      lines = documents.map((doc) => (
        <div key={doc.id} className="GDSNCIListRow GDSNCIListRow_mini row">
          <StatusBar type={this.getDocumentState(doc)} />
          <div className="col-xs-1">{doc.type}</div>
          <div className="col-xs-2">
            {formatTimeImplicitUTC(doc.createdAt, TIME_FORMAT_MINUTE)}
          </div>
          <div className="col-xs-2">{doc.status}</div>
          <div className="col-xs-1">{doc.message.state}</div>
          <div className="col-xs-1">
            <i
              className="mdi mdi-download GDSNCIListDetailsIconDoc"
              onClick={this.downloadGDSNDocument(doc.message.id)}
            />
          </div>
          <div className="col-xs-4">
            {`${
              doc.transaction.ack.id
                ? `${doc.transaction.ack.status}`
                : 'Not received'
            } `}
            {doc.transaction.ack.id && (
              <i
                className="mdi mdi-download GDSNCIListDetailsIconDoc"
                onClick={this.downloadGDSNDocument(doc.transaction.ack.id)}
              />
            )}
          </div>
        </div>
      ));
    } else if (Array.isArray(documents) && documents.length === 0) {
      lines = <div>No documents attached to this CI synchronization :(</div>;
    } else if (documents === undefined) {
      lines = (
        <div>
          <Spinner loading small />
        </div>
      );
    }
    return (
      <div className="GDSNCIListRowWrapper">
        <div className="GDSNCIListRow GDSNCIListRow_mini GDSNCIListRow__Header row">
          <div className="col-xs-1">Type</div>
          <div className="col-xs-2">Created At</div>
          <div className="col-xs-2">Status</div>
          <div className="col-xs-1">State</div>
          <div className="col-xs-1">Download</div>
          <div className="col-xs-2">GS1Response</div>
        </div>
        <div className="GDSNCIListRow__Lines">{lines}</div>
      </div>
    );
  }

  render() {
    const { ci, direction } = this.props;
    const { isExpanded } = this.state;
    const expandClass = {
      GDSNCIListDetailsIcon: true,
      'col-xs-0.5': true,
      mdi: true,
      'mdi-chevron-up': isExpanded,
      'mdi-chevron-down': !isExpanded,
    };
    return (
      <div className="GDSNCIListRowWrapper row">
        <div key={ci.id} id={ci.id} className="GDSNCIListRow row">
          <div className="col-xs-5 GDSNCIListRow_Organizations">
            <div>
              {<b>{ci.source.name}</b> || 'N/A'} ({ci.source.gln})
            </div>
            <div>
              {<b>↳ {ci.recipient.name}</b> || 'N/A'} ({ci.recipient.gln})
            </div>
          </div>
          <div className="col-xs-2 GDSNCIListRow_GTIN">
            <div>
              <i className="mdi mdi-barcode" />
              <b>{` ${ci.gtin}`}</b>
            </div>
            <div>
              <i className="mdi mdi-chemical-weapon" />
              {` ${ci.gpc}`}
            </div>
            <div>
              <i className="mdi mdi-home-map-marker" />
              {` ${ci.target_market}`}
            </div>
          </div>
          <div className="col-xs-1 GDSNCIListRow_Status">{ci.status}</div>
          <div className="col-xs-2 GDSNCIListRow_CreatedAt">
            <div>
              <i className="mdi mdi-note-plus" />
              {formatTimeImplicitUTC(ci.createdAt, TIME_FORMAT_MINUTE)}
            </div>
            <div>
              <i className="mdi mdi-sync" />
              {formatTimeImplicitUTC(ci.updatedAt, TIME_FORMAT_MINUTE)}
            </div>
          </div>
          <div className="col-xs-2 GDSNCISListRow_actions">
            <i
              onClick={this.onClickExpand}
              className={classNames(expandClass)}
            />
            <i
              onClick={this.onClickDirectLink}
              className="mdi mdi-link-variant GDSNCIListDetailsIcon"
            />
            {direction === 'write' && (
              <i
                onClick={this.onClickHierarchyWithdrawal}
                className="mdi mdi-lan-disconnect GDSNCIListDetailsIcon"
              />
            )}
          </div>
        </div>
        {isExpanded ? this.renderDocuments() : null}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(GDSNCIListRow);
