// List
export const RECEIVE_GDSN_CI_LIST = 'RECEIVE_GDSN_CI_LIST';
export const GET_GDSN_CI_LIST = 'GET_GDSN_CI_LIST';
export const CHANGE_LIMIT_GDSN_CI_LIST = 'CHANGE_LIMIT_GDSN_CI_LIST';
export const NEXT_PAGE_GDSN_CI_LIST = 'NEXT_PAGE_GDSN_CI_LIST';
export const PREVIOUS_PAGE_GDSN_CI_LIST = 'PREVIOUS_PAGE_GDSN_CI_LIST';
export const LOAD_GDSN_CI_DETAIL = 'LOAD_GDSN_CI_DETAIL';
export const RECEIVE_GDSN_CI_DETAIL = 'RECEIVE_GDSN_CI_DETAIL';

// Filters
export const UPDATE_GDSN_CI_FILTER = 'UPDATE_GDSN_CI_FILTER';

export const DOWNLOAD_GDSN_DOCUMENT = 'DOWNLOAD_GDSN_DOCUMENT';

export const GDSN_CI_OPEN_CIHW_MODAL = 'GDSN_CI_OPEN_CIHW_MODAL';
export const GDSN_CI_CLOSE_CIHW_MODAL = 'GDSN_CI_CLOSE_CIHW_MODAL';
export const GDSN_CI_PUSH_HIERARCHY_WITHDRAWAL =
  'GDSN_CI_PUSH_HIERARCHY_WITHDRAWAL';
