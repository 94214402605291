export const ORGANIZATIONS_GET = 'organizations/ORGANIZATIONS_GET';
export const ORGANIZATIONS_RECEIVE = 'organizations/ORGANIZATIONS_RECEIVE';
export const ORGANIZATIONS_NEXT_PAGE = 'organizations/ORGANIZATIONS_NEXT_PAGE';
export const ORGANIZATIONS_PREVIOUS_PAGE =
  'organizations/ORGANIZATIONS_PREVIOUS_PAGE';
export const ORGANIZATIONS_SEARCH = 'organizations/ORGANIZATIONS_SEARCH';
export const ORGANIZATIONS_CHANGE_LIMIT =
  'organizations/ORGANIZATIONS_CHANGE_LIMIT';
export const ORGANIZATIONS_FILTERS_ADD_SINGLE =
  'organizations/ORGANIZATIONS_FILTERS_ADD_SINGLE';
export const ORGANIZATIONS_FILTERS_REMOVE_SINGLE =
  'organizations/ORGANIZATIONS_FILTERS_REMOVE_SINGLE';
export const ORGANIZATIONS_FILTERS_CLEAR =
  'organizations/ORGANIZATIONS_FILTERS_CLEAR';
export const ORGANIZATIONS_ARE_LOADING =
  'organizations/ORGANIZATIONS_ARE_LOADING';
