import { flow, get } from 'lodash/fp';
import { OrganizationSSOState } from '../types';
import { SSO_REDUCER_KEY, ORGANIZATION_PAGE_STORE_KEY } from '../constants';

const selectState: (state: any) => OrganizationSSOState = get([
  'module',
  ORGANIZATION_PAGE_STORE_KEY,
  SSO_REDUCER_KEY,
]);

export const selectSSOConfiguration = flow(selectState, (state) => state.sso);
export const selectIsLoading = flow(selectState, (state) => state.isLoading);
