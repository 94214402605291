import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { MultipleSelector } from '@alkem/react-ui-select';

export default class SearchableFilter extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    filters: ImmutablePropTypes.list.isRequired,
    selection: ImmutablePropTypes.map.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.onSelect = this.onSelect.bind(this);
  }

  onSelect({ value }, path, selected) {
    const { onChange, filters } = this.props;
    onChange(
      filters.find((filter) => filter.get('id') === value),
      selected
    );
  }

  formatFilters() {
    const { id, filters } = this.props;
    return filters
      .map((filter) => ({
        key: `${id}-${filter.get('id')}`,
        label: filter.get('name') || filter.get('label'),
        value: filter.get('id'),
      }))
      .toJS();
  }

  formatSelection() {
    const { filters, selection } = this.props;
    return selection
      .keySeq()
      .map((filterId) => [
        filters.findIndex((filter) => filter.get('id') === filterId),
      ])
      .toJS();
  }

  render() {
    const { id, title } = this.props;
    return (
      <div className="ReactUiFilter__filter ReactUiFilter__filter--withPadding">
        <h3 className="ReactUiFilter__filterTitle">{title}</h3>
        <MultipleSelector
          id={id}
          options={this.formatFilters()}
          selection={this.formatSelection()}
          onSelect={this.onSelect}
          emptyLabel="Not found"
          placeholder=""
          noToggleIcon
          scrollable
        />
      </div>
    );
  }
}
