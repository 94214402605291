import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import * as routes from 'constants/routes';
import { logout } from 'modules/auth/actions';
import authApi from 'resources/authApi';

export class LogoutView extends React.Component {
  state = {
    loggedOut: false,
  };

  async componentDidMount() {
    await authApi.UserLogout();
    this.props.logout();
    this.setLoggedOut();
  }

  setLoggedOut = () => {
    this.setState({ loggedOut: true });
  };

  render() {
    if (this.state.loggedOut) {
      return <Redirect to={{ pathname: routes.home }} />;
    }
    return <div />;
  }
}

LogoutView.propTypes = {
  logout: PropTypes.func.isRequired,
};

export default connect(null, { logout })(LogoutView);
