import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { HeaderLayout } from '@alkem/react-layout';

import * as routes from 'constants/routes';

import GDSNRCIList from '../list';
import GDSNRCIListFilters from '../filters';

export class GDSNRCIDashboardView extends PureComponent {
  render() {
    const headerProps = {
      title: 'GDSN Item registry (RCI)',
      backHref: routes.home,
      backMessage: 'Back home',
      isTitleSmall: true,
    };

    return (
      <div className="GDSNRCIDashboardView__body">
        <HeaderLayout {...headerProps} />
        <div className="container-fluid row">
          <div className="col-md-3">
            <GDSNRCIListFilters />
          </div>
          <div className="col-md-9">
            <GDSNRCIList />
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(GDSNRCIDashboardView);
