import { Modal } from '@alkem/react-ui-modal';
import { Spinner } from '@alkem/react-ui-spinner';
import { CodeEditor } from 'components/code-editor';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  closePotentialSupplierModal,
  generatePotentialSupplierExportFile,
} from '../actions';
import {
  selectIsPotentialSupplierExportFileInProgress,
  selectIsPotentialSupplierModalOpen,
  selectPotentialSupplierModalParameters,
} from '../selectors';
import './modal.scss';

export const ExportPotentialSupplierFileModal = () => {
  const dispatch = useDispatch();
  const isPotentialSupplierOpen = useSelector<any, boolean>(
    selectIsPotentialSupplierModalOpen
  );
  const startingExportParameters = useSelector(
    selectPotentialSupplierModalParameters
  );
  const isPotentialSupplierExportFileInProgress = useSelector<any, boolean>(
    selectIsPotentialSupplierExportFileInProgress
  );
  const initialConfigValue = JSON.stringify(
    startingExportParameters,
    null,
    '\t'
  );

  const [configurationExport, setConfigurationExport] =
    useState(initialConfigValue);
  const onChange = (newConfigurationExport) => {
    setConfigurationExport(newConfigurationExport);
  };

  const onClose = () => {
    dispatch(closePotentialSupplierModal());
  };
  const exportPotentialSupplierFile = () => {
    if (isPotentialSupplierExportFileInProgress) {
      return;
    }
    dispatch(
      generatePotentialSupplierExportFile(JSON.parse(configurationExport))
    );
  };

  const bodyconfig = () => {
    return (
      <div className="ExportFileModal_Body">
        <CodeEditor
          name="export-configuration"
          className="ReplayDetailsModal_Parameters"
          value={configurationExport}
          mode="json"
          theme="monokai"
          width="100%"
          height="100%"
          wrapEnabled
          editorProps={{ $blockScrolling: Infinity }}
          onChange={onChange}
        />
      </div>
    );
  };

  const bodyPoll = () => {
    return (
      <div className="ExportFileModal_Body_Poll">
        <div>
          <Spinner loading big />
        </div>
        <div>Your file is being created... :)</div>
      </div>
    );
  };
  if (!isPotentialSupplierOpen) {
    return null;
  }
  return (
    <Modal
      title="Export potential suppliers (experimental)"
      modalStyle="default"
      confirmButtonText="Export"
      onConfirm={exportPotentialSupplierFile}
      isProcessing={isPotentialSupplierExportFileInProgress}
      onClose={onClose}
    >
      {isPotentialSupplierExportFileInProgress ? bodyPoll() : bodyconfig()}
    </Modal>
  );
};
