import { Modal } from '@alkem/react-ui-modal';
import {
  ORGANIZATION_TYPE_MAPPING,
  STATUS_ACTIVE,
} from 'constants/organization';
import * as routes from 'constants/routes';
import {
  validationClearSelectedRules,
  validationDeleteRuleset,
  validationResetPathValueFromFilters,
  validationResetRuleSetFromStore,
} from 'modules/validation-dashboard/actions';
import { ruleEntityTypesMap } from 'modules/validation-dashboard/constants';
import { selectValidationRuleSet } from 'modules/validation-dashboard/selectors';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const DeleteRulesetValidationModal = ({ onClose, closeParentModal }) => {
  const dispatch = useDispatch();

  useEffect(
    () => () => {
      dispatch(validationResetRuleSetFromStore());
    },
    [dispatch]
  );

  const rulesetToDelete = useSelector(selectValidationRuleSet);

  const organizationsLinked = rulesetToDelete.linked_organizations;
  const rulesLinked = rulesetToDelete.rules;

  const confirmDeleteRuleset = () => {
    onClose();
    closeParentModal();
    dispatch(
      validationResetPathValueFromFilters({
        path: 'ruleSetIdIn',
      })
    );
    dispatch(validationClearSelectedRules());
    dispatch(validationDeleteRuleset(rulesetToDelete.id));
  };

  return (
    <Modal
      title={'Delete rule set ' + rulesetToDelete.label}
      modalStyle="default"
      onClose={onClose}
      onConfirm={() => confirmDeleteRuleset()}
      confirmDisabled={!!organizationsLinked?.length}
      className="DeleteRulesetValidationModal"
      confirmButtonText={'Delete ruleset'}
      data-testid="DeleteRulesetValidationModal"
    >
      <div className="DeleteRulesetValidationModal__modalBody">
        {!organizationsLinked?.length && (
          <div>
            <div>Do you really want to delete this ruleset?</div>
            <div>
              The deletion will be effective immediately and cannot be reverted.
            </div>
            {!!rulesLinked?.length && (
              <div className="DeleteRulesetValidationModal__modalBodyTextDanger">
                <span>
                  The {rulesLinked.length} following rules depend on this
                  ruleset and WILL ALSO be deleted:
                </span>
                <ul>
                  {rulesLinked.map((rule) => (
                    <li
                      key={`DeleteRulesetValidationModal__rule_id__${rule.id}`}
                    >
                      Rule {rule.id}: {rule.errorMessage} -{' '}
                      {rule.entityType !== undefined
                        ? ruleEntityTypesMap[rule.entityType].label
                        : 'unknown entity type'}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}

        <div>
          {!!organizationsLinked?.length && (
            <div className="DeleteRulesetValidationModal__modalBodyTextDanger">
              <span>
                You can't delete this ruleset because these organizations are
                assigned to it:
              </span>
              <ul>
                {organizationsLinked.map((orga) => (
                  <li
                    key={`DeleteRulesetValidationModal__organization_id__${orga.id}`}
                  >
                    <Link
                      to={`${routes.organizationUsesFields}?organization_id=${orga.id}`}
                    >
                      {[
                        orga.nameLegal,
                        orga.id,
                        ORGANIZATION_TYPE_MAPPING[orga.type]?.name,
                        orga.status === STATUS_ACTIVE ? 'Active' : 'Inactive',
                        `Network: ${orga.network?.name}`,
                      ].join(' - ')}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export { DeleteRulesetValidationModal };
