import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { Map, fromJS, List } from 'immutable';

import { Button } from '@alkem/react-ui-button';

import { separateActions } from 'utils/redux';
import { READONLY_FIELDS } from 'constants/organization-settings';

import FieldsReadOnlySettingModal from './modal';
import Section from '../section';
import LabeledCheckbox from '../controllers/checkbox';
import { selectEditedOrSourceSetting } from '../../../selectors/settings';
import { updateSettingValue } from '../../../actions/settings';

import './index.scss';

const mapStateToProps = (state) => ({
  readOnlyFieldSetting: selectEditedOrSourceSetting(state, [READONLY_FIELDS]),
});

const mapDispatchToProps = {
  updateSettingValue,
};

export class FieldsReadOnlySetting extends PureComponent {
  static propTypes = {
    isReadOnly: PropTypes.bool,
    readOnlyFieldSetting: ImmutablePropTypes.map,
    actions: PropTypes.shape({
      updateSettingValue: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = {
    editModalOpened: false,
  };

  onCheckboxChange = (checked) => {
    const { actions } = this.props;
    let value = Map();
    if (checked) {
      value = fromJS({
        add_field_ids: [],
        add_field_tags: [],
        add_custom: [],
        remove_field_ids: [],
        remove_field_tags: [],
      });
    }
    actions.updateSettingValue([READONLY_FIELDS], value);
  };

  closeEditModal = () => this.setState({ editModalOpened: false });

  openEditModal = () => this.setState({ editModalOpened: true });

  renderConfigLine(label, settingAttr) {
    const { readOnlyFieldSetting } = this.props;
    const list = readOnlyFieldSetting.get(settingAttr) || List();
    if (list.isEmpty()) {
      return null;
    }
    return (
      <li key={settingAttr}>
        {label}: <strong>{list.join(', ')}</strong>
      </li>
    );
  }

  renderConfig() {
    const includes = [
      this.renderConfigLine('Ids', 'add_field_ids'),
      this.renderConfigLine('Tags', 'add_field_tags'),
      this.renderConfigLine('Custom', 'add_custom'),
    ].filter((x) => !!x);
    const excludes = [
      this.renderConfigLine('Ids', 'remove_field_ids'),
      this.renderConfigLine('Tags', 'remove_field_tags'),
    ].filter((x) => !!x);

    return (
      <div className="OrganizationPageSettingReadOnlyField__config">
        <div>
          <strong>Include</strong> (read-only)
        </div>
        <ul>{includes.length ? includes : <li>None</li>}</ul>
        <div>
          <strong>Exclude</strong> (read-write)
        </div>
        <ul>{excludes.length ? excludes : <li>None</li>}</ul>
      </div>
    );
  }

  render() {
    const { readOnlyFieldSetting, isReadOnly } = this.props;
    const { editModalOpened } = this.state;
    const hasReadOnlyField = !!(
      readOnlyFieldSetting && !readOnlyFieldSetting.isEmpty()
    );
    return (
      <Section label="Fields">
        <div className="OrganizationPageSettingReadOnlyField">
          <div className="OrganizationPageSettingReadOnlyField__title">
            <LabeledCheckbox
              onChange={this.onCheckboxChange}
              checked={hasReadOnlyField}
              label="Readonly configuration"
              disabled={isReadOnly}
            />
            {!isReadOnly && hasReadOnlyField && (
              <Button
                content="Edit"
                onClick={this.openEditModal}
                secondary
                link
              />
            )}
          </div>
          {hasReadOnlyField && this.renderConfig()}
        </div>
        {editModalOpened && (
          <FieldsReadOnlySettingModal onClose={this.closeEditModal} />
        )}
      </Section>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(FieldsReadOnlySetting);
