import { notificationSuccess } from 'actions/notifications';
import { pick } from 'lodash/fp';
import qs from 'querystringify';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import history from 'utils/history';
import { withCatch } from 'utils/saga';
import {
  loadProduct,
  reindexProduct,
  resyncProduct,
  saveProduct,
  triggerReview,
} from './api';
import {
  LOAD_PRODUCT,
  maintainableFieldNames,
  PRODUCT_LOADED,
  REINDEX,
  REINDEX_FAILURE,
  REINDEX_SUCCESS,
  RESYNC,
  RESYNC_FAILURE,
  RESYNC_SUCCESS,
  REVIEW,
  REVIEW_FAILURE,
  REVIEW_SUCCESS,
  SAVE,
  SAVE_FAILURE,
  SAVE_SUCCESS,
} from './constants';
import { selectProduct, selectProductKeyId, selectReason } from './selectors';

function* loadProductSaga({ productKeyId }) {
  history.replace({
    pathname: history.location.pathname,
    search: productKeyId ? qs.stringify({ productKeyId }) : '',
  });
  if (!productKeyId) {
    yield put({ type: PRODUCT_LOADED, product: {} });
    return;
  }

  const { product } = yield call(
    loadProduct,
    productKeyId,
    [
      'product_key',
      'gtin',
      'namePublicLong.data',
      'owner.id',
      'tags.targetMarket.id',
      'manufacturerInfo.id',
    ].concat(maintainableFieldNames)
  );
  yield put({ type: PRODUCT_LOADED, product });
}

function* saveSaga() {
  const product = yield select(selectProduct);
  const reason = yield select(selectReason);

  const productData = {
    key: product.product_key,
    data: {
      productVersion: {
        data: pick(['tags'].concat(maintainableFieldNames), product),
      },
    },
  };

  yield call(saveProduct, productData, reason);
  yield put(notificationSuccess('It is saved!'));
  yield put({ type: SAVE_SUCCESS });
}

function* reindexSaga() {
  const keyId = yield select(selectProductKeyId);
  yield call(reindexProduct, keyId);
  yield put(notificationSuccess('It will be reindexed!'));
  yield put({ type: REINDEX_SUCCESS });
}

function* resyncSaga() {
  const keyId = yield select(selectProductKeyId);
  yield call(resyncProduct, keyId);
  yield put(notificationSuccess('It will be resynced!'));
  yield put({ type: RESYNC_SUCCESS });
}

function* triggerReviewSaga() {
  const keyId = yield select(selectProductKeyId);
  yield call(triggerReview, keyId);
  yield put(notificationSuccess('It will be reviewed!'));
  yield put({ type: REVIEW_SUCCESS });
}

export default function* mainSaga() {
  yield takeLatest(
    [LOAD_PRODUCT],
    withCatch(loadProductSaga, { withNotification: true })
  );
  yield takeLatest(
    [SAVE],
    withCatch(saveSaga, { withNotification: true, errorAction: SAVE_FAILURE })
  );
  yield takeLatest(
    [REINDEX],
    withCatch(reindexSaga, {
      withNotification: true,
      errorAction: REINDEX_FAILURE,
    })
  );
  yield takeLatest(
    [RESYNC],
    withCatch(resyncSaga, {
      withNotification: true,
      errorAction: RESYNC_FAILURE,
    })
  );
  yield takeLatest(
    [REVIEW],
    withCatch(triggerReviewSaga, {
      withNotification: true,
      errorAction: REVIEW_FAILURE,
    })
  );
}
