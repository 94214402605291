import { remove as removeDiacritics } from 'diacritics';

const isString = (value: any) => typeof value === 'string';
const isNumber = (value: any) => typeof value === 'number';

function isValid(value: any) {
  return isString(value) || isNumber(value);
}

type StringOrNumber = string | number;

export function sortDesc(
  valueA: StringOrNumber,
  valueB: StringOrNumber,
  check = true
) {
  if (check) {
    // invalid objects will be sorted to the end of the array
    if (!isValid(valueA)) {
      return 1;
    }
    if (!isValid(valueB)) {
      return -1;
    }
  }
  // NB: String.localeCompare is not supported in Safari <= 9
  // So to handle special characters, diacritics are removed
  const hasString = isString(valueA) || isString(valueB);
  const _valueA = hasString
    ? removeDiacritics(`${valueA}`.toLowerCase())
    : valueA;
  const _valueB = hasString
    ? removeDiacritics(`${valueB}`.toLowerCase())
    : valueB;
  if (_valueA === _valueB) {
    return 0;
  }
  return _valueA < _valueB ? 1 : -1;
}

export function sortAsc(valueA: StringOrNumber, valueB: StringOrNumber) {
  // invalid objects will be sorted to the end of the array
  if (!isValid(valueA)) {
    return 1;
  }
  if (!isValid(valueB)) {
    return -1;
  }
  return sortDesc(valueB, valueA, false);
}
