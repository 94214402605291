import { selectMainState } from '.';
import { MODAL_REDUCER_KEY } from '../constants';

export function selectModalState(state) {
  return selectMainState(state)[MODAL_REDUCER_KEY];
}

export function selectIsModalOpen(state) {
  return selectModalState(state).get('isOpen');
}

export function selectIsModalLoading(state) {
  return selectModalState(state).get('isLoading');
}
