import { Button, SwitchButton } from '@alkem/react-ui-button';
import { Text } from '@alkem/react-ui-inputs';
import { Modal } from '@alkem/react-ui-modal';
import { Select, SimpleSelect } from '@alkem/react-ui-select';
import { LazyTooltip } from '@alkem/react-ui-tooltip';
import { ResponseWithBody } from '@alkem/sdk-dashboard';
import { notificationError, notificationSuccess } from 'actions/notifications';
import CancelablePromise from 'cancelable-promise';
import classNames from 'classnames';
import { FieldAutocomplete } from 'components/autocomplete';
import DateInput from 'components/date-input';
import { CONSUMER_UNIT } from 'constants/fields';
import {
  validationFetchRuleSet,
  validationFetchRuleSetTags,
} from 'modules/validation-dashboard/actions';
import {
  ruleSetDeadlineTypes,
  ruleSetTypes,
  VALIDATION_RULESET_TYPE_DEADLINE__DYNAMIC,
  VALIDATION_RULESET_TYPE_DEADLINE__STATIC,
  VALIDATION_RULESET_TYPE__MATURITY,
  VALIDATION_RULESET_TYPE__STAGE_GATE,
} from 'modules/validation-dashboard/constants';
import { selectRuleSetTags } from 'modules/validation-dashboard/selectors';
import { RuleSet, RuleSetTag } from 'modules/validation-dashboard/types';
import { ChangeEvent, ReactNode, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validationApi } from 'resources/validationApi';
import { DeleteRulesetValidationModal } from './delete-rule-set-modal';
import './rule-set-modal.scss';
import { RuleSetInEdition } from './types';
import {
  initRuleSetInEdition,
  makePostableRuleSet,
  ruleSetIsIncomplete,
  ruleSetToRuleSetInEdition,
} from './utils';

interface CreationProps {
  // either creation went fine
  // either it was aborted => createdRuleSet === undefined
  onClose: (createdRuleSet?: RuleSet) => void;
}

interface EditionProps {
  // either edition went fine
  // either it was aborted => updatedRuleSet === undefined
  onClose: (updatedRuleSet?: RuleSet) => void;
  ruleSetToEdit: RuleSet;
}

interface RuleSetModalProps {
  onClose: (ruleSet?: RuleSet) => void;
  onCloseValidation?: (boolean) => void;
  title: string;
  buttonText: string;
  ruleSetInEdition: RuleSetInEdition;
  disableTypeField: boolean;
  isOpenDeleteValidationModal?: boolean;
  additionalFooterContent?: ReactNode;
  saveRuleset: (
    ruleSet: RuleSetInEdition
  ) => CancelablePromise<ResponseWithBody<any>>;
}
interface StageGateProps {
  ruleSet: RuleSetInEdition;
  handleDeadlineTypeChange: ({
    key,
  }: (typeof ruleSetDeadlineTypes)[number]) => void;
  handleDeadlineDateChange: ({ label }) => void;
  handleDeadlineDeltaChange: ({ currentTarget }: ChangeEvent<Element>) => void;
  handleDeadlineFieldChange: ({ label }) => void;
}

const StageGateFields = (props: StageGateProps) => {
  const {
    ruleSet,
    handleDeadlineTypeChange,
    handleDeadlineDateChange,
    handleDeadlineDeltaChange,
    handleDeadlineFieldChange,
  } = props;

  const isDeadlineTypeStatic =
    ruleSet.deadline_type === VALIDATION_RULESET_TYPE_DEADLINE__STATIC;
  const isDeadlineTypeDynamic =
    ruleSet.deadline_type === VALIDATION_RULESET_TYPE_DEADLINE__DYNAMIC;

  const ruleSetDeadlineTypeOptions = useMemo(
    () => Object.values(ruleSetDeadlineTypes),
    []
  );

  const ruleSetDeadlineTypeSelectedOption = ruleSetDeadlineTypeOptions.find(
    ({ key }) => key === ruleSet.deadline_type
  );

  const filterFields = (field) => field.value.get('type') === 'date';

  return (
    <>
      <div
        className={classNames('row', 'RuleSetModal-deadline', {
          'RuleSetModal-deadline-dynamic': isDeadlineTypeDynamic,
        })}
      >
        <div className="col-md-2" data-testid="rule-set-modal--deadline-type">
          <label htmlFor="rule-set-modal-deadline-type">
            Deadline type{' '}
            <LazyTooltip
              id={'deadline-help-tooltip'}
              tooltipLabel="<div>For a dynamic date, select 'dynamic' and choose the deadline delta and associated field.</div>
              <div>Indicate the amount of days the deadline, for which the retailer has to receive the data, should be calculated from and
              which attribute should be the reference date for the calculation of the deadline.</div>
              <div>Example → 30 days before the first shipping date the retailer needs the product information.</div>"
            >
              <i className="mdi mdi-help-circle" />
            </LazyTooltip>
          </label>
          <SimpleSelect
            autoSize
            id="rule-set-modal-deadline-type"
            value={ruleSetDeadlineTypeSelectedOption}
            onSelect={handleDeadlineTypeChange}
            options={ruleSetDeadlineTypeOptions}
          />
        </div>
        {isDeadlineTypeStatic && (
          <div className="col-md-4" data-testid="rule-set-modal--deadline-date">
            <label htmlFor="rule-set-modal-deadline-date">Date</label>
            <DateInput
              id="rule-set-modal-deadline-date"
              title=""
              value={ruleSet.deadline_date}
              onSelect={handleDeadlineDateChange}
              onUnselect={handleDeadlineDateChange}
            />
          </div>
        )}
        {isDeadlineTypeDynamic && (
          <>
            <div className="col-md-auto">The rule set deadline is </div>
            <div
              className="col-md-2 RuleSetModal-deadline-delta"
              data-testid="rule-set-modal--deadline-delta"
            >
              <div>
                <Text
                  id="rule-set-modal-deadline-delta"
                  value={ruleSet.deadline_delta || ''}
                  type="number"
                  placeholder="Delta..."
                  onChange={handleDeadlineDeltaChange}
                />
              </div>
              <div>day(s)</div>
            </div>
            <div className="col-md-auto">before</div>
            <div
              className="col-md-4"
              data-testid="rule-set-modal--deadline-field"
            >
              <FieldAutocomplete
                id="rule-set-modal-deadline-field"
                placeholder="Select a date field..."
                value={[{ label: ruleSet.deadline_field }]}
                onSelect={handleDeadlineFieldChange}
                entityType={CONSUMER_UNIT}
                searchOnClick
                filterFunc={filterFields}
                attributes={['type']}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

const RuleSetModal = (props: RuleSetModalProps) => {
  const {
    onClose,
    saveRuleset,
    title,
    buttonText,
    ruleSetInEdition,
    disableTypeField,
    additionalFooterContent,
  } = props;

  const dispatch = useDispatch();

  const ruleSetTags = useSelector(selectRuleSetTags);

  useEffect(() => {
    dispatch(validationFetchRuleSetTags());
  }, [dispatch]);

  const ruleSetTypeOptions = useMemo(
    () =>
      Object.values(ruleSetTypes).map(({ value, label }) => ({
        key: value,
        label,
      })),
    []
  );

  const [ruleSet, setRuleSet] = useState<RuleSetInEdition>(ruleSetInEdition);

  const ruleSetTypeSelectedOption = ruleSetTypeOptions.find(
    ({ key }) => key === ruleSet.type
  );

  const ruleSetTagOptions: RuleSetTag[] = useMemo(() => {
    if (Array.isArray(ruleSetTags)) {
      return ruleSetTags.filter(
        (ruleSetTag: RuleSetTag) => !ruleSet.tagCodes.includes(ruleSetTag.code)
      );
    } else {
      return [];
    }
  }, [ruleSetTags, ruleSet]);

  const ruleSetTagSelectedOptions: RuleSetTag[] = useMemo(() => {
    if (Array.isArray(ruleSetTags)) {
      return ruleSet.tagCodes.map(
        (tagCode: string) =>
          ruleSetTags.find((tag: RuleSetTag) => tagCode === tag.code) ||
          ({} as RuleSetTag)
      );
    } else {
      return [];
    }
  }, [ruleSetTags, ruleSet]);

  const handleChange = (prop: string, value: unknown) =>
    setRuleSet({ ...ruleSet, [prop]: value });

  const handleNameChange = ({ currentTarget }: ChangeEvent<Element>) =>
    handleChange('name', (currentTarget as HTMLInputElement).value);

  const handleRuleSetTypeChange = ({
    key,
  }: (typeof ruleSetTypeOptions)[number]) => handleChange('type', key);

  const handleRuleSetTagAdd = (tag: RuleSetTag) => {
    // ...new Set(...) handle duplicates in the new list created
    handleChange('tagCodes', [...new Set([...ruleSet.tagCodes, tag.code])]);
  };

  const handleRuleSetTagRemove = (index: number) => {
    ruleSet.tagCodes.splice(index, 1);
    handleChange('tagCodes', ruleSet.tagCodes);
  };

  // STAGE GATE
  const isStageGateRuleSet =
    ruleSet.type === VALIDATION_RULESET_TYPE__STAGE_GATE;

  const handleDeadlineTypeChange = ({
    key,
  }: (typeof ruleSetDeadlineTypes)[number]) =>
    handleChange('deadline_type', key);

  const handleDeadlineDateChange = ({ label }) =>
    handleChange('deadline_date', label);

  const handleDeadlineDeltaChange = ({ currentTarget }: ChangeEvent<Element>) =>
    handleChange('deadline_delta', (currentTarget as HTMLInputElement).value);

  const handleDeadlineFieldChange = ({ label }) =>
    handleChange('deadline_field', label);

  // MATURITY
  const isMaturityRuleSet = ruleSet.type === VALIDATION_RULESET_TYPE__MATURITY;

  const handleDisplayableChange = (isSelected: boolean) =>
    handleChange('displayable', isSelected);

  // ACTIONS
  const handleConfirm = async () => {
    try {
      const { data: savedRuleSet } = await saveRuleset(ruleSet);
      dispatch(
        notificationSuccess(
          `The rule set ${ruleSet.name} has been saved successfully.`
        )
      );
      onClose(savedRuleSet);
    } catch (error) {
      const typedError = error as ResponseWithBody<{ message: string }>;
      dispatch(
        notificationError(
          `An error occured while saving the rule set. ${
            typedError?.data?.message || 'Unknown error.'
          }`
        )
      );
    }
  };

  const handleAbort = () => onClose();

  const displayableSwitchContent = (
    <>
      <span>Displayable </span>
      <LazyTooltip
        id={'displayable-help-tooltip'}
        tooltipLabel="By checking displayable, the manufacturer will have visibility on the scenario requested by the retailer. The name of the scenario will be displayed under the rule error message and in the ruleset filter."
      >
        <i className="mdi mdi-help-circle" />
      </LazyTooltip>
    </>
  );

  return (
    <Modal
      title={title}
      modalStyle="default"
      onClose={handleAbort}
      onConfirm={handleConfirm}
      confirmDisabled={ruleSetIsIncomplete(ruleSet)}
      className="RuleSetModal"
      confirmButtonText={buttonText}
      additionalFooterContent={additionalFooterContent}
    >
      <div className="RuleSetModal__modalBody">
        <div className="row">
          <div className="col-md-12" data-testid="rule-set-modal--name">
            <label htmlFor="InputText-rule-set-modal-name">Name</label>
            <Text
              id="rule-set-modal-name"
              value={ruleSet.name}
              placeholder={'This field is required'}
              onChange={handleNameChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6" data-testid="rule-set-modal--type">
            <label htmlFor="rule-set-modal-type">Type</label>
            <SimpleSelect
              autoSize
              id="rule-set-modal-type"
              value={ruleSetTypeSelectedOption}
              onSelect={handleRuleSetTypeChange}
              options={ruleSetTypeOptions}
              disabled={disableTypeField}
            />
          </div>
          <div className="col-md-6" data-testid="rule-set-modal--tags">
            <label htmlFor="rule-set-modal-tags">Tags</label>
            <Select
              id="rule-set-modal-tags"
              placeholder="Select tags"
              options={ruleSetTagOptions}
              values={ruleSetTagSelectedOptions}
              onValueAdd={handleRuleSetTagAdd}
              onValueDelete={handleRuleSetTagRemove}
              multiple
            />
          </div>
        </div>
        {isMaturityRuleSet && (
          <div className="row">
            <div className="col-md-6" data-testid="rule-set-modal--displayable">
              <SwitchButton
                content={displayableSwitchContent}
                checked={ruleSet.displayable}
                onChange={handleDisplayableChange}
              />
            </div>
          </div>
        )}
        {isStageGateRuleSet && (
          <StageGateFields
            ruleSet={ruleSet}
            handleDeadlineTypeChange={handleDeadlineTypeChange}
            handleDeadlineDateChange={handleDeadlineDateChange}
            handleDeadlineDeltaChange={handleDeadlineDeltaChange}
            handleDeadlineFieldChange={handleDeadlineFieldChange}
          />
        )}
      </div>
    </Modal>
  );
};

export const RuleSetEditionModal = (props: EditionProps) => {
  const { onClose, ruleSetToEdit } = props;
  const dispatch = useDispatch();

  const [isOpenDeleteValidationModal, setOpenDeleteValidationModal] =
    useState(false);

  const title = 'Rule set edition';
  const buttonText = 'Edit rule set';

  const ruleSetInEdition = ruleSetToRuleSetInEdition(ruleSetToEdit);

  const saveRuleset = (ruleSet: RuleSetInEdition) =>
    validationApi.updateRuleSet(ruleSetToEdit.id, makePostableRuleSet(ruleSet));

  const deleteRulesetButton = (
    <Button
      warning
      content="Delete the ruleset"
      onClick={() => {
        setOpenDeleteValidationModal(true);
        dispatch(validationFetchRuleSet(ruleSetToEdit.id));
      }}
    />
  );

  return (
    <>
      <RuleSetModal
        onClose={onClose}
        title={title}
        buttonText={buttonText}
        ruleSetInEdition={ruleSetInEdition}
        disableTypeField={
          ruleSetToEdit.type === VALIDATION_RULESET_TYPE__MATURITY
        }
        saveRuleset={saveRuleset}
        additionalFooterContent={deleteRulesetButton}
      />
      {isOpenDeleteValidationModal && (
        <DeleteRulesetValidationModal
          onClose={() => setOpenDeleteValidationModal(false)}
          closeParentModal={onClose}
        />
      )}
    </>
  );
};

export const RuleSetCreationModal = (props: CreationProps) => {
  const { onClose } = props;

  const title = 'Rule set creation';
  const buttonText = 'Create rule set';

  const ruleSetInEdition = initRuleSetInEdition();

  const saveRuleset = (ruleSet: RuleSetInEdition) =>
    validationApi.createRuleSet(makePostableRuleSet(ruleSet));
  return (
    <RuleSetModal
      onClose={onClose}
      title={title}
      buttonText={buttonText}
      ruleSetInEdition={ruleSetInEdition}
      disableTypeField={false}
      saveRuleset={saveRuleset}
    />
  );
};
