const isSpecialNode = (id) =>
  id === 'manualInput' || id === 'constants' || id === 'funcs';

const formatNodeId = (nodeId) => JSON.stringify(nodeId);

export const sortEdgesBySource = (edges) =>
  edges.reduce((acc, e) => {
    if (isSpecialNode(e.sourceId)) {
      return acc;
    }

    const l = acc[e.sourceId] || [];
    if (l.indexOf(e.targetId) !== -1) {
      return acc;
    }

    l.push(e.targetId);
    acc[e.sourceId] = l;
    return acc;
  }, {});

export const sortEdgesByTarget = (edges) =>
  edges.reduce((acc, e) => {
    if (isSpecialNode(e.sourceId)) {
      return acc;
    }

    const l = acc[e.targetId] || [];
    if (l.indexOf(e.sourceId) !== -1) {
      return acc;
    }

    l.push(e.sourceId);
    acc[e.targetId] = l;
    return acc;
  }, {});

const computeNodeDepths = (graph) => {
  const edgesBySource = sortEdgesBySource(graph.edges);
  const edgesByTarget = sortEdgesByTarget(graph.edges);

  // Now we compute the depth of each node
  const depths = {};
  const visited = {};
  const computeDepth = (nodeId) => {
    // Flag the current node
    visited[nodeId] = true;
    const d = (edgesByTarget[nodeId] || []).reduce(
      (acc, id) => ((id in depths ? depths[id] : -1) > acc ? depths[id] : acc),
      -1
    );
    depths[nodeId] = d + 1;

    //
    if (edgesBySource[nodeId]) {
      edgesBySource[nodeId].forEach((nextId) => {
        const ok = edgesByTarget[nextId].reduce(
          (acc, nId) => Boolean(acc && visited[nId]),
          true
        );
        if (!ok) {
          return;
        }

        computeDepth(nextId, d + 1);
      });
    }
  };
  computeDepth('inputs', 0);

  return depths;
};

const esc = (s) => s.replace(/"/g, '');

const sortNodesByDepth = (graph, depths) => {
  const nodesById = graph.nodes.reduce((acc, n) => {
    acc[n.id] = n;
    return acc;
  }, {});

  return Object.keys(depths).reduce((acc, nodeId) => {
    const depth = depths[nodeId];
    if (!acc[depth]) {
      acc[depth] = [];
    }

    acc[depth].push(nodesById[nodeId] || { id: nodeId });
    return acc;
  }, {});
};

const formatNode = (node) => {
  const nodeId = formatNodeId(node.id);
  if (!node.type) {
    return `${nodeId} [id="${node.id}"]`;
  }
  if (node.type === 'group') {
    return `${nodeId} [id="${node.id}", shape="hexagon"]`;
  }

  const borderColor = '#000000';

  // @TODO: replace with a smartly selected color
  // -> that requires sending all the nodes to the D3Graph
  let label = node.id;
  if (node.dir === 'mocked') {
    label = `!! ${label} !!`;
  }

  const bgcolor = node.bgcolor || '#4DCBD8';

  const table = `
<TABLE BORDER="0" CELLBORDER="1" CELLSPACING="0" COLOR="${borderColor}">
  <TR>
    <TD ID="title${node.id}" BGCOLOR="${bgcolor}"><FONT COLOR="${borderColor}">
        ${label}
    </FONT></TD>
  </TR>
  <TR><TD><FONT COLOR="${borderColor}">${node.pkg}.${node.type}</FONT></TD></TR>
</TABLE>
    `;
  return `${nodeId} [ shape=none, margin=0, id="${node.id}", label=<${table}>];`;
};

const formatEdge = (edge) => {
  let style = 'solid';
  if (edge.inputType === '__wait__' || edge.inputType === '__after__') {
    style = 'dashed';
  }
  return `"${edge.sourceId}" -> "${edge.targetId}" [id="${esc(
    edge.sourceId
  )}->${esc(edge.targetId)}", style="${style}"]`;
};

export const selectNodeIds = (nodeId, graph, nodeGroup) => {
  const activeNodes = new Set([nodeId]);
  const selectPath = (nId, path) => {
    if (!path || !path[nId]) {
      return;
    }

    path[nId].forEach((targetId) => {
      activeNodes.add(targetId);
      const group = nodeGroup[targetId];
      if (group) {
        activeNodes.add(group);
      }
      selectPath(targetId, path);
    });
  };

  selectPath(nodeId, sortEdgesBySource(graph.edges));
  selectPath(nodeId, sortEdgesByTarget(graph.edges));
  return activeNodes;
};

const toDotInternal = (graph, hiddenNodes, nodeGroup) => {
  if (!graph || !graph.nodes || !graph.edges) {
    return `digraph {
    bgcolor=transparent;
  }`;
  }

  const depths = computeNodeDepths(graph);
  const nodesByDepth = sortNodesByDepth(graph, depths);

  const groupDepth = {};
  Object.keys(nodeGroup || {}).forEach((nodeId) => {
    const groupId = nodeGroup[nodeId];
    const depth = groupDepth[groupId] || 0;
    groupDepth[groupId] = depths[nodeId] > depth ? depths[nodeId] : depth;
  });

  Object.keys(groupDepth || {}).forEach((groupId) => {
    const depth = groupDepth[groupId];
    const l = nodesByDepth[depth] || [];
    l.push({
      id: `group_${groupId}`,
      type: 'group',
    });
    nodesByDepth[depth] = l;
  });

  return `
digraph {
  splines="ortho";
  bgcolor=transparent;

  ${Object.keys(nodesByDepth || {})
    .map((d) => {
      const nodes = nodesByDepth[d]
        .filter((n) => !n.skip && !hiddenNodes.includes(n.id))
        .filter((n) => !nodeGroup[n.id]);
      return `subgraph depth_${d} {
        rank=same;
      ${nodes.map((n) => formatNode(n)).join('\n')}
    }`;
    })
    .join('\n\n')}

  ${(graph.edges || [])
    .filter((e) => !isSpecialNode(e.sourceId))
    .filter((e) => !e.skip)
    .filter(
      (e) =>
        !hiddenNodes.includes(e.sourceId) && !hiddenNodes.includes(e.targetId)
    )
    .map((e) => {
      let { sourceId, targetId } = e;
      sourceId = nodeGroup[sourceId]
        ? `group_${nodeGroup[sourceId]}`
        : sourceId;
      targetId = nodeGroup[targetId]
        ? `group_${nodeGroup[targetId]}`
        : targetId;
      return { ...e, sourceId, targetId };
    })
    .filter((e) => e.sourceId !== e.targetId)
    .filter(
      (edge, i, edges) =>
        edges.findIndex(
          (o) => o.sourceId === edge.sourceId && o.targetId === edge.targetId
        ) === i
    )

    // .filter(e => !nodeGroup[e.sourceId] && !nodeGroup[e.targetId])
    .map((e) => formatEdge(e))
    .join('\n')}
}
  `;
};

const prefixed = (prefix, str) => (prefix ? `${prefix}.${str}` : str);

const colors = ['#61b3d3', '#2a9d8f', '#e9c46a', '#f4a261', '#e76f51'];

const includeSubgraphs = (graph, subgraphs) => {
  if (!graph.nodes) {
    return graph;
  }

  let additionalNodes = [];
  let additionalEdges = [];
  let idx = 0;

  const updateNode = (prefix, colorIdx) => (n) =>
    Object.assign({}, n, {
      id: prefixed(prefix, n.id),
      bgcolor: colors[colorIdx % colors.length],
    });

  for (let i = 0; i < graph.nodes.length; i += 1) {
    const node = graph.nodes[i];
    let subgraph = subgraphs[`${node.pkg}.${node.type}`];
    if (graph.nodes[i].nodes && graph.nodes[i].nodes.length > 1) {
      subgraph = graph.nodes[i];
    }
    if (subgraph) {
      node.skip = true;
      const readySubGraph = includeSubgraphs(subgraph, subgraphs);

      additionalNodes = additionalNodes.concat(
        readySubGraph.nodes.map(updateNode(node.id, idx))
      );
      additionalEdges = additionalEdges.concat(
        readySubGraph.edges
          .filter((e) => !isSpecialNode(e.sourceId))
          .map((e) =>
            Object.assign({}, e, {
              sourceId: prefixed(node.id, e.sourceId),
              targetId: prefixed(node.id, e.targetId),
            })
          )
      );

      // Remap the inputs and outputs
      graph.edges.forEach((e) => {
        if (e.targetId === node.id) {
          e.targetId = prefixed(node.id, 'inputs');
        }

        if (e.sourceId === node.id) {
          e.sourceId = prefixed(node.id, 'outputs');
        }
      });
      idx += 1;
    }
  }

  const _graph = graph;
  _graph.nodes = _graph.nodes.concat(additionalNodes);
  _graph.edges = _graph.edges.concat(additionalEdges);

  return _graph;
};

export const toDot = (graph, subgraphs, hiddenNodes, nodeGroup) =>
  toDotInternal(
    includeSubgraphs(graph, subgraphs),
    hiddenNodes || [],
    nodeGroup || {}
  );
