import { fromJS } from 'immutable';
import { createReducer } from 'redux-create-reducer';

import * as events from './events';

const initialState = {
  roots: null,
  fields: null,
  tags: null,
  sourceField: null,
  editedField: null,
  saveInProgress: false,
  shouldBlockSaveField: false,
  deleteInProgress: false,
};

const checkIfSaveFieldShouldBeBlocked = (field) => {
  return field.get('type') === 'entity' && !field.get('referential');
};

export default createReducer(initialState, {
  [events.RECEIVE_ROOTS_LIST]: (state, { roots }) => ({
    ...state,
    roots,
  }),
  [events.RECEIVE_FIELDS_LIST]: (state, { fields }) => ({
    ...state,
    fields,
  }),
  [events.RECEIVE_TAGS]: (state, { tags }) => ({
    ...state,
    tags,
  }),
  [events.RECEIVE_FIELD]: (state, { field }) => ({
    ...state,
    sourceField: field,
    editedField: field,
  }),
  [events.DISCARD_CHANGES]: (state) => ({
    ...state,
    editedField: state.sourceField,
  }),
  [events.CREATE_FIELD]: (state, { parentId }) => {
    const field = fromJS({
      name: null,
      description: null,
      specific: false,
      type: null,
      functional_key: false,
      declinable_by: null,
      referential: null,
      uri: null,
      index: false,
      nullable: true,
      enabled: false,
      private: false,
      isApplicableToLU: false,
      isApplicableToDU: false,
      displayInfo: {},
      parent: { id: parentId },
    });
    return {
      ...state,
      sourceField: null,
      editedField: field,
    };
  },
  [events.UPDATE_FIELD]: (state, { path, value }) => {
    const newEditedField = state.editedField.setIn(path, value);
    const shouldBlockSaveField =
      checkIfSaveFieldShouldBeBlocked(newEditedField);

    return {
      ...state,
      editedField: newEditedField,
      shouldBlockSaveField: shouldBlockSaveField,
    };
  },
  [events.SET_SAVE_IN_PROGRESS]: (state, { saveInProgress }) => ({
    ...state,
    saveInProgress,
  }),
  [events.SET_DELETE_IN_PROGRESS]: (state, { deleteInProgress }) => ({
    ...state,
    deleteInProgress,
  }),
});
