import PropTypes from 'prop-types';
import React from 'react';

export default function CellHistoryQuality({ data }) {
  const styleDate = {
    display: 'inline-block',
    width: '120px',
    borderRight: '1px solid',
    borderBottom: '1px solid',
    paddingLeft: '4px',
    paddingRight: '4px',
  };
  const styleNumber = {
    display: 'inline-block',
    width: '90px',
    borderRight: '1px solid',
    borderBottom: '1px solid',
    paddingLeft: '4px',
    paddingRight: '4px',
  };

  const indiv = Object.entries(data)
    .sort()
    .map((entry) => {
      const date = new Date(Number(entry[0]));
      const dateName = date.toISOString().slice(0, 10);
      const value = entry[1];
      return (
        <div key={dateName}>
          <span style={styleDate}> {dateName} </span>
          <span style={styleNumber}> {value.fail} </span>
          <span style={styleNumber}> {value.doc_count} </span>
        </div>
      );
    });

  return <div>{indiv}</div>;
}

CellHistoryQuality.propTypes = {
  data: PropTypes.object,
};
