import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import './right-panel-attributes.scss';
import './list-input-modal.scss';
import { Text } from '@alkem/react-ui-inputs';
import { Button } from '@alkem/react-ui-button';
import AttributeModal from './modal';

export default class RightPanelAttribute extends PureComponent {
  static propTypes = {
    selectedAttributeVersionList: PropTypes.array,
    onSaveAttribute: PropTypes.func,
  };

  state = {
    modalOpenEdit: false,
    editedAttribute: null,
  };

  isAttributeValidityToday(attributeToEvaluate) {
    const now = Date.now();
    if (
      !attributeToEvaluate.start_validity &&
      !attributeToEvaluate.end_validity
    ) {
      return true;
    }
    const start = Date.parse(attributeToEvaluate.start_validity);
    const end = Date.parse(attributeToEvaluate.end_validity);
    if (start <= now && now < end) {
      return true;
    }
    return false;
  }

  openModalEdit = (attribute) => {
    this.setState({ modalOpenEdit: true, editedAttribute: attribute });
  };

  closeModalEdit = () => {
    this.setState({ modalOpenEdit: false });
  };

  render() {
    const { selectedAttributeVersionList, onSaveAttribute } = this.props;
    const { modalOpenEdit } = this.state;
    if (!selectedAttributeVersionList) {
      return null;
    }
    return (
      <div>
        <table className="RightPanel__Attributes">
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Version</th>
              <th>Function</th>
              <th>Parameters</th>
              <th>Start date</th>
              <th>End date</th>
            </tr>
          </thead>
          <tbody>
            {selectedAttributeVersionList.map((e) => (
              <tr key={`right-panel-attribute-${e.id}`}>
                <td>
                  <Text
                    id={`right-panel-attribute-name-${e.id}`}
                    value={e.name}
                    disabled={true}
                  />
                </td>
                <td>
                  <Text
                    id={`right-panel-attribute-description-${e.id}`}
                    value={e.description}
                    disabled={true}
                  />
                </td>
                <td>
                  <Text
                    id={`right-panel-attribute-version-${e.id}`}
                    value={e.version}
                    disabled={true}
                  />
                </td>
                <td>
                  <div>
                    <Text
                      id={`right-panel-attribute-func-${e.id}`}
                      value={e.function === null ? '' : e.function.name}
                      disabled={true}
                    />
                  </div>
                </td>
                <td>
                  <Text
                    id={`right-panel-attribute-param-${e.id}`}
                    value={Array.prototype.map
                      .call(e.parameters, (p) => p.param)
                      .toString()}
                    disabled={true}
                  />
                </td>
                <td>
                  <Text
                    id={`right-panel-attribute-start-${e.id}`}
                    value={e.start_validity}
                    disabled={true}
                  />
                </td>
                <td>
                  <Text
                    id={`right-panel-attribute-end-${e.id}`}
                    value={e.end_validity}
                    disabled={true}
                  />
                </td>
                {this.isAttributeValidityToday(e) && (
                  <td>
                    <Button
                      primary
                      id={`AttributeEdit-${e.id}`}
                      content="Edit"
                      onClick={() => this.openModalEdit(e)}
                    />
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        {modalOpenEdit && (
          <AttributeModal
            attribute={this.state.editedAttribute}
            onClose={() => this.setState({ modalOpenEdit: false })}
            onSaveAttribute={onSaveAttribute}
          />
        )}
      </div>
    );
  }
}
