// Import
export const SUPERSEED_GDSN = 'superseedGDSN';

// Assignation
export const ASSIGNATION = 'assignation';

// Information Request
export const INFORMATION_REQUEST = 'information-request';

// Collaboration
export const RECIPIENTS_RULE = 'recipients.rule';
export const VALUE_RECIPIENT_RULE_ALL = 'all';
export const VALUE_RECIPIENT_RULE_RESTRICTED = 'restricted';
export const CHAT_ORGANIZATION_ENABLED = 'chat.organization.enabled';
export const CHAT_PRODUCTVERSION_ENABLED = 'chat.productversion.enabled';
export const ELIGIBLE_FOR_SOURCING = 'eligibleforsourcing';
export const VIEW_SOURCING = 'viewsourcing';
export const ACCEPTED_SOURCING = 'acceptedsourcing';

// Share
export const ALLOW_PUSH = 'allow_push';
export const TAG_ON_SHARE = 'tagonshare';
export const UNTAG_ON_CHANGE = 'untagonchange';
export const AUTO_ACCEPT_SHARE_CREATE = 'auto_accept_share_create';
export const AUTO_ACCEPT_SHARE_UPDATE = 'auto_accept_share_update';
export const EXCLUSIVE_PRODUCTS = 'exclusive_products';
export const SINGLE_EXPORTABLE_GTIN = 'single_exportable_gtin';

// Catalog / reporting
export const LISTCOLUMN = 'listcolumns';
export const LISTREPORTING = 'reportingdashboard';
export const SUPPLIER_DIRECTORY = 'supplier-directory';

// Listing
export const LISTING = 'listing';
export const LISTING_COMPULSORY = 'listing.compulsory';
export const LISTING_HIERARCHY = 'listing.hierarchy';
export const VALUE_LISTING_HIERARCHY_NONE = 'none';
export const VALUE_LISTING_HIERARCHY_MATURE = 'mature';
export const VALUE_LISTING_HIERARCHY_IMMATURE = 'immature';
export const PRICE_DISPLAYUNITS = 'price.displayunits';
export const VALUE_PRICE_DISPLAYUNITS_ALL = 'all';
export const VALUE_PRICE_DISPLAYUNITS_DISPLAY = 'display';
export const VALUE_PRICE_DISPLAYUNITS_CONSUMERUNITS = 'consumerunits';
export const PRICE_HLU = 'price.heterogeneous-logistical-unit';
export const VALUE_PRICE_HLU_ALL = 'all';
export const VALUE_PRICE_HLU_HETEROGENEOUS = 'heterogeneous';
export const VALUE_PRICE_HLU_CONSUMERUNITS = 'consumerunits';

// Sourcing
export const SOURCINGLABEL_SHARE = 'sourcinglabel.share';

// Export
export const EXPORT_SYNCHRONIZATION_STATUS = 'export_synchronization_status';
export const EXPORT_REGISTRY = 'export_registry';

// Code used in referentials list columns
export const ALLOWED_COLUMN = 'allowed';
export const REQUESTED_SUPPLIER_INTERNAL_ID = 'REQUESTED_SUPPLIER_INTERNAL_ID';
export const REQUESTED_PRODUCTS = 'REQUESTED_PRODUCTS';

// Other
export const WORKFLOW = 'workflow';
export const NO_TAG = 'notag';
export const VALUE_ENABLE = 'enable';
export const VALUE_DISABLE = 'disable';
export const BIG_MANUFACTURER = 'bigmanufacturer';
export const ALERT_ON_RECIPIENT_ACTIVATION = 'alert_on_recipient_activation';
export const READONLY_FIELDS = 'readonly_fields';
