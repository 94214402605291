import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { List, fromJS } from 'immutable';

import { separateActions } from 'utils/redux';

import { AddButton } from '@alkem/react-ui-button';

import { updateSettingValue } from '../../../actions/settings';
import {
  selectSettings,
  selectEditedSettings,
} from '../../../selectors/settings';

import './matrix.scss';

const mapStateToProps = (state) => ({
  settings: selectSettings(state),
  editedSettings: selectEditedSettings(state),
});

const mapDispatchToProps = {
  updateSettingValue,
};

export class OrganizationPageSettingsMatrixController extends PureComponent {
  static propTypes = {
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    help: PropTypes.string,
    path: PropTypes.arrayOf(PropTypes.string).isRequired,
    settings: ImmutablePropTypes.map.isRequired,
    editedSettings: ImmutablePropTypes.map.isRequired,
    disabled: PropTypes.bool,
    actions: PropTypes.shape({
      updateSettingValue: PropTypes.func.isRequired,
    }).isRequired,
  };

  getSettingsJS = () => {
    const { path, settings, editedSettings } = this.props;
    const baseSettings = editedSettings.isEmpty() ? settings : editedSettings;
    return baseSettings.getIn(path, List()).toJS();
  };

  onAdd = () => {
    const { labels, actions, path } = this.props;
    const exportSettings = this.getSettingsJS();
    exportSettings.push(
      labels.reduce((acc, curr) => {
        acc[curr] = '';
        return acc;
      }, {})
    );
    actions.updateSettingValue(path, fromJS(exportSettings));
  };

  onChange = (label, rowIndex) => (event) => {
    const { actions, path } = this.props;
    const exportSettings = this.getSettingsJS();
    const settingToBeEdited = exportSettings[rowIndex];
    settingToBeEdited[label] = event.target.value;
    actions.updateSettingValue(path, fromJS(exportSettings));
  };

  onDelete = (index) => () => {
    const { actions, path } = this.props;
    const exportSettings = this.getSettingsJS();
    exportSettings.splice(index, 1);
    actions.updateSettingValue(path, fromJS(exportSettings));
  };

  render() {
    const { labels, editedSettings, settings, path, help, disabled } =
      this.props;
    const editedValue = editedSettings.getIn(path);
    const sourceValue = settings.getIn(path, []);
    const value = editedValue || sourceValue;

    return (
      <div>
        <div className="OrganizationPageSettingMatrixRow OrganizationPageSettingMatrixHeader">
          {labels.map((label) => (
            <div className="OrganizationPageSettingMatrixCell" key={label}>
              {label}
            </div>
          ))}
          <div
            className="OrganizationPageSettingMatrixCell OrganizationPageSettingMatrixDelete"
            key="org-setting-matrix-column-delete"
          >
            <i className="mdi mdi-delete" />
          </div>
        </div>
        {value.map((exportSpec, rowIndex) => (
          <div
            className="OrganizationPageSettingMatrixRow"
            key={JSON.stringify(exportSpec)}
          >
            {labels.map((label) => (
              <div className="OrganizationPageSettingMatrixCell" key={label}>
                <input
                  type="text"
                  className="OrganizationPageSettingMatrixValue form-control"
                  value={exportSpec.get(label)}
                  placeholder={label}
                  onChange={this.onChange(label, rowIndex)}
                  disabled={disabled}
                />
              </div>
            ))}
            {!disabled && (
              <div className="OrganizationPageSettingMatrixCell OrganizationPageSettingMatrixDelete">
                <i
                  className="mdi mdi-delete"
                  onClick={this.onDelete(rowIndex)}
                />
              </div>
            )}
          </div>
        ))}
        {!disabled && (
          <div className="OrganizationPageSettingMatrixAdd">
            <AddButton label="Add Setting" onClick={this.onAdd} />
          </div>
        )}
        <span className="OrganizationPageSettingMatrixHelp">{help}</span>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(OrganizationPageSettingsMatrixController);
