import { RulesetAutocomplete } from 'components/autocomplete/custom/ruleset-autocomplete';
import InputWithLabel from 'components/input-with-label';
import { FilterValue, RuleSet } from 'modules/validation-dashboard/types';

interface Props {
  ruleSet?: RuleSet;
  onChange: (ruleSet: RuleSet) => void;
}
export const ValidationRuleSetInput = ({ ruleSet, onChange }: Props) => {
  const ruleSetOptionSelected: FilterValue[] = [];

  if (ruleSet) {
    ruleSetOptionSelected.push({
      id: ruleSet.id,
      key: ruleSet.id,
      value: ruleSet,
      label: `${ruleSet.id}: ${ruleSet.label} (${ruleSet.type})`,
    });
  }

  return (
    <InputWithLabel
      inputId="rule-set-input"
      label="Rule set"
      extraInputClassNames={{ NoRuleSetSelected: !ruleSet }}
    >
      <RulesetAutocomplete
        onSelect={(ruleSetOptionSelected) =>
          onChange(ruleSetOptionSelected.value)
        }
        onRemove={onChange}
        value={ruleSetOptionSelected}
        multiple={false}
      />
    </InputWithLabel>
  );
};
