// Transaction id loading
export const LOAD_TRANSACTION_ID = 'transaction-explorer/LOAD_TRANSACTION_ID';

// Link loading
export const LOAD_TRACE = 'transaction-explorer/LOAD_TRACE';
export const LINKS_LOADED = 'transaction-explorer/LINKS_LOADED';

// Transaction loading
export const TRANSACTIONS_LOADED = 'transaction-explorer/TRANSACTIONS_LOADED';

// Selection
export const SELECT = 'transaction-explorer/SELECT';
export const LAYERS_LOADED = 'transaction-explorer/LAYERS_LOADED';

// Payload modal
export const SHOW_PAYLOAD = 'transaction-explorer/SHOW_PAYLOAD';
export const CLOSE_MODAL = 'transaction-explorer/CLOSE_MODAL';
