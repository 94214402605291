import { takeLatest, select, call, put } from 'redux-saga/effects';
import history from 'utils/history';
import qs from 'querystringify';

import GdsnApi from 'resources/gdsnApi';
import { saveAs } from 'utils';
import { notificationError, notificationSuccess } from 'actions/notifications';

import * as events from '../constants/events';

import { receiveGDSNCIList, closeCIHWModal } from '../actions';

import { selectPagination, selectFilters } from '../selectors';

function updateURLWithFilters(filtersSelected) {
  const qsv = {};
  for (const [key, value] of Object.entries(filtersSelected)) {
    if (value != null && value !== '') {
      qsv[key] = value;
    }
  }
  history.replace({
    pathname: history.location.pathname,
    search: qs.stringify(qsv),
  });
}

const {
  GET_GDSN_CI_LIST,
  CHANGE_LIMIT_GDSN_CI_LIST,
  NEXT_PAGE_GDSN_CI_LIST,
  PREVIOUS_PAGE_GDSN_CI_LIST,
  DOWNLOAD_GDSN_DOCUMENT,
  GDSN_CI_PUSH_HIERARCHY_WITHDRAWAL,
  UPDATE_GDSN_CI_FILTER,
  LOAD_GDSN_CI_DETAIL,
} = events;

export default function* gdsnCIMainSaga() {
  yield takeLatest(GDSN_CI_PUSH_HIERARCHY_WITHDRAWAL, pushGDSNCIHW);
  yield takeLatest(GET_GDSN_CI_LIST, getGDSNCIList);
  yield takeLatest(DOWNLOAD_GDSN_DOCUMENT, downloadGDSNDocument);
  yield takeLatest(LOAD_GDSN_CI_DETAIL, loadGDSNCINDetail);
  yield takeLatest(
    [
      CHANGE_LIMIT_GDSN_CI_LIST,
      NEXT_PAGE_GDSN_CI_LIST,
      PREVIOUS_PAGE_GDSN_CI_LIST,
      UPDATE_GDSN_CI_FILTER,
    ],
    getGDSNCIList
  );
}

function* pushGDSNCIHW(action) {
  try {
    yield call(
      [GdsnApi, GdsnApi.SendCIHW],
      action.payload.cisyncID,
      action.payload.deletionReasonCode
    );
    yield put(notificationSuccess('CIHW Successfully pushed'));
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured: pushGDSNCIHW'));
    }
  }
  yield put(closeCIHWModal());
}

function* getGDSNCIList() {
  try {
    const pagination = yield select(selectPagination);
    const filters = yield select(selectFilters);
    const offset = (pagination.currentPage - 1) * pagination.limit;
    updateURLWithFilters(filters);

    let parameters = `?direction=${filters.direction}&offset=${offset}&limit=${pagination.limit}`;
    if (filters.filter_gtin_in) {
      parameters += `&filter_gtin_in=${filters.filter_gtin_in}`;
    }
    if (filters.filter_gln_in) {
      parameters += `&filter_gln_in=${filters.filter_gln_in}`;
    }
    if (filters.filter_id_in) {
      parameters += `&filter_id_in=${filters.filter_id_in}`;
    }
    const response = yield call([GdsnApi, GdsnApi.CINList], parameters);
    yield put(
      receiveGDSNCIList(
        response.data.data,
        pagination.currentPage * pagination.limit + 1
      )
    );
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured: getGDSNCIList'));
    }
  }
}

function* loadGDSNCINDetail(payload) {
  try {
    const response = yield call(
      [GdsnApi, GdsnApi.CINDocumentDetail],
      payload.id
    );
    yield call(payload.onLoadedDocuments, response.data.data);
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured: loadGDSNCINDetail'));
    }
  }
}

function* downloadGDSNDocument(payload) {
  try {
    const response = yield call(
      [GdsnApi, GdsnApi.DownloadDocument],
      payload.documentID
    );
    const filename = `gdsn_document_${payload.documentID}.xml`;
    saveAs(response.data, filename);
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured: downloadGDSNDocument'));
    }
  }
}
