import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { HeaderLayout } from '@alkem/react-layout';

import * as routes from 'constants/routes';
import { separateActions } from 'utils/redux';
import Search from 'components/ui/input/search';

import GLNClaimList from './list';
import { searchByUsername, searchByGLN } from '../actions';
import { selectSearchUsername, selectSearchGLN } from '../selectors';

import './index.scss';

const mapStateToProps = (state) => ({
  searchUsername: selectSearchUsername(state),
  searchGLN: selectSearchGLN(state),
});

const mapDispatchToProps = {
  searchByUsername,
  searchByGLN,
};

export class GLNClaimDashboard extends PureComponent {
  static propTypes = {
    searchUsername: PropTypes.string,
    searchGLN: PropTypes.string,
    actions: PropTypes.shape({
      searchByUsername: PropTypes.func.isRequired,
      searchByGLN: PropTypes.func.isRequired,
    }).isRequired,
  };

  onUpdateUsernameSearchQuery = (query) => {
    this.props.actions.searchByUsername(query);
  };

  onUpdateGLNSearchQuery = (query) => {
    this.props.actions.searchByGLN(query);
  };

  renderSearchBars() {
    return (
      <div className="GLNClaim__searchContainer">
        <Search
          query={this.props.searchUsername}
          placeholder="Username"
          updateSearchQuery={this.onUpdateUsernameSearchQuery}
        />
        <Search
          query={this.props.searchGLN}
          placeholder="GLN"
          updateSearchQuery={this.onUpdateGLNSearchQuery}
        />
      </div>
    );
  }

  render() {
    return (
      <div>
        <HeaderLayout
          title="GLN Claim Dashboard"
          backHref={routes.home}
          backMessage="Back home"
          isTitleSmall
        >
          {this.renderSearchBars()}
        </HeaderLayout>
        <div className="container-fluid row">
          <div className="col-md-12">
            <GLNClaimList />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(GLNClaimDashboard);
