import { datadogRum } from '@datadog/browser-rum';
import { get } from 'lodash';
import { User } from 'modules/auth/types';
import type { AppConfig } from 'types/config';
import { logError } from 'utils';
import { getOrganizationId, getUserId } from 'utils/user';

let initialized = false;

const getDatadogConfig = () =>
  (get(global, ['appconfig', 'datadog']) as AppConfig['datadog']) || {
    enabled: false,
  };

export const initDataDogRUM = () => {
  try {
    if (initialized) {
      return;
    }
    const config = getDatadogConfig();
    if (config.enabled) {
      const { enabled, ...rumConfig } = config;
      datadogRum.init({
        ...rumConfig,
        version: global.FA_VERSION,
      });
    } else if (
      get(window, ['location', 'host']) === 'admin.supplierxm.salsify.com'
    ) {
      datadogRum.init({
        applicationId: 'ca55f89a-e54d-4837-81f5-be24802b4331',
        clientToken: 'pubacf110494e38be9e3cc663aab03d282a',
        site: 'datadoghq.com',
        service: 'front-admin',
        env: 'prod',
        version: global.FA_VERSION,
        sampleRate: 100,
        trackInteractions: true,
        useSecureSessionCookie: true,
        allowedTracingOrigins: ['https://apis.supplierxm.salsify.com'],
      });
    }
    initialized = true;
  } catch (err) {
    logError(err);
  }
};

export function identifyDataDogSession(user: User) {
  try {
    datadogRum.setUser({
      id: getUserId(user) as any,
      oid: getOrganizationId(user),
    });
  } catch (err) {
    logError(err);
  }
}
