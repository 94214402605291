import { createSelector } from 'reselect';
import { MODULE_REDUCER_KEY } from 'constants/reducer';

import { GDSN_RELATION_LIST_STORE_KEY } from '../constants/reducers';

export function selectState(state) {
  return state[MODULE_REDUCER_KEY][GDSN_RELATION_LIST_STORE_KEY];
}

export function selectList(state) {
  return selectState(state).list;
}

export function selectPagination(state) {
  return selectState(state).pagination;
}

export const selectFilters = createSelector(
  selectState,
  (state) => state.filters
);
