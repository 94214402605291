import { useCallback } from 'react';
import { SimpleSelect } from '@alkem/react-ui-select';
import { Text } from '@alkem/react-ui-inputs';

import { FieldCategory, FieldCategories } from '../../../types';

import './field-selector-header.scss';

interface Props {
  currentFieldCategory: FieldCategory;
  fieldCategories: FieldCategories;
  searchValue: string | number;
  selectFieldCategory: (fieldCategory: FieldCategory) => void;
  updateSearchValue: (searchValue: string) => void;
}

export function FieldSelectorHeader({
  currentFieldCategory,
  fieldCategories,
  searchValue,
  selectFieldCategory,
  updateSearchValue,
}: Props): JSX.Element {
  const onSelectFieldCategory = useCallback(
    (fieldCategory: FieldCategory): void => {
      selectFieldCategory(fieldCategory);
    },
    [selectFieldCategory]
  );

  const onUpdateSearchValue = useCallback(
    (event: { target: { value: string } }): void => {
      updateSearchValue(event.target.value);
    },
    [updateSearchValue]
  );

  return (
    <div className="GDSNImportMappingsFieldSelectorHeader">
      <div className="GDSNImportMappingsFieldSelectorHeader__root">
        <div className={'col-xs-12'}>
          <SimpleSelect
            id="gdsn-import-mappings-field-categories"
            options={fieldCategories}
            value={currentFieldCategory}
            onSelect={onSelectFieldCategory}
            autoSize
          />
        </div>
      </div>
      <div className="GDSNImportMappingsFieldSelectorHeader__search">
        <Text
          id="field-search"
          placeholder="Filter fields by name or tag"
          onChange={onUpdateSearchValue}
          value={searchValue}
        />
      </div>
    </div>
  );
}
