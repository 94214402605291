import { TargetMarketApi } from '@alkem/sdk-dashboard';

export const immutableTargetMarketApi = new TargetMarketApi({
  immutable: true,
  cacheEnabled: true,
  acceptLanguage: 'en',
});

export default new TargetMarketApi({
  cacheEnabled: true,
  acceptLanguage: 'en',
});
