import { notificationError, notificationSuccess } from 'actions/notifications';
import qs from 'querystringify';
import {
  delay,
  all,
  call,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import EtlApi from 'resources/etlApi';
import { logError, saveAs } from 'utils';
import history from 'utils/history';
import { get } from 'utils/immutable';
import {
  currentExportFileUpdate,
  currentPotentialSupplierExportFileUpdate,
  downloadExportedFile,
  exportListIsLoading,
  pollExportFileGeneration,
  pollExportFilePotentialSupplierGeneration,
  receiveExportFileReferenceList,
  receiveExportList,
  receiveExportListFilters,
} from '../actions';
import {
  ADD_EXPORT_LIST_FILTER,
  ADD_ONE_EXPORT_LIST_FILTER,
  CHANGE_LIMIT_EXPORT_LIST,
  CLEAR_EXPORT_LIST_FILTER,
  DOWNLOAD_EXPORTED_FILE,
  EXPORTFILE_GENERATE,
  EXPORTFILE_POTENTIAL_SUPPLIER_GENERATE,
  EXPORTFILE_MODAL_CLOSE,
  EXPORTFILE_POTENTIAL_SUPPLIER_MODAL_CLOSE,
  EXPORTGDSNFILE_PUSH,
  GET_EXPORT_FILE_REFERENCE_LIST,
  GET_EXPORT_LIST,
  GET_EXPORT_LIST_FILTER,
  NEXT_PAGE_EXPORT_LIST,
  POLL_EXPORTFILE_EVERY_MS,
  POLL_EXPORTFILE_GENERATION,
  POLL_EXPORTFILE_POTENTIAL_SUPPLIER_GENERATION,
  PREVIOUS_PAGE_EXPORT_LIST,
  REMOVE_EXPORT_LIST_FILTER,
  SEARCH_EXPORT_LIST,
  UPDATE_EXPORT_LIST_FILTER,
  CANCEL_EXPORT,
} from '../constants/events';
import { selectFilters, selectPagination, selectSearch } from '../selectors';

export default function* exportMainSaga() {
  yield takeLatest(EXPORTFILE_GENERATE, generateExportFile);
  yield takeLatest(
    EXPORTFILE_POTENTIAL_SUPPLIER_GENERATE,
    generatePotentialSupplierExportFile
  );
  yield takeLatest(GET_EXPORT_LIST_FILTER, getExportListFilters);
  yield takeEvery(DOWNLOAD_EXPORTED_FILE, downloadExportedFileSaga);
  yield takeLatest(GET_EXPORT_LIST, getExportList);
  yield takeLatest(GET_EXPORT_FILE_REFERENCE_LIST, getExportFileReferenceList);
  yield takeLatest(EXPORTGDSNFILE_PUSH, pushGDSNExport);
  yield takeLatest(
    POLL_EXPORTFILE_GENERATION,
    cancelablePollExportFileGenerationSaga
  );
  yield takeLatest(
    POLL_EXPORTFILE_POTENTIAL_SUPPLIER_GENERATION,
    cancelablePollExportPotentialSupplierFileGenerationSaga
  );
  yield takeLatest(
    [
      SEARCH_EXPORT_LIST,
      ADD_EXPORT_LIST_FILTER,
      REMOVE_EXPORT_LIST_FILTER,
      UPDATE_EXPORT_LIST_FILTER,
      CLEAR_EXPORT_LIST_FILTER,
      CHANGE_LIMIT_EXPORT_LIST,
      NEXT_PAGE_EXPORT_LIST,
      PREVIOUS_PAGE_EXPORT_LIST,
      ADD_ONE_EXPORT_LIST_FILTER,
    ],
    getExportList
  );
  yield takeLatest(CANCEL_EXPORT, cancelExport);
}

function* pushGDSNExport(params) {
  try {
    if (params.payload.includeData === true) {
      yield call([EtlApi, EtlApi.QueuePusher], 'etl.v2.export.gdsn', false, {
        product_key_id: params.payload.productKeyID,
        document_command_type: params.payload.dct,
      });
    }
    if (params.payload.includePrice === true) {
      yield call(
        [EtlApi, EtlApi.QueuePusher],
        'etl.v2.export.gdsn.price',
        false,
        {
          product_key_id: params.payload.productKeyID,
        }
      );
    }
    yield put(notificationSuccess('GDSN export event successfully registered'));
  } catch (error) {
    logError(error);
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured: pushGDSNExport'));
    }
  }
}

function* getExportFileReferenceList(params) {
  try {
    const response = yield call(
      [EtlApi, EtlApi.ProductsExportReference],
      params.exportFile.id,
      params.payload
    );
    yield put(
      receiveExportFileReferenceList(response.data.data, params.exportFile.id)
    );
  } catch (error) {
    logError(error);
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(
        notificationError('An error occured: getExportFileReferenceList')
      );
    }
  }
}

function getExportFileState(ef) {
  if (!ef) {
    return 'STARTING';
  } else if (ef.steps.length === 0) {
    return 'STARTING';
  } else if (ef.steps.filter((step) => step.status === 'ERROR').length > 0) {
    return 'ERROR';
  } else if (ef.steps.filter((step) => step.name === 'upload').length > 0) {
    return 'DONE';
  }
  return 'IN_PROGRESS';
}

function* getExportListFilters() {
  try {
    const response = yield call([EtlApi, EtlApi.ExportFileFilters]);
    const formats = response.data.formats.map((f) => ({
      label: f.toUpperCase(),
      value: f,
      statusBarType: 'NOT_ACTIVE',
    }));

    yield put(
      receiveExportListFilters({
        organizations: response.data.organizations,
        formats,
      })
    );
  } catch (error) {
    logError(error);
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured: getExportListFilters'));
    }
  }
}

function* getExportList({ payload }) {
  try {
    yield put(exportListIsLoading());

    const pagination = yield select(selectPagination);
    const search = yield select(selectSearch);
    const filters = yield select(selectFilters);

    const qsv = {};

    const qFilters = {
      offset: (pagination.currentPage - 1) * pagination.limit,
      limit: pagination.limit,
      filter_references_in: search,
      with_gtins: false,
    };

    if (search) {
      qsv.q = search;
    }

    if (filters.organizations.length > 0) {
      qFilters.filter_organization_id_in = filters.organizations.map(
        (o) => o.value
      );
      qsv.organization_id_in = filters.organizations.map((o) => o.value);
    }

    if (filters.filter_ids_in && filters.filter_ids_in.length > 0) {
      qFilters.filter_ids_in = filters.filter_ids_in.map((o) => o.value);
      qsv.filter_ids_in = filters.filter_ids_in.map((o) => o.value);
    }

    if (filters.status) {
      if (filters.status.value === 'SUCCESS') {
        qFilters.filter_step_status = [
          'ACCEPTED',
          'RECEIVED',
          'SUCCESS',
          'SYNCHRONISED',
        ].join();
      } else if (filters.status.value === 'REVIEW') {
        qFilters.filter_step_status = 'REVIEW';
      } else if (filters.status.value === 'RETRY') {
        qFilters.filter_step_status = 'RETRY';
      } else if (filters.status.value === 'ERROR') {
        qFilters.filter_step_status = ['REJECTED', 'ERROR'].join();
      } else if (filters.status.value === 'IN_PROGRESS') {
        qFilters.filter_step_status = 'IN_PROGRESS';
      }
      qsv.status = filters.status.value;
    }

    if (filters.format) {
      qFilters.filter_format = filters.format.value;
      qsv.format = qFilters.filter_format;
    }

    if (filters.dates.length > 0) {
      qFilters.start_date = filters.dates.find(
        (element) => element.name === 'start_date'
      );
      if (qFilters.start_date) {
        qFilters.start_date = qFilters.start_date.value;
        qsv.start_date = qFilters.start_date;
      }
      qFilters.end_date = filters.dates.find(
        (element) => element.name === 'end_date'
      );
      if (qFilters.end_date) {
        qFilters.end_date = qFilters.end_date.value;
        qsv.end_date = qFilters.end_date;
      }
    }

    if (get(payload, 'replaceHistory') !== false) {
      yield call(history.replace, {
        pathname: history.location.pathname,
        query: qsv,
        search: qs.stringify(qsv),
      });
    }

    const response = yield call([EtlApi, EtlApi.ExportFile], qFilters);
    yield put(
      receiveExportList(response.data.data, response.data.totalResults)
    );
  } catch (error) {
    logError(error);
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured: getExportList'));
    }
  }
}

function* downloadExportedFileSaga(params) {
  try {
    const response = yield call(
      [EtlApi, EtlApi.ExportedFileDownload],
      params.efID,
      params.fileIndex,
      params.stepName,
      params.stepIndex
    );
    saveAs(response.data, params.filename);
  } catch (error) {
    logError(error);
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(
        notificationError('An error occured: downloadExportedFileSaga')
      );
    }
  }
}

function* generateExportFile(payload) {
  try {
    const response = yield call(
      [EtlApi, EtlApi.ProductsExportRaw],
      payload.params,
      true
    );
    yield put(currentExportFileUpdate(null, true));
    yield put(pollExportFileGeneration(response.data.tracker.id));
  } catch (error) {
    logError(error);
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured: generateExportFile'));
    }
  }
}

function* pollExportFileGenerationSaga({ exportFileID }) {
  try {
    const response = yield call([EtlApi, EtlApi.ExportFile], {
      filter_ids_in: [exportFileID],
    });
    const ef = response.data.data[0];
    yield put(currentExportFileUpdate(ef, true));

    const exportStatus = getExportFileState(ef);

    if (exportStatus === 'DONE') {
      const uploadStep = ef.steps.filter((step) => step.name === 'upload')[0];
      yield all(
        (uploadStep.data.remote_files || []).map((fl, i) =>
          put(downloadExportedFile(ef.id, i, fl.replace(/^.*[\\/]/, '')))
        )
      );
      yield put(currentExportFileUpdate(null, false));
    } else if (exportStatus === 'ERROR') {
      yield put(
        notificationError('An error occured while generating your file')
      );
      yield put(currentExportFileUpdate(null, false));
    } else {
      yield delay(POLL_EXPORTFILE_EVERY_MS);
      yield put(pollExportFileGeneration(exportFileID));
    }
  } catch (error) {
    logError(error);
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(
        notificationError('An error occured: pollExportFileGenerationSaga')
      );
    }
  }
}

function* cancelablePollExportFileGenerationSaga(action) {
  yield race({
    task: call(pollExportFileGenerationSaga, action),
    cancel: take(EXPORTFILE_MODAL_CLOSE),
  });
}

function* cancelablePollExportPotentialSupplierFileGenerationSaga(action) {
  yield race({
    task: call(pollPotentialSupplierExportFileGenerationSaga, action),
    cancel: take(EXPORTFILE_POTENTIAL_SUPPLIER_MODAL_CLOSE),
  });
}

function* pollPotentialSupplierExportFileGenerationSaga({ exportFileID }) {
  try {
    const response = yield call([EtlApi, EtlApi.ExportFile], {
      filter_ids_in: [exportFileID],
    });
    const ef = response.data.data[0];
    yield put(currentPotentialSupplierExportFileUpdate(ef, true));

    const exportStatus = getExportFileState(ef);

    if (exportStatus === 'DONE') {
      const uploadStep = ef.steps.filter((step) => step.name === 'upload')[0];
      yield all(
        (uploadStep.data.remote_files || []).map((fl, i) =>
          put(downloadExportedFile(ef.id, i, fl.replace(/^.*[\\/]/, '')))
        )
      );
      yield put(currentPotentialSupplierExportFileUpdate(null, false));
    } else if (exportStatus === 'ERROR') {
      yield put(
        notificationError('An error occured while generating your file')
      );
      yield put(currentPotentialSupplierExportFileUpdate(null, false));
    } else {
      yield delay(POLL_EXPORTFILE_EVERY_MS);
      yield put(pollExportFilePotentialSupplierGeneration(exportFileID));
    }
  } catch (error) {
    logError(error);
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(
        notificationError(
          'An error occured: pollPotentialSupplierExportFileGenerationSaga'
        )
      );
    }
  }
}

function* generatePotentialSupplierExportFile(payload) {
  try {
    const response = yield call(
      [EtlApi, EtlApi.PotentialSuppliersExportRaw],
      payload.params,
      true
    );
    yield put(currentPotentialSupplierExportFileUpdate(null, true));
    yield put(
      pollExportFilePotentialSupplierGeneration(response.data.tracker.id)
    );
  } catch (error) {
    logError(error);
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(
        notificationError(
          'An error occured: generatePotentialSupplierExportFile'
        )
      );
    }
  }
}

function* cancelExport(params) {
  try {
    yield call([EtlApi, EtlApi.CancelExport], params.efID, params.status);
  } catch (error) {
    logError(error);
    if (error && error.data && error.data.message) {
      yield put(notificationError(error.data.message));
    } else {
      yield put(notificationError('An error occured: cancelExport'));
    }
  }
}
