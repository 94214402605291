import { isString } from 'utils';
import { remove as removeDiacritics } from 'diacritics';
import { compose } from 'redux';

export const toTitleCase = (str) => {
  if (!isString(str)) {
    return str;
  }
  return str.replace(
    /[^\-'_\s]+/g,
    (txt) => `${txt[0].toUpperCase()}${txt.substring(1).toLowerCase()}`
  );
};

export function toSafeLowerCase(str) {
  if (!isString(str)) {
    return str;
  }
  return str.toLowerCase();
}

export const toComparable = compose(toSafeLowerCase, removeDiacritics);
