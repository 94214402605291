import React from 'react';
import referentialApi from 'resources/referentialApi';
import {
  LegacyAutocompleteProps,
  LegacyAutocomplete,
} from '../legacy-autocomplete';

export const ReferentialAutocomplete = React.memo(
  function ReferentialAutocomplete(props: LegacyAutocompleteProps) {
    const onSearch = (search) =>
      referentialApi.ReferentialList({ search, limit: 20 });

    return <LegacyAutocomplete onSearch={onSearch} {...props} />;
  }
);

export default ReferentialAutocomplete;
