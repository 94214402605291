import { Radio } from '@alkem/react-ui-inputs';
import InputWithLabel from 'components/input-with-label';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GET_FIELD_LIST } from '../../../../actions/constants';
import { selectFieldOptions } from '../../../../selectors';
import {
  ExportMapping,
  ExportMappingPayload,
  FieldOption,
} from '../../../../types';
import { ExportMappingsSimpleSelector } from '../../simple-selector';

interface Props {
  elementName: string;
  exportMapping: ExportMapping;
  dispatchSetExportMapping: ({
    cast,
    field,
    use_su,
    referential,
    is_declinable,
    value,
    custom_parse,
  }: ExportMappingPayload) => void;
}
export function ExportMappingsSimpleElementItemFieldTypePropsSelector({
  elementName,
  exportMapping,
  dispatchSetExportMapping,
}: Props) {
  const { payload } = exportMapping;
  const dispatch = useDispatch();
  const fieldOptions = useSelector(selectFieldOptions);
  const [category, setCategory] = useState(
    payload.use_su ? 'sharingUnit' : 'consumerUnit'
  );
  const [fieldSearchValue, setFieldSearchValue] = useState<string | undefined>(
    undefined
  );
  const [fieldOption, setFieldOption] = useState<FieldOption | undefined>(
    undefined
  );
  const [field, setField] = useState(payload.field || undefined);

  const dispatchGetFieldList = useCallback(
    ({ entityType, search }: { entityType?: string; search?: string }) => {
      dispatch({
        type: GET_FIELD_LIST,
        entityType: entityType || category,
        search: search ?? fieldSearchValue,
      });
    },
    [dispatch, category, fieldSearchValue]
  );

  useEffect(() => {
    dispatchGetFieldList({});
  }, []);

  const clearFieldSelectorFields = () => {
    setFieldSearchValue(undefined);
    setFieldOption(undefined);
    setField(undefined);
  };

  const updateCategory = (event: { target: { value: string } }) => {
    const { value } = event.target;
    setCategory(value);
    dispatchGetFieldList({ entityType: value, search: '' });
    clearFieldSelectorFields();
    dispatchSetExportMapping({
      use_su: value === 'sharingUnit' || undefined,
    });
  };

  const updateSearchValue = useCallback(
    (event: { target: { value: string } }): void => {
      const { value } = event.target;
      setFieldSearchValue(value);
      dispatchGetFieldList({ search: value });
    },
    [setFieldSearchValue, category]
  );

  const updateField = (value: FieldOption) => {
    const { use_su } = payload;
    setFieldOption(value);
    setField(value.label);
    const { data } = value;
    const referential: string | undefined =
      data.referential || data.declinable_by;
    const is_declinable: boolean | undefined =
      !!data.declinable_by || undefined;
    dispatchSetExportMapping({
      cast: value.data.type,
      field: value.label,
      use_su,
      referential,
      is_declinable,
    });
  };

  const resetField = () => {
    const { use_su } = payload;
    setFieldOption(undefined);
    setField(undefined);
    dispatchSetExportMapping({
      use_su,
    });
  };

  const { id } = exportMapping;

  return (
    <div className="GDSNExportMappingsSimpleElementItemFieldTypePropsSelector">
      <div
        data-testid={`gdsn-export-mappings-simple-element-${elementName}-category-property-${id}`}
      >
        <InputWithLabel
          inputId={`gdsn-export-mappings-simple-element-${elementName}-category-property-${id}`}
          label="Category"
        >
          <Radio
            id={`gdsn-export-mappings-simple-element-${elementName}-category-property-${id}`}
            value={category}
            options={[
              { label: 'Consumer unit', value: 'consumerUnit' },
              { label: 'Sharing unit', value: 'sharingUnit' },
            ]}
            onChange={updateCategory}
          />
        </InputWithLabel>
      </div>
      <ExportMappingsSimpleSelector
        label="Select a field"
        testId={`gdsn-export-mappings-simple-element-${elementName}-field-property-${id}`}
        options={fieldOptions}
        onSelect={updateField}
        option={fieldOption}
        onReset={resetField}
        placeholder={fieldOptions.length === 0 ? 'No field found' : undefined}
        disabled={fieldOptions.length === 0}
        searchProps={{
          onSearch: updateSearchValue,
          placeholder: 'Filter fields by name or tag',
          value: fieldSearchValue,
        }}
        displayedValueProps={{
          label: 'Field',
          value: field,
        }}
      />
    </div>
  );
}
