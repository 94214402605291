import { createReducer } from 'redux-create-reducer';
import { fromJS } from 'immutable';

import {
  ORGANIZATION_CREATE_MODAL_OPEN,
  ORGANIZATION_CREATE_MODAL_CLOSE,
  ORGANIZATION_CREATE_MODAL_LOADING,
  ORGANIZATION_CREATE_RESET,
} from '../constants/events';

const initialState = fromJS({
  isOpen: false,
  isLoading: false,
});

export default createReducer(initialState, {
  [ORGANIZATION_CREATE_MODAL_OPEN]: (state) => state.set('isOpen', true),
  [ORGANIZATION_CREATE_MODAL_CLOSE]: (state) => state.set('isOpen', false),
  [ORGANIZATION_CREATE_MODAL_LOADING]: (state, { payload }) =>
    state.set('isLoading', payload),
  [ORGANIZATION_CREATE_RESET]: () => initialState,
});
