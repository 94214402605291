import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { separateActions } from 'utils/redux';
import { Tooltip } from '@alkem/react-ui-tooltip';

import StatusBar from 'components/ui/status-bar';
import PcrModal from 'modules/io/gdsn/pcr-modal';

import './row.scss';

import {
  getGDSNPSRDetail,
  downloadGDSNDocument,
  deleteGDSNPSRItem,
} from '../../actions';
import {
  formatTimeImplicitUTC,
  TIME_FORMAT_MINUTE,
  TIME_FORMAT_SECOND,
} from 'utils/time';

const mapDispatchToProps = {
  getGDSNPSRDetail,
  downloadGDSNDocument,
  deleteGDSNPSRItem,
};

export class GDSNPSRListRow extends Component {
  static propTypes = {
    psr: PropTypes.object.isRequired,
    detail: PropTypes.object,
    actions: PropTypes.shape({
      getGDSNPSRDetail: PropTypes.func.isRequired,
      downloadGDSNDocument: PropTypes.func.isRequired,
      deleteGDSNPSRItem: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      pcrSendId: null,
    };
  }

  onClickExpand = () => {
    const { isExpanded } = this.state;
    const { psr, detail } = this.props;
    this.setState({ isExpanded: !isExpanded });
    if (detail) {
      return;
    }
    this.props.actions.getGDSNPSRDetail(psr.id, psr.gtin);
  };

  onClosePCRModal = () => {
    this.setState({ pcrSendId: null });
  };

  computeRunStatus(status) {
    if (status === 'REJECTED' || status === 'REVIEW') {
      return 'FAILURE';
    } else if (status === 'RECEIVED' || status === 'SYNCHRONISED') {
      return 'SUCCESS';
    }
    return 'DEFAULT';
  }

  downloadDocument(messageID) {
    return () => this.props.actions.downloadGDSNDocument(messageID);
  }

  deleteItem(itemID, psrID, gtin) {
    return () => {
      // eslint-disable-next-line no-alert
      const confirmed = window.confirm(
        'Are you sure you want to delete this item?'
      );
      if (confirmed) {
        this.props.actions.deleteGDSNPSRItem(itemID, psrID, gtin);
      }
    };
  }

  generateACKSummary(ack) {
    let summary = '';
    let first = true;
    for (const [key, value] of Object.entries(ack.state)) {
      if (!first) {
        summary += ', ';
      }
      summary += `${value.length} ${key}`;
      first = false;
    }
    return summary;
  }

  renderParty(party) {
    const org =
      party.organizations.length > 0
        ? party.organizations[0]
        : {
            id: 0,
            name: 'Unknown',
          };
    return (
      <div className="col-xs-2">
        <div>
          <i className="mdi mdi-domain" />
          {` ${org.nameLegal} (${org.id})`}
        </div>
        <div>
          <i className="mdi mdi-steam" />
          {` ${party.gln}`}
        </div>
      </div>
    );
  }

  renderGS1Response(e, i) {
    return (
      <span>
        <i
          data-tip
          data-for={`file-tooltip-gs1r-${e.gs1_response.message_id}-${Math.ceil(
            i
          )}`}
          className={`mdi mdi-${
            e.gs1_response.status === 'REJECTED'
              ? 'sync-alert GDSNPSRListDetailsIconDoc_red'
              : 'sync GDSNPSRListDetailsIconDoc_green'
          } GDSNPSRListDetailsIconDoc`}
        />
        <Tooltip
          id={`file-tooltip-gs1r-${e.gs1_response.message_id}-${Math.ceil(i)}`}
          place="right"
        >
          {formatTimeImplicitUTC(e.gs1_response.createdAt, TIME_FORMAT_MINUTE)}
        </Tooltip>
        <i
          className="mdi mdi-download GDSNPSRListDetailsIconDoc"
          onClick={this.downloadDocument(e.gs1_response.message_id)}
        />
      </span>
    );
  }

  renderACK(acks) {
    const uniq = Math.ceil(Math.random() * 10000);
    let i = 0;
    return acks.map((e) => {
      i += 1;
      return (
        <div
          key={`psr-ack-${uniq}-${i}`}
          className="col-xs-3 GDSNPSRColBL_mini"
        >
          <div>
            <i className="mdi mdi-sync  " />
            {' '}
            {formatTimeImplicitUTC(e.date, TIME_FORMAT_SECOND)}
            {' '}
            <i
              className="mdi mdi-download GDSNPSRListDetailsIconDoc"
              onClick={this.downloadDocument(e.message_id)}
            />
            {e.gs1_response.message_id && this.renderGS1Response(e, i)}
          </div>
          <div>
            <i className="mdi mdi-backup-restore" />
            {` ${this.generateACKSummary(e)}`}
          </div>
        </div>
      );
    });
  }

  renderDelete(itemID, psrID, gtin) {
    return (
      <span>
        <i
          className="mdi mdi-delete GDSNPSRListDetailsIconDoc"
          onClick={this.deleteItem(itemID, psrID, gtin)}
        />
      </span>
    );
  }

  renderDetail() {
    const { detail, psr } = this.props;
    if (!detail) {
      return <div>Loading...</div>;
    }
    const uniq = Math.ceil(Math.random() * 10000);
    let i = 0;
    const lines = detail.toJS().map((e) => {
      i += 1;
      const openPCRModal = () => {
        this.setState({ pcrSendId: e.id });
      };
      return (
        <div
          key={`psr-send-${uniq}-${i}`}
          className="GDSNPSRListRow GDSNPSRListRow_mini row"
        >
          <StatusBar type={this.computeRunStatus(e.status)} />
          <div className="col-xs-4">
            <div>
              <i className="mdi mdi-send" />
              {` ${formatTimeImplicitUTC(e.sent_date, TIME_FORMAT_SECOND)} (${
                e.id
              }) `}
              <i
                className="mdi mdi-download GDSNPSRListDetailsIconDoc"
                onClick={this.downloadDocument(e.message_id)}
              />
              <i className="mdi mdi-checkbook" onClick={openPCRModal} />
              {e.gs1_response.message_id && this.renderGS1Response(e, i)}
            </div>
            <div>
              <i className="mdi mdi-backup-restore" />
              {` ${e.status} `}
              {e.ack.length === 0 && this.renderDelete(e.id, psr.id, psr.gtin)}
            </div>
          </div>
          {this.renderACK(e.ack)}
        </div>
      );
    });
    return (
      <div className="GDSNPSRListRowWrapper">
        <div className="GDSNPSRListRow__Lines">{lines}</div>
      </div>
    );
  }

  render() {
    const { psr } = this.props;
    const { isExpanded, pcrSendId } = this.state;
    const expandClass = {
      GDSNPSRListDetailsIcon: true,
      'col-xs-0.5': true,
      mdi: true,
      'mdi-chevron-up': isExpanded,
      'mdi-chevron-down': !isExpanded,
    };
    return (
      <div className="GDSNPSRListRowWrapper row">
        <div className="GDSNPSRListRow row">
          <div className="col-xs-1">
            <div className="GDSNPSRListRowCenter">
              <i className="mdi mdi-barcode" />
            </div>
            <div className="GDSNPSRListRowCenter">{`${psr.gtin}`}</div>
          </div>
          <div className="col-xs-2">
            <div>
              <i className="mdi mdi-account" />
              {` ${psr.business_location_gln}`}
            </div>
            <div>
              <i className="mdi mdi-currency-btc" />
              {psr.currency_code}
            </div>
            <div>
              <i className="mdi mdi-road-variant" />
              {` ${psr.trade_channel}`}
            </div>
          </div>
          {this.renderParty(psr.source)}
          <div className="col-xs-1">
            <i
              data-tip
              data-for={`psr-sync-status-${psr.id}`}
              className={`mdi mdi mdi-24px mdi-${
                psr.deleted === true
                  ? 'sync-off GDSNRelationIconDoc_red'
                  : 'sync GDSNRelationIconDoc_green'
              } GDSNPSRListDetailsIconDoc`}
            />
            {psr.deleted === true && (
              <Tooltip id={`psr-sync-status-${psr.id}`} place="right">
                The relation is deleted (By CIS DELETE)
                <br />A new one will be automatically created on next export
              </Tooltip>
            )}
            {psr.deleted === false && (
              <Tooltip id={`psr-sync-status-${psr.id}`} place="right">
                The relation is active
              </Tooltip>
            )}
          </div>
          {this.renderParty(psr.recipient)}
          <div className="col-xs-2">
            <div>
              <i className="mdi mdi-calendar-export" />
              {psr.first_sync_date}
            </div>
            <div>
              <i className="mdi mdi-calendar-clock" />
              {psr.last_sync_date}
            </div>
          </div>
          <div className="col-xs-1 GDSNPSRListRow_actions">
            <div
              onClick={this.onClickExpand}
              className={classNames(expandClass)}
            />
          </div>
        </div>
        {isExpanded ? this.renderDetail() : null}
        {pcrSendId && (
          <PcrModal psrItemId={pcrSendId} onClose={this.onClosePCRModal} />
        )}
      </div>
    );
  }
}

export default connect(
  null,
  mapDispatchToProps,
  separateActions
)(GDSNPSRListRow);
