import './rule-applier-overview.scss';
import { RuleOverviewStep } from './rule-applier-overview-step';

type Props = {
  conditionExpression?: string;
  conditionOutcome: any;
  validationExpression?: string;
  validationOutcome: any;
};

export const RuleApplierOverview = ({
  conditionExpression,
  conditionOutcome,
  validationExpression,
  validationOutcome,
}: Props) => {
  return (
    <>
      <h4 className="RuleApplierOverview__Header">Overview</h4>
      <div data-testid="OverviewSection" className="alk-flex">
        <div className="alk-flex alk-flex-1 alk-flex-column alk-flex-space-between">
          <RuleOverviewStep
            name="Condition"
            expression={conditionExpression}
            outcome={conditionOutcome}
          />
        </div>
        <div className="alk-flex alk-flex-1 alk-flex-column alk-flex-space-between">
          <RuleOverviewStep
            name="Validation"
            expression={validationExpression}
            outcome={validationOutcome}
          />
        </div>
      </div>
      <hr className="RuleDebuggerSectionSeparator" />
    </>
  );
};
