import PropTypes from 'prop-types';
import React, { Component } from 'react';
import RangePicker from 'react-daterange-picker';
import listensToClickOutside from 'react-onclickoutside';
import { result } from 'lodash';

import moment from 'moment';

import './date.scss';

class Date extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    value: PropTypes.number,
    onSelect: PropTypes.func.isRequired,
    onUnselect: PropTypes.func.isRequired,
  };

  static defaultProps = {
    value: null,
  };

  constructor(props) {
    super(props);
    this.state = { displayCalendar: false };

    this.onClick = this.onClick.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  onClick() {
    this.setState((prevState) => ({
      displayCalendar: !prevState.displayCalendar,
    }));
  }

  onDelete() {
    this.props.onUnselect(null);
  }

  onSelect(date) {
    this.setState({
      displayCalendar: false,
    });
    this.props.onSelect({ key: date.valueOf(), label: date.format('LL') });
  }

  handleClickOutside = () => {
    if (this.state.displayCalendar) {
      this.setState({
        displayCalendar: false,
      });
    }
  };

  renderDeleteSelectionButton() {
    const { value } = this.props;
    if (!value || !value.format) {
      return null;
    }
    return (
      <div
        className="InputDate__deleteIcon mdi mdi-close"
        onClick={this.onDelete}
      />
    );
  }

  render() {
    const { id, title, value } = this.props;

    const v = value && moment(value);
    const formatted = v && v.format ? v.format('LL') : '';

    return (
      <div id={id}>
        <h3 className="ReactUiFilter__filterTitle">{title}</h3>
        <div className="DateFilter">
          <span className="DateFilter__wrapped">
            <input
              className="DateFiler__input"
              placeholder="Date..."
              onClick={this.onClick}
              value={formatted}
              disabled
            />
            {this.renderDeleteSelectionButton()}
            <span
              className="DateFiler__calendarIcon mdi mdi-calendar-blank"
              onClick={this.onClick}
            />
          </span>
        </div>
        {this.state.displayCalendar && (
          <div className="DateFiler__calendar">
            <RangePicker
              numberOfCalendars={1}
              selectionType="single"
              onSelect={this.onSelect}
              value={(result(v, 'isValid') && v) || null}
            />
          </div>
        )}
      </div>
    );
  }
}

export { Date };
export default listensToClickOutside(Date);
