import { combineReducers } from 'redux';

import { MODAL_REDUCER_KEY, FORM_REDUCER_KEY } from '../constants';
import modalReducer from './modal';
import formReducer from './form';

export default combineReducers({
  [MODAL_REDUCER_KEY]: modalReducer,
  [FORM_REDUCER_KEY]: formReducer,
});
