/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List as ImmutableList } from 'immutable';
import { noop } from 'lodash';

import { Select } from '@alkem/react-ui-select';

import { RulesetEditor } from './components/ruleset';

import { fetchAllRuleSets } from './actions';
import { selectAllRuleSets } from './selectors';
import { Rulesets } from './state';
import * as constants from './constants';

import './ruleset-selector.scss';

const isSelected = (ruleSet, selectedRuleSets) =>
  selectedRuleSets.findIndex((rs) => rs.get('id') === ruleSet.get('id')) >= 0;

const findLabel = (rulesetId, allRulesets) => {
  const inAll = allRulesets.find((rs) => rs.get('id') === rulesetId);
  if (!inAll) {
    return '(No label)';
  }

  return inAll.get('label');
};

interface Props {
  rulesets: Rulesets;
  onChange: (rulesets: Rulesets) => void;
  readOnly?: boolean;
  manageDeadline?: boolean;
}

const RuleSetSelector = (props: Props) => {
  const { rulesets, onChange, readOnly = false, manageDeadline = true } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    fetchAllRuleSets(dispatch);
  }, [dispatch]);

  const allRulesets = useSelector(selectAllRuleSets) || ImmutableList();

  const allRulesetsOptions = useMemo(
    () =>
      allRulesets
        .filter((ruleSet) => !isSelected(ruleSet, rulesets))
        .map((rs) => ({
          id: rs!.get('id'),
          label: rs!.get('label'),
        }))
        .toArray(),
    [allRulesets, rulesets]
  );

  // not all parent components will give use ruleset with labels
  // we create another property that we feed using the catalog of all rulesets
  const rulesetsWithDisplayName = useMemo(
    () =>
      rulesets.map((rs) =>
        rs!.set('displayName', findLabel(rs!.get('id'), allRulesets))
      ),
    [allRulesets, rulesets]
  );

  const handleSelectRuleset = ({ id }: { id: string }) => {
    const rs = allRulesets.find((rs) => rs!.get('id') === id);
    if (!rs) {
      throw Error(
        'Dev error handleSelectRuleset called with id not in allRulesets'
      );
    }
    onChange(
      rulesets.push(
        rs.merge({
          restrictionType: constants.restrictionTypes.warning,
          with_deadline: false,
          default_deadline: null,
        })
      )
    );
  };

  const handleRemoveRuleset = (id: number) => () => {
    const index = rulesets.findIndex((rs) => rs!.get('id') === id);
    if (index < 0) {
      return;
    }

    onChange(rulesets.delete(index));
  };

  const handleUpdateRuleset = (ruleset) => {
    const index = rulesets.findIndex(
      (rs) => rs!.get('id') === ruleset.get('id')
    );
    if (index < 0) {
      return;
    }

    // we must now remove compatibility display property to look the same as the source structure
    onChange(rulesets.set(index, ruleset.delete('displayName')));
  };

  const selectRulesetsElements = rulesetsWithDisplayName.map((rs) => {
    const id = rs!.get('id') as number;
    return (
      <div className="RuleSetSelector__ruleSet" key={id}>
        <RulesetEditor
          ruleset={rs!}
          onUpdate={handleUpdateRuleset}
          disabled={readOnly}
          manageDeadline={manageDeadline}
        />
        {!readOnly && (
          <span
            className="RuleSetSelector__delete mdi mdi-delete"
            onClick={handleRemoveRuleset(id)}
          />
        )}
      </div>
    );
  });

  return (
    <div className="RuleSetSelector">
      {!readOnly && (
        <div>
          <Select
            id="rule-set-selector-add-ruleset"
            placeholder="Select a ruleset to add"
            options={allRulesetsOptions}
            values={[]}
            onValueAdd={handleSelectRuleset}
            onValueDelete={noop}
            inputable
          />
        </div>
      )}
      <div>{selectRulesetsElements}</div>
    </div>
  );
};

export default RuleSetSelector;
