import { SplitButton } from '@alkem/react-ui-button';
import { ListColumn as Column, ListRow as Row } from '@alkem/react-ui-list';
import classNames from 'classnames';
import TimeAgo from 'components/timeago';
import * as routes from 'constants/routes';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectUser } from 'reducers/user';
import { createStructuredSelector } from 'reselect';
import { fill } from 'utils/routes';
import { getStreamUrl } from 'utils/stream';
import { openUserUpdateModal } from '../../../user-update/actions/modal';
import {
  getUserFullName,
  getUserId,
  getUserLastLoggedIn,
  getUserOrganizationId,
  getUserOrganizationNameLegal,
  getUserPhoneNumber,
  getUserUsername,
  isAdmin,
} from '../../utils';
import './row.scss';

const mapStateToProps = createStructuredSelector({ userLogAs: selectUser });

const mapDispatchToProps = {
  openUserUpdateModal,
};

class UserRow extends Component {
  static propTypes = {
    isFirst: PropTypes.bool,
    openUserUpdateModal: PropTypes.func.isRequired,
    user: ImmutablePropTypes.map.isRequired,
    userLogAs: ImmutablePropTypes.map,
  };

  constructor(saiyan) {
    super(saiyan);
    this.onUpdateUser = this.onUpdateUser.bind(this);
  }

  onUpdateUser() {
    this.props.openUserUpdateModal(this.props.user);
  }

  onLogAsButtonClick = () => {
    const { userLogAs, user } = this.props;
    const encodedSource = encodeURIComponent(getUserUsername(userLogAs));
    const encodedTarget = encodeURIComponent(getUserUsername(user));
    const encodedOrganizationId = encodeURIComponent(
      user.getIn(['belongsTo', '0', 'id'])
    );

    window.open(
      getStreamUrl(
        `logas?source=${encodedSource}&target=${encodedTarget}&organization=${encodedOrganizationId}`
      ),
      '_blank'
    );
  };

  renderHeader(headerName) {
    return <span className="UserRow__header">{headerName}</span>;
  }

  render() {
    const { user, isFirst } = this.props;
    const isPendingOrganization = !user.getIn(['belongsTo', 0, 'accepted']);
    return (
      <Row
        className={classNames('UserRow', {
          'UserRow--first': isFirst,
          'UserRow--deactivated': user.get('status') === 0,
        })}
      >
        <Column className="UserRow__dual">
          {isFirst && this.renderHeader('User / id')}
          <span className="UserRow__name">{getUserFullName(user)}</span>
          <span>{getUserId(user)}</span>
        </Column>

        <Column className="UserRow__single">
          {isFirst && this.renderHeader('User rights')}
          {isAdmin(user) && (
            <span className="UserRow__badge">Administrateur</span>
          )}
        </Column>

        <Column className="UserRow__dual">
          {isFirst && this.renderHeader('Organization / id')}
          <Link
            to={fill(routes.organization, getUserOrganizationId(user))}
            className={classNames('UserRow__organizationName', {
              'UserRow__organizationName--pending': isPendingOrganization,
            })}
            onClick={this.onGoToOrganizationList}
          >
            {getUserOrganizationNameLegal(user)}
          </Link>
          <span>{getUserOrganizationId(user)}</span>
        </Column>

        <Column className="UserRow__dual">
          {isFirst && this.renderHeader('Contact info')}
          <span className="UserRow__username">{getUserUsername(user)}</span>
          <span>{getUserPhoneNumber(user)}</span>
        </Column>

        <Column className="UserRow__single">
          {isFirst && this.renderHeader('Last logged in')}
          <span className="UserRow__lastLoggedIn">
            <TimeAgo time={getUserLastLoggedIn(user)} />
          </span>
        </Column>

        <Column className="UserRow__single UserRow__edit">
          <SplitButton
            text="Edit"
            onClick={this.onUpdateUser}
            primary
            dropleft
            alternativeOptions={[
              {
                text: 'Log as',
                onClick: this.onLogAsButtonClick,
              },
            ]}
          />
        </Column>
      </Row>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserRow);
