import { Modal } from '@alkem/react-ui-modal';
import { Spinner } from '@alkem/react-ui-spinner';
import { CodeEditor } from 'components/code-editor';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { separateActions } from 'utils/redux';
import { closeModal, generateExportFile } from '../actions';
import {
  selectIsExportFileInProgress,
  selectIsModalOpen,
  selectModalParameters,
} from '../selectors';
import './modal.scss';

const mapDispatchToProps = {
  closeModal,
  generateExportFile,
};

export function getExportFileState(ef) {
  if (!ef) {
    return 'STARTING';
  } else if (ef.steps.length === 0) {
    return 'STARTING';
  } else if (ef.steps.filter((step) => step.status === 'ERROR').length > 0) {
    return 'ERROR';
  } else if (ef.steps.filter((step) => step.name === 'upload').length > 0) {
    return 'DONE';
  }
  return 'IN_PROGRESS';
}

const mapStateToProps = (state) => ({
  isOpen: selectIsModalOpen(state),
  startingExportParameters: selectModalParameters(state),
  isExportFileInProgress: selectIsExportFileInProgress(state),
});

export class ExportFileModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    startingExportParameters: PropTypes.object.isRequired,
    isExportFileInProgress: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      closeModal: PropTypes.func.isRequired,
      generateExportFile: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      configurationExport: JSON.stringify(
        props.startingExportParameters,
        null,
        '\t'
      ),
    };
  }

  onChange = (newConfigurationExport) => {
    this.setState({
      configurationExport: newConfigurationExport,
    });
  };

  exportFile = () => {
    if (this.props.isExportFileInProgress === true) {
      return;
    }
    const { configurationExport } = this.state;
    this.props.actions.generateExportFile(JSON.parse(configurationExport));
  };

  renderBodyConfiguration() {
    const { configurationExport } = this.state;
    return (
      <div className="ExportFileModal_Body">
        <CodeEditor
          name="export-configuration"
          className="ReplayDetailsModal_Parameters"
          value={configurationExport}
          mode="json"
          theme="monokai"
          width="100%"
          height="100%"
          wrapEnabled
          editorProps={{ $blockScrolling: Infinity }}
          onChange={this.onChange}
        />
      </div>
    );
  }

  renderBodyPoll() {
    return (
      <div className="ExportFileModal_Body_Poll">
        <div>
          <Spinner loading big />
        </div>
        <div>Your file is being created... :)</div>
      </div>
    );
  }

  render() {
    const { actions, isOpen, isExportFileInProgress } = this.props;
    if (!isOpen) {
      return null;
    }
    return (
      <Modal
        title="Export data (experimental)"
        modalStyle="default"
        confirmButtonText="Export"
        onConfirm={this.exportFile}
        isProcessing={isExportFileInProgress}
        onClose={actions.closeModal}
      >
        {isExportFileInProgress
          ? this.renderBodyPoll()
          : this.renderBodyConfiguration()}
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(ExportFileModal);
