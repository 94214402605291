import * as types from '../../types';
import { useDispatch, useSelector } from 'react-redux';
import { selectQueueStatus } from '../../selectors';
import { useEffect } from 'react';
import * as actions from '../../actions';

export function IndexerQueueStatus() {
  const status: types.QueueStatus | null = useSelector(selectQueueStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchQueueStatus());
  }, [dispatch]);

  if (!status) {
    return <h3>Pending: ... | In progress: ... | Total: ...</h3>;
  }

  return (
    <h3>{`Pending: ${status.pending.toLocaleString()} | In progress: ${status.in_progress.toLocaleString()} | Total: ${status.total.toLocaleString()}`}</h3>
  );
}
