import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { Spinner } from '@alkem/react-ui-spinner';

import ReferentialPictureItem from './item';
import { selectIsLoading, selectReferentialEntities } from '../../selectors';

import './index.scss';

const mapStateToProps = (state) => ({
  isLoading: selectIsLoading(state),
  list: selectReferentialEntities(state),
});

export class ReferentialPicturesList extends PureComponent {
  static propTypes = {
    list: ImmutablePropTypes.list,
    isLoading: PropTypes.bool,
  };

  render() {
    const { isLoading, list } = this.props;
    return (
      <div className="ReferentialPicturesList">
        {isLoading && (
          <div className="ReferentialPicturesList__spinner">
            <Spinner big primary loading />
          </div>
        )}
        <div className="ReferentialPicturesList__container">
          {!isLoading &&
            list
              .map((referentialEntity) => (
                <ReferentialPictureItem
                  key={referentialEntity.get('id')}
                  referentialEntity={referentialEntity}
                />
              ))
              .toJS()}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, {})(ReferentialPicturesList);
