import { useState } from 'react';
import InputWithLabel from 'components/input-with-label';
import classNames from 'classnames';
import { Radio, Text, Textarea } from '@alkem/react-ui-inputs';
import { Button } from '@alkem/react-ui-button';
import { HelpTooltip } from '@alkem/react-ui-helptooltip';

import {
  createXmlFile,
  getStringWithoutLineBreaks,
} from 'modules/io/gdsn/imports/utils';
import {
  ImportMappingDetails,
  ImportMappingDetailsFallback,
} from '../../../../types';
import './item-fallback-property.scss';
import { useSelector } from 'react-redux';
import { selectReferentialCodes } from 'modules/io/gdsn/imports/selectors';

interface Props {
  details: ImportMappingDetails;
  dispatchImportMappingFallback: (
    fallback: ImportMappingDetailsFallback
  ) => void;
  fallback: ImportMappingDetailsFallback;
  fieldName: string;
  parents?: ImportMappingDetails[];
}
export function ImportMappingsItemFallbackProperty({
  details,
  dispatchImportMappingFallback,
  fallback,
  fieldName,
  parents = [],
}: Props): JSX.Element {
  const referentialCodes = useSelector(selectReferentialCodes);
  const [xpaths, setXpaths] = useState(fallback.xpath);
  const [indexEdition, setIndexEdition] = useState<number | undefined>(
    fallback.xpath.length === 1 && fallback.xpath[0] === '' ? 0 : undefined
  );
  const [editedXpath, setEditedXpath] = useState('');
  const [customParse, setCustomParse] = useState(
    fallback.custom_parse || fallback.custom_parse_all || ''
  );
  const [mustFindAllNodes, setMustFindAllNodes] = useState(
    fallback.custom_parse_all ? true : undefined
  );
  const [isMandatory, setIsMandatory] = useState(fallback.mandatory);
  const [messageWhenMandatory, setMessageWhenMandatory] = useState(
    fallback.message
  );

  const setAndDispatchXpath = (value: string[]) => {
    setXpaths(value);
    dispatchImportMappingFallback({ ...fallback, xpath: value });
  };

  const editXpath = (value: string, index: number): void => {
    setEditedXpath(value);
    setIndexEdition(index);
  };

  const dowloadXml = (xpath: string): void => {
    createXmlFile(
      fieldName,
      {
        ...details,
        custom_parse: fallback.custom_parse,
        custom_parse_all: fallback.custom_parse_all,
      },
      xpath,
      [...parents.slice().reverse()],
      referentialCodes
    );
  };

  const updateEditedXpath = (event: { target: { value: string } }): void => {
    setEditedXpath(getStringWithoutLineBreaks(event.target.value));
  };

  const updateXpaths = (index: number): void => {
    const newXpaths = [...xpaths];
    newXpaths[index] = editedXpath;
    setAndDispatchXpath(newXpaths);
    setIndexEdition(undefined);
  };

  const removeXpathValue = (index: number): void => {
    let newXpaths = [...xpaths];
    setEditedXpath('');
    if (newXpaths.length === 1) {
      newXpaths[0] = '';
      setIndexEdition(0);
    } else {
      newXpaths = newXpaths.filter((_, idx) => idx !== index);
      setIndexEdition(undefined);
    }
    setXpaths(newXpaths);
    setAndDispatchXpath(newXpaths);
  };

  const addXpathValue = (): void => {
    const newXpaths = [...xpaths];
    newXpaths.push('');
    setEditedXpath('');
    setXpaths(newXpaths);
    setIndexEdition(newXpaths.length - 1);
  };

  const updateCustomParse = (event: { target: { value: string } }) => {
    const fallbackCustomParse = getStringWithoutLineBreaks(event.target.value);
    setCustomParse(fallbackCustomParse);
    dispatchImportMappingFallback({
      ...fallback,
      [mustFindAllNodes ? 'custom_parse_all' : 'custom_parse']:
        fallbackCustomParse,
    });
  };

  const updateMustFindAllNodes = (event: { target: { value: string } }) => {
    const fallbackMustFindall =
      event.target.value === 'true' ? true : undefined;
    setMustFindAllNodes(fallbackMustFindall);
    dispatchImportMappingFallback({
      ...fallback,
      custom_parse: fallbackMustFindall ? undefined : customParse,
      custom_parse_all: fallbackMustFindall ? customParse : undefined,
    });
  };

  const updateIsMandatory = (event: { target: { value: string } }) => {
    const fallbackIsMandatory =
      event.target.value === 'true' ? true : undefined;
    const fallbackMessageWhenMandatory = !fallbackIsMandatory ? undefined : '';
    setIsMandatory(fallbackIsMandatory);
    if (!fallbackIsMandatory)
      setMessageWhenMandatory(fallbackMessageWhenMandatory);
    dispatchImportMappingFallback({
      ...fallback,
      mandatory: fallbackIsMandatory,
      message: fallbackMessageWhenMandatory,
    });
  };

  const updateMessageWhenMandatory = (event: { target: { value: string } }) => {
    const fallbackMessageWhenMandatory = event.target.value;
    setMessageWhenMandatory(fallbackMessageWhenMandatory);
    dispatchImportMappingFallback({
      ...fallback,
      message: fallbackMessageWhenMandatory,
    });
  };

  const fallbackIndex = fallback.index;
  return (
    <div
      data-testid="GDSNImportMappingsItem_fallback"
      className="GDSNImportMappingsItemFallbackProperty"
    >
      <InputWithLabel
        inputId={`gdsn-import-mappings-field-${fieldName}-${fallbackIndex}-fallback-property-xpaths`}
        label={
          <span>
            xpath{' '}
            <HelpTooltip
              id={`gdsn-import-mappings-field-${fieldName}-${fallbackIndex}-fallback-property-xpaths`}
              place="top"
              message="Make sure to validate the xpath with the check icon for it to be taken in account. If no xpath is saved, the fallback properties won't be saved."
            />
          </span>
        }
      >
        {xpaths.map((xpath, index) => (
          <div
            key={`gdsn-import-mappings-field-${fieldName}-${fallbackIndex}-fallback-property-xpath-${index}`}
            className="GDSNImportMappingsItemFallbackProperty__sectionTextbox"
          >
            <Textarea
              id={`gdsn-import-mappings-field-${fieldName}-${fallbackIndex}-fallback-property-xpath-${index}`}
              value={index === indexEdition ? editedXpath : xpath}
              autoresize
              onChange={updateEditedXpath}
              disabled={index !== indexEdition}
              onKeyDown={(e) => {
                if (e.key === 'Enter') e.preventDefault();
              }}
            />
            <div className="GDSNImportMappingsItemFallbackProperty__spansContainer">
              {indexEdition === index ? (
                <>
                  <span
                    className={classNames({
                      'mdi mdi-check': true,
                      'GDSNImportMappingsItemFallbackProperty__span--disabled':
                        editedXpath === '',
                      'GDSNImportMappingsItemFallbackProperty__span--enabled':
                        editedXpath !== '',
                    })}
                    onClick={() => updateXpaths(index)}
                  />
                  <span
                    className="mdi mdi-delete GDSNImportMappingsItemFallbackProperty__span--enabled"
                    onClick={() => removeXpathValue(index)}
                  />
                </>
              ) : (
                <>
                  <span
                    className={classNames({
                      'mdi mdi-download': true,
                      'GDSNImportMappingsItemFallbackProperty__span--disabled':
                        indexEdition !== undefined && xpath !== '',
                      'GDSNImportMappingsItemFallbackProperty__span--enabled':
                        indexEdition === undefined,
                    })}
                    onClick={() => dowloadXml(xpath)}
                  />
                  <span
                    className={classNames({
                      'mdi mdi-pencil': true,
                      'GDSNImportMappingsItemFallbackProperty__span--disabled':
                        indexEdition !== undefined,
                      'GDSNImportMappingsItemFallbackProperty__span--enabled':
                        indexEdition === undefined,
                    })}
                    onClick={() => editXpath(xpath, index)}
                  />
                  <span
                    className={classNames({
                      'mdi mdi-delete': true,
                      'GDSNImportMappingsItemFallbackProperty__span--disabled':
                        indexEdition !== undefined,
                      'GDSNImportMappingsItemFallbackProperty__span--enabled':
                        indexEdition === undefined,
                    })}
                    onClick={() => removeXpathValue(index)}
                  />
                </>
              )}
            </div>
          </div>
        ))}
      </InputWithLabel>
      <div className="GDSNImportMappingsItemFallbackProperty__buttonContainer">
        <Button
          testid="GDSNImportMappingsItemFallbackProperty__button"
          content="Add xpath"
          onClick={addXpathValue}
          secondary
          disabled={indexEdition !== undefined}
        />
      </div>
      <InputWithLabel
        inputId={`gdsn-import-mappings-field-${fieldName}-${fallbackIndex}-fallback-property-custom-parse`}
        label="Custom parse"
      >
        <Textarea
          id={`gdsn-import-mappings-field-${fieldName}-${fallbackIndex}-fallback-property-custom-parse`}
          value={customParse || ''}
          autoresize
          onChange={updateCustomParse}
          onKeyDown={(e) => {
            if (e.key === 'Enter') e.preventDefault();
          }}
        />
      </InputWithLabel>
      <InputWithLabel
        inputId={`gdsn-import-mappings-field-${fieldName}-${fallbackIndex}-fallback-property-must-find-all-nodes`}
        label="Must find all nodes for custom parse"
      >
        <Radio
          id={`gdsn-import-mappings-field-${fieldName}-${fallbackIndex}-fallback-property-must-find-all-nodes`}
          value={mustFindAllNodes}
          options={[
            { label: 'TRUE', value: true },
            { label: 'FALSE', value: undefined },
          ]}
          onChange={updateMustFindAllNodes}
        />
      </InputWithLabel>
      <InputWithLabel
        inputId={`gdsn-import-mappings-field-${fieldName}-${fallbackIndex}-fallback-property-is-mandatory`}
        label="Is mandatory"
      >
        <Radio
          id={`gdsn-import-mappings-field-${fieldName}-${fallbackIndex}-fallback-property-is-mandatory`}
          value={isMandatory}
          options={[
            { label: 'TRUE', value: true },
            { label: 'FALSE', value: undefined },
          ]}
          onChange={updateIsMandatory}
        />
      </InputWithLabel>
      <InputWithLabel
        inputId={`gdsn-import-mappings-field-${fieldName}-${fallbackIndex}-fallback-property-message-when-mandatory`}
        label="Message"
      >
        <Text
          id={`gdsn-import-mappings-field-${fieldName}-${fallbackIndex}-fallback-property-message-when-mandatory`}
          value={messageWhenMandatory || ''}
          onChange={updateMessageWhenMandatory}
          disabled={!isMandatory}
        />
      </InputWithLabel>
    </div>
  );
}
