export const INIT = 'translation/INIT';

export const FETCH_FILTERS = 'translation/FETCH_FILTERS';
export const RECEIVE_FILTERS = 'translation/RECEIVE_FILTERS';
export const UPDATE_SELECTED_CURRENT = 'translation/UPDATE_SELECTED_CURRENT';
export const UPDATE_SELECTED_FILENAME = 'translation/UPDATE_SELECTED_FILENAME';
export const UPDATE_SELECTED_LANG = 'translation/UPDATE_SELECTED_LANG';

export const FETCH_FILES = 'translation/FETCH_FILES';
export const RECEIVE_FILES = 'translation/RECEIVE_FILES';

export const UPDATE_LATEST_FILE = 'translation/UPDATE_LATEST_FILE';
export const FETCH_ONE_FILE = 'translation/FETCH_ONE_FILE';
export const FETCHED_ONE_FILE = 'translation/FETCHED_ONE_FILE';
export const DISCARD_ONE_FILE = 'translation/DISCARD_ONE_FILE';
export const DOWNLOAD_ONE_TRANSLATION = 'translation/DOWNLOAD_ONE_TRANSLATION';
export const DOWNLOAD_ALL_TRANSLATIONS =
  'translation/DOWNLOAD_ALL_TRANSLATIONS';
