import * as events from './events';

export const init = () => ({ type: events.INIT });

export const fetchGroups = () => ({ type: events.FETCH_GROUPS });

export const updateSearch = (search) => ({
  type: events.UPDATE_SEARCH,
  search,
});

export const edit = (group) => ({ type: events.EDIT, group });

export const updateName = (name) => ({ type: events.UPDATE_NAME, name });

export const upload = (picture) => ({ type: events.UPLOAD_PICTURE, picture });

export const save = () => ({ type: events.SAVE });
