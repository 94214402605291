import React from 'react';

import { ModuleWrapper } from 'redux/register';

import reducer, { REDUCER_KEY } from './reducers';
import PumpConfigList from './components';
import saga from './sagas';

export default function PumpConfigListContainer() {
  return (
    <ModuleWrapper reducerKey={REDUCER_KEY} reducer={reducer} saga={saga}>
      <PumpConfigList />
    </ModuleWrapper>
  );
}
