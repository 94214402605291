import React from 'react';

import { ModuleWrapper } from 'redux/register';

import { REDUCER_KEY } from './constants';
import reducer from './reducers';
import { UserUpdate } from './components';

export default function UserUpdateContainer(props) {
  return (
    <ModuleWrapper reducerKey={REDUCER_KEY} reducer={reducer}>
      <UserUpdate {...props} />
    </ModuleWrapper>
  );
}
