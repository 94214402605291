import { HeaderLayout } from '@alkem/react-layout';
import { ErrorFallback } from 'components/error/ErrorFallback';
import { PayloadModal } from 'components/payload-modal';
import * as routes from 'constants/routes';
import Immutable from 'immutable';
import qs from 'querystringify';
import { memo, useEffect, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import { closePayload, loadFilters } from './actions';
import { TransactionExplorerList } from './components/list';
import { selectModal } from './selectors';

interface Props {
  location: Location;
}

export const TransactionExplorerView = memo(({ location }: Props) => {
  const dispatch = useDispatch();
  const modal = useSelector<any, Immutable.Map<string, any>>(selectModal);

  const { transaction_id } = useMemo(
    () => qs.parse(location.search) as { transaction_id: string },
    [location.search]
  );

  useEffect(() => {
    const transactionId = Number(transaction_id);
    dispatch(loadFilters(transactionId));
  }, [dispatch, transaction_id]);

  return (
    <>
      <HeaderLayout
        title="Transaction explorer"
        backHref={routes.home}
        backMessage="Back home"
        isTitleSmall
      />
      <ErrorBoundary
        FallbackComponent={({ error }) => <ErrorFallback error={error} />}
      >
        <div className="container-fluid row">
          <div className="col-md-12">
            <TransactionExplorerList />
          </div>
        </div>
        {modal.get('show') && (
          <PayloadModal
            payload={modal.get('payload')}
            onClose={() => dispatch(closePayload())}
          />
        )}
      </ErrorBoundary>
    </>
  );
});
