import { createBrowserHistory } from 'history';
import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import qs from 'querystringify';

export const history = createBrowserHistory();

export function forwardTo(location) {
  history.push(location);
}

export default history;

export function useQueryParams<T = { [key: string]: string }>() {
  const { search } = useLocation();
  return useMemo(() => qs.parse(search) as T, [search]);
}

export function useUpdateQueryParams() {
  const { push } = useHistory();
  const { search, pathname } = useLocation();
  return useCallback(
    (params: { [key: string]: any }) => {
      const currentParams = qs.parse(search);
      push(`${pathname}?${qs.stringify({ ...currentParams, ...params })}`);
    },
    [push, search, pathname]
  );
}
