import { useState } from 'react';
import { Context } from '../types';
import './invalid-message.scss';

export function EdaRmqReplayerQueueInvalidMessage({
  context,
}: {
  context: Context;
}) {
  const [isTraceBackVisible, setIsTraceBackVisible] = useState(false);
  return (
    <div>
      <div>lib-alexandria: {context.alexandria_version}</div>
      <div className="EdaRmqReplayerQueueInvalidMessage__content">
        <i className="mdi mdi-alert-outline" /> exception: {context.exc}
      </div>
      <div>
        <i
          className={`EdaRmqReplayerQueueInvalidMessage__traceIcon mdi mdi-eye${
            isTraceBackVisible ? '-off' : ''
          }`}
          onClick={() => setIsTraceBackVisible(!isTraceBackVisible)}
        />{' '}
        trace
        {isTraceBackVisible && <pre>{context.traceback}</pre>}
      </div>
    </div>
  );
}
