import { Checkbox } from '@alkem/react-ui-checkbox';
import { Modal } from '@alkem/react-ui-modal';
import { LegacyAutocomplete } from 'components/autocomplete';
import { ruleEntityTypesMap } from 'modules/validation-dashboard/constants';
import { Rule, RuleSet } from 'modules/validation-dashboard/types';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { validationApiJS } from 'resources/validationApi';
import './bulk-edit-rules-modal.scss';

export interface BulkEditProps {
  id: string;
  rules: Array<Rule>;
  onCloseAction: () => void;
  title: string;
  confirmButtonText: string;
  eligibleRulesHeader: string;
  ineligibleRulesHeader: string;
  requiresRuleSetSelection: boolean;
  performAction: (
    selectedRuleIds: number[],
    selectedRuleSet: RuleSet | undefined
  ) => void;
}

export const BulkEditRulesModal = ({
  rules,
  onCloseAction,
  eligibleRulesHeader,
  ineligibleRulesHeader,
  requiresRuleSetSelection,
  performAction,
  ...props
}: BulkEditProps) => {
  const [rulesetSelected, selectRuleset] = useState<RuleSet>();
  const onSearch = (search) => validationApiJS.listRuleSets(search);

  const [checkedRules, setCheckedRules] = useState({});

  const onSelect = (rulesetSelected) => {
    selectRuleset(rulesetSelected);
  };
  const onUnselect = () => {
    selectRuleset(undefined);
  };

  const isRuleSelected = useCallback(
    (ruleId) => {
      return ruleId in checkedRules ? checkedRules[ruleId] : true;
    },
    [checkedRules]
  );

  function onChecked(ruleId: number) {
    return (checked: boolean) => {
      setCheckedRules((prevState) => ({
        ...prevState,
        [ruleId]: checked,
      }));
    };
  }

  const selectedRulesetValue = rulesetSelected
    ? [
        {
          key: rulesetSelected.id,
          label: rulesetSelected.id + ' - ' + rulesetSelected.label,
        },
      ]
    : [];

  const filterRulesList = (isAssignable: boolean) => {
    if (isAssignable) {
      return rules.filter((rule) => {
        return rule.ruleSet;
      });
    } else {
      return rules.filter((rule) => {
        return !rule.ruleSet;
      });
    }
  };

  const assignableRules: Rule[] = filterRulesList(true);
  const assignableRulesGroupedByRuleset = assignableRules.reduce(
    (groupedRules, rule) => {
      const key = `${rule.ruleSet?.id} - ${rule.ruleSet?.label} (${rule.ruleSet?.type})`;
      (groupedRules[key] = groupedRules[key] || []).push(rule);
      return groupedRules;
    },
    {}
  );

  const eligibleSelectedRules: Rule[] = useMemo(() => {
    return assignableRules.filter((rule) => isRuleSelected(rule.id));
  }, [isRuleSelected, assignableRules]);

  const nonEligibleRules: Rule[] = filterRulesList(false);

  const confirmAction = () => {
    const selectedRuleIds = eligibleSelectedRules.map((rule) => rule.id);

    performAction(selectedRuleIds, rulesetSelected);
    onCloseAction();
  };

  function renderEligibleRule(rule) {
    const ruleLabel = `Rule ${rule.id}: ${rule.errorMessage} - ${
      ruleEntityTypesMap[rule.ruleEntityType].label
    }`;
    return (
      <li
        key={'selected-rule-key-' + rule.id}
        className={'liRuleSelected'}
        data-testid={'selected-rule-li'}
      >
        <Checkbox
          onChange={onChecked(rule.id)}
          id={'selected-rule-checkbox-' + rule.id}
          testid={'rule-checkbox-' + rule.id}
          checked={isRuleSelected(rule.id)}
          label={ruleLabel}
        />
      </li>
    );
  }

  function renderIneligibleRule(rule: Rule) {
    return (
      <li
        key={'not-selected-rule-key-' + rule.id}
        className={'liRuleSelected error'}
        data-testid={'not-selected-rule-li'}
      >
        Rule {rule.id}: {rule.errorMessage} -{' '}
        {ruleEntityTypesMap[rule.ruleEntityType].label}
      </li>
    );
  }

  return (
    <Modal
      {...props}
      className="BulkEditRulesModal"
      confirmDisabled={
        (requiresRuleSetSelection && !rulesetSelected) ||
        !eligibleSelectedRules.length
      }
      onConfirm={confirmAction}
      closeButtonText="Cancel"
      onClose={onCloseAction}
    >
      {requiresRuleSetSelection && (
        <span>
          <p>
            Select the ruleset to assign the{' '}
            <b>{eligibleSelectedRules.length}</b> selected rule(s) to
            {!!nonEligibleRules.length && (
              <span className="Text__warning">
                {' '}
                (<b>{Object.keys(nonEligibleRules).length}</b> rule(s) cannot be
                assigned to a ruleset, only the{' '}
                <b>{Object.keys(assignableRules).length}</b> assignabled ones
                will be moved)
              </span>
            )}
            .
          </p>
          <LegacyAutocomplete
            id="ValidationSelectRuleset_SelectInput"
            className="ValidationSelectRuleset_SelectInput"
            value={selectedRulesetValue}
            onSelect={onSelect}
            onUnselect={onUnselect}
            placeholder="Select a rule set"
            onSearch={onSearch}
            searchOnClick
          />
        </span>
      )}

      {!!assignableRules.length && (
        <>
          <h3>{eligibleRulesHeader}</h3>
          <ul>
            {Object.keys(assignableRulesGroupedByRuleset).map((ruleset) => {
              return (
                <Fragment key={ruleset}>
                  <b>From ruleset: {ruleset}</b>
                  {assignableRulesGroupedByRuleset[ruleset].map(
                    renderEligibleRule
                  )}
                </Fragment>
              );
            })}
          </ul>
        </>
      )}
      {!!nonEligibleRules.length && (
        <>
          <h3>{ineligibleRulesHeader}</h3>
          <ul>{nonEligibleRules.map(renderIneligibleRule)}</ul>
        </>
      )}
    </Modal>
  );
};
