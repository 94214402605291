export const INIT = 'indexer/queue/INIT';

export const SET_LOADING = 'indexer/queue/SET_LOADING';
export const FETCH_QUEUE_LIST = 'indexer/queue/FETCH_QUEUE_LIST';
export const RECEIVE_QUEUE_LIST = 'indexer/queue/RECEIVE_QUEUE_LIST';
export const RECEIVE_QUEUE_PAGE = 'indexer/queue/RECEIVE_QUEUE_PAGE';
export const FETCH_DETAIL = 'indexer/queue/FETCH_DETAIL';
export const RECEIVE_DETAIL = 'indexer/queue/RECEIVE_DETAIL';

export const SELECT_ORGANIZATION = 'indexer/queue/SELECT_ORGANIZATION';
export const FETCH_SOURCES = 'indexer/queue/FETCH_SOURCES';
export const RECEIVE_SOURCES = 'indexer/queue/RECEIVE_SOURCES';
export const SELECT_SOURCE = 'indexer/queue/SELECT_SOURCE';
export const SELECT_PRIORITY = 'indexer/queue/SELECT_PRIORITY';
export const PREVIOUS_PAGE = 'indexer/queue/PREVIOUS_PAGE';
export const NEXT_PAGE = 'indexer/queue/NEXT_PAGE';
export const CHANGE_PAGE_SIZE = 'indexer/queue/CHANGE_PAGE_SIZE';

export const FETCH_QUEUE_STATUS = 'indexer/queue/FETCH_QUEUE_STATUS';
export const RECEIVE_QUEUE_STATUS = 'indexer/queue/RECEIVE_QUEUE_STATUS';

export const FETCH_ORGANIZATION = 'indexer/queue/FETCH_ORGANIZATION';
export const REINDEX_ORGANIZATION = 'indexer/queue/REINDEX_ORGANIZATION';
export const FETCH_ORGANIZATION_STATISTICS =
  'indexer/queue/FETCH_ORGANIZATION_STATISTICS';
export const RECEIVE_ORGANIZATION_STATISTICS =
  'indexer/queue/RECEIVE_ORGANIZATION_STATISTICS';
