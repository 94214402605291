export type Field = {
  id: number;
  name: string;
  displayInfo?: {
    label: string;
  };
  children?: Field[];
  tags?: string[];
  referential?: string;
  declinable_by?: string;
  type?: string;
  specific?: boolean;
  functional_key?: boolean;
};

export type Fields = Field[];

export type Xpath = {
  id: number;
  name: string;
  type: string;
  order: number;
  children: XpathList;
  xpath: string;
};

export type XpathList = Xpath[];

export type Option = {
  id: number | string;
  label: string;
};

export type Options = Option[];

export type FieldOption = {
  id: number;
  label: string;
  data: Field;
};

export type FieldOptions = FieldOption[];

export type ExportMappingPayloadValueData =
  | string
  | number
  | boolean
  | { code: string }
  | { data: string; expressedIn: { code: string } }[];

export type ExportMappingPayloadValue = {
  cast?: string;
  data?: ExportMappingPayloadValueData;
};

export enum ExportMappingConstantCast {
  FUNCTION = 'function',
  LAMBDA = 'lambda',
}

export type ExportMappingPayloadConstant = {
  cast: string;
  data: string;
};

export type ExportMappingPayload = {
  cast?: string;
  field?: string;
  value?: ExportMappingPayloadValue;
  custom_parse?: string;
  use_su?: boolean;
  referential?: string;
  is_declinable?: boolean;
  constants_for_later_use?: { [key: string]: ExportMappingPayloadConstant };
};

export type ExportMapping = {
  id?: number;
  date_start: string;
  date_end: string;
  order: number;
  xpath_id: number;
  parent_id?: number;
  is_fallback?: boolean;
  payload: ExportMappingPayload;
};

export type ExportMappings = ExportMapping[];

export interface GDSNExportMappingsState {
  customParseOptions: Options;
  exportMapping?: ExportMapping;
  exportMappings: ExportMappings;
  isSavingInProcess: boolean;
  listFieldOptions: FieldOptions;
  fieldOptions: FieldOptions;
  functionValueOptions: Options;
  functionNotValueOptions: Options;
  referentialCodeOptions: { [key: string]: Options };
  xpathList: XpathList;
}

export type RegularOption = {
  id: string;
  label: string;
};

export type RegularOptions = RegularOption[];
