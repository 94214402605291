import {
  ImportMapping,
  ImportMappingDetails,
  ImportMappingDetailsChildren,
} from '../../../types';
import { ImportMappingsItemChild } from './child';

import './item-children.scss';

interface Props {
  importMapping: ImportMapping;
  importMappingChildren?: ImportMappingDetailsChildren;
  isFirst?: boolean;
  parent?: ImportMappingDetails;
  parentName?: string;
  parents?: ImportMappingDetails[];
  parentNames?: string[];
}
export function ImportMappingsItemChildren({
  importMapping,
  importMappingChildren,
  isFirst = false,
  parent,
  parentName,
  parents = [],
  parentNames = [],
}: Props): JSX.Element {
  if (
    !importMappingChildren ||
    Object.entries(importMappingChildren).length === 0
  )
    return <div />;
  return (
    <div
      className="GDSNImportMappingsItemChildren"
      data-testid="GDSNImportMappingsItem_children"
    >
      <div className="GDSNImportMappingsItemChildren__sectionTitle">
        Children
      </div>
      {Object.entries(importMappingChildren).map(([childName, child]) => (
        <ImportMappingsItemChild
          key={childName}
          child={child}
          childName={childName}
          importMapping={importMapping}
          isFirst={isFirst}
          parent={parent}
          parentName={parentName}
          parentNames={parentNames}
          parents={parents}
        />
      ))}
    </div>
  );
}
