import { flow, get } from 'lodash/fp';

import { REDUCER_KEY } from '../constants';
import { GDSNExportMappingsState } from '../types';

const selectState: (globalState: any) => GDSNExportMappingsState = get([
  'module',
  REDUCER_KEY,
]);

export const selectCustomParseOptions = flow(
  selectState,
  (state) => state.customParseOptions
);
export const selectFunctionValueOptions = flow(
  selectState,
  (state) => state.functionValueOptions
);
export const selectFunctionNotValueOptions = flow(
  selectState,
  (state) => state.functionNotValueOptions
);
export const selectExportMapping = flow(
  selectState,
  (state) => state.exportMapping
);
export const selectExportMappings = flow(
  selectState,
  (state) => state.exportMappings
);
export const selectListFieldOptions = flow(
  selectState,
  (state) => state.listFieldOptions
);
export const selectFieldOptions = flow(
  selectState,
  (state) => state.fieldOptions
);
export const selectReferentialCodeOptions = flow(
  selectState,
  (state) => state.referentialCodeOptions
);
export const selectXpathList = flow(selectState, (state) => state.xpathList);
