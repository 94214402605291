export const USERS_GET = 'users/USERS_GET';
export const USERS_RECEIVE = 'users/USERS_RECEIVE';
export const USERS_NEXT_PAGE = 'users/USERS_NEXT_PAGE';
export const USERS_PREVIOUS_PAGE = 'users/USERS_PREVIOUS_PAGE';
export const USERS_SEARCH = 'users/USERS_SEARCH';
export const USERS_CHANGE_LIMIT = 'users/USERS_CHANGE_LIMIT';
export const USERS_FILTERS_ADD_SINGLE = 'users/USERS_FILTERS_ADD_SINGLE';
export const USERS_FILTERS_REMOVE_SINGLE = 'users/USERS_FILTERS_REMOVE_SINGLE';
export const USERS_FILTERS_CLEAR = 'users/USERS_FILTERS_CLEAR';
export const USERS_ARE_LOADING = 'users/USERS_ARE_LOADING';
