import { immutableAuthApi } from 'resources/authApi';

import * as events from '../constants';
import {
  selectLimit,
  selectCurrentPage,
  selectSearch,
  selectAdminFilters,
  selectOrganizationFilters,
} from '../selectors';

const {
  USERS_RECEIVE,
  USERS_NEXT_PAGE,
  USERS_PREVIOUS_PAGE,
  USERS_SEARCH,
  USERS_CHANGE_LIMIT,
  USERS_FILTERS_ADD_SINGLE,
  USERS_FILTERS_REMOVE_SINGLE,
  USERS_FILTERS_CLEAR,
  USERS_ARE_LOADING,
} = events;

export const computeOffset = (state) => {
  const limit = selectLimit(state);
  const currentPage = selectCurrentPage(state);

  return limit * (currentPage - 1);
};

const constructFilters = (state) => {
  const search = selectSearch(state);
  const filterAdmin = selectAdminFilters(state);
  const filterOrganization = selectOrganizationFilters(state);

  let organizationIds = [];
  if (filterOrganization.size > 0) {
    organizationIds = Object.keys(filterOrganization.toJS());
  }

  const queries = {};
  const filters = {
    withTotalResults: true,
    admin: filterAdmin.size > 0,
    belongsTo_id: organizationIds,
    withPermissions: false,
    withPendingOrganizations: true,
    withType: true,
  };
  if (search && search.length) {
    search
      .split(',')
      .map((w) => w.trim())
      .forEach((w) => {
        if (w && w.length) {
          if (Number.isNaN(Number(w))) {
            // not a number: username: we only match with the last one
            queries.username = w;
          } else {
            // user ids
            filters.ids = filters.ids ? filters.ids : [];
            filters.ids.push(w);
          }
        }
      });
  }

  return {
    queries,
    filters,
  };
};

export const receiveUsers = (list, totalResults) => ({
  type: USERS_RECEIVE,
  list,
  totalResults,
});
export const usersAreLoading = (payload) => ({
  type: USERS_ARE_LOADING,
  payload,
});

let getUsersPromise = null;
export const getUsers = () => (dispatch, getState) => {
  const state = getState();
  const { queries, filters } = constructFilters(state);

  const limit = selectLimit(state);
  const offset = computeOffset(state);
  if (getUsersPromise === null) {
    dispatch(usersAreLoading(true));
    getUsersPromise = immutableAuthApi
      .UserList(queries, filters, offset, limit)
      .then(
        (data) => {
          dispatch(usersAreLoading(false));
          dispatch(receiveUsers(data.data.data, data.data.totalResults));
          getUsersPromise = null;
        },
        () => {
          dispatch(usersAreLoading(false));
          getUsersPromise = null;
        }
      );
  }
  return getUsersPromise;
};

export const nextUsers = () => ({ type: USERS_NEXT_PAGE });
export const previousUsers = () => ({ type: USERS_PREVIOUS_PAGE });
export const searchUsers = (search) => ({ type: USERS_SEARCH, search });
export const changeUsersLimit = (limit) => ({
  type: USERS_CHANGE_LIMIT,
  limit,
});

export const addFilter = (filterType, filterId, filterValue, filterLabel) => ({
  type: USERS_FILTERS_ADD_SINGLE,
  filterType,
  filterId,
  filterValue,
  filterLabel,
});

export const removeFilter = (filterType, filterId) => ({
  type: USERS_FILTERS_REMOVE_SINGLE,
  filterType,
  filterId,
});

export const clearFilters = () => ({ type: USERS_FILTERS_CLEAR });
