import { notificationError } from 'actions/notifications';
import applicationsApi from 'resources/applicationsApi';
import { logError } from 'utils';
import { createAction } from 'utils/reducers';
import { ActionWithPayload } from 'utils/reducers/types';
import { Application } from '@alkem/sdk-dashboard';
import {
  FETCH_APPLICATIONS,
  RECEIVE_APPLICATIONS,
  SAVE_APPLICATIONS_DONE,
  SHOW_MORE_APPLICATIONS,
  UPDATE_APPLICATION,
} from '../constants/events';
import {
  selectApplicationsChanges,
  selectApplicationsDict,
} from '../selectors/applications';
import { selectOrganization } from '../selectors/infos';

export const updateApplication = createAction<{
  applicationId: number;
  isEnabled: boolean;
}>(UPDATE_APPLICATION);

export const showMoreApplications = createAction(SHOW_MORE_APPLICATIONS);

export const fetchApplications =
  () =>
  async (
    dispatch: (action: ActionWithPayload) => void,
    getState: () => any
  ) => {
    dispatch({ type: FETCH_APPLICATIONS });
    try {
      const state = getState();
      const organizationId = selectOrganization(state).get('id');
      const response = await applicationsApi.ApplicationList({
        organizationId,
        useCache: false,
        visibilities: false,
      });
      dispatch({
        payload: response.data.data,
        type: RECEIVE_APPLICATIONS,
      });
    } catch (error) {
      dispatch(
        notificationError('Error while fetching organization applications')
      );
    }
  };

export const saveApplications =
  () =>
  (
    dispatch: (action: ActionWithPayload) => void,
    getState: () => any
  ): Promise<any> => {
    try {
      const state = getState();
      const orgId = selectOrganization(state).get('id');
      const changes = selectApplicationsChanges(state);
      const promises = Object.entries(changes)
        .map(([applicationId, isEnabled]) => {
          if (typeof isEnabled === 'boolean') {
            const appId = parseInt(applicationId, 10);
            return isEnabled
              ? applicationsApi.ApplicationEnable(appId, orgId)
              : applicationsApi.ApplicationDisable(appId, orgId);
          }
          return null;
        })
        .filter((promise) => promise);
      return Promise.all(promises).then(
        () => {
          dispatch({ type: SAVE_APPLICATIONS_DONE });
        },
        (error) => {
          logError(error);
          return Promise.reject(error);
        }
      );
    } catch (error) {
      logError(error);
      dispatch(
        notificationError('Error while updating organization applications')
      );
      return Promise.reject(error);
    }
  };

export const loadApplications =
  (applications: Application[]) => (dispatch, getState) => {
    const currentApplications = selectApplicationsDict(getState());
    Object.values(applications).forEach(
      ({ id: applicationId, enabled: isEnabled }) => {
        if (!currentApplications[applicationId]) {
          return;
        }

        if (currentApplications[applicationId].enabled !== isEnabled) {
          dispatch(updateApplication({ applicationId, isEnabled }));
        }
      }
    );
  };
