import { Button } from '@alkem/react-ui-button';
import { Spinner } from '@alkem/react-ui-spinner';
import ListController from 'components/ui/list/controller';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { selectUser } from 'reducers/user';
import { createStructuredSelector } from 'reselect';
import { separateActions } from 'utils/redux';
import { hasFullPower } from 'utils/user';
import {
  changeLimit,
  getGDSNRCIList,
  nextPage,
  openGepirModal,
  openRCIModal,
  previousPage,
} from '../actions';
import GDSNRCIModal from '../modal';
import GDSNGepirModal from '../modal/gepir';
import {
  selectList,
  selectModal,
  selectModalGepir,
  selectPagination,
  selectState,
} from '../selectors';
import './list.scss';
import GDSNRCIListRow from './row';

const mapStateToProps = createStructuredSelector({
  list: selectList,
  isOpen: (state) => selectModal(state).isOpen,
  isOpenGepirModal: (state) => selectModalGepir(state).isOpen,
  isLoading: (state) => selectState(state).isLoading,
  pagination: selectPagination,
  isSuperAdmin: (state) => hasFullPower(selectUser(state)),
});

const mapDispatchToProps = {
  getGDSNRCIList,
  nextPage,
  previousPage,
  changeLimit,
  openRCIModal,
  openGepirModal,
};

export class GDSNRCIList extends PureComponent {
  static propTypes = {
    list: PropTypes.array.isRequired,
    isOpen: PropTypes.bool,
    isOpenGepirModal: PropTypes.bool,
    isLoading: PropTypes.bool,
    pagination: PropTypes.object.isRequired,
    isSuperAdmin: PropTypes.bool,
    actions: PropTypes.shape({
      getGDSNRCIList: PropTypes.func.isRequired,
      nextPage: PropTypes.func.isRequired,
      previousPage: PropTypes.func.isRequired,
      changeLimit: PropTypes.func.isRequired,
      openRCIModal: PropTypes.func.isRequired,
      openGepirModal: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    isLoading: true,
  };

  componentDidMount() {
    this.props.actions.getGDSNRCIList();
  }

  renderList() {
    const { list, isLoading, isSuperAdmin } = this.props;
    if (isLoading) {
      return (
        <div className="GDSNRCIList__spinner">
          <Spinner loading big />
        </div>
      );
    }
    return list.map((rci) => (
      <GDSNRCIListRow key={rci.id} rci={rci} isSuperAdmin={isSuperAdmin} />
    ));
  }

  actionBar() {
    return (
      <div>
        {this.props.isSuperAdmin && (
          <Button onClick={this.openModal}>
            <i className="mdi mdi-plus" />
          </Button>
        )}
        <Button onClick={this.openGepirModal}>
          <i className="mdi mdi-file-upload" /> Gepir
        </Button>
      </div>
    );
  }

  openModal = () => {
    this.props.actions.openRCIModal();
  };

  openGepirModal = () => {
    this.props.actions.openGepirModal();
  };

  render() {
    const { pagination, actions, isOpen, isOpenGepirModal } = this.props;
    const { currentPage, totalPages, limit, totalResults } = pagination;
    return (
      <div>
        <ListController
          actions={this.actionBar()}
          onNext={actions.nextPage}
          onPrev={actions.previousPage}
          currentPage={currentPage}
          totalPages={totalPages}
          limit={limit}
          totalResults={totalResults}
          onLimitChange={actions.changeLimit}
          rowsLength={0}
          type="endpointlist"
        >
          {this.renderList()}
        </ListController>
        {isOpen && <GDSNRCIModal />}
        {isOpenGepirModal && <GDSNGepirModal />}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(GDSNRCIList);
