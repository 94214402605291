import { HeaderLayout } from '@alkem/react-layout';
import { Button } from '@alkem/react-ui-button';
import * as routes from 'constants/routes';
import { useState, useCallback } from 'react';
import { edaApi } from 'resources/edaApi';
import { logError } from 'utils';
import InputWithLabel from 'components/input-with-label';
import { Text, Textarea } from '@alkem/react-ui-inputs';
import './index.scss';
import { EdaRmqReplayerQueueInvalidMessage } from '../rmq-replayer/components/invalid-message';

async function decodeRawMessage(
  setDecodedEvent,
  eventType: string,
  body: string
) {
  try {
    const res = await edaApi.post('/eda/rmq/v1/view/raw', {
      event_type: eventType,
      body,
    });
    setDecodedEvent(res.data);
  } catch (err: any) {
    logError(err);
    return setDecodedEvent({ context: { exc: err.toString() } });
  }
}

export default function EdaRmqEventViewer() {
  const [eventType, setEventType] = useState<string>('');
  const [rawBody, setRawBody] = useState<string>('');
  const [decodedEvent, setDecodedEvent] = useState<any>(null);
  const decode = useCallback(() => {
    setDecodedEvent(null);
    decodeRawMessage(setDecodedEvent, eventType, rawBody);
  }, [eventType, rawBody]);

  return (
    <div>
      <HeaderLayout
        title="EDA | RMQ event viewer"
        backHref={routes.home}
        backMessage="Back home"
        isTitleSmall
      />
      <div className="EdaViewerContainer">
        <div className="EdaViewer">
          <InputWithLabel inputId="event-type" label="Event type (routing key)">
            <Text
              id="event-type"
              value={eventType}
              onChange={(event) => setEventType(event.target.value)}
            />
          </InputWithLabel>
          <InputWithLabel inputId="event-raw-body" label="Raw body">
            <Textarea
              id="event-raw-body"
              autoresize
              value={rawBody}
              onChange={(event) =>
                setRawBody(event.target.value.replaceAll('\n', ''))
              }
            />
          </InputWithLabel>
          <Button primary onClick={decode}>
            Decode
          </Button>
          {decodedEvent !== null && (
            <div className="EdaViewerDecodedEvent">
              {decodedEvent.context.exc && (
                <EdaRmqReplayerQueueInvalidMessage
                  context={decodedEvent.context}
                />
              )}
              {!decodedEvent.context.exc && (
                <pre>{JSON.stringify(decodedEvent, null, 2)}</pre>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
