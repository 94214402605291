import * as referentialsConstants from 'constants/events/referentials';
import { debounce, uniq, difference } from 'lodash';

import referentialApi from 'resources/referentialApi';

export const receiveReferentialList = (referential, data) => (dispatch) =>
  dispatch({
    type: referentialsConstants.RECEIVE_REFERENTIAL_LIST,
    referential,
    data,
  });

export const receiveMultipleReferentialList = (data) => (dispatch) =>
  dispatch({
    type: referentialsConstants.RECEIVE_MULTIPLE_REFERENTIAL_LIST,
    data,
  });

const getReferentialListPromises = {};

export const getReferential = (referential) => (dispatch) => {
  // Handle cancel
  if (getReferentialListPromises[referential]) {
    getReferentialListPromises[referential].cancel();
  }
  getReferentialListPromises[referential] =
    referentialApi.ReferentialGetList(referential);
  getReferentialListPromises[referential].then(
    (response) => {
      const { data } = response.data;
      return dispatch(receiveReferentialList(referential, data));
    },
    (error) =>
      dispatch({
        type: referentialsConstants.REFERENTIAL_LIST_ERROR,
        referential,
        status: error.status,
      })
  );

  return { type: referentialsConstants.GET_REFERENTIAL_LIST, referential };
};

export const getReferentials = (() => {
  let _referentials = [];

  const getReferentialMultipleList = async (dispatch, getState) => {
    const alreadyLoaded = getState().referentials.list;
    const referentialNames = uniq(difference(_referentials, alreadyLoaded));
    if (referentialNames.length > 0) {
      const response = await referentialApi.ReferentialGetListMultiple(
        referentialNames,
        true /* silent: don't 404 if some referential was not found */,
        { lang: 'noop' }
      );
      dispatch(receiveMultipleReferentialList(response.data));
    }
    _referentials = [];
  };

  getReferentialMultipleList.debounced = debounce(
    getReferentialMultipleList,
    500
  );

  return (referentials) => (dispatch, getState) => {
    _referentials.push(...referentials);
    getReferentialMultipleList.debounced(dispatch, getState);
  };
})();
