export const APPLICATION_TYPE_UNKNOWN = { key: 0, label: 'unknown' };
export const APPLICATION_TYPE_FEATURE = { key: 1, label: 'Feature' };
export const APPLICATION_TYPE_CONTRIBUTION = { key: 2, label: 'Contribution' };
export const APPLICATION_TYPE_CATALOG_ACCESS = {
  key: 3,
  label: 'CatalogAccess',
};
export const APPLICATION_TYPE_ANALYTICS = { key: 4, label: 'Analytics' };
export const APPLICATION_TYPE_CONNECTOR = { key: 5, label: 'Connector' };

export const APPLICATION_TYPES = {
  [APPLICATION_TYPE_UNKNOWN.key]: APPLICATION_TYPE_UNKNOWN,
  [APPLICATION_TYPE_FEATURE.key]: APPLICATION_TYPE_FEATURE,
  [APPLICATION_TYPE_CONTRIBUTION.key]: APPLICATION_TYPE_CONTRIBUTION,
  [APPLICATION_TYPE_CATALOG_ACCESS.key]: APPLICATION_TYPE_CATALOG_ACCESS,
  [APPLICATION_TYPE_ANALYTICS.key]: APPLICATION_TYPE_ANALYTICS,
  [APPLICATION_TYPE_CONNECTOR.key]: APPLICATION_TYPE_CONNECTOR,
};
