import React, { ReactNode } from 'react';
import classNames from 'classnames';
import './styles.scss';

type Props = {
  title: ReactNode;
  children: ReactNode;
  collapsed: boolean;
  toggle: any;
  className?: string;
  simple?: boolean;
};

const Collapsible = React.memo(
  ({ className = '', title, collapsed, children, toggle }: Props) => (
    <div className={classNames('Collapsible', className)}>
      <div className="Collapsible__header" onClick={toggle}>
        <span className="right">
          <span className="Collapsible__collapse-button">
            <i
              className={classNames('mdi', {
                'mdi-chevron-down': collapsed,
                'mdi-chevron-up': !collapsed,
              })}
            />
          </span>
        </span>
        {title}
      </div>
      {!collapsed && (
        <div className={classNames('Collapsible__content')}>{children}</div>
      )}
    </div>
  )
);

export default Collapsible;
