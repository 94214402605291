import { createReducer } from 'utils/reducers';
import {
  SET_EXPORT_MAPPING,
  RECEIVE_XPATH_LIST,
  RECEIVE_CUSTOM_PARSE_OPTIONS,
  SET_IS_SAVING_EXPORT_MAPPING_IN_PROGRESS,
  RECEIVE_EXPORT_MAPPING_LIST,
  RECEIVE_FIELD_OPTIONS,
  RECEIVE_LIST_FIELD_OPTIONS,
  RECEIVE_REFERENTIAL_CODE_OPTIONS,
  RECEIVE_FUNCTION_VALUE_OPTIONS,
  RECEIVE_FUNCTION_NOT_VALUE_OPTIONS,
} from '../actions/constants';
import {
  ExportMapping,
  GDSNExportMappingsState,
  FieldOptions,
  XpathList,
  Options,
  ExportMappings,
} from '../types';
import { ActionWithPayload } from 'utils/reducers/types';

type State = GDSNExportMappingsState;

const initialState: State = {
  customParseOptions: [],
  functionValueOptions: [],
  functionNotValueOptions: [],
  exportMapping: undefined,
  exportMappings: [],
  isSavingInProcess: false,
  listFieldOptions: [],
  fieldOptions: [],
  referentialCodeOptions: {},
  xpathList: [],
};

export default createReducer<State>(initialState, {
  [RECEIVE_CUSTOM_PARSE_OPTIONS]: (
    state,
    { payload }: ActionWithPayload<{ customParseOptions: Options }>
  ) => ({
    ...state,
    customParseOptions: payload?.customParseOptions || [],
  }),
  [RECEIVE_FUNCTION_VALUE_OPTIONS]: (
    state,
    { payload }: ActionWithPayload<{ functionValueOptions: Options }>
  ) => ({
    ...state,
    functionValueOptions: payload?.functionValueOptions || [],
  }),
  [RECEIVE_FUNCTION_NOT_VALUE_OPTIONS]: (
    state,
    { payload }: ActionWithPayload<{ functionNotValueOptions: Options }>
  ) => ({
    ...state,
    functionNotValueOptions: payload?.functionNotValueOptions || [],
  }),
  [RECEIVE_EXPORT_MAPPING_LIST]: (
    state,
    { payload }: ActionWithPayload<{ exportMappings: ExportMappings }>
  ) => ({
    ...state,
    exportMappings: payload?.exportMappings || [],
  }),
  [RECEIVE_LIST_FIELD_OPTIONS]: (
    state,
    { payload }: ActionWithPayload<{ listFieldOptions: FieldOptions }>
  ) => ({
    ...state,
    listFieldOptions: payload?.listFieldOptions || [],
  }),
  [RECEIVE_FIELD_OPTIONS]: (
    state,
    { payload }: ActionWithPayload<{ fieldOptions: FieldOptions }>
  ) => ({
    ...state,
    fieldOptions: payload?.fieldOptions || [],
  }),
  [RECEIVE_REFERENTIAL_CODE_OPTIONS]: (
    state,
    {
      payload,
    }: ActionWithPayload<{
      referential: string;
      referentialCodeOptions?: Options;
    }>
  ) => {
    if (!payload) return { ...state };

    const referentialCodeOptions = { ...state.referentialCodeOptions };
    referentialCodeOptions[payload.referential] =
      payload.referentialCodeOptions || [];
    return {
      ...state,
      referentialCodeOptions,
    };
  },
  [RECEIVE_XPATH_LIST]: (
    state,
    { payload }: ActionWithPayload<{ xpathList?: XpathList }>
  ) => ({
    ...state,
    xpathList: payload?.xpathList || [],
  }),
  [SET_EXPORT_MAPPING]: (
    state,
    { payload }: ActionWithPayload<{ exportMapping: ExportMapping | undefined }>
  ) => ({
    ...state,
    exportMapping: payload?.exportMapping,
  }),
  [SET_IS_SAVING_EXPORT_MAPPING_IN_PROGRESS]: (
    state,
    { payload }: ActionWithPayload<{ isSavingInProgress: boolean }>
  ) => ({
    ...state,
    isSavingInProgress: payload?.isSavingInProgress || false,
  }),
});
