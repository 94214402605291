import { Spinner } from '@alkem/react-ui-spinner';
import {
  accessPolicyDashboardAccessPolicy,
  applicationDashboardAccessPolicy,
  displayGroupDashboardAccessPolicy,
  edaEventCapturesAccessPolicy,
  exchangeDashboardAccessPolicy,
  fieldDashboardAccessPolicy,
  gdsnDasshboardAccessPolicy,
  gdsnExportMappingsDashboardAccessPolicy,
  gdsnImportMappingsDashboardAccessPolicy,
  gdsnRciDashboardAccessPolicy,
  glnDashboardAccessPolicy,
  importExportDashboardAccessPolicy,
  indexerDashboardAccessPolicy,
  kindDashboardAccessPolicy,
  organizationDashboardAccessPolicy,
  organizationGroupDashboardAccessPolicy,
  organizationGroupFieldDashboardAccessPolicy,
  productMaintenanceDashboardAccessPolicy,
  rabbitmqDashboardAccessPolicy,
  referentialDashboardAccessPolicy,
  retailerFieldsDashboardAccessPolicy,
  settingsDashboardAccessPolicy,
  transactionDashboardAccessPolicy,
  translationDashboardAccessPolicy,
  userDashboardAccessPolicy,
  validationDashboardAccessPolicy,
} from 'access-policies';
import * as ROUTES from 'constants/routes';
import ApplicationsDashboardView from 'modules/applications/views';
import Route from 'modules/auth/components/ProtectedRoute';
import LoginView from 'modules/auth/views/LoginView';
import LogoutView from 'modules/auth/views/LogoutView';
import BrandClaimDashboard from 'modules/brand-claim';
import DiscoverServives from 'modules/discover-services';
import DisplayGroupView from 'modules/display-groups/views';
import {
  EdaCapturedEventsModule,
  EdaEventCapturesModule,
  EdaCapturedEventsGraphModule,
} from 'modules/eda/event-capture';
import EdaRmqExplorer from 'modules/eda/rmq-explorer';
import { EdaRmqReplayer } from 'modules/eda/rmq-replayer';
import EdaRmqEventViewer from 'modules/eda/rmq-viewer';
import FieldSuggestionDashboard from 'modules/field-suggestion';
import FieldsPage from 'modules/fields';
import GLNClaimDashboard from 'modules/gln-claim';
import HomeView from 'modules/home';
import { OpenAPIModule } from 'modules/openapi';
import PumpConfiguration from 'modules/indexer/pump';
import IndexingQueue from 'modules/indexer/queue';
import EndpointDashboardView from 'modules/io/endpoint';
import ExportDashboardView from 'modules/io/export';
import GDSNCIDashboardView from 'modules/io/gdsn/ci';
import GDSNCISDashboardView from 'modules/io/gdsn/cis';
import GDSNExportMappingsView from 'modules/io/gdsn/exports';
import GDSNImportMappingsView from 'modules/io/gdsn/imports';
import GDSNPartyDashboardView from 'modules/io/gdsn/party';
import GDSNPSRDashboardView from 'modules/io/gdsn/psr';
import GDSNRCIDashboardView from 'modules/io/gdsn/rci';
import GDSNRelationDashboardView from 'modules/io/gdsn/relation';
import ImportDashboardView from 'modules/io/import';
import ImportGenericDashboardView from 'modules/io/importgeneric';
import { ImportV2DashboardModule } from 'modules/io/importv2';
import MapperAFSDashboardView from 'modules/io/mapper/afs';
import OrganizationFieldGroupsModule from 'modules/organization-group-field';
import OrganizationGroups from 'modules/organization-groups';
import OrganizationDashboardView from 'modules/organization-list';
import OrganizationPageDashboardView from 'modules/organization-page';
import OrganizationUsesFieldsView from 'modules/organization-uses-fields/views';
import ProductMaintenance from 'modules/product-maintenance';
import { ProductReview } from 'modules/product-review';
import ProductWorkflowPlayground from 'modules/product-workflow-playground';
import Rabbitmq from 'modules/rabbitmq';
import ReferentialPicturesDashboard from 'modules/referential-pictures';
import ReferentialsManager from 'modules/referentials';
import ReportingHandlingView from 'modules/reporting-handling';
import RetailerProductPictureComparator from 'modules/retailer-picture-comparator/views';
import TransactionDashboardView from 'modules/transaction';
import TransactionExplorerDashboardView from 'modules/transaction-explorer';
import TranslationDashboardView from 'modules/translation';
import UserDashboardView from 'modules/user-list';
import ValidationDashboard from 'modules/validation-dashboard';
import { Suspense, lazy } from 'react';
import { Link, Redirect, Switch } from 'react-router-dom';
import KindDashboardView from 'views/KindDashboardView/KindDashboardView';

const SettingList = lazy(async () => {
  const { List } = await import('modules/settings');
  return { default: List };
});
const SettingEdit = lazy(async () => {
  const { Edit } = await import('modules/settings');
  return { default: Edit };
});
const AccessPolicy = lazy(async () => {
  const { List } = await import('modules/access-policy');
  return { default: List };
});
const AccessPolicyEdit = lazy(async () => {
  const { Edit } = await import('modules/access-policy');
  return { default: Edit };
});

export default () => (
  <Suspense
    fallback={
      <center style={{ paddingTop: '100px' }}>
        <Spinner loading big />
      </center>
    }
  >
    <Switch>
      <Route exact path={ROUTES.home} component={HomeView} bypassAccessPolicy />
      <Route isPublic path={ROUTES.login} component={LoginView} />
      <Route isPublic path={ROUTES.logout} component={LogoutView} />
      <Route
        path={ROUTES.kinds}
        component={KindDashboardView}
        accessPolicy={kindDashboardAccessPolicy}
      />
      <Route
        path={ROUTES.transactionExplorer}
        component={TransactionExplorerDashboardView}
        accessPolicy={transactionDashboardAccessPolicy}
      />
      <Route
        path={ROUTES.transaction}
        component={TransactionDashboardView}
        accessPolicy={transactionDashboardAccessPolicy}
      />
      <Route
        path={ROUTES.referentials}
        component={ReferentialsManager}
        accessPolicy={referentialDashboardAccessPolicy}
      />
      <Route
        path={ROUTES.organizationUsesFields}
        component={OrganizationUsesFieldsView}
        accessPolicy={retailerFieldsDashboardAccessPolicy}
      />
      <Route
        path={ROUTES.organization}
        component={OrganizationPageDashboardView}
        accessPolicy={organizationDashboardAccessPolicy}
      />
      <Route
        exact
        path={ROUTES.organizations}
        component={OrganizationDashboardView}
        accessPolicy={organizationDashboardAccessPolicy}
      />
      <Route
        exact
        path={ROUTES.reportingHandling}
        component={ReportingHandlingView}
      />
      <Route
        path={ROUTES.applications}
        component={ApplicationsDashboardView}
        accessPolicy={applicationDashboardAccessPolicy}
      />
      <Route
        path={ROUTES.displayGroups}
        component={DisplayGroupView}
        accessPolicy={displayGroupDashboardAccessPolicy}
      />
      <Route
        path={ROUTES.ioImportGeneric}
        component={ImportGenericDashboardView}
        accessPolicy={importExportDashboardAccessPolicy}
      />
      <Route
        path={ROUTES.ioImport}
        component={ImportDashboardView}
        accessPolicy={importExportDashboardAccessPolicy}
      />
      <Route
        path={ROUTES.ioImportV2}
        component={ImportV2DashboardModule}
        accessPolicy={importExportDashboardAccessPolicy}
      />
      <Route
        path={ROUTES.ioExport}
        component={ExportDashboardView}
        accessPolicy={importExportDashboardAccessPolicy}
      />
      <Route
        path={ROUTES.ioEndpoints}
        component={EndpointDashboardView}
        accessPolicy={exchangeDashboardAccessPolicy}
      />
      <Route
        path={ROUTES.ioGdsnCi}
        component={GDSNCIDashboardView}
        accessPolicy={gdsnDasshboardAccessPolicy}
      />
      <Route
        path={ROUTES.ioGdsnCis}
        component={GDSNCISDashboardView}
        accessPolicy={gdsnDasshboardAccessPolicy}
      />
      <Route path={ROUTES.ioGdsnParty} component={GDSNPartyDashboardView} />
      <Route
        path={ROUTES.ioGdsnRci}
        component={GDSNRCIDashboardView}
        accessPolicy={gdsnRciDashboardAccessPolicy}
      />
      <Route path={ROUTES.ioGdsnPsr} component={GDSNPSRDashboardView} />
      <Route
        path={ROUTES.ioGdsnRelation}
        component={GDSNRelationDashboardView}
      />
      <Route
        path={ROUTES.ioGdsnImports}
        component={GDSNImportMappingsView}
        accessPolicy={gdsnImportMappingsDashboardAccessPolicy}
      />
      <Route
        path={ROUTES.ioGdsnExports}
        component={GDSNExportMappingsView}
        accessPolicy={gdsnExportMappingsDashboardAccessPolicy}
      />
      <Route
        exact
        path={ROUTES.ioMapperAfs}
        component={MapperAFSDashboardView}
      />
      <Route
        exact
        path={ROUTES.ioMapperAfsCategories}
        component={MapperAFSDashboardView}
      />
      <Route
        exact
        path={ROUTES.ioMapperAfsCategory}
        component={MapperAFSDashboardView}
      />
      <Route
        exact
        path={ROUTES.ioMapperAfsAttributes}
        component={MapperAFSDashboardView}
      />
      <Route exact path={ROUTES.servicesOpenapi} component={OpenAPIModule} />
      <Route
        exact
        path={ROUTES.ioMapperAfsAttribute}
        component={MapperAFSDashboardView}
      />
      <Route
        exact
        path={ROUTES.ioMapperAfsFunctions}
        component={MapperAFSDashboardView}
      />
      <Route
        exact
        path={ROUTES.ioMapperAfsFunction}
        component={MapperAFSDashboardView}
      />
      <Route
        path={ROUTES.validationDashboard}
        component={ValidationDashboard}
        accessPolicy={validationDashboardAccessPolicy}
      />
      <Route
        path={ROUTES.validationRuleEditor}
        component={ValidationDashboard}
        accessPolicy={validationDashboardAccessPolicy}
      />
      <Route
        path={ROUTES.users}
        component={UserDashboardView}
        accessPolicy={userDashboardAccessPolicy}
      />
      <Route
        path={ROUTES.pictureComparator}
        component={RetailerProductPictureComparator}
      />
      <Route
        path={ROUTES.fieldSuggestion}
        component={FieldSuggestionDashboard}
      />
      <Route
        path={ROUTES.productWorkflowPlay}
        component={ProductWorkflowPlayground}
      />
      <Route path={ROUTES.brandClaims} component={BrandClaimDashboard} />
      <Route
        path={ROUTES.glnClaims}
        component={GLNClaimDashboard}
        accessPolicy={glnDashboardAccessPolicy}
      />
      <Route
        path={ROUTES.referentialPictures}
        component={ReferentialPicturesDashboard}
      />
      <Route path={ROUTES.servicesDiscover} component={DiscoverServives} />
      <Route
        path={ROUTES.rabbitmq}
        component={Rabbitmq}
        accessPolicy={rabbitmqDashboardAccessPolicy}
      />
      <Route
        path={ROUTES.indexerQueue}
        component={IndexingQueue}
        accessPolicy={indexerDashboardAccessPolicy}
      />
      <Route path={ROUTES.indexerConfig} component={PumpConfiguration} />
      <Route
        path={ROUTES.edaRmqReplayer}
        component={EdaRmqReplayer}
        accessPolicy={rabbitmqDashboardAccessPolicy}
      />
      <Route
        path={ROUTES.edaRmqExplorer}
        component={EdaRmqExplorer}
        accessPolicy={rabbitmqDashboardAccessPolicy}
      />
      <Route
        path={ROUTES.edaEventCaptures}
        component={EdaEventCapturesModule}
        accessPolicy={edaEventCapturesAccessPolicy}
      />
      <Route
        path={ROUTES.edaCapturedEventsGraph}
        component={EdaCapturedEventsGraphModule}
        accessPolicy={edaEventCapturesAccessPolicy}
      />
      <Route
        path={ROUTES.edaCapturedEvents}
        component={EdaCapturedEventsModule}
        accessPolicy={edaEventCapturesAccessPolicy}
      />
      <Route
        path={ROUTES.edaRmqViewer}
        component={EdaRmqEventViewer}
        accessPolicy={rabbitmqDashboardAccessPolicy}
      />
      <Route
        path={ROUTES.fieldsPage}
        component={FieldsPage}
        accessPolicy={fieldDashboardAccessPolicy}
      />
      <Route
        path={ROUTES.organizationGroups}
        component={OrganizationGroups}
        accessPolicy={organizationGroupDashboardAccessPolicy}
      />
      <Route
        path={ROUTES.organizationGroupField}
        component={OrganizationFieldGroupsModule}
        accessPolicy={organizationGroupFieldDashboardAccessPolicy}
      />
      <Route
        path={ROUTES.organizationGroupFieldRoot}
        component={OrganizationFieldGroupsModule}
        accessPolicy={organizationGroupFieldDashboardAccessPolicy}
      />
      <Route
        exact
        path={ROUTES.settings}
        component={SettingList}
        accessPolicy={settingsDashboardAccessPolicy}
      />
      <Route
        path={ROUTES.setting}
        component={SettingEdit}
        accessPolicy={settingsDashboardAccessPolicy}
      />
      <Route
        exact
        path={ROUTES.accessPolicy}
        component={AccessPolicy}
        accessPolicy={accessPolicyDashboardAccessPolicy}
      />
      <Route
        path={ROUTES.accessPolicyEdit}
        component={AccessPolicyEdit}
        accessPolicy={accessPolicyDashboardAccessPolicy}
      />
      <Route
        path={ROUTES.productMaintenance}
        component={ProductMaintenance}
        accessPolicy={productMaintenanceDashboardAccessPolicy}
      />
      <Route path={ROUTES.productReview} component={ProductReview} />
      <Route
        path={ROUTES.translation}
        component={TranslationDashboardView}
        accessPolicy={translationDashboardAccessPolicy}
      />
      <Route
        isPublic
        key="legacy-admin-route"
        path="/admin"
        render={({ location }) => (
          <Redirect
            to={location.pathname.replace(/^\/admin/, '').trim() || ROUTES.home}
          />
        )}
      />
      <Route
        isPublic
        key="route-not-found"
        path="/"
        render={() => (
          <h1 style={{ paddingTop: '25vh' }}>
            <center>
              <p>404 not found</p>
              <Link to="/">go back</Link>
            </center>
          </h1>
        )}
      />
    </Switch>
  </Suspense>
);
