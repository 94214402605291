import {
  STATUS_BAR_IN_PROGRESS,
  STATUS_BAR_SUCCESS,
  STATUS_BAR_ERROR,
  STATUS_BAR_CONTAINS_ERRORS,
  STATUS_BAR_EMPTY,
  STATUS_BAR_TIMEOUT,
} from 'components/ui/status-bar';

export const dates = [
  { name: 'date', value: undefined, label: 'Date' },
  { name: 'date_from', value: undefined, label: 'Date from' },
  { name: 'date_to', value: undefined, label: 'Date to' },
];

export const statusFilters = [
  {
    label: 'Success',
    help: 'The data contained in the file was successfully integrated!',
    value: 'SUCCESS',
    statusBarType: STATUS_BAR_SUCCESS,
    checked: false,
  },
  {
    label: 'In Progress',
    help: 'The import is still in progress: some products are not fully integrated yet.',
    value: 'IN_PROGRESS',
    statusBarType: STATUS_BAR_IN_PROGRESS,
    checked: false,
  },
  {
    label: 'Contains Errors',
    help: ' There was a problem with some data in the file: some products could not be imported.',
    value: 'CONTAINS_ERRORS',
    statusBarType: STATUS_BAR_CONTAINS_ERRORS,
    checked: false,
  },
  {
    label: 'Error',
    help: ' There was a problem with the file itself: no data could be imported from it.',
    value: 'ERROR',
    statusBarType: STATUS_BAR_ERROR,
    checked: false,
  },
  {
    label: 'Timeout',
    help: "We don't know what happened, the import is not finished, but we have had no news for more than one hour. It probably failed. Or not. ¯\\_(ツ)_/¯",
    value: 'TIMEOUT',
    statusBarType: STATUS_BAR_TIMEOUT,
    checked: false,
  },
  {
    label: 'Empty',
    help: 'There was no data in the file: nothing was imported.',
    value: 'EMPTY',
    statusBarType: STATUS_BAR_EMPTY,
    checked: false,
  },
];

export const supportStatusFilters = [
  {
    label: 'TO DO',
    value: 'TO_DO',
    statusBarType: STATUS_BAR_ERROR,
    checked: false,
  },
  {
    label: 'IN PROGRESS',
    value: 'IN_PROGRESS',
    statusBarType: STATUS_BAR_IN_PROGRESS,
    checked: false,
  },
  {
    label: 'DONE',
    value: 'DONE',
    statusBarType: STATUS_BAR_SUCCESS,
    checked: false,
  },
];

export const spiders = [
  {
    value: 'JSON',
    label: 'Salsify SupplierXM JSON',
  },
  {
    value: 'AlkemicsV2',
    label: 'Salsify SupplierXM XML V2',
  },
  {
    value: 'Alkemics',
    label: 'Salsify SupplierXM XML V1',
  },
  {
    value: 'XLSX',
    label: 'Salsify SupplierXM XLSX',
  },
  {
    value: 'XLSX2',
    label: 'Salsify SupplierXM XLSX v2',
  },
  {
    value: 'FABDIS',
    label: 'FAB-DIS XLSX',
  },
  {
    value: 'GDSN3',
    label: 'GDSN 3.1',
  },
  {
    value: 'Brandbank',
    label: 'BrandBank XML',
  },
  {
    value: 'Casino',
    label: 'Casino MDD XLSX',
  },
  {
    value: 'NESTLE',
    label: 'Nestlé PDH API',
  },
  {
    value: 'CASINOXLSXDISPATCH',
    label: 'Casino mailbox',
  },
  {
    value: 'ART04',
    label: 'Retailer: ART04',
    type: 'range',
  },
  {
    value: 'ART04B',
    label: 'Retailer: ART04B',
    type: 'range',
  },
  {
    value: 'ALKEMICSXML',
    label: 'Retailer: XML',
    type: 'range',
  },
  {
    value: 'ALKEMICSXLSX',
    label: 'Retailer: XLSX',
    type: 'range',
  },
  {
    value: 'CASINOXML',
    label: 'Retailer: Casino XML',
    type: 'range',
  },
  {
    value: 'ALKEMICSXLSX2',
    label: 'Salsify SupplierXM XLSX2',
    type: 'range',
  },
  {
    value: 'BRANDBANKJSONAPI',
    label: 'BrandBank JSON UK',
  },
  {
    value: 'BRANDBANKJSONAPIGLOBAL',
    label: 'BrandBank JSON Global',
  },
].sort((a, b) => a.label.localeCompare(b.label));

export function getFormatType(formatName) {
  const spider = spiders.filter((e) => e.value === formatName)[0];
  return (spider && spider.type) || 'product';
}

export const rangeSpiders = spiders.filter((e) => e.type === 'range');
export const rfpSpiders = spiders.filter((e) => e.type === 'rfp');
export const dataSpiders = spiders.filter((e) => e.type === undefined);
