import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { fromJS } from 'immutable';

import { Button } from '@alkem/react-ui-button';
import { Modal } from '@alkem/react-ui-modal';
import Well from '@alkem/react-ui-well';

import KindsTree from 'components/kind-tree';

import './kinds.scss';

export default class FieldKinds extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    kinds: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      kinds: props.kinds,
    };

    this.openModal = this.toggleModal.bind(this, true);
    this.closeModal = this.toggleModal.bind(this, false);
  }

  applyChanges = () => {
    this.props.onChange(this.state.kinds);
    this.closeModal();
  };

  discardChanges = () => {
    this.setState({ kinds: this.props.kinds });
    this.closeModal();
  };

  update = (kinds) => {
    this.setState({ kinds: fromJS(kinds) });
  };

  toggleModal(modalOpen) {
    this.setState({ kinds: this.props.kinds });
    this.setState({ modalOpen });
  }

  renderSummary() {
    const { kinds } = this.props;
    return (
      <Well content={`${kinds.size} kind(s)`}>
        <pre className="FieldKinds__selectedList">
          {kinds
            .sortBy((kind) => kind.getIn(['kind', 'id']))
            .map((kind) => (
              <div
                key={kind.getIn(['kind', 'id'])}
                className={
                  kind.get('exclusion') ? 'text-danger' : 'text-success'
                }
              >
                {kind.getIn(['kind', 'id'])}
                &nbsp;
                {kind.getIn(['kind', 'label'])}
              </div>
            ))}
        </pre>
      </Well>
    );
  }

  renderModal() {
    const { readOnly } = this.props;
    const { modalOpen } = this.state;

    if (readOnly) {
      return null;
    }

    return (
      <>
        <Button content="Edit/view" onClick={this.openModal} secondary />
        {modalOpen && (
          <Modal
            title="Select applicable kinds"
            confirmButtonText="Apply"
            onConfirm={this.applyChanges}
            onClose={this.discardChanges}
          >
            <KindsTree
              onSelectionChange={this.update}
              selection={this.state.kinds.toJS()}
              useFormatters
              revealSelectedPaths
            />
          </Modal>
        )}
      </>
    );
  }

  render() {
    return (
      <div className="FieldKinds" id={this.props.id}>
        {this.renderSummary()}
        {this.renderModal()}
      </div>
    );
  }
}
