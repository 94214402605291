import { useState } from 'react';
import moment, { Moment } from 'moment';
import { useDispatch } from 'react-redux';

import './item-activation-dates.scss';
import { ExportMappingsDateSelector } from '../date-selector';
import { DATETIME_FORMAT, UNLIMITED_DATE } from '../../../constants';
import { ExportMapping } from '../../../types';
import { SET_EXPORT_MAPPING } from '../../../actions/constants';

interface Props {
  exportMapping: ExportMapping;
}
export function ExportMappingsItemActivationDates({ exportMapping }: Props) {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(moment(exportMapping.date_start));
  const [endDate, setEndDate] = useState(moment(exportMapping.date_end));
  const [showStartDateCalendar, setShowStartDateCalendar] = useState(false);
  const [showEndDateCalendar, setShowEndDateCalendar] = useState(false);
  const [isEndDateUnlimited, setIsEndDateUnlimited] = useState(true);

  const updateStartDate = (date: Moment) => {
    setStartDate(date);
    dispatch({
      type: SET_EXPORT_MAPPING,
      payload: {
        exportMapping: {
          ...exportMapping,
          date_start: date.format(DATETIME_FORMAT),
        },
      },
    });
  };

  const updateEndDate = (date?: Moment) => {
    const dateValue = date || moment(startDate).add(1, 'd');
    setEndDate(dateValue);
    dispatch({
      type: SET_EXPORT_MAPPING,
      payload: {
        exportMapping: {
          ...exportMapping,
          date_end: dateValue.format(DATETIME_FORMAT),
        },
      },
    });
  };

  const toggleShowStartDateCalendar = (): void => {
    if (showEndDateCalendar) setShowEndDateCalendar(false);
    setShowStartDateCalendar(!showStartDateCalendar);
  };

  const toggleShowEndDateCalendar = (): void => {
    if (showStartDateCalendar) setShowStartDateCalendar(false);
    setShowEndDateCalendar(!showEndDateCalendar);
  };

  const updateIsEndDateUnlimited = (isUnlimited: boolean) => {
    setIsEndDateUnlimited(isUnlimited);
    if (isUnlimited) {
      setShowEndDateCalendar(false);
      updateEndDate(moment(UNLIMITED_DATE));
    } else {
      updateEndDate(undefined);
    }
  };

  return (
    <div
      data-testid="GDSNExportMappingsItem_activationDates"
      className="GDSNExportMappingsItemActivationDates"
    >
      <div className="GDSNExportMappingsItemActivationDates__sectionTitle">
        Activation dates
      </div>
      <ExportMappingsDateSelector
        date={startDate}
        label="Start date"
        name="start"
        selectDate={updateStartDate}
        showCalendar={showStartDateCalendar}
        toggleShowCalendar={toggleShowStartDateCalendar}
      />
      <ExportMappingsDateSelector
        date={endDate}
        isUnlimited={isEndDateUnlimited}
        label="End date"
        name="end"
        selectDate={updateEndDate}
        setIsUnlimited={updateIsEndDateUnlimited}
        showCalendar={showEndDateCalendar}
        toggleShowCalendar={toggleShowEndDateCalendar}
      />
    </div>
  );
}
