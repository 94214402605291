import TurboSelect, { TurboSelectProps } from '@alkem/react-ui-turbo-select';
import { SOURCE_PRODUCTDNA } from 'constants/organization';
import { TARGETMARKET_UK } from 'constants/targetmarkets';
import { Map } from 'immutable';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import referentialApi from 'resources/referentialApi';
import { getOrganizationSource } from 'utils/organization';
import { getOrganization } from 'utils/user';

type Country = {
  id: number;
  code: string;
  label: string;
};

interface Props extends Pick<TurboSelectProps<Country>, 'onChange'> {
  components?: any;
  isClearable: boolean;
  isMulti: boolean;
  disabled: boolean;
  isSearchable: boolean;
  value: number[] | number;
  user: Map<string, any>;
}

export const CountryAutocomplete = React.memo(
  ({
    components,
    isClearable,
    disabled,
    isSearchable,
    isMulti,
    value,
    onChange,
    user,
  }: Props) => {
    const [defaultOptions, setDefaultOptions] = useState<Country[]>([]);

    const onSearch = useCallback(
      async (search = '') => {
        const isProductDna = () =>
          getOrganizationSource(getOrganization(user)) === SOURCE_PRODUCTDNA;
        const queries: any = {
          search,
          lang: user.get('language'),
        };
        if (isProductDna()) {
          queries.search_code = TARGETMARKET_UK;
        }
        let {
          data: { data },
        }: any = await referentialApi.ReferentialGetList(
          'targetmarkets',
          queries
        );
        if (isProductDna()) {
          data = data.filter(
            (country) => country.code === TARGETMARKET_UK.toString()
          );
        }
        return data;
      },
      [user]
    );

    useEffect(() => {
      let canUpdateState = true;
      onSearch().then((countries) => {
        if (canUpdateState) {
          setDefaultOptions(countries);
        }
      });
      return () => {
        canUpdateState = false;
      };
    }, [onSearch]);

    const loadOptions = debounce((inputValue: string, callback) => {
      onSearch(inputValue).then((options) => callback(options));
    }, 250);

    const getOptionLabel = useCallback((opt: Country) => `${opt.label}`, []);

    const getOptionValue = useCallback((opt: Country) => opt.id, []);

    const selectedValue = useMemo(() => {
      if (!value) {
        return undefined;
      }
      return isMulti
        ? defaultOptions.filter((opt) =>
            (value as number[]).includes(getOptionValue(opt))
          )
        : defaultOptions.find(
            (opt) => getOptionValue(opt) === (value as number)
          );
    }, [value, isMulti, defaultOptions, getOptionValue]);

    return (
      <TurboSelect<Country, true | false>
        value={selectedValue}
        defaultOptions={defaultOptions}
        loadOptions={loadOptions}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue as any}
        isClearable={isClearable}
        isMulti={isMulti}
        isSearchable={isSearchable}
        components={components}
        onChange={onChange}
        isDisabled={disabled}
      />
    );
  }
);
