import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { List } from 'immutable';
import { separateActions } from 'utils/redux';

import { ListAutocomplete } from 'components/autocomplete';
import { READONLY_FIELDS } from 'constants/organization-settings';
import { updateSettingValue } from 'modules/organization-page/actions/settings';
import {
  selectEditedOrSourceSetting,
  selectFieldTagReferential,
} from 'modules/organization-page/selectors/settings';
import FieldsReadOnlySettingEditModalFieldTagsLine from './line';

const mapStateToProps = (state) => ({
  readOnlyFieldSetting: selectEditedOrSourceSetting(state, [READONLY_FIELDS]),
  fieldTagReferential: selectFieldTagReferential(state),
});

const mapDispatchToProps = {
  updateSettingValue,
};

export class FieldsReadOnlySettingEditModalFieldTags extends PureComponent {
  static propTypes = {
    settingAttr: PropTypes.string.isRequired,
    readOnlyFieldSetting: ImmutablePropTypes.map.isRequired,
    fieldTagReferential: ImmutablePropTypes.list.isRequired,
    actions: PropTypes.shape({
      updateSettingValue: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = {
    newTag: null,
  };

  add = () => {
    const { actions, readOnlyFieldSetting, settingAttr } = this.props;
    const { newTag } = this.state;
    const currentTags = readOnlyFieldSetting.get(settingAttr) || List();
    actions.updateSettingValue(
      [READONLY_FIELDS],
      readOnlyFieldSetting.set(settingAttr, currentTags.push(newTag.value))
    );
    this.setState({ newTag: null });
  };

  delete = (tag) => {
    const { actions, readOnlyFieldSetting, settingAttr } = this.props;
    const currentTags = readOnlyFieldSetting.get(settingAttr) || List();
    actions.updateSettingValue(
      [READONLY_FIELDS],
      readOnlyFieldSetting.set(
        settingAttr,
        currentTags.filter((t) => t !== tag)
      )
    );
  };

  onSelectTag = (tag) => {
    this.setState({ newTag: tag });
  };

  render() {
    const { newTag } = this.state;
    const { fieldTagReferential, settingAttr, readOnlyFieldSetting } =
      this.props;
    const tags = readOnlyFieldSetting.get(settingAttr) || List();
    return (
      <div>
        <div>
          <h3>Tags</h3>
        </div>
        {tags.size ? (
          <ul className="FieldsReadOnlySettingEditModal__lines">
            {tags.map((tag) => (
              <FieldsReadOnlySettingEditModalFieldTagsLine
                key={tag}
                tag={tag}
                onDelete={this.delete}
              />
            ))}
          </ul>
        ) : (
          <div className="FieldsReadOnlySettingEditModal__empty">None</div>
        )}
        <div className="FieldsReadOnlySettingEditModal__line">
          <ListAutocomplete
            id="list-autocomplete-tags"
            onSelect={this.onSelectTag}
            values={fieldTagReferential
              .filter((tag) => !tags.contains(tag.get('code')))
              .map((tag) => ({
                key: tag.get('code'),
                value: tag.get('code'),
                label: tag.get('code'),
              }))
              .toJS()}
            value={newTag ? [newTag] : []}
            addMissingOptionLabel="Search..."
            searchOnClick
          />{' '}
          <span
            className="FieldsReadOnlySettingEditModal__addButton mdi mdi-check"
            onClick={this.add}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  separateActions
)(FieldsReadOnlySettingEditModalFieldTags);
