import { useState } from 'react';
// import { createI13 }

import { SimpleSelect } from '@alkem/react-ui-select';

const options = [
  'SUCCESS',
  'ERROR',
  'CONTAINS_ERRORS',
  'TIMEOUT',
  'EMPTY',
  'IN_PROGRESS',
].map((status) => ({
  id: `import-status-${status}`,
  label: renderField(status, status.replace('_', ' ')),
  value: status,
}));
const extraOptions = {
  i13Action: 'importStatus',
  i13Category: 'filter',
};

function renderField(status, readableStatus) {
  return (
    <span className={`SimpleSelect_status--${status}`}>{readableStatus}</span>
  );
}

export declare type ÌmportStatusSelectProps = {
  importFileId: number;
  status: string;
  onChange: (number, string) => void;
  extraOptions: Map<any, any>;
};

export function ImportStatusSelect(props: ÌmportStatusSelectProps) {
  const [status, setStatus] = useState<string>(props.status);

  const onChange = (value: any) => {
    setStatus(value.value);
    props.onChange(props.importFileId, value.value);
  };

  const findSelectedValue = () => {
    return options.find((opt) => opt.value === status);
  };

  return (
    <SimpleSelect
      id="importStatus"
      options={options}
      value={findSelectedValue()}
      onSelect={onChange}
      extraOptions={{ ...extraOptions, ...props.extraOptions }}
      autoSize
    />
  );
}
