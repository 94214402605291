import { GdsnApi } from '@alkem/sdk-dashboard';

export const immutableGdsnApi = new GdsnApi({
  immutable: true,
  cacheEnabled: true,
  acceptLanguage: 'en',
});

export default new GdsnApi({
  cacheEnabled: true,
  acceptLanguage: 'en',
});
