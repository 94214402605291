import { List } from 'immutable';

import { immutableAuthApi } from 'resources/authApi';
import { immutableGdsnApi } from 'resources/gdsnApi';
import { notificationError } from 'actions/notifications';
import * as events from '../constants/events';
import { STATUS_CREATED } from '../constants';
import {
  selectSearchUsername,
  selectSearchGLN,
  selectLimit,
  selectCurrentPage,
  selectActionnableClaimsOnly,
} from '../selectors';

export const setLoading = (value) => ({
  type: events.GLNCLAIMS_LOADING,
  value,
});

export const setActionInProgress = (value) => ({
  type: events.GLNCLAIMS_ACTION_IN_PROGRESS,
  value,
});

export const setActionnableClaimsOnly = (value) => ({
  type: events.GLNCLAIMS_SET_ACTIONNABLE_CLAIMS_ONLY,
  value,
});

// Pagination

export const nextPage = () => ({
  type: events.GLNCLAIMS_NEXT_PAGE,
});

export const previousPage = () => ({
  type: events.GLNCLAIMS_PREVIOUS_PAGE,
});

export const onLimitChange = (limit) => ({
  type: events.GLNCLAIMS_CHANGE_LIMIT,
  limit,
});

// Search

export const searchByUsername = (search) => ({
  type: events.USERNAME_SEARCH_UPDATE,
  search,
});

export const searchByGLN = (search) => ({
  type: events.GLN_SEARCH_UPDATE,
  search,
});

// List

export const getGLNData = (glns) => async (dispatch) => {
  const batchSize = 50;
  const promises = [];

  dispatch(setLoading(true));

  // Batch to not explode the limit
  while (glns.length > 0) {
    const glnBatch = glns.splice(0, batchSize);
    promises.push(
      immutableGdsnApi.GdsnGLNList({ filter_gln_in: glnBatch }, 0, batchSize)
    );
  }

  let results;
  try {
    results = await Promise.all(promises);
  } catch (exception) {
    dispatch(notificationError('Could not retrieve gln data'));
    return;
  }

  let glnList = List();
  results.forEach((result) => {
    try {
      glnList = glnList.concat(result.data.data);
    } catch (exception) {
      dispatch(notificationError('Could not retrieve gln data'));
    }
  });

  dispatch({
    type: events.GLNDATA_RECEIVED,
    data: glnList,
  });

  dispatch(setLoading(false));
};

const constructFilters = (state) => {
  const filters = {};

  const searchUsername = selectSearchUsername(state);
  const searchGLN = selectSearchGLN(state);
  const actionnableClaimsOnly = selectActionnableClaimsOnly(state);

  if (searchUsername && searchUsername.length) {
    filters.username = searchUsername;
  }
  if (searchGLN && searchGLN.length) {
    filters.gln = searchGLN;
  }
  if (actionnableClaimsOnly) {
    filters.status = STATUS_CREATED.id;
  }

  return filters;
};

const computeOffset = (state) => {
  const limit = selectLimit(state);
  const currentPage = selectCurrentPage(state);
  return limit * (currentPage - 1);
};

export const getGLNClaimsWithData =
  (resetPagination = false) =>
  async (dispatch, getState) => {
    const state = getState();

    const filters = constructFilters(state);
    const queries = {
      withTotalResults: true,
    };

    const limit = selectLimit(state);
    const offset = resetPagination ? 0 : computeOffset(state);

    dispatch(setLoading(true));
    try {
      const response = await immutableAuthApi.UserClaimGlnList(
        queries,
        filters,
        offset,
        limit,
        false
      );

      const list = response.data.data;
      dispatch(setLoading(false));
      dispatch({
        type: events.GLNCLAIMS_RECEIVED,
        list,
        totalResults: response.data.totalResults,
        resetPagination,
      });

      if (list.size) {
        // Retrieve GLN data
        const glns = list
          .map((elem) => elem.get('value'))
          .toSet()
          .toJS();
        dispatch(getGLNData(glns));
      }
    } catch (exception) {
      dispatch(
        notificationError(
          'An error occured while loading the list, refresh the page and retry'
        )
      );
      dispatch(setLoading(false));
    }
  };

// Actions

export const updateGLNClaimStatus = (claimId, status) => async (dispatch) => {
  dispatch(setActionInProgress(true));
  try {
    await immutableAuthApi.UserClaimGlnUpdate(claimId, { status });
    dispatch(setActionInProgress(false));
    dispatch(getGLNClaimsWithData(true));
  } catch (exception) {
    dispatch(notificationError('An error occured, refresh the page and retry'));
    dispatch(setActionInProgress(false));
  }
};

export const updateGLNNameLegal = (glnId, nameLegal) => async (dispatch) => {
  dispatch(setActionInProgress(true));
  try {
    await immutableGdsnApi.GLNUpdate(glnId, nameLegal);
    dispatch(setActionInProgress(false));
  } catch (exception) {
    dispatch(notificationError('An error occured, refresh the page and retry'));
    dispatch(setActionInProgress(false));
  }
};
