import {
  FIELD_TYPES_WITH_CHILDREN,
  FORBIDDEN_FIELD_TYPES as FORBIDDEN_FIELD_TYPES,
} from './constants';
import { FieldOptions, Fields } from './types';

export const getUpdatedListFieldOptions = (
  listFieldOptions: FieldOptions,
  parentName: string,
  children: Fields
): FieldOptions => {
  for (const child of children) {
    if (child.type === 'list') {
      const childName = `${parentName}.${child.name}`;
      listFieldOptions.push({ id: child.id, label: childName, data: child });
      if (child.children) {
        listFieldOptions = getUpdatedListFieldOptions(
          listFieldOptions,
          childName,
          child.children
        );
      }
    }
  }
  return listFieldOptions;
};

export const getListFieldChildren = (children: Fields | undefined) => {
  return (children || [])
    .filter((child) => child.type === 'list')
    .map((child) => ({
      ...child,
      children: getListFieldChildren(child.children),
    }));
};

export const getListFieldOptions = (fields: Fields) => {
  return fields
    .filter((field) => field.type === 'list')
    .map((field) => {
      const children = getListFieldChildren(field.children);
      return { ...field, children };
    })
    .reduce((arr: FieldOptions, field) => {
      let updatedListFieldOptions: FieldOptions = [
        ...arr,
        { id: field.id, label: field.name, data: field },
      ];
      if ((field.children || []).length === 0) {
        return updatedListFieldOptions;
      }
      updatedListFieldOptions = getUpdatedListFieldOptions(
        updatedListFieldOptions,
        field.name,
        field.children
      );
      return updatedListFieldOptions;
    }, []);
};

export const getFieldChildren = (children: Fields | undefined) => {
  return (children || [])
    .filter(
      (child) => child.type && !FORBIDDEN_FIELD_TYPES.includes(child.type)
    )
    .map((child) => {
      if (!FIELD_TYPES_WITH_CHILDREN.includes(child.type || '')) {
        return child;
      }
      return {
        ...child,
        children: getFieldChildren(child.children),
      };
    });
};

export const getUpdatedFieldOptions = (
  fieldOptions: FieldOptions,
  parentName: string,
  children: Fields
): FieldOptions => {
  for (const child of children) {
    const childName = `${parentName}.${child.name}`;
    if (!FIELD_TYPES_WITH_CHILDREN.includes(child.type || '')) {
      fieldOptions.push({ id: child.id, label: childName, data: child });
    } else if (child.children && child.children.length > 0) {
      fieldOptions = getUpdatedFieldOptions(
        fieldOptions,
        childName,
        child.children
      );
    }
  }
  return fieldOptions;
};

export const getFieldOptions = (fields: Fields) => {
  return fields
    .filter(
      (field) => field.type && !FORBIDDEN_FIELD_TYPES.includes(field.type)
    )
    .map((field) => {
      if (!FIELD_TYPES_WITH_CHILDREN.includes(field.type || '')) {
        return field;
      }
      const children = getFieldChildren(field.children);
      return { ...field, children };
    })
    .reduce((arr: FieldOptions, field) => {
      let updatedFieldOptions: FieldOptions = arr.slice();
      if (!FIELD_TYPES_WITH_CHILDREN.includes(field.type || '')) {
        updatedFieldOptions = [
          ...updatedFieldOptions,
          { id: field.id, label: field.name, data: field },
        ];
      } else if (field.children && field.children.length > 0) {
        updatedFieldOptions = getUpdatedFieldOptions(
          updatedFieldOptions,
          field.name,
          field.children
        );
      }
      return updatedFieldOptions;
    }, []);
};

export const getStringWithoutLineBreaks = (value: string): string => {
  return value.replace(/(?:\r\n|\r|\n)/g, ' ');
};
