import classNames from 'classnames';
import StatusBar from 'components/ui/status-bar';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { separateActions } from 'utils/redux';
import { Modal } from '@alkem/react-ui-modal';

import { downloadGDSNDocument, deleteGDSNRCI } from '../../actions';
import './row.scss';

const mapDispatchToProps = {
  downloadGDSNDocument,
  deleteGDSNRCI,
};

export class GDSNRCIListRow extends Component {
  static propTypes = {
    rci: PropTypes.object.isRequired,
    isSuperAdmin: PropTypes.bool,
    actions: PropTypes.shape({
      downloadGDSNDocument: PropTypes.func.isRequired,
      deleteGDSNRCI: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      confirmDeleteModalOpen: false,
    };
  }

  onClickExpand = () => {
    const { isExpanded } = this.state;
    this.setState({ isExpanded: !isExpanded });
  };

  onClickOpenDeleteModal = () => {
    this.setState({ confirmDeleteModalOpen: true });
  };

  onClickCloseDeleteModal = () => {
    this.setState({ confirmDeleteModalOpen: false });
  };

  onDelete = () => {
    const { rci } = this.props;
    this.props.actions.deleteGDSNRCI(rci.id);
    this.onClickCloseDeleteModal();
  };

  getDocumentState(doc) {
    return doc.transaction.ack.status !== 'ACCEPTED' ? 'FAILURE' : 'SUCCESS';
  }

  getRCIState(entity) {
    return !entity.documents ||
      entity.documents.filter(
        (doc) => doc.transaction.ack.status === 'ACCEPTED'
      ).length === 0
      ? 'FAILURE'
      : 'SUCCESS';
  }

  renderDocuments() {
    const { rci, isSuperAdmin } = this.props;
    let lines = null;
    if (rci.documents !== undefined) {
      lines = rci.documents.map((doc) => (
        <div key={doc.id} className="GDSNRCIListRow GDSNRCIListRow_mini row">
          <StatusBar type={this.getDocumentState(doc)} />
          <div className="col-xs-1">{doc.type}</div>
          <div className="col-xs-2">{doc.createdAt}</div>
          <div className="col-xs-2">{doc.status}</div>
          <div className="col-xs-1">{doc.message.state}</div>
          {isSuperAdmin && (
            <div className="col-xs-1">
              <i
                className="mdi mdi-download GDSNRCIListDetailsIconDoc"
                onClick={() => {
                  this.props.actions.downloadGDSNDocument(doc.message.id);
                }}
              />
            </div>
          )}
          <div className="col-xs-4">
            {`${
              doc.transaction.ack.document_id
                ? `${doc.transaction.ack.status} @ ${doc.transaction.ack.createdAt}`
                : 'Not received'
            } `}
            {isSuperAdmin && doc.transaction.ack.document_id && (
              <i
                className="mdi mdi-download GDSNRCIListDetailsIconDoc"
                onClick={() => {
                  this.props.actions.downloadGDSNDocument(
                    doc.transaction.ack.document_id
                  );
                }}
              />
            )}
          </div>
        </div>
      ));
    }
    return (
      <div className="GDSNRCIListRowWrapper">
        <div className="GDSNRCIListRow GDSNRCIListRow_mini GDSNRCIListRow__Header row">
          <div className="col-xs-1">Type</div>
          <div className="col-xs-2">Created At</div>
          <div className="col-xs-2">Status</div>
          <div className="col-xs-1">State</div>
          {isSuperAdmin && <div className="col-xs-1">Download</div>}
          <div className="col-xs-2">CIRR</div>
        </div>
        <div className="GDSNRCIListRow__Lines">{lines}</div>
      </div>
    );
  }

  renderParty(party) {
    if (party.organizations && party.organizations.length > 0) {
      return (
        <div className="GDSNRCIListRow_boldy">
          <i className="mdi mdi-domain" />
          {` ${party.organizations[0].nameLegal} (${party.organizations[0].id})`}
          {party.organizations.length > 1 &&
            ` +${party.organizations.length - 1}`}
        </div>
      );
    }
    if (party.nameLegal === null) {
      return <div />;
    }
    return (
      <div className="GDSNRCIListRow_boldy">
        <i className="mdi mdi-domain" />
        {` ${party.nameLegal}`}
      </div>
    );
  }

  render() {
    const { rci } = this.props;
    const { isExpanded, confirmDeleteModalOpen } = this.state;
    const expandClass = {
      GDSNRCIListDetailsIcon: true,
      'col-xs-0.5': true,
      mdi: true,
      'mdi-chevron-up': rci.documents.length > 0 && isExpanded,
      'mdi-chevron-down': rci.documents.length > 0 && !isExpanded,
      hidden: rci.documents.length === 0,
    };
    return (
      <div className="GDSNRCIListRowWrapper row">
        <div key={rci.id} id={rci.id} className="GDSNRCIListRow row">
          <StatusBar type={this.getRCIState(rci)} />
          <div className="col-xs-3">
            <div>
              <i className="mdi mdi-barcode GDSNRCIListRow_PadTip" />
              <span className="GDSNRCIListRow_boldy GDSNRCIListRow_PadTip">
                {rci.gtin}
              </span>
            </div>
            <div>
              <i className="mdi mdi-earth GDSNRCIListRow_PadTip" />
              {rci.target_market}
            </div>
            <div>
              <i className="mdi mdi-chemical-weapon GDSNRCIListRow_PadTip" />
              {rci.gpc}
            </div>
          </div>
          <div className="col-xs-3">
            <div>
              <i className="mdi mdi-information-outline GDSNRCIListRow_PadTip" />
              {rci.state}
            </div>
            <div>
              <i className="mdi mdi-note-plus GDSNRCIListRow_PadTip" />
              {rci.registeredAt}
            </div>
            {rci.lastChangedAt !== rci.registeredAt && (
              <div>
                <i className="mdi mdi-sync GDSNRCIListRow_PadTip" />
                {rci.lastChangedAt}
              </div>
            )}
            {rci.canceledAt && (
              <div>
                <i className="mdi mdi-sync-off GDSNRCIListRow_PadTip" />
                {rci.canceledAt}
              </div>
            )}
            {rci.deletedAt && (
              <div>
                <i className="mdi mdi-close-box GDSNRCIListRow_PadTip" />
                {rci.deletedAt}
              </div>
            )}
          </div>
          <div className="col-xs-3">
            {this.renderParty(rci.party)}
            <div>
              <i className="mdi mdi-apps" />
              {` ${rci.party.gln}`}
            </div>
            {rci.party.datapool.name !== '' && (
              <div>
                <i className="mdi mdi-steam" />
                {` ${rci.party.datapool.name}`}
              </div>
            )}
          </div>
          <div className="col-xs-1 GDSNRCIListRow_actions">
            <i
              onClick={this.onClickOpenDeleteModal}
              className="GDSNRCIListDetailsIcon mdi mdi-delete"
            />
            <i
              onClick={this.onClickExpand}
              className={classNames(expandClass)}
            />
          </div>
        </div>
        {isExpanded ? this.renderDocuments() : null}
        {confirmDeleteModalOpen && (
          <Modal
            title="RCI Removal"
            confirmButtonText="Confirm"
            onConfirm={this.onDelete}
            onClose={this.onClickCloseDeleteModal}
          >
            <div>
              <p>
                Are you sure you want to remove this GTIN from Global Registry ?
              </p>
              <p>
                <strong>{rci.gtin}</strong>
              </p>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

export default connect(
  null,
  mapDispatchToProps,
  separateActions
)(GDSNRCIListRow);
